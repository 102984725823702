import React from 'react';
import styled from 'styled-components';
import * as Style from '../Common/StyledComponents';
import {MdClose} from 'react-icons/md';
import {FaRegCheckSquare, FaRegSquare} from "react-icons/fa";
import DatePicker from 'react-date-picker';
import AlertBox from '../Common/AlertBox';
import SpinningLoaderSmall from '../Common/SpinningLoaderSmall';


const CSLDateCover = styled.div`
    width: 200px;
    border-radius: 3px;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0 0 4px #c4c4c4;
`


const moment = require('moment');

class DateRangeModal extends React.Component{
    state = {
        mode : null,
        subtitle_msg : "",
        from_nodate : true,
        to_nodate : true,
        date_range_data : {},
		error_msg : null,
		ready : false,
		date_range_data_from_prop : {},
		alert_param : null,
		show_spinner : false
    }

	constructor(props){
		super(props);
		this.state.date_range_data = this.props.date_range_data;
	}

	componentDidMount()
	{
		this.initialiseState();
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			this.initialiseState();
		}
	}

	initialiseState()
	{
		console.log("Date Range Modal Props ", this.props);
		let data = JSON.parse(JSON.stringify(this.props.date_range_data));
		let date_range_data_from_prop = JSON.parse(JSON.stringify(data));
		let from_nodate = data.data.from === -1 ? true : false;
		let to_nodate = data.data.to === parseInt(process.env.REACT_APP_NO_END_DATE) ? true : false;
		data.data.from = data.data.from === -1 ? null : this.toDateObject(data.data.from);
		data.data.to = data.data.to === parseInt(process.env.REACT_APP_NO_END_DATE) ? null : this.toDateObject(data.data.to);
		let subtitle_msg = ""
		switch(this.props.date_range_data.type){
			case "na":
				subtitle_msg = "Enter the date range and reason why this role is marked as Not Applicable at this firm during this date range"
				break;
			case "shared":
				subtitle_msg = "Enter the date range and reason why this role is allowed to be shared during this date range"
				break;
			case "divided":
				subtitle_msg = "Enter the date range and reason why this role is allowed to be divided during this date range"
				break;
		}
		if(this.props.date_range_data.mode === 'add'){
			this.setState({from_nodate, to_nodate, subtitle_msg, ready : true, date_range_data : data, mode : this.props.date_range_data.mode});
		}else if(this.props.date_range_data.mode === 'edit'){
			this.setState({from_nodate, to_nodate, subtitle_msg, ready : true, date_range_data : data, date_range_data_from_prop ,  mode : this.props.date_range_data.mode});
		}

	}

	closeModal =(type) => (event) => {
		event.preventDefault();
		this.props.closeModal(type);
	}

	toggleCheckBox = (id) => (e) => {
		e.preventDefault();
		let date_range_data = JSON.parse(JSON.stringify(this.state.date_range_data));
		let status = false;
		if(id === "to") {
			status = this.state.to_nodate === true ? false : true;
			date_range_data.data.to = status === false ? date_range_data.data.from !== null ? moment(date_range_data.data.from).toDate() : new Date() : null;
			date_range_data.data.from = date_range_data.data.from !==  null ? moment(date_range_data.data.from).toDate() : null;
			console.log("date range data : ", date_range_data);
			this.setState({ to_nodate : status, date_range_data });
		}else if(id === "from"){
			status = this.state.from_nodate === true ? false : true;
			date_range_data.data.from = status === false ? date_range_data.data.to !== null ? moment(date_range_data.data.to).toDate() : new Date() : null;
			date_range_data.data.to = date_range_data.data.to !== null ? moment(date_range_data.data.to).toDate() : null;
			console.log("date range data : ", date_range_data);
			this.setState({ from_nodate : status, date_range_data });
		}
	}

	onFromDateChange = (date) => {
		let date_range_data = JSON.parse(JSON.stringify(this.state.date_range_data));
		let error_msg = null;
		if(date_range_data.data.to === null){
			date_range_data.data.from = new Date(date);
		}
		else if(date_range_data.data.to !== null && moment(date).unix() < moment(date_range_data.data.to).unix())
		{
			date_range_data.data.from = new Date(date);
			date_range_data.data.to = date_range_data.data.to !== null ? new Date(date_range_data.data.to) : null;
		}
		else{
			let alert_param = {
				title: 'ERROR..', message: 'From date must be smaller than to date.', ok_text: 'Ok', confirm: false,
				alertHandler: this.closeAlert, stack: {}
			}
			this.setState({ alert_param });
			date_range_data.data.from = date_range_data.data.from !== null ? new Date(date_range_data.data.from) : null;
			date_range_data.data.to = date_range_data.data.to !== null ? new Date(date_range_data.data.to) : null;
		}
		this.setState({date_range_data, error_msg});
	}

	onToDateChange = (date) => {
		let date_range_data = JSON.parse(JSON.stringify(this.state.date_range_data));
		let error_msg = null;
		if(date_range_data.data.from === null){
			date_range_data.data.to = new Date(date);
		}
		else if(date_range_data.data.from !== null && moment(date).unix() > moment(date_range_data.data.from).unix())
		{
			date_range_data.data.to = new Date(date);
			date_range_data.data.from = date_range_data.data.from !== null ? new Date(date_range_data.data.from) : null;
		}
		else{
			let alert_param = {
				title: 'ERROR..', message: 'To date must be greater then from date', ok_text: 'Ok', confirm: false,
				alertHandler: this.closeAlert, stack: {}
			}
			this.setState({ alert_param });
			date_range_data.data.to = date_range_data.data.to != null ? new Date(date_range_data.data.to) : null;
			date_range_data.data.from = date_range_data.data.from !== null ? new Date(date_range_data.data.from) : null;
		}
		this.setState({date_range_data, error_msg});
	}

	changeClear = () => {
		this.setState({ isclear: false })
	}


	toDateObject = (date) =>{
		let date_str = (date%100).toString() + "-" + Math.floor((date%10000)/100).toString() + "-" + Math.floor(date/10000).toString();
		// console.info("date_str:", date_str);
		let date_obj = moment(date_str, 'DD-MM-YYYY').toDate();
		// console.info("date_obj:", date_obj);
		return date_obj;
	}

	toDateValueAsDatabase = (date) => {
		let ret = moment(date).format('YYYY') + moment(date).format("MM") + moment(date).format("DD")
		console.log("RET : " , ret);
		return parseInt(ret);
	}

	saveDateRange = (e) =>{
		e.preventDefault();
		let date_range_data = JSON.parse(JSON.stringify(this.state.date_range_data));
		date_range_data.data.from = 0;
		date_range_data.data.from = this.state.from_nodate === true ? -1 : this.toDateValueAsDatabase(this.state.date_range_data.data.from);
		date_range_data.data.to = 0;
		date_range_data.data.to = this.state.to_nodate === true ? parseInt(process.env.REACT_APP_NO_END_DATE) : this.toDateValueAsDatabase(this.state.date_range_data.data.to);
		if(date_range_data.mode === 'edit'){
			if(this.state.date_range_data_from_prop.data.from !== date_range_data.data.from || this.state.date_range_data_from_prop.data.to !== date_range_data.data.to || this.state.date_range_data_from_prop.data.reason !== date_range_data.data.reason) {
				date_range_data.data['action'] = date_range_data.data['action'] !== 'insert' ? 'update' : date_range_data.data['action'];
			}
		}
		this.setState({ show_spinner : true })
		console.log("saveDateRange data : ",date_range_data);
		this.props.saveDateRange(date_range_data);
	}

	reasonOnChange = (e) =>{
		e.preventDefault();
		let date_range_data = JSON.parse(JSON.stringify(this.state.date_range_data));
		date_range_data.data.reason = e.target.value;
		date_range_data.data.to = date_range_data.data.to != null ? new Date(date_range_data.data.to) : null;
		date_range_data.data.from = date_range_data.data.from !== null ? new Date(date_range_data.data.from) : null;
		this.setState({date_range_data});
	}

	closeAlert = () => {

		this.setState({ alert_param : null})
	}

    render()
	{
		console.log("STate : ", this.state);
		if(this.state.ready !== true) return (<div></div>);
		return (
			<div>
                <Style.ModalContainer style={{color:"#1B3451", top: "40%", width:"90%", margin:"0px 5% 0px 5%"}}>
                    <Style.ModalHeader style={{color:"#1B3451"}}>
                    	 <div style={{fontSize: "20px", float: "left", marginTop: "15px", marginLeft: "30px"}}>
                    	 {
                    	     (() =>{
                    	         if(this.state.mode == 'add'){
                    	             return "Add Role Date Range"
                    	         }else{
                    	             return "Edit Role Date Range"
                    	         }
                    	     })()
                    	 }
                    	 </div>

        	         	<div style={{fontSize: "13px", marginTop: "50px", marginLeft: "30px", marginBottom: "15px"}}>
	                    	{this.state.subtitle_msg}
	                    </div>

                        <Style.ModalHeaderCloseBtn style={{fontSize: "19px"}} onClick={this.closeModal(this.props.date_range_data.type)}><MdClose /></Style.ModalHeaderCloseBtn>
                    </Style.ModalHeader>
                    <Style.ModalBody>
						{
							(()=>{
								return(
									<div style={{paddingLeft: "28px", color: "red"}}>
										{this.state.error_msg}
									</div>
									)
							})()
						}
						<div style={{display : "flex", flexDirection : "row", justifyContent : "space-between", alignItems : "center", width: "90%", margin : "auto"}}>
							<div style={{display : "flex", flexDirection : "column", justifyContent : "flex-start", width : "44%"}}>
								<div style={{fontSize: "14px", marginTop: "5px", fontWeight: "700"}}>
									From :
								</div>
								<div style={{fontSize: "14px", marginTop: "10px", fontWeight: "700"}}>
									{
										(()=>{
											if(this.state.from_nodate){
												return <FaRegCheckSquare name = "from" onClick={this.toggleCheckBox("from")} style={{cursor: "pointer", marginRight: "10px", fontSize: "12px"}}/>
											}else{
												return <FaRegSquare name = "from" onClick={this.toggleCheckBox("from")} style={{cursor: "pointer", marginRight: "10px", fontSize: "12px"}}/>
											}
										})()
									}
									<span>No Date</span>
								</div>
								{
									(()=>{
										if(this.state.from_nodate){
											return <div style={{width : "80%", height : "30px", border : "1px solid #bbb", borderRadius : "3px",marginTop : "10px", backgroundColor : "#eee" }}/>
										}else{
											return(
												<CSLDateCover style={{marginTop : "10px"}}>
												<DatePicker
														onChange={this.onFromDateChange}
														clearIcon={null}
														calendarIcon={null}
														locale={"en-GB"}
														value={this.state.date_range_data.data.from}
														disabled = {false}
														onKeyPress = {(e)=>{e.preventDefault()}}
												/>
												</CSLDateCover>
											)
										}
									})()
								}

							</div>
							<div style={{display : "flex", flexDirection : "column", justifyContent : "flex-start", width : "44%"}}>
								<div style={{fontSize: "14px", marginTop: "5px", fontWeight: "700"}}>
									To :
								</div>
								<div style={{fontSize: "14px", marginTop: "10px", fontWeight: "700"}}>
									{
										(()=>{
											if(this.state.to_nodate){
												return <FaRegCheckSquare name = "to" onClick={this.toggleCheckBox("to")} style={{cursor: "pointer", marginRight: "10px", fontSize: "12px"}}/>
											}else{
												return <FaRegSquare name = "to" onClick={this.toggleCheckBox("to")} style={{cursor: "pointer", marginRight: "10px", fontSize: "12px"}}/>
											}
										})()
									}
									<span>No Date</span>
								</div>
								{
									(()=>{
										if(this.state.to_nodate){
											return <div style={{width : "80%", height : "30px", border : "1px solid #bbb", borderRadius : "3px",marginTop : "10px", backgroundColor : "#eee"}}/>
										}else{
											return(
												<CSLDateCover style={{marginTop : "10px"}}>
												<DatePicker
														onChange={this.onToDateChange}
														clearIcon={null}
														calendarIcon={null}
														locale={"en-GB"}
														value={this.state.date_range_data.data.to}
														disabled = {false}
														onKeyPress = {(e)=>{e.preventDefault()}}
												/>
												</CSLDateCover>
											)
										}
									})()
								}

							</div>
						</div>
                    	<div style={{fontSize: "14px", marginTop: "5px", marginLeft: "30px", fontWeight: "700"}}>
							Reason :
						</div>
						<Style.ModalTextArea type="text" name="reason" style={{width: "89%",height: "70px",margin: "7px", marginLeft:"30px"}} onChange={this.reasonOnChange} value={this.state.date_range_data.data.reason} />
                    </Style.ModalBody>
                    <div style={{width: "100%", height: "2px", backgroundColor: "#EBEBEB", marginTop: "20px"}}></div>
                    <div style={{textAlign: "right", marginTop: "20px", paddingLeft : "20px", display : "flex", justifyContent : "space-between", alignItems : "center"}}>
						{this.state.show_spinner && <SpinningLoaderSmall text={'Validating'}/>}
						{!this.state.show_spinner && <div></div>}
						<div>
							<Style.ConfigBtn style={{backgroundColor: "#FFFFFF", borderColor:"#8D8989", color:"#8D8989"}} onClick={this.closeModal(this.props.date_range_data.type)}>Cancel</Style.ConfigBtn>
							{
								(()=>{
									if(this.state.show_spinner === true) return <Style.ConfigBtn style={{marginLeft: "15px", marginRight: "15px", backgroundColor: "#888888", borderColor:"#666666"}}>OK</Style.ConfigBtn>
									if(this.state.date_range_data.data.reason.length > 0){
										return <Style.ConfigBtn style={{marginLeft: "15px", marginRight: "15px", backgroundColor: "#18B8A5"}} onClick={this.saveDateRange}>OK</Style.ConfigBtn>
									}else{
										return <Style.ConfigBtn style={{marginLeft: "15px", marginRight: "15px", backgroundColor: "#888888", borderColor:"#666666"}}>OK</Style.ConfigBtn>
									}
								})()
							}
						</div>
                    </div>
					<AlertBox alertParam={this.state.alert_param} />
                    <div style={{width: "100%", height: "1px", backgroundColor: "#EBEBEB", marginTop: "20px"}}></div>
                </Style.ModalContainer>
            </div>
		);
	}
}

export default DateRangeModal;
