import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import Store from "../../Common/Store";
import BasicInfo from "./BasicInfo";
import HorizontalTabBar from "../RolesAndResponsibility/HorizontalTabBar"
import Roles from "./Roles";
import CertMgs from "./CertMgs";
import Files from "./Files";
import AcceptanceAlert from "./AcceptanceAlert";

class EmployeeDetails extends React.Component {

    state = {
        selected_employee_id: 0,
        tabs: [
            { id: 1, role_type: "Role Management", selected: true },
            { id: 2, role_type: "Files", selected: false },
            { id: 3, role_type: "Certification Managers", selected: false }
        ],
        selected_tab_id: 1,
    }

    async componentDidMount() {
        await this.setComponentState();
        console.log("EMPLOYEE", this.props.selected_employee_id);
        console.log("task_id1:", this.props.task_id);
    }

    async componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            await this.setComponentState()
        }
    }

    setComponentState = async () => {
        this.setState({ selected_employee_id: this.props.selected_employee_id })
    }

    changeUser = (event) => {
        event.preventDefault()
        this.setState({ selected_employee_id: parseInt(event.target.value) })
    }

    changeTab = (tab_id) => {
        let tabs = JSON.parse(JSON.stringify(this.state.tabs))
        for (let i = 0; i < tabs.length; i++) {
            if (tabs[i].id === tab_id) {
                tabs[i].selected = true
            } else {
                tabs[i].selected = false
            }
        }
        this.setState({ tabs, selected_tab_id: tab_id })
    }

    closeThis = (event) => {
        event.preventDefault()
        this.props.closeEmployeeDetails()
    }

    render() {
        const regime_users = Store.getStoreData("regime_users")
        let users = []
        for (let item of regime_users) {
            users.push({ id: item, name: this.props.users_name_obj[item.toString()] })
        }
        users.sort((a, b) => {
            if (a.name < b.name) return -1
            if (a.name > b.name) return 1
            return 0
        })
        if (this.state.selected_employee_id === 0) return <div></div>
        return (
            <div className="mc-general-container" style={{ padding: "0px", backgroundColor: "#F4F5F7" }}>
                {
                    this.state.selected_employee_id !== 0 &&
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "#E7F0F7" }}>
                        <div style={{ display: "flex", padding: "15px 30px", alignItems: "center" }}>
                            <div style={{ fontWeight: "500" }}>Select Record:</div>
                            <div style={{ marginLeft: "10px" }}>
                                <select value={this.state.selected_employee_id} onChange={this.changeUser} className="mc-dropdown" style={{ height: "27px", padding: "0px" }}>
                                    {
                                        users.map(item => {
                                            return <option key={item.id} value={item.id}>{item.name}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div style={{ marginRight: "20px" }}>
                            <FontAwesomeIcon icon={solid('xmark')} onClick={this.closeThis} size="2x" style={{ cursor: "pointer" }} />
                        </div>
                    </div>
                }
                <BasicInfo employee_id={this.state.selected_employee_id} />
                {/* {Store.getStoreData('role') !== "team" && <AcceptanceAlert />} */}
                <AcceptanceAlert />
                <div style={{ padding: "0px 30px" }}>
                    <HorizontalTabBar roleTypes={this.state.tabs} changeTab={this.changeTab} />
                    {
                        this.state.selected_tab_id === 1 &&
                        <Roles selected_employee_id={this.state.selected_employee_id}
                            task_id={this.props.task_id}
                        />
                    }
                    {
                        this.state.selected_tab_id === 2 &&
                        <Files selected_employee_id={this.state.selected_employee_id} />
                    }
                    {
                        this.state.selected_tab_id === 3 &&
                        <CertMgs selected_employee_id={this.state.selected_employee_id} />
                    }
                </div>
            </div>
        )
    }


}

export default EmployeeDetails
