import React, { Component } from "react";
import styled from "styled-components";
import AlertBox from '../Common/AlertBox';
import CSLTable from '../Common/CSLTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import AddTopicModal from "./Modals/AddTopicModal"
import APICall from '../../Common/APICall.js'
import ExcelGenerator from '../Common/ExcelGenerator';

const MRModalHeader = styled.div`
  background-color: white;
  display: block;
  padding-top: 5px;
  font-size: 14px;
  height: 56px;
  minWidth: 100%;
  box-sizing: border-box;
`;
const MRModalHeaderText = styled.div`
  font-weight: 600;
  font-size: 20px;
  float: left;
  margin-top: 24px;
  margin-left: 35px;
  color: #1a3552;
  font-family: "Montserrat", sans-serif;
`;

const MRModalBody = styled.div`
  position: relative;
  display: block;
  box-sizing: border-box;
  background-color: #ffffff;
  padding: 35px 35px 35px 35px;
  border-radius: 4px;
`;
const ClearBoth = styled.div`
  clear: both;
`;
const TextMessage = styled.div`
  background-color: #f1f6f7;
  padding: 15px 15px 15px 10px;
  text-align: justify;
  border-radius: 3px;
  border-bottom: 1px solid #dfdfdf;
`


class TopicOverviewModal extends Component {

    constructor (props) {
        super(props);
        this.exported_data = [];
    }

    state = {
        alert_param: null,
        topics: [],
        ready: false,
        selected_topic_id: 0,
        show_topic_details_modal: false,
        qset_header: {},
        header_context_visible: false
    }

    componentDidMount() {
        let topics = []
        topics = this.props.topics
        const selected_topic_id = topics.find(item => item.selected === true)
        this.setState({ topics })
        if (selected_topic_id !== undefined) this.viewTopic(selected_topic_id.id)
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            let topics = []
            topics = this.props.topics
            const selected_topic_id = topics.find(item => item.selected === true)
            this.setState({ topics })
            if (selected_topic_id !== undefined) {
                this.viewTopic(selected_topic_id.id)
            } else {
                this.setState({ show_topic_details_modal: false })
            }
        }
    }

    toggleActive = (topic_id) => (event) => {
        event.preventDefault()
        const topic = this.state.topics.find(item => item.id === topic_id)
        topic.is_active = topic.is_active === 1 ? 0 : 1
        this.props.editTopic("is_active", topic.is_active, topic_id)
    }

    toggleContextMenu = (topic_id) => (event) => {
        event.preventDefault();
        // console.log("toggleContextMenu", role_id, row_id);
        let topics = JSON.parse(JSON.stringify(this.state.topics));
        for (let i = 0; i < topics.length; i++) {
            if (topics[i].id === topic_id) {
                topics[i].context_visible = topics[i].context_visible === true ? false : true;
                break;
            }
        }
        this.setState({ topics })
    }

    closeContextMenu = (topic_id) => (event) => {
        event.preventDefault()
        // console.log("closeContextMenu", role_id)
        let topics = JSON.parse(JSON.stringify(this.state.topics));
        for (let i = 0; i < topics.length; i++) {
            if (topics[i].id === topic_id) {
                topics[i].context_visible = false;
                break;
            }
        }
        this.setState({ topics })
    }

    showTopicEditModal = (topic_id) => (event) => {
        event.preventDefault()
        this.props.switchTopic(topic_id)
        // this.viewTopic(topic_id)
    }

    viewTopic = (topic_id) => {
        this.setState({ selected_topic_id: topic_id, show_topic_details_modal: true })
    }

    removeTopic = (topic_id) => async (event) => {
        let alert_param = null;
        event.preventDefault();
        let topics = this.state.topics;
        const topic = topics.find(item => item.id === topic_id)
        if (topic !== undefined) {
            if (topic.can_modify == 1) {
                const api = new APICall()
                const payload = { command: "topics_api", action: "remove_topic", topic_id: topic_id }
                const result = await api.commandWithoutCallback(payload);
                if (result.error_code == 0) {
                    this.props.editTopic("delete", 1, topic_id)
                }
            } else {

                alert_param = {
                    title: 'Warning', message: "This Topic cannot be removed. This Topic has been used in active Assessments that have been completed.", ok_text: 'Ok',
                    alertHandler: this.closeAlertBox, stack: {}
                }

            }
        }
        this.setState({ alert_param: alert_param, topics });
    }

    closeAlertBox = (result, stack) => {
        this.setState({ alert_param: null })
    }

    tableData = () => {
        let has_gc = JSON.parse(localStorage.getItem('has_gc'));
        let ret = { data: [], columns: [] };
        ret.columns = [
            {
                Header: 'TOPIC TITLE', accessor: 'topic_title', filterable: false, Cell: (row) => {
                    return (
                        <div style={{ cursor: 'pointer' }} onClick={this.showTopicEditModal(row.original.id)} >
                            {row.original.topic_title}
                        </div >
                    )
                }
            },
            { Header: 'DESCRIPTION', accessor: 'description', filterable: false },
            {
                Header: 'LEGAL ENTITY', accessor: 'legal_entity', filterable: false, show: has_gc,
                Cell: row => (
                    <div >
                        {(() => {
                            if (row.original.legal_entity == "0") {
                                return (
                                    <span>No Company Selected</span>
                                )
                            } else if (row.original.legal_entity.includes(",")) {
                                return (
                                    <span title={row.original.legal_entity}>Multiple</span>
                                )

                            } else {
                                return (
                                    <span title={row.original.legal_entity}>{row.original.legal_entity}</span>
                                )
                            }
                        })()}
                    </div>
                )

            },
            {
                'Header': 'APPLIES TO', filterable: false, accessor: 'applies_to', Cell: row => (
                    <div >
                        {(() => {
                            if (row.original.applies_to == "0") {
                                return (
                                    <span>No role type selected</span>
                                )
                            } else if (row.original.applies_to.includes(",")) {
                                return (
                                    <span title={row.original.applies_to}>Multiple</span>
                                )

                            } else {
                                return (
                                    <span title={row.original.applies_to}>{row.original.applies_to}</span>
                                )
                            }
                        })()}
                    </div>
                )
            },
            {
                Header: "",
                Cell: row => (
                    <div className="mc-roles-table-action-container" style={{ width: "100%", paddingRight: "10px" }}>
                        {
                            row.original.is_active === 1 ?
                                <FontAwesomeIcon className="mc-roles-add-icon" title="Edit Role Type" icon={regular('toggle-on')} onClick={this.toggleActive(row.original.id)} /> :
                                <FontAwesomeIcon className="mc-roles-add-icon" style={{ color: "#ababab" }} title="Edit Role Type" icon={regular('toggle-off')} onClick={this.toggleActive(row.original.id)} />
                        }
                        <div>
                            <FontAwesomeIcon className="mc-roles-add-icon" title="Edit Role Type" icon={regular('ellipsis-vertical')} onClick={this.toggleContextMenu(row.original.id)} />
                            {
                                row.original.context_visible &&
                                <div className="context-menu" onMouseLeave={this.closeContextMenu(row.original.id)}>
                                    <div className="context-menu-item" onClick={this.showTopicEditModal(row.original.id)}>Edit</div>
                                    <div className="context-menu-item" onClick={this.removeTopic(row.original.id)}>Remove</div>
                                </div>
                            }
                        </div>
                    </div>
                ),
                maxWidth: 70,
                filterable: false
            }
        ];

        if (this.state.topics.length !== 0) {
            for (let d of this.state.topics) {
                //let topic_json = d.topic_json === "" ? { description: "" } : JSON.parse(d.topic_json)
                let elem = {
                    topic_title: d.name,
                    description: d.description,
                    id: d.id,
                    legal_entity: this.processString(d.company_names),
                    applies_to: this.processString(d.bases),
                    is_active: d.is_active,
                    context_visible: d.context_visible,
                    can_modify: d.can_modify
                }
                ret.data.push(elem);
            }
        }
        this.exported_data = ret.data;
        return ret;
    }

    processString = (input) => {
        if (typeof input != 'undefined') {
            if (input === "0") {
                return "0";
            } else if (input.indexOf(",") !== -1) {
                const uniqueValues = Array.from(new Set(input.split(","))).join(",");
                return uniqueValues;
            } else {
                return input;
            }
        } else {
            return "0"
        }
    };

    closeAddTopicModal = () => {
        this.setState({ selected_topic_id: 0, show_topic_details_modal: false });
    }

    openCloseContexMenu = (event) => {
        event.preventDefault()
        let header_context_visible = this.state.header_context_visible === true ? false : true;
        this.setState({ header_context_visible });
    }

    editTopic = (entity, value, entity_id) => {
        this.props.editTopic(entity, value, entity_id)
    }

    closeHeaderContextMenu = (e) => {
        e.preventDefault();
        this.setState({ header_context_visible: false });
    }

    // exportTopics = (e) => {
    //     let data = this.exported_data;
    //     let modifiedArray = [];
    //     modifiedArray = data.map(({ can_modify, context_visible, id, is_active, applies_to, legal_entity, ...rest }) => {
    //         return {
    //             ...rest,
    //             is_active: is_active === 1 ? "Active" : "In-Active",
    //             applies_to: applies_to == "0" ? "No Role Type Selected" : applies_to,
    //             legal_entity: legal_entity == "0" ? "No Company Selected" : legal_entity,
    //         };
    //     });
    //     const mapper = [
    //         { "topic_title": "Topic Name" },
    //         { "description": "Description" },
    //         { "is_active": "Status" },
    //         { "applies_to": "Regime Type" },
    //         { "legal_entity": "Company Name" }
    //     ];

    //     if (modifiedArray.length > 0) {
    //         new ExcelGenerator(mapper, modifiedArray, 'Topics');
    //     } else {
    //         new ExcelGenerator(mapper, [], 'Topics');
    //     }
    // }

    exportTopics = (e) => {
        let has_gc = JSON.parse(localStorage.getItem('has_gc'));
        let data = this.exported_data;
        let modifiedArray = [];
        modifiedArray = data.map(({ can_modify, context_visible, id, is_active, applies_to, legal_entity, ...rest }) => {
            return {
                ...rest,
                is_active: is_active === 1 ? "Active" : "In-Active",
                applies_to: applies_to == "0" ? "No Role Type Selected" : applies_to,
                legal_entity: legal_entity == "0" ? "No Company Selected" : legal_entity,
            };
        });
        const mapper = [
            { "topic_title": "Topic Name" },
            { "description": "Description" },
            { "is_active": "Status" },
            { "applies_to": "Role Type" },
            { "legal_entity": "Company Name" }
        ];

        if (has_gc == false) {
            // Remove the specific column from the mapper
            const columnNameToRemove = "Company Name";
            const updatedMapper = mapper.filter(column => Object.values(column)[0] !== columnNameToRemove);
            // Remove the unwanted data from modifiedArray
            const filteredModifiedArray = modifiedArray.map(({ legal_entity, ...rest }) => rest);

            new ExcelGenerator(updatedMapper, filteredModifiedArray, 'Topics');
        } else {
            new ExcelGenerator(mapper, modifiedArray, 'Topics');
        }
    }


    render() {
        const overrideRolesRespoStyle = {
            position: 'absolute',
            backgroundColor: '#f3f0f0',
            padding: '5px 5px',
            display: 'inline-block',
            width: '150px',
            right: '7px',
            boxShadow: '0px 0px 4px rgba(0,0,0,0.4)',
            zIndex: 10000,
            boxSizing: 'border-box',
        };

        return (
            <div style={{ position: "relative" }}>
                {
                    this.state.show_topic_details_modal === true &&
                    <div className="interactive-overlay">
                        <AddTopicModal
                            topic_id={this.state.selected_topic_id}
                            closeView={this.props.closeView}
                            editTopic={this.editTopic}
                        />
                    </div>
                }
                <MRModalHeader>
                    <MRModalHeaderText>Topic Overview</MRModalHeaderText>
                    <ClearBoth />
                </MRModalHeader>
                <MRModalBody>
                    <TextMessage style={{ marginBottom: 20 }}>
                        An Assessment is made up of one or more “Topics” or areas of assessment. Topics can contain Sections which allows you to break an Assessment down into
                        manageable and identifiable parts. Topics can be restricted to only apply to certain Role Types and/or Legal Entities. Below are all the Topics
                        available to use in your Assessments.
                    </TextMessage>
                    <div className="mc-table-header-container">
                        <div style={{ fontWeight: "600", fontSize: "15px" }}>Topics</div>
                        <div style={{ display: "flex", justifyContent: "flex-end", position: 'relative' }}>
                            <FontAwesomeIcon className="mc-roles-add-icon" title="Add Topic" icon={regular('square-plus')} onClick={this.showTopicEditModal(0)} style={{ marginRight: "10px" }} />
                            <FontAwesomeIcon className="mc-roles-add-icon" icon={regular('ellipsis-vertical')} onClick={this.openCloseContexMenu} />
                            {
                                (() => {
                                    if (this.state.header_context_visible === true) return (
                                        <div style={overrideRolesRespoStyle} onMouseLeave={this.closeHeaderContextMenu}>
                                            <div className="table-context-menu-item" onClick={(e) => this.exportTopics(e)}>Export</div>
                                        </div>
                                    )
                                })()
                            }
                        </div>
                    </div>
                    <CSLTable processData={this.tableData} />
                    <AlertBox alertParam={this.state.alert_param} />
                </MRModalBody>
            </div>
        )

    }
}

export default TopicOverviewModal;
