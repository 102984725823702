import React, { Component } from 'react'
import Store from '../../../Common/Store'
import APICall from '../../../Common/APICall';
import moment from 'moment';
import CSLLoader from '../../Common/CSLLoader';
import DropdownMenu from './DropdownMenu';
import FilterIcon from './FilterIcon';
import ReloadIcon from './ReloadIcon';
import ContainerComponent from './ContainerComponent';
import { SorDataProvider } from './SorContext';
import './sor.css'




class StatementOfResponsibility extends Component {
    constructor (props) {
        super(props);
        this.EMPLOYEE_DATA = [];
        this.ROLE_TYPES = [];
        this.JOB_TITLES = [];
        this.DB_REPORT_PARAMETER = {}

    }


    state = {
        ready: false,
        isClickedFilter: true,
        isClickedReload: false,
        employee_data: [],
        selected_report_parameter: {
            firstSection: {
                personalFilter: {
                    primaryText: 'Personnel',
                    secondaryText: "All Personnel",
                    value: ""
                },
                dateFilter: {
                    primaryText: 'Date',
                    secondaryText: `As of ${moment().format('DD/MM/YYYY')}`,
                    value: `${moment().format('YYYYMMDD')}`
                },
                statusFilter: {
                    primaryText: 'Status',
                    secondaryText: "All levels(Default)",
                    value: "all"
                },
                sortByFilter: {
                    primaryText: 'Sort By',
                    secondaryText: "Forename",
                    value: "Forename"
                },
                roleTypeFilter: {
                    primaryText: 'RoleType',
                    secondaryText: "All Role Types",
                    value: ""
                },
            }, secondSection: {
                is_used_regime_color_codes: true,
                is_include_roles_respo_desc: true,
                is_show_role_effective_date: true
            },
            report_param_apply: {}
        },
        reportPrint: {
            type: 'pdf',
            orientation: 'portrait'
        }
    };


    componentDidMount() {
        this.initialiseState();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.initialiseState();
        }
    }

    initialiseState = async () => {
        let contact_ids = Store.getStoreData('regime_users');
        const postData = { command: "sor", action: 'fetch_sor', contact_ids };
        const api = new APICall();
        let result = await api.commandWithoutCallback(postData);
        await this.prepareData(result)
    }

    prepareData = async (result) => {
        console.log('prepareData called');
        let employees = result.result.employees
        let employee_description = result.result.employee_description;
        let job_titles = result.result.job_titles;
        let selected_report_parameter_db = result.result.selected_report_parameter;
        let role_types = result.result.role_types;


        if (selected_report_parameter_db != null) {
            selected_report_parameter_db = JSON.parse(selected_report_parameter_db.filter_json)
        } else {
            selected_report_parameter_db = this.state.selected_report_parameter
        }
        this.DB_REPORT_PARAMETER = JSON.parse(JSON.stringify(selected_report_parameter_db));
        this.EMPLOYEE_DATA = employees;
        this.JOB_TITLES = job_titles;
        this.ROLE_TYPES = role_types;
        let roles = result.result.roles;
        let rc = roles ? JSON.parse(JSON.stringify(roles)) : {}
        let employee_array = []
        if (employees != null) {
            if (employees.length > 0) {
                for (const employee of employees) {
                    let employee_object = {};
                    employee_object.ID = employee.ID;
                    employee_object.EmployeeName = employee.EmployeeName;
                    employee_object.JobTitle = (job_titles.find((jt) => jt.id === employee.JobtitleID) || {}).JobTitle || '';
                    employee_object.IRN = employee.IRN;
                    employee_object.FK_RegulatoryStatusID = employee.FK_RegulatoryStatusID;
                    employee_object.RegulatoryStatus = employee.RegulatoryStatus;
                    employee_object.RegulatoryStatusDate = this.formatDate(employee.RegulatoryStatusDate);
                    employee_object.Description = (employee_description.find((ed) => ed.employee_id === employee.ID) || {}).description || '';
                    employee_object.Appointed = this.formatDate((employee_description.find((ed) => ed.employee_id === employee.ID) || {}).appointed_date || '');


                    employee_object.AssignedRoleTypes = [];
                    let employee_roles = [];
                    employee_roles = rc.filter(r => r["contact_id"] == employee.ID);


                    if (employee_roles.length > 0) {
                        for (const role_type of employee_roles) {
                            let role_type_obj = employee_object.AssignedRoleTypes.find((art) => art['role_type_id'] === role_type['role_type_id']);
                            if (!role_type_obj) {
                                role_type_obj = {
                                    role_type_id: role_type['role_type_id'],
                                    RoleFunctionName: role_type['name'],
                                    RoleType: role_type['base'],
                                    RoleTypeGradient_FirstColorCode: role_type['color'],
                                    RoleTypeGradient_LastColorCode: this.convertTo20Opacity(role_type['color']),
                                    AssignedRoles: []
                                };
                                employee_object.AssignedRoleTypes.push(role_type_obj);
                            }

                            if (!role_type_obj.AssignedRoles.find((ri) => ri['role_id'] == role_type['role_id'])) {
                                let role_object = {
                                    ClientRole_Code: role_type['role_code'],
                                    ClientRole_Description: JSON.parse(role_type['role_json'])['regulator_definition'] + ' ' + JSON.parse(role_type['role_json'])['firm_definition'] + ' ',
                                    ClientRole_Title: JSON.parse(role_type['role_json'])['title'],
                                    RoleAssigned_Date: this.formatDate1(role_type['effective_till_date']),
                                    RoleFunctionName: role_type['name'],
                                    RoleType: role_type['name'],
                                    Shared_DividedComment: role_type['sr'] + ' ' + role_type['dr'] + ' ' + role_type['nr'],
                                    Is_Accepted: role_type["is_accepted"]
                                };
                                
                                if (role_type['is_shared'] === 1) {
                                    role_object.Shared_DividedWith = this.formatShareDivideWidth(role_type['shared_with_contacts']);
                                    role_object.Shared_DividedWithText = "This role is shared with";
                                }
                                role_type_obj.AssignedRoles.push(role_object);
                            }
                        }
                    }

                    employee_array.push(employee_object);

                }
            }
        }
        this.setState({
            employee_data: employee_array,
            ready: true,
            selected_report_parameter: selected_report_parameter_db,
            report_param_apply: JSON.parse(JSON.stringify(selected_report_parameter_db))
        })
    }



    formatShareDivideWidth = (str) => {
        let employees = this.EMPLOYEE_DATA;
        let jobTitles = this.JOB_TITLES;
        let formattedStr = ''
        if (str != '') {
            const numbersArray = str.split(',').map(Number);
            for (const uid of numbersArray) {

                let employee = employees.find((e) => e.ID == uid);
                let name = employee.EmployeeName;
                let jtitle = (jobTitles.find((jt) => jt.id === employee.JobtitleID) || {}).JobTitle || '';
                formattedStr += jtitle != '' ? name + ' , ' + jtitle + '</br>' : name + '</br>';
            }
        }
        return formattedStr;
    }

    formatDate = (dateString) => {
        if (!dateString) {
            return '';
        }
        const formattedDate = dateString.length == 8 ? moment(dateString, "YYYYMMDD").format("DD/MM/YYYY") : moment.utc(dateString).format('DD/MM/YYYY');
        return formattedDate;
    }

    formatDate1 = (dateString) => {
        if (!dateString) {
            return '';
        }

        dateString = String(dateString); // Convert to string

        const year = dateString.slice(0, 4);
        const month = dateString.slice(4, 6);
        const day = dateString.slice(6);

        const formattedDate = `${day}/${month}/${year}`;
        return formattedDate;
    }

    convertTo20Opacity = (hexColor) => {
        // Remove the # character from the beginning (if present)
        const hex = hexColor.replace('#', '');

        // Parse the RGB values from the hexadecimal color
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);

        // Calculate the new RGB values with 20% opacity
        const opacityFactor = 0.2;
        const newR = Math.round((1 - opacityFactor) * 255 + opacityFactor * r);
        const newG = Math.round((1 - opacityFactor) * 255 + opacityFactor * g);
        const newB = Math.round((1 - opacityFactor) * 255 + opacityFactor * b);

        // Convert the new RGB values back to hexadecimal format
        const newHexColor = `#${(newR << 16 | newG << 8 | newB).toString(16).padStart(6, '0')}`;

        return newHexColor;
    }

    updateFilter = (data) => {
        this.setState({ isClickedFilter: data })
    }

    updateFilterParameter = (obj) => {

        let reportParameters = JSON.parse(JSON.stringify(this.state.selected_report_parameter))
        if (obj.hasOwnProperty('type')) {
            let secondaryText = obj.secondaryText;
            let value = obj.value;
            reportParameters.firstSection[obj.type].secondaryText = secondaryText;
            reportParameters.firstSection[obj.type].value = value;
        } else {
            if (obj.hasOwnProperty('check')) {
                reportParameters.secondSection[Object.keys(obj.check)[0]] = obj.check[Object.keys(obj.check)[0]];
            }
        }
        this.setState({ selected_report_parameter: reportParameters })
    }

    saveFilterParameter = async () => {
        //console.log('saveFilterParameter called')
        // first check if previous filter and new filters are same or not
        if (JSON.stringify(this.state.selected_report_parameter) != JSON.stringify(this.DB_REPORT_PARAMETER)) {
            let contact_ids = Store.getStoreData('regime_users');
            let cur_date = this.state.selected_report_parameter.firstSection.dateFilter.value;
            let prev_date = this.DB_REPORT_PARAMETER.firstSection.dateFilter.value;
            let show_new_data = cur_date != prev_date;
            this.DB_REPORT_PARAMETER = JSON.parse(JSON.stringify(this.state.selected_report_parameter));
            let parameterJson = this.state.selected_report_parameter;
            const postData = { command: "sor", action: 'insert_sor_parameter', parameter: parameterJson, show_new_data, contact_ids };
            const api = new APICall();
            let result = await api.commandWithoutCallback(postData);

            if (show_new_data) {
                let res = result.result;
                this.prepareData(res);
            }
        }
        this.setState({ report_param_apply: this.state.selected_report_parameter })
    }

    updatePrintFilter = (filter) => {
        let reportPrint = JSON.parse(JSON.stringify(this.state.reportPrint));
        reportPrint.type = filter.type;
        reportPrint.orientation = filter.orientation;
        this.setState({ reportPrint });
    }


    render() {
        if (!this.state.ready) {
            return (<div><CSLLoader /></div>);
        }
        //console.log('this.state.report_param_apply', this.state.report_param_apply);
        return (
            <SorDataProvider value=
                {{
                    employee_data: this.state.employee_data,
                    showFilterOption: this.state.isClickedFilter,
                    updateFilterParameter: this.updateFilterParameter,
                    selected_report_parameter: this.state.selected_report_parameter,
                    report_param_apply: this.state.report_param_apply,
                    saveFilterParameter: this.saveFilterParameter,
                    role_types: this.ROLE_TYPES,
                    updatePrintFilter: this.updatePrintFilter
                }}>
                <div className='sor-root'>
                    <div className='sor-header'>
                        <div className='sor-header-text'>
                            <span className='lbl-header-text'>Statement of Responsibilities</span>
                        </div>
                        <div className='sor-header-filter'>
                            {/* <DropdownMenu /> */}
                            <FilterIcon getState={this.updateFilter} />
                            <ReloadIcon />
                        </div>
                    </div>
                    <div className='sor-body'>
                        <ContainerComponent />
                    </div>
                </div>
            </SorDataProvider>
        )
    }
}
export default StatementOfResponsibility;
