import React, { Component } from 'react';
import TaskTracker from './TaskTracker'
import RegimeTypeTracker from './RegimeTypeTracker';
import ReportSectionFilter from './ReportSectionFilter';
import ReportActionPanel from './ReportActionPanel';
import './sor.css'


class FilterOptionComponent extends Component {
    constructor (props) {
        super(props);
        this.state = {

        };
    }


    render() {
        return (
            <div className="filter-option show">
                <div className="filter-content">
                    <div>
                        <div class="panel-heading">
                            <h4 class="panel-title"> Filters/Options </h4>
                        </div>
                        {/* Personal , date , status , sortby columns */}
                        <TaskTracker />
                        <hr class="horizontal-line"></hr>
                        {/* Checkbox filter first */}
                        <RegimeTypeTracker />
                        <hr class="horizontal-line"></hr>
                        {/* Checkbox filter last */}
                        <ReportSectionFilter />
                        {/* Report Print Panel */}
                        <ReportActionPanel />
                    </div>
                </div>
            </div >
        );
    }
}

export default FilterOptionComponent;
