import React from 'react';
import styled from 'styled-components'
import RespoEventBusNew from './RespoEventBusNew'
import RespoUtils from './RespoUtils';

const PartialMapMsgBoxWrapper = styled.div`
							position:absolute;
							top: 65px;
							left: 40px;
							height:125px;
							-moz-box-shadow:    inset 0 0 10px #b1b1b1;
						    -webkit-box-shadow: inset 0 0 10px #6161b1;
						    box-shadow:         inset 0 0 10px #b1b1b1;
							display: block;
							border-radius: 15px;
							padding-left: 25px;
							padding-top:15px
							color: blue;
							background: white;
							z-index:500;
							width: 80%;
							`;

let path = RespoUtils.getPath();

class BranchIsolationMessage extends React.Component {
	revertToFullMap = () => {
		let respomapVisible = RespoEventBusNew.getStoreData('respomap');
		RespoEventBusNew.updateStore('max_level', 999, null, true);
		RespoEventBusNew.updateStore('respomap_visible', respomapVisible);
		RespoEventBusNew.updateStore('full_map', true);
		let loadCount = RespoEventBusNew.getStoreData('load_count');
		RespoEventBusNew.updateStore('load_count', loadCount++);
	}

	render() {
		let fullMap = RespoEventBusNew.getStoreData('full_map');
		if (fullMap) return <div></div>;
		let visibleMap = RespoEventBusNew.getStoreData('respomap_visible');
		// console.log('visibleMap', JSON.stringify(visibleMap));
		if (!visibleMap || !visibleMap.children[0] || typeof visibleMap.children[0] === undefined) return <div></div>;
		let topName = !visibleMap ? '' : visibleMap.children[0].name;
		let topTitle = !visibleMap ? '' : visibleMap.children[0].title;
		let showScreen = RespoEventBusNew.getStoreData('show_screen') === 'viewprint' ? false : true;
		let showFilter = RespoEventBusNew.getStoreData('show_filter');

		// const { name, role, title, level, job_desc, boxHeight, boxTop, color } = this.props.item;
		if (!showScreen) return <div></div>;
		return (<PartialMapMsgBoxWrapper style={{
			display: `${fullMap ? 'none' : 'block'}`,
			width: `${showFilter ? 'calc(100% - 440px)' : '90%'}`
		}}>
			<img src={`${path}csltreeactive.png`} alt="Modify tree" style={{ width: "18px", height: "auto" }} />
			&nbsp;&nbsp;&nbsp;
			<span style={{ fontHeight: `15px`, fontWeight: `700`, color: `#457BB8` }}>Branch Isolated</span><br /><br />
			<span style={{ fontHeight: `14px`, fontWeight: `400`, color: `#457BB8` }}>You have isolated the Responsibilities Map to start at </span>
			<span style={{ fontHeight: `15px`, fontWeight: `700`, color: `#457BB8` }}>{topTitle} - {topName}.</span><br /><br />
			<span style={{ fontHeight: `14px`, fontWeight: `400`, color: `#457BB8` }}><a href="#" onClick={this.revertToFullMap}>Revert to Full View</a></span>
		</PartialMapMsgBoxWrapper>);
	}

}


export default BranchIsolationMessage;
