import React, { Component } from 'react';
import ReportDisplayComponent from './ReportDisplayComponent';
import FilterOptionComponent from './FilterOptionComponent';
import SorContext from "./SorContext";
import './sor.css'


class ContainerComponent extends Component {

    static contextType = SorContext;

    render() {
        const { showFilterOption } = this.context;
        return (
            <div className="sor-container">
                <ReportDisplayComponent showFilterOption={showFilterOption} />
                {showFilterOption && <FilterOptionComponent />}
            </div>
        );
    }
}

export default ContainerComponent;
