import React, { Component } from 'react';
import Tabs from './Tabs'
import AssessmentAttestation from './AssessmentAttestation';
import AssessmentFollowUpTasks from './AssessmentFollowUpTasks';
import AssessmentAttachment from './AssessmentAttachment';
import AssessmentCertification from './AssessmentCertification';
import AssessmentRoles from './AssessmentRoles';
import AssessmentDataContext from "./AssessmentDataContext";


class AssessmentBody extends Component {
    state = {
        assessment_certificate_generated : false
    }

    componentDidMount(){
        this.setState({assessment_certificate_generated : this.props.assessment_certificate_generated});
    }

    componentDidUpdate(prevProps){
        if(this.props !== prevProps) this.setState({assessment_certificate_generated : this.props.assessment_certificate_generated});
    }
    
    static contextType = AssessmentDataContext;


    getAssessmentTopicDetails = () => {

    }

    getFollowupTasks = () => {

    }

    getAttachments = () => {

    }

    getCertifications = () => {

    }

    getRoles = () => {

    }

    render() {
        console.log("Asss Body props ===>", this.props);
        let certification_visibility = false;
        const { assessment_necessary_object, data, assessment_certificate_generated } = this.context;
        let { assignment_id, assessment_id, assessment_name } = assessment_necessary_object
        console.log('assessment_necessary_object ==>', assessment_necessary_object)
        certification_visibility = data.every(obj => obj.topic_status === "Complete" || obj.topic_status === "Reviewed") && this.state.assessment_certificate_generated === true;
        return (
            <div>
                <div>
                    <Tabs>
                        <div className='assessment-body-tab-panel' key={1} label="Assessment" visibility={true} onClick={this.getAssessmentTopicDetails}>
                            <AssessmentAttestation />
                        </div>

                        {/* <div className='assessment-body-tab-panel' key={2} label="Follow up Tasks" visibility={true} onClick={this.getFollowupTasks}>
                            <AssessmentFollowUpTasks />
                        </div>

                        <div className='assessment-body-tab-panel' key={3} label="Attachments" visibility={true} onClick={this.getAttachments}>
                            <AssessmentAttachment />
                        </div> */}

                        <div className='assessment-body-tab-panel' key={4} label="Certificate" visibility={certification_visibility} onClick={this.getCertifications}>
                            <AssessmentCertification assessment_name={assessment_name} assignment_id={assignment_id} assessment_id={assessment_id} />
                        </div>

                        {/* <div className='assessment-body-tab-panel' key={5} label="Roles" visibility={true} onClick={this.getRoles}>
                            <AssessmentRoles />
                        </div> */}
                    </Tabs>
                </div>
            </div>
        )
    }
}

export default AssessmentBody
