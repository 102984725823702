import React, { Component } from 'react';
import SorContext from "./SorContext";
import EmployeeAppointedRoles from './EmployeeAppointedRoles'
import './sor.css'


class ReportDisplayComponent extends Component {
    constructor (props) {
        super(props);
        this.divRef = React.createRef();
    }
    static contextType = SorContext;

    renderEmployees() {
        let data = [];
        const { employee_data, report_param_apply } = this.context;
        let employeeFilter = report_param_apply.firstSection.personalFilter.value;
        let roleTypeFilter = report_param_apply.firstSection.roleTypeFilter.value;
        let is_used_regime_color_codes = report_param_apply.secondSection.is_used_regime_color_codes;
        let sortByFilter = report_param_apply.firstSection.sortByFilter.value;

        if (employeeFilter == '' || employeeFilter == 'all') {
            data = employee_data;
        } else {
            data = employee_data.filter(item => employeeFilter.split(',').includes(item.ID.toString()));
        }

        if (sortByFilter !== 'all') {
            if (sortByFilter.includes('2') && sortByFilter.includes('3')) {
                data = [...data].sort((a, b) => a.EmployeeName.localeCompare(b.EmployeeName));
            } else if (sortByFilter.includes('3')) {
                // First name
                data = [...data].sort((a, b) => a.EmployeeName.split(' ')[0].localeCompare(b.EmployeeName.split(' ')[0]));
            } else if (sortByFilter.includes('2')) {
                //Last name
                data = [...data].sort((a, b) => a.EmployeeName.split(' ').pop().localeCompare(b.EmployeeName.split(' ').pop()));
            } else if (sortByFilter.includes('4')) {
                // role types and roles sort
                data = data.map(item => ({
                    ...item,
                    AssignedRoleTypes: item.AssignedRoleTypes.sort((a, b) => a.RoleType.localeCompare(b.RoleType))
                        .map(roleType => ({
                            ...roleType,
                            AssignedRoles: roleType.AssignedRoles.sort((a, b) => a.ClientRole_Code.localeCompare(b.ClientRole_Code))
                        }))
                }));
            }
        }



        return data.map((employee, index) => {
            let employee_roles = [];

            if (roleTypeFilter == '' || roleTypeFilter == 'all') {
                employee_roles = employee.AssignedRoleTypes;
            } else {
                employee_roles = employee.AssignedRoleTypes.filter(item => roleTypeFilter.split(',').includes(item.role_type_id.toString()));
            }

            employee_roles.forEach((employee) => {
                employee.AssignedRoles.sort((a, b) => {
                    const codeA = parseInt(a.ClientRole_Code.split("#")[1]);
                    const codeB = parseInt(b.ClientRole_Code.split("#")[1]);

                    if (codeA < codeB) {
                        return -1;
                    }
                    if (codeA > codeB) {
                        return 1;
                    }
                    return 0;
                });
            });



            if (!is_used_regime_color_codes) {
                employee_roles = employee_roles.map(role => ({
                    ...role,
                    RoleTypeGradient_FirstColorCode: "#000000", // Make it black
                    RoleTypeGradient_LastColorCode: "#B2B2B2", // Light gray
                }));
            }

            // roles filter
            let statusFilter = report_param_apply.firstSection.statusFilter.value;
            //console.log('data ==>', data);
            if (statusFilter != 'all') {
                if (statusFilter != '') {
                    //Accepted
                    if (statusFilter == 4) {
                        employee_roles = employee_roles.filter(item => {
                            return item.AssignedRoles.some(role => role.Is_Accepted === 1);
                        });
                    }
                    //Pending
                    else if (statusFilter == 3) {
                        employee_roles = employee_roles.filter(item => {
                            return item.AssignedRoles.some(role => role.Is_Accepted === 0);
                        });
                    }
                }
            }

            //console.log('employee role , employee name', employee.EmployeeName, employee_roles);

            if (employee_roles.length == 0) {
                return null
            }


            return <div className="employee-container" key={index}>
                <h3 className="employee-name">
                    {employee.EmployeeName} <span className="employee-job-title">{employee.JobTitle}</span>
                </h3>

                <h4 className="employee-description">
                    <b>Description</b>
                </h4>
                <p style={{ paddingRight: '3px' }} className="employee-description">
                    {employee.Description || 'Job description to be provided.'}
                </p>

                <h4 className="employee-appointed">
                    <b>Appointed {employee.Appointed}</b>
                </h4>

                <table className="employee-table">
                    <tbody>
                        <tr className="employee-row">
                            <td className="employee-irn">
                                <h4 className="employee-description">
                                    <b>IRN:</b>
                                    {employee.IRN}
                                </h4>
                            </td>
                            <td className="employee-reg-status">
                                <h4 className="employee-description">
                                    <b>Regulatory Status: </b>
                                    {employee.RegulatoryStatus}
                                </h4>
                            </td>
                            <td className="employee-reg-status-date">
                                <h4 className="employee-description">
                                    <b>as of: </b>
                                    {employee.RegulatoryStatusDate || 'N/A'}
                                </h4>
                            </td>
                        </tr>
                    </tbody>
                </table>

                {employee_roles.length > 0 && <EmployeeAppointedRoles data={employee_roles} />}
            </div>
        });
    }

    render() {
        const { showFilterOption } = this.props;
        return (
            <div
                className={`report-display ${showFilterOption ? 'with-filter' : ''}`}>
                <div id='report-display-container' style={{ minHeight: '100vh' }} className='report-container-display'>
                    {this.renderEmployees()}
                </div>
            </div>
        );
    }
}

export default ReportDisplayComponent;
