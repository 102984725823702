import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { FaRegCheckSquare, FaRegSquare } from "react-icons/fa";
import OutsideClickHandler from 'react-outside-click-handler';



const Wrapper = styled.div`
    position : relative;
    width : 100%;
    box-sizing : border-box;
`

const DropdownWrapper = styled.div`
    position : relative;
    width : 100%;
    background-color : white;
    display : flex;
    flex-direction : row;
    align-items : center;
    justify-content : space-between;
    border : 1px solid #ddd;
    padding : 5px;
    box-sizing : border-box;
`
const MultiSelectWrapper = styled.div`
    width : 94%;
    display : flex;
    flex-direction : row;
    justify-content : flex-start;
    align-items : center;
    flex-wrap : wrap;
    row-gap : 10px;
    max-height : 40px;
    overflow-y : auto;
    scroll : auto;
`
const DropdownIcon = styled.div`
    width : 3%;
    padding : 5px;
    text-align : center;
    cursor:pointer;
`

const Pill = styled.div`
    padding : 5px 5px 5px 10px;
    height : 90%;
    box-sizing : border-box;
    color : #5f6368;
    display : flex;
    flex-direction : row;
    align-items : center;
    justiy-content : space-between;
    margin : 0 2px 0 2px;
    border-radius : 5px;
`
const SearchTextBox = styled.input`
    outline : 0;
    height : 25px;
    font-size : 13px;
    border:none;
`
const ListWrapper = styled.div`
    max-height : 250px;
    overflow-y : auto;
    overflow-x : hidden;
    background-color : #fff;
    box-sizing : border-box;
    position:relative;
    z-index:100000;
    cursor:pointer;
    visibility: ${props => props.show ? 'visible' : 'hidden'};
    box-shadow: 0 0 3px #959595;
`

const ListHeader = styled.div`
    width : 100%;
    padding : 8px;
    display : flex;
    align-items : center;
    column-gap : 7px;
    border-bottom : 1px solid #ddd;
    font-weight:600;
`
const ListCell1 = styled.div`
    font-size : 13px;
    width : 35%;

`
const ListCell2 = styled.div`
    font-size : 13px;
    width : 53%;

  overflow: hidden; /* make sure it hides the content that overflows */
  white-space: nowrap; /* don't break the line */
  text-overflow: ellipsis;

`
const ListItem = styled.div`
    width : 100%;
    margin : 4px;
    padding : 4px;
    display : flex;
    align-items : center;
    column-gap : 7px;
    cursor:pointer;
`

export default function MultiColumnDropdown(props) {
    const isMultiSelect = props.isMultiSelect || false;
    const dataCopy = props.data.data_list || [];
    const [data, setData] = useState(null);
    const [allcheck, setAllCheck] = useState(false)
    const [text_box_width, setWidth] = useState(30);
    const [prev_text_length, setPrevWidth] = useState(0);
    const [dropDownToggle, setDropDownToggle] = useState(false);

    useEffect(() => {
        console.log('props.closeDrop ==>', props.closeDropDown)
        let t_data = props.data
        let i_allcheck = false
        if (Object.keys(t_data.selected_ids).length === t_data.data_list.length) i_allcheck = true
        setData(t_data);
        setAllCheck(i_allcheck);
    }, [])

    const setTextBoxWidth = (e) => {
        e.preventDefault();
        console.log("text:", e.target.value)
        let width = text_box_width;
        console.log("Width : ", width);
        if (e.target.value.length > 4 && e.target.value.length <= 30) {
            if (e.target.value.length > prev_text_length)
                setWidth(width + 7);
            else
                setWidth(width - 7);
        }
        let m_data = JSON.parse(JSON.stringify(data))
        for (let i = 0; i < m_data.data_list.length; i++) {
            if (m_data.data_list[i].assest_name.toLowerCase().includes(e.target.value.toLowerCase()) === false) {
                m_data.data_list[i].display = false
            } else {
                m_data.data_list[i].display = true
            }
        }
        setPrevWidth(e.target.value.length)
        setData(m_data)
    }

    const checkStatus = (id) => (e) => {
        e.preventDefault();
        if (isMultiSelect) {
            dropDownOperation(id);
        } else {
            if (Object.keys(data.selected_ids).length == 0) {
                dropDownOperation(id)
            }
        }

    }

    const toggleAllCheck = (e) => {
        e.preventDefault()
        let t_data = JSON.parse(JSON.stringify(data))
        let i_allcheck = allcheck === true ? false : true
        if (i_allcheck === true) {
            for (let item of t_data.data_list) {
                t_data.selected_ids[item.id.toString()] = item.code
            }
        } else {
            for (let key in t_data.selected_ids) {
                delete t_data.selected_ids[key.toString()]
            }
        }
        setData(t_data)
        setAllCheck(i_allcheck)
    }



    const toggleDropDown = (e) => {
        console.log('toggleDropDown1 called');
        console.log('e.target', e.target);
        console.log('e.currentTarget', e.currentTarget);
        setDropDownToggle(!dropDownToggle);
    };

    console.log('dropDownToggle', dropDownToggle);

    const dropDownOperation = (id) => {
        let t_data = JSON.parse(JSON.stringify(data));
        if (id.toString() in t_data.selected_ids) {
            delete t_data.selected_ids[id.toString()];
        }
        else {
            const sel_obj = t_data.data_list.find(item => item.id.toString() === id.toString());
            t_data.selected_ids[id.toString()] = sel_obj.code;
            t_data.data_list = t_data.data_list.filter((td) => {
                return td.id != id;
            });
        }
        let i_allcheck = false;
        if (Object.keys(t_data.selected_ids).length === t_data.data_list.length)
            i_allcheck = true;
        setData(t_data);
        setAllCheck(i_allcheck);
        setDropDownToggle(false)
        props.selectedId(t_data.selected_ids);
    }

    const removePill = (e, id) => {
        e.preventDefault();
        let t_data = JSON.parse(JSON.stringify(data))
        let t_data_cp = JSON.parse(JSON.stringify(dataCopy));
        const sel_obj = t_data_cp.find(item => item.id.toString() === id.toString())


        t_data.data_list.unshift(sel_obj);
        delete t_data.selected_ids[id.toString()]

        setData(t_data)
        props.selectedId(t_data.selected_ids);
    }


    if (data === null) return <></>




    return (

        <Wrapper>
            <DropdownWrapper style={{ cursor: 'pointer' }} onClick={(e) => {
                e.stopPropagation();
                toggleDropDown(e);
            }}>
                <MultiSelectWrapper>

                    {Object.keys(data.selected_ids).map((item) => (
                        <Pill key={item}>
                            {data.selected_ids[item]}
                            <MdClose
                                onClick={(e) => {
                                    e.stopPropagation();
                                    removePill(e, item);
                                }}
                                style={{ paddingLeft: "5px", fontWeight: "600" }}
                            />
                        </Pill>
                    ))}
                </MultiSelectWrapper>

                {/* Dropdown icon */}
                <DropdownIcon>
                    {dropDownToggle ? (
                        <FaAngleUp
                            style={{ width: "100%", lineHeight: "40px" }}
                        />
                    ) : (
                        <FaAngleDown
                            style={{ width: "100%", lineHeight: "40px" }}
                        />
                    )}
                </DropdownIcon>
            </DropdownWrapper>

            <OutsideClickHandler
                onOutsideClick={(e) => {
                    e.stopPropagation();
                    //setDropDownToggle(false)
                }}
            >
                <ListWrapper show={dropDownToggle}>

                    <ListHeader id="container">
                        {allcheck === true ? <FaRegCheckSquare onClick={toggleAllCheck} style={{ visibility: 'hidden', fontSize: "13px", width: "5%" }} /> : <FaRegSquare onClick={toggleAllCheck} style={{ visibility: 'hidden', fontSize: "13px", width: "5%" }} />}
                        {
                            (() => {
                                let i = 1;
                                let arr = [];
                                for (let keys in data.header_list) {
                                    if (i == 1) arr.push(<ListCell1 key={keys}>{keys}</ListCell1>)
                                    else arr.push(<ListCell2 key={keys}>{keys}</ListCell2>)
                                }
                                return (arr);
                            })()
                        }
                    </ListHeader>

                    {
                        (() => {
                            let i = 1;
                            let arr = [];
                            let keys = Object.keys(data.header_list);
                            // console.log("keys :",keys);
                            for (let item of data.data_list) {
                                if (item.display !== false) {
                                    if (keys.length === 2) {
                                        if (item.id.toString() in data.selected_ids)
                                            arr.push(
                                                <ListItem key={item.id} onClick={checkStatus(item.id)} ><FaRegCheckSquare style={{ visibility: 'hidden', fontSize: "13px", width: "5%" }} /><ListCell1>{item[data.header_list[keys[0]]]}</ListCell1><ListCell2 title={item[data.header_list[keys[1]]]}>{item[data.header_list[keys[1]]]}</ListCell2></ListItem>
                                            )
                                        else
                                            arr.push(
                                                <ListItem key={item.id} onClick={checkStatus(item.id)}><FaRegSquare style={{ visibility: 'hidden', fontSize: "13px", width: "5%" }} /><ListCell1>{item[data.header_list[keys[0]]]}</ListCell1><ListCell2 title={item[data.header_list[keys[1]]]}>{item[data.header_list[keys[1]]]}</ListCell2></ListItem>
                                            )
                                    } else if (keys.length === 1) {
                                        if (item.id.toString() in data.selected_ids)
                                            arr.push(
                                                <ListItem key={item.id} onClick={checkStatus(item.id)} ><FaRegCheckSquare style={{ visibility: 'hidden', fontSize: "13px", width: "5%" }} /><ListCell1>{item[data.header_list[keys[0]]]}</ListCell1></ListItem>
                                            )
                                        else
                                            arr.push(
                                                <ListItem key={item.id} onClick={checkStatus(item.id)}><FaRegSquare style={{ visibility: 'hidden', fontSize: "13px", width: "5%" }} /><ListCell1>{item[data.header_list[keys[0]]]}</ListCell1></ListItem>
                                            )

                                    }
                                }
                            }
                            return (arr);
                        })()
                    }

                </ListWrapper>
            </OutsideClickHandler>

        </Wrapper >

    )
}
