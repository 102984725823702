import React from 'react';
//import * as Style from '../Common/StyledComponents';
//import APICall from '../../Common/APICall.js';
//import Utils from '../../Common/Utils.js';
//import EventBus from '../../Common/EventBus.js';
import Store from '../../Common/Store.js';
//import WelcomeBanner from '../Common/WelcomeBanner';
//import DefaultHeader from '../Common/DefaultHeader';
//import SelectTeam from '../Team/SelectTeam';
//import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
//import {FaTimes} from 'react-icons/fa';
import {MdClose} from 'react-icons/md';
//import OrgUtils from '../../Common/OrgUtils.js';
//import AlertBox from '../Common/AlertBox.js'
//import CSLCheckBox from '../Common/CSLCheckBox.js'
import CSLMultiSelect from '../Common/CSLMultiSelect.js'
import Draggable from 'react-draggable';
import styled from 'styled-components';
// import AlertBox from '../Common/AlertBox.js'

const ModalContainer = styled.div`
    width : 750px;
    top : 70px;
    left : 25%;
    position : absolute;
    background-color : white;
    box-sizing : border-box;
    border-radius : 5px;
    box-shadow : 0px 0px 4px rgba(0, 0, 0, 0.4);
    border : 1px solid #d1d0d0;
    z-index: 1;
`

const Label = styled.div`
    padding : 15px 5px 5px 15px;
    color : #222222;
    font-size : 20px;
    font-weight: 600;
`
const LargeLabel = styled.div`
    padding : 10px;
    padding-left : 15px;
    color : #222222;
    font-size : 13px;
    font-weight : 600;
    
`

const GridLabel = styled.div`
    padding : 10px 10px 10px 0px;
    color : #3c3c3c;
    font-size : 16px;
    font-weight : 600;
`

const ModalHeader = styled.div`
    border-left : 7px solid #11aba6;
    background : white;
    box-sizing : border-box;
`

const ModalBody=styled.div`
    background-color : #F3F7FB;
    width : 100%;
    padding : 15px;
    box-sizing : border-box;
`

const ModalFooter=styled.div`
    height : 70px;
    background-color: #fff;
    box-sizing : border-box;
    display : flex;
    flex-direction : row;
    justify-content : flex-end;
    align-items : center;
    padding : 20px;
    border-radius : 5px;
`
const ModalCloseIcon=styled.div`
    position : absolute;
    top : 25px;
    right : 25px;
    color : #222222;
    font-size : 24px; 
`
const ModalCloseBtn=styled.button`
    background-color: #18b8a5;
    border: 1px solid #18b8a5;
    color: white;
    border-radius: 5px;
    width: 80px;
    padding: 5px 10px;
    box-sizing: border-box;
    cursor: pointer;

`

const ModalBodyFlex=styled.div`
    box-sizing : border-box;
    display : flex;
    justify-content : space-between;
    align-items : center;
    padding : 5px;
`

const ModalBodyFlexLeft=styled.div`
    width : 25%;
`
const ModalBodyFlexRight=styled.div`
    width : 65%;
`

const TriangleRight=styled.div`
    width: 0;
    height: 0;
    border-top: 18px solid transparent;
    border-left: 14px solid #555;
    border-bottom: 18px solid transparent;
`
const RoleLabel=styled.div`
    height : 36px;
    width : 200px;
    background-color : #555;
    color : white;
    font-size : 16px;
    font-weight : 600px;
    line-height : 38px;
    padding-left : 20px;
`

const RoleContainer=styled.div`
    display : flex;
    flex-direction : row;
`
// const role_colors = {
//     "SMF": '#1B3451',
//     "SMPR": '#1B3451',
//     "SMOR": '#1B3451',
//     "OTH": '#1B3451',
//     "COCO": '#24B3AD',
//     "CRR": '#F0B72E',
//     "CNF": '#11BBF2',
//     "ANC": '#CE6666',
//     "PAST": '#8A97A6'
// }


class AssignCertManagerModal extends React.Component
{
    state = {
        contacts_in_list : {},
        selected_cert_mgrs : {},
        current_user : {},
        assigned_roles : {},
        assigned_roles_backup : {}

    } 
    componentDidMount = () => {
		this.setComponentState()
	}
    setComponentState = () =>{
        console.log("This Props : ", this.props);
        let contacts = Store.getStoreData('listcontacts');
        console.log("CON : ",contacts);
        let contacts_in_list = {};
        //console.log("PROPS USER ID",this.props.user_id);
        for(let con of contacts){
            if(con.ID !== this.props.user_id) contacts_in_list[con.ID] = {id : parseInt(con.ID), name : con.ContactName};
        }
        console.log("Contacts_in_list : ", contacts_in_list);
        let assigned_roles = JSON.parse(JSON.stringify(this.props.assigned_roles));
        let assigned_roles_backup = JSON.parse(JSON.stringify(this.props.assigned_roles_backup));
        let selected_cert_mgrs = {};
        for(let arole in assigned_roles) {
            if(assigned_roles[arole].cert_mgr_id !== 0) selected_cert_mgrs[arole] = contacts_in_list[(assigned_roles[arole].cert_mgr_id).toString()];
        }
        console.log("SELECTED CERT MGRS : ", selected_cert_mgrs);
        this.setState({contacts_in_list, current_user : contacts.find(item => item.ID === this.props.user_id), selected_cert_mgrs, assigned_roles, assigned_roles_backup, ready : true});
    }
    assignCertMgr = (id, role_id = null) => {
        console.log("ID : ",id, "Role ID", role_id);
        let selected_cert_mgrs = JSON.parse(JSON.stringify(this.state.selected_cert_mgrs));
        //console.log("selected_cert_mgrs[this.current_role]",selected_cert_mgrs[this.current_role])
        selected_cert_mgrs[role_id] = {};
        selected_cert_mgrs[role_id] = this.state.contacts_in_list[id] ;
        console.log("selected_cert_mgrs", selected_cert_mgrs);
        this.setState({selected_cert_mgrs});
    }

    removeCertMgr = (id, role_id = null) => {
        console.log("ID : ",id, "Role ID", role_id);
        let selected_cert_mgrs = JSON.parse(JSON.stringify(this.state.selected_cert_mgrs));
        //console.log("selected_cert_mgrs[this.current_role]",selected_cert_mgrs[this.current_role])
        delete selected_cert_mgrs[role_id];
        console.log("selected_cert_mgrs", selected_cert_mgrs);
        this.setState({selected_cert_mgrs});
    }

    saveAssignment = (e) =>{
        e.preventDefault();
        let assigned_roles = JSON.parse(JSON.stringify(this.state.assigned_roles));
        let selected_cert_mgrs = JSON.parse(JSON.stringify(this.state.selected_cert_mgrs))
        for(let key in assigned_roles){
            if(key in selected_cert_mgrs){
                assigned_roles[key].cert_mgr_id = selected_cert_mgrs[key].id;
            }
            else{
                assigned_roles[key].cert_mgr_id = 0;
            }
        }
        for(let key in assigned_roles){
            if(assigned_roles[key].cert_mgr_id === this.state.assigned_roles_backup[key].cert_mgr_id){
                assigned_roles[key]["action"] = "nochange";
            }else if(assigned_roles[key].cert_mgr_id !== 0 && this.state.assigned_roles_backup[key].cert_mgr_id === 0){
                assigned_roles[key]["action"] = "insert";
            }else if(assigned_roles[key].cert_mgr_id !== this.state.assigned_roles_backup[key].cert_mgr_id){
                assigned_roles[key]["action"] = "update";
            }
        }
        console.log("Save Cert : ", assigned_roles);
        this.props.assignCertificationMgr(assigned_roles);
    }

    closeDialog = (e) =>{
        e.preventDefault();
        this.props.closeModal();    
    }
	render() {
        console.log("Cert Modal : ", this.state);
        if(this.state.ready !== true) return <div></div>
        return (<Draggable>
            <ModalContainer>
                <ModalHeader>
                    <Label>Assign Certification Manager to </Label>
                    <LargeLabel>{this.state.current_user.ContactName}</LargeLabel>
                    <ModalCloseIcon onClick={this.closeDialog} style={{cursor : "pointer", color: "#222222"}}><MdClose /></ModalCloseIcon>
                </ModalHeader>
                <ModalBody>
                    <ModalBodyFlex>
                        <ModalBodyFlexLeft><GridLabel>Role</GridLabel></ModalBodyFlexLeft>
                        <ModalBodyFlexRight><GridLabel>Assign to</GridLabel></ModalBodyFlexRight>
                    </ModalBodyFlex>
                    {
                        (()=>{
                            let ret = [];
                            let assigned_roles = JSON.parse(JSON.stringify(this.props.assigned_roles))
                            for(let key in assigned_roles){
                                let bgc = assigned_roles[key].role_color;
                                console.log("Seelcted : ",this.props.row_id);
                                if(this.props.row_id !== 0 && assigned_roles[key].row_id === this.props.row_id || this.props.row_id === 0)
                                ret.push(
                                    <ModalBodyFlex>
                                    <ModalBodyFlexLeft>
                                            <RoleContainer>
                                                <RoleLabel style={{backgroundColor : bgc}}>{assigned_roles[key].role_code}</RoleLabel>
                                                <TriangleRight style={{borderLeftColor : bgc}}/>
                                            </RoleContainer>
                                        </ModalBodyFlexLeft>
                                        <ModalBodyFlexRight>
                                            <CSLMultiSelect 
                                                //selectedItems={{}}
                                                selectedItems={this.state.selected_cert_mgrs[key] === undefined ? {} :{key : this.state.selected_cert_mgrs[key]} } 
                                                items={this.state.contacts_in_list}
                                                selectCallback={this.assignCertMgr}
                                                deselectCallback={this.removeCertMgr}
                                                entity={key}
                                            />
                                        </ModalBodyFlexRight>
                                    </ModalBodyFlex>
                                )
                            }
                            return ret;
                        })()
                    }             
                </ModalBody>
                <ModalFooter>
                    <ModalCloseBtn onClick={this.saveAssignment}>OK</ModalCloseBtn>
                </ModalFooter>
            </ModalContainer>
        </Draggable>)
    }
}

export default AssignCertManagerModal;


