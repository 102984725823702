import React, { Component } from 'react';
import DatePicker from 'react-date-picker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, thin, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import AssessmentDataContext from "./AssessmentDataContext";
import AssessmentCertPDFBGProcess from '../Certifications/AssessmentCertPDFBGProcess';
import ConfirmModal from "./ConfirmModal";
import APICall from "../../Common/APICall";
import AlertBox from '../Common/AlertBox.js';
import Store from '../../Common/Store.js';
import moment from 'moment'


class AssessmentCompletion extends Component {

    initialState = {
        effectiveFromDate: null,
        effectiveToDate: null,
        certification_comment: "",
        alert_param: null,

        show_reject_confirm_modal: false,
        show_reject_ok_modal: false,

        show_confirm_confirm_modal: false,
        show_confirm_ok_modal: false,

        assessment_name: "",
        assessment_user_name: "",
        errorWarning: {
            rejectModalConfirmContent: { headerText: '', bodyText: '' },
            rejectModalAlertContent: { headerText: '', bodyText: '' },
            certificateModalConfirmContent: { headerText: '', bodyText: '' },
            certificateModalAlertContent: { headerText: '', bodyText: '' }
        },
        show_certify_button: false,
        show_reject_button: false,
        disable_save_reject_certify_button: false,
        certified : false,
        is_checked_email_me: false,
        is_checked_email_employee: false
    }

    state = {
        ...this.initialState
    }
    static contextType = AssessmentDataContext;
    constructor (props) {
        super(props);

    }

    componentDidMount() {
        this.initialiseState();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.initialiseState();
        }
    }

    initialiseState = async () => {
        let user_role = Store.getStoreData('role');
        let futureDate = new Date();
        let effectiveFrom = new Date();
        const currentDate = new Date();
        let errorWarning = this.state.errorWarning;
        const { assessment_necessary_object, data } = this.context;
        //console.log('assessment_necessary_object ==>', assessment_necessary_object);
        let is_over_all_acessor = assessment_necessary_object.assessment_is_overall_acessor;
        let assessment_name = assessment_necessary_object.assessment_name;
        let assessment_user_name = assessment_necessary_object.assessment_assigned_to_name;
        let runs_every = parseInt(assessment_necessary_object.assessment_runs_every);

        let assessment_effective_from = assessment_necessary_object.assessment_effective_from;
        let assessment_effective_to = assessment_necessary_object.assessment_effective_to;

        //console.log('assessment_effective_from , assessment_effective_to', assessment_effective_from, assessment_effective_to)
        if (assessment_effective_to == 0) {
            // if (runs_every > 0) {
            // // If show frequency wise then show first one
            //     //futureDate = new Date(currentDate.setMonth(currentDate.getMonth() + runs_every));
            // // If show yeaar wise no matter what then show this one
            //     futureDate = new Date(currentDate.getTime());
            //     futureDate.setFullYear(currentDate.getFullYear() + 1);
            // }
            futureDate = new Date(currentDate.getTime());
            futureDate.setFullYear(currentDate.getFullYear() + 1);
            console.log('future date ==>', futureDate)
        } else {
            futureDate = new Date(assessment_effective_to * 1000)
        }

        if (assessment_effective_from != 0) {
            effectiveFrom = new Date(assessment_effective_from * 1000)
        } else {
            effectiveFrom = new Date()
        }
        let showCertBtn = is_over_all_acessor && data.every(obj => obj.topic_status === "Complete" || obj.topic_status === "Reviewed");

        const postData = {
            command: "assessments_details",
            action: 'get_assessment_status',
            assignment_id: assessment_necessary_object.assignment_id,
            check_file_validation: showCertBtn
        };


        const api = new APICall();
        let assessment_response = await api.commandWithoutCallback(postData);

        let is_completed = assessment_response.is_completed;
        let certification_comment = assessment_response.certification_comment;
        let is_all_files_uploaded = assessment_response.required_file_to_be_upload;
        let cur_lane = assessment_response.cur_lane;
        let rejectModalConfirmContent = {
            'headerText': `<b>Please confirm your decision</b>`,
            'bodyText': `You have choosen to <b>Reject</b> the ${assessment_name}
                        <br/>
                        Assessment of ${assessment_user_name}
                        <br/>
                        <br/>
                        Please click <b>'Confirm'</b> to record this decision
                        `
        }

        let rejectModalAlertContent = {
            'headerText': `<b>Please provide a comment</b>`,
            'bodyText': `You have choosen to <b>Reject</b> the ${assessment_name}
                        <br/>
                        Assessment of ${assessment_user_name}
                        <br/>
                        <br/>
                        Please provide a detailed comment describe why this decision has been taken
                        `
        }

        let certificateModalConfirmContent = {
            'headerText': `<b>Please confirm your decision</b>`,
            'bodyText': `You have choosen to <b>Certify</b> the ${assessment_name}
                        <br/>
                        Assessment of ${assessment_user_name}
                        <br/>
                        <br/>
                        Please click <b>'Confirm'</b> to record this decision.A certificate will be produced and can be found in certification tab.
                        `
        }
        let certificateModalAlertContent = {
            'headerText': `<b>Please provide a comment</b>`,
            'bodyText': `You have choosen to <b>Certify</b> the ${assessment_name}
                        <br/>
                        Assessment of ${assessment_user_name}
                        <br/>
                        <br/>
                        Please provide a detailed comment describe why this decision has been taken`
        }

        errorWarning['rejectModalConfirmContent'].headerText = rejectModalConfirmContent.headerText;
        errorWarning['rejectModalConfirmContent'].bodyText = rejectModalConfirmContent.bodyText;

        errorWarning['rejectModalAlertContent'].headerText = rejectModalAlertContent.headerText;
        errorWarning['rejectModalAlertContent'].bodyText = rejectModalAlertContent.bodyText;

        errorWarning['certificateModalConfirmContent'].headerText = certificateModalConfirmContent.headerText;
        errorWarning['certificateModalConfirmContent'].bodyText = certificateModalConfirmContent.bodyText;

        errorWarning['certificateModalAlertContent'].headerText = certificateModalAlertContent.headerText;
        errorWarning['certificateModalAlertContent'].bodyText = certificateModalAlertContent.bodyText;
        showCertBtn = showCertBtn && is_all_files_uploaded
        this.setState({
            certification_comment: certification_comment,
            assessment_name,
            assessment_user_name,
            errorWarning,
            show_certify_button: showCertBtn,
            disable_save_reject_certify_button: is_completed,
            show_reject_button: is_over_all_acessor == true ? true : false,
            effectiveToDate: futureDate,
            effectiveFromDate: effectiveFrom
        });
    }




    certifyAssessment = (e) => {
        e.preventDefault();
        let has_comment = document.querySelector('#final-assessment-comment').value != "";
        if (has_comment) {
            this.setState({
                show_confirm_confirm_modal: true,
                show_reject_confirm_modal: false,
                show_reject_ok_modal: false,
                show_confirm_ok_modal: false
            })
        } else {
            this.setState({
                show_confirm_ok_modal: true,
                show_confirm_confirm_modal: false,
                show_reject_confirm_modal: false,
                show_reject_ok_modal: false,
            })
        }
    }

    rejectAssessment = (e) => {
        e.preventDefault();
        let has_comment = document.querySelector('#final-assessment-comment').value != "";
        console.log("has_comment===>", has_comment)
        if (has_comment) {
            this.setState({
                show_reject_confirm_modal: true,
                show_confirm_ok_modal: false,
                show_confirm_confirm_modal: false,
                show_reject_ok_modal: false,
            })
        } else {
            this.setState({
                show_reject_ok_modal: true,
                show_reject_confirm_modal: false,
                show_confirm_ok_modal: false,
                show_confirm_confirm_modal: false,
            })
        }

    }

    cancelAssessment = (e) => {
        e.preventDefault();
        // const { data } = this.context;
        // let topics = data;
        // this.setState({ ready: false })
        // topics = topics.map(topic => ({
        //     ...topic,
        //     is_clicked: false,
        //     sections: topic.sections.map(section => ({
        //         ...section,
        //         questions: []
        //     }))
        // }));

        // topics[0]["is_clicked"] = true;
        // const { updateState } = this.context;
        // updateState(topics, topics[0].topic_id, topics[0].role_id, topics[0].role_code);

        window.location.reload();
    }

    saveAssessment = async (e) => {
        e.preventDefault();
        const { assessment_necessary_object } = this.context;
        let assessment_status = assessment_necessary_object.assessment_cur_lane
        this.performDatabaseOperation(assessment_status, 0);
    }

    confirmHandler = () => {
        this.setState({ alert_param: null });
    }

    confirmHandlerReload = () => {
        window.location.reload();
    }

    okHandlerCertified = () => {
        this.setState({ alert_param: null });
        //window.location.reload();
    }

    performDatabaseOperation = async (flag, is_certify) => {
        let alert_param = null;

        let commentData = document.querySelector('#final-assessment-comment').value;
        const { assessment_necessary_object, data } = this.context;
        let roleIds = data.map(obj => obj.role_id).join(',');
        let assignment_id = assessment_necessary_object.assignment_id;
        let assessment_id = assessment_necessary_object.assessment_id;
        let user_id = assessment_necessary_object.assessment_assigned_to_id;
        let assessment_name = assessment_necessary_object.assessment_name;

        let assessment_effective_from = moment(this.state.effectiveFromDate).unix();
        let assessment_effective_to_date = moment(this.state.effectiveToDate).unix();
        const postData = {
            command: "assessments_details",
            action: 'final_completion_assessment_data',
            assignment_id,
            assessment_id,
            user_id,
            cur_lane: flag,
            comments: commentData,
            is_certify,
            assessment_name: assessment_name,
            role_ids: roleIds,
            effective_from: assessment_effective_from,
            effective_to: assessment_effective_to_date,
            email_me: this.state.is_checked_email_me,
            email_employee: this.state.is_checked_email_employee
            //overall_acessor: assessment_necessary_object.assessment_over_all_acessor
        };

        console.log('postdata ==>', postData)

        const api = new APICall();
        let assessment_response = await api.commandWithoutCallback(postData);
        //let assessment_response = { result: { rows_effected: 1 } };

        if (assessment_response.result.rows_effected > 0) {
            if (is_certify == 1) {
                alert_param = { title: 'Success', message: "Assessment certified successfully", ok_text: 'OK', confirm: false, alertHandler: this.okHandlerCertified }
                this.setState({certified : true});
            } else {
                if (is_certify == 0 && flag == "COMP_SMCR_REJECTED") {
                    alert_param = { title: 'Success', message: "Assessment rejected successfully", ok_text: 'OK', confirm: false, alertHandler: this.confirmHandlerReload }
                } else if (is_certify == 0 && flag != "COMP_SMCR_REJECTED") {
                    alert_param = { title: 'Success', message: "Assessment saved successfully", ok_text: 'OK', confirm: false, alertHandler: this.confirmHandler }
                }
            }
        } else {
            if (is_certify == 1) {
                alert_param = { title: 'Failure', message: "Problem while certifing assessment", ok_text: 'OK', confirm: false, alertHandler: this.confirmHandler }

            } else {
                if (is_certify == 0 && flag == "COMP_SMCR_REJECTED") {
                    alert_param = { title: 'Failure', message: "Problem while rejecting assessment", ok_text: 'OK', confirm: false, alertHandler: this.confirmHandler }
                } else if (is_certify == 0 && flag != "COMP_SMCR_REJECTED") {
                    alert_param = { title: 'Failure', message: "Problem while saving assessment", ok_text: 'OK', confirm: false, alertHandler: this.confirmHandler }
                }
            }
        }
        this.setState({ show_confirm_confirm_modal: false, show_reject_confirm_modal: false, alert_param: alert_param });
    }

    editComment = (e) => {
        e.preventDefault();
        let comments = e.target.value;
        this.setState({ certification_comment: comments });
    }

    hideModalRejectOkModal = () => {
        this.setState({ show_reject_ok_modal: false })
    }
    hideConfirmOkModal = () => {
        this.setState({ show_confirm_ok_modal: false })
    }
    hideRejectConfirmModal = () => {
        this.setState({ show_reject_confirm_modal: false })
    }
    hideConfirmConfirmModal = () => {
        this.setState({ show_confirm_confirm_modal: false })
    }
    handleRejectConfirmModal = () => {
        this.performDatabaseOperation('COMP_SMCR_REJECTED', 0);
    }
    handleConfirmConfirmModal = () => {
        this.performDatabaseOperation('COMP_SMCR_CERTIFIED', 1);
        const { certifyClicked } = this.context;
        certifyClicked();
    }
    onCheckEmilMe = () => {
        let { is_checked_email_me } = this.state
        is_checked_email_me = is_checked_email_me ? false : true
        this.setState({is_checked_email_me})
    }
    onCheckEmilEmployee = () => {
        let { is_checked_email_employee } = this.state
        is_checked_email_employee = is_checked_email_employee ? false : true
        this.setState({is_checked_email_employee})
    }

    onRequestedFromDate = (date) => {
        const { updateEffectiveFromDate } = this.context;
        this.setState({ effectiveFromDate: date }, () => {
            updateEffectiveFromDate(date);
        });
    }
    onRequestedToDate = (date) => {
        const { updateEffectiveTodate } = this.context;
        this.setState({ effectiveToDate: date }, () => {
            updateEffectiveTodate(date);
        });
    }

    getCertificateGenerationStatus = async (status) => {
        const { assessmnentCertificateGenerated, activateClose } = this.context;
        if (status === true){
            assessmnentCertificateGenerated();

            const { assessment_necessary_object, data } = this.context;

            if(this.state.is_checked_email_me){
                const postData = {
                    command: "assessments_details",
                    action: 'completion_assessment_attachment_mail_me',
                    assignment_id: assessment_necessary_object.assignment_id,
                    assessment_id: assessment_necessary_object.assessment_id,
                    assessment_name: assessment_necessary_object.assessment_name,
                    email_me: this.state.is_checked_email_me,
                    email_employee: this.state.is_checked_email_employee
                };
                console.log('postdata ==>', postData)
                const api = new APICall();
                let assessment_response = await api.commandWithoutCallback(postData);
            }
            if(this.state.is_checked_email_employee){
                const postData = {
                    command: "assessments_details",
                    action: 'completion_assessment_attachment_mail_emp',
                    assignment_id: assessment_necessary_object.assignment_id,
                    assessment_id: assessment_necessary_object.assessment_id,
                    assessment_name: assessment_necessary_object.assessment_name,
                    email_me: this.state.is_checked_email_me,
                    email_employee: this.state.is_checked_email_employee
                };
                console.log('postdata ==>', postData)
                const api = new APICall();
                let assessment_response = await api.commandWithoutCallback(postData);
            }

        }
        activateClose();
    }

    render() {
        const { assessment_necessary_object } = this.context;
        return (
            <div className='assessment-completion-panel-container'>
                {this.state.show_reject_ok_modal && <ConfirmModal
                    header={this.state.errorWarning.rejectModalAlertContent['headerText']}
                    body={this.state.errorWarning.rejectModalAlertContent['bodyText']}
                    employeeName={''}
                    show={this.state.show_reject_ok_modal}
                    onHide={this.hideModalRejectOkModal}
                    showConfirm={false}
                    showCancel={false}
                    showOk={true}
                    onClose={this.hideModalRejectOkModal}
                    showIsCheckEmail={false}
                />
                }
                {this.state.show_reject_confirm_modal && <ConfirmModal
                    header={this.state.errorWarning.rejectModalConfirmContent['headerText']}
                    body={this.state.errorWarning.rejectModalConfirmContent['bodyText']}
                    employeeName={''}
                    show={this.state.show_reject_confirm_modal}
                    onHide={this.hideRejectConfirmModal}
                    onConfirm={this.handleRejectConfirmModal}
                    showConfirm={true}
                    showCancel={true}
                    showOk={false}
                    onClose={this.hideRejectConfirmModal}
                    showIsCheckEmail={false}
                />
                }
                {this.state.show_confirm_ok_modal && <ConfirmModal
                    header={this.state.errorWarning.certificateModalAlertContent['headerText']}
                    body={this.state.errorWarning.certificateModalAlertContent['bodyText']}
                    employeeName={''}
                    show={this.state.show_confirm_ok_modal}
                    onHide={this.hideConfirmOkModal}
                    showConfirm={false}
                    showCancel={false}
                    showOk={true}
                    onClose={this.hideConfirmOkModal}
                    showIsCheckEmail={false}
                />
                }
                {this.state.show_confirm_confirm_modal && <ConfirmModal
                    header={this.state.errorWarning.certificateModalConfirmContent['headerText']}
                    body={this.state.errorWarning.certificateModalConfirmContent['bodyText']}
                    employeeName={this.state.assessment_user_name}
                    show={this.state.show_confirm_confirm_modal}
                    onHide={this.hideConfirmConfirmModal}
                    onConfirm={this.handleConfirmConfirmModal}
                    showConfirm={true}
                    showCancel={true}
                    showOk={false}
                    onClose={this.hideConfirmConfirmModal}
                    showIsCheckEmail={true}
                    isCheckedEmailMe={this.state.is_checked_email_me}
                    isCheckedEmailEmployee={this.state.is_checked_email_employee}
                    onCheckEmilMe={this.onCheckEmilMe}
                    onCheckEmilEmployee={this.onCheckEmilEmployee}
                />
                }

                <div className='assessment-comp-panel'>
                    <p className='assessment-completion-header'>Assessment Completion</p>
                    <p className='assessment-completion-comment-label'>Comment</p>
                    <textarea className='comment-input-panel' value={this.state.certification_comment} onChange={(e) => this.editComment(e)} placeholder="Add final approval comments" id='final-assessment-comment'></textarea>
                    {/* Hiding for MVP */}
                    {/* <div className='assessment-completion-attachment-panel'>
                        <span className='topic-question-attachment-text-header'>Attachment</span>
                        <p className='assessment-completion-attachment-helptext'>This attachments will appear on the register entry form to assist the user</p>
                        <div style={{ marginTop: '7px' }}>
                            <div className='topic-question-attachment-container'>
                                <div className='topic-question-attachment-icon'>
                                    <span className='assessment-completion-download-image'>PDF</span>
                                </div>
                                <div className='topic-question-attachment-info'>
                                    <p className='topic-question-attachment-name'>AttachmentName.pdf</p>
                                    <p className='topic-question-attachment-date'>Added [10/01/2023]</p>
                                    <div style={{ marginTop: '12px', cursor: 'pointer' }}>
                                        <span className='topic-question-download-icon'>
                                            <FontAwesomeIcon title="download" icon={solid('download')} />
                                        </span>
                                        <span className='topic-question-download-text'>Download</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className='assessment-effective-date-panel'>
                        <p className='assessment-effective-date-panel-header'>Assessment Effective Dates</p>
                        <div className='assessment-effective-date-cal-container'>
                            <div className='assessment-effective-date-cover'>
                                <DatePicker
                                    onChange={this.onRequestedFromDate}
                                    clearIcon={null}
                                    calendarIcon={null}
                                    //calendarIcon={<FontAwesomeIcon title="Action" style={{ color: '#243E57' }} icon={solid('calendar-days')} />}
                                    locale={'en-GB'}
                                    value={this.state.effectiveFromDate == null ? new Date() : this.state.effectiveFromDate}
                                />
                            </div>
                            <div className='date-picker-seperator'>
                                -
                            </div>
                            <div className='assessment-effective-date-cover'>
                                <DatePicker
                                    onChange={this.onRequestedToDate}
                                    clearIcon={null}
                                    calendarIcon={null}
                                    //calendarIcon={<FontAwesomeIcon title="Action" style={{ color: '#243E57' }} icon={solid('calendar-days')} />}
                                    locale={'en-GB'}
                                    value={this.state.effectiveToDate == null ? new Date() : this.state.effectiveToDate}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='panel-footer-container'>
                        <div class="panel-footer">
                            <button className="panel-button panel-cancel-btn" id="cancel" onClick={(e) => this.cancelAssessment(e)}>Cancel</button>
                            <button className="panel-button panel-save-btn" disabled={this.state.disable_save_reject_certify_button} id="save" onClick={(e) => this.saveAssessment(e)}>Save</button>
                            {(this.state.show_reject_button && assessment_necessary_object.assessment_cur_lane !== 'COMP_SMCR_REJECTED') && <button className="panel-button panel-reject-btn" disabled={this.state.disable_save_reject_certify_button} id="reject" onClick={(e) => this.rejectAssessment(e)}>Reject</button>}
                            {(this.state.show_certify_button && assessment_necessary_object.assessment_cur_lane !== 'COMP_SMCR_REJECTED') && <button className="panel-button panel-certify-btn" disabled={this.state.disable_save_reject_certify_button} id="certify" onClick={(e) => this.certifyAssessment(e)}>Certify</button>}
                        </div>

                    </div>
                    <div style={{ height: '15px' }}></div>
                    {this.state.certified === true && <AssessmentCertPDFBGProcess assessment_name={this.context.assessment_necessary_object.assessment_name} assessment_id={this.context.assessment_necessary_object.assessment_id} assignment_id={this.context.assessment_necessary_object.assignment_id} getCertificateGenerationStatus={this.getCertificateGenerationStatus}/>}
                </div>
                <AlertBox alertParam={this.state.alert_param} />
            </div >
        )

    }
}

export default AssessmentCompletion
