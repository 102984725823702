import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import APICall from "../../Common/APICall";
import { saveAs } from 'file-saver';


class AttachmentFiles extends Component {
    state = {
        activeContextMenuIndex: -1
    };

    handleContextMenuOpen = (event, index) => {
        event.preventDefault();
        this.setState({ activeContextMenuIndex: index });
    };

    handleContextMenuClose = () => {
        this.setState({ activeContextMenuIndex: -1 });
    };

    handleDownload = async (file) => {
        let name = file.name;
        const payload = {
            command: "assessments_details", action: 'download_assessment_question_file',
            ref_id: file.add_ref_id
        }

        const api = new APICall();
        let files_response = await api.commandWithoutCallback(payload);
        let content = files_response.binFileContent;
        if (content != "" || content != null) {
            this.downloadBase64File(content, name)
        }
    }

    downloadBase64File = (base64String, filename) => {
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/octet-stream' });
        saveAs(blob, filename);
    }

    handleDelete = (file) => {
        let { add_ref_id, attach_id } = file;
        this.props.deleteFile(add_ref_id, attach_id);
    }

    render() {
        const { files } = this.props;
        const { activeContextMenuIndex } = this.state;

        return (
            <div>
                <h2>List of Files</h2>
                <div className="attachment-file-tiles">
                    {files.map((file, index) => (
                        <div
                            key={index}
                            className="attachment-file-tile"
                            onMouseDown={(e) => this.handleContextMenuOpen(e, index)}
                            onMouseLeave={this.handleContextMenuClose}
                        >
                            <FontAwesomeIcon className="mc-roles-add-icon file-context-elipse" title="Files action" icon={regular('ellipsis-vertical')} />
                            {activeContextMenuIndex === index && (
                                <div className="attachment-context-menu">
                                    <button onClick={() => this.handleDownload(file)}>Download</button>
                                    <button onClick={() => this.handleDelete(file)}>Delete</button>
                                </div>
                            )}
                            <p className="attachment-file-name">{file.name}</p>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default AttachmentFiles;
