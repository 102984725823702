import React from "react";
import APICall from "../../../Common/APICall";
import CSLTable from "../../Common/CSLTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import AlertBox from '../../Common/AlertBox';


class AssessmentRoleTypes extends React.Component {

    state = { assessment_role_types: {}, role_types: [], alert_param: null, }

    componentDidMount() {
        this.setComponentState()
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) this.setComponentState()
    }

    setComponentState = async () => {
        const payload1 = { command: "assessments", action: "list_roletypes" }
        const payload2 = { command: "assessments", action: "list_assessment_role_types", assessment_id: this.props.assessment_id }
        const api = new APICall()
        const [role_types_result, assessment_role_type_result] = await Promise.all([api.commandWithoutCallback(payload1), api.commandWithoutCallback(payload2)])
        let assessment_role_types = {}
        let role_types = []
        if (role_types_result.error_code === 0) {
            role_types = role_types_result.result.role_types
        }
        if (assessment_role_type_result.error_code === 0) {
            if (assessment_role_type_result.result.length !== 0) {
                const list = assessment_role_type_result.result
                for (let item of list) {
                    assessment_role_types[item.role_type_id.toString()] = 1
                }
            }
        }
        this.setState({ assessment_role_types: assessment_role_types, role_types })
    }

    toggleActive = (role_type_id, is_active) => async (event) => {

        event.preventDefault();

        let idsNotPresent = [];
        let alert_param = null;
        let assessment_role_types = JSON.parse(JSON.stringify(this.state.assessment_role_types));

        if (is_active == 1) {
            // Retrieve IDs not present in assessment_role_types
            let in_active_ids = this.state.role_types.filter(role => !(role.id.toString() in assessment_role_types)).map(role => role.id);

            if (in_active_ids.length > 0) {
                idsNotPresent.push(in_active_ids);
            }


            if (!idsNotPresent.includes(role_type_id)) {
                idsNotPresent.push(role_type_id)
            }

            idsNotPresent = idsNotPresent.join(', ');


            let payload = { command: "assessments", action: 'check_for_role_type_validation', role_type_id: idsNotPresent, assessment_id: this.props.assessment_id }
            const api = new APICall()
            const result = await api.commandWithoutCallback(payload);

            let affectedTopics = result.result;
            if (affectedTopics != null) {
                let affected_topic_ids = [];
                if (affectedTopics.length > 0) {

                    let msg = `<div>
                                Removing the Role Type(s) will invalidate the use of the
                               following Topics within the Assessment.Please check and press
                               confirm to proceed.
                               <div style='margin-top:15px'>`;
                    affectedTopics.forEach((topic) => {
                        affected_topic_ids.push(topic.topic_id)
                        msg += `<div style='text-align:left;margin-left:24px;margin-bottom:5px'>
                                    ${topic.name}
                                </div>`;
                    });
                    msg += '</div></div>'
                    alert_param = {
                        title: 'Please confirm your updates',
                        html_message: msg,
                        ok_text: 'Confirm',
                        cancel_text: 'Cancel',
                        confirm: true,
                        body_align: 'left',
                        alertHandler: this.handleClientRequest,
                        stack: {
                            topic_ids: affected_topic_ids,
                            role_type_id: role_type_id
                        }
                    }
                } else {
                    let payload = { command: "assessments", role_type_id: role_type_id, assessment_id: this.props.assessment_id }
                    if (role_type_id.toString() in assessment_role_types === true) {
                        delete assessment_role_types[role_type_id.toString()]
                        payload.action = "remove_role_type_assessment_association"
                    } else {
                        assessment_role_types[role_type_id.toString()] = 1
                        payload.action = "add_role_type_assessment_association";
                    }

                    const api = new APICall()
                    const result = await api.commandWithoutCallback(payload)
                }
            }
        }
        else {
            let payload = { command: "assessments", role_type_id: role_type_id, assessment_id: this.props.assessment_id }
            if (role_type_id.toString() in assessment_role_types === true) {
                delete assessment_role_types[role_type_id.toString()]
                payload.action = "remove_role_type_assessment_association"
            } else {
                assessment_role_types[role_type_id.toString()] = 1
                payload.action = "add_role_type_assessment_association";
            }

            const api = new APICall()
            const result = await api.commandWithoutCallback(payload)
        }
        this.setState({ assessment_role_types: assessment_role_types, alert_param })
    }



    handleClientRequest = async (status, stack) => {

        let assessment_role_types = JSON.parse(JSON.stringify(this.state.assessment_role_types));
        if (status === true) {
            let topic_ids = stack.topic_ids;
            topic_ids = topic_ids.join(',');

            let role_type_ids = stack.role_type_id;


            let payload = { command: "assessments", action: 'remove_role_type_and_topics', role_type_id: role_type_ids, assessment_id: this.props.assessment_id, topic_ids: topic_ids }
            const api = new APICall()
            const result = await api.commandWithoutCallback(payload)
            console.log('result innside handleClientRequest()', result);
            if (result.error_code == 0) {
                delete assessment_role_types[role_type_ids.toString()]
            }
        }
        this.setState({ alert_param: null, assessment_role_types: assessment_role_types })
    }



    toggleActive1 = (role_type_id, is_active) => async (event) => {
        event.preventDefault();

        let alert_param = null;
        let assessment_role_types = { ...this.state.assessment_role_types };

        if (is_active == 1) {
            let payload = { command: "assessments", action: 'check_for_role_type_validation', role_type_id, assessment_id: this.props.assessment_id };
            const api = new APICall();
            const result = await api.commandWithoutCallback(payload);

            let affectedTopics = result.result;
            if (affectedTopics != null && affectedTopics.length > 0) {
                let msg = `
                            Removing the Role Type(s) will invalidate the use of the
                            following Topics within the Assessment. Please check and press
                            confirm to proceed.
                        `;
                affectedTopics.forEach((topic) => {
                    msg += `<br/></br><div style='text-align:left;margin-left:24px;margin-bottom:5px'>${topic.name}</div>`;
                });

                alert_param = { title: 'Please confirm your updates', message: msg, ok_text: 'OK', confirm: false, alertHandler: this.confirmHandler };
            } else {
                assessment_role_types[role_type_id.toString()] = 1;
            }
        } else {
            assessment_role_types[role_type_id.toString()] = 1;
        }

        let payload = { command: "assessments", role_type_id, assessment_id: this.props.assessment_id };
        payload.action = (role_type_id.toString() in assessment_role_types) ? "remove_role_type_assessment_association" : "add_role_type_assessment_association";

        const api = new APICall();
        await api.commandWithoutCallback(payload);

        this.setState({ assessment_role_types, alert_param });
    }


    confirmHandler = () => {
        this.setState({ alert_param: null });
    }

    tableData = () => {
        let ret = { data: [], columns: [] }
        ret.columns = [
            { Header: 'REGIME TYPE', accessor: 'role_type', filterable: false },
            { Header: 'REGIME REFERENCE', accessor: 'base', filterable: false, maxWidth: 250 },
            {
                Header: "",
                Cell: row => (
                    <>
                        {
                            row.original.is_active === 1 ?
                                <FontAwesomeIcon className="mc-roles-add-icon" icon={regular('toggle-on')} onClick={this.toggleActive(row.original.id, row.original.is_active)} /> :
                                <FontAwesomeIcon className="mc-roles-add-icon" style={{ color: "#ababab" }} icon={regular('toggle-off')} onClick={this.toggleActive(row.original.id, row.original.is_active)} />
                        }
                    </>
                ),
                maxWidth: 50,
                filterable: false
            }
        ]
        if (this.state.role_types.length !== 0) {
            for (let item of this.state.role_types) {
                let is_active = item.id.toString() in this.state.assessment_role_types === true ? 1 : 0
                let elem = { id: item.id, role_type: item.name, base: `${item.base}#`, color: item.color, is_active }
                ret.data.push(elem)
            }
        }
        return ret
    }

    render() {
        console.log('this.state.role_types', this.state.role_types)
        return (
            <div style={{ marginTop: "25px" }}>
                <AlertBox alertParam={this.state.alert_param} />
                <CSLTable processData={this.tableData} />
            </div>
        )
    }
}

export default AssessmentRoleTypes
