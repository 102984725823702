import React from 'react';
import MicroserviceHeader from './MicroserviceHeader.js';
import styled from 'styled-components';
import { FaHome } from 'react-icons/fa'
import RespoEventBusNew from './RespoEventBusNew';
import RespoUtils from './RespoUtils.js';

const DashboardButton = styled.div`
							float: right;
							color: #bdbdbd ! important;
							padding-right: 6px;
							padding-left: 6px;
							padding-top: 6px;
							padding-bottom: 1px;
							border-radius: 4px;
							border-style: solid;
							border-color: white;
							border-width: 1px;
							cursor: pointer;
							background-color: #eaeaea ! important;
							font-size: 14px;
							`;
const MicroserviceHeaderText = styled.div`
									padding-top: 7px;
									display: inline-block;
									font-family: 'Montserrat', sans-serif;
									`;

let path = RespoUtils.getPath();

class RespomapHeader extends React.Component {
	state = { show_orientation: false, orientation: "portrait", "load_count": 0 };
	showPrintPreview() {
		RespoEventBusNew.updateStore('show_screen', 'viewprint');
		RespoEventBusNew.updateStore('show_filter', false);
		RespoEventBusNew.updateStore('orientation', 'portrait');
		this.setState({ show_orientation: true });
	}
	toggleOrientation() {
		console.log('this.state.orientation in toggle enter', this.state.orientation);
		if (RespoEventBusNew.getStoreData('orientation') === "portrait") {
			RespoEventBusNew.updateStore('orientation', "landscape");
		} else {
			RespoEventBusNew.updateStore('orientation', "portrait");
		}
		console.log('this.state.orientation in toggle', this.state.orientation);
		this.setState({ load_count: this.state.load_count + 1 })
	}
	showScreen() {
		RespoEventBusNew.updateStore('show_screen', 'screen');
		this.setState({ show_orientation: false });
	}
	showOptions() {
		RespoEventBusNew.updateStore('show_filter', true);
	}
	render() {
		let orientation = RespoEventBusNew.getStoreData('orientation');
		if (this.state.show_orientation && orientation === "portrait") {
			return (
				<MicroserviceHeader>
					<MicroserviceHeaderText><a href="/"><FaHome /></a>   My Compliance Centre / <b>Responsibilities Map/Matrix</b></MicroserviceHeaderText>
					<DashboardButton onClick={this.toggleOrientation.bind(this)}><img src={`${path}landscape-grey.png`} alt="Go to Landscape view" style={{ width: "auto", height: "20px" }} /></DashboardButton>
					<DashboardButton onClick={this.showScreen.bind(this)}><img src={`${path}dekstop-grey.png`} alt="screen view" style={{ width: "auto", height: "20px" }} /></DashboardButton>
				</MicroserviceHeader>
			);
		} else if (this.state.show_orientation && orientation === "landscape") {
			return (
				<MicroserviceHeader>
					<MicroserviceHeaderText><a href="/"><FaHome /></a>   My Compliance Centre / <b>Responsibilities Map/Matrix</b></MicroserviceHeaderText>
					<DashboardButton onClick={this.toggleOrientation.bind(this)}><img src={`${path}portrait-grey.png`} alt="Go to portrait view" style={{ width: "auto", height: "20px" }} /></DashboardButton>
					<DashboardButton onClick={this.showScreen.bind(this)}><img src={`${path}dekstop-grey.png`} alt="screen view" style={{ width: "auto", height: "20px" }} /></DashboardButton>
				</MicroserviceHeader>
			);
		} else {
			return (
				<MicroserviceHeader>
					<MicroserviceHeaderText><a href="/"><FaHome /></a>   My Compliance Centre / <b>Responsibilities Map/Matrix</b></MicroserviceHeaderText>
					<DashboardButton onClick={this.showPrintPreview.bind(this)}><img src={`${path}printer-grey.png`} alt="pdf view" style={{ width: "auto", height: "20px" }} /></DashboardButton>
					<DashboardButton onClick={this.showOptions.bind(this)}><img src={`${path}filter-grey.png`} alt="filter" style={{ width: "auto", height: "20px" }} /></DashboardButton>
				</MicroserviceHeader>
			);
		}
	}
}

export default RespomapHeader;
