import React from 'react';
import styled from 'styled-components';
import Store from './../../../Common/Store';

const HeaderText = styled.div`
    padding:12px 24px 12px 24px;
    margin-bottom: 10px;
    color: #212121;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 22px;
    color: #4D4D68;
`;

const HeaderDiv = styled.div`
    padding:9px;
    background-color: white;
`;


class HeaderRow extends React.Component {
	render() {
		return (
				<div style={{width: '100%', backgroundColor: '#203764', color: 'white', display: 'block', padding: "4px 0px 4px 4px"}}>
					<div style={{width: 'calc(20% - 10px)', paddingLeft: 10, display: 'inline-block', wordWrap: "break-word"}}><strong>{this.props.row.row.name}</strong></div>
					<div style={{width: 'calc(20% - 10px)', paddingLeft: 10, display: 'inline-block'}}>Members</div>
					<div style={{width: 'calc(20% - 10px)', paddingLeft: 10, display: 'inline-block'}}>Attendees</div>
					<div style={{width: 'calc(40% - 10px)', paddingLeft: 10, display: 'inline-block'}}>Purpose and Remit</div>
				</div>
			)
	}
}
class CommitteeRow extends React.Component {
	render() {
		// let color = this.props.even ? 
		return (
				<div style={{width: '100%', backgroundColor: '#ffff', color: '#63758A', borderBottomStyle: 'solid', borderWidth: 2, borderColor: '#111199', paddingTop: 4}}>
					<div style={{width: 'calc(20% - 10px)', paddingLeft: 10, float: 'left', paddingTop: 3, paddingBottom: 3, wordWrap: "break-word"}}><strong>{this.props.row.row.name}</strong></div>
					<div style={{width: 'calc(20% - 10px)', paddingLeft: 10, float: 'left', paddingTop: 3, paddingBottom: 3}}>
					{
						this.props.row.row.members.map((item, i) => {
							return (
									<div style={{width: '100%', fontSize: 12}} key={i}>
										{item.employeename}
									</div>
								)
						})
					}
					</div>
					<div style={{width: 'calc(20% - 10px)', paddingLeft: 10, float: 'left', paddingTop: 3, paddingBottom: 3}}>
					{
						this.props.row.row.attendees.map((item, i) => {
							return (
									<div style={{width: '100%', fontSize: 12}} key={i}>
										{item.thirdpartyembername}
									</div>
								)
						})
					}
					</div>
					<div style={{width: 'calc(40% - 10px)', paddingLeft: 10, float: 'left', paddingTop: 3, paddingBottom: 3, wordWrap: "break-word"}}>{this.props.row.row.purpose}</div>
					<div style={{clear: 'both'}} />
				</div>
			)
	}
}


class GovmapTable extends React.Component {
	state = {
		ready: false,
		gov_committees: null,
		members: null,
		show_role_desc: false,
		root_user_id: '0',
		max_level: 100000
	}

	constructor(props) {
		super(props);
		this.initBBHt = 80
		this.bHeaderHt = 60
		this.bWidth = 550
		this.hGap = 100
		this.vGap = 30
		this.currentY = 0
		this.rolesPerLine = 6
		this.lineHeight = 15
		this.maxLevel = 0
		this.committees_tab = []
	}

	setComponentState = () => {
		let gov_committees = Store.getStoreData('gov_committees')
		let members = Store.getStoreData('members')
		this.currentY = 0
		this.committees_tab = []
		if(gov_committees !== null) {
			for(let gm of gov_committees.children) {
				this.measureGovmap(gm, -1)
			}
		}
		
		console.log('committees_tab gov_committees', gov_committees)
		// console.log('committees_tab members', members)
		console.log('committees_tab this.committees_tab', this.committees_tab)
		// console.log('after measureRespomap respomap', respomap)
		Store.updateStore('committees_tab', this.committees_tab)
		this.setState({ready:true, gov_committees: gov_committees})
	}

	componentDidMount = () => {
		this.setComponentState()
	}

	componentDidUpdate = (prevprops) => {
		if(this.props === prevprops)return
		this.setComponentState()
	}

	measureGovmap = (committee, level) => {
		console.log('committees_tab committee level', committee, level)
		if(committee.children.length > 0) {
			let row = {is_header: true, row: {}}
			row.row.name = committee.json_data.committeename
			row.row.members = 'Members'
			row.row.attendees = 'Attendees'
			row.row.purpose = 'Purpose and Remit'
			this.committees_tab.push(row)
			if(level < 0) {
				let com_row = {is_header: false, row: {}}
				com_row.row.name = committee.json_data.committeename
				com_row.row.members = committee.json_data.committeemembers
				com_row.row.attendees = committee.json_data.thirdpartyembers
				com_row.row.purpose = committee.json_data.perposeofcommitee
				this.committees_tab.push(com_row)
			}
		}
		for(let c of committee.children) {
			let com_row = {is_header: false, row: {}}
			com_row.row.name = c.json_data.committeename
			com_row.row.members = c.json_data.committeemembers
			com_row.row.attendees = c.json_data.thirdpartyembers
			com_row.row.purpose = c.json_data.perposeofcommitee
			this.committees_tab.push(com_row)
		}
		for(let c of committee.children) {
			this.measureGovmap(c, level + 1)
		}
		if(committee.parent_id === 0 && committee.children.length === 0) {
			let row = {is_header: true, row: {}}
			row.row.name = committee.json_data.committeename
			row.row.members = 'Members'
			row.row.attendees = 'Attendees'
			row.row.purpose = 'Purpose and Remit'
			this.committees_tab.push(row)
			if(level < 0) {
				let com_row = {is_header: false, row: {}}
				com_row.row.name = committee.json_data.committeename
				com_row.row.members = committee.json_data.committeemembers
				com_row.row.attendees = committee.json_data.thirdpartyembers
				com_row.row.purpose = committee.json_data.perposeofcommitee
				this.committees_tab.push(com_row)
			}
		}
	}

	mapFindById = (find_id, respomap) => {
        for(let m of respomap) {
        	if(find_id === m.id)return m
            let found_member = this.mapFindById(find_id, m.children);
            if(found_member !== null) return found_member;
        }
        return null;
    }

	render() {
		console.log('GovmapReport this.state in render', this.committees_tab)
		if(!this.state.ready)return (<div></div>)
		if(this.state.gov_committees === null)return (<div></div>)
		return (<div>
					<HeaderDiv>
						<HeaderText>Boards and Committees</HeaderText>
						<div style={{position: 'relative', height: 600, marginLeft: 12, overflowY: 'scroll'}}>
						{
							this.committees_tab.map((row, i) => {
								if(row.is_header) {
									return <HeaderRow row={row} key={i} even={i%2 === 0 ? true : false} />
								} else {
									return <CommitteeRow  row={row} key={i} even={i%2 === 0 ? true : false} />
								}
							})
						}
						</div>
					</HeaderDiv>
				</div>
			)
	}
}

export default GovmapTable;