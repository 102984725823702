import http from 'mcc-front-aux/dist/httpCommon'

class AssignedAssessmentService {
    baseUrl = '/microservice/smcr/api/assigned-assessment'

    async update(assignmentId, dto) {
        const data = await http.put(`${this.baseUrl}/${assignmentId}`, dto)
        return data
    }
}

export default new AssignedAssessmentService()
