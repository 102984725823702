import React from 'react';
import './SearchableCombo.css'
import APICall from "../../Common/APICall";


class SearchableComboBox extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            searchValue: '',
            options: [],
            filteredOptions: [],
            showOptions: false,
        };
    }

    componentDidMount() {
        const { options, selectedOptionId } = this.props;
        this.setState({ options });
        if (selectedOptionId) {
            const selectedOption = options.find(option => option.id === selectedOptionId);
            if (selectedOption) {
                this.setState({ searchValue: selectedOption.name });
                this.props.onChange(selectedOption);
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.options !== this.props.options) {
            this.setState({ options: this.props.options });
        }
        if (
            prevState.searchValue !== this.state.searchValue ||
            prevState.options !== this.state.options
        ) {
            this.filterOptions();
        }
    }


    handleInputChange = (event) => {
        this.setState({ searchValue: event.target.value });
    };

    handleInputClick = () => {
        this.setState({ showOptions: true });
    };

    handleOptionClick = (option) => {
        this.setState({
            searchValue: option.name,
            showOptions: false,
        });
        this.props.onChange(option);
    };

    filterOptions = () => {
        const { options, searchValue } = this.state;
        const filteredOptions = options.filter((option) =>
            option.name.toLowerCase().includes(searchValue.toLowerCase())
        );
        this.setState({ filteredOptions });
    };

    handleKeyDown = async (event) => {
        const { filteredOptions } = this.state;
        if (event.key === 'Enter' && this.state.searchValue !== '') {
            if (filteredOptions.length === 0) {
                const newOption = { name: this.state.searchValue };
                let job_title_text = newOption.name;
                // Add new option to database
                const payload = { command: "personnel", action: 'add_job_title', job_title_text }
                const api = new APICall()

                const resp = await api.commandWithoutCallback(payload);
                console.log('result ==> inside searchable component', resp);
                // Update options state
                this.setState({
                    options: [...this.state.options, resp],
                    searchValue: resp.name,
                    showOptions: false,
                });
                this.props.onChange(resp);
            } else {
                // Select the first filtered option
                this.setState({
                    searchValue: filteredOptions[0].name,
                    showOptions: false,
                });
                this.props.onChange(filteredOptions[0]);
            }
        } else if (event.key === 'Escape') {
            this.setState({ showOptions: false });
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            this.moveSelection(-1);
        } else if (event.key === 'ArrowDown') {
            event.preventDefault();
            this.moveSelection(1);
        } else if (event.key === 'Tab' && filteredOptions.length > 0) {
            event.preventDefault();
            this.setState({
                searchValue: filteredOptions[0].name,
                showOptions: false,
            });
            this.props.onChange(filteredOptions[0]);
        }
    };

    moveSelection = (step) => {
        const { filteredOptions } = this.state;
        const selectedIndex = filteredOptions.findIndex(
            (option) => option.name === this.state.searchValue
        );
        const nextIndex = selectedIndex + step;
        if (nextIndex >= 0 && nextIndex < filteredOptions.length) {
            this.setState({
                searchValue: filteredOptions[nextIndex].name,
            });
        }
    };

    handleMouseLeave = () => {
        this.setState({ showOptions: false });
    };

    handleMouseDown = () => {
        //this.setState({ showOptions: false });
    };

    render() {
        const { searchValue, filteredOptions, showOptions } = this.state;
        const { placeholder, notFoundContent } = this.props;

        return (
            <div className="searchable-combo-box" onMouseLeave={this.handleMouseLeave}>
                <input
                    type="text"
                    className="searchable-combo-box-input"
                    value={searchValue}
                    placeholder={placeholder}
                    onChange={this.handleInputChange}
                    onClick={this.handleInputClick}
                    onKeyDown={this.handleKeyDown}
                />
                {showOptions && (
                    <ul className="searchable-combo-box-options">
                        {filteredOptions.length > 0 ? (
                            filteredOptions.map((option) => (
                                <li
                                    key={option.id || option.name}
                                    className="searchable-combo-box-option"
                                    onClick={() => this.handleOptionClick(option)}
                                    onMouseDown={() => this.handleMouseDown(option)}
                                >
                                    {option.name}
                                </li>
                            ))
                        ) : (
                            <li className="searchable-combo-box-option not-found">
                                {notFoundContent}
                            </li>
                        )}
                    </ul>
                )}
            </div>
        )
    }
}

export default SearchableComboBox
