import React from 'react'
import APICall from '../../../Common/APICall'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import "./ModalStyle.css"
import BulkModal from './BulkModal.js'

class QuestionModal extends React.Component {

    constructor (props) {
        super(props);
        this.rteChange = this.rteChange.bind(this);
        this.myRef = React.createRef()
    }

    state = {
        question: null,
        binFiles: [],
        isLoadedIcon: false,
        isExitingfilesRemove: false,
        alert_param: null,
        modal_view: null,
        documentdetails: [],
        documentversion: [],
    }

    componentDidMount() {
        this.setComponentState();
    }

    setComponentState = async () => {
        let question = null
        if (this.props.question_id !== 0) {
            const payload = { command: "topics_api", action: "get_question", question_id: this.props.question_id }
            const api = new APICall()
            const result = await api.commandWithoutCallback(payload)
            if (result.error_code === 0) {
                question = result.result
            }
            question.question_json = JSON.parse(question.question_json)
            question.question_json.text = question.question_json.text === "" ? question.question_json.text : window.decodeURIComponent(atob(question.question_json.text))
        } else {
            question = {
                id: 0,
                name: "",
                section_id: this.props.section_id,
                topic_id: this.props.topic_id,
                question_json: {
                    text: "",
                    allow_na: 0,
                    correct_answer: "Yes",
                    flagged_opt: "incorrect",
                    req_attach: "optional",
                    req_comment: 'optional',
                    question_attachment: []
                },
                is_active: 1,
                is_deleted: 0
            }
        }

        // btoa(window.encodeURIComponent(qText));
        this.setState({ question })
    }

    handleChange = (position) => (event) => {
        event.preventDefault()
        let question = JSON.parse(JSON.stringify(this.state.question))
        if (position === "outer") {
            question[event.target.name] = event.target.value
        } else {
            question.question_json[event.target.name] = event.target.value
        }
        this.setState({ question })
    }

    rteChange = (content, delta, source, editor) => {
        console.log(editor.getHTML()); // HTML/rich text
        console.log(editor.getText()); // plain text
        console.log(editor.getLength()); // number of characters
        console.log(content);
        let question = JSON.parse(JSON.stringify(this.state.question))
        question.question_json.text = editor.getHTML()
        this.setState({ question })
    }

    toggleActive = (event) => {
        event.preventDefault()
        let question = JSON.parse(JSON.stringify(this.state.question))
        question.is_active = question.is_active === 1 ? 0 : 1
        this.setState({ question })
    }

    toggleNa = (event) => {
        event.preventDefault()
        let question = JSON.parse(JSON.stringify(this.state.question))
        question.question_json.allow_na = question.question_json.allow_na === 1 ? 0 : 1
        this.setState({ question })
    }

    closeModal = (event) => {
        event.preventDefault()
        this.props.closeModal()
    }

    filesTobeAdded = (files) => {
        console.log('files', files);
        this.setState({ binFiles: files });
    }

    saveQuestion = async (event) => {
        event.preventDefault()
        let question = JSON.parse(JSON.stringify(this.state.question));

        if (question.question_json.text !== "") {
            question.question_json.text = btoa(window.encodeURIComponent(question.question_json.text))
        }


        let postData = null;
        let add_ref_id = "";
        let new_files = this.state.binFiles.filter((b) => b["is_new"] == 1);
        if (question.question_json.hasOwnProperty('question_attachment')) {
            if (question.question_json.question_attachment.length > 0) {
                add_ref_id = question.question_json.question_attachment[0]["add_ref_id"];
            }
        }

        question.question_json = JSON.stringify(question.question_json);

        if (new_files.length > 0) {
            new_files = new_files.map(item => {
                return { ...item, is_new: 0 };
            });

            if (add_ref_id == "") {
                postData = { command: "topics_api", action: "create_qsetbinfile", bin_files: new_files };
            } else {
                postData = { command: "topics_api", action: "update_qset_binfile", bin_files: new_files, add_ref_id: add_ref_id };
            }

            let api = new APICall();
            let response = await api.commandWithoutCallback(postData);
            if (response.error_code == 0) {
                if (response.qsetbinfilesArray.length > 0) {
                    let filesArray = response.qsetbinfilesArray;
                    console.log('filesArray', filesArray);
                    let question_json = JSON.parse(question.question_json);
                    let new_question = {
                        ...question,
                        question_json: JSON.stringify({
                            ...question_json,
                            question_attachment: filesArray
                        })
                    }
                    this.props.saveQuestion(new_question);
                }
            }

        } else {
            this.props.saveQuestion(question);
        }

    }


    filesToBeRemoved = (files) => {

    }

    showBulkUploadModal = () => {
        this.myRef.current.calluploader();
        this.setState({ modal_view: true, documentdetails: null, documentversion: null });
    }

    render() {
        if (this.state.question === null) return <></>
        let question_attachments = this.state.question.question_json.hasOwnProperty('question_attachment') ? this.state.question.question_json.question_attachment : [];
        return (
            <div className="add-role-modal-container" style={{ zIndex: "1" }}>
                <div className="add-role-modal-header-container">
                    <div className="add-role-modal-header-text-container">
                        <div className="add-role-modal-header-title">Add/Edit Question</div>
                        <div className="add-role-modal-header-subtitle">Complete the necessary fields to add/edit a question</div>
                    </div>
                    <FontAwesomeIcon icon={solid('xmark')} onClick={this.closeModal} size="2x" style={{ cursor: "pointer" }} />
                </div>
                <div className="add-role-modal-body-container-large">
                    <div>
                        <div className="add-role-modal-label">Question Title</div>
                        <input type="text" name="name" className="add-role-modal-input" value={this.state.question.name} onChange={this.handleChange("outer")} />
                    </div>
                    <div>
                        <div className="add-role-modal-label">Question Text</div>
                        <div style={{ backgroundColor: "#fff" }}><ReactQuill onChange={this.rteChange} value={this.state.question.question_json.text} /></div>
                        {/* <textarea rows={6} className="add-role-modal-textarea" name="text" value={this.state.question.question_json.text} onChange={this.handleChange("nested")} /> */}
                    </div>
                    <div>
                        <div className="add-role-modal-label">Upload Attachment</div>
                        <div className='topic-attachment-div'>
                            {/* <DropZoneQuestionset onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} onExitsFilesUpdated={this.onExitsFilesUpdated} /> */}
                            <BulkModal ref={this.myRef} filesTobeAdded={this.filesTobeAdded} previousFiles={question_attachments} question_id={this.state.question.id} filesToBeRemoved={this.filesToBeRemoved} />
                            <button className='topic-attcahment-upload-button' onClick={this.showBulkUploadModal}>Upload</button>
                        </div>
                    </div>
                    <div>
                        <div className="add-role-modal-label">Active</div>
                        {
                            this.state.question.is_active === 1 ?
                                <FontAwesomeIcon className="mc-roles-add-icon" icon={regular('toggle-on')} onClick={this.toggleActive} /> :
                                <FontAwesomeIcon className="mc-roles-add-icon" style={{ color: "#ababab" }} icon={regular('toggle-off')} onClick={this.toggleActive} />
                        }
                    </div>
                    <div>
                        <div className="add-role-modal-label">Allow N/A Response</div>
                        {
                            this.state.question.question_json.allow_na === 1 ?
                                <FontAwesomeIcon className="mc-roles-add-icon" icon={regular('toggle-on')} onClick={this.toggleNa} /> :
                                <FontAwesomeIcon className="mc-roles-add-icon" style={{ color: "#ababab" }} icon={regular('toggle-off')} onClick={this.toggleNa} />
                        }
                    </div>
                    <div>
                        <div className="add-role-modal-label">Correct Answer</div>
                        <select className='mc-dropdown' name='correct_answer' value={this.state.question.question_json.correct_answer} onChange={this.handleChange("nestesd")}>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                    <div>
                        <div className="add-role-modal-label">Flagged Options</div>
                        <select className='mc-dropdown' name='flagged_opt' value={this.state.question.question_json.flagged_opt} onChange={this.handleChange("nestesd")}>
                            <option value="no_flag">No Flag</option>
                            <option value="incorrect">Incorrect</option>
                            {
                                this.state.question.question_json.allow_na === 1 &&
                                <>
                                    <option value="na">N/A</option>
                                    <option value="incorrect_na">Incorrect and N/A</option>
                                </>
                            }
                        </select>
                    </div>
                    {/* Comment */}
                    <div>
                        <div className="add-role-modal-label">Comment Options</div>
                        <select className='mc-dropdown' name='req_comment' value={this.state.question.question_json.req_comment} onChange={this.handleChange("nestesd")}>
                            <option value="no_comment">No Comment</option>
                            <option value="mand_corrt_ans">Mandatory for Correct Answer</option>
                            <option value="mand_incorrt_ans">Mandatory for Incorrect Answer</option>
                            <option value="mand_both">Mandatory for Both</option>
                            <option value="optional">Optional</option>
                        </select>
                    </div>
                    <div>
                        <div className="add-role-modal-label">Requires Attachment for Responses?</div>
                        <select className='mc-dropdown' name='req_attach' value={this.state.question.question_json.req_attach} onChange={this.handleChange("nestesd")}>
                            <option value="no_attach">No Attachment</option>
                            <option value="mand_corrt_ans">Mandatory for Correct Answer</option>
                            <option value="mand_incorrt_ans">Mandatory for Incorrect Answer</option>
                            <option value="mand_both">Mandatory for Both</option>
                            <option value="optional">Optional</option>
                        </select>
                    </div>
                </div>
                <div className="add-role-modal-footer-container">
                    <button className="add-role-modal-cancel-button" onClick={this.closeModal}>Close</button>
                    <button className="add-role-modal-save-button" onClick={this.saveQuestion}>Save</button>
                </div>
            </div>
        )
    }
}

export default QuestionModal
