import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import CSLTable from "../Common/CSLTable";
import APICall from "../../Common/APICall";
import Store from "../../Common/Store";
import styled from 'styled-components';
import { FaEllipsisV } from 'react-icons/fa';
import AssignCertManagerModal from "./AssignCertManagerModal";
import SmallSpinner from "../Common/SmallSpinner";

const ViewDiv = styled.div`
    box-sizing: border-box;
    cursor:pointer;
`;
const RoleDiv = styled.div`
	display : inline-block;
	padding : 5px 10px 5px 10px;
	border-radius : 5px;
	color : #fff;
	font_weight : bold;
`

class CertMgs extends React.Component {

	state = { assigned_roles: {}, cert_result: [], ass_result: [], selected_role_for_cert: 0, show_add_cert_modal: false, context_visible: false, loading : false}

	async componentDidMount() {
		await this.setComponentState()
	}

	async componentDidUpdate(prevProps) {
		if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
			await this.setComponentState()
		}
	}

	setComponentState = async () => {
		this.setState({ loading : true })
		const api = new APICall()
		let payload1 = { command: 'get_cert_manager', contact_id: this.props.selected_employee_id }
		let payload2 = { command: 'get_role_assignment', contact_id: this.props.selected_employee_id }
		let payload3 = { command: 'personnel', action: 'get_user_role_code_and_color', user_id: parseInt(this.props.selected_employee_id) }
		let [cert_result, ass_result, role_result] = await Promise.all([api.commandWithoutCallback(payload1), api.commandWithoutCallback(payload2), api.commandWithoutCallback(payload3)])
		// let role_list = Store.getStoreData('role_list')
		// let role_type_list = Store.getStoreData('role_type_list')
		const roles = role_result.result.roles
		const role_types = role_result.result.role_types
		let assigned_roles = {}
		for (let ass of ass_result) {
			if (ass.role_id.toString() in assigned_roles === false) {
				assigned_roles[ass.role_id.toString()] = {
					role_id: ass.role_id,
					role_type_id: ass.role_type_id,
					role_code: roles[ass.role_id.toString()],
					role_color: role_types[ass.role_type_id.toString()],
					cert_mgr_id: 0,
					row_id: 0,
					context_visible: false
				}
			}
		}
		for (let row of cert_result) {
			if (row.role_id.toString() in assigned_roles) {
				assigned_roles[row.role_id.toString()].cert_mgr_id = row.cert_mgr_id
				assigned_roles[row.role_id.toString()].row_id = row.id
			}
		}
		this.setState({ assigned_roles, cert_result, ass_result, loading : false })
	}

	openCloseCertContexMenu = (event) => {
		event.preventDefault()
		const context_visible = this.state.context_visible === true ? false : true
		this.setState({ context_visible })
	}

	closeCertContextMenu = (event) => {
		event.preventDefault()
		this.setState({ context_visible: false })
	}

	addCertMgr = (mode, role_id, id = null) => (event) => {
		event.preventDefault();
		const context_visible = false
		let assigned_roles = JSON.parse(JSON.stringify(this.state.assigned_roles))
		if (role_id !== null) assigned_roles[role_id].context_visible = false
		if (mode === 'add') {
			this.setState({ show_add_cert_modal: true, assigned_roles, context_visible });
		} else if (mode === 'edit') {
			this.setState({ show_add_cert_modal: true, selected_role_for_cert: id, assigned_roles, context_visible });
		}
	}

	toggleContextMenu = (role_id) => (event) => {
		event.preventDefault()
		let assigned_roles = JSON.parse(JSON.stringify(this.state.assigned_roles))
		console.log("role_id:", role_id)
		for (let key in assigned_roles) {
			console.log("key:", key)
			if (key === role_id) {
				assigned_roles[key].context_visible = assigned_roles[key].context_visible === true ? false : true
			} else {
				assigned_roles[key].context_visible = false
			}
		}
		this.setState({ assigned_roles })
	}

	closeContextMenu = (role_id) => (event) => {
		event.preventDefault()
		let assigned_roles = JSON.parse(JSON.stringify(this.state.assigned_roles))
		assigned_roles[role_id].context_visible = false
		this.setState({ assigned_roles })
	}

	processCertMgrs = () => {
		let list_contacts = Store.getStoreData('listcontacts');
		let assigned_roles = JSON.parse(JSON.stringify(this.state.assigned_roles))
		console.log("assigned_roles:", assigned_roles)
		let ret = { data: [], columns: [] };
		ret.columns = [
			{
				Header: 'ROLE', Cell: row => (
					<RoleDiv style={{ backgroundColor: row.original.role_color }}>{row.original.role_code}</RoleDiv>
				), minWidth: 70, headerStyle: { textAlign: 'left' }
			},
			{ Header: 'CERTIFICATION MANAGER', accessor: 'cert_mgr', minWidth: 50, headerStyle: { textAlign: 'left' } },
			{
				'Header': '', filterable: false, Cell: row => (
					<div className="mc-roles-table-action-container" style={{ width: "100%", paddingRight: "10px" }}>
						<FontAwesomeIcon className="mc-roles-add-icon" title="View" icon={regular('ellipsis-vertical')} onClick={this.toggleContextMenu(row.original.role_id)} />
						{
							row.original.context_visible &&
							<div className="context-menu" onMouseLeave={this.closeContextMenu(row.original.role_id)}>
								<div className="context-menu-item" onClick={this.addCertMgr('edit', row.original.role_id, row.original.id)}>Edit</div>
							</div>
						}
					</div>
				),
				show: Store.getStoreData('role') === "team" ? false : true
			}
		];
		if (Object.keys(assigned_roles).length !== 0) {
			for (let key in assigned_roles) {
				let elem = {
					id: assigned_roles[key].row_id,
					role_id: key,
					role_code: assigned_roles[key].role_code,
					role_color: assigned_roles[key].role_color,
					cert_mgr: assigned_roles[key].cert_mgr_id !== 0 ? list_contacts.find(item => item.ID === assigned_roles[key].cert_mgr_id.toString()).ContactName : '-',
					context_visible: assigned_roles[key].context_visible
				}
				ret.data.push(elem);
			}
		}
		return ret;
	}

	assignCertificationMgr = async (assigned_roles) => {
		const api = new APICall();
		let postData = { command: 'save_cert_manager', contact_id: this.props.selected_employee_id, assigned_roles: assigned_roles };
		let save_resp = await api.commandWithoutCallback(postData);
		console.log("Cert Save Resp : ", save_resp);
		if (save_resp.error_code === 0) await this.setComponentState();
		this.setState({ show_add_cert_modal: false, selected_role_for_cert: 0 });
	}

	closeCertMgrModal = () => {
		this.setState({ show_add_cert_modal: false, selected_role_for_cert: 0 });
	}

	render() {
		console.log("this.state:", this.state)
		return (
			<div>
				{
					this.state.show_add_cert_modal === true && Object.keys(this.state.assigned_roles).length > 0 &&
					<AssignCertManagerModal
						assigned_roles={this.state.assigned_roles}
						assigned_roles_backup={this.state.assigned_roles}
						closeModal={this.closeCertMgrModal}
						user_id={this.props.selected_employee_id.toString()}
						row_id={this.state.selected_role_for_cert}
						assignCertificationMgr={this.assignCertificationMgr}
					/>
				}
				<div className="mc-table-header-container" style={{ backgroundColor: "#fff" }}>
					<div style={{ fontWeight: "600", fontSize: "15px", paddingLeft: "15px" }}>Certification Managers</div>
					<div style={{ display: "flex", justifyContent: "flex-end", position: "relative", zIndex: "1" }}>
						{
							Store.getStoreData('role') !== "team" &&
							<>
								<FontAwesomeIcon className="mc-roles-add-icon" icon={regular('ellipsis-vertical')} onClick={this.openCloseCertContexMenu} />
								{
									this.state.context_visible &&
									<div className="context-menu" style={{ right: "8px" }} onMouseLeave={this.closeCertContextMenu}>
										<div className="context-menu-item" onClick={this.addCertMgr('add', null)}>Add</div>
									</div>
								}
							</>
						}
					</div>
				</div>
				<div className="mc-table-container">
					<CSLTable processData={this.processCertMgrs} />
					{
						this.state.loading && <SmallSpinner />
					}
				</div>
			</div>
		)
	}
}

export default CertMgs