import React from 'react';
import styled from 'styled-components';
import RespoUtils from './RespoUtils';
import RespoBox from './RespoBox.js';
import RespoLine from './RespoLine.js';
import APICall from '../../Common/APICall.js';
import { FaExclamationCircle } from 'react-icons/fa';
import RespoEventBusNew from './RespoEventBusNew'
import Store from '../../Common/Store';
const boxBorderWidth = 1;
const lineBorderWidth = 5;
const boxTrueHeight = 235;
const boxTrueWidth = 267;
const boxHeight = boxTrueHeight - (2 * boxBorderWidth);
const boxBodyHeight = 145;
const boxFooterHeight = 40;
const boxWidth = 360 - (2 * boxBorderWidth);
const horzGap = boxTrueWidth / 3;
const vertGap = 40;
const initialHorzGap = 20;
const RespoMapWrapper = styled.div`
							display: block;
							width: 100%;
							padding-left: ${props => `${(props.level > 0 ? horzGap : 30)}px`};
							${props => `${props.level > 0 ? '' : 'position: relative'}`};
							padding-top: ${vertGap}px;

						`;
// margin-bottom: ${vertGap}px;
// margin-left: ${props => `${props.level*105}px`};
// margin-left: ${props => `${(props.level > 0 ? 1 : 0) * 105 + 15}px`};

const NoManagersMsgBoxWrapper = styled.div`
							position:absolute;
							top: 65px;
							left: 40px;
							height:65px;
							-moz-box-shadow:    inset 0 0 10px #b1b1b1;
						    -webkit-box-shadow: inset 0 0 10px #6161b1;
						    box-shadow:         inset 0 0 10px #b1b1b1;
							display: block;
							border-radius: 15px;
							padding-left: 55px;
							padding-top:30px
							color: blue;
							background: white;
							z-index:500;
							width: 80%;
							`;

class RespoMap extends React.Component {
	state = {
		isLoaded: false,
		data: null,
		respomap: null,
		respomapVisible: null,
		maxLevel: 999,
		effective_date: new Date()
	};

	component_id = null;
	numberOfBoxes = 0;
	showJobDesc = 0;
	showRoleDesc = 1;
	remount = 0;
	fullMap = null;

	constructor (props) {
		super(props);
		this._isMounted = false;
		if (this.props.respomap === null) {
			this.updateRespoMap = this.updateRespoMap.bind(this);
			this.updateRespoMapVisible = this.updateRespoMapVisible.bind(this);
			this.updateMaxLevel = this.updateMaxLevel.bind(this);
			this.component_id = RespoEventBusNew.registerCallBack('respomap_raw', this.updateRespoMap);
			RespoEventBusNew.registerCallBack('respomap_visible', this.updateRespoMapVisible, this.component_id);
			RespoEventBusNew.registerCallBack('max_level', this.updateMaxLevel, this.component_id);
			////console.log('registerCallBack called', this.component_id);
		} else {
			let store_data = RespoEventBusNew.getStoreData('respomap_raw');
			this.showJobDesc = store_data.show_job_desc;
			this.showRoleDesc = store_data.show_role_desc;
			// console.log('respomap -->Job_desc', Job_desc);
		}
	}

	convertListToTree(list) {
		var companyIDs = {};
		list.forEach(function (x) {
			companyIDs[x['id']] = 1;
		});
		var treeList = [];
		var lookup = {};
		list.forEach(function (obj) {
			if (!(obj['parent_id'] in companyIDs)) {
				obj['parent_id'] = 0;
			}
			lookup[obj['id']] = obj;
			obj['children'] = [];
		});
		list.forEach(function (obj) {
			if (obj['parent_id'] !== 0) {
				lookup[obj['parent_id']]['children'].push(obj);
			} else {
				treeList.push(obj);
			}
		});

		var retTree = { "children": treeList, "id": 0 };
		return retTree;
	}

	measureLines(tree, top, lastHeight, level, maxLevel, lastTop = 0) {
		// let lastTop = top;
		let newTop = top + vertGap;
		let thisBoxTrueHeight = 0;
		if (!('role' in tree)) {
			tree.boxHeight = 0;
			tree.top = 0; // - vertGap - (vertGap / 2);
			tree.boxTop = 0;
			tree.mid = 0; // + vertGap; //+ boxBorderWidth;
			tree.level = -1;
			tree.score = -1;
			tree.bottom = 0;
			tree.id = 0;
		} else {
			let roles = Object.keys(tree.role).length;
			let footerRows = this.showRoleDesc == 1 ? roles : (roles % 4 == 0 ? Math.floor(roles / 4) : Math.floor(roles / 4) + 1);
			if (footerRows === 0) {
				footerRows = 1;
			}
			thisBoxTrueHeight = boxTrueHeight - ((1 - this.showJobDesc) * boxBodyHeight) + (footerRows < 1 ? 0 : footerRows - 1) * boxFooterHeight;
			newTop = top + thisBoxTrueHeight;
			tree.boxHeight = thisBoxTrueHeight;
			tree.top = lastTop; // - vertGap - (vertGap / 2);
			tree.boxTop = newTop - (thisBoxTrueHeight) - vertGap;
			tree.mid = newTop - (thisBoxTrueHeight) + 20; // + vertGap; //+ boxBorderWidth;
			tree.level = level;
			tree.bottom = newTop;
			tree.footerHeight = boxFooterHeight * footerRows;

			tree.color = RespoUtils.getMaxRoleColor(tree.role);
			tree.footerItems = {};
			console.log('tree.role', tree.role);
			for (var r in tree.role) {
				let roleColor = tree.role[r]["color"];
				tree.footerItems[r] = { 'text': tree.role[r], 'bgcolor': roleColor, 'color': RespoUtils.getMaxRoleColor(tree.role) }
			}
		}
		let score = 0;
		if (level < maxLevel) {
			for (var i = 0; i < tree.children.length; i++) {
				let res = this.measureLines(tree.children[i], newTop + vertGap, thisBoxTrueHeight, level + 1, maxLevel, tree.bottom); //lastTop + thisBoxTrueHeight + vertGap);
				newTop = res.top;// + vertGap;
				// lastTop = res.top;
				tree.score = tree.children.length > 0 ? res.score : 1;
				score += tree.score;
			}
		}
		return { top: newTop, score: score + 1 };
	}

	measureLinesOld(tree, top, lastHeight, level, maxLevel) {
		let lastBoxHeight = lastHeight

		if (lastHeight === 0) {
			let roles = Object.keys(tree[0].role).length;
			let footerRows = this.showRoleDesc === 1 ? roles : (roles % 4 === 0 ? Math.floor(roles / 4) : Math.floor(roles / 4) + 1);
			lastBoxHeight = boxTrueHeight - ((1 - this.showJobDesc) * boxBodyHeight) + (footerRows < 1 ? 0 : footerRows - 1) * boxFooterHeight;
			//console.log('lastBoxHeight in measurelines', lastBoxHeight);
		}

		let thisBoxTrueHeight = lastBoxHeight;// - (this.state.data.show_job_desc * boxBodyHeight) + (footerRows - 1 < 1 ? 1 : footerRows - 1) * boxFooterHeight;
		let newTop = top + thisBoxTrueHeight + vertGap;
		let thisBoxHeight = thisBoxTrueHeight - (2 * boxBorderWidth);

		let score = 0;
		// let newBottom = newTop;
		// console.log('thisBoxTrueHeight', thisBoxTrueHeight);

		if (level <= maxLevel) {
			for (var i = 0; i < tree.length; i++) {

				let lastTop = top;// + ((lastHeight > 0 && i > 0) ? 1 : 0) * vertGap;
				let roles = Object.keys(tree[i].role).length;
				let footerRows = this.showRoleDesc === 1 ? roles : (roles % 4 === 0 ? Math.floor(roles / 4) : Math.floor(roles / 4) + 1);
				thisBoxTrueHeight = boxTrueHeight - ((1 - this.showJobDesc) * boxBodyHeight) + (footerRows < 1 ? 0 : footerRows - 1) * boxFooterHeight;;
				thisBoxHeight = thisBoxTrueHeight - (2 * boxBorderWidth);
				//console.log('boxBodyHeight in measurelines', boxBodyHeight);
				console.log('newTop, level, thisBoxTrueHeight', newTop, level, thisBoxTrueHeight);
				// console.log('thisBoxTrueHeight inside', thisBoxTrueHeight);
				tree[i].boxHeight = thisBoxTrueHeight;
				tree[i].top = lastTop; // - vertGap - (vertGap / 2);
				tree[i].boxTop = newTop - (thisBoxTrueHeight) - vertGap;
				tree[i].mid = newTop - (thisBoxTrueHeight) + 40; // + vertGap; //+ boxBorderWidth;
				tree[i].level = level;
				console.log('tree[i]', tree[i]);
				let res = this.measureLines(tree[i].children, newTop, thisBoxTrueHeight, level + 1, maxLevel);
				newTop = res.top;// + vertGap;
				tree[i].score = tree[i].children.length > 0 ? res.score : 1;
				score += tree[i].score;
			}
		}

		//console.log('newTop', newTop);
		return { top: newTop, score: score + 1 };
	}

	updateRespoMapVisible() {

		let respomapVisible = RespoEventBusNew.getStoreData('respomap_visible');
		let store_data = RespoEventBusNew.getStoreData('respomap_raw');
		this.showJobDesc = store_data.show_job_desc;
		this.showRoleDesc = store_data.show_role_desc;

		let maxLevelTemp = RespoEventBusNew.getStoreData('max_level');
		let maxLevel = maxLevelTemp === null ? 999 : maxLevelTemp;

		this.measureLines(respomapVisible, 0, 0, 0, maxLevel);
		//console.log('respomapVisible', respomapVisible);

		this.setState({ 'respomapVisible': respomapVisible });
	}

	componentWillUpdate(nextProps, nextState) {

	}

	updateMaxLevel() {
		let maxLevelTemp = RespoEventBusNew.getStoreData('max_level');
		let maxLevel = maxLevelTemp === null ? 999 : maxLevelTemp;
		//console.log('maxLevel', maxLevel);
		let respomapVisible = this.state.respomapVisible;
		this.measureLines(respomapVisible, 0, 0, 0, maxLevel);
		this.setState({ 'respomapVisible': respomapVisible });
		//console.log('state', this.state);
	}


	updateRespoMap() {

		let store_data = RespoEventBusNew.getStoreData('respomap_raw');
		// console.log("RESPOMAP RAW:", JSON.stringify(store_data));
		this.showJobDesc = store_data.show_job_desc;
		this.showRoleDesc = store_data.show_role_desc;


		let respomap = this.convertListToTree(store_data.result);
		// console.log('respomap', respomap);
		let maxLevelTemp = RespoEventBusNew.getStoreData('max_level');
		let maxLevel = maxLevelTemp === null ? 999 : maxLevelTemp;
		this.setState({ data: store_data });
		this.measureLines(respomap, 0, 0, 0, maxLevel);

		RespoEventBusNew.updateStore('respomap', respomap, this.component_id);
		RespoEventBusNew.updateStore('respomap_visible', respomap, this.component_id);

		this.setState({ data: store_data, isLoaded: true, 'respomap': respomap, 'respomapVisible': respomap });
	}

	componentWillUnmount() {
		RespoEventBusNew.deRegisterCallback('respomap_raw', this.component_id);
		RespoEventBusNew.deRegisterCallback('respomap_visible', this.component_id);
		RespoEventBusNew.deRegisterCallback('respomap_visible', this.component_id);
	}

	processIndex = async (get_company, get_regulators, get_jobtitle_id, logged_in_contact, resultObj, appointed_date) => {
		//debugger;
		let formattedDate = "";
		if (typeof appointed_date === 'object' && appointed_date.hasOwnProperty('appointed_date') && appointed_date.appointed_date !== "") {
			const date = new Date(appointed_date.appointed_date);
			formattedDate = date.toLocaleDateString('en-GB');
		}


		let data = {
			"error_code": 0,
			"error_msg": "",
			"ContactName": logged_in_contact.ContactName,
			"CompanyName": get_company.CompanyName,
			"ru_ref": get_regulators,
			"curdate": new Date().toLocaleDateString('en-GB'),
			"appointed_date": formattedDate
		}

		const filteredJobTitleIds = get_jobtitle_id.jobTitleId
			.filter(item => item.mc_JobtitleID !== null)
			.map(item => item.mc_JobtitleID);


		const payload1 = { command: "respomap", action: "get_job_title_desc", job_ids: filteredJobTitleIds }
		const payload2 = { command: "respomap", action: "get_employees", employee_ids: Object.keys(resultObj) }

		const api = new APICall()
		const [get_job_title_desc, get_employees] = await Promise.all([
			api.commandWithoutCallback(payload1),
			api.commandWithoutCallback(payload2),
		]);


		const newArray = [];

		// get_employees.employees.forEach(item => {
		// 	const index = newArray.findIndex(obj => obj.id === item.contact_id);
		// 	if (index !== -1) {
		// 		if (!newArray[index].role.hasOwnProperty(item.title)) {
		// 			newArray[index].role[item.role_code] = item.title;
		// 		}
		// 	} else {
		// 		debugger;
		// 		let jt = get_jobtitle_id.jobTitleId.find(j => j["ID"] == item.contact_id)["mc_JobtitleID"];
		// 		jt = jt ? jt : 0;
		// 		let jd = get_job_title_desc.jobTitles.find(k => k["id"] == jt);

		// 		jd = jd ? jd.JobTitle : ""
		// 		newArray.push({
		// 			id: item.contact_id,
		// 			parent_id: item.manager_id,
		// 			title: jd,
		// 			job_desc: item.description,
		// 			name: resultObj[item.contact_id]["ContactName"],
		// 			collapsed: 0,
		// 			role: {
		// 				[item.role_code]: {
		// 					title: item.title,
		// 					color: item.color
		// 				}
		// 			}

		// 		});
		// 	}
		// });

		get_employees.employees.forEach(item => {
			const index = newArray.findIndex(obj => obj.id === item.contact_id);
			if (index !== -1) {
				let found = false;
				for (const roleCode in newArray[index].role) {
					const role = newArray[index].role[roleCode];
					if (role.title === item.title) {
						role.color = item.color;
						found = true;
						break;
					}
				}
				if (!found) {
					newArray[index].role[item.role_code] = {
						title: item.title,
						color: item.color,
						priority: item.priority
					};
				}
			} else {
				let jt = get_jobtitle_id.jobTitleId.find(j => j["ID"] == item.contact_id)["mc_JobtitleID"];
				jt = jt ? jt : 0;
				let jd = get_job_title_desc.jobTitles.find(k => k["id"] == jt);

				jd = jd ? jd.JobTitle : ""
				newArray.push({
					id: item.contact_id,
					parent_id: item.manager_id,
					title: jd,
					job_desc: item.description,
					name: resultObj[item.contact_id]["ContactName"],
					collapsed: 0,
					role: {
						[item.role_code]: {
							title: item.title,
							color: item.color,
							priority: item.priority
						}
					}
				});
			}
		});


		data.result = newArray;
		let respomap_original = JSON.parse(JSON.stringify(data));

		let filtered = [];
		data.result.forEach((item) => {
			let objkeys = Object.keys(item.role);
			let check = false;
			objkeys.forEach((obkey) => {
				// console.log(index);
				if (obkey.includes("SMF") || obkey.includes("SMP") || obkey.includes("SMO")) {
					check = true;
				}
			});
			if (check === true) {
				filtered.push(item);
			}
		});

		console.log('data12345 ==>', data);

		RespoEventBusNew.updateStore('respomap_raw', data);
		RespoEventBusNew.updateStore('respomap_filtered', data);
		RespoEventBusNew.updateStore('respomap_original', respomap_original);

	}

	componentDidMount = async () => {
		let maxLevel = RespoEventBusNew.getStoreData('max_level');
		maxLevel = maxLevel === null ? 999 : maxLevel;
		this.setState({ 'maxLevel': maxLevel });
		console.log('this.fullMap', this.fullMap);

		let respomapVisible = RespoEventBusNew.getStoreData('respomap_visible');
		let respomap = RespoEventBusNew.getStoreData('respomap');
		let data = RespoEventBusNew.getStoreData('respomap_raw');

		let data2 = JSON.parse(localStorage.getItem("contactlist")).result.contactlist;

		console.log('data2 ==>', data2);



		const resultObject = Object.keys(data2).reduce((acc, curr) => {
			acc[curr] = {
				ContactName: data2[curr].ContactName
			};
			return acc;
		}, {});

		console.log('resultObject ===>', resultObject);
		const sldata = JSON.parse(localStorage.getItem("site_layout"));
		let logged_in_contact_id = sldata.contact_id;
		let logged_in_obj = resultObject[logged_in_contact_id];

		console.log('respomapVisible in ==>', this.props.respomap, respomapVisible);
		let api = new APICall()
		if (this.props.respomap === null && respomapVisible === null) {

			const payload1 = { command: "respomap", action: 'get_company_namme' }
			const payload2 = { command: "respomap", action: "get_regulators" }
			const payload3 = { command: "respomap", action: "get_jobtitle_id", user_ids: Object.keys(resultObject) }
			const payload4 = { command: "respomap", action: 'get_employee_appointed_date', contact_id: logged_in_contact_id }

			const [get_company, get_regulators, get_jobtitle_id, get_appointed_date] = await Promise.all([
				api.commandWithoutCallback(payload1),
				api.commandWithoutCallback(payload2),
				api.commandWithoutCallback(payload3),
				api.commandWithoutCallback(payload4)
			]);

			this.processIndex(get_company, get_regulators, get_jobtitle_id, logged_in_obj, resultObject, get_appointed_date);

		} else {
			this.showJobDesc = data.show_job_desc;
			this.showRoleDesc = data.show_role_desc;

			this.measureLines(respomapVisible, 0, 0, 0, maxLevel);
			//console.log('respomapVisible after', respomapVisible);

			this.setState({ data: data, isLoaded: true, respomap: respomap, respomapVisible: respomapVisible, maxLevel: maxLevel });
		}
	}

	hasChildren(item) {
		if ('children' in item) return true;
		return false;
	}


	render() {
		console.log('respo dashboard gets called', Store.getStoreData('module_name'));
		if (this.state.isLoaded === false) {
			return <div>Loading...</div>
		}
		let maxLevelTemp = RespoEventBusNew.getStoreData('max_level');
		let maxLevel = maxLevelTemp === null ? 999 : maxLevelTemp;
		//console.log('this.state.data', this.state.data);
		////console.log('this.state.respomap', this.state.respomapVisible);
		const level = this.props.level || 0;
		let respomap = this.props.respomap === null ? this.state.respomapVisible.children : this.props.respomap; //.children;
		let store_data = RespoEventBusNew.getStoreData('respomap_raw');
		this.showJobDesc = store_data.show_job_desc;
		this.showRoleDesc = store_data.show_role_desc;
		// console.log('showJobDesc showRoleDesc', this.showJobDesc, this.showRoleDesc);

		this.remount++;
		console.log('respomap', respomap);
		// console.log('store_data', store_data);
		console.log('level', level);
		let fullMap = RespoEventBusNew.getStoreData('full_map');
		let showFilter = RespoEventBusNew.getStoreData('show_filter');
		if (level <= 0 && respomap.length === 0 && store_data.error_code === 0) {
			return <NoManagersMsgBoxWrapper style={{
				width: `${showFilter ? 'calc(100% - 500px)' : '90%'}`
			}}><span style={{ color: `#457BB8`, fontHeight: '26px' }}><FaExclamationCircle /></span>&nbsp;&nbsp;
				<span style={{ fontHeight: `15px`, fontWeight: `600`, color: `#457BB8` }}>No Senior Manager Roles found on selected effective date {store_data.appointed_date}.</span></NoManagersMsgBoxWrapper>;
		}
		if (level <= 0 && respomap.length === 0 && store_data.error_code === 2001) {
			return <NoManagersMsgBoxWrapper style={{
				width: `${showFilter ? 'calc(100% - 500px)' : '90%'}`
			}}><span style={{ color: `#457BB8`, fontHeight: '26px' }}><FaExclamationCircle /></span>&nbsp;&nbsp;
				<span style={{ fontHeight: `15px`, fontWeight: `600`, color: `#457BB8` }}>{store_data.error_msg}</span></NoManagersMsgBoxWrapper>;
		}
		return (<div><RespoMapWrapper level={level}>
			{
				respomap.map((item, i) => {
					return (
						<div key={`level-${level}-${i}`}>
							<RespoLine item={item} />
							<RespoBox item={item} showJob={this.showJobDesc} showRole={this.showRoleDesc} />

							{this.hasChildren(item) && item.level < maxLevel && <RespoMap respomap={item.children} level={level + 1} />}

						</div>
					)
				}
				)}
		</RespoMapWrapper></div>
		)
	}

}

export default RespoMap;
