import React, { Component } from 'react';
import SorContext from "./SorContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import './sor.css'


class StatusFilter extends Component {
    constructor (props) {
        super(props);
        this.state = {
            selectedCount: 0,
            statusFilter: [
                // { id: '1', name: 'Unallocated', selected: 0 },
                { id: '2', name: 'Allocated', selected: 0 },
                { id: '3', name: 'Pending', selected: 0 },
                { id: '4', name: 'Accepted', selected: 0 },
                // { id: '5', name: 'Declined', selected: 0 }
            ]
        };
    }
    static contextType = SorContext;

    componentDidMount = () => {
        this.setComponentState();
    }


    setComponentState = () => {
        const { selected_report_parameter } = this.context;
        let status = this.state.statusFilter;
        let sfilter = selected_report_parameter.firstSection.statusFilter;
        let updatedData = []
        if (sfilter.value != 'all') {
            updatedData = status.map(item => {
                if (sfilter.value.includes(item.id)) {
                    return { ...item, selected: item.selected === 0 ? 1 : 0 };
                }
                return item;
            });
            this.setState({
                statusFilter: updatedData,
                selectedCount: updatedData.filter(item => item.selected === 1).length
            });
        }
    }

    selectedStatus = (e, Id) => {
        e.preventDefault();
        let status = this.state.statusFilter;
        const updatedData = status.map(item => {
            if (item.id == Id) {
                return { ...item, selected: item.selected === 0 ? 1 : 0 };
            }
            return item;
        });

        this.setState({
            statusFilter: updatedData,
            selectedCount: updatedData.filter(item => item.selected === 1).length
        });
    };

    closeStatusFilter = () => {
        let statusFilter = this.state.statusFilter;
        const { updateFilterParameter } = this.context;
        let obj = {
            type: 'statusFilter',
            secondaryText: "",
            value: ""
        }

        if (this.state.selectedCount != 0) {
            const selectedObjects = statusFilter.filter(item => item.selected == 1);
            let names = "", ids = "";
            selectedObjects.forEach((e) => {
                names += e.name + ','
                ids += e.id + ','
            })
            names = names.slice(0, -1);
            ids = ids.slice(0, -1);

            obj.secondaryText = names;
            obj.value = ids
        } else {
            obj.secondaryText = 'All levels(Default)';
            obj.value = 'all'
        }

        updateFilterParameter(obj)
        this.props.closeFilter();
    }


    render() {

        return (
            <div className='personnel-container'>
                <span class="select-smr-popover-selected" id="spanSelectCount">Show selected
                    ({this.state.selectedCount} selected)</span>
                <div className='personnel-employee-container'>
                    <div className='personnel-employees'>
                        <ul>
                            {this.state.statusFilter.map((item, index) => (
                                <React.Fragment>
                                    <li className='personnel-li' key={index} onClick={(e) => this.selectedStatus(e, item.id)}>
                                        {item.selected == 1 && <FontAwesomeIcon ktitle="Action" className='personnel-check-icon'
                                            icon={solid('check')}>
                                        </FontAwesomeIcon>}
                                        {item.name}
                                    </li>
                                </React.Fragment>
                            ))}

                        </ul>
                    </div>
                    <button className='personnel-employee-done' onClick={this.closeStatusFilter}>Done</button>
                </div>
            </div >
        )
    }
}

export default StatusFilter
