import React, { Component } from 'react';
import moment from 'moment';
import AssessmentCompletion from './AssessmentCompletion';
import AssessmentDataContext from "./AssessmentDataContext";
import APICall from "../../Common/APICall";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, thin, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import CSLLoader from '../Common/CSLLoader';
import { saveAs } from 'file-saver';
import Store from '../../Common/Store.js';
import QuestionAttachments from './QuestionAttachments';

class TopicQuestion extends Component {
    constructor (props) {
        super(props);
        this.state = {
            ready: false,
            topic: [],
            assessment_final_click: false,
            show_review_button: false,
            is_completed: false
        }
    }

    static contextType = AssessmentDataContext;

    componentDidMount() {
        this.setComponentState();
    }
    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setComponentState();
        }
    }

    setComponentState = () => {
        let user_role = Store.getStoreData('role');
        const { assessment_final_click, logged_in_user, assessment_necessary_object } = this.context;
        console.log('assessment_necessary_object ==>', assessment_necessary_object);
        let canShow = this.checkReviewButton(this.props.data.topic_status, logged_in_user,
            user_role,
            assessment_necessary_object.assessment_over_all_acessor,
            assessment_necessary_object.assessment_assessed_by_type);
        let is_completed_assessment = assessment_necessary_object.assessment_is_completed;        
        this.setState({ topic: this.props.data, ready: true, assessment_final_click, show_review_button: canShow, is_completed: is_completed_assessment });
    }

    checkReviewButton = (topic_status, logged_in_user, user_role, over_all_acessor, assessed_by_type) => {
        console.log('this.props.data inside checkReviewButton ==>', this.props.data)
        //commented for jenny
        //const topic_over_all_acessor = this.props.data.topic_assessed_by;
        const status = topic_status;
        if (assessed_by_type != 6) {
            return status === "Review Required" && (over_all_acessor.includes(logged_in_user) || (user_role == 'admin_manager' || user_role == 'hr'))
        } else {
            return status === "Review Required" && (over_all_acessor.includes(logged_in_user))
        }
    }


    onValueChange = (event, section_id, question_id) => {
        event.preventDefault();
        let checkedValue = event.target.value;
        this.updateAnswer(question_id, section_id, 'value', checkedValue);
    }


    onSaveButtonClick = (event, section_id, question_id) => {
        event.preventDefault();
        let enteredValue = event.target.previousElementSibling.value;
        this.updateAnswer(question_id, section_id, 'text', enteredValue);
    }


    updateAnswer = (question_id, section_id, propertyName, value) => {
        let topic = this.state.topic;
        let is_review_required = false;
        let section = topic.sections.find(x => x.section_id == section_id);
        let question = section.questions.find(q => q["question_id"] == question_id && q["section_id"] == (section_id));
        if (propertyName == 'value') {
            let suggestedValue = question["correct_answer"];
            let isFlagged = question["flagged_opt"];
            let canShow = false;
            if (value != '') {
                if (isFlagged === 'incorrect_na') {
                    canShow = value == "na" || value.toLowerCase() !== suggestedValue.toLowerCase();
                } else if (isFlagged === 'na') {
                    canShow = value == 'na';
                } else if (isFlagged === 'incorrect') {
                    canShow = value !== "" && value.toLowerCase() !== suggestedValue.toLowerCase();
                } else if (isFlagged === 'no_flag') {
                    canShow = false;
                }
            }

            if (canShow) {
                is_review_required = true;
            } else {
                is_review_required = false;
            }

            question["answer_json"]["answer"]["value"] = value;

        } else if (propertyName == 'text') {
            question["answer_json"]["answer"]["text"] = value;
            //is_review_required = true;
        }

        let answer_id = question["answer_id"];
        let answer_json = question["answer_json"]["answer"];

        //console.log('update toggle answer_id  ==>', answer_id);

        const { updateTopic } = this.context;
        updateTopic(topic, section_id, question_id, answer_id, answer_json, is_review_required, propertyName);
    }

    saveReviewQuestions = () => {
        let topic = this.state.topic;
        let review_question_ids = "";
        let sections = topic.sections;

        for (let i = 0; i < sections.length; i++) {
            const section = sections[i];
            let questions = section.questions;

            for (let j = 0; j < questions.length; j++) {
                const question_obj = questions[j];
                let question_answer = question_obj["answer_json"]['answer'];

                let radioValue = question_answer["value"];
                let suggestedValue = question_obj["correct_answer"];
                let isFlagged = question_obj["flagged_opt"];
                //let canShow = (radioValue != "" && isFlagged.toLowerCase() == "incorrect" && radioValue.toLowerCase() !== suggestedValue.toLowerCase());

                let canShow = false;
                if (question_obj.req_comment === 'no_comment') {
                    canShow = false;
                } else if (question_obj.req_comment === 'mand_incorrt_ans') {
                    if (isFlagged === 'incorrect_na') {
                        canShow = radioValue == 'na' || radioValue.toLowerCase() !== suggestedValue.toLowerCase();
                    } else if (isFlagged === 'na') {
                        canShow = radioValue == 'na';
                    } else if (isFlagged === 'incorrect') {
                        canShow = radioValue !== "" && radioValue.toLowerCase() !== suggestedValue.toLowerCase();
                    } else if (isFlagged === 'no_flag') {
                        canShow = false;
                    }
                } else if (question_obj.req_comment === 'mand_corrt_ans') {
                    canShow = radioValue !== "" && radioValue.toLowerCase() === suggestedValue.toLowerCase();
                } else if (question_obj.req_comment === 'mand_both' || question_obj.req_comment === 'optional') {
                    if (radioValue != 'na') {
                        canShow = true;
                    }
                } else if (question_obj.req_comment === '') {
                    if (radioValue != '') {
                        if (isFlagged === 'incorrect_na') {
                            canShow = radioValue == 'na' || radioValue.toLowerCase() !== suggestedValue.toLowerCase();
                        } else if (isFlagged === 'na') {
                            canShow = radioValue == 'na';
                        } else if (isFlagged === 'incorrect') {
                            canShow = radioValue !== "" && radioValue.toLowerCase() !== suggestedValue.toLowerCase();
                        } else if (isFlagged === 'no_flag') {
                            canShow = false;
                        }
                    }
                }


                if (canShow) {
                    review_question_ids += (question_obj["question_id"]) + ",";
                }
            }
        }
        // remove lastcomma
        if (review_question_ids != "") {
            review_question_ids = review_question_ids.replace(/,\s*$/, "");
        }

        let reviewJson = {};

        // review_question_ids.split(',').forEach((qid) => {
        //     let txtBoxId = `#txt_${qid}`;
        //     reviewJson[qid] = document.querySelector(txtBoxId).value;
        // })
        review_question_ids.split(',').forEach((qid) => {
            let txtBoxId = `#txt_${qid}`;
            let element = document.querySelector(txtBoxId);
            if (element) {
                reviewJson[qid] = element.value;
            } else {
                reviewJson[qid] = ""
            }
        });

        const { reviewQuestions } = this.context;
        let topics_status = "Reviewed";

        reviewQuestions(topic.topic_id, topic.role_id, review_question_ids, reviewJson, topics_status);
    }

    downloadAttachment = async (uid, ref_id, attachName) => {

        let postData = { command: "topics_api", action: "fetch_qset_binfile_content", uid: uid, add_ref_id: ref_id };
        let api = new APICall();
        let response = await api.commandWithoutCallback(postData);
        let file_data = response.binFileContent;
        this.downloadBase64File(file_data, attachName);
    }

    downloadBase64File = (base64String, filename) => {
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/octet-stream' });
        saveAs(blob, filename);
    }

    handleTextareaChange = (event, section_id, question_id) => {
        event.preventDefault();
        let textValue = event.target.value;
        let topic = { ...this.state.topic };
        let sectionIndex = topic.sections.findIndex(section => section.section_id == section_id);
        let questionIndex = topic.sections[sectionIndex].questions.findIndex(question => question.question_id == question_id);
        topic.sections[sectionIndex].questions[questionIndex].answer_json.answer.text = textValue;
        this.setState({ topic });
    }


    render() {
        if (!this.state.ready) {
            return (<div><CSLLoader /></div>);
        }
        const { assessment_necessary_object } = this.context;
        let is_assessment_overall_acessor = assessment_necessary_object['assessment_is_overall_acessor']
        let topic = this.state.topic;
        let sections = Object.keys(topic).length > 0 ? topic["sections"] : [];
        const sectionsItem = sections.map((section, i) => {
            console.log('topic inside file', topic)
            if (section.questions.length > 0) {

                const key = `${section.section_id}-${i}`;
                return <div className='topic-question-section-item' key={key}>
                    <div className='topic-question-section-container'>
                        <label className='topic-question-section-name'>{section.section_name}</label>
                        {section.questions.map((question, i) => {

                            let question_id = question.question_id;
                            let question_answer = question["answer_json"]['answer'];

                            let radioValue = question_answer["value"];
                            let textBoxValue = question_answer['text'];
                            let placeholder = "";
                            if (is_assessment_overall_acessor) {
                                if (question.req_comment == 'optional' && textBoxValue == '') {
                                    placeholder = 'No comments were added'
                                }
                            }


                            let allow_na = Boolean(parseInt(question.allow_na));

                            //console.log('textBoxValue', question.text, question_answer,);
                            let suggestedValue = question["correct_answer"];
                            let isFlagged = question["flagged_opt"];

                            let canShow = false;


                            if (question.req_comment === 'no_comment') {
                                canShow = false;
                            } else if (question.req_comment === 'mand_incorrt_ans') {
                                if (isFlagged === 'incorrect_na') {
                                    canShow = radioValue == 'na' || radioValue.toLowerCase() !== suggestedValue.toLowerCase();
                                } else if (isFlagged === 'na') {
                                    canShow = radioValue == 'na';
                                } else if (isFlagged === 'incorrect') {
                                    canShow = radioValue !== "" && radioValue.toLowerCase() !== suggestedValue.toLowerCase();
                                } else if (isFlagged === 'no_flag') {
                                    canShow = false;
                                }
                            } else if (question.req_comment === 'mand_corrt_ans') {
                                canShow = radioValue !== "" && radioValue.toLowerCase() === suggestedValue.toLowerCase();
                            } else if (radioValue !== "" && (question.req_comment === 'mand_both' || question.req_comment === 'optional')) {
                                if (radioValue != "na") {
                                    canShow = true;
                                }
                            } else if (question.req_comment === '') {
                                if (radioValue != '') {
                                    if (isFlagged === 'incorrect_na') {
                                        canShow = radioValue == 'na' || radioValue.toLowerCase() !== suggestedValue.toLowerCase();
                                    } else if (isFlagged === 'na') {
                                        canShow = radioValue == 'na';
                                    } else if (isFlagged === 'incorrect') {
                                        canShow = radioValue !== "" && radioValue.toLowerCase() !== suggestedValue.toLowerCase();
                                    } else if (isFlagged === 'no_flag') {
                                        canShow = false;
                                    }
                                }
                            }
                            if (radioValue == 'na') {
                                canShow = false;
                            }


                            let has_attachment = question.hasOwnProperty('question_attachment') && question.question_attachment.length > 0;
                            let response_attachments = question.hasOwnProperty('response_attachment') ? question.response_attachment : []
                            return <div key={question_id} className={`topic-question-section-question ${canShow && has_attachment ? 'topic-question-increase-height' : ''}`}>
                                <div className='topic-question-container'>
                                    <span className='topic-question-index'>Q{i + 1}</span>
                                    <div className='topic-question-body' key={question_id}>
                                        <span className='topic-question-questiontext'>{question.title}</span>
                                        <div className='topic-question-questiontitle' dangerouslySetInnerHTML={{ __html: question.text }}></div>

                                        {(() => {
                                            if (has_attachment) {
                                                return (
                                                    <div className='attachment-alignment'>
                                                        {question.question_attachment.map((attachment, index) => {

                                                            let attachName = attachment.name;
                                                            let add_ref_id = attachment.add_ref_id;
                                                            let uid = attachment.uid;
                                                            let fileExtn = attachName.substr(attachName.lastIndexOf('.') + 1).toUpperCase();
                                                            return (
                                                                <div key={index} className='topic-question-attachment-panel'>
                                                                    <span className='topic-question-attachment-text-header'>Attachment</span>
                                                                    <div style={{ marginTop: '7px' }}>
                                                                        <div className='topic-question-attachment-container'>
                                                                            <div className='topic-question-attachment-icon'>
                                                                                <span className='topic-question-attachment-type'>{fileExtn}</span>
                                                                            </div>
                                                                            <div className='topic-question-attachment-info'>
                                                                                <p className='topic-question-attachment-name'>{attachment.name}</p>
                                                                                <p className='topic-question-attachment-date'>Added [{moment.unix(attachment.upload_date).format("DD/MM/YYYY")}]</p>
                                                                                <div style={{ marginTop: '12px', cursor: 'pointer' }}>
                                                                                    <span className='topic-question-download-icon'>
                                                                                        <FontAwesomeIcon title="download" icon={solid('download')} />
                                                                                    </span>
                                                                                    <span className='topic-question-download-text' onClick={() => this.downloadAttachment(uid, add_ref_id, attachName)}>Download</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                )
                                            } else {
                                                return null;
                                            }
                                        })()}


                                        <div key={question_id} style={{ display: 'flex' }}>
                                            <div className='topic-question-radio-container'>
                                                <div className="radio">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name={`yng-${question_id}`}
                                                            value="yes"
                                                            checked={radioValue === "yes"}
                                                            disabled={this.state.is_completed}
                                                            key={`yes_${question_id}`}
                                                            onChange={(e) => this.onValueChange(e, section.section_id, question_id)}
                                                        ></input>
                                                        Yes
                                                    </label>
                                                </div>
                                                <div className="radio">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name={`yng-${question_id}`}
                                                            value="no"
                                                            checked={radioValue === "no"}
                                                            disabled={this.state.is_completed}
                                                            key={`no_${question_id}`}
                                                            onChange={(e) => this.onValueChange(e, section.section_id, question_id)}
                                                        ></input>
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                            {allow_na && <div className='topic-question-radio-container1'>
                                                <div className="radio">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            value="na"
                                                            checked={radioValue == "na"}
                                                            disabled={this.state.is_completed}
                                                            onChange={(e) => this.onValueChange(e, section.section_id, question_id)}
                                                        />
                                                        N/A
                                                    </label>
                                                </div>
                                            </div>
                                            }

                                        </div>

                                        {canShow && <div className='text-area-container'>
                                            <textarea placeholder={placeholder} disabled={this.state.is_completed} onChange={(e) => this.handleTextareaChange(e, section.section_id, question_id)} value={textBoxValue} className='topic-question-input-panel' key={`ans_${topic.topic_id}_${question_id}`} id={`txt_${question_id}`}></textarea>
                                            <button disabled={this.state.is_completed} className='btn-text-area' onClick={(e) => this.onSaveButtonClick(e, section.section_id, question_id)} key={`btn_${topic.topic_id}_${question_id}`}> Save </button>
                                        </div>}
                                    </div>
                                </div>

                                {(() => {
                                    if (
                                        question.req_attach !== "" &&
                                        question.req_attach !== "no_attach" &&
                                        radioValue !== ""
                                    ) {

                                        if (radioValue != 'na') {
                                            const isCorrectAnswer =
                                                question.correct_answer.toLowerCase() === radioValue;

                                            if (
                                                (question.req_attach === "mand_incorrt_ans" && !isCorrectAnswer) ||
                                                (question.req_attach === "mand_corrt_ans" && isCorrectAnswer) ||
                                                question.req_attach === "mand_both" ||
                                                question.req_attach === "optional"

                                            ) {
                                                return (
                                                    <QuestionAttachments
                                                        attachments={response_attachments}
                                                        role_id={topic.role_id}
                                                        topic_id={topic.topic_id}
                                                        section_id={section.section_id}
                                                        question_id={question_id}
                                                        req_attach={question.req_attach}
                                                        assignment_id={assessment_necessary_object.assignment_id}
                                                    />
                                                );
                                            }
                                        }
                                    }
                                    return null;
                                })()}

                            </div>
                        })}
                    </div>
                </div >
            }
        });



        return (
            <div style={{ position: 'relative' }}>
                {
                    this.state.assessment_final_click
                        ? <div>
                            <AssessmentCompletion />
                        </div>
                        : <div>
                            {sectionsItem}
                            {this.state.show_review_button && <button className='reviewed-btn' onClick={this.saveReviewQuestions}>Review</button>}
                            <div style={{ marginTop: '67px' }}></div>
                        </div>
                }
            </div>
        )
    }
}

export default TopicQuestion;
