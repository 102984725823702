class RespoUtils {

    getMaxRoleColor(roles) {

        let maxPriority = Infinity;
        let maxRoleColor = null;

        for (const [roleCode, role] of Object.entries(roles)) {
            const { priority, color } = role;
            if (priority < maxPriority) {
                maxPriority = priority;
                maxRoleColor = color;
            }
        }

        return maxRoleColor;
    }

    getRoleColor(role) {
        let key = role.substring(0, 3);
        let ret = '#FFFFFF';
        if (key === 'CRR') ret = '#000000';
        return ret;
    }
    getRoleBGColor(role) {
        let key = role.substring(0, 3);
        return "";
    }

    getPath() {
        return '/images/';
    }

}

export default new RespoUtils();
