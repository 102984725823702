import React from 'react';
import styled from 'styled-components';
import * as Style from '../Common/StyledComponents';
import CSLLoader from '../Common/CSLLoader';
import AlertBox from '../Common/AlertBox.js';
import { MdClose } from 'react-icons/md';
import Store from '../../Common/Store.js';
import CreateRoles from './CreateRole';
import AssignmentSectionNew from './AssignmentSectionNew';
//import { uuid } from 'uuidv4';
import APICall from '../../Common/APICall.js';
import EventBus from '../../Common/EventBus';


const moment = require('moment');

class CreateUpdateRoles extends React.Component {
	state = {
		ready : false, role_list: [], prefered_columns: null, alert_param: null, role_type: '', role_type_list : [], list_contacts : [],
		base: '', show_role_menu: false, mode: 'add', show_new_role_sec: false, active_section_id: this.props.hasOwnProperty('show_section') ? this.props.show_section : "general",
		role_info : {
			role_type_id : 0,
			role_id : 0,
			role_code : "",
			title : "",
			reg_def : "",
			firm_def : "",
			comment : "",
			created_at : null,
			is_mgr_req : false,
			is_na : false,
			is_shared : false,
			is_divided : false,
			na_date_ranges : [],
			shared_date_ranges : [],
			divided_date_ranges : [],
			assignment_info : [],
			action : "insert"
		},
		activate_assignment_section : false
	};

	componentDidMount() {
		this.initialiseState();
		EventBus.registerEvent('deactivate_ass_sec', 'CreateUpdateRole', this.deactivateAssignmentTab)
	}

	componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			console.log("After Save State Change Create Update Role");
			this.initialiseState();
		}
	}

	initialiseState(){
		//let content = this.props.mode == 'add' ? {} : this.props.content
		//let content = this.props.content;
		console.log("inside createupdateroles ==>", this.props);
		const role_list = Store.getStoreData("role_list");
        const role_type_list = Store.getStoreData("role_type_list");
        const list_contacts = Store.getStoreData("listcontacts");
		let activate_assignment_section = this.props.activate_assignment_section;
		let role_info = JSON.parse(JSON.stringify(this.state.role_info));
		if (this.props.mode === 'edit') {
			const role = role_list.find(item => item.id === this.props.edit_role_id);
			const role_json = JSON.parse(role.role_json);
			
			role_info.role_type_id = role.role_type_id;
			role_info.role_id = role.id;
			role_info.role_code = role.role_code;
			role_info.title = role_json.title;
			role_info.reg_def = role_json.regulator_definition;
			role_info.firm_def = role_json.firm_definition;
			role_info.comment = role_json.comment;
			role_info.created_at = this.toDateValueAsDatabase(role.created_at);
			role_info.is_mgr_req = role.is_manager_required === 1 ? true : false;
			role_info.is_na = role.is_na === 1 ? true : false;
			role_info.is_shared = role.is_shared === 1 ? true : false;
			role_info.is_divided = role.is_divided === 1 ? true : false;
			role_info.action = "nochange";
		}
		console.log("role_info =========>", role_info);
		this.setState({role_type_list, role_list, mode: this.props.mode, list_contacts, activate_assignment_section, role_info})
		if (this.props.mode === 'edit') {
            this.fetchRoleStatusDateRanges(role_info.role_id);
		} else {
            this.setState({ ready : true })
        }
	}

	toDateValueAsDatabase = (date) => {
		let ret = moment(date).format('YYYY') + moment(date).format("MM") + moment(date).format("DD")
		console.log("RET : " , ret);
		return parseInt(ret);
	}

	fetchRoleStatusDateRanges = async (role_id) =>{
        //console.log("ROLE S FOR FETCH : ",role_type_id);
        const postData = { command: "list_role_status_date_ranges", role_id: role_id };
        const api = new APICall();
        let result = await api.commandWithoutCallback(postData);
		console.log(" fetchRoleStatusDateRanges : ", result);
		let role_info = this.state.role_info;
		let na_date_ranges = [];
		let shared_date_ranges = [];
		let divided_date_ranges = [];
		for(let r of result.result){
			let obj = {
				id : r.id,
				role_id : r.role_id,
				status_type : r.status_type,
				from : r.start_date,
				to : r.end_date,
				reason : r.reason,
				action : r.is_default === 1 ? "default" : "",
				temp_id : this.makeid(8)
			}
			switch(r.status_type){
				case 'na' :
					na_date_ranges.push(obj);
					break;
				case 'shared' :
					shared_date_ranges.push(obj);
					break;
				case 'divided' :
					divided_date_ranges.push(obj);
					break;
			}
		}
		role_info.na_date_ranges = na_date_ranges;
		role_info.shared_date_ranges = shared_date_ranges;
		role_info.divided_date_ranges = divided_date_ranges;
		console.log("Status Date Ranges fetched")
		this.setState({ role_info, ready : true })
	}

	makeid = (length) => {
		var result = '';
		var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() *
				charactersLength));
		}
		return result;
	}
    

    // prepareActiveTabData = (result) =>{
    //     //console.log("ROLES LENGTH : ",result.roles.length);
    //     //console.log("ACTIVE DATA FETCH : ",result.roles);
    //     let active_tab_data = [];
    //     let i=1;
    //     for(let item of result.roles){
    //         let role_detail = {};
    //         debugger;
    //         role_detail["id"] = item.role_id;
    //         role_detail["role_id"] = Store.getStoreData('role_list').find(role => role.id === item.role_id).role_code;
    //         role_detail["title"] = item.title;
    //         role_detail["description"] = item.regulator_definition + " " + item.firm_definition;
    //         role_detail["status"] = item.cur_assigned_to.length > 0 ? "ASSIGNED" : "NOT ASSIGNED";
    //         console.log("assigned length : ", item.cur_assigned_to.length);
    //         role_detail["assigned_to"] = item.cur_assigned_to.length === 0 ? "None" : item.cur_assigned_to.length === 1 ? (Store.getStoreData('listcontacts').find(con => parseInt(con.ID) === item.cur_assigned_to[0])).ContactName : <span onClick={(e) => this.showMultiple(e, item.role_id)} style={{ color: "#24B3AD", fontWeight: "700", cursor: 'pointer' }}>Multiple</span>;
    //         role_detail["date"] = moment(Store.getStoreData('role_list').find(role => role.id === item.role_id).created_at).format("DD/MM/YYYY");
    //         role_detail["shared"] = item.is_shared === false ? "N/A" : "SHARED";
    //         role_detail["divided"] = item.is_divided === false ? "N/A" : "DIVIDED";
    //         //console.log("ACTIVE T AB DATA SINGLE : ",role_detail);
    //         active_tab_data.push(role_detail);
    //         //break;
    //         //console.log("ROLE ",item.role_id);
    //         i++;
    //     }

	closeModal = (event) => {
		event.preventDefault();
		this.props.closeModal();
	}

	saveRole = (role_info) => {
		this.props.saveRole(role_info);
		this.setState({activate_assignment_section : true});
	}

	setRoleInfoFromCreateRole = (role_info) => {
		this.setState({ role_info });
		console.log("Role Info from Create Update Role : ", role_info);
	}

	closeAlert = (result, stack) => {
		this.setState({ alert_param: null })
	}

	changeTab = (active_tab) => (event) => {
		event.preventDefault();
		this.setState({ active_section_id: active_tab })
	}

	activateAssignmentTab = (is_dirty) => {
		if(is_dirty === true) this.setState({activate_assignment_section : false});
		else this.setState({activate_assignment_section : true});
	}

	deactivateAssignmentTab = (is_dirty) => {
		console.log("Deactivate Assignment Section Called", is_dirty);
		this.setState({activate_assignment_section : !(is_dirty)});
	}
	render() {
		console.log("THIS STATE CreateUpdateRole : ", this.state);
		if (this.state.ready === false) {
			return (<CSLLoader />);
		}

		return (
			<div style={{ backgroundColor: "#f2f2f3", position: "relative", top: "50px", left: "300px", width: "55%", marginBottom : "20px" }}>
				<Style.ModalContainer style={{ color: "#1B3451" }}>
					<Style.ModalHeader style={{ color: "#1B3451", height : "100px" }}>
						<div style={{ fontSize: "20px", float: "left", marginTop: "15px", marginLeft: "30px" }}>
							{
								(() => {
									if (this.state.mode == 'add'|| this.state.mode === 'new') {
										return "Create Role"
									} else {
										return "Update Role"
									}
								})()
							}
						</div>
						{
							(() => {
								if (this.state.mode === 'edit') {
									return (
										<div style={{ fontSize: "13px", marginTop: "50px", marginLeft: "30px", marginBottom: "20px", paddingRight : "80px" }}>
											Use this form to update this role. Depending on your role, you may be able to view or update the information.
										</div>
									)

								}
							})()
						}


						<Style.ModalHeaderCloseBtn style={{ fontWeight: 200 }} onClick={this.closeModal}><MdClose /></Style.ModalHeaderCloseBtn>
					</Style.ModalHeader>
                    <div className='tab-container'>
                        {
                            (() => {
								if(Store.getStoreData('role') !== 'admin_manager') 
									return (<div className='tab-inactive-rev'>General</div>);
                                if (this.state.active_section_id === "general") {
                                    return (<div className='tab-active-rev' >General</div>);
                                } else {
                                    return (<div className='tab-inactive-rev' onClick={this.changeTab('general')}>General</div>);
                                }
                            })()
                        }
                        {
                            (() => {
                                if(this.state.activate_assignment_section === true){
                                    if (this.state.active_section_id === "assignment") {
                                        return (<div className='tab-active-rev'>Assignment </div>);
                                    } else {
                                        return (<div className='tab-inactive-rev' onClick={this.changeTab('assignment')}>Assignment</div>);
                                    }
                                }else{
                                    return (<div style={{display : "flex", flexDirection : "row", flexWrap : "nowrap", alignItems : "center"}}><div className='tab-inactive-rev'>Assignment</div> <div style={{display : "inline-block", fontStyle : "italic"}}>*Please save role first to activate assignment section</div> </div>);
                                }
                                
                            })()
                        }
                    </div>
					{
						(() => {
							if (this.state.active_section_id === "general" && this.state.mode !== 'new') {
								return (<CreateRoles role_info = {this.state.role_info} role_list={this.state.role_list} role_type_list={this.state.role_type_list} listContacts = {this.state.list_contacts} 
									closeModal={this.closeModal} saveRole={this.saveRole} sendRoleInfo={this.setRoleInfoFromCreateRole} activateAssignmentTab={this.activateAssignmentTab}/>);
							} else if(this.state.active_section_id === "general" && this.state.mode === 'new') {
								return (<CreateRoles role_info = {this.state.role_info} role_list={this.state.role_list} role_type_list={this.state.role_type_list} listContacts = {this.state.list_contacts} 
										closeModal={this.closeModal} saveRole={this.saveRole} sendRoleInfo={this.setRoleInfoFromCreateRole}/>);
							} else {
								return (<div></div>)
							}
						})()
					}

					{
						(() => {
							if (this.state.active_section_id === "assignment" && this.state.mode !== 'new') {
								return <AssignmentSectionNew  mode={this.state.mode} role_info = {this.state.role_info} role_type_list={this.state.role_type_list} list_contacts={this.state.list_contacts} 
								closeModal={this.closeModal} saveRole={this.saveRole} sendRoleInfo={this.setRoleInfoFromCreateRole}/>
							} else if(this.state.active_section_id === "assignment" && this.state.mode === 'new') {
								return (<AssignmentSectionNew  mode={this.state.mode} role_info = {this.state.role_info} role_type_list={this.state.role_type_list} 
									closeModal={this.closeModal} saveRole={this.saveRole} sendRoleInfo={this.setRoleInfoFromCreateRole}/>);
							} else {
								return (<div></div>)
							}
						})()
					}
				</Style.ModalContainer>
				<AlertBox alertParam={this.state.alert_param} />
			</div>
		);
	}
}

export default CreateUpdateRoles;
