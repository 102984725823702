import React, { Component } from 'react'
import PersonnelFilter from './PersonnelFilter';
import DateFilter from './DateFilter';
import StatusFilter from './StatusFilter';
import './sor.css'
import SortByFilter from './SortByFilter';
import RoleTypeFilter from './RoleTypeFilter';
import SorContext from "./SorContext";


class TaskTracker extends Component {
    constructor (props) {
        super(props);
        this.state = {
            popOverDisplay: {
                name: '',
                position: null,
            }
        };
    }

    static contextType = SorContext;

    showPopOver = (e, name, position) => {
        e.preventDefault();
        if (this.state.popOverDisplay.name == '') {
            this.setState({
                popOverDisplay: {
                    ...this.state.popOverDisplay,
                    name: name,
                    position: position,

                }
            });
        } else {
            this.closeFilter();
        }
    };

    closeFilter = () => {
        this.setState({
            popOverDisplay: {
                ...this.state.popOverDisplay,
                name: '',
                position: null,
            }
        });
    }

    render() {
        const { name, position } = this.state.popOverDisplay;
        const { selected_report_parameter } = this.context;
        let dynamicStyle = { top: (position ?? {}).y - 100, left: (position ?? {}).x - 490 }
        return (
            <div class='filter-container-first'>
                <div class='filter-personal filter-box' onClick={(e) => this.showPopOver(e, 'personnel', { x: 1035, y: 90 })}>
                    <p className='primary-text'>{selected_report_parameter.firstSection.personalFilter.primaryText}</p>
                    <p className='secondary-text text-overflow-handle'>
                        {selected_report_parameter.firstSection.personalFilter.secondaryText}
                    </p>
                </div>
                <div class='filter-date filter-box' onClick={(e) => this.showPopOver(e, 'date', { x: 1030, y: 260 })}>
                    <p className='primary-text'>{selected_report_parameter.firstSection.dateFilter.primaryText}</p>
                    <p className='secondary-text'>{selected_report_parameter.firstSection.dateFilter.secondaryText}</p>
                </div>
                <div class='filter-status filter-box' onClick={(e) => this.showPopOver(e, 'status', { x: 1030, y: 320 })}>
                    <p className='primary-text'>{selected_report_parameter.firstSection.statusFilter.primaryText}</p>
                    <p className='secondary-text text-overflow-handle'>{selected_report_parameter.firstSection.statusFilter.secondaryText}</p>
                </div>
                <div class='filter-sortby filter-box' onClick={(e) => this.showPopOver(e, 'sortby', { x: 1030, y: 390 })}>
                    <p className='primary-text'>{selected_report_parameter.firstSection.sortByFilter.primaryText}</p>
                    <p className='secondary-text text-overflow-handle'>{selected_report_parameter.firstSection.sortByFilter.secondaryText}</p>
                </div>
                <div class='filter-sortby filter-box' onClick={(e) => this.showPopOver(e, 'role_types', { x: 1030, y: 400 })}>
                    <p className='primary-text'>{selected_report_parameter.firstSection.roleTypeFilter.primaryText}</p>
                    <p className='secondary-text text-overflow-handle'>{selected_report_parameter.firstSection.roleTypeFilter.secondaryText}</p>
                </div>

                {
                    (name && position && name == 'personnel') && (
                        <div className='popover pop-personal' style={dynamicStyle}>
                            Personnel
                            <PersonnelFilter closeFilter={this.closeFilter} />
                        </div>
                    )
                }
                {
                    (name && name == 'date') && (
                        <div className='popover pop-date' style={dynamicStyle}>
                            Date
                            <DateFilter closeFilter={this.closeFilter}></DateFilter>
                        </div>
                    )
                }
                {
                    (name && name == 'status') && (
                        <div className='popover pop-status' style={dynamicStyle}>
                            Status
                            <StatusFilter closeFilter={this.closeFilter} selected_report_parameter={selected_report_parameter} />
                        </div>
                    )
                }
                {
                    (name && name == 'sortby') && (
                        <div className='popover pop-sortby' style={dynamicStyle}>
                            Sort By
                            <SortByFilter closeFilter={this.closeFilter} />
                        </div>
                    )
                }
                {
                    (name && name == 'role_types') && (
                        <div className='popover pop-roletypes' style={dynamicStyle}>
                            Role Type
                            <RoleTypeFilter closeFilter={this.closeFilter} />
                        </div>
                    )
                }
            </div >
        )
    }
}

export default TaskTracker
