import React from 'react';
import CSLLoader from '../Common/CSLLoader';
import APICall from '../../Common/APICall.js';
import Store from '../../Common/Store.js';
import AlertBox from '../Common/AlertBox.js';
import './MConfig.css'
import SmallSpinner from '../Common/SmallSpinner';


class General extends React.Component {
	state = { general: null, orig_general: null, alert_param: null, isLoadedIcon: false }

	async componentDidMount() {
		const api = new APICall()
        const postData = {command: "get_mc_general"}
        const result = await api.commandWithoutCallback(postData)
		console.log("result:", result)
		result.result.config_json = JSON.parse(JSON.stringify(result.result.config_json))       
		// this.setState({general: null, orig_general: JSON.parse(JSON.stringify(result.result.config_json))})
		this.setState({general: result.result.config_json, orig_general: JSON.parse(JSON.stringify(result.result.config_json))});
	}

	handleChange = (event) => {
		event.preventDefault();
		let general = this.state.general;
		general[event.target.name] = event.target.value === "" ? "" : parseInt(event.target.value);
		this.setState({ general });
	}

	updateGeneral = async (event) => {
		event.preventDefault()
		this.setState({isLoadedIcon: true})
		let api = new APICall()
		let postData = {command: "save_mc_general", data: this.state.general}
		let result = await api.commandWithoutCallback(postData);
		let general = JSON.parse(JSON.stringify(this.state.general))
		this.setState({orig_general: general, isLoadedIcon: false})
	}
	closePage = (event) => {
		//event.preventDefault()
		let url = "/"
		window.location.replace(url);
	}

	render() {
		if (this.state.general === null) {
			// return <div><CSLLoader /></div>
			return <div className='mc-general-empty-container'><SmallSpinner /></div>
		}

		return (
			<div>
				{
					(() => {
						if(this.state.isLoadedIcon){
							return <div><CSLLoader style={{position: 'absolute'}}/></div>
						}
					})()
				} 
				<div className='mc-general-container'>
					<div className='mc-general-label'><span className='mc-general-label-header'>Allow Responsibilities to overlap - </span>responsibilities that are not shared or divided may be shared temporarily like during a handover period, for example.</div>
					<div className='mc-general-input-container'>
						<input className = 'mc-general-input' type='text' name="respo_overlap_window" onChange={this.handleChange} value={this.state.general.respo_overlap_window} />
						days (0 indicates that no overlap is allowed).
					</div>
					<div className='mc-general-label'><span className='mc-general-label-header'>Assessment Projection Window - </span>The number of days the Assessment Overview screens look to the future to show projected assessments and when they are due.</div>
					<div className='mc-general-input-container'>
						<input className = 'mc-general-input' type='text' name="assest_project_window" onChange={this.handleChange} value={this.state.general.assest_project_window} />
						days into the future.
					</div>
					<div className='mc-general-footer'>
						<button className='mc-general-cancel-button' onClick={this.closePage}>Cancel</button>
						<button className='mc-general-save-button' onClick={this.updateGeneral}>Save</button>
					</div>

					<AlertBox alertParam={this.state.alert_param} />
				</div>
			</div>
		);
	}
}

export default General;
