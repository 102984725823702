import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, thin, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import "./../Moduleconfig/Modals/Bulk.css";
import moment from 'moment';
import APICall from '../../Common/APICall';

import { saveAs } from 'file-saver';

class BulkModalEmployee extends React.Component {

    state = { attachments: [], files_content: {}, show_bulk_review_modal: false }

    constructor (props) {
        super(props)
        this.fileInputRef = React.createRef();
        this.openFileDialog = this.openFileDialog.bind(this);
    }

    componentDidMount() {
        this.initialiseState();
    }

    initialiseState = () => {
        let fileContent = {};
        let previousFiles = this.props.previousFiles;
        for (let file of previousFiles) {
            let uid = file["uid"];
            fileContent[uid] = "";
        }
        this.setState({ attachments: previousFiles, files_content: fileContent });
    }

    openFileDialog() {
        this.fileInputRef.current.click();
    }

    onFileDrop = async (e) => {
        e.preventDefault()
        e.stopPropagation()
        const sldata = JSON.parse(localStorage.getItem("site_layout"));
        let contact_id = sldata["contact_id"];
        let attachments = JSON.parse(JSON.stringify(this.state.attachments))
        let files_content = JSON.parse(JSON.stringify(this.state.files_content))
        for (let file of e.dataTransfer.files) {
            const content = await this.readFile(file)
            const id = this.genKey(16).toString()
            attachments.push({ uid: id, name: file.name, size: file.size, size_hr: this.getHumanReadableFileSize(file.size), bin_file: content, user_id: contact_id, upload_date: moment().unix(), is_new: 1 })
            files_content[id] = content
        }

        this.setState({ attachments, files_content }, () => {
            this.props.filesTobeAdded(attachments);
        })

    }

    onDragOver = (event) => {
        event.preventDefault();
    }

    onDragEnter = (e) => {
        e.stopPropagation();
    }

    onFilesAdded = async (e) => {
        const sldata = JSON.parse(localStorage.getItem("site_layout"));
        let contact_id = sldata["contact_id"];
        let attachments = JSON.parse(JSON.stringify(this.state.attachments))
        let files_content = JSON.parse(JSON.stringify(this.state.files_content))
        for (let file of e.target.files) {
            const content = await this.readFile(file)
            const id = this.genKey(16).toString()
            attachments.push({ uid: id, name: file.name, size: file.size, size_hr: this.getHumanReadableFileSize(file.size), bin_file: content, user_id: contact_id, upload_date: moment().unix(), is_new: 1 })
            files_content[id] = content
        }

        this.setState({ attachments, files_content }, () => {
            this.props.filesTobeAdded(attachments);
        });
    }

    readFile = async (file) => {
        const promise = new Promise((resolve, reject) => {
            let reader = new FileReader()
            reader.readAsBinaryString(file)
            reader.onload = () => {
                resolve(window.btoa(reader.result))
            }
        })
        return promise;
    }

    goNext = (event) => {
        event.preventDefault()
        this.setState({ show_bulk_review_modal: true })
    }

    closeModal = (event) => {
        event.preventDefault()
        this.props.closeUserDialogFolder()
    }

    removeAttachment = async (attach_id) => {
        let attachment = this.state.attachments.find((a) => a["uid"] == attach_id);
        if (Object.keys(attachment).length !== 0) {
            if (attachment.is_new == 0) {
                let postData = { command: "topics_api", action: "delete_qset_binfile", uid: attach_id, add_ref_id: attachment.add_ref_id, employee_id: this.props.employee_id };
                console.log('post data', postData);
                let api = new APICall();

                let response = await api.commandWithoutCallback(postData);
            }
        }

        let files_content = JSON.parse(JSON.stringify(this.state.files_content))
        let attachments = []
        for (let item of this.state.attachments) {
            if (item.uid !== attach_id) attachments.push(item)
        }
        delete files_content[attach_id]

        this.setState({ attachments, files_content });
        this.props.filesToBeRemoved(attachments);
    }

    downloadFile = async (attach_id) => {
        let attachment = this.state.attachments.find((a) => a["uid"] == attach_id);
        if (Object.keys(attachment).length !== 0) {
            if (attachment.is_new == 0) {
                let postData = { command: "topics_api", action: "fetch_qset_binfile_content", uid: attach_id, add_ref_id: attachment.add_ref_id };
                // console.log('post data', postData);
                let api = new APICall();
                let response = await api.commandWithoutCallback(postData);
                let file_data = response.binFileContent;
                this.downloadBase64File(file_data, attachment.name);
            } else {
                let files_content = JSON.parse(JSON.stringify(this.state.files_content));
                let file_data = files_content[attach_id];
                this.downloadBase64File(file_data, attachment.name);
            }
        }
    }

    downloadBase64File = (base64String, filename) => {
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/octet-stream' });
        saveAs(blob, filename);
    }

    getExtension = (file_name) => {
        const file_parts = file_name.split(/\.(?=[^\.]+$)/)
        let ext = "PDF";
        switch (file_parts[file_parts.length - 1].toLowerCase()) {
            case "jpeg":
            case "jpg":
                ext = "JPG"; break;
            case "png":
                ext = "PNG"; break;
            case "docx":
                ext = "DOC"; break;
            case "doc":
                ext = "DOC"; break;
            case "msg":
                ext = "MSG"; break;
            case "txt":
                ext = "TXT"; break;
            case "ppt":
                ext = "PPT"; break;
            case "pptx":
                ext = "PPT"; break;
            case "xls":
                ext = "XLS"; break;
            case "xlsx":
                ext = "XLSX"; break;
            default:
                ext = "PDF"; break;
        }
        return ext
    }

    getHumanReadableFileSize = (number_of_bytes) => {
        const units = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        const exponent = Math.min(Math.floor(Math.log(number_of_bytes) / Math.log(1024)), units.length - 1)
        const approx = number_of_bytes / 1024 ** exponent
        const output = exponent === 0 ? `${number_of_bytes} bytes` : `${approx.toFixed(1)} ${units[exponent]}`
        return output
    }

    genKey = (length) => {
        var result = "";
        var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };

    closeBulkReviewModal = () => {
        this.setState({ show_bulk_review_modal: false })
    }

    calluploader = () => {
        document.getElementById("upload-attachment").click();
    }

    render() {
        const menu_options = [
            { name: "remove", nick_name: "Remove", clickHandler: this.removeAttachment }
        ]
        return (
            <React.Fragment>
                <div className="bulk-body" style={{ display: 'none' }}>
                    <div className="upload-inner-box" >
                        <div><button id="upload-attachment" className="upload-btn" onClick={this.openFileDialog}>Or browse...</button></div>
                        <input
                            ref={this.fileInputRef}
                            style={{ display: "none" }}
                            type="file"
                            multiple
                            accept=".pdf,.jpg,.jpeg,.png,.docx,.doc,.msg,.txt,.ppt,.pptx,.xls,.xlsx"
                            onChange={this.onFilesAdded}
                        />
                    </div>
                </div>
                <div>
                    <table className="emp-file">
                        <tbody>
                            {this.state.attachments.map(item => (
                                <tr key={item.uid} style={{ marginBottom: '10px' }}>
                                    <td className="ext emp-file-td">{this.getExtension(item.name)}</td>
                                    <td style={{ paddingRight: '13px', textAlign: 'center', paddingLeft: '13px' }}>
                                        <div style={{ fontWeight: "600" }}>{item.name}</div>
                                        <div style={{ color: "#6e6d6d" }}>{item.size_hr}</div>
                                    </td>
                                    <td className="emp-file-td attachment-action">
                                        <span>
                                            <FontAwesomeIcon title="download" icon={solid('download')} onClick={() => this.downloadFile(item.uid)} />
                                        </span>
                                        <span style={{ marginLeft: "10px" }}>
                                            <FontAwesomeIcon onClick={() => this.removeAttachment(item.uid)} title="remove" icon={solid('xmark')} />
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </React.Fragment >
        )
    }
}

export default BulkModalEmployee;
