import React from "react";
import CSLTable from "../Common/CSLTable";
import APICall from "../../Common/APICall";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import SmallSpinner from "../Common/SmallSpinner";
import styled from 'styled-components'

const EmailBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 25px;
`;
const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;

class Reporting extends React.Component {

    state = {
        ready: false,
        general: null,
        access_reporting: false,
        url_permissions: {}
    }

    async componentDidMount() {
        await this.setComponentState()
    }

    // async componentDidUpdate(prevProps) {
    //     if (this.props !== prevProps) {
    //         await this.setComponentState()
    //     }
    // }

    setComponentState = async () => {        
        const api = new APICall()
        const postData = {command: "get_mc_general"}
        const result = await api.commandWithoutCallback(postData)
		console.log("result:", result)
        let config_json = JSON.parse(JSON.stringify(result.result.config_json))
        let access_reporting = config_json.access_reporting
        let url_permissions = config_json.url_permissions
        this.setState({general: result.result.config_json, access_reporting, url_permissions, ready: true})
    }  
    
    isCheckUnchecked = (row_id) => async (event) => {
        event.preventDefault()
        let url_permissions = this.state.url_permissions;
        url_permissions[row_id].respomap = url_permissions[row_id].respomap === 1 ? 0 : 1;
        url_permissions[row_id].smcr_report = url_permissions[row_id].smcr_report === 1 ? 0 : 1;
        console.log("url_permissions===>",row_id, url_permissions)   
        this.setState({url_permissions})
        let api = new APICall()
        let {general} = this.state;
        general.access_reporting = this.state.access_reporting
        general.url_permissions = url_permissions
		let postData = {command: "save_mc_general", data: general}
		let result = await api.commandWithoutCallback(postData)
		console.log(result)        
    }

    tableData = () => {
        let ret = { data: [], columns: [] };
        ret.columns = [
            { Header: 'Role', accessor: 'role', filterable: true, minWidth: 330 },
            {'Header' : 'Enabled', Cell: row => (
                <div>
                       {
                           (()=> {
                               if (row.original.enabled) {
                                   return(<FontAwesomeIcon className="mc-roles-add-icon" title="Edit Role Type" icon={solid('check-square')} onClick={this.isCheckUnchecked(row.original.id)}/>);
                               } else {
                                   return(<FontAwesomeIcon className="mc-roles-add-icon" title="Edit Role Type" icon={solid('square')} onClick={this.isCheckUnchecked(row.original.id)}/>);
                               }
                           })()
                       }
                </div>
             ), headerStyle:{textAlign: 'left'}}
        ];

        let nameListRole = {'admin_manager': 'Admin Manager', 'hr': 'HR', 'team': 'Team'}
        for (let k in this.state.url_permissions) {
            let elem = {
                id: k,
                role: nameListRole[k],     
                enabled: this.state.url_permissions[k].respomap === 1 ? true : false           
            }
            ret.data.push(elem);
        }
        
        return ret;
    }

    switchRadio = async () => {	
        let {access_reporting} = this.state;
		access_reporting = access_reporting ? false : true;	
        this.setState({access_reporting})	
        let api = new APICall()
        let {general} = this.state;
        general.access_reporting = access_reporting
        general.url_permissions = this.state.url_permissions
		let postData = {command: "save_mc_general", data: general}
		let result = await api.commandWithoutCallback(postData)
		console.log(result)   
	}   

    render() {
        if (!this.state.ready) {			
			return <div style={{backgroundColor: "none"}} className='mc-general-empty-container'><SmallSpinner /></div>
		}
        return (
            <div className="mc-general-container"> 
                <EmailBoldLabel style={{display: "inline-block"}}>Restrict access to Reporting?</EmailBoldLabel>
                {
                    (() => {
                        if (this.state.access_reporting) {
                            return (
                                <RadioBlock>
                                    <SelectedRadio>Yes</SelectedRadio>
                                    <UnselectedRadio onClick={this.switchRadio}>No</UnselectedRadio>
                                </RadioBlock>
                            );
                        } else {
                            return (
                                <RadioBlock>
                                    <UnselectedRadio onClick={this.switchRadio}>Yes</UnselectedRadio>
                                    <SelectedRadio>No</SelectedRadio>
                                </RadioBlock>
                            );
                        }
                    })()
                } 
                {
                    (() => {
                        if(this.state.access_reporting){
                            return(<div style={{marginTop: "10px"}} className="mc-table-container">
                            <CSLTable processData={this.tableData} />                    
                        </div>)
                        }
                    })()
                } 
            </div>
        )
    }
}

export default Reporting