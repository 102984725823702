import { utils, writeFile } from 'xlsx';

export default class ExcelGenerator {
    constructor (mapper, data, fileName) {
        // Create a new workbook
        const wb = utils.book_new();

        // Convert the data to an array of arrays
        const dataArray = data.map(obj =>
            Object.values(obj).filter(val => val !== undefined)
        );

        // Map the column names to an array of values
        const headerArray = mapper.map(col => Object.values(col)[0]);

        // Create a new worksheet with the data
        const ws = utils.aoa_to_sheet([headerArray, ...dataArray]);
        utils.book_append_sheet(wb, ws, "Sheet1");

        // Save the workbook as an Excel file and offer it for download
        writeFile(wb, `${fileName}.xlsx`, { type: "base64" });
    }
}
