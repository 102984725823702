import React from "react"
import "./ModalStyle.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import APICall from "../../../Common/APICall"
import AlertBox from "../../Common/AlertBox"

class EmailConfigModal extends React.Component {

    state = { email: null, subject_cursor: null, body_cursor: null, alert_param: null }

    componentDidMount() {
        this.setComponentState()
    }

    setComponentState = () => {
        console.log("this.props:", this.props)
        let email = JSON.parse(JSON.stringify(this.props.current_email))
        email.lookup_json = JSON.parse(email.lookup_json)
        this.setState({ email })
    }

    setEntity = (event) => {
        event.preventDefault()
        let email = JSON.parse(JSON.stringify(this.state.email))
        email.lookup_json[event.target.name] = event.target.value.replace(/\n/g, '--lf--')
        this.setState({ email, subject_cursor: null, body_cursor: null })
    }

    setSubjectCursorPosition = (event) => {
        event.preventDefault();
        const subject_cursor = event.target.selectionStart;
        this.setState({ subject_cursor, body_cursor: null, currentField: event.target.name, });
    }

    setBodyCursorPosition = (event) => {
        event.preventDefault();
        const cursorPosition = event.target.selectionStart;
        this.setState({ body_cursor: cursorPosition, currentField: event.target.name, subject_cursor: null });
    }


    // insertVariable = (variable_name) => (event) => {
    //     event.preventDefault();
    //     debugger;
    //     variable_name = `{{${variable_name}}}`;
    //     let email = JSON.parse(JSON.stringify(this.state.email));

    //     if (this.state.currentField === "subject") {
    //         const subject_cursor = this.state.subject_cursor;
    //         email.lookup_json.subject = [email.lookup_json.subject.slice(0, subject_cursor), variable_name, email.lookup_json.subject.slice(subject_cursor)].join('');
    //         this.setState({ email, subject_cursor: null });
    //     } else if (this.state.currentField === "body") {
    //         const body_cursor = this.state.body_cursor;
    //         email.lookup_json.body = [email.lookup_json.body.slice(0, body_cursor), variable_name, email.lookup_json.body.slice(body_cursor)].join('');
    //         this.setState({ email, body_cursor: null });
    //     }
    // }

    insertVariable = (variable_name) => (event) => {
        event.preventDefault();
        const { currentField, email, body_cursor } = this.state;
        variable_name = `{{${variable_name}}}`;
        if (currentField === "subject") {
            const subject_cursor = this.state.subject_cursor;
            email.lookup_json.subject = [email.lookup_json.subject.slice(0, subject_cursor), variable_name, email.lookup_json.subject.slice(subject_cursor)].join('');
            this.setState({ email, subject_cursor: null });
        }
        if (currentField === "body") {
            const body = email.lookup_json.body;
            const lines = body.split("--lf--");
            let charCount = 0;
            let adjustedCursor = body_cursor;

            for (let i = 0; i < lines.length; i++) {
                const line = lines[i];
                charCount += line.length + 1;

                if (charCount >= adjustedCursor) {
                    const lineCursor = adjustedCursor - (charCount - line.length - 1);
                    lines[i] = `${line.slice(0, lineCursor)}${variable_name}${line.slice(lineCursor)}`;
                    email.lookup_json.body = lines.join("--lf--");
                    this.setState({ email, body_cursor: null });
                    return;
                }
            }

            // Handle an invalid cursor position
            console.error("Invalid cursor position");
        }
    };




    modifyEmail = async (event) => {
        event.preventDefault()
        const payload = { command: "email_config", action: "modify_email", email: this.state.email }
        const api = new APICall()
        const ret = await api.commandWithoutCallback(payload)
        let alert_param = null
        if (ret.error_code === 0) {
            alert_param = {
                title: 'SUCCESS', message: "Email Modifed Successfully.", ok_text: 'Ok',
                alertHandler: this.processAfterSave, stack: {}, position: { top: "22vh", left: "7vw" }
            }
        } else {
            alert_param = {
                title: 'ERROR', message: ret.error_msg, ok_text: 'Ok',
                alertHandler: () => { this.setState({ alert_param: null }) }, stack: {}, position: { top: "22vh", left: "7vw" }
            }
        }
        this.setState({ alert_param })
    }

    processAfterSave = (result, stack) => {
        this.props.setComponentState()
    }

    closeModal = (event) => {
        event.preventDefault()
        this.props.closeEditModal()
    }

    render() {
        console.log("Email state:", this.state.email)
        if (this.state.email === null) return <div></div>
        return (
            <div className="add-role-modal-container" style={{ width: "58%" }}>
                {this.state.alert_param !== null && <AlertBox alertParam={this.state.alert_param} />}
                <div className="add-role-modal-header-container">
                    <div className="add-role-modal-header-text-container">
                        <div className="add-role-modal-header-title">Configure Email</div>
                        <div className="add-role-modal-header-subtitle">{this.props.current_email.name}</div>
                    </div>
                    <FontAwesomeIcon icon={solid('xmark')} onClick={this.closeModal} size="2x" style={{ cursor: "pointer" }} />
                </div>
                <div className="mc-em-modal-body">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                        <div className="add-role-modal-label" style={{ width: "12%" }}>Subject</div>
                        <div style={{ width: "84%" }}>
                            <input className="add-role-modal-input" type="text" name="subject" value={this.state.email.lookup_json.subject} onChange={this.setEntity} onClick={this.setSubjectCursorPosition} onKeyUp={this.setSubjectCursorPosition} />
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", marginTop: "15px" }}>
                        <div className="add-role-modal-label" style={{ width: "12%" }}>Body</div>
                        <div style={{ width: "84%" }}>
                            <textarea rows={10} className="add-role-modal-textarea"
                                name="body" value={this.state.email.lookup_json.body.replace(/(--lf--)/g, "\n")} onChange={this.setEntity} onClick={this.setBodyCursorPosition} onKeyUp={this.setBodyCursorPosition}></textarea>
                        </div>
                    </div>
                    <div className="mc-em-hr"></div>
                    <div style={{ margin: "10px 0px 5px 0px", fontWeight: "600", fontSize: "15px" }}>Variables</div>
                    <div style={{ display: "flex", columnGap: "10px", flexWrap: "wrap" }}>
                        {
                            this.props.variables.map(item => {
                                return <div className="mc-em-vr-pill" onClick={this.insertVariable(item.name)}>
                                    {item.nickname}
                                </div>
                            })
                        }
                    </div>
                </div>
                <div className="mc-em-modal-footer-container">
                    <button className="mc-em-btn mc-em-success">Send Test Email</button>
                    <div style={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
                        <button className="mc-em-btn mc-em-normal" onClick={this.closeModal}>Cancel</button>
                        <button className="mc-em-btn mc-em-success" onClick={this.modifyEmail}>Submit</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default EmailConfigModal
