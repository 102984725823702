import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import CSLTable from "../Common/CSLTable";
import APICall from "../../Common/APICall";
import moment from 'moment';
import "./Roles.css";
import EventBus from "../../Common/EventBus";
import AlertBox from "../Common/AlertBox";
import Store from "../../Common/Store";
import SmallSpinner from "../Common/SmallSpinner";
import SendForAcceptanceModal from "./SendForAcceptanceModal";
import "../../Components/Moduleconfig/Modals/ModalStyle.css";
// import { fal } from "@fortawesome/pro-light-svg-icons";

class Roles extends React.Component {

    state = {
        roles: [], 
        needs_acceptance: false, 
        needs_accepting_roles: false, 
        alert_param: null, 
        loading : false, 
        showmodal: false, 
        show_review_roles_modal: false,
        emp_job_title: "", 
        emp_job_desc: "",
        emp_name: "",
        assigned_roles: []
    }

    async componentDidMount() {
        await this.setComponentState()
        if (this.props.task_id !== 0) {
            this.confirmAcceptRoles();
        }
    }

    async componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
            await this.setComponentState()
        }
    }

    setComponentState = async() => {
        let list_contacts = Store.getStoreData('listcontacts');
        this.setState({ loading : true })
        const payload = {command: "personnel", action: "get_user_roles", user_id: this.props.selected_employee_id}
        const payload1 = {command: "personnel", action: 'list_jobtitles'}
        const payload2 = {command: "personnel", action: 'fetch_employee_jobtitle_id', employee_id: this.props.selected_employee_id}
        const payload3 = {command: "personnel", action: 'fetch_employee_header_data', employee_id: this.props.selected_employee_id}
        const api = new APICall();
        const [response, job_titles_response, employee_job_title_resp, employee_details_response] = await Promise.all([
            api.commandWithoutCallback(payload),
            api.commandWithoutCallback(payload1),
            api.commandWithoutCallback(payload2),
            api.commandWithoutCallback(payload3),
        ]);

        let roles = []
        let needs_acceptance = false
        let needs_accepting_roles = false
        if (response.error_code === 0) {
            if (response.result.length !== 0) {
                for (let item of response.result) {
                    let title = JSON.parse(item.role_json).title
                    if (item.requires_acceptance === 1) {
                        if (item.is_accepted === 0 && item.is_rejected === 0) needs_acceptance = true
                        if (item.is_accepted === 0 && item.is_rejected === 0 && item.for_acceptance === 1) needs_accepting_roles = true
                    }
                    let elem = {
                        id: item.id,
                        type: item.name,
                        color: item.color,
                        role: item.role_code,
                        title: title,
                        requires_acceptance: item.requires_acceptance,
                        is_accepted: item.is_accepted,
                        is_rejected: item.is_rejected,
                        for_acceptance: item.for_acceptance,
                        assigned_date: moment(item.assigned_date, 'YYYYMMDD').format('DD/MM/YYYY'),
                        effective_from_date: item.effective_from_date === -1 ? "--/--/----" : moment(item.effective_from_date, 'YYYYMMDD').format('DD/MM/YYYY'),
                        effective_till_date: item.effective_till_date.toString() === process.env.REACT_APP_NO_END_DATE ? "--/--/----" : moment(item.effective_till_date, 'YYYYMMDD').format('DD/MM/YYYY')
                    }
                    roles.push(elem)
                }
            }
        }
        let emp_job_desc ="";
        let emp_job_title = "";
        let emp_job_title_obj = "";
        let emp_name = this.props.selected_employee_id !== 0 ? list_contacts.find(item => item.ID === this.props.selected_employee_id.toString()).ContactName : '-';
        if (this.props.selected_employee_id !== 0){
            let job_titles = job_titles_response.result.job_titles;
            emp_job_title_obj = job_titles.find(item => item.id === employee_job_title_resp.result.job_title_id.jt_id);
            emp_job_title = emp_job_title_obj === undefined ? "" : emp_job_title_obj.name
            emp_job_desc = employee_details_response.result.employee_details.description;
        }
        EventBus.raiseEvent('needs_acceptance', needs_acceptance)
        this.setState({roles, needs_acceptance, needs_accepting_roles, alert_param: null, loading : false, emp_job_title, emp_job_desc, emp_name})
    }

    openCloseRoleContexMenu = (event) => {
        event.preventDefault()
        // console.log("openCloseRoleContexMenu")
    }

    processRoles = () => {
        let ret = {data: [], columns: []}
        ret.columns = [
            { Header: 'TYPE', accessor: 'type', minWidth: 80, filterable: true, headerStyle: { textAlign: 'left' }, style: { fontWeight: '600' } },
            { Header: 'ROLE ID', accessor: 'role', minWidth: 40, filterable: true, headerStyle: { textAlign: 'left' }, style: { fontWeight: '600' } },
            { Header: 'TITLE', accessor: 'title', minWidth: 150, filterable: true, headerStyle: { textAlign: 'left' }, style: { fontWeight: '600' } },
            { Header: 'STATUS', accessor: 'status', minWidth: 40, filterable: true, headerStyle: { textAlign: 'left' }, style: { fontWeight: '600' } },
            { Header: 'ASSIGNED', accessor: 'assigned_date', minWidth: 40, filterable: true, headerStyle: { textAlign: 'left' }, style: { fontWeight: '600' } },
            { Header: 'EFFECTIVE FROM', accessor: 'effective_from_date', minWidth: 40, filterable: true, headerStyle: { textAlign: 'left' }, style: { fontWeight: '600' } },
            { Header: 'EFFECTIVE UNTIL', accessor: 'effective_till_date', minWidth: 40, filterable: true, headerStyle: { textAlign: 'left' }, style: { fontWeight: '600' } }
        ]
        if (this.state.roles.length !== 0) {
            for (let item of this.state.roles) {
                let status = "Accepted"
                if (item.requires_acceptance === 1) {
                    if (item.is_accepted === 0 && item.is_rejected === 0) status = "Assigned"
                    if (item.for_acceptance === 1) status = "Pending Acceptance"
                    if (item.is_accepted === 1) status = "Accepted"
                    if (item.is_rejected === 1) status = "Rejected"
                }
                ret.data.push({
                    id: item.id,
                    type: item.type,
                    role: item.role,
                    title: item.title,
                    status: status,
                    assigned_date: item.assigned_date,
                    effective_from_date: item.effective_from_date,
                    effective_till_date: item.effective_till_date
                })
            }
        }
        return ret;
    }
    processAwatingAcceptanceRoles = () => {
        let ret = {data: [], columns: []}
        ret.columns = [
            { Header: 'ROLE ID', accessor: 'role', width: 130, filterable: true, headerStyle: { textAlign: 'left' }, style: { fontWeight: '600' },
                Cell: row => (
                    <div style={{ display: "flex", columnGap: "10px" }}>
                        <div style={{ width: "15px", height: "15px", backgroundColor: `${row.original.color}`, borderRadius: "2px" }}></div>
                        <div style={{ fontWeight: "600" }}>{row.original.role}</div>
                    </div>
                )
            },
            { Header: 'TITLE', accessor: 'title', width: 150, filterable: true, headerStyle: { textAlign: 'left' }, style: { fontWeight: '600' }},
            { Header: 'DESCRIPTION', accessor: 'description', Width: 230, filterable: true, headerStyle: { textAlign: 'left' }, style: { fontWeight: '600' }},
            { Header: 'EFFECTIVE', accessor: 'effective_from_date', minWidth: 30, filterable: true, headerStyle: { textAlign: 'left' }, style: { fontWeight: '600' }},
            { Header: 'ACCEPTED', accessor: 'accepted_date', minWidth: 30, filterable: true, headerStyle: { textAlign: 'left' }, style: { fontWeight: '600' }},
            { Header: 'SHARING', accessor: 'sharing', minWidth: 20, filterable: true, headerStyle: { textAlign: 'left' }, style: { fontWeight: '600' }}
        ]
        if (this.state.assigned_roles.length !== 0) {
            let share = "";
            for (let item of this.state.assigned_roles) {
                if (item.shared === 0 && item.divided === 0){
                    share = "Not Shared";
                }
                else if (item.shared === 1 && item.divided === 0){
                    share = "Shared";
                }
                else if (item.shared === 0 && item.divided === 1){
                    share = "Divided";
                }else{
                    share = "Sh/Div";
                }
                let elem = {
                    color: item.color,
                    role: item.role_code,
                    title: item.role_title,
                    description: item.reg_def,
                    effective_from_date: item.eff_from_date,
                    accepted_date: "--/--/----",
                    sharing: share
                }
                ret.data.push(elem);
            }
        }
        return ret;
    }

    // showConfirmation = (event) => {
    //     event.preventDefault()
    //     let alert_param = {
    //         title: 'Accepting Roles & Responsibilities',
    //         html_message: "<p>You have chosen to accept the roles and responsibilities assigned to this employee on their behalf.</p><p>Please click <strong>'Confirm'</strong> to record this decision.</p>",
    //         ok_text: 'Confirm',
    //         cancel_text: 'Cancel',
    //         confirm: true,
    //         body_align: 'left',
    //         alertHandler: this.completeAssignment,
    //         stack: {}
    //     }
    //     this.setState({alert_param})
    // }

    // completeAssignment = async (status, stack) => {
    //     console.log("status:", status)
    //     console.log("stack:", stack)
    //     if (status === true) {
    //         const payload = {command: "personnel", action: "complete_assignment", user_id: this.props.selected_employee_id}
    //         const api = new APICall()
    //         const ret = await api.commandWithoutCallback(payload)
    //         if (ret.error_code === 0) {
    //             await this.setComponentState()
    //         } else {
    //             this.setState({alert_param: null})
    //         }
    //     } else {
    //         this.setState({alert_param: null})
    //     }
    // }

    submitForAcceptance = async () => {
        this.setState({loading: true})
        const api = new APICall()
        const payload = {command: "roles_acceptance", action: "send_roles_for_acceptance", user_id: this.props.selected_employee_id}
        const ret = await api.commandWithoutCallback(payload)
        let roles = JSON.parse(JSON.stringify(this.state.roles))
        let needs_accepting_roles = JSON.parse(JSON.stringify(this.state.needs_accepting_roles))
        if (ret.error_code === 0) {
            for (let i=0; i<roles.length; i++) {
                if (roles[i].requires_acceptance === 1) {
                    if (roles[i].for_acceptance === 0) roles[i].for_acceptance = 1
                    if (roles[i].is_accepted === 0 && roles[i].is_rejected === 0 && roles[i].for_acceptance === 1) needs_accepting_roles = true
                }
            }
        }
        this.setState({roles, loading: false, needs_accepting_roles, showmodal: false})
    }
    sendForAcceptance = async () => {
        this.setState({showmodal: true});
    }
    closemodal = () =>{
        this.setState({showmodal: false});
    }

    confirmAcceptRoles = async () => {
        this.setState({show_review_roles_modal: true});
        const payload = {command: "roles_acceptance", action: "review_roles", user_id: this.props.selected_employee_id};
        const api = new APICall();
        const ret = await api.commandWithoutCallback(payload);
        let assigned_roles = [];
        if (ret.error_code === 0) {
            if (ret.result.length !== 0) {
                const records = ret.result
                for (let i=0; i<records.length; i++) {
                    let elem = {
                        id: records[i].id,
                        color: records[i].color,
                        role_code: records[i].role_code,
                        role_title: records[i].role_title,
                        eff_from_date: records[i].eff_from_date,
                        reg_def: records[i].reg_definition,
                        shared: records[i].shared,
                        divided: records[i].divided
                    }
                    assigned_roles.push(elem);
                }
            }
        }
        this.setState({assigned_roles: assigned_roles});
    }

    onAccepted = (event) => {
        event.preventDefault()
        let confirm_msg = "<p>You have chosen to accept the roles and responsibilities assigned to this employee on their behalf.</p><p>Please click <strong>'Confirm'</strong> to record this decision.</p>"
        if (parseInt(this.props.selected_employee_id) === parseInt(Store.getStoreData("logged_in_contact_id"))) {
            confirm_msg = "<p>You have chosen to accept the roles and responsibilities assigned to you</p><p>Please click <strong>'Confirm'</strong> to record this decision.</p>"
        }
        let alert_param = {
            title: 'Accepting Roles & Responsibilities',
            html_message: confirm_msg,
            ok_text: 'Confirm',
            cancel_text: 'Cancel',
            confirm: true,
            body_align: 'left',
            alertHandler: this.acceptRoles,
            stack: {}
        }
        this.setState({alert_param})
    }

    acceptRoles = async (status) => {
        if (status === true) {
            this.setState({loading: true, alert_param: null});
            const api = new APICall();
            const payload = {command: "roles_acceptance", action: "accept_roles", user_id: this.props.selected_employee_id};
            const ret = await api.commandWithoutCallback(payload);
            if (ret.error_code === 0) {
                let roles = JSON.parse(JSON.stringify(this.state.roles))
                for (let i=0; i<roles.length; i++) {
                    if (roles[i].requires_acceptance === 1 && roles[i].for_acceptance === 1  && roles[i].is_rejected === 0) {
                        roles[i].is_accepted = 1
                    }
                }
                const needs_acceptance = false
                EventBus.raiseEvent('needs_acceptance', needs_acceptance)
                this.setState({needs_accepting_roles: false, needs_acceptance, loading: false, roles, show_review_roles_modal: false})
            }
        } else {
            this.setState({alert_param: null, show_review_roles_modal: false})
        }
    }

    onDeclined = (event) => {
        event.preventDefault();
        let confirm_msg = "<p>You have chosen not to accept the roles and responsibilities assigned to this employee on their behalf.</p><p>Please click <b>'Confirm'</b> to record this decision.</p>";
        if (parseInt(this.props.selected_employee_id) === parseInt(Store.getStoreData("logged_in_contact_id"))) {
            confirm_msg = "<p>You have chosen not to accept the roles and responsibilities assigned to you</p><p>Please click <strong>'Confirm'</strong> to record this decision.</p>"
        }
        let alert_param = {
            title: 'Declining Roles & Responsibilities',
            html_message: confirm_msg,
            ok_text: 'Confirm',
            cancel_text: 'Cancel',
            confirm: true,
            body_align: 'left',
            alertHandler: this.declinedRoles,
            stack: {}
        }
        this.setState({alert_param})
    }

    declinedRoles = async (status) => {
        if (status === true) {
            this.setState({loading: true, alert_param: null});
            const api = new APICall();
            const payload = {command: "roles_acceptance", action: "declined_roles", user_id: this.props.selected_employee_id};
            const ret = await api.commandWithoutCallback(payload);
            if (ret.error_code === 0) {
                let roles = JSON.parse(JSON.stringify(this.state.roles))
                for (let i=0; i<roles.length; i++) {
                    if (roles[i].requires_acceptance === 1 && roles[i].for_acceptance === 1 && roles[i].is_accepted === 0) {
                        roles[i].is_rejected = 1
                    }
                }
                const needs_acceptance = false
                EventBus.raiseEvent('needs_acceptance', needs_acceptance)
                this.setState({needs_accepting_roles: false, needs_acceptance, loading: false, roles, show_review_roles_modal: false})
            }
        } else {
            this.setState({alert_param: null, show_review_roles_modal: false})
        }
    }

    onRespondLater = (event) => {
        event.preventDefault();
        this.setState({show_review_roles_modal: false});
    }

    render() {
        return (
            <div>
                <div className="mc-table-header-container" style={{backgroundColor: "#fff"}}>
                    <div style={{ fontWeight: "600", fontSize: "15px", paddingLeft: "15px" }}>Assigned Roles (Responsibility Summary)</div>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <FontAwesomeIcon className="mc-roles-add-icon" icon={regular('ellipsis-vertical')} onClick={this.openCloseRoleContexMenu} />
                    </div>
                </div>
                <div className="mc-table-container">
                    <CSLTable processData={this.processRoles} />
                    {
                        this.state.loading && <SmallSpinner />
                    }
                </div>
                <div className="footer">
                    {Store.getStoreData('role') !== "team" && <button className={`${this.state.needs_acceptance === true ? "btn-success" : "btn-success disabled"}`} onClick={this.sendForAcceptance}>Send for Acceptance</button>}
                    {this.state.showmodal === true &&
                        <div className="interactive-overlay" style={{height: "115vh"}}>
                            <SendForAcceptanceModal employee_id={this.props.selected_employee_id} submitForAcceptance={this.submitForAcceptance} closemodal={this.closemodal}/>
                        </div>
                    }
                    <button className={`${this.state.needs_accepting_roles === true ? "btn-success" : "btn-success disabled"}`} onClick={this.confirmAcceptRoles}>Review Roles</button>
                    {this.state.show_review_roles_modal === true &&
                        <>
                            <div className="add-role-modal-container" style={{top: "20vh", height: "75vh", width: "50vw", position: "absolute", zIndex: 100, backgroundColor: "#F4F5F7"}}>
                                <div style={{height: "6vh", backgroundColor: "#E7F0F7"}}>
                                    <div className="review-role-modal-header-sbcontainer">
                                        <div style={{position: "relative", paddingLeft: "10px"}}>
                                            <button className="review-role-modal-button" style={{backgroundColor: "#24B3AD", color: "#FFFFFF"}} onClick={this.onAccepted}>Accept</button>
                                            <button className="review-role-modal-button" style={{backgroundColor: "#CE6666", color: "#FFFFFF"}} onClick={this.onDeclined}>Decline</button>
                                            <button className="review-role-modal-button" style={{backgroundColor: "#FFFFFF"}} onClick={this.onRespondLater}>Respond Later</button>
                                        </div>
                                        <div style={{position: "relative"}}>
                                            <FontAwesomeIcon className="mc-roles-add-icon" style={{color: "#B1B1B1", marginRight: "15px"}}icon={regular('ellipsis-vertical')} onClick={this.openCloseRoleContexMenu}/>
                                        </div>
                                    </div>
                                </div>
                                <div style={{border: "1px solid #DDE4E9"}}></div>
                                <div className="review-role-modal-body-sbcontainer" style={{paddingTop: "10px", overflowY: "scroll", overflowX: "hidden"}}>
                                    <div style={{position: "relative", display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                                        <div className="review-role-modal-body-sbcontainer-TextHeader" style = {{width: "10vw"}}>Name</div>
                                        <div className="review-role-modal-body-sbcontainer-TextHeader" style = {{width: "10vw"}}>Title</div>
                                        <div className="review-role-modal-body-sbcontainer-TextHeader" style = {{width: "25vw"}}>Job Description</div>
                                    </div>
                                    <div style={{position: "relative", display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                                        <div className="review-role-modal-body-sbcontainer-Text" style = {{width: "10vw"}}>{this.state.emp_name}</div>
                                        <div className="review-role-modal-body-sbcontainer-Text" style = {{width: "10vw"}}>{this.state.emp_job_title}</div>
                                        <div className="review-role-modal-body-sbcontainer-Text" style = {{width: "25vw"}}>{this.state.emp_job_desc}</div>
                                    </div>
                                </div>
                                <div style={{marginTop: "20px"}}>
                                    <div className="mc-table-header-container" style={{backgroundColor: "#fff", margin: "15px", height: "3vh"}}>
                                        <div style={{fontWeight: "600", fontSize: "15px", paddingLeft: "10px"}}>Roles/Responsibilities Awaiting Acceptance</div>
                                        <div style={{display: "flex", justifyContent: "flex-end" }}>
                                            <FontAwesomeIcon className="mc-roles-add-icon" style={{color: "#B1B1B1", marginRight: "15px"}} icon={regular('ellipsis-vertical')} onClick={this.openCloseRoleContexMenu} />
                                        </div>
                                    </div>
                                    <div className="mc-table-container" style={{margin: "15px", overflowX: "scroll"}}>
                                        <CSLTable processData={this.processAwatingAcceptanceRoles} tableRows={5} />
                                        {
                                            this.state.loading && <SmallSpinner />
                                        }
                                    </div>
                                </div> 
                                <div className="review-role-modal-body-sbcontainer">If you have any questions regarding the roles and responsibilities assigned, please contact the Compliance Officer / HR Manager. If you disagree with the functions and responsibilities assigned, please click 'Decline' and provide reasons, Note though that declining these functions could have an impact on your contract of employment. 
                                </div>
                            </div>
                        </>
                    }
                </div>
                {this.state.alert_param !== null && <AlertBox alertParam={this.state.alert_param} />}
            </div>
        )
    }


}

export default Roles