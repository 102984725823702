import React from 'react';
import APICall from '../../../Common/APICall.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import "./AddTopicModal.css"
import TopicSections from './TopicSections.js'
import TopicsRoleTypes from './TopicsRoleTypes.js';
import TopicsLegalEntities from './TopicsLegalEntities.js';

class AddTopicModal extends React.Component {

    state = { sections: [], topic: null, selected_tab: "questions", topic_name_editing: false }

    async componentDidMount() {
        await this.setComponentState();
    }

    async componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            await this.setComponentState();
        }
    }

    setComponentState = async () => {
        if (this.props.topic_id !== 0) {
            const api = new APICall()
            const payload1 = { command: "qsets", action: 'list_sections', topic_id: this.props.topic_id }
            const payload2 = { command: "topics_api", action: "get_topic", topic_id: this.props.topic_id }
            const [section_result, topic_result] = await Promise.all([api.commandWithoutCallback(payload1), api.commandWithoutCallback(payload2)])

            let sections = []
            if (section_result.error_code === 0) sections = section_result.result.sections
            let topic = null
            if (topic_result.error_code === 0) topic = topic_result.result
            this.setState({ sections, topic })
        }
    }

    toggleTopicStatus = (event) => {
        event.preventDefault()
        let topic = JSON.parse(JSON.stringify(this.state.topic))
        topic.is_active = topic.is_active === 1 ? 0 : 1
        this.setState({ topic })
        this.props.editTopic("status", topic.is_active, this.props.topic_id)
    }

    changeTab = (tab) => (event) => {
        event.preventDefault()
        let selected_tab = this.state.selected_tab
        if (tab !== selected_tab) {
            selected_tab = tab
            this.setState({ selected_tab })
        }
    }

    addSection = async (event) => {
        event.preventDefault()
        const api = new APICall()
        const payload = { command: "topics_api", action: "upsert_section", section_id: 0, topic_id: this.props.topic_id }
        const result = await api.commandWithoutCallback(payload)
        if (result.error_code === 0) {
            let sections = JSON.parse(JSON.stringify(this.state.sections))
            sections.push({ id: result.result, name: "Untitled Section" })
            this.setState({ sections })
        }
    }

    closeView = (event) => {
        event.preventDefault()
        this.props.closeView()
    }

    setTopicName = (event) => {
        this.props.editTopic("name", event.currentTarget.innerHTML, this.props.topic_id)
    }


    updateSection = async (data) => {
        const payload = { command: "topics_api", action: "update_section", name: data.name, topic_id: this.props.topic_id, section_id: data.section_id }
        const api = new APICall()
        const result = await api.commandWithoutCallback(payload)
        if (result.error_code === 0) {
            let sections = JSON.parse(JSON.stringify(this.state.sections))
            for (let i = 0; i < sections.length; i++) {
                if (sections[i].id === data.section_id) {
                    sections[i].name = data.name
                }
            }
            this.setState({ sections })
        }
    }

    handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
        }
    }

    deleteSection = async (section_id) => {
        const payload = { command: "topics_api", action: "update_section_delete", topic_id: this.props.topic_id, section_id: section_id }
        const api = new APICall()
        const result = await api.commandWithoutCallback(payload);
        if (result.error_code === 0) {
            this.setComponentState();
        }
    }

    onSaveButtonClick = () => {
        let desc = document.getElementById('text-area-topic-desc').value;
        this.props.editTopic("description", desc, this.props.topic_id)
    }

    handleTextareaChange = (e) => {
        let desc = e.target.value;
        let t = JSON.parse(JSON.stringify(this.state.topic))
        t.description = desc;
        this.setState({ topic: t })
    }

    render() {
        let has_gc = JSON.parse(localStorage.getItem('has_gc'));
        console.log('has_gc ==>', has_gc);
        return (
            <div className="mc-general-container" style={{ paddingTop: "20px", backgroundColor: "#fff", minHeight: "calc(100vh - 160px)" }}>
                {
                    this.state.topic !== null &&
                    <>
                        <div style={{ display: "flex", paddingBottom: "15px", alignItems: "center", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div contentEditable={true} onKeyDown={this.handleKeyDown} onBlur={this.setTopicName} style={{ fontWeight: "600", fontSize: "16px", paddingRight: "10px", color: "#1a3552", whiteSpace: 'pre-wrap' }}>{this.state.topic.name}</div>
                                {/* {
                                    this.state.topic_name_editing === true ?
                                    <FontAwesomeIcon className="mc-roles-add-icon" style={{fontSize: "15px"}} icon={regular('floppy-disk')} /> :
                                    <FontAwesomeIcon className="mc-roles-add-icon" style={{fontSize: "15px"}} icon={solid('pencil')} />
                                } */}
                            </div>
                            <FontAwesomeIcon size="2x" icon={solid('xmark')} style={{ cursor: "pointer" }} onClick={this.closeView} />
                        </div>
                        {/* Add topic description */}
                        <div style={{ display: "flex", paddingBottom: "15px", alignItems: "center", justifyContent: "space-between", position: 'relative' }}>
                            <textarea onChange={(e) => this.handleTextareaChange(e)} id='text-area-topic-desc' value={this.state.topic.description} className='topic-question-input-panel-desc'></textarea>
                            <button className='btn-text-area-topic' onClick={(e) => this.onSaveButtonClick(e, this.state.topic.id)} > Save </button>
                        </div>
                        <div style={{ paddingBottom: "15px" }}>
                            <div style={{ fontWeight: "500", paddingBottom: "5px" }}>Active?</div>
                            {
                                this.state.topic.is_active === 1 ?
                                    <FontAwesomeIcon className="mc-roles-add-icon" style={{ fontSize: "24px" }} icon={regular('toggle-on')} onClick={this.toggleTopicStatus} /> :
                                    <FontAwesomeIcon className="mc-roles-add-icon" style={{ fontSize: "24px", color: "#ababab" }} icon={regular('toggle-off')} onClick={this.toggleTopicStatus} />
                            }
                        </div>
                        <div style={{ display: "flex", columnGap: "25px" }}>
                            <div className={this.state.selected_tab === "questions" ? "topic-tab-selected" : "topic-tab"} onClick={this.changeTab("questions")}>Questions</div>
                            <div className={this.state.selected_tab === "role_types" ? "topic-tab-selected" : "topic-tab"} onClick={this.changeTab("role_types")}>Role Types</div>
                            {has_gc && <div div className={this.state.selected_tab === "gcs" ? "topic-tab-selected" : "topic-tab"} onClick={this.changeTab("gcs")}>Legal Entities</div>}
                        </div>
                        {
                            this.state.selected_tab === "questions" &&
                            <div className='add-section-btn' style={{ marginBottom: "15px" }} onClick={this.addSection}>Add Section</div>
                        }
                    </>
                }
                {
                    this.state.selected_tab === "questions" &&
                    <>
                        {
                            this.state.sections.map(item => {
                                return (
                                    <div key={`sec-${item.id}`}>
                                        <TopicSections topic_id={this.props.topic_id} section={item} updateSection={this.updateSection} deleteSection={this.deleteSection} />
                                    </div>
                                )
                            })
                        }
                    </>
                }
                {this.state.selected_tab === "role_types" && <TopicsRoleTypes topic_id={this.props.topic_id} />}
                {this.state.selected_tab === "gcs" && <TopicsLegalEntities topic_id={this.props.topic_id} topic={this.state.topic} />}
            </div >
        )
    }
}

export default AddTopicModal;
