import React, { Component } from 'react';
import SorContext from "./SorContext";
import './sor.css'
import moment from 'moment';

class DateFilter extends Component {
    constructor (props) {
        super(props);
        this.state = {
            whichPanel: '',
            selectedDate: new Date().toISOString().slice(0, 10),
        };
    }

    static contextType = SorContext;

    selectedDate = (e, type) => {
        e.preventDefault();
        this.setState({ whichPanel: (this.state.whichPanel == 'previous' && type == 'previous') ? '' : type })
    }

    handleDateChange = (e) => {
        e.target.blur();
        this.setState({
            selectedDate: e.target.value,
        });
    };

    handleClick = (e) => {
        e.target.focus()
    }

    closeDateFilter = () => {
        let selectedDate = this.state.selectedDate;
        console.log('selected date ==>', selectedDate);
        const { updateFilterParameter } = this.context;
        let obj = {
            type: 'dateFilter',
            secondaryText: `As of ${moment(selectedDate).format("DD/MM/YYYY")}`,
            value: moment(selectedDate).format("YYYYMMDD")
        }
        updateFilterParameter(obj)
        this.props.closeFilter();
    }


    render() {
        return (
            <div className='personnel-container'>

                <span class="select-smr-popover-selected" id="spanSelectCount">Today's date is {moment().format("DD/MM/YYYY")}</span>
                <div className='personnel-employee-container'>
                    <div className='personnel-employees'>
                        <ul>
                            {(this.state.whichPanel == '' || this.state.whichPanel == 'today') && <li className='personnel-li' onClick={(e) => this.selectedDate(e, 'today')}>Today's Date</li>}
                            {(this.state.whichPanel == '' || this.state.whichPanel == 'previous') && <li className='personnel-li' onClick={(e) => this.selectedDate(e, 'previous')}>Previous Date</li>}
                            {this.state.whichPanel == 'previous' &&
                                <input
                                    type="date"
                                    value={this.state.selectedDate}
                                    onChange={this.handleDateChange}
                                    className='date-filter-custom-calendar'
                                    onClick={(e) => this.handleClick(e)}
                                />
                            }
                        </ul>
                    </div>
                    <button className='personnel-employee-done'
                        onClick={this.closeDateFilter}>Done</button>
                </div>
            </div >
        )
    }
}

export default DateFilter
