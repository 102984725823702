import React from 'react';
import APICall from '../../Common/APICall.js';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro'
import assignedAssessmentService from "../../services/assignedAssessmentService"
import AlertBox from "../Common/AlertBox";

class AssessmentDeleteModal extends React.Component {
    // Data
    state = {ready: false, alert_param: null}

    // Api
    cancelProjection = async (assignmentId) => {
        try {
            const dto = {
                assessment: {
                    should_project: false
                }
            }
            await assignedAssessmentService.update(assignmentId, dto)
            this.props.closeModal()
        } catch (error) {
            this.showErrorModal(error?.response?.data?.error_msg)
        }
    }

    deleteAssessment = async (assessmentId, assignmentId) => {
        try {
            const api = new APICall()
            const postData = {
                command: "assessments_details",
                action: 'delete_assessment_from_dashboard',
                assignment_id: assignmentId,
                assessment_id: assessmentId,
            }
            let res = await api.commandWithoutCallback(postData)
            if (res.result.deleted === 0) {
                this.showErrorModal()
                return
            }
            this.props.closeModal()
        } catch (error) {
            this.showErrorModal()
        }
    }

    // Methods
    handleClickCancelProjections = async () => {
        const assessment = this.props.assessment
        await this.cancelProjection(assessment.original_assignment_id)
    }

    handleClickDeleteAssessment = async () => {
        const assessment = this.props.assessment
        await this.deleteAssessment(assessment.assessment_id, assessment.assignment_id)
    }

    showErrorModal(errorMessage) {
        let alert_param = {
            title: 'An Error Occurred',
            message: errorMessage || "An unexpected error occurred deleting the assessment. Please contact administration for support",
            ok_text: 'Ok',
            confirm: false,
            alertHandler: this.alertHandler,
            stack: {id: 0}
        }
        this.setState({ready: true, alert_param})
    }

    alertHandler = (result, stack) => {
        this.setState({ready: false, alert_param: null})
        this.props.closeModal()
    }

    render() {
        const {assessment, closeModal} = this.props

        return (
            <div className="assessment-delete-modal-wrapper">
                <div className='assign-assessment-modal-container'>
                    {/* Header */}
                    <div className='assign-assessment-confirmation-modal-header'>
                        <div className='assign-assessment-confirmation-header-text'>
                            {
                                assessment.assessment_status === 'Scheduled'
                                    ?
                                    'Cancel Scheduled Assessments'
                                    :
                                    'Delete Assessment'
                            }
                        </div>

                        <div className='assign-assessment-confirmation-header-close-btn'
                             onClick={closeModal}>
                            <FontAwesomeIcon title="Action" icon={solid('xmark')}/>
                        </div>
                    </div>

                    {/* Body */}
                    <div className='assessment-delete-modal-body'>

                      {assessment.assessment_status === "Scheduled" ? (
                        <div className="warning-text-box">
                          <span>
                            <strong>Important:</strong> This is a projected assessment. Canceling it will also remove all future projections for this assessment. Are you sure you want to proceed?
                          </span>
                        </div>
                      ) : null}

                      {assessment.assessment_status === "Due now" && assessment.should_project ? (
                        <div className="warning-text-box">
                          <span>
                            <strong>Please note:</strong> This assessment has projected future assessments linked to it. Deleting it will also remove all associated projections. Please confirm if you wish to continue.
                          </span>
                        </div>
                      ) : null}

                        <div className='assessment-delete-modal-table-section'>
                            <p className='assessment-attest-topic-name'>Assessment Details</p>
                            <div className='table-wrapper'>
                                <div className='table-body'>
                                    <div className='table-row'>
                                        <div className='table-cell fw-bold'>Assessment</div>
                                        <div className='table-cell'><span
                                            className=''><span>{assessment.assessment_name}</span></span>
                                        </div>
                                    </div>
                                    <div className='table-row'>
                                        <div className='table-cell fw-bold'>Status</div>
                                        <div className='table-cell'><span
                                            className=''><span>{assessment.assessment_status}</span></span>
                                        </div>
                                    </div>
                                    <div className='table-row'>
                                        <div className='table-cell fw-bold'>Employee</div>
                                        <div className='table-cell'><span
                                            className=''><span>{assessment.assessment_assigned_to_name}</span></span>
                                        </div>
                                    </div>
                                    <div className='table-row'>
                                        <div className='table-cell fw-bold'>Due Date</div>
                                        <div className='table-cell'><span
                                            className=''>{new Date(assessment.assessment_due_date * 1000).toLocaleDateString()}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='assign-assessment-confirmation-footer'>
                        {
                            assessment.assessment_status === 'Scheduled'
                                ?
                                <button
                                    className={`assign-assessment-confirmation-btn assign-assessment-submit-button `}
                                    onClick={this.handleClickCancelProjections}
                                    id="submit"
                                >
                                    Cancel Projections
                                </button>
                                :
                                <button
                                    className={`assign-assessment-confirmation-btn assign-assessment-submit-button `}
                                    onClick={this.handleClickDeleteAssessment}
                                    id="submit"
                                >
                                    Delete Assessment
                                </button>
                        }

                        <button
                            className='assign-assessment-confirmation-btn assign-assessment-conf-cancel-button'
                            onClick={closeModal}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
                <AlertBox alertParam={this.state.alert_param}/>
            </div>
        );
    }
}

export default AssessmentDeleteModal;
