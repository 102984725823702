import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tab from './Tab';
import styled from 'styled-components';

const OrderList = styled.ol`
  border-bottom: none;
  padding-left: 0;
  cursor:pointer;
`
class Tabs extends Component {
    static propTypes = {
        children: PropTypes.instanceOf(Array).isRequired,
    }

    constructor (props) {
        super(props);

        this.state = {
            activeTab: this.props.children[0].props.label,
        };
    }

    onClickTabItem = (tab) => {
        if (tab == 'Assessment') {
            this.props.children[0].props.onClick();
        }
        // if (tab == 'Follow up Tasks') {
        //     this.props.children[1].props.onClick();
        // }
        // if (tab == 'Attachments') {
        //     this.props.children[2].props.onClick();
        // }
        if (tab == 'Certifications') {
            this.props.children[1].props.onClick();
        }
        // if (tab == 'Roles') {
        //     this.props.children[4].props.onClick();
        // }
        this.setState({ activeTab: tab });
    }

    render() {
        const {
            onClickTabItem,
            props: {
                children,
            },
            state: {
                activeTab,
            }
        } = this;

        return (
            <div>
                <OrderList>
                    {children.map((child) => {

                        const { label } = child.props;
                        const { visibility } = child.props;

                        return (
                            <Tab
                                activeTab={activeTab}
                                key={label}
                                label={label}
                                onClick={onClickTabItem}
                                visibility={visibility}
                            />
                        );
                    })}
                </OrderList>
                <div>
                    {children.map((child) => {
                        if (child.props.label !== activeTab) return undefined;
                        return child.props.children;
                    })}
                </div>
            </div>
        );
    }
}

export default Tabs;
