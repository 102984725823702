import React from 'react';
import styled from 'styled-components';
import * as Style from '../Common/StyledComponents';
import { FaExclamationTriangle } from 'react-icons/fa';
import Store from '../../Common/Store.js';
import SpinningLoaderSmall from '../Common/SpinningLoaderSmall';
import APICall from '../../Common/APICall';
import OverlapReasonModal from "./OverlapReasonModal"
const moment = require('moment');


const ModalTitle= styled.div`
    color : #1B3451;
    font-size : 20px;
    font-weight : 600;
    padding : 2%;
    padding-left : 5%;
`
const ModalSubtitle = styled.div`
    color : #1B3451;
    font-size : 16px;
    font-weight : 400;
    padding-left : 5%;
    margin-bottom : 8px;
`
const AssignmentSection = styled.div`
    background-color : #E7F0F7;
    border : 1px solid #5F8FB4;
    border-radius : 5px;
    color : #5F8FB4;
    padding : 10px 15px 10px 20px;
    margin : 3% 5% 2% 5%;
    postion : relative;
`
const WaringSection = styled.div`
    background-color : #F0E3BA;
    border : 1px solid #DF861C;
    border-radius : 5px;
    color : #DF861C;
    padding : 10px;
    margin : 3% 5% 2% 5%;
`
const ErrorSection = styled.div`
    background-color : #ECCACA;
    border : 1px solid #A32626;
    border-radius : 5px;
    color : #A32626;
    padding : 10px;
    margin : 3% 5% 2% 5%;
`

const ModalButton = styled.button`
    width : 100px;
    padding : 10px;
    font-weight : 700;
    margin : 15px 10px 15px 0;
    border-radius : 5px;
`
const ModalFooter = styled.div`
    display : flex;
    background-color : #fff;
    justify-content : space-between;
    align-items : center;
    padding-right : 5%;
    padding-left : 10%;
`
const ModalFooterButtonContainer = styled.div`
    display : flex;
    background-color : #fff;
    justify-content : flex-end;
    width : 50%;
    align-items : center;
`
const SectionHeader = styled.div`
    margin : auto;
    display : flex;
    flex-direction : row;
    gap : 15px;
    justify-content : flex-start;
    align-items : center;
`
const SectionIcon = styled.div`
    font-size : 16px;
    margin-left : 2%;
    margin-top : 3px;
`
const Messages = styled.div`
    font-size : 16px;
    padding : 1% 3% 1% 7%;
    text-align : justify;
    line-height : 22px;
`
const SectionTitle = styled.div`
    font-size : 16px;
    font-weight : 600;
`

const AssignmentGrid = styled.table`
    width : 100%;
`

const AssignmentGridHeader = styled.th`
    font-size : 16px;
    font-weight : 600;
    margin-bottom : 5px;
    text-align : left;
`
const RoleCellContainer = styled.td`

`
const RoleCell = styled.div`
    font-size : 16px;
    font-weight : 600;
    color : #fff;
    text-align : center;
    padding : 3px 5px 3px 5px;
    border-radius : 5px;
    display : inline-block;
    margin : 3px 0 3px 0;
    
`
const DateCell = styled.td`
    font-size : 16px;
    padding : 3px;
    margin : 3px 0 3px 0;
    padding : 10px 5px 10px 0;
`
const ValidatingDiv = styled.div`
    position : absolute;
    top : 50%;
    transform : translateY(-50%);
    border : 1px solid black;
`

const ValidatingText = styled.div`
    width : 100%;
    text-align : center;
    color : #000;
`

class AssignmentConfimationModal extends React.Component {
	state = {
        assignment_section_data : [],
        contact_name : '',
        warning_sec_msg : [],
        error_sec_msg : [],
        validating: true,
        temp_ovr_msg : [],
        show_overlap_reason_modal : false,
        date_range_reason_data : {},
        assignment_temp_data : {}
    }

    componentDidMount(){
        this.initializeState();
    }

    componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			this.initialiseState();
		}
	}

    initializeState = async () => {
        console.log("Conf Props in confirmation:", this.props);
        let assignment_data_arr = this.props.assignment_data;
        let role_info = this.props.role_info;
        let list_contacts = Store.getStoreData('listcontacts');
        let role_list = Store.getStoreData('role_list');
        let role_type_list = Store.getStoreData('role_type_list');
        let contact_name = list_contacts.find(item => item.ID === assignment_data_arr[0].contact_id.toString()).ContactName;
        let assignment_temp_data = JSON.parse(JSON.stringify(assignment_data_arr))[0];
        let assignment_section_data = [];
        for(let i=0; i<assignment_data_arr.length; i++){
            let elem = {
                role_code : role_list.find(item => item.id === assignment_data_arr[i].role_id).role_code,
                role_color : role_type_list.find(item => item.id === assignment_data_arr[i].role_type_id).color,
                assigned_date : moment(this.toDateObject(assignment_data_arr[i].assigned_date)).format("DD-MM-YYYY").toString(),
                from : assignment_data_arr[i].effective_from_date === -1 ? 'Regime Start' : moment(this.toDateObject(assignment_data_arr[i].effective_from_date)).format("DD-MM-YYYY").toString(),
                to : assignment_data_arr[i].effective_till_date === parseInt(process.env.REACT_APP_NO_END_DATE) ? 'No End Date' : moment(this.toDateObject(assignment_data_arr[i].effective_till_date)).format("DD-MM-YYYY").toString()
            }
            assignment_section_data.push(elem);
        }
        this.setState({ assignment_section_data, contact_name })
        // const payload1 = {command:"rough_work", action:"assignments", role_id:role_info.role_id, gc_ids:[0]}
        const payload = {
            command:"get_date_range_status",
            from: assignment_data_arr[0].effective_from_date === -1 ? parseInt(moment().subtract(10,'years').format("YYYYMMDD")): assignment_data_arr[0].effective_from_date,
            to: assignment_data_arr[0].effective_till_date === parseInt(process.env.REACT_APP_NO_END_DATE) ? parseInt(moment().add(10,'years').format("YYYYMMDD")) : assignment_data_arr[0].effective_till_date,
            role_id:role_info.role_id,
            role_creation_date: role_info.created_at
        }
        if (assignment_temp_data.mode === "edit") {
            let date_range_object = {
                id: assignment_temp_data.id,
                action: assignment_temp_data.action,
                effective_from_date: assignment_temp_data.effective_from_date,
                effective_till_date: assignment_temp_data.effective_till_date
            }
            payload.ass_date_range_object = date_range_object
        }
        let api = new APICall()
        // let api_response = await api.commandWithoutCallback(payload)
        const range_status = await api.commandWithoutCallback(payload)
        // console.log("user_assignments:", user_assignments)
        console.log("range_status:", range_status)
        let warning_sec_msg = [];
        let error_sec_msg = [];
        const req_range = {from: assignment_data_arr[0].effective_from_date, to: assignment_data_arr[0].effective_till_date}
        let na_info = await this.checkNA(req_range, role_info)
        console.log("na_info:", na_info)
        if (na_info.length !== 0) {
            let alert_msg = ""
            for (let i=0; i<na_info.length; i++) {             //item.effective_till_date === parseInt(process.env.REACT_APP_NO_END_DATE) ? item.effective_from_date < parseInt(moment().format("YYYYYMMDD")) ? "Present" : "Not Set" : moment(this.toDateObject(item.effective_till_date)).format("DD-MM-YYYY").toString(),
                let range_arr = na_info[i].split("-")
                console.log("Range arr ====>",parseInt(range_arr[range_arr.length-1]));
                let range = `${parseInt(range_arr[range_arr.length-2]) === 1 ? "Regime Start" : moment(range_arr[range_arr.length-2], 'YYYYMMDD').format('DD/MM/YYYY')}  to ${parseInt(range_arr[range_arr.length-1]) === parseInt(process.env.REACT_APP_NO_END_DATE) ? assignment_data_arr[0].effective_till_date < parseInt(moment().format("YYYYYMMDD")) ? "Present Day" : "Not Set" : moment(range_arr[range_arr.length-1], 'YYYYMMDD').format('DD/MM/YYYY')}`
                error_sec_msg.push(`${assignment_section_data[0].role_code} is marked as Not Applicable at this firm from ${range}`)
            }
            this.setState({error_sec_msg, validating: false})
        }
        if(na_info.length > 0) return;
        let sad_info = await this.checkSad(range_status, assignment_data_arr[0].contact_id)
        console.log("sad_info:", sad_info);
        let sad_warn_msg = ``
        let sad_err_msg = ``
        if(Object.keys(sad_info.advisory_obj).length > 0){
            sad_warn_msg = `${assignment_section_data[0].role_code} was assigned to<br/>`
            for(let user in sad_info.advisory_obj){
                for(let row_id in sad_info.advisory_obj[user]){
                    let u_name = list_contacts.find(item => item.ID.toString() === user).ContactName
                    let u_start = sad_info.advisory_obj[user][row_id].start_date === -1 ? "Regime Start" : moment(sad_info.advisory_obj[user][row_id].start_date.toString(), 'YYYYMMDD').format('DD/MM/YYYY');
                    let u_end = sad_info.advisory_obj[user][row_id].end_date === parseInt(process.env.REACT_APP_NO_END_DATE) ? assignment_data_arr[0].effective_till_date <= parseInt(moment().format("YYYYYMMDD")) ? "Present Day" : "Not Set" : moment(sad_info.advisory_obj[user][row_id].end_date.toString(), 'YYYYMMDD').format('DD/MM/YYYY');
                    sad_warn_msg = sad_warn_msg + `${u_name} from ${u_start} to ${u_end}<br>`
                }
            }
        }
        if(Object.keys(sad_info.err_obj).length > 0){
            sad_err_msg = `${assignment_section_data[0].role_code} was assigned is already assigned to`
            for(let user in sad_info.err_obj){
                let i=1;
                for(let row_id in sad_info.err_obj[user]){
                    let u_name = list_contacts.find(item => item.ID.toString() === user).ContactName
                    let u_start = sad_info.err_obj[user][row_id].start_date === -1 ? "Regime Start" : moment(sad_info.err_obj[user][row_id].start_date.toString(), 'YYYYMMDD').format('DD/MM/YYYY');
                    let u_end = sad_info.err_obj[user][row_id].end_date === parseInt(process.env.REACT_APP_NO_END_DATE) ? assignment_data_arr[0].effective_till_date <= parseInt(moment().format("YYYYYMMDD")) ? "Present Day" : "Not Set" : moment(sad_info.err_obj[user][row_id].end_date.toString(), 'YYYYMMDD').format('DD/MM/YYYY');
                    if( i == 1 ) sad_err_msg = sad_err_msg + `<br>${u_name} <br>from ${u_start} to ${u_end}`
                    else sad_err_msg = sad_err_msg + `<br>from ${u_start} to ${u_end}`
                }
            }
        }

        if(Object.keys(sad_info.advisory_obj).length > 0 && Object.keys(sad_info.err_obj).length === 0) {
            warning_sec_msg.push(sad_warn_msg);
            this.setState({warning_sec_msg, validating : false});
        }
        if(Object.keys(sad_info.err_obj).length > 0) {
            error_sec_msg.push(sad_err_msg);
            this.setState({ warning_sec_msg, error_sec_msg, validating : false})
        }
        let ovrs = await this.checkResOvr(range_status)
        console.log("ovrs:", ovrs)
        if (Object.keys(ovrs.err_ovrs).length !== 0) {
            let ovr_err_msg = `${assignment_section_data[0].role_code} was assigned to<br/>`
            for (let range in ovrs.err_ovrs) {
                let range_arr = range.split("-")
                let r_start = moment(range_arr[0], 'YYYYMMDD').format('DD/MM/YYYY')
                let r_end = moment(range_arr[1], 'YYYYMMDD').format('DD/MM/YYYY')
                for (let user_id in ovrs.err_ovrs[range]) {
                    let username = list_contacts.find(item => item.ID === user_id).ContactName
                    for (let row_id in ovrs.err_ovrs[range][user_id]) {
                        let u_start = ovrs.err_ovrs[range][user_id][row_id].start_date === -1 ? "Regime Start" : moment(ovrs.err_ovrs[range][user_id][row_id].start_date.toString(), 'YYYYMMDD').format('DD/MM/YYYY')
                        let u_end = ovrs.err_ovrs[range][user_id][row_id].end_date === parseInt(process.env.REACT_APP_NO_END_DATE) ? assignment_data_arr[0].effective_till_date <= parseInt(moment().format("YYYYYMMDD")) ? "Present Day" : "Not Set" : moment(ovrs.err_ovrs[range][user_id][row_id].end_date.toString(), 'YYYYMMDD').format('DD/MM/YYYY')
                        ovr_err_msg = ovr_err_msg + `${username} from ${u_start} to ${u_end}<br/>`
                    }
                }
            }
            ovr_err_msg = ovr_err_msg + "and this role cannot be shared/divided at that time."
            error_sec_msg.push(ovr_err_msg)
            this.setState({warning_sec_msg, error_sec_msg, validating: false})
            return;
        }
        if (Object.keys(ovrs.warn_ovrs).length !== 0) {
            let ovr_err_msg = `${assignment_section_data[0].role_code} was assigned to<br/>`
            for (let range in ovrs.warn_ovrs) {
                let range_arr = range.split("-")
                let r_start = moment(range_arr[0], 'YYYYMMDD').format('DD/MM/YYYY')
                let r_end = moment(range_arr[1], 'YYYYMMDD').format('DD/MM/YYYY')
                for (let user_id in ovrs.warn_ovrs[range]) {
                    let username = list_contacts.find(item => item.ID === user_id).ContactName
                    for (let row_id in ovrs.warn_ovrs[range][user_id]) {
                        let u_start = ovrs.warn_ovrs[range][user_id][row_id].start_date === -1 ? "Regime Start" : moment(ovrs.warn_ovrs[range][user_id][row_id].start_date.toString(), 'YYYYMMDD').format('DD/MM/YYYY')
                        let u_end = ovrs.warn_ovrs[range][user_id][row_id].end_date === parseInt(process.env.REACT_APP_NO_END_DATE) ? assignment_data_arr[0].effective_till_date <= parseInt(moment().format("YYYYYMMDD")) ? "Present Day" : "Not Set" : moment(ovrs.warn_ovrs[range][user_id][row_id].end_date.toString(), 'YYYYMMDD').format('DD/MM/YYYY')
                        ovr_err_msg = ovr_err_msg + `${username} from ${u_start} to ${u_end}<br/>`
                    }
                }
            }
            ovr_err_msg = ovr_err_msg + "This role cannot be shared/divided at that time but the overlap is within the temporary share allowance."
            error_sec_msg.push(ovr_err_msg);
            assignment_temp_data["is_ovr"] = 1;
            assignment_temp_data["ovr_reason"] = "";
            this.setState({warning_sec_msg, error_sec_msg, temp_ovr_msg : error_sec_msg, validating: false})
        }
        this.setState({ assignment_section_data, assignment_temp_data, validating: false });
        if (Object.keys(ovrs.err_ovrs).length !== 0) return;
        // let sad_info = await this.checkSad(range_status, assignment_data_arr[0].contact_id)
        // console.log("sad_info:", sad_info)
    }

    checkNA = async (req_range,role_info) => {
        let na_ranges = [];
        if(role_info.na_date_ranges.length>0){
            for(let range of role_info.na_date_ranges){
                console.log("NA====>",range);
                if(range.action !== "delete"){
                    if(req_range.from >= range.from && req_range.from <= range.to || req_range.to >= range.from && req_range.to <= range.to || req_range.from >= range.from && req_range.to <= range.to || req_range.from < range.from && req_range.to > range.to){
                        na_ranges.push(range.from.toString() + "-" + range.to.toString());
                    }
                }
            }
        }
        console.log("NA RANGES====>",na_ranges);
        return na_ranges;
    }

    checkResOvr = async (range_status) => {
        let ovr_window = Store.getStoreData('mc_general').respo_overlap_window
        let err_ovrs = {}
        let warn_ovrs = {}
        let res_dates = []
        for (let key in range_status) {
            if (key !== "status_summary") {
                if (range_status[key].status === "res" && range_status[key].no_assignment === 0 && Object.keys(range_status[key].assignment).length !== 0) {
                    res_dates.push(parseInt(key))
                }
            }
        }
        if (res_dates.length === 0) return {err_ovrs, warn_ovrs}
        res_dates.sort()
        console.log("res_dates:", res_dates)
        let continuous_ranges = await this.getContinuousRanges(res_dates);
        console.log("continuous_ranges:", continuous_ranges)

        for (let range of continuous_ranges) {
            let from_moment = moment(range.from.toString(), 'YYYYMMDD')
            let to_moment = moment(range.to.toString(), 'YYYYMMDD')
            let range_diff = 1 + moment.duration(to_moment.diff(from_moment)).asDays();
            if (range_diff > ovr_window) {
                let start_index = res_dates.indexOf(range.from)
                let stop_index = res_dates.indexOf(range.to)
                for (let i=start_index; i<=stop_index; i++) {
                    let ovr_date = moment(res_dates[i].toString(), 'YYYYMMDD').add(ovr_window, 'days').format('YYYYMMDD')
                    let user_obj = range_status[res_dates[i].toString()].assignment
                    if (parseInt(ovr_date) <= range.to) {
                        for (let user_id in user_obj) {
                            if (user_obj[user_id].end_date >= ovr_date) {
                                let range_key = `${range.from.toString()}-${range.to.toString()}`
                                if (range_key in err_ovrs === false) {
                                    err_ovrs[range_key] = {[user_id]: {[user_obj[user_id].row_id.toString()]: user_obj[user_id]}}
                                } else {
                                    if (user_id in err_ovrs[range_key] === true) {
                                        if (user_obj[user_id].row_id.toString() in err_ovrs[range_key][user_id] === false) {
                                            err_ovrs[range_key][user_id] = {[user_obj[user_id].row_id.toString()]: user_obj[user_id]}
                                        }
                                    } else {
                                        err_ovrs[range_key][user_id] = {[user_obj[user_id].row_id.toString()]: user_obj[user_id]}
                                    }
                                }
                            }else{
                                let range_key = `${range.from.toString()}-${range.to.toString()}`
                                if (range_key in warn_ovrs === false) {
                                    warn_ovrs[range_key] = {[user_id]: {[user_obj[user_id].row_id.toString()]: user_obj[user_id]}}
                                } else {
                                    if (user_id in warn_ovrs[range_key] === true) {
                                        if (user_obj[user_id].row_id.toString() in warn_ovrs[range_key][user_id] === false) {
                                            warn_ovrs[range_key][user_id] = {[user_obj[user_id].row_id.toString()]: user_obj[user_id]}
                                        }
                                    } else {
                                        warn_ovrs[range_key][user_id] = {[user_obj[user_id].row_id.toString()]: user_obj[user_id]}
                                    }
                                }
                            }
                        }
                    }else{
                        for (let user_id in user_obj) {
                            let range_key = `${range.from.toString()}-${range.to.toString()}`
                            if (range_key in warn_ovrs === false) {
                                warn_ovrs[range_key] = {[user_id]: {[user_obj[user_id].row_id.toString()]: user_obj[user_id]}}
                            } else {
                                if (user_id in warn_ovrs[range_key] === true) {
                                    if (user_obj[user_id].row_id.toString() in warn_ovrs[range_key][user_id] === false) {
                                        warn_ovrs[range_key][user_id] = {[user_obj[user_id].row_id.toString()]: user_obj[user_id]}
                                    }
                                } else {
                                    warn_ovrs[range_key][user_id] = {[user_obj[user_id].row_id.toString()]: user_obj[user_id]}
                                }
                            }
                        }
                    }
                }
            }
            if (range_diff <= ovr_window && Object.keys(err_ovrs).length === 0) {
                let start_index = res_dates.indexOf(range.from)
                let stop_index = res_dates.indexOf(range.to)
                for (let i=start_index; i<=stop_index; i++) {
                    // let ovr_date = moment(res_dates[i].toString(), 'YYYYMMDD').add(ovr_window, 'days').format('YYYYMMDD')
                    let user_obj = range_status[res_dates[i].toString()].assignment
                    for (let user_id in user_obj) {
                        let range_key = `${range.from.toString()}-${range.to.toString()}`
                        if (range_key in warn_ovrs === false) {
                            warn_ovrs[range_key] = {[user_id]: {[user_obj[user_id].row_id.toString()]: user_obj[user_id]}}
                        } else {
                            if (user_id in warn_ovrs[range_key] === true) {
                                if (user_obj[user_id].row_id.toString() in warn_ovrs[range_key][user_id] === false) {
                                    warn_ovrs[range_key][user_id] = {[user_obj[user_id].row_id.toString()]: user_obj[user_id]}
                                }
                            } else {
                                warn_ovrs[range_key][user_id] = {[user_obj[user_id].row_id.toString()]: user_obj[user_id]}
                            }
                        }
                    }
                }
            }
        }
        console.log("err_ovrs:", err_ovrs)
        return {err_ovrs, warn_ovrs}
    }

    checkSad = async (range_status, user_id) => {
        let err_obj = {}
        let advisory_obj = {}
        let saod_dates = []
        for (let key in range_status) {
            if (key !== "status_summary") {
                if (range_status[key].status === "shr" || range_status[key].status === "div" || range_status[key].status === "sad") {
                    if (range_status[key].no_assignment === 0 && Object.keys(range_status[key].assignment).length !== 0) {
                        saod_dates.push(parseInt(key))
                    }
                }
            }
        }
        if (saod_dates.length === 0) return {err_obj, advisory_obj}
        saod_dates.sort()
        let continuous_ranges = await this.getContinuousRanges(saod_dates);
        console.log("continuous_ranges:", continuous_ranges)
        let assignment_obj = {}
        for (let range of continuous_ranges) {
            for (let date=range.from; date<=range.to; date=this.increase1day(date)) {
                assignment_obj = range_status[date.toString()]['assignment']
                for (let contact_id in assignment_obj) {
                    if (user_id.toString() === contact_id) {
                        if (user_id.toString() in err_obj) {
                            err_obj[user_id.toString()][assignment_obj[user_id.toString()].row_id.toString()] = assignment_obj[user_id.toString()]
                        } else {
                            err_obj[user_id.toString()] = {[assignment_obj[user_id.toString()].row_id.toString()] : assignment_obj[user_id.toString()]}
                        }
                    } else {
                        if (contact_id in advisory_obj) {
                            advisory_obj[contact_id][assignment_obj[contact_id].row_id.toString()] = assignment_obj[contact_id]
                        } else  {
                            advisory_obj[contact_id] = {[assignment_obj[contact_id].row_id.toString()] : assignment_obj[contact_id]}
                        }
                    }
                }
            }
        }
        return {err_obj, advisory_obj}
    }

    increase1day = (date) =>{
        let date_str = (date%100).toString() + "-" + Math.floor((date%10000)/100).toString() + "-" + Math.floor(date/10000).toString();
        let date_obj = moment(date_str, 'DD-MM-YYYY').add(1, 'days');
        let ret = moment(date_obj).format('YYYY') + moment(date_obj).format("MM") + moment(date_obj).format("DD")
        ret = parseInt(ret)
        // console.info("date_obj:", date_obj);
        return ret;
    }

    getContinuousRanges = async (sorted_date_arr) => {
        let continuous_ranges = []
        let res_range_obj = {from: null, to: null}
        if (sorted_date_arr.length === 1) {
            res_range_obj = {from: sorted_date_arr[0], to: sorted_date_arr[0]}
            continuous_ranges.push(res_range_obj)
        } else {
            for (let i=0; i<sorted_date_arr.length; i++) {
                let prev_date = sorted_date_arr[i-1]
                let index_date = sorted_date_arr[i]
                if (prev_date !== undefined) {
                    let prev_date_moment = moment(prev_date.toString(), 'YYYYMMDD')
                    let index_date_moment = moment(index_date.toString(), 'YYYYMMDD')
                    let diff_days = moment.duration(index_date_moment.diff(prev_date_moment)).asDays()
                    if (diff_days === 1) {
                        res_range_obj.to = index_date
                        if (i === sorted_date_arr.length - 1) {
                            continuous_ranges.push(JSON.parse(JSON.stringify(res_range_obj)))
                        }
                    } else {
                        res_range_obj.to = prev_date
                        continuous_ranges.push(JSON.parse(JSON.stringify(res_range_obj)))
                        res_range_obj.from = index_date
                        res_range_obj.to = null
                        if (i === sorted_date_arr.length - 1) {
                            continuous_ranges.push({from: index_date, to: index_date})
                        }
                    }
                } else {
                    res_range_obj.from = index_date
                }
            }
        }
        return continuous_ranges
    }

    listAssignedUsers = async (user_info) => {
        if ("-1" in user_info === false) user_info["-1"] = {}
        if (parseInt(process.env.REACT_APP_NO_END_DATE).toString() in user_info === false) user_info[parseInt(process.env.REACT_APP_NO_END_DATE).toString()] = {}
    }

    toDateObject = (date) =>{
        let date_str = (date%100).toString() + "-" + Math.floor((date%10000)/100).toString() + "-" + Math.floor(date/10000).toString();
        // console.info("date_str:", date_str);
        let date_obj = moment(date_str, 'DD-MM-YYYY').toDate();
        // console.info("date_obj:", date_obj);
        return date_obj;
    }

    showReasonModal = (event) =>{
        event.preventDefault();
        let show_overlap_reason_modal = true;
        let date_range_reason_data =
        {
             from : this.state.assignment_temp_data.effective_from_date,
             to : this.state.assignment_temp_data.effective_till_date,
             reason : this.state.assignment_temp_data.ovr_reason,
        }
        this.setState({ show_overlap_reason_modal, date_range_reason_data});
    }

    closeReasonModal = () =>{
        let show_overlap_reason_modal = false;
        this.setState({ show_overlap_reason_modal});
    }

    getReason = (date_range_data) => {
        let assignment_temp_data = JSON.parse(JSON.stringify(this.state.assignment_temp_data));
        assignment_temp_data.ovr_reason = date_range_data.reason;
        console.log("DATA : ", assignment_temp_data);
        let warning_sec_msg = this.state.warning_sec_msg;
        warning_sec_msg.push(this.state.error_sec_msg);
        this.setState({ assignment_temp_data, error_sec_msg : [], warning_sec_msg, show_overlap_reason_modal : false, date_range_reason_data : date_range_data});
    }

    saveAssignment = (event) => {
        event.preventDefault();
        this.props.saveAssignment(this.state.assignment_temp_data);
    }

    closeModal = (e) => {
        e.preventDefault();
        this.props.closeModal()
    }

    render() {
        console.log("Props in confirmation:", this.props);
        console.log("State in confirmation:", this.state);

		return (
			<Style.ModalContainer style={{ left : "3%", top: "80px", width: "95%" }}>
                <Style.ModalHeader style={{ color: "#1B3451" }}>
                    <ModalTitle>Assign Role</ModalTitle>
                    <ModalSubtitle>Assigning Roles for <b>{ this.state.contact_name }</b></ModalSubtitle>
                </Style.ModalHeader>
                <Style.ModalBody>
                    <AssignmentSection>
                        <SectionTitle style={{fontWeight : "400", marginBottom : "20px"}}>You are assigning <b>{ this.state.contact_name }</b> the following Roles and Responsibilities</SectionTitle>
                        <AssignmentGrid>
                            <tr>
                                <AssignmentGridHeader>Role</AssignmentGridHeader>
                                <AssignmentGridHeader>Assigned</AssignmentGridHeader>
                                <AssignmentGridHeader>From</AssignmentGridHeader>
                                <AssignmentGridHeader>To</AssignmentGridHeader>
                            </tr>
                            {
                                this.state.assignment_section_data.map((item, index) =>{
                                    return (
                                        <tr key={index}>
                                            <RoleCell style={{backgroundColor : item.role_color }}>{ item.role_code }</RoleCell>
                                            <DateCell>{ item.assigned_date }</DateCell>
                                            <DateCell>{ item.from }</DateCell>
                                            <DateCell>{ item.to }</DateCell>
                                        </tr>
                                    )})
                            }
                        </AssignmentGrid>

                    </AssignmentSection>
                    {
                        this.state.warning_sec_msg.length > 0 &&
                        <WaringSection>
                            <SectionHeader>
                                <SectionIcon><FaExclamationTriangle /></SectionIcon>
                                <SectionTitle>We have identified the following warnings:</SectionTitle>
                            </SectionHeader>
                            {
                                this.state.warning_sec_msg.map((item, index) => {
                                    return(<Messages key={index} dangerouslySetInnerHTML={{__html: item}} />)
                                })
                            }
                            {
                                "is_ovr" in this.state.assignment_temp_data && this.state.assignment_temp_data.is_ovr === 1 && this.state.assignment_temp_data.ovr_reason !== "" &&
                                <SectionTitle style={{ paddingLeft : "7%" }} title={this.state.assignment_temp_data.ovr_reason}>Please <span style={{ color : "#5876ff", fontStyle : "italic", fontWeight : "900", cursor : "pointer"}} onClick={this.showReasonModal}>provide a reason</span> to continue.</SectionTitle>
                            }
                        </WaringSection>
                    }
                    {
                        this.state.error_sec_msg.length > 0 &&
                        <ErrorSection>
                            <SectionHeader>
                                <SectionIcon><FaExclamationTriangle /></SectionIcon>
                                <SectionTitle>We have identified the following warnings:</SectionTitle>
                            </SectionHeader>
                            {
                                this.state.error_sec_msg.map((item, index) => {
                                    return(<Messages key={index} dangerouslySetInnerHTML={{__html: item}} />)
                                })
                            }
                            {
                                "is_ovr" in this.state.assignment_temp_data && this.state.assignment_temp_data.is_ovr === 1 && this.state.assignment_temp_data.ovr_reason === "" &&
                                <SectionTitle style={{ paddingLeft : "7%" }}>Please <span style={{ color : "#5876ff", fontStyle : "italic", fontWeight : "900", cursor : "pointer"}} onClick={this.showReasonModal}>provide a reason</span> to continue.</SectionTitle>
                            }
                            <SectionTitle style={{ paddingLeft : "7%" }}>You must address these errors before continuing.</SectionTitle>
                        </ErrorSection>
                    }
                </Style.ModalBody>
                <ModalFooter>
                    { this.state.validating === true && <SpinningLoaderSmall text={'Validating'}/> }
                    { this.state.validating === false && <div></div>}
                    <ModalFooterButtonContainer>
                        <ModalButton style={{backgroundColor : "#f6f2f2", border : "1px solid #ababab", color:"#222222", cursor: "pointer"}} onClick={this.closeModal}>CANCEL</ModalButton>
                        <ModalButton
                            style={{
                                backgroundColor : this.state.validating === true || this.state.error_sec_msg.length > 0 ? "#b8e6e4" : "#18B8A5",
                                border : "1px solid #2066DA", color : "#fff",
                                pointerEvents : this.state.validating === true || this.state.error_sec_msg.length > 0 ? "none" : "auto",
                                cursor : this.state.validating === true || this.state.error_sec_msg.length > 0 ? "auto" : "pointer",
                            }}
                            onClick={this.saveAssignment}>
                            SUBMIT
                        </ModalButton>
                    </ModalFooterButtonContainer>
                </ModalFooter>
                {
                    this.state.show_overlap_reason_modal === true &&
                    <OverlapReasonModal date_range_data={this.state.date_range_reason_data} closeModal={this.closeReasonModal} getReason={this.getReason}/>
                }
            </Style.ModalContainer>
        )
    }
}

export default AssignmentConfimationModal;
