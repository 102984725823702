import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import "./MultiSelectDropdown.css"


class MultiSelectDropDown extends React.Component {

    state = {
        all_options: [],
        selected_options: [],
        unselected_options: [],
        show_dropdown: false,
        search_box_width: 15,
        prev_search_string: ""
    }

    async componentDidMount() {
        let unselected_options = [];
        for (let items of this.props.options) unselected_options.push(items);
        unselected_options = await this.sortOptions(unselected_options);
        this.setState({ unselected_options, all_options: JSON.parse(JSON.stringify(unselected_options)) })
        if (this.props.defaultValue) {
            this.selectDefaultValues(this.props.defaultValue);
        }
    }

    // add on for pre-populated data
    selectDefaultValues = (defaultValues) => {
        let selected_options = [];
        let unselected_options = [];
        let all_options = JSON.parse(JSON.stringify(this.state.all_options));

        defaultValues.forEach((value) => {
            const item = all_options.find((option) => option.id === value.id);
            if (item) {
                selected_options.push(item);
                all_options.splice(all_options.indexOf(item), 1);
            }
        });

        this.setState({ selected_options, unselected_options: all_options });
        this.props.get_selected_items(selected_options);
    }

    deselectOption = (id) => async (event) => {
        if (id === 0) return;
        event.preventDefault();
        let selected_options = JSON.parse(JSON.stringify(this.state.selected_options));
        let unselected_options = JSON.parse(JSON.stringify(this.state.unselected_options));
        let s_item = selected_options.find(item => item.id === id);
        unselected_options.push(s_item);
        let temp = []
        for (let i = 0; i <= (selected_options.length - 1); i++) {
            if (selected_options[i].id !== id)
                temp.push(selected_options[i]);
        }
        unselected_options = await this.sortOptions(unselected_options);
        this.setState({ selected_options: temp, unselected_options });
        this.props.get_selected_items(temp);
    }

    selectOption = (id) => async (event) => {
        if (id === 0) return;
        console.log("ID : ", id);
        event.preventDefault();
        let unselected_options = JSON.parse(JSON.stringify(this.state.unselected_options));
        let selected_options = JSON.parse(JSON.stringify(this.state.selected_options));
        let s_item = unselected_options.find(item => item.id === id);
        selected_options.push(s_item);
        let temp = []
        for (let i = 0; i <= (unselected_options.length - 1); i++) {
            if (unselected_options[i].id !== id)
                temp.push(unselected_options[i]);
        }
        temp = await this.sortOptions(temp);
        this.setState({ selected_options, unselected_options: temp, show_dropdown: false });
        this.props.get_selected_items(selected_options);
    }

    toggleDropdown = (e) => {
        this.setState({ show_dropdown: this.state.show_dropdown === true ? false : true })
    }

    onSearchStringChange = async (e) => {
        e.preventDefault();
        let text = e.target.value;
        let search_box_width = this.state.search_box_width;
        let prev_search_string = this.state.prev_search_string;
        if(search_box_width <= 100 && text.length > prev_search_string.length) search_box_width+= 8;
        else if(search_box_width > 15 && text.length < prev_search_string.length) search_box_width-= 8;
        let unselected_options = []
        let selected_options_ids = []
        for (let item of this.state.selected_options){
           if(item.id !== 0) selected_options_ids.push(item.id);
        }
        for ( let item of this.state.all_options) {
            if (item.name.toLowerCase().includes(text) && selected_options_ids.includes(item.id) === false) unselected_options.push(item)
        }
        unselected_options = await this.sortOptions(unselected_options);
        this.setState({ search_box_width, prev_search_string : text, unselected_options });
    }

    activateSearch = (e) => {
        e.preventDefault();
        this.setState({show_dropdown : this.state.show_dropdown === true ? false : true})
    }

    sortOptions = async (items) => {
        let temp = [];
        let ids = [];
        for (let item of this.props.options) {
            console.log("id", item.id);
            ids.push(item.id)
        };
        for (let i = 0; i <= (items.length - 1); i++) {
            if (ids.includes(items[i].id))
                temp.push(items[i]);
        }
        console.log("Unselected Options", ids);
        temp.sort((a, b) => {
            let a1 = a.name;
            let b1 = b.name;
            return a1 - b1;
        })
        let unselected_options = [{ id: 0, name: "Select...", selected: false }];
        for (let item of temp) unselected_options.push(item);
        console.log("Unselected Options", temp);
        return unselected_options;
    }

    render() {
        console.log("From MultiSelect", this.state)
        return (
            <div className="multi-select-dropdown-wrapper">
                <div className="multi-select-dropdown-container" onClick={this.activateSearch}>
                    <div className="multi-select-dropdown-pill-container">
                        {
                            this.state.selected_options.map((item) => {
                                return (
                                    <div className="multi-select-dropdown-pill" key={item.id}>
                                        <div className="multi-select-dropdown-pill-title">{item.name}</div>
                                        <div className="multi-select-dropdown-pill-cross"><FontAwesomeIcon icon={solid('xmark')} style={{ cursor: "pointer" }} onClick={this.deselectOption(item.id)} /></div>
                                    </div>
                                    // <div className="multi-select-dropdown-list-item" key={item.id} onClick={this.selectOption(item.id)}>{item.name} 1 </div>
                                )
                            })
                        }
                        <input type='text' className="search-input" style={{ width: this.state.search_box_width }} onChange={this.onSearchStringChange} id="search-box" />
                        {/* <div className="multi-select-dropdown-pill">
                            <div className="multi-select-dropdown-pill-title">pill 1</div>
                            <div className="multi-select-dropdown-pill-cross"><FontAwesomeIcon icon={solid('xmark')} style={{cursor: "pointer"}} onClick={this.deselectOption} /></div>
                        </div> */}
                    </div>
                    <div className="dropdown-icon" onClick={this.toggleDropdown}><FontAwesomeIcon icon={solid('chevron-down')} style={{ cursor: "pointer" }} /></div>
                </div>
                {
                    this.state.show_dropdown === true &&
                    <div className="multi-select-dropdown-list">
                        {/* <div className="multi-select-dropdown-list-item">Select</div> */}
                        {
                            this.state.unselected_options.map((item) => {
                                return (
                                    <div className="multi-select-dropdown-list-item" key={item.id} onClick={this.selectOption(item.id)}>{item.name}</div>
                                )
                            })
                        }
                    </div>
                }
            </div>
        )

    }
}

export default MultiSelectDropDown;
