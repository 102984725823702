import Store from './Store.js';

class Utils {
  // constructor() {
  // }
  genKey = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  isBase64(str) {
    //alert('call');
    if (str === '' || str.trim() === '') {
      return false;
    }
    try {
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
  }

  log() {
  }

  setEnv(module_name) {
    let api_url = process.env.REACT_APP_API_URL;
    Store.updateStore('api_url', api_url);
    Store.updateStore('is_admin', false);
    Store.updateStore('is_debug', false);
    Store.updateStore('base_api_url', process.env.REACT_APP_BASE_URL)
  }

  selectedOtions(selected_options) {
    return selected_options.reduce((acc, cur) => {
      acc[cur.id] = cur.label;
      return acc;
    }, {});
  }

  isEmpty(value) {
    return (
      // null or undefined
      (value == null) ||

      // has length and it's zero
      (value.hasOwnProperty('length') && value.length === 0) ||

      // is an Object and has no keys
      (value.constructor === Object && Object.keys(value).length === 0)
    )
  }

  //generate unique id (not fixed dgt)
  uid() {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  }

  // check if a string is a valid timestamp
  isTimestamp(n) {
    const parsed = parseFloat(n);
    return !Number.isNaN(parsed) && Number.isFinite(parsed) && /^\d+\.?\d+$/.test(n);
  }


}



export default new Utils();
