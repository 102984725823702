import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import './sor.css';

class FilterIcon extends Component {
    constructor (props) {
        super(props);
        this.state = {
            isClicked: false,
        };
    }

    toggle = () => {
        this.setState((prevState) => ({ isClicked: !prevState.isClicked }), () => {
            this.props.getState(this.state.isClicked);
        });
    };


    render() {
        const { isClicked } = this.state;
        return (
            <div className={`icon-container-funnel ${isClicked ? 'clicked' : ''}`} onClick={this.toggle}>
                <FontAwesomeIcon title="Action" icon={solid('filter')} />
            </div>
        );
    }
}

export default FilterIcon;
