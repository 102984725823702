import React from "react";
import './CertSetupField.css';
import { SketchPicker } from "react-color";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { PdfFontMapping,  DisplayFontMapping, StyleDefaults, FontVarietyAvailable } from "../../../Certifications/Pdf_Font_n_Icons/FontMapping";
import moment from "moment";

class CertSetupField extends React.Component {

    state = { 
        field_style : {}, 
        fonts : StyleDefaults.fonts, 
        sizes : StyleDefaults.sizes, 
        date_formats : StyleDefaults.date_formats,
        selected_font_to_display : "", 
        selected_font_to_apply : "", 
        selected_font_style : "",
        selected_font_color : "",
        selected_font_size : "",
        selected_font_weight : "",
        bold : false,
        bold_active : true,
        italic : false,
        italic_active : true,
        edit_active : false,
        value : "",
        selected_format : "",
        show_color_picker : false
    }

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
      }

    componentDidMount(){
        this.initializeState();
    }

    componentDidUpdate(prevProps){
        if(this.props !== prevProps){
            this.initializeState();
        }
    }

    initializeState = () => {
        let field_style = this.props.field_style;
        let display_font = DisplayFontMapping[field_style.font_id];
        let selected_font_to_apply = display_font.font_family;
        let selected_font_style = display_font.font_style;
        let selected_font_weight = display_font.font_weight;
        let pdf_font = PdfFontMapping[field_style.font_id];
        let selected_font_to_display = pdf_font.font_name;
        let selected_font_color = field_style.font_color !== 'default' ? field_style.font_color : StyleDefaults.role_id_color ;
        let selected_font_size = field_style.font_size;
        let bold = pdf_font.font_style === 'bold' || pdf_font.font_style === 'bolditalic' ? true : false;
        let italic = pdf_font.font_style === 'italic' || pdf_font.font_style === 'bolditalic' ? true : false;
        let bold_active = true;
        let italic_active = true;
        if(FontVarietyAvailable[selected_font_to_display].length === 1 && FontVarietyAvailable[selected_font_to_display][0] === 'regular'){
            bold_active = false;
            italic_active = false;
        }
        let value = this.props.field_style.value;
        let selected_format = this.props.field_style.format;
        this.setState( { value, selected_format, field_style, selected_font_to_display, selected_font_to_apply, selected_font_style, selected_font_color, selected_font_size, selected_font_weight, bold, italic, bold_active, italic_active});
    }

    handleFontChange = (e) => {
        e.preventDefault();
        this.setState({selected_font_to_display : e.target.value})
        let font_key = null;
        let bold_active = true;
        let italic_active = true;
        let font_style = this.state.bold ? this.state.italic ? 'bolditalic' : 'bold' : this.state.italic ? 'italic' : 'regular';
        if(FontVarietyAvailable[e.target.value].length === 1 && FontVarietyAvailable[e.target.value][0] === 'regular'){
            bold_active = false;
            italic_active = false;
            font_style = 'regular';
        }
        for(let key in PdfFontMapping){
            if(PdfFontMapping[key].font_name === e.target.value && PdfFontMapping[key].font_style === font_style){
                font_key = key;
                break;
            }
        }
        if(font_key !== null){
            console.log("Font Change : ", font_key);
            let selected_font_to_apply = DisplayFontMapping[font_key].font_family;
            let selected_font_style = DisplayFontMapping[font_key].font_style;
            let selected_font_weight = DisplayFontMapping[font_key].font_weight;
            let field_style = JSON.parse(JSON.stringify(this.state.field_style));
            let new_field_style = {...field_style, font_id : font_key};
            this.setState({ selected_font_to_apply, selected_font_style, selected_font_weight, field_style : new_field_style, bold_active, italic_active});
            this.props.getFieldStyle(this.props.field, new_field_style);
        }
    }

    handleFontSizeChange = (e) => {
        e.preventDefault();
        let field_style = JSON.parse(JSON.stringify(this.state.field_style));
        let new_field_style = {...field_style, font_size : e.target.value};
        this.setState({selected_font_size : e.target.value, field_style: new_field_style});
        this.props.getFieldStyle(this.props.field, new_field_style);
    }

    handleBold = (e) => {
        e.preventDefault();
        let bold = this.state.bold ? false : true;
        this.setState({ bold });
        let style = bold ? this.state.italic ? "bolditalic": "bold" : this.state.italic ? "italic" : "regular";
        let font_key = null;
        for(let key in PdfFontMapping){
            if(PdfFontMapping[key].font_name === this.state.selected_font_to_display && PdfFontMapping[key].font_style === style){
                font_key = key;
                break;
            }
        }
        if(font_key !== null){
            console.log("Font Change : ", font_key);
            let selected_font_to_apply = DisplayFontMapping[font_key].font_family;
            let selected_font_style = DisplayFontMapping[font_key].font_style;
            let selected_font_weight = DisplayFontMapping[font_key].font_weight;
            let field_style = JSON.parse(JSON.stringify(this.state.field_style));
            let new_field_style = {...field_style, font_id : font_key};
            this.setState({ selected_font_to_apply, selected_font_style, selected_font_weight, field_style : new_field_style});
            this.props.getFieldStyle(this.props.field, new_field_style);
        }
    }

    handleItalic = (e) => {
        e.preventDefault();
        let italic = this.state.italic ? false : true;
        this.setState({ italic });
        let style = italic ? this.state.bold ? "bolditalic": "italic" : this.state.bold ? "bold" : "regular";
        let font_key = null;
        for(let key in PdfFontMapping){
            if(PdfFontMapping[key].font_name === this.state.selected_font_to_display && PdfFontMapping[key].font_style === style){
                font_key = key;
                break;
            }
        }
        if(font_key !== null){
            console.log("Font Change : ", font_key);
            let selected_font_to_apply = DisplayFontMapping[font_key].font_family;
            let selected_font_style = DisplayFontMapping[font_key].font_style;
            let selected_font_weight = DisplayFontMapping[font_key].font_weight;
            let field_style = JSON.parse(JSON.stringify(this.state.field_style));
            let new_field_style = {...field_style, font_id : font_key};
            this.setState({ selected_font_to_apply, selected_font_style, selected_font_weight, field_style : new_field_style});
            this.props.getFieldStyle(this.props.field, new_field_style);
        }
    }

    handleColorClick = (e) => {
        this.setState({show_color_picker : this.state.show_color_picker ? false : true});
    }

    handleFontColorChange = (color, e) => {
        //e.preventDefault();
        console.log('color : ', color);
        let field_style = JSON.parse(JSON.stringify(this.state.field_style));
        let new_field_style = {...field_style, font_color : color.hex};
        this.setState({selected_font_color : color.hex, field_style : new_field_style});
        this.props.getFieldStyle(this.props.field, new_field_style);
    }

    setEditActive = (status) => (e) => {
        e.preventDefault();
        this.setState({ edit_active : status});
        if (status && this.myRef.current) {
            setTimeout(() => {
                this.myRef.current.focus();
                const range = document.createRange();
                range.selectNodeContents(this.myRef.current);
                range.collapse(false); // Set to end of the content
                const selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);
            }, 0);
        }
    }

    getNewValue = (e) => {
        e.preventDefault();
        let value = e.target.value;
        //value = value.trim() === '' ? ' ' : value;
        let field_style = JSON.parse(JSON.stringify(this.state.field_style));
        let new_field_style = {...field_style, value : value};
        this.setState({field_style : new_field_style, value});
        this.props.getFieldStyle(this.props.field, new_field_style);
    }

    changeDateFormat = (e) => {
        e.preventDefault();
        let format = e.target.value;
        let field_style = JSON.parse(JSON.stringify(this.state.field_style));
        let new_field_style = {...field_style, format : format};
        this.setState({field_style : new_field_style, selected_format : format});
        this.props.getFieldStyle(this.props.field, new_field_style);
    }

    changeDefaultFontColor = (value) => (e) => {
        e.preventDefault();
        let field_style = JSON.parse(JSON.stringify(this.state.field_style));
        let new_field_style = {...field_style, font_color : value};
        this.setState({field_style : new_field_style});
        this.props.getFieldStyle(this.props.field, new_field_style);
    }

    render(){


        //console.log("Current State : ", this.state);
        
        return(
            <div className="csf-container">
                <div className="csf-header">
                    Font 
                    <select className="csf-select" value={this.state.selected_font_to_display} onChange={this.handleFontChange}>
                        {
                            this.state.fonts.map((font, i)=>{
                                return <option key={i} value={font}>{font}</option>
                            })
                        }
                    </select>
                    <div className="csf-v-separator" />
                    <span style={{marginLeft : "10px"}}>Font Size</span>
                    <select className="csf-select" value={this.state.selected_font_size} onChange={this.handleFontSizeChange}>
                        {
                            this.state.sizes.map((size, i)=>{
                                return <option key={i} value={size}>{size}</option>
                            })
                        }
                    </select> <span style={{marginLeft : "5px"}}>pt</span>
                    <div className="csf-v-separator" />
                    {
                        this.state.bold_active && 
                        <div className={`csf-button csf-button-bold${this.state.bold ? " csf-button-active" : ""}`} onClick={this.handleBold}>B</div>
                    }
                    {
                        !this.state.bold_active && 
                        <div className={`csf-button csf-button-bold csf-button-inactive`}>B</div>
                    }
                    {
                        this.state.italic_active &&
                        <div className={`csf-button csf-button-italic${this.state.italic ? " csf-button-active" : ""}`} onClick={this.handleItalic}>I</div>
                    }
                    {
                        !this.state.italic_active && 
                        <div className={`csf-button csf-button-italic csf-button-inactive`}>I</div>
                    }
                    <div className="csf-v-separator" />
                    {
                        (this.props.field !== 'role_id' || this.props.field === 'role_id' && this.state.field_style.font_color !== 'default')  && 
                        <div className="csf-color-label" onClick={this.handleColorClick}> Font Colour
                            {/* <input type="color" id="fcolor" className="csf-color-input" value={this.state.selected_font_color} onChange={this.handleFontColorChange}/> */}
                            <div style={{ width : "14px", height : "12px", backgroundColor : this.state.selected_font_color}}></div>
                            <div  className="csf-color-input" style={{ visibility : this.state.show_color_picker ? "visible" : "collapse"}}>
                                <SketchPicker 
                                    id="fcolor"
                                    color={ this.state.selected_font_color }
                                    disableAlpha = {true}
                                    onChange={ this.handleFontColorChange }
                                    onChangeComplete={ this.handleFontColorChange }
                                />
                            </div>
                        </div>
                    }
                </div>
                <div className="csf-body">
                    {
                        (()=>{
                            if(this.state.field_style.editable){
                                return(
                                    <input type="text"
                                        style={{
                                            fontFamily : this.state.selected_font_to_apply,
                                            color : this.state.selected_font_color,
                                            fontStyle : this.state.selected_font_style,
                                            fontWeight : this.state.selected_font_weight,
                                            fontSize : `${this.state.selected_font_size}pt`,
                                            lineHeight : `${this.state.selected_font_size}pt`
                                        }}
                                        value={this.state.value}
                                        className="csf-body-input"
                                        onChange={this.getNewValue}
                                    />
                                )
                            }else{
                                return(
                                    <div
                                        style={{
                                            fontFamily : this.state.selected_font_to_apply,
                                            color : this.state.selected_font_color,
                                            fontStyle : this.state.selected_font_style,
                                            fontWeight : this.state.selected_font_weight,
                                            fontSize : `${this.state.selected_font_size}pt`,
                                            lineHeight : `${this.state.selected_font_size}pt`
                                        }}
                                        value={this.state.value}
                                        className="csf-body-input"
                                    >
                                        {this.state.field_style.value !== 'curdate' ? this.state.field_style.value : moment().format(this.state.selected_format)}
                                    </div>
                                )
                            }
                        })()
                    }
                    {
                        this.state.field_style.value === 'curdate' && 
                        <div className="csf-date-format-container">
                            Date Format 
                            <select className="csf-select" value={this.state.selected_format} onChange={this.changeDateFormat}>
                                {
                                    this.state.date_formats.map((format,i)=>{
                                        return <option key={i}>{format}</option>
                                    })
                                }
                            </select>
                        </div>
                    }
                    {
                        this.props.field === 'role_id' && 
                        <div className="csf-date-format-container">
                            { this.state.field_style.font_color !== 'default' && <FontAwesomeIcon icon={regular('square')} className="csf-check-icon" onClick={this.changeDefaultFontColor('default')}/>}
                            { this.state.field_style.font_color === 'default' && <FontAwesomeIcon icon={regular('check-square')}  className="csf-check-icon"  onClick={this.changeDefaultFontColor(StyleDefaults.role_id_color)}/>}
                            Use default colours for Role IDs
                        </div>
                    }
                       
                        {/* { this.state.field_style.editable === true && this.state.edit_active === false && <div className="csf-edit-icon"><FontAwesomeIcon icon={regular('pencil')} onClick={this.setEditActive(true)}/></div>}
                        { this.state.field_style.editable === true && this.state.edit_active === true && <div className="csf-edit-icon"><FontAwesomeIcon icon={regular('check')} onClick={this.setEditActive(false)}/></div>} */}
                </div>
            </div>
        )
    }
}

export default CertSetupField;