import React from 'react'
import styled from 'styled-components'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import Pagination from "./Pagination";
import Store from '../../Common/Store';

import "./table_styles.css";

const TableContainer = styled.div`
    background-color: #ffffff;
`

class CSLTable extends React.Component {
    state = { toggle_state: true };

    constructor (props) {
        super(props);
        // console.log("props", props);
    }

    componentDidMount() {
        if ("toggle_state" in this.props) this.setState({ toggle_state: this.props.toggle_state });
        const currentRecords = this.selectTable.getResolvedState().sortedData;
        let current_record_ids = [];
        if (currentRecords.lenght != 0 && "from" in this.props && this.props.from === "roles_respo") {
            for (let record of currentRecords) {
                current_record_ids.push(record._original.id);
            }
            Store.updateStore("roles_respo_table_current_record_ids", current_record_ids);
            // this.setState({current_record_ids});
            console.log("Current record ids component did mount : ", current_record_ids);
        }
    }

    componentDidUpdate(prev_props) {
        if (this.props !== prev_props) {
            if ("toggle_state" in this.props) this.setState({ toggle_state: this.props.toggle_state });
        }
    }

    filterMethod = (filter, row, column) => {
        const id = filter.pivotId || filter.id
        // console.log('id',id);
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
    }

    handleFilterChange = () => {
        const currentRecords = this.selectTable.getResolvedState().sortedData;
        let current_record_ids = [];
        if (currentRecords.lenght != 0 && "from" in this.props && this.props.from === "roles_respo") {
            for (let record of currentRecords) {
                current_record_ids.push(record._original.id);
                //console.log("Record : ", record)
            }
            Store.updateStore("roles_respo_table_current_record_ids", current_record_ids);
        }
        //this.setState({current_record_ids});
        // console.log("currentRecords:", currentRecords, "ids", current_record_ids);
    }

    openAssignQcModal = (event) => {
        event.preventDefault();
        this.props.toggleAssignQcModal("assignqcmodal");
    }

    exportToCSV = () => (event) => {
        event.preventDefault();
        let fileName = "export";
        if ('export_file_name' in this.props) {
            fileName = this.props.export_file_name;
        }
        let tableData = this.props.processData();

        let headerCSV = tableData.export_columns;
        console.log("headerCSV", headerCSV)
        let data = tableData.data;
        let columns = tableData.columns;
        let csv_data_arr = [];

        csv_data_arr.unshift(headerCSV);
        for (let k = 0; k < data.length; k++) {
            let cont = {};

            Object.entries(headerCSV).forEach(([key, value]) => {
                // console.log("data[k][key]",data[k][key])
                // cont[key] = data[k][key].replace(/,/g, ' ');
                cont[key] = data[k][key].replace(/,/g, ' ').replace(/-/g, '-');
            })
            csv_data_arr.push(cont);
        }
        console.log("csv_data_arr", csv_data_arr)
        var csv_data_final_arr = [];
        for (let j = 0; j < csv_data_arr.length; j++) {
            var container = [];
            container = Object.values(csv_data_arr[j])
            if (container.length > 0);
            csv_data_final_arr.push(container)
        }
        console.log("csv_data_final_arr", csv_data_final_arr)
        let csvContent = "data:text/csv;charset=utf-8," + csv_data_final_arr.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", fileName + ".csv");
        document.body.appendChild(link);
        link.click();

    }

    goToReport = () => (event) => {
        event.preventDefault();
        this.props.goToReport();
    }

    conditionalCellStyling = (state, rowInfo, column) => {
        // Grey's out inactive assessments in the assessment dashboard
        if (rowInfo?.row?._original?.is_active === false) {
            return {
                style: {
                    color: 'gray',
                },
            }
        }
        return {}
    }

    render() {
        // console.log('this.props', this.props.prefered_columns);
        let tableData = null;
        if ("id" in this.props)
            tableData = this.props.processData(this.props.id);
        else
            tableData = this.props.processData();
        //let tableData = this.props.processData();
        // console.log('tableData', tableData);
        let row_count = "tableRows" in this.props ? this.props.tableRows : 10;
        return (
            <TableContainer>
                <div style={{ width: '100%' }}>
                    <ReactTable
                        ref={(r) => { this.selectTable = r; }}
                        PaginationComponent={Pagination}
                        data={tableData.data}
                        columns={tableData.columns}
                        defaultPageSize={parseInt(row_count)}
                        filterable={true}
                        defaultFilterMethod={this.filterMethod}
                        onFilteredChange={this.handleFilterChange}
                        noDataText=""
                        getTdProps={(state, rowInfo, column) => this.conditionalCellStyling(state, rowInfo, column)}
                    />

                    <div style={{ clear: "both" }}></div>
                </div>
            </TableContainer>
        );
    }
}

export default CSLTable;
