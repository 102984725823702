import React from 'react'
import EventBus from '../../Common/EventBus'

class AcceptanceAlert extends React.Component {

    state = {needs_acceptance: false}

    componentDidMount() {
        EventBus.registerEvent('needs_acceptance', 'AcceptanceAlert', this.setNeedsAcceptance)
    }

    setNeedsAcceptance = (needs_acceptance) => {
        // console.log("needs_acceptance:", needs_acceptance)
        this.setState({needs_acceptance: needs_acceptance})
    }

    render() {
        return (
            <>
                {
                    this.state.needs_acceptance === true &&
                        <div style={{padding: "0px 30px 30px 30px"}}>
                            <div className="mcc-alert error">
                                <div style={{fontWeight: "600"}}>One or more roles are pending your acceptance. Please click on the 'Review Roles' button. This will open a form to allow you to review and accept or decline your assigned roles.</div>
                            </div>
                        </div>
                }
            </>
        )
    }
}

export default AcceptanceAlert