import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import APICall from "../../Common/APICall";
import CSLLoader from "../Common/CSLLoader";
import './EditEmployee.css'
import SearchableComboBox from './SearchableComboBox';



class EditEmployeeModal extends React.Component {

    state = { employee: { row_id: 0, jobTitleId: '0', joinedDate: '', appointedDate: '', comments: '', description: '' }, job_titles: [], alert_param: null };

    async componentDidMount() {
        await this.setComponentState()
    }
    async componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            await this.setComponentState()
        }
    }

    setComponentState = async () => {
        const payload1 = { command: "personnel", action: 'list_jobtitles' }
        const payload2 = { command: "personnel", action: 'fetch_employee_jobtitle_id', employee_id: this.props.employee_id }
        const payload3 = { command: "personnel", action: 'fetch_employee_header_data', employee_id: this.props.employee_id }

        const api = new APICall()
        const [job_titles_response, employee_job_title_resp, employee_details_response] = await Promise.all([
            api.commandWithoutCallback(payload1),
            api.commandWithoutCallback(payload2),
            api.commandWithoutCallback(payload3),
        ]);

        let job_titles = job_titles_response.result.job_titles;
        let employee_details_obj = employee_details_response.result.employee_details;
        let employee_jobtitle_obj = employee_job_title_resp.result.job_title_id;

        console.log('employee_job_title_resp', employee_details_obj);

        let employee = this.state.employee;


        employee.jobTitleId = employee_jobtitle_obj.hasOwnProperty('jt_id') ? employee_jobtitle_obj['jt_id'] : 0;
        employee.row_id = employee_details_obj.row_id;
        employee.joinedDate = employee_details_obj.joinedDate;
        employee.appointedDate = employee_details_obj.appointedDate;
        employee.comments = employee_details_obj.comments;
        employee.description = employee_details_obj.description;

        this.setState({ employee, job_titles })
    }

    closeEditModal = () => {
        this.props.closeModal();
    }

    confirmHandler = () => {
        this.setState({ alert_param: null }, () => {
            this.props.closeModal();
        });
    }

    updateEmployee = async (e) => {
        e.preventDefault();
        let alert_param = null;
        let employee = this.state.employee;
        // Set appointedDate to null if it's empty or null
        if (!employee.appointedDate) {
            employee.appointedDate = null;
        }
        // Set joined date to null if it's empty or null
        if (!employee.joinedDate) {
            employee.joinedDate = null;
        }

        const payload = { command: "personnel", action: 'update_employee_details', employee_id: this.props.employee_id, employee_obj: this.state.employee }
        const api = new APICall()

        const result = await api.commandWithoutCallback(payload);
        employee.row_id = result.result.rowId;
        if (result.error_code == 0) {
            alert_param = { title: 'Sucess', message: "Employee details updated successfully", ok_text: 'OK', confirm: false, alertHandler: this.confirmHandler }
        } else {
            alert_param = { title: 'Error', message: "Problem occured when updating employee record", ok_text: 'OK', confirm: false, alertHandler: this.confirmHandler }
        }
        this.setState({ alert_param: alert_param });
    }

    changejobTitle = (event) => {
        event.preventDefault()
        let employee = {
            ...this.state.employee,
            jobTitleId: parseInt(event.target.value)
        }
        this.setState({ employee })
    }

    changeJoinedDate = (event) => {
        event.preventDefault();
        const selectedDate = event.target.value;
        const dateObject = new Date(selectedDate);
        const year = dateObject.getFullYear();
        const month = ('0' + (dateObject.getMonth() + 1)).slice(-2);
        const day = ('0' + dateObject.getDate()).slice(-2);
        const formattedDateString = `${year}-${month}-${day}`;

        let employee = {
            ...this.state.employee,
            joinedDate: formattedDateString
        }
        this.setState({ employee });
    }

    changeAppointedDate = (event) => {
        event.preventDefault();
        const selectedDate = event.target.value;
        const dateObject = new Date(selectedDate);
        const year = dateObject.getFullYear();
        const month = ('0' + (dateObject.getMonth() + 1)).slice(-2);
        const day = ('0' + dateObject.getDate()).slice(-2);
        const formattedDateString = `${year}-${month}-${day}`;

        let employee = {
            ...this.state.employee,
            appointedDate: formattedDateString
        }
        this.setState({ employee });
    }

    changeDescription = (event) => {
        event.preventDefault();
        const desc = event.target.value;
        const employee = {
            ...this.state.employee,
            description: desc
        };
        this.setState({ employee });
    }

    changeComment = (event) => {
        event.preventDefault();
        const comment = event.target.value;
        const employee = {
            ...this.state.employee,
            comments: comment
        };
        this.setState({ employee });
    }

    handleOptionChange = (v) => {
        let employee = this.state.employee;
        employee.jobTitleId = v.id;

        const job_titles = this.state.job_titles;
        const index = job_titles.findIndex(obj => obj.name.toLowerCase() === v.name.toLowerCase());

        if (index === -1) {
            job_titles.push(v);
        }

        this.setState({ employee, job_titles })
    }


    render() {
        const empData = Object.values(this.state).every(val => val == '');
        return (
            <React.Fragment>
                <div className="add-role-modal-container" style={{ top: "0%", width: "50%", position: 'absolute', zIndex: 9999 }}>
                    {empData && <CSLLoader />}
                    <div className="add-role-modal-header-container">
                        <div className="add-role-modal-header-text-container">
                            <div className="add-role-modal-header-title">Edit Employee Details</div>
                            <div className="add-role-modal-header-subtitle">Use this section to edit Employee details.</div>
                        </div>
                        <FontAwesomeIcon icon={solid('xmark')} onClick={this.closeEditModal} size="2x" style={{ cursor: "pointer" }} />
                    </div>
                    <div className="add-role-modal-body-container">
                        <div>
                            <div className="add-role-modal-label">Job title</div>
                            <SearchableComboBox
                                key={this.state.employee.jobTitleId}
                                options={this.state.job_titles}
                                onChange={this.handleOptionChange}
                                placeholder="Type here to search or add a new option"
                                notFoundContent="No options found.Press Enter to add in database"
                                selectedOptionId={this.state.employee.jobTitleId}
                            />
                        </div>
                        <div className="employee-edit-container">
                            <div className="employee-edit-date-container">
                                <div className="employee-edit-date-picker">
                                    <label htmlFor="joinedDate">Joined:</label>
                                    <input type="date" id="joinedDate" className="custom-date-input"
                                        value={this.state.employee.joinedDate ? this.state.employee.joinedDate.substr(0, 10) : ''}
                                        onChange={(e) => this.changeJoinedDate(e)} />
                                </div>
                                <div className="employee-edit-date-picker" style={{ marginRight: '2px' }}>
                                    <label htmlFor="appointedDate">Appointed:</label>
                                    <input type="date" id="appointedDate" className="custom-date-input"
                                        value={this.state.employee.appointedDate ? this.state.employee.appointedDate.substr(0, 10) : ''}
                                        onChange={(e) => this.changeAppointedDate(e)} />
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="add-role-modal-label">Description</div>
                            <textarea rows={6} className="add-role-modal-textarea" name="employee-description" value={this.state.employee.description} onChange={(e) => this.changeDescription(e)} />
                        </div>
                        <div>
                            <div className="add-role-modal-label">Comments</div>
                            <textarea rows={6} className="add-role-modal-textarea" name="firm_definition" value={this.state.employee.comments} onChange={this.changeComment} />
                        </div>
                    </div>
                    <div className="add-role-modal-footer-container">
                        <button className="add-role-modal-cancel-button" onClick={this.closeEditModal}>Cancel</button>
                        <button className="add-role-modal-save-button" onClick={(e) => this.updateEmployee(e)}>Save</button>
                    </div>

                    {
                        this.state.alert_param != null &&
                        <div class="employee-edit-modal">
                            <div class="employee-edit-modal-content">
                                <div class="employee-edit-modal-header">
                                    <span class="employee-edit-modal-title">{this.state.alert_param.title}</span>
                                </div>
                                <div class="employee-edit-modal-body">
                                    <p>{this.state.alert_param.message}</p>
                                </div>
                                <div class="employee-edit-modal-footer">
                                    <button class="employee-edit-modal-button" onClick={this.confirmHandler}>OK</button>
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </React.Fragment >
        )
    }
}

export default EditEmployeeModal
