import React from 'react';
import styled from 'styled-components';
import Store from './../../../Common/Store';

const CommitteeDiv = styled.div`
    padding:22px 10px 9px 9px;
    margin-top:10px;
    background-color: #F7F7F7;
    border-style: solid;
    border-size: 2px;
    border-color: #EAEAEA;
    color: #63758A;
    display: inline-block;
    width: 100%;
    height: 30px;
    position:relative;
    font-weight: 700;
    font-size:14px;
`;
const RolesDiv = styled.div`
    padding:2px 9px 9px 9px;
    margin-top:2px;
    background-color: #FFFFFF;
    color: #1BB2B2;
    font-weight: 500;
    font-size:12px;
	
`
const CompanyNameDiv = styled.div`
	width: 220px;
	color: #63758A;
	float: right;
    text-overflow: ellipsis; 
    overflow: hidden; 
    white-space: nowrap;
    margin-right: 10px;
`
const NameDiv = styled.div`
	color: #63758A;
	float: left;
    text-overflow: ellipsis; 
    overflow: hidden; 
    white-space: nowrap;
	width: 450px;
`
const PurposeDiv = styled.div`
	width: 220px;
	color: #63758A;
	float:right;
	margin-right: 10px;
    text-overflow: ellipsis; 
    overflow: hidden; 
    white-space: nowrap;
`

const EntryDiv = styled.div`
	width: 100%;
	font-size: 12px;
	font-weight: 700;
	padding-left: 3px;
    text-overflow: ellipsis; 
    overflow: hidden; 
    white-space: nowrap;
`

const Connector = styled.div`
	width: 18px;
	border-bottom-style: solid;
	border-left-style: solid;
	border-left-size: 4px;
	border-bottom-size: 4px;
	border-color: #1BB2B2;
	position: absolute;
`

class Committee extends React.Component {
	state = {ready: false}

	componentDidMount = () => {
		let members = Store.getStoreData('members')
		this.setState({ready: true, members: members})
	}

	render() {
		console.log('Committee props', this.props)
		if(!this.state.ready)return (<div></div>)
		// return (<div></div>)
		let indent = this.props.committee.level * 30
		// let connector_top = this.props.committee.prev_y + 85
		// let connector_height = this.props.committee.y - connector_top + 43
		// let connector_left = (this.props.committee.level - 1) * 30 + 2

		let connector_top = this.props.committee.prev_y + 100 -16;
		let connector_height = this.props.committee.y - connector_top + 36;
		let connector_left = (this.props.committee.level - 1) * 30 + 2;
		console.log('Committee connector_top, connector_height', connector_top, connector_height)


		return (
				<div>
				{
					(() => {
						if(this.props.committee.level > 0)return <Connector style={{height: connector_height, top: connector_top, left: connector_left}} />
					})()
				}
					
					<CommitteeDiv style={{marginLeft: indent, width: `calc(100% - 60px - ${indent}px)`}}>
						<NameDiv title={this.props.committee.name}>{this.props.committee.name}</NameDiv>
						<PurposeDiv title={this.props.committee.purpose}>{this.props.committee.purpose}</PurposeDiv>
						<CompanyNameDiv title={this.props.committee.company_name}>{this.props.committee.company_name}</CompanyNameDiv>
					</CommitteeDiv>
					{
						(() => {
							if(this.props.showRoles) {
								return (
										<RolesDiv style={{width: `calc(100% - 60px`}}>
											<PurposeDiv>
											{
												this.props.committee.members.map((item, i) => {
													return (<EntryDiv>{item.committeerolename}</EntryDiv>)
												})
											}
											{
												this.props.committee.alt_members.map((item, i) => {
													if(item.thirdpartyembername !== '')return (<EntryDiv>{item.thirdpartyemberreason}</EntryDiv>)
												})
											}
											</PurposeDiv>
											<CompanyNameDiv>
											{
												this.props.committee.members.map((item, i) => {
													return (<EntryDiv>{this.state.members[item.employeeid]}</EntryDiv>)
												})
											}
											{
												this.props.committee.alt_members.map((item, i) => {
													if(item.thirdpartyembername !== '')return (<EntryDiv>{item.thirdpartyembername}</EntryDiv>)
												})
											}
											</CompanyNameDiv>
										</RolesDiv>
									)
							}
						})()
					}
					{
						this.props.committee.children.map((item, i) => {
							return (
									<Committee 
										key={i} 
										committee={item}
										horizontalGap={this.props.horizontalGap}
			                        	showRoles={this.props.showRoles}
									/>
								)
						})
					}
				</div>
			)
	}
}

export default Committee