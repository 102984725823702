import React from 'react';
import PropTypes from "prop-types";
import DatePicker from 'react-date-picker';
import '../../Components/Common/CSLDatePickerRemoveBorder.css';

class CSLDatePickerRemoveBorder extends React.Component {

    static propTypes = {
        /** Initial Date */
        updateFromDate: PropTypes.instanceOf(Date),
        updateToDate: PropTypes.instanceOf(Date),
        /** Return Date instance*/
        ondateValue: PropTypes.func,
        /** Reset value */
        isclear: PropTypes.bool,
        /** callback: Date has changed */
        changeClear: PropTypes.func,
    };

    state = { date: new Date() };

    componentDidMount() {

        let curDate = new Date();
        if ('updateFromDate' in this.props) {
            curDate = this.props.updateFromDate;
        }
        if ('updateToDate' in this.props) {
            curDate = this.props.updateToDate;
        }
        this.setState({ date: curDate })
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if (this.props.isclear) {
                let curDate = new Date();
                if ('updateFromDate' in this.props)
                    curDate = this.props.updateFromDate;
                if ('updateToDate' in this.props)
                    curDate = this.props.updateToDate;
                this.props.changeClear()
                this.setState({ date: curDate })
            }
        }
    }

    onChange = (date) => {
        this.setState({ date });
        this.props.ondateValue(date);
    }

    // onChange = date => this.setState({ date });
    render() {
        // console.log('this.props.readOnly',  this.props.readOnly)
        console.log("Props in CSLDatePickerRemoveBorder:", this.props)
        return (() => {

            if (this.props.hasOwnProperty("readOnly")) {
                if (this.props.readOnly == true) {
                    return (
                        <DatePicker
                            onChange={this.onChange}
                            value={this.state.date}
                            clearIcon={null}
                            calendarIcon={null}
                            locale={"en-GB"}
                            dateFormat="DD-MM-YYYY"
                            className="csldatecontainerremove"
                            readOnly
                            onKeyPress = {this.props.onKeyPress}
                        />
                    );
                } else {
                    return (
                        <DatePicker
                            onChange={this.onChange}
                            value={this.state.date}
                            clearIcon={null}
                            calendarIcon={null}
                            locale={"en-GB"}
                            dateFormat="DD-MM-YYYY"
                            className="csldatecontainerremove"
                            onKeyPress = {this.props.onKeyPress}
                        />
                    );
                }

            } else {
                return (
                    <DatePicker
                        onChange={this.onChange}
                        value={this.state.date}
                        clearIcon={null}
                        calendarIcon={null}
                        locale={"en-GB"}
                        dateFormat="DD-MM-YYYY"
                        className="csldatecontainerremove"

                    />
                );
            }
        })()


    }
}

export default CSLDatePickerRemoveBorder;
