import React from "react";
import "./RolesAndResponsibility.css"
import DefaultHeader from "../../Common/DefaultHeader";
import CSLLoader from "../Common/CSLLoader"
import WelcomeBanner from "../../Common/WelcomeBanner";
import Store from "../../Common/Store";
import APICall from "../../Common/APICall"
import HorizontalTabBar from "./HorizontalTabBar";
import CSLTable from "../Common/CSLTable"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, thin, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import CreateUpdateRoles from "./CreateUpdateRole";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import AlertBox from "../Common/AlertBox";
const moment = require('moment');

const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";


class RolesAndResponsibility extends React.Component {

    state = {
        ready: false,
        roles: [],
        role_types_arr: [],
        active_tab_data : [],
        role_type_list : [],
        role_list : [],
        employees : [],
        active_role_type_name: "",
        active_role_type_help_text : "",
        contact_by_id: [],
        active_tab_data: [],
        activate_assignment_section : false,
        edit_role_id : 0,
        show_na_text : "Showing N/A Roles",
        header_context_visible : false,
        alert_param : null
    }

    componentDidMount(){
		//console.log("componentDidMount Called")
        this.active_tab_role_type_id = 0;
		this.initialiseConfiguration();
	}

	// componentDidUpdate(prevProps) {
	// 	if (prevProps !== this.props) {
	// 		this.initialiseConfiguration();
	// 	}
	// }

    initialiseConfiguration = async () => {
		//debugger;
        const api = new APICall();
        const sldata = JSON.parse(localStorage.getItem("site_layout"));
        let company = {};
		company["CompanyName"] = sldata.result.company_name;
        let postData = { "command": "index_rolesresponsibility", "company" : company };
		const result = await api.commandWithoutCallback(postData);
        if (result.error_code === 0) {
			let company_id = result.result.company.ClientNumber;
			let company = result.result.company;
			Store.updateStore('company', company);
			Store.updateStore('company_id', company_id);
			Store.updateStore('role_list', result.result['role_list']);
			Store.updateStore('role_type_list', result.result['role_type_list']);
			Store.updateStore('mc_general', result.result.mc_general)
			//this.initializeStore();
			//console.log("Store Initialized",Store.getAllStoreData());
			//this.setState({ ready: true });
			//console.log("INDEX CALL END : ", moment().unix());
		} else {
			let alert_param = {
				title: 'System Error', message: 'Please contact your manager or Compliancy Services customer support on: Tel: 01462 510022 or at regtech@compliancy-services.co.uk.', ok_text: 'Ok', confirm: false,
				alertHandler: this.alertHandler, stack: { id: 0 }
			}
			this.setState({ ready: true, alert_param: alert_param });
			return
		}
		console.log("roles respo api call result : ");
		let active_tab = '';
		let active_role_type_name = '';
        let active_role_type_help_text = "";
        let active_tab_role_type_id = 0;
		let prefered_columns = {};
		prefered_columns['role_type'] = 'Role Type';
		let role_types_arr = [];
		let i = 0;
        console.log("role_type_list",Store.getStoreData('role_type_list'));
        console.log("role_list",Store.getStoreData('role_list'));
        Store.getStoreData('role_type_list').forEach((item) => {  //change arnab
			let role_exists = Store.getStoreData('role_list').find(role => role.role_type_id === item.id);
            //console.log('role_exists',role_exists);
            let has_roles = role_exists !== undefined;
            let cont = {};
            if(has_roles == true){       
                cont["id"] = item.id;
                cont["base"] = item.base;
                cont["role_type"] = item.name;
                cont["help_text"] = JSON.parse(item.role_type_json).help_text;

                if (i === 0) {
                    active_tab = item.base;
                    active_role_type_name = item.name;
                    active_role_type_help_text = JSON.parse(item.role_type_json).help_text;
                    active_tab_role_type_id = item.id;
                    cont["selected"] = true;
                    i++;
                }
                role_types_arr.push(cont);
            }
            cont = {};
		})
        let role_types_arr_new = [];
        console.log("Active Role Type ID : ", this.active_tab_role_type_id, active_tab_role_type_id);
        const cookie_active_tab = document.cookie
            .split(';')
            .find(cookie => cookie.startsWith('activeTab='))
            ?.split('=')[1];
            
        console.log('cookie_active_tab : ',cookie_active_tab);
        if(cookie_active_tab !== undefined){
            role_types_arr.forEach((item) => {
                item.selected = false;
                if (item.id === parseInt(cookie_active_tab)) {
                    item.selected = true;
                    active_role_type_name = item.role_type;
                    active_role_type_help_text = item.help_text;
                }
                role_types_arr_new.push(item);
            })
            active_tab_role_type_id =  parseInt(cookie_active_tab);
        }
        let roles = await this.fetchActiveTabData(active_tab_role_type_id);
        let active_tab_data = []
        if (roles.length !== 0) {
            active_tab_data = await this.prepareActiveTabData(roles)
        }
        console.log("roles:", roles)
		let listcontacts = Store.getStoreData('listcontacts');
		console.log("ListContact : ",listcontacts);
		let contact_by_id = {}
		listcontacts.forEach((item) => {
			contact_by_id[item.ID] = item;
		})
        this.active_tab_role_type_id = active_tab_role_type_id;
        this.setState({
			ready: true, employees: listcontacts, role_types_arr: role_types_arr_new.length === 0 ? role_types_arr : role_types_arr_new, active_tab_role_type_id, roles,
			active_role_type_name: active_role_type_name, contact_by_id: contact_by_id, active_role_type_help_text,
			role_list : Store.getStoreData('role_list'), role_type_list : Store.getStoreData('role_type_list'),
            active_tab_data
		})
    }

    fetchActiveTabData = async (role_type_id) =>{
		console.log("ROLE TYPE ID FOR FETCH : ",role_type_id);
		const postData = { command: "smcr_roles_for_listing", role_type_id: role_type_id, current_date : parseInt(moment().format("YYYYMMDD")) };
		const api = new APICall();
        const result = await api.commandWithoutCallback(postData)
        let roles = []
        if (result.error_code === 0) roles = result.result
        return roles
		// api.command(postData, this.prepareActiveTabData);
    }

    prepareActiveTabData = async (roles, show_na_text=null) =>{
        //console.log("ROLES LENGTH : ",result.roles.length);
        //console.log("ACTIVE DATA FETCH : ",result.roles);
		// const roles = result.result;
        let active_tab_data = [];
        let i=1;
        for(let i=0; i < roles.length ; i++){
            let item = roles[i];
            console.log("Na : ", item.is_currently_na, "Text : ", this.state.show_na_text);
            if(item.is_currently_na === true && show_na_text === "Hiding N/A Roles") continue;                
            let role_detail = {};
            //debugger;
            role_detail["id"] = item.role_id;
            role_detail["role_id"] = item.role_code;
            role_detail["title"] = item.title;
            role_detail["description"] = item.desc;
            role_detail["status"] = item.assigned_to.length > 0 ? "ASSIGNED" : "NOT ASSIGNED";
            //console.log("assigned length : ", item.assigned_to.length);
            role_detail["assigned_to"] = item.assigned_to.length === 0 ? "None" : item.assigned_to.length === 1 ? (Store.getStoreData('listcontacts').find(con => parseInt(con.ID) === item.assigned_to[0])).ContactName : "Multiple" ; //<span onClick={(e) => this.showMultiple(e, item.role_id)} style={{ color: "#24B3AD", fontWeight: "700", cursor: 'pointer' }}>Multiple</span>;
            role_detail["date"] = moment(item.created_at).format("DD/MM/YYYY");
            role_detail["shared"] = item.is_shared === false ? "N/A" : "SHARED";
            role_detail["divided"] = item.is_divided === false ? "N/A" : "DIVIDED";
            role_detail["is_currently_na"] = item.is_currently_na;
            role_detail["context_visible"] = false;
            //console.log("ACTIVE TAB DATA SINGLE : ",role_detail);
            active_tab_data.push(role_detail);
            //break;
            //console.log("ROLE ",item.role_id);
            
        }
        
		//sorting

		active_tab_data.sort((a, b) => {
			let a1 = a.role_id.split("#");
			let b1 = b.role_id.split("#");
			return a1[a1.length - 1] - b1[b1.length - 1];
		})
        return active_tab_data
		
        //console.log("ACTIVE TAB DATA",active_tab_data);
        // this.setState({active_tab_data, active_tab_backup_data : active_tab_data, roles_assignment_data : result.roles});
    }

    changeTab = async (id) => {
        let role_type_arr_new = [];
		let active_role_type_name = "";
        let active_role_type_help_text = "";
        let role_type_arr = JSON.parse(JSON.stringify(this.state.role_types_arr));
        role_type_arr.forEach((item) => {
			item.selected = false;
			if (item.id === id) {
				item.selected = true;
				active_role_type_name = item.role_type;
                active_role_type_help_text = item.help_text;
			}
			role_type_arr_new.push(item);
		})
        this.active_tab_role_type_id = id;
        this.setState({role_types_arr: role_type_arr_new, active_role_type_name: active_role_type_name, active_role_type_help_text, active_tab_role_type_id : id})
        let roles = await this.fetchActiveTabData(id);
        let active_tab_data = []
        if (roles.length !== 0) {
            active_tab_data = await this.prepareActiveTabData(roles);
        }
        this.setState({active_tab_data, roles});
    }

    processRolesRespoData_new = () => {
        let ret = {data: [], columns: []}
        return ret
    }

    processRolesRespoData = () => {
		let roles = JSON.parse(JSON.stringify(this.state.active_tab_data));
        console.log("===ROles===",roles);
		let ret = { data: [], columns: [] };
		let i = 1;
		ret.columns = [
			{
				'Header': '', Cell: row => (
                    <div className='table-icon-container'><FontAwesomeIcon icon={regular('square')}/></div>
				), width: 70, headerStyle: { textAlign: 'center' }
			},

			{
				'Header': 'ROLE ID', Cell: row => (
                    <div style={{ position: 'relative', cursor: Store.getStoreData('role') === 'admin_manager' ? 'pointer' : 'auto' }} title={row.original.title} onClick={this.showRoleEditModal(row.original.id)}>
						{row.original.role_id}
					</div>
				), width: 80, headerStyle: { textAlign: 'center' }
			},

			{ Header: 'TITLE', accessor: 'title', minWidth: 50, headerStyle: { textAlign: 'left' } },
			{
				'Header': 'DESCRIPTION', Cell: row => (
					<div style={{ position: 'relative', cursor: 'pointer', textOverflow : "ellipsis", overflow : "clip" }} title={row.original.description}>
						{row.original.description}
					</div>
				), minWidth: 100, headerStyle: { textAlign: 'left' }
			},
			//{Header: 'DESCRIPTION', accessor: 'description', minWidth: 80, headerStyle: {textAlign: 'left'}},
			{ Header: 'STATUS', accessor: 'status', minWidth: 30, headerStyle: { textAlign: 'left' } },
			{ Header: 'ASSIGNED TO', accessor: 'assigned_to', minWidth: 50, headerStyle: { textAlign: 'left' } },
			{ Header: 'DATE', accessor: 'date', minWidth: 30, headerStyle: { textAlign: 'left' } },
			{ Header: 'SHARING', accessor: 'is_shared', minWidth: 50, headerStyle: { textAlign: 'left' } },
			{ Header: 'DIVIDED', accessor: 'is_divided', minWidth: 50, headerStyle: { textAlign: 'left' } },
			{
				'Header': '', Cell: row => (
                    <div style={{ textAlign : "center"}}>
                        { Store.getStoreData('role') === 'admin_manager' && <FontAwesomeIcon className="table-ellipsis" icon={regular('ellipsis-vertical')} onClick={this.toggleContextMenu(row.original.id)}/>}
                        {
                            (() => {
                                if(row.original.context_visible === true) return(
                                    <div className="table-context-menu" onMouseLeave={this.closeContextMenu(row.original.id)}>
                                        <div className="table-context-menu-item" onClick={this.showRoleEditModal(row.original.id)}>Edit</div>
                                    </div>
                                )
                            })()
                        }
                    </div>
				), width: 40, headerStyle: { textAlign: 'center' }
			}];

		//roles = roles.sort(this.customSort);

		if (roles.length !== 0) {
            for (let k = 0; k < roles.length; k++) {
                let elem = {
                    'index': k,
                    'role_id': roles[k].role_id,
                    'title': roles[k].title,
                    'description': roles[k].description,
                    'status': roles[k].is_currently_na ? 'N/A' : roles[k].status,
                    'assigned_to': roles[k].assigned_to === "Multiple" ? <span onClick={(e) => this.showMultiple(e, roles[k].id)} style={{ color: "#24B3AD", fontWeight: "700", cursor: 'pointer' }}>Multiple</span> : roles[k].assigned_to,
                    'date': roles[k].date,
                    'is_shared': roles[k].shared,
                    'is_divided': roles[k].divided,
                    'id': roles[k].id,
                    'context_visible': roles[k].context_visible 
                };
                ret.data.push(elem);
            }
        }
		return ret;
    }

    toggleContextMenu = (role_id) => (event) => {
        event.preventDefault();
        console.log("toggleContextMenu", role_id);
        let roles = JSON.parse(JSON.stringify(this.state.active_tab_data));
        //console.log("ROLES : ",roles);
        for(let i=0; i<roles.length; i++){
            if(roles[i].id === parseInt(role_id)) {
                // roles[i].context_visible = roles[i].context_visible === true ? false : true;
                roles[i].context_visible = true;
                // break;
            }else{
                roles[i].context_visible = false;
            }
        }
        console.log("ROLES : ",roles);
        this.setState({active_tab_data : JSON.parse(JSON.stringify(roles))})
    }

    closeContextMenu = (role_id) => (event) => {
        event.preventDefault()
        // console.log("closeContextMenu", role_id)
        let roles = JSON.parse(JSON.stringify(this.state.active_tab_data));
        for(let i=0; i < roles.length; i++){
            if(roles[i].id === parseInt(role_id)){
                roles[i].context_visible = false;
                break;
            }
        }
        this.setState({ active_tab_data : roles })
    }

    toggleHeaderContextMenu = (e) => {
        e.preventDefault();
        let header_context_visible = this.state.header_context_visible === true ? false : true;
        this.setState({ header_context_visible });
    }

    closeHeaderContextMenu = (e) => {
        e.preventDefault();
        this.setState({ header_context_visible : false });
    }

    showRoleEditModal = (role_id) => (event) => {
        event.preventDefault();
        if(Store.getStoreData('role') !== 'admin_manager') return;
		if (role_id != 0) {
			this.setState({ show_add_role_modal: true, edit_mode: 'edit', is_clicked_multiple: false, edit_role_id : role_id });
		}
		else if(role_id === 0){
			this.setState({ show_add_role_modal : true, edit_mode: 'new', is_clicked_multiple: false, edit_role_id : role_id})
		}
    } 

    closeModal = () => {
		this.setState({ show_add_role_modal : false })
        this.active_tab_role_type_id = this.state.active_tab_role_type_id;
        this.initialiseConfiguration();
	}

    showMultiple = (e, role_id) => {
		e.preventDefault();
		let role_content = {};
		if (role_id != 0) {
			//this.state.roles_assignment_data.forEach((item) => {
			Store.getStoreData('role_list').forEach((item) =>{
				if (item.id == role_id) {
					role_content = item;
				}
			})
			console.log('showRoleEditModal content==>', role_content);
			this.setState({ show_add_role_modal: true, edit_mode: 'edit', role_content: role_content, is_clicked_multiple: true, edit_role_id: role_id });
		}
	}
    
    saveRole = (role_info) => {
		const api = new APICall();
		let postData = {};
		if(role_info.role_id===0 || role_info.status === 'insert'){
			postData = { command: "create_new_role", role_info : role_info };
		}
		else{
			postData = { command: "update_role", role_info : role_info };
		}
		console.log('modifyRoleProcess ==> postData ==>', postData);
		//return;
		api.command(postData, this.afterSaveRole);
	}

    afterSaveRole = (result) => {
		if("newly_added_role_id" in result.result) {
			Store.updateStore("newly_added_role_id", result.result.newly_added_role_id);
			this.setState({ activate_assignment_section : true });
		}
		console.log(result);
        let alert_param = {
            title: 'Alert', message: 'Role detail saved successfully', ok_text: 'Ok', confirm: false,
            alertHandler: this.alertHandler, stack: {}
        }
        //alert("Role Details Saved");
        this.setState({ show_add_role_modal : false, alert_param });
        this.initialiseConfiguration();
	}

    alertHandler = () =>{
        this.setState({ alert_param : null});
    }

    showHideNaRoles = async (event) => {
        event.preventDefault();
        let show_na_text = this.state.show_na_text === "Showing N/A Roles" ? "Hiding N/A Roles" : "Showing N/A Roles";
        this.setState({ show_na_text });
        let roles = JSON.parse(JSON.stringify(this.state.roles));
        let active_tab_data = []
        if (roles.length !== 0) {
            active_tab_data = await this.prepareActiveTabData(roles,show_na_text)
        }
        this.setState({active_tab_data});
    }

    prepareExportData = async () => {
        let export_data = [];
        let roles = JSON.parse(JSON.stringify(this.state.roles));
        console.log("Roles Export : ", roles);
        for(let role of roles){
            let status = role.assigned_to.length > 0 ? "Assigned" : "Not Assigned";
            let assigned_to_names = [];
            if(role.assigned_to.length > 0){
                const list_contacts= Store.getStoreData('listcontacts')
                for(let id of role.assigned_to){
                    let u_name = "Inactive User"
                    let contact = Store.getStoreData('listcontacts').find(con => parseInt(con.ID) === id)
                    if(contact !== undefined){
                        u_name = contact.ContactName;
                    }
                    assigned_to_names.push(u_name);
                }
            }
            let data= {
                "Role ID" : role.role_code, //role.role_code.replace(/#/g,"-"), 
                Title : role.title, 
                Description: role.desc, 
                Status: status, 
                "Assigned to": assigned_to_names.length > 0 ? assigned_to_names.join(", ") : "None", 
                Date : moment(role.created_at).format("DD/MM/YYYY"),
                Sharing : role.is_shared === true ? "Shared": "N/A",
                Divided : role.is_divided === true ? "Divided" : "N/A"
            }
            console.log("Data  Export ", data);
            export_data.push(data);
        }
        return export_data;
    }

    // exportRolesRespoTable = (event) =>{
    //     event.preventDefault();
    //     let headerCSV = {
    //         role_code : "Role ID", 
    //         title : "Title", 
    //         description: "Description", 
    //         status: "Status", 
    //         assigned_to: "Assigned to", 
    //         created_date: "Date",
    //         sharing : "Sharing",
    //         divided : "Divided"
    //     };
    //     let dataCSV = [];
    //     let roles = JSON.parse(JSON.stringify(this.state.roles));
    //     console.log("Roles Export : ", roles);
    //     for(let role of roles){
    //         let status = role.assigned_to.length > 0 ? "Assigned" : "Not Assigned";
    //         let assigned_to_names = [];
    //         if(role.assigned_to.length > 0){
    //             const list_contacts= Store.getStoreData('listcontacts')
    //             for(let id of role.assigned_to){
    //                 let u_name = "Inactive User"
    //                 let contact = Store.getStoreData('listcontacts').find(con => parseInt(con.ID) === id)
    //                 if(contact !== undefined){
    //                     u_name = contact.ContactName;
    //                 }
    //                 assigned_to_names.push(u_name);
    //             }
    //         }
    //         let data= {
    //             role_code : role.role_code.replace(/#/g,"-"), 
    //             title : role.title, 
    //             description: role.desc, 
    //             status: status, 
    //             assigned_to: assigned_to_names.length > 0 ? assigned_to_names.join() : "None", 
    //             created_date: moment(role.created_at).format("DD/MM/YYYY"),
    //             sharing : role.is_shared === true ? "Shared": "N/A",
    //             divided : role.is_divided === true ? "Divided" : "N/A"
    //         }
    //         console.log("Data  Export ", data);
    //         dataCSV.push(data);
    //     }
    //     console.log("Headers : ", headerCSV, "Data : ", dataCSV);
    //     this.exportToCSV(headerCSV, dataCSV);
    // }

    exportToExcel = async (event) => {
        event.preventDefault();
        let wb = { SheetNames: [], Sheets: {} };
        let ex_data = await this.prepareExportData();
        wb.SheetNames.push("Roles");
        wb.Sheets["Roles"] = XLSX.utils.json_to_sheet(ex_data);
        const format = "DD-MM-YYYY HH:mm:ss";
        let tm = moment().format(format);
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "Roles and Resoponsibility" + "-" + tm + fileExtension);
    };

    // exportToCSV = (headerCSV, dataCSV) => {
    //     //event.preventDefault();
    //     //let tableData = this.processDataTable();
    //     // if (Store.getStoreData("role") === "team") {
    //     //   delete headerCSV.requester;
    //     // }
    //     let data= dataCSV;
    //     let csv_data_arr = [];
    //     csv_data_arr.unshift(headerCSV);
    //     for(let k = 0; k < data.length; k++) {
    //       console.log("Data in Export : ", data[k]);
    //       let cont = {};
    //       Object.entries(headerCSV).forEach(([key, value]) => {
    //         if(data[k][key] !== null) {
    //           console.log("Data key", data[k][key]);
    //           cont[key] = data[k][key].toString().replace(/,/g, '  ').replace(/-/g, '-');
    //         }
    //       })            
    //       csv_data_arr.push(cont);
    //     }
    //     console.log("csv_data_arr",csv_data_arr) 
    //     var csv_data_final_arr = [];
    //     for(let j = 0; j<  csv_data_arr.length; j++){
    //       var container = [];
    //       container = Object.values(csv_data_arr[j])
    //       if(container.length > 0); 
    //       csv_data_final_arr.push(container)
    //     }
    //     let csvContent = "data:text/csv;charset=utf-8," + csv_data_final_arr.map(e => e.join(",")).join("\n");
    //     var encodedUri = encodeURI(csvContent);
    //     var link = document.createElement("a");
    //     link.setAttribute("href", encodedUri);
    //     link.setAttribute("download", "Roles and  Responsibility.csv");
    //     document.body.appendChild(link); 
    //     link.click();
    // }
    
    render () {
        if (!this.state.ready) {
			return <CSLLoader />
		}
        console.log("active_tab_data:", this.state)
        console.log("Table IDs : ", Store.getStoreData("roles_respo_table_current_record_ids"));
        //console.log("REACT_APP_NO_END_DATE 1",process.env.REACT_APP_NO_END_DATE);
        //console.log("NO_END_DATE 2",process.env.NO_END_DATE);
        document.cookie = `activeTab=${this.state.active_tab_role_type_id}`;
        let role = Store.getStoreData('role');
        return (
            <div className="index-container">
                <DefaultHeader pageName="Roles and Responsibility" />
                <WelcomeBanner welcome={`Welcome back, ` + Store.getStoreData('contact').ContactName} />
                <div className="topbar">
                    {
                        (()=>{
                            if(Store.getStoreData('role') === 'admin_manager')
                                return(<button className="add-button" style={{ marginLeft: "5px", fontSize: "10px", width: "126px" }} onClick={this.showRoleEditModal(0)}>ADD NEW RECORD</button>)
                            else
                                return(<button className="add-button-inactive" style={{ marginLeft: "5px", fontSize: "10px", width: "126px" }}>ADD NEW RECORD</button>)
                        })()
                    }
                </div>
                <div className="base-title">
                    Accountability. You can delegate tasks but not responsibility. It's important that the people who input into tasks for which you're responsible have the time and
                    competence to discharge their responsibilities. You should make it clear who is responsible for what and how roles and responsibilities knit together.
                </div>
                <HorizontalTabBar roleTypes={this.state.role_types_arr} changeTab={this.changeTab} />
                <div className="table-header-container">
                    <div className="table-header-left-pane">
                        <div className="table-header-role-title">{this.state.active_role_type_name}</div>
                        <div className="table-header-msg-section">
                            <span>{this.state.active_role_type_help_text}</span>
                        </div>
                    </div>
                    <div className="table-header-right-pane">
                        <div className="show-na" onClick={this.showHideNaRoles}>{this.state.show_na_text}</div>
                        <div className="table-header-ellipsis-container">
                            <FontAwesomeIcon className="table-ellipsis" icon={regular('ellipsis-vertical')} onClick={this.toggleHeaderContextMenu}/>
                            {
                                (() => {
                                    if(this.state.header_context_visible === true) return(
                                        <div className="table-header-context-menu" onMouseLeave={this.closeHeaderContextMenu}>
                                            {role === 'admin_manager' && <div className="table-context-menu-item" onClick={this.exportToExcel}>Export</div>}
                                            <div className="table-context-menu-item">Statement of Responsibilities Report</div>
                                        </div>
                                    )
                                })()
                            }
                        </div>
                    </div>
                </div>
                <div className="table-container"><CSLTable processData={this.processRolesRespoData} from={"roles_respo"}/></div>
                {
                    (() => {
                        if (this.state.show_add_role_modal === true && this.state.is_clicked_multiple == false && this.state.edit_mode === 'edit') {
                            return (
                                <div className="interactive-overlay-rr">
                                    <CreateUpdateRoles closeModal={this.closeModal} listContacts={this.state.employees} mode={this.state.edit_mode} edit_role_id={this.state.edit_role_id} role_list ={this.state.role_list} role_type_list = {this.state.role_type_list} saveRole={this.saveRole} activate_assignment_section={true}/>
                                </div>
                                );
                        } else if (this.state.show_add_role_modal === true && this.state.is_clicked_multiple == true && this.state.edit_mode === 'edit') {
                            return (
                                <div className="interactive-overlay-rr">
                                    <CreateUpdateRoles closeModal={this.closeModal} listContacts={this.state.employees} mode={this.state.edit_mode} show_section={"assignment"} edit_role_id={this.state.edit_role_id} role_list ={this.state.role_list} role_type_list = {this.state.role_type_list} activate_assignment_section={true}/>
                                </div>
                                );
                        } else if (this.state.show_add_role_modal === true && this.state.is_clicked_multiple === false && this.state.edit_mode === "new") {
                            return (
                                
                                <div className="interactive-overlay-rr">
                                    <CreateUpdateRoles closeModal={this.closeModal}  listContacts={this.state.employees} mode={this.state.edit_mode} role_list={this.state.role_list} role_type_list={this.state.role_type_list} saveRole={this.saveRole} edit_role_id={this.state.edit_role_id} activate_assignment_section={this.state.activate_assignment_section}/>
                                </div>
                                );
                        }
                    })()
                }
                <AlertBox alertParam={this.state.alert_param} />
            </div>
        )
    }
}

export default RolesAndResponsibility