import React from "react";
import CertSetupField from "./Partials/CertSetup/CertSetupField";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FieldStyleMapping } from "../Certifications/Pdf_Font_n_Icons/FontMapping";
import Store from "../../Common/Store";
import logo_base_64 from './../Certifications/Pdf_Font_n_Icons/MccLogoNew';
import YesNoBox from "../Common/YesNoBox";
import APICall from '../../Common/APICall.js';
import SmallSpinner from "../Common/SmallSpinner";
const MAX_FILE_SIZE = 512000;

class CertSetup extends React.Component{

    
    state = { field_styles : {}, uploaded_logo : null, logo_position : null, ready : false, alertParam : null}
    
    constructor(props) {
        super(props);
        this.fileRef = React.createRef();
        this.lastlogo = ""    
    }

    async componentDidMount(){
        let api = new APICall();
        let postData = {command: "get_assess_cert_setup"}
		let result = await api.commandWithoutCallback(postData)
        console.log("api res : ", result);
        let field_styles = null;
        if(Object.keys(result.result.field_styles).length > 1) field_styles = result.result.field_styles;
        else field_styles = FieldStyleMapping;
        let uploaded_logo = field_styles.logo.value === 'default' && this.state.uploaded_logo === null ? logo_base_64 : this.state.uploaded_logo !== null ? uploaded_logo : field_styles.logo.value;
        this.lastlogo = uploaded_logo;
        let logo_position = field_styles.logo.format;
        this.setState({field_styles , ready : true, uploaded_logo, logo_position});
    }

    getFieldStyle = (field, field_style) => {
        console.log("Field & Field Style", field, field_style);
        let field_styles = JSON.parse(JSON.stringify(this.state.field_styles));
        field_styles[field] = field_style;
        this.setState({ field_styles});
    }

    setShow = (field) => (e) => {
        e.preventDefault();
        let field_styles = JSON.parse(JSON.stringify(this.state.field_styles));
        field_styles[field] = {...field_styles[field], show : field_styles[field].show ? false : true}
        this.setState({ field_styles});
    }

    changeLogoPosition = (position) => (e) => {
        e.preventDefault();
        this.setState({logo_position : position});
    }

    handleUploadClick = (e) => {
        e.preventDefault();
        if(this.fileRef.current){
            this.fileRef.current.click();
        }
    }

    handleBrowse = (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        let file_size = file.size;
        if( file_size > MAX_FILE_SIZE){
            console.log("Large File");
            let alertParam = {
                "header" : "Alert",
                "subheader" : "File too large",
                "msg" : "Please upload file within 500kB",
                "closefunc" : this.closeYesNoBox,
                "buttons" : {
                    "OK" : {
                        "caption" : "OK",
                        "bgcolor" : "#24B3BF",
                        "color" : "#fff",
                        "callback" : this.closeYesNoBox
                    }
                }
            }
            this.setState({ alertParam })
            return;
        }

        const data = new FileReader();
    
        data.addEventListener('load', () => {
            const uploaded_logo = data.result;
            this.setState({ uploaded_logo });
        });
    
        data.readAsDataURL(file);
    }

    closeYesNoBox = () => {
        this.setState({alertParam : null});
    }

    closePage = (event) => {
		//event.preventDefault()
		let url = "/"
		window.location.replace(url);
	}

    updateCertSetup = async (event) => {
        event.preventDefault()
        let api = new APICall()
        let field_styles = JSON.parse(JSON.stringify(this.state.field_styles));
        if( field_styles.record_status === 'new'){
            if (this.state.uploaded_logo !== this.lastlogo){
                field_styles.logo.value = this.state.uploaded_logo;
            }
            field_styles.logo.format = this.state.logo_position; 
        }else if( field_styles.record_status === 'old'){
            if (this.state.uploaded_logo !== this.lastlogo){ 
                field_styles.logo.value = this.state.uploaded_logo;
            }
            else
                delete field_styles.logo.value;
            field_styles.logo.format = this.state.logo_position;
        }
		let postData = {command: "set_assess_cert_setup", cert_setup : field_styles}
		let result = await api.commandWithoutCallback(postData)
		console.log(result)
        if(result.error_code === 0){
            let alertParam = {
                "header" : "Saved",
                "subheader" : "",
                "msg" : "Certificate set-up saved successfully",
                "closefunc" : this.closeYesNoBox,
                "buttons" : {
                    "OK" : {
                        "caption" : "OK",
                        "bgcolor" : "#24B3BF",
                        "color" : "#fff",
                        "callback" : this.closeYesNoBox
                    }
                }
            }
            this.setState({ alertParam })
        }
		// let general = JSON.parse(JSON.stringify(this.state.general))
		// this.setState({orig_general: general})
    }

    render(){
        console.log("===>state : ", this.state);
        if(!this.state.ready)  {
			// return <div><CSLLoader /></div>
			return <div className='mc-general-empty-container'><SmallSpinner /></div>
		}
        return(
            <div className="mc-general-container">
                <div style={{fontSize : "15px", fontWeight : "600", marginTop : "15px"}}><span className='mc-general-label-header'>Certificate set-up</span></div>
                <div>Customise you Assessment Certificates by updating the sections below with your company logo and choices of text font, colour and size</div>
                <div className="mc-cert-setup-container">
                    <div className="mc-cert-setup-label-container">
                        <div className="mc-cert-setup-label"><span className="mc-cert-setup-label-main">Logo</span></div>
                    </div>
                    <div className="mc-cert-setup-logo-selection-container">
                        <div className="mc-cert-setup-logo-selection-option-container">
                            <div className="mc-cert-setup-logo-selection-option">
                                {
                                    (()=>{
                                        if(this.state.logo_position === 'left') return <FontAwesomeIcon icon={solid('circle')} className="mc-cert-setup-radio" />
                                        else return <FontAwesomeIcon icon={regular('circle')} className="mc-cert-setup-radio" onClick={this.changeLogoPosition('left')} />
                                    })()
                                }
                                <div>Left</div>
                            </div>
                            <div className="mc-cert-setup-logo-selection-option">
                                {
                                    (()=>{
                                        if(this.state.logo_position === 'middle') return <FontAwesomeIcon icon={solid('circle')} className="mc-cert-setup-radio" />
                                        else return <FontAwesomeIcon icon={regular('circle')} className="mc-cert-setup-radio" onClick={this.changeLogoPosition('middle')} />
                                    })()
                                }
                                <div>Middle (Default)</div>
                            </div>
                            <div className="mc-cert-setup-logo-selection-option">
                                {
                                    (()=>{
                                        if(this.state.logo_position === 'right') return <FontAwesomeIcon icon={solid('circle')} className="mc-cert-setup-radio" />
                                        else return <FontAwesomeIcon icon={regular('circle')} className="mc-cert-setup-radio" onClick={this.changeLogoPosition('right')} />
                                    })()
                                }
                                <div>Right</div>
                            </div>
                        </div>
                        <input type="file" id="logo-input" style={{display:"none"}} accept=".jpg,.png" onChange={this.handleBrowse}/>
                        <label className="mc-cert-setup-upload" htmlFor="logo-input">Upload Logo</label>
                        <div className="mc-cert-setup-logo-container" style={{backgroundImage : `url(${this.state.uploaded_logo})`}}/>
                    </div>
                    <div className="mc-cert-setup-label-container">
                        <div className="mc-cert-setup-label"><span className="mc-cert-setup-label-main">Certificate - </span>change the text and appearance of the Certificate Title</div>
                    </div>
                    <div className="mc-cert-setup-field-container">
                        <CertSetupField field="certificate" field_style={this.state.field_styles.certificate} getFieldStyle={this.getFieldStyle}/>
                    </div>
                    <div className="mc-cert-setup-label-container">
                        <div className="mc-cert-setup-label"><span className="mc-cert-setup-label-main">Assessment Name - </span>change the appearance of the Assessment Name</div>
                    </div>
                    <div className="mc-cert-setup-field-container">
                        <CertSetupField field="assessment_name" field_style={this.state.field_styles.assessment_name} getFieldStyle={this.getFieldStyle}/>
                    </div>
                    <div className="mc-cert-setup-label-container">
                        <div className="mc-cert-setup-label"><span className="mc-cert-setup-label-main">Employee Name - </span>change the appearance of the Employee's Name</div>
                    </div>
                    <div className="mc-cert-setup-field-container">
                        <CertSetupField field="emp_name" field_style={this.state.field_styles.emp_name} getFieldStyle={this.getFieldStyle}/>
                    </div>
                    <div className="mc-cert-setup-label-container">
                        <div className="mc-cert-setup-label"><span className="mc-cert-setup-label-main">Job Title - </span>change the appearance of the Employee's Job Title</div>
                    </div>
                    <div className="mc-cert-setup-field-container">
                        <CertSetupField field="job_title" field_style={this.state.field_styles.job_title} getFieldStyle={this.getFieldStyle}/>
                    </div>
                    <div className="mc-cert-setup-label-container">
                        <div className="mc-cert-setup-label"><span className="mc-cert-setup-label-main">Declaration Text - </span>change the text and appearance of the Declaration Text</div>
                    </div>
                    <div className="mc-cert-setup-field-container">
                        <CertSetupField field="dec_text" field_style={this.state.field_styles.dec_text} getFieldStyle={this.getFieldStyle}/>
                    </div>
                    <div className="mc-cert-setup-label-container-flex">
                        <div className="mc-cert-setup-label"><span className="mc-cert-setup-label-main">Role ID's - </span>change the appearance of the Role ID's</div>
                        <div className="mc-cert-setup-label">
                            Do not show Role IDs 
                            { this.state.field_styles.role_id.show === true && <FontAwesomeIcon icon={regular('square')} className="mc-cert-setup-icon" onClick={this.setShow('role_id')}/>}
                            { this.state.field_styles.role_id.show === false && <FontAwesomeIcon icon={regular('square-check')} className="mc-cert-setup-icon" onClick={this.setShow('role_id')}/>}
                        </div>
                    </div>
                    {
                        this.state.field_styles.role_id.show === true &&
                        <div className="mc-cert-setup-field-container">
                            <CertSetupField field="role_id" field_style={this.state.field_styles.role_id} getFieldStyle={this.getFieldStyle}/>
                        </div>
                    }
                    <div className="mc-cert-setup-label-container-flex">
                        <div className="mc-cert-setup-label"><span className="mc-cert-setup-label-main">Role Title's - </span>change the appearance of the Role Titles</div>
                        <div className="mc-cert-setup-label">
                            Do not show Role Titles 
                            { this.state.field_styles.role_title.show === true && <FontAwesomeIcon icon={regular('square')} className="mc-cert-setup-icon" onClick={this.setShow('role_title')}/>}
                            { this.state.field_styles.role_title.show === false && <FontAwesomeIcon icon={regular('square-check')} className="mc-cert-setup-icon" onClick={this.setShow('role_title')}/>}
                        </div>
                    </div>
                    {
                        this.state.field_styles.role_title.show === true &&
                        <div className="mc-cert-setup-field-container">
                            <CertSetupField field="role_title" field_style={this.state.field_styles.role_title} getFieldStyle={this.getFieldStyle}/>
                        </div>
                    }
                    <div className="mc-cert-setup-label-container">
                        <div className="mc-cert-setup-label"><span className="mc-cert-setup-label-main">Edit the text and appearance for the message when no roles were assessed</span></div>
                    </div>
                    <div className="mc-cert-setup-field-container">
                        <CertSetupField field="no_role" field_style={this.state.field_styles.no_role} getFieldStyle={this.getFieldStyle}/>
                    </div>
                    <div className="mc-cert-setup-label-container">
                        <div className="mc-cert-setup-label"><span className="mc-cert-setup-label-main">Assessor Name - </span>change the appearance of the Assessor's name</div>
                    </div>
                    <div className="mc-cert-setup-field-container">
                        <CertSetupField field="assessor_name" field_style={this.state.field_styles.assessor_name} getFieldStyle={this.getFieldStyle}/>
                    </div>
                    <div className="mc-cert-setup-label-container">
                        <div className="mc-cert-setup-label"><span className="mc-cert-setup-label-main">Assessment Date - </span>change the appearance of the Assessment Date</div>
                    </div>
                    <div className="mc-cert-setup-field-container">
                        <CertSetupField field="assessment_date" field_style={this.state.field_styles.assessment_date} getFieldStyle={this.getFieldStyle}/>
                    </div>
                    <div className="mc-cert-setup-label-container">
                        <div className="mc-cert-setup-label"><span className="mc-cert-setup-label-main">"Certified By" Label - </span>change the text and appearance</div>
                    </div>
                    <div className="mc-cert-setup-field-container">
                        <CertSetupField field="cert_by" field_style={this.state.field_styles.cert_by} getFieldStyle={this.getFieldStyle}/>
                    </div>
                    <div className="mc-cert-setup-label-container">
                        <div className="mc-cert-setup-label"><span className="mc-cert-setup-label-main">"Date" Label - </span>change the text and appearance</div>
                    </div>
                    <div className="mc-cert-setup-field-container">
                        <CertSetupField field="date" field_style={this.state.field_styles.date} getFieldStyle={this.getFieldStyle}/>
                    </div>
                </div>
                <div className='mc-general-footer'>
                    <button className='mc-general-cancel-button' onClick={this.closePage}>Cancel</button>
                    <button className='mc-general-save-button' onClick={this.updateCertSetup}>Save</button>
                </div>
                { this.state.alertParam !== null && <YesNoBox alertParam={this.state.alertParam} />}
            </div>
        )
    }
}

export default CertSetup;