import React from 'react';
import styled from 'styled-components';
import Store from './../../../Common/Store';
import CSLCheckBox from './../../Common/CSLCheckBox';
import DatePicker from 'react-date-picker';
import { FaTimes,FaAngleDown,FaAngleUp } from 'react-icons/fa';
import Cookies from 'universal-cookie'
// import "./../../../App.css";
import './../../Personnel/EditEmployee.css'
import moment from 'moment';


const SelectedRadio = styled.div`
    background-color: #04ADA8;
    border: 1px solid #1f615e;
    padding: 5px 10px;
    color: #ffffff;
    display: inline-block;
    cursor: pointer;
`;
const UnselectedRadio = styled.div`
    background-color: #F4F4F4;
    border: 1px solid #8c8c8c;
    padding: 5px 10px;
    color: #262626;
    display: inline-block;
    cursor: pointer;
`;
const RadioBlock = styled.div`
    margin-top: 10px;
    padding: 2%;
`;

const CSLDateCover = styled.div`
    margin-top: 1%;
    margin-bottom: 2%;
    width: 50%;
    border-radius: 3px;
    height: 34px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0 0 4px #c4c4c4;
    font-weight: 200;
    `;

const BlankRAdiv = styled.div`
    font-size: 11px;
    width: 100%;
    box-sizing: border-box;
    padding: 30px 10px;
    font-style: italic;
`;

const TabItemContainer = styled.div`
    padding: 2% 2%;
    box-sizing: border-box;
    display: flex;
`;

const DropDownContainer = styled.div`
    padding: 2% 2%;
    border-bottom: 1px solid #bfbbbb ;
    
`;
const SMRTDes = styled.div`
    padding: 2% 2%;
`;



const TabItemText = styled.div`
    float: left;
    font-weight: 600;
    color: #213E61;
    flex: 1;
    min-width: 0; 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const ModalBodySecondaryHeader = styled.div`
    display: block;
    color: #213E61;
    font-weight: bold;
    padding: 2%;
    margin-top: 2%;
    flex: 1;
    
`;

const TabItemExpander = styled.div`
    float: right;
    cursor: pointer;
`;

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    width: 400px;
    right: 20px;
    margin-top: 36px;
    z-index: 30000;
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px 20px 10px 20px;
    border-bottom: 1px solid #9c9696;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
	color: #000000;
    font-weight: bold;
    font-size : 14px;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #a0a0a0;
    cursor: pointer;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    height: 600px;
    overflow-y: scroll;
`;
const MRModalSummary = styled.div`
    display: block;
    box-sizing: border-box;
    width: 98%;
    background-color: #EDF1F1;
    padding: 20px;
    border: 1px solid #EBEBEB;
    border-radius: 5px;
    margin:10px;

`;
const DatePickerWrapper = styled.div`
    border: none;
`;
const MRDes = styled.div`
    display: block;
    padding: 2%;
    // font-weight: 100;
    font-style: italic;
    color: grey;
    flex:1;

`;
const CheckBoxContainer = styled.div`
    fontSize: 12px;
    display: block;
    marginLeft: 2%;
    marginTop: 2%;
    margin-bottom: 2%;
    font-weight: 200;
    width: 100%;
`;
const MRModalFooter = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    background-color: #EDF1F1;
    padding: 20px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
`;
const MRModalLabel = styled.label`
	color: #000000;
    font-weight: 200;
    margin-top: 20px;
    margin-left: 2%;
`;

const CSLSpacer = styled.div`
    clear: both;
    min-height: 5px;
`
const CSLHorzSpacer = styled.div`
    float: left;
    width: 15px;
`

Date.prototype.withoutTime = function () {
    var d = new Date(this);
    d.setHours(0, 0, 0, 0);
    return d;
}



class SideBar extends React.Component
{
	state = {  
        ready : true ,
        general: null,
        Show_RespoMap: null,
        sdtable: false,
        amtable: false,
        amtable_na: false,
        sdsmprtable: false,
        amsmpr: false,
        nasmf: false,
        nasmpr: false,
        uasmf: false,
        uasmpr: false,
        smrtable: false,
        smrtable_reg_type: false,
        Show_SMRContainer:null,
        Show_ResContainer: null,
        Show_AMContainer: null,
        Show_LEContainer:null,
        Show_ComContainer: null,
        Show_Sidebar:null,
        reportStartDate: new Date(),
        respomap: true,
        respomap_show_gov: true,
        respomap_show_roledesc: false,
        respomap_show_jobdesc: false,
        respomap_all_regimes: true,
        letable: false,
        letable_com_gov: false,
        com_gov: true,
        com_gov_map: true,
        com_gov_reg:false,
        com_gov_tab: true,
        sidebar:true
	}

	constructor(props) {
		super(props);
	}
	componentDidMount = () => {
        let thisState = Store.getStoreData('sidebar_state')
        console.log("in componentDidMount",thisState)
        console.log("new Date==>",new Date())
        window.addEventListener('beforeunload', this.saveCookie);
        // if(thisState !== null){
        //     thisState['reportStartDate'] = new Date(thisState.reportStartDate)
        //     thisState['pdf'] = false
        //     Store.updateStore('sidebar_state', thisState)
        //     this.setState(thisState)
        // }else {
            const cookies = new Cookies();
            let newState = cookies.get('sidebar_cookie')
            console.log("sidebar_cookie",newState);
            // if (newState !== this.state & typeof newState !== "undefined") {
            //     newState['reportStartDate'] = new Date(newState.reportStartDate)
            //     console.log("convert",newState.reportStartDate)
            //     newState['sidebar'] = true
            //     newState['pdf'] = false
            //     Store.updateStore('sidebar_state', newState)
            //     this.setState(newState)    
            //     this.props.updateDate({reportStartDate: newState.reportStartDate});
            // }else{
                this.setState({Show_ComContainer: true, Show_Sidebar:true,reportStartDate: new Date()});
            // }
        // }
    }
    componentDidUpdate(prevProps) {
        console.log("in componentDidUpdate 1")
        if (prevProps !== this.props) {
            
            let thisState = Store.getStoreData('sidebar_state');
            console.log("in componentDidUpdate 2",thisState)
            if(thisState !== null ){
                console.log("inside did update")
                thisState['reportStartDate'] = new Date(thisState.reportStartDate)
                thisState['pdf'] = false
                Store.updateStore('sidebar_state', thisState)
                this.setState(thisState)
                
            }
            else {
                console.log("inside did update else")

                this.setState({Show_Sidebar:true});}

        }
    }
    componentWillUnmount(){    
      window.removeEventListener('beforeunload', this.saveCookie);
    }

    saveCookie = (event) => {
        const cookies = new Cookies();
        cookies.set('sidebar_cookie', this.state, { path: '/' });
        Store.updateStore('sidebar_state', this.state)
    }

    toggleCommon = (key) => (event) => {
        event.preventDefault();
        let general = this.state;
        console.log('toggleCommon',general)
        general[key] = general[key] ? false : true;
        Store.updateStore('sidebar_state', general)
        this.setState(general);

        this.props.updateGeneral(general);
    }   
    
    // onScheduleDateChange = (date)  => {
    //     //event.preventDefault()
    //     this.setState({reportStartDate: date},()=>{
    //         console.log("in Datechange sidebar")
    //         let general = this.state;
    //         general['reportStartDate'] = date
    //         Store.updateStore('sidebar_state', general)
	// 		this.props.updateDate({reportStartDate: date});
    //     });
        
    // }   

    onScheduleDateChange = (event)  => {
        event.preventDefault()
        const selectedDate = event.target.value;
        const dateObject = new Date(selectedDate);

        this.setState({reportStartDate: dateObject},()=>{
            console.log("in Datechange sidebar")
            let general = this.state;
            general['reportStartDate'] = dateObject
            Store.updateStore('sidebar_state', general)
			this.props.updateDate({reportStartDate: dateObject});
        });
        
    }   
    
    
    switchRadio = (key) => (event) => {
        event.preventDefault();
        let general = this.state;
        console.log('switchRadio',general)
        general[key] = general[key] ? false : true;
        Store.updateStore('sidebar_state', general)
        this.setState(general);

        this.props.updateGeneral(general);
    }

    CheckBoxChangeCommon = (key) => (event) => {
        event.preventDefault();
        let general = this.state;
        console.log('chekbox',general)
        general[key] = general[key] ? false : true;
        Store.updateStore('sidebar_state', general)
        this.setState(general);

        this.props.updateGeneral(general);
    }


    clickCheckBox = (result, key) => {
        let general = this.state
        if(key === 'respomap_show_roledesc_alt') {
            key = 'respomap_show_roledesc'
            result = !result
        }
        general[key] = result
        Store.updateStore('sidebar_state', general)
        this.props.updateGeneral(general);
        this.setState(general)
    }

    

    /*HandleSMRTable = (value) => (event) => {
        //event.preventDefault();
        console.log("value",value)  
        let data = value === 1 ? true : false;
        this.setState({Show_SMRTable: data});
        //this.props.changeActiveInactive(data);             
    }*/

    closeModal = () => {
        this.props.closeSidebar({close : true});

    }
   
	handleClickOutside = evt => {
        this.props.closeSidebar({close : true});
    };

	render()
	{
		if(!this.state.ready) {
            return <div>Loading...</div>
        }
        console.log('SideBar this.state', this.state)
		return (
            <MRModalContainer>
                <MRModalHeader>
                    <MRModalHeaderText>Report Parameters</MRModalHeaderText>                    
                    <MRModalLabel style={{color:"#FF0000", marginLeft:"40%"}}></MRModalLabel>
                    <MRModalHeaderCloseBtn onClick= {() => this.closeModal()} ><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}} ref={el => { this.el = el; }}></div>
                </MRModalHeader>
                <MRModalBody>
                    {/* <ModalBodySecondaryHeader >Run Report As Of</ModalBodySecondaryHeader>
                    <CSLDateCover style={{display: "inline-block",marginLeft: "10px"}}>
                        <DatePicker 
                            onChange={this.onScheduleDateChange}
                            clearIcon={null}
                            calendarIcon={true}
                            locale={"en-GB"}
                            value={this.state.reportStartDate}
                            
                            
                        />
                    </CSLDateCover> */}
                    <div className="employee-edit-container">
                        <div className="employee-edit-date-container">
                            <div className="employee-edit-date-picker" style={{marginBottom: "10px", marginLeft: "15px"}}>  
                            <ModalBodySecondaryHeader style={{marginBottom: "10px", padding: "0%"}}>Run Report As Of</ModalBodySecondaryHeader>                              
                                <input type="date" id="joinedDate" className="custom-date-input" value={this.state.reportStartDate ? moment(this.state.reportStartDate).format('YYYY-MM-DD') : ''} onChange={this.onScheduleDateChange}/>
                            </div>                            
                        </div>
                    </div>
                    <DropDownContainer>
                        <TabItemContainer  onClick={this.toggleCommon('Show_ComContainer')}>
                            <TabItemText>Committees and Governance</TabItemText>
                            <TabItemExpander onClick={this.toggleCommon('Show_ComContainer')}>
                            {
                                (() => {    
                                        if (this.state.Show_ComContainer) {
                                            return <FaAngleUp  size = {20} onClick={this.toggleCommon('Show_ComContainer')}/>
                                        }
                                        else{
                                            return <FaAngleDown  size = {20} onClick={this.toggleCommon('Show_ComContainer')}/>

                                        }   
                                })()
                            }                                                
                            </TabItemExpander>
                            <div style={{clear: "both"}}></div>
                        </TabItemContainer>
                        <div>
                        {
                            (() => {
                                if (this.state.Show_ComContainer) {
                                    return(<div>
                                                <MRDes>Enable this section to include within the overall report</MRDes>
                                                <div>
                                                    {
                                                        (() => {
                                                            if (this.state.com_gov) {
                                                                return (
                                                                    <RadioBlock>
                                                                        <SelectedRadio>Yes</SelectedRadio>
                                                                        <UnselectedRadio onClick={this.switchRadio('com_gov')}>No</UnselectedRadio>
                                                                    </RadioBlock>
                                                                );
                                                            } else {
                                                                return (
                                                                    <RadioBlock>
                                                                        <UnselectedRadio onClick={this.switchRadio('com_gov')}>Yes</UnselectedRadio>
                                                                        <SelectedRadio>No</SelectedRadio>
                                                                    </RadioBlock>
                                                                 );
                                                            }
                                                        }) ()
                                                    }
                                                </div>
                                                <div>
                                                {
                                                    (() => {
                                                        if(this.state.com_gov) {
                                                            return (<div>
                                                                    <CSLSpacer />
                                                                    <CheckBoxContainer>
                                                                    <CSLCheckBox checked={this.state.com_gov_map} 
                                                                                             label='Hierarchy Chart' 
                                                                                             checkCallback={this.clickCheckBox}
                                                                                             stack={'com_gov_map'} />
                                                                    </CheckBoxContainer>
                                                                    <CSLSpacer />
                                                                    {
                                                                        (() => {
                                                                            if(this.state.com_gov_map) {
                                                                                return (<div>
                                                                                            <CSLSpacer />
                                                                                            <CheckBoxContainer>
                                                                                            
                                                                                            <CSLCheckBox checked={this.state.com_gov_reg} 
                                                                                                                     label='Commitee Roles And Holders' 
                                                                                                                     checkCallback={this.clickCheckBox}
                                                                                                                     stack={'com_gov_reg'}
                                                                                                                     />
                                                                                            <CSLHorzSpacer />
                                                                                            </CheckBoxContainer>
                                                                                            <CSLSpacer />
                                                                                        </div>
                                                                                    )
                                                                            }
                                                                        })()
                                                                    }
                                                                    <CSLSpacer />
                                                                    <CheckBoxContainer>
                                                                    <CSLCheckBox checked={this.state.com_gov_tab} 
                                                                                             label='Boards and Committees Table' 
                                                                                             checkCallback={this.clickCheckBox}
                                                                                             stack={'com_gov_tab'} />
                                                                    </CheckBoxContainer>
                                                                    <CSLSpacer />
                                                                    </div>
                                                            )
                                                        }
                                                     })()
                                                 }
                                                </div>
                                            </div>
                                        );

                                    };
                                }) ()
                            }
                        </div>         
                    </DropDownContainer>  
                </MRModalBody>
            </MRModalContainer>  
			);
	}
}
export default SideBar;
