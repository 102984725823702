import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import APICall from "../../../Common/APICall"
import AssessmentTopics from "./AssessmentTopics"
import AssessmentRoleTypes from "./AssessmentRoleTypes.js";
import AssessmentLegalEntities from "./AssessmentLegalEntities.js";
import AssessmentConfig from "./AssessmentConfig"
import './../Modals/ModalStyle.css'

class AssessmentDetails extends React.Component {

    state = { assessment: null, selected_tab: "topics" }

    componentDidMount() {
        this.setComponentState()
    }


    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) this.setComponentState()
    }

    setComponentState = async () => {
        const payload = { command: "assessments", action: 'get_assessment_details', assessment_id: this.props.assessment_id }
        const api = new APICall()
        const result = await api.commandWithoutCallback(payload)
        if (result.error_code === 0) {
            this.setState({ assessment: result.result })
        }
    }

    setAssessmentName = async (event) => {
        event.preventDefault()
        let assessment = JSON.parse(JSON.stringify(this.state.assessment))
        assessment.name = event.currentTarget.innerHTML;
        const payload = { command: "assessments", action: 'change_assessment_name', assessment_id: this.props.assessment_id, name: assessment.name }
        console.log('payload nick', payload);
        const api = new APICall();
        const result = await api.commandWithoutCallback(payload)
        console.log('this.props', this.props)
        this.props.changeNick({ id: this.props.assessment_id, name: assessment.name });
        this.setState({ assessment })
    }

    closeView = (event) => {
        event.preventDefault()
        this.props.closeView()
    }

    toggleAssessmentStatus = async (event) => {
        event.preventDefault()
        let assessment = JSON.parse(JSON.stringify(this.state.assessment))
        assessment.is_active = assessment.is_active === 1 ? 0 : 1
        const payload = { command: "assessments", action: 'toggle_assessment_details', assessment_id: this.props.assessment_id, is_active: assessment.is_active }
        const api = new APICall()
        const result = await api.commandWithoutCallback(payload)
        this.setState({ assessment })
    }

    changeTab = (tab) => (event) => {
        event.preventDefault()
        let selected_tab = this.state.selected_tab
        if (selected_tab !== tab) selected_tab = tab
        this.setState({ selected_tab })
    }

    changeFrequency = async (event) => {
        event.preventDefault()
        let assessment = JSON.parse(JSON.stringify(this.state.assessment))
        assessment.runs_every_month = parseInt(event.target.value)
        const payload = { command: "assessments", action: 'change_runs_every_assessment_details', assessment_id: this.props.assessment_id, runs_every: assessment.runs_every_month }
        const api = new APICall()
        const result = await api.commandWithoutCallback(payload)
        this.setState({ assessment })
    }

    handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
        }
    }

    render() {
        let has_gc = JSON.parse(localStorage.getItem('has_gc'));
        return (
            <div className="mc-general-container" style={{ paddingTop: "20px", backgroundColor: "#fff", minHeight: "calc(100vh - 160px)" }}>
                {
                    this.state.assessment !== null &&
                    <>
                        <div style={{ display: "flex", paddingBottom: "15px", alignItems: "center", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div contentEditable={true} onBlur={this.setAssessmentName} onKeyDown={this.handleKeyDown} style={{ fontWeight: "600", fontSize: "16px", paddingRight: "10px", color: "#1a3552" }}>{this.state.assessment.name}</div>
                                {/* {
                                    this.state.topic_name_editing === true ?
                                    <FontAwesomeIcon className="mc-roles-add-icon" style={{fontSize: "15px"}} icon={regular('floppy-disk')} /> :
                                    <FontAwesomeIcon className="mc-roles-add-icon" style={{fontSize: "15px"}} icon={solid('pencil')} />
                                } */}
                            </div>
                            <FontAwesomeIcon size="2x" icon={solid('xmark')} style={{ cursor: "pointer" }} onClick={this.closeView} />
                        </div>
                        <div style={{ paddingBottom: "15px" }}>
                            <div style={{ fontWeight: "500", paddingBottom: "5px" }}>Active?</div>
                            {
                                this.state.assessment.is_active === 1 ?
                                    <FontAwesomeIcon className="mc-roles-add-icon" style={{ fontSize: "24px" }} icon={regular('toggle-on')} onClick={this.toggleAssessmentStatus} /> :
                                    <FontAwesomeIcon className="mc-roles-add-icon" style={{ fontSize: "24px", color: "#ababab" }} icon={regular('toggle-off')} onClick={this.toggleAssessmentStatus} />
                            }
                        </div>
                        <div style={{ marginTop: "5px", display: "flex", columnGap: "5px", alignItems: "center" }}>
                            <div style={{ fontWeight: "500", paddingBottom: "5px" }}>This Assessment will run every</div>
                            <select className="mc-dropdown-sm" style={{ width: "8%" }} value={this.state.assessment.runs_every_month} onChange={this.changeFrequency}>
                                {
                                    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(item => {
                                        return <option key={`rem${item}`} value={item}>{item === 0 ? "select" : item}</option>
                                    })
                                }
                            </select>
                            <div style={{ fontWeight: "500", paddingBottom: "5px" }}>months (select is a one-off assessment)</div>
                        </div>
                        <div style={{ display: "flex", columnGap: "25px", marginTop: "20px" }}>
                            <div className={this.state.selected_tab === "topics" ? "topic-tab-selected" : "topic-tab"} onClick={this.changeTab("topics")}>Topics</div>
                            <div className={this.state.selected_tab === "role_types" ? "topic-tab-selected" : "topic-tab"} onClick={this.changeTab("role_types")}>Role Types</div>
                            {has_gc && <div className={this.state.selected_tab === "gcs" ? "topic-tab-selected" : "topic-tab"} onClick={this.changeTab("gcs")}>Legal Entities</div>}
                            <div className={this.state.selected_tab === "config" ? "topic-tab-selected" : "topic-tab"} onClick={this.changeTab("config")}>Config</div>
                        </div>
                    </>
                }
                {this.state.selected_tab === "topics" && <AssessmentTopics assessment_id={this.props.assessment_id} />}
                {this.state.selected_tab === 'role_types' && <AssessmentRoleTypes assessment_id={this.props.assessment_id} />}
                {this.state.selected_tab === "gcs" && <AssessmentLegalEntities assessment_id={this.props.assessment_id} />}
                {this.state.selected_tab === "config" && <AssessmentConfig assessment_id={this.props.assessment_id} />}

            </div>
        )
    }
}

export default AssessmentDetails
