import React, { Component } from 'react'
import onClickOutside from 'react-onclickoutside'
import './menu.css'
import {regular} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
class Menu extends Component {
    constructor () {
        super()
        this.state = {
            open: false,
            width: '200px'
        }
    }
    handleToggle = () => {
        this.setState({ open: !this.state.open })
        // window.document.addEventListener('click', this.close, false)
    }
    close = () => {
        this.setState({ open: false })
        window.document.removeEventListener('click', this.close, false)
    }
    handleClickOutside = ev => {
        this.close()
    }
    componentWillReceiveProps(nextProps) {
        this.close()
    }
    render() {
        const status = this.state.open ? 'open' : 'close'
        return (
            <span className='menu-wrapper'>
                <a onClick={this.handleToggle}>
                    <FontAwesomeIcon className="menu-icon" icon={regular('ellipsis-vertical')} />
                </a>

                {(() => {
                    if (this.props.hasOwnProperty('width')) {
                        return (
                            <div style={{ width: `${this.props.width}px` }} className={`menu menu-${status}`}>
                                {this.props.children}
                            </div>
                        )
                    } else {
                        return (
                            <div className={`menu menu-${status}`}>
                                {this.props.children}
                            </div>
                        )
                    }
                })()}

            </span>
        )
    }
}

export default onClickOutside(Menu)
