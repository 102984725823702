export const PdfFontMapping = {
    "1" : { font_name : "Montserrat", font_style : "regular", actual_font : "Montserrat-Regular", actual_font_postfix : "normal" },
    "2" : { font_name : "Montserrat", font_style : "bold", actual_font : "Montserrat-Bold", actual_font_postfix : "bold" },
    "3" : { font_name : "Montserrat", font_style : "italic", actual_font : "Montserrat-Italic", actual_font_postfix : "italic" },
    "4" : { font_name : "Montserrat", font_style : "bolditalic", actual_font : "Montserrat-BoldItalic", actual_font_postfix : "bolditalic" },
    "5" : { font_name : "Montserrat-Medium", font_style : "regular", actual_font : "Montserrat-Medium", actual_font_postfix : "normal" },
    "6" : { font_name : "Montserrat-Medium", font_style : "bold", actual_font : "Montserrat-Bold", actual_font_postfix : "bold" },
    "7" : { font_name : "Montserrat-Medium", font_style : "italic", actual_font : "Montserrat-MediumItalic", actual_font_postfix : "italic" },
    "8" : { font_name : "Montserrat-Medium", font_style : "bolditalic", actual_font : "Montserrat-BoldItalic", actual_font_postfix : "bolditalic" },
    "9" : { font_name : "Montserrat-SemiBold", font_style : "regular", actual_font : "Montserrat-SemiBold", actual_font_postfix : "bold" },
    "10" : { font_name : "Montserrat-SemiBold", font_style : "bold", actual_font : "Montserrat-ExtraBold", actual_font_postfix : "bold" },
    "11" : { font_name : "Montserrat-SemiBold", font_style : "italic", actual_font : "Montserrat-SemiBoldItalic", actual_font_postfix : "bold" },
    "12" : { font_name : "Montserrat-SemiBold", font_style : "bolditalic", actual_font : "Montserrat-ExtraBoldItalic", actual_font_postfix : "bolditalic" },
    "13" : { font_name : "NotoSans", font_style : "regular", actual_font : "NotoSans-Regular", actual_font_postfix : "normal" },
    "14" : { font_name : "NotoSans", font_style : "bold", actual_font : "NotoSans-Bold", actual_font_postfix : "bold" },
    "15" : { font_name : "NotoSans", font_style : "italic", actual_font : "NotoSans-Italic", actual_font_postfix : "italic" },
    "16" : { font_name : "NotoSans", font_style : "bolditalic", actual_font : "NotoSans-BoldItalic", actual_font_postfix : "bolditalic" },
    "17" : { font_name : "NotoSans-Medium", font_style : "regular", actual_font : "NotoSans-Medium", actual_font_postfix : "normal" },
    "18" : { font_name : "NotoSans-Medium", font_style : "bold", actual_font : "NotoSans-Bold", actual_font_postfix : "bold" },
    "19" : { font_name : "NotoSans-Medium", font_style : "italic", actual_font : "NotoSans-MediumItalic", actual_font_postfix : "italic" },
    "20" : { font_name : "NotoSans-Medium", font_style : "bolditalic", actual_font : "NotoSans-BoldItalic", actual_font_postfix : "bolditalic" },
    "21" : { font_name : "NotoSans-SemiBold", font_style : "regular", actual_font : "NotoSans-SemiBold", actual_font_postfix : "bold" },
    "22" : { font_name : "NotoSans-SemiBold", font_style : "bold", actual_font : "NotoSans-ExtraBold", actual_font_postfix : "bold" },
    "23" : { font_name : "NotoSans-SemiBold", font_style : "italic", actual_font : "NotoSans-SemiBoldItalic", actual_font_postfix : "bold" },
    "24" : { font_name : "NotoSans-SemiBold", font_style : "bolditalic", actual_font : "NotoSans-ExtraBoldItalic", actual_font_postfix : "bolditalic" },
    "25" : { font_name : "AlexBrush", font_style : "regular", actual_font : "AlexBrush-Regular", actual_font_postfix : "normal" },
    "26" : { font_name : "Borel", font_style : "regular", actual_font : "Borel-Regular", actual_font_postfix : "normal" },
    "27" : { font_name : "OpenSans", font_style : "regular", actual_font : "OpenSans-Regular", actual_font_postfix : "normal" },
    "28" : { font_name : "OpenSans", font_style : "bold", actual_font : "OpenSans-Bold", actual_font_postfix : "bold" },
    "29" : { font_name : "OpenSans", font_style : "italic", actual_font : "OpenSans-Italic", actual_font_postfix : "italic" },
    "30" : { font_name : "OpenSans", font_style : "bolditalic", actual_font : "OpenSans-BoldItalic", actual_font_postfix : "bolditalic" },
    "31" : { font_name : "OpenSans-Medium", font_style : "regular", actual_font : "OpenSans-Medium", actual_font_postfix : "normal" },
    "32" : { font_name : "OpenSans-Medium", font_style : "bold", actual_font : "OpenSans-Bold", actual_font_postfix : "bold" },
    "33" : { font_name : "OpenSans-Medium", font_style : "italic", actual_font : "OpenSans-MediumItalic", actual_font_postfix : "italic" },
    "34" : { font_name : "OpenSans-Medium", font_style : "bolditalic", actual_font : "OpenSans-BoldItalic", actual_font_postfix : "bolditalic" },
    "35" : { font_name : "OpenSans-SemiBold", font_style : "regular", actual_font : "OpenSans-SemiBold", actual_font_postfix : "normal" },
    "36" : { font_name : "OpenSans-SemiBold", font_style : "bold", actual_font : "OpenSans-ExtraBold", actual_font_postfix : "bold" },
    "37" : { font_name : "OpenSans-SemiBold", font_style : "italic", actual_font : "OpenSans-SemiBoldItalic", actual_font_postfix : "italic" },
    "38" : { font_name : "OpenSans-SemiBold", font_style : "bolditalic", actual_font : "OpenSans-ExtraBoldItalic", actual_font_postfix : "bolditalic" },
    "39" : { font_name : "Roboto", font_style : "regular", actual_font : "Roboto-Regular", actual_font_postfix : "normal" },
    "40" : { font_name : "Roboto", font_style : "bold", actual_font : "Roboto-Bold", actual_font_postfix : "bold" },
    "41" : { font_name : "Roboto", font_style : "italic", actual_font : "Roboto-Italic", actual_font_postfix : "italic" },
    "42" : { font_name : "Roboto", font_style : "bolditalic", actual_font : "Roboto-BoldItalic", actual_font_postfix : "bolditalic" },
    "43" : { font_name : "Roboto-Medium", font_style : "regular", actual_font : "Roboto-Medium", actual_font_postfix : "normal" },
    "44" : { font_name : "Roboto-Medium", font_style : "bold", actual_font : "Roboto-Bold", actual_font_postfix : "bold" },
    "45" : { font_name : "Roboto-Medium", font_style : "italic", actual_font : "Roboto-MediumItalic", actual_font_postfix : "italic" },
    "46" : { font_name : "Roboto-Medium", font_style : "bolditalic", actual_font : "Roboto-BoldItalic", actual_font_postfix : "bolditalic" },
    "47" : { font_name : "WorkSans", font_style : "regular", actual_font : "WorkSans-Regular", actual_font_postfix : "normal" },
    "48" : { font_name : "WorkSans", font_style : "bold", actual_font : "WorkSans-Bold", actual_font_postfix : "bold" },
    "49" : { font_name : "WorkSans", font_style : "italic", actual_font : "WorkSans-Italic", actual_font_postfix : "italic" },
    "50" : { font_name : "WorkSans", font_style : "bolditalic", actual_font : "WorkSans-BoldItalic", actual_font_postfix : "bolditalic" },
    "51" : { font_name : "WorkSans-Medium", font_style : "regular", actual_font : "WorkSans-Medium", actual_font_postfix : "normal" },
    "52" : { font_name : "WorkSans-Medium", font_style : "bold", actual_font : "WorkSans-Bold", actual_font_postfix : "bold" },
    "53" : { font_name : "WorkSans-Medium", font_style : "italic", actual_font : "WorkSans-MediumItalic", actual_font_postfix : "italic" },
    "54" : { font_name : "WorkSans-Medium", font_style : "bolditalic", actual_font : "WorkSans-BoldItalic", actual_font_postfix : "bolditalic" },
    "55" : { font_name : "WorkSans-SemiBold", font_style : "regular", actual_font : "WorkSans-SemiBold", actual_font_postfix : "normal" },
    "56" : { font_name : "WorkSans-SemiBold", font_style : "bold", actual_font : "WorkSans-ExtraBold", actual_font_postfix : "bold" },
    "57" : { font_name : "WorkSans-SemiBold", font_style : "italic", actual_font : "WorkSans-SemiBoldItalic", actual_font_postfix : "italic" },
    "58" : { font_name : "WorkSans-SemiBold", font_style : "bolditalic", actual_font : "WorkSans-ExtraBoldItalic", actual_font_postfix : "bolditalic" },
}

export const DisplayFontMapping = {
    "1" : { font_family : 'Montserrat', font_style : 'normal', font_weight : '400'},
    "2" : { font_family : 'Montserrat', font_style : 'normal', font_weight : '700'},
    "3" : { font_family : 'Montserrat', font_style : 'italic', font_weight : '400'},
    "4" : { font_family : 'Montserrat', font_style : 'italic', font_weight : '700'},
    "5" : { font_family : 'Montserrat', font_style : 'normal', font_weight : '500'},
    "6" : { font_family : 'Montserrat', font_style : 'normal', font_weight : '700'},
    "7" : { font_family : 'Montserrat', font_style : 'italic', font_weight : '500'},
    "8" : { font_family : 'Montserrat', font_style : 'italic', font_weight : '700'},
    "9" : { font_family : 'Montserrat', font_style : 'normal', font_weight : '600'},
    "10" : { font_family : 'Montserrat', font_style : 'normal', font_weight : '800'},
    "11" : { font_family : 'Montserrat', font_style : 'italic', font_weight : '600'},
    "12" : { font_family : 'Montserrat', font_style : 'italic', font_weight : '800'},
    "13" : { font_family : 'Noto Sans', font_style : 'normal', font_weight : '400'},
    "14" : { font_family : 'Noto Sans', font_style : 'normal', font_weight : '700'},
    "15" : { font_family : 'Noto Sans', font_style : 'italic', font_weight : '400'},
    "16" : { font_family : 'Noto Sans', font_style : 'italic', font_weight : '700'},
    "17" : { font_family : 'Noto Sans', font_style : 'normal', font_weight : '500'},
    "18" : { font_family : 'Noto Sans', font_style : 'normal', font_weight : '700'},
    "19" : { font_family : 'Noto Sans', font_style : 'italic', font_weight : '500'},
    "20" : { font_family : 'Noto Sans', font_style : 'italic', font_weight : '700'},
    "21" : { font_family : 'Noto Sans', font_style : 'normal', font_weight : '600'},
    "22" : { font_family : 'Noto Sans', font_style : 'normal', font_weight : '800'},
    "23" : { font_family : 'Noto Sans', font_style : 'italic', font_weight : '600'},
    "24" : { font_family : 'Noto Sans', font_style : 'italic', font_weight : '800'},
    "25" : { font_family : 'Alex Brush', font_style : 'normal', font_weight : '400'},
    "26" : { font_family : 'Borel', font_style : 'normal', font_weight : '400'},
    "27" : { font_family : 'Open Sans', font_style : 'normal', font_weight : '400'},
    "28" : { font_family : 'Open Sans', font_style : 'normal', font_weight : '700'},
    "29" : { font_family : 'Open Sans', font_style : 'italic', font_weight : '400'},
    "30" : { font_family : 'Open Sans', font_style : 'italic', font_weight : '700'},
    "31" : { font_family : 'Open Sans', font_style : 'normal', font_weight : '500'},
    "32" : { font_family : 'Open Sans', font_style : 'normal', font_weight : '700'},
    "33" : { font_family : 'Open Sans', font_style : 'italic', font_weight : '500'},
    "34" : { font_family : 'Open Sans', font_style : 'italic', font_weight : '700'},
    "35" : { font_family : 'Open Sans', font_style : 'normal', font_weight : '600'},
    "36" : { font_family : 'Open Sans', font_style : 'normal', font_weight : '800'},
    "37" : { font_family : 'Open Sans', font_style : 'italic', font_weight : '600'},
    "38" : { font_family : 'Open Sans', font_style : 'italic', font_weight : '800'},
    "39" : { font_family : 'Roboto', font_style : 'normal', font_weight : '400'},
    "40" : { font_family : 'Roboto', font_style : 'normal', font_weight : '700'},
    "41" : { font_family : 'Roboto', font_style : 'italic', font_weight : '400'},
    "42" : { font_family : 'Roboto', font_style : 'italic', font_weight : '700'},
    "43" : { font_family : 'Roboto', font_style : 'normal', font_weight : '500'},
    "44" : { font_family : 'Roboto', font_style : 'normal', font_weight : '700'},
    "45" : { font_family : 'Roboto', font_style : 'italic', font_weight : '500'},
    "46" : { font_family : 'Roboto', font_style : 'italic', font_weight : '700'},
    "47" : { font_family : 'Work Sans', font_style : 'normal', font_weight : '400'},
    "48" : { font_family : 'Work Sans', font_style : 'normal', font_weight : '700'},
    "49" : { font_family : 'Work Sans', font_style : 'italic', font_weight : '400'},
    "50" : { font_family : 'Work Sans', font_style : 'italic', font_weight : '700'},
    "51" : { font_family : 'Work Sans', font_style : 'normal', font_weight : '500'},
    "52" : { font_family : 'Work Sans', font_style : 'normal', font_weight : '700'},
    "53" : { font_family : 'Work Sans', font_style : 'italic', font_weight : '500'},
    "54" : { font_family : 'Work Sans', font_style : 'italic', font_weight : '700'},
    "55" : { font_family : 'Work Sans', font_style : 'normal', font_weight : '600'},
    "56" : { font_family : 'Work Sans', font_style : 'normal', font_weight : '800'},
    "57" : { font_family : 'Work Sans', font_style : 'italic', font_weight : '600'},
    "58" : { font_family : 'Work Sans', font_style : 'italic', font_weight : '800'},
}

export const FontVarietyAvailable = {
    "AlexBrush" : ['regular'],
    "Borel" : ['regular'],
    "Montserrat" : ['regular', 'bold', 'italic', 'bolditalic'],
    "Montserrat-Medium" : ['regular', 'bold', 'italic', 'bolditalic'],
    "Montserrat-SemiBold" : ['regular', 'bold', 'italic', 'bolditalic'],
    "NotoSans" : ['regular', 'bold', 'italic', 'bolditalic'],
    "NotoSans-Medium" : ['regular', 'bold', 'italic', 'bolditalic'],
    "NotoSans-SemiBold" : ['regular', 'bold', 'italic', 'bolditalic'],
    "OpenSans" : ['regular', 'bold', 'italic', 'bolditalic'],
    "OpenSans-Medium" : ['regular', 'bold', 'italic', 'bolditalic'],
    "OpenSans-SemiBold" : ['regular', 'bold', 'italic', 'bolditalic'],
    "Roboto" : ['regular', 'bold', 'italic', 'bolditalic'],
    "Roboto-Medium" : ['regular', 'bold', 'italic', 'bolditalic'],
    "WorkSans" : ['regular', 'bold', 'italic', 'bolditalic'],
    "WorkSans-Medium" : ['regular', 'bold', 'italic', 'bolditalic'],
    "WorkSans-SemiBold" : ['regular', 'bold', 'italic', 'bolditalic']
}

export const StyleDefaults = {
    fonts : [
        "AlexBrush",
        "Borel",
        "Montserrat",
        "Montserrat-Medium",
        "Montserrat-SemiBold",
        "NotoSans",
        "NotoSans-Medium",
        "NotoSans-SemiBold",
        "OpenSans",
        "OpenSans-Medium",
        "OpenSans-SemiBold",
        "Roboto",
        "Roboto-Medium",
        "WorkSans",
        "WorkSans-Medium",
        "WorkSans-SemiBold",
    ],
    sizes : [
        "10",
        "11",
        "12",
        "14",
        "16",
        "18",
        "20",
        "22",
        "24",
        "26",
        "28",
        "30",
        "32",
        "34",
        "36",
        "40",
        "42",
        "44",
        "46",
        "48"
    ],
    date_formats : [
        "DD/MM/YYYY",
        "MM/DD/YYYY",
        "DD-MM-YYYY",
        "MM-DD-YYYY",
        "DD MMM YYYY",
        "MMM DD YYYY",
        "YYYY/MM/DD",
        "YYYY-MM-DD"
    ],
    role_id_color : "#2E75B6"
}

export const FieldStyleMapping = {
    "record_status" : "new",
    "logo" : { value : "default", format : 'middle', editable : true, font_id : "", font_size : "", font_color : "", show : true},
    "certificate" : { value : "Certificate", format : null, editable : true, font_id : "14", font_size : "42", font_color : "#000000", show : true},
    "assessment_name" : { value : "Assessment Name", format : null, editable : false, font_id : "17", font_size : "18", font_color : "#000000", show : true},
    "emp_name" : { value : "Employee Name", format : null, editable : false, font_id : "14", font_size : "18", font_color : "#000000", show : true},
    "job_title" : { value : "Job Title", format : null, editable : false, font_id : "19", font_size : "16", font_color : "#000000", show : true},
    "dec_text" : { value : "has been deemed fit and proper to carry out the following function(s) of:", format : null, editable : true, font_id : "13", font_size : "14", font_color : "#000000", show : true},
    "role_id" : { value : "Role ID", format : null, editable : false, font_id : "14", font_size : "14", font_color : "default", show : true},
    "role_title" : { value : "Role Title", format : null, editable : false, font_id : "13", font_size : "14", font_color : "#000000", show : true},
    "no_role" : { value : "No roles were assessed as part of this Certification", format : null, editable : true, font_id : "14", font_size : "14", font_color : "#000000", show : true},
    "assessor_name" : { value : "Assessor Name", format : null, editable : false, font_id : "25", font_size : "24", font_color : "#000000", show : true},
    "assessment_date" : { value : "curdate", format : "DD/MM/YYYY", editable : false, font_id : "13", font_size : "12", font_color : "#000000", show : true},
    "cert_by" : { value : "Certified By", format : null, editable : true, font_id : "15", font_size : "12", font_color : "#000000", show : true},
    "date" : { value : "Date", format : null, editable : true, font_id : "15", font_size : "12", font_color : "#000000", show : true}
}

