import React from 'react';
import styled from 'styled-components'

const DashboardHeader = styled.div`
							background: #ffffff;
							color: #bdbdbd;
							background-color: #ffffff ! important;
							font-family: 'Montserrat', sans-serif;
							font-size: 14px;
							width:100%;
							height: 34px;
							padding-left: 10px;
							margin-right: 16px;
							padding-bottom: 7px;
							text-align: left;
						`;


class MicroserviceHeader extends React.Component {
	render() {
		return (<DashboardHeader>
			{this.props.children}
		</DashboardHeader>);
	}
}

export default MicroserviceHeader;
