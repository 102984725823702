import React, { Component } from "react";
import styled, { css } from "styled-components";
import { FaTimes, FaCalendarAlt } from 'react-icons/fa';
import APICall from '../../Common/APICall.js';
import MultiColumnDropdown from "../Common/MultiColumnDropdown.js";
import Utils from "../../Common/Utils.js";
import CSLLoader from '../Common/CSLLoader';
import DatePicker from 'react-date-picker';
import moment from 'moment'
import AlertBox from '../Common/AlertBox';
import Store from "../../Common/Store.js";

moment.defaultFormat = "DD/MM/YYYY";


const ModalWraper = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  width: 50%;
  position: absolute;
  top: 38px;
  z-index: 1001;
  margin-left: 15%;
  background-color: #F5F9FD;
`;
const MRModalContainer = styled.div`
  display: block;
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
  height: auto;
  background-color: #F5F9FD;
  box-shadow: rgb(167, 166, 166) 0px 0px 5px;
`;

const MRModalBody = styled.div`
  position: relative;
  display: block;
  box-sizing: border-box;
  background-color: #f6f9fd;
  padding: 35px 35px 35px 35px;
  border-radius: 4px;
  // border: solid black;
`;
const HelpTextContainer = styled.div`
    margin-top: 8px;
    border: 1px solid #86aac7;
    padding: 28px;
    padding-top:18px;
    padding-bottom:18px;
    background: #e7f0f7;
    color:#99b8d0;
`;
const First = styled.div`
    font-family:sans-serif;
    font-size: 28px;
    font-weight: bold;
`;
const Second = styled.div`
    font-family: sans-serif;
    color: #6583a1;
    font-size: 15px;
    line-height: 21px;
`;
const MRModalHeaderCloseBtn = styled.div`
	position: absolute;
	top: 8px;
	right: 10px;
	box-sizing: border-box;
	margin-top: 24px;
	margin-right: 20px;
	font-size: 25px;
	cursor: pointer;
	font-weight: 200;
`;

const MRModalButton = styled.button`
	display: inline-block;
	box-sizing: border-box;
	color: #ffffff;
	width: 145px;
	height: 45px;
	border-radius: 3px;
	margin-left: 5px;
	font-size: 15px;
	margin-left: 10px;
	float: right;
	cursor: pointer;
`;

const MRModalNextBtn = styled(MRModalButton)`
	background-color: #37ADA7;
	border: 1px solid #37ada7;
    cursor: pointer !important;
    width:180px;
    cursor: pointer !important;
`;
const MRModalCancelBtn = styled(MRModalButton)`
	background-color: #ffffff;
	color: #545454;
	border: 2px solid #dadada;
	cursor: pointer !important;

`;

const MRModalFooter = styled.div`
	position: relative;
	padding: 17px;
	background-color: white;
	height: 48px;
	border-style: solid;
	border-width: 1px;
	border-color: #f3f7fb;
	border-top: 1px solid #dfdfdf;
`;

const ModalLabel = styled.div`
    color: #212121;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
`;

const CSLDateCover = styled.div`
    border-radius: 3px;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0 0 4px #c4c4c4;
    `;



class RescheduleAssessment extends Component {
    initialState = {
        selected_assessment_id: this.props.assessment_id,
        filterDueDate: new Date(),
        isclear: true,
        alert_param: null,
        ready: true,
        comment: '',
        due_by_str: ''
    }

    state = {
        ...this.initialState
    }

    constructor (props) {
        super(props);
    }
    componentDidMount() {
        this.initialiseState();
    }

    initialiseState = () => {
        let dateObj = null;
        let reschedule_date = this.props.re_schedule_date;
        let due_date = this.props.due_date
        if (!reschedule_date || reschedule_date === "0" || reschedule_date === "") {
            dateObj = new Date(parseInt(due_date) * 1000);
            console.log("Due Date:", dateObj);
        } else {
            dateObj = new Date(parseInt(reschedule_date) * 1000);
            console.log("Re-Schedule Date:", dateObj);
        }
        this.setState({ filterDueDate: dateObj })
    }


    changeClear = () => {
        this.setState({ isclear: false })
    }

    closeView = () => {
        this.props.closeModal();
    }



    onRequestedDueDate = (date) => {
        let curmonth = parseInt(date.getMonth());
        let currmonth = curmonth + 1;
        var dateStr =
            currmonth >= 10
                ? date.getFullYear() + '/' + currmonth + '/' + date.getDate()
                : date.getFullYear() + '/0' + currmonth + '/' + date.getDate();
        this.setState({ due_by_str: dateStr, filterDueDate: date });
    }



    assignAssessmentSubmit = async (e) => {
        e.preventDefault();
        let alert_param = null;
        let due_date = moment(this.state.filterDueDate, 'DD - MM - YYYY').unix();
        const postData = { command: "assessments_details", action: 'reschedule_assessment', assessment_id: this.props.assessment_id, due_date: due_date, assignment_id: this.props.assignment_id };
        const api = new APICall();
        let assessment_response = await api.commandWithoutCallback(postData);
        let { error_code } = assessment_response;
        if (error_code == 0) {
            alert_param = { title: 'Success', message: "Assessment Rescheduled successfully", ok_text: 'OK', confirm: false, alertHandler: this.confirmHandler }

        } else {
            alert_param = { title: 'Error', message: "Problem while  Rescheduling Assessment", ok_text: 'OK', confirm: false, alertHandler: this.confirmHandler }
        }
        this.setState({ alert_param: alert_param });
    }

    confirmHandler = () => {
        this.props.closeModal();
        this.setState({ alert_param: null })

    }

    handleChange = (event) => {
        this.setState({ comment: event.target.value });
    }


    render() {

        if (!this.state.ready) {
            return (<div><CSLLoader /></div>);
        }
        return (
            <div style={{ "fontFamily": "Montserrat, sans-serif" }}>
                <ModalWraper>
                    <MRModalContainer>
                        <AlertBox alertParam={this.state.alert_param} />
                        <MRModalBody>
                            <MRModalHeaderCloseBtn onClick={this.closeView}>
                                <FaTimes />
                            </MRModalHeaderCloseBtn>
                            <First>Reschedule Assessment</First>
                            <HelpTextContainer>
                                <Second>Select a  new due date to re-assign this assessment.<br />Please also provide an explanatory comment</Second>
                            </HelpTextContainer>

                            <div id="dueDateDiv">
                                <ModalLabel>
                                    Due Date
                                </ModalLabel>
                                <CSLDateCover>
                                    <DatePicker
                                        onChange={this.onRequestedDueDate}
                                        clearIcon={null}
                                        calendarIcon={<FaCalendarAlt style={{ color: '#243E57', marginLeft: '500px' }} />}
                                        locale={'en-GB'}
                                        value={this.state.filterDueDate == null ? new Date() : this.state.filterDueDate}
                                    />
                                </CSLDateCover>

                            </div>

                            <ModalLabel>Comments</ModalLabel>
                            <textarea style={{ width: '99%', resize: 'none', border: '2px solid #EAEEF2' }} name="textarea" cols="30" rows="5"
                                placeholder='Add Your Comment for rescheduling the assessment.'
                                value={this.state.comment}
                                onChange={this.handleChange}
                            ></textarea>

                        </MRModalBody>
                        <MRModalFooter>
                            <MRModalNextBtn
                                onClick={this.assignAssessmentSubmit}
                                id="submit"
                            >
                                Assign Assessment
                            </MRModalNextBtn>

                            <MRModalCancelBtn onClick={() => this.closeView()}>Close</MRModalCancelBtn>

                        </MRModalFooter>

                    </MRModalContainer>

                </ModalWraper>
            </div>
        );
    }
}

export default RescheduleAssessment;
