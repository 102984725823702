import React from "react";
import APICall from "../../../Common/APICall";
import CSLTable from "../../Common/CSLTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import EventBus from "../../../Common/EventBus";
class TopicsRoleTypes extends React.Component {

    state = { topic_role_types: {}, role_types: [] }

    componentDidMount() {
        this.setComponentState()
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) this.setComponentState()
    }

    setComponentState = async () => {
        const payload1 = { command: "topics_api", action: "list_role_types" }
        const payload2 = { command: "topics_api", action: "list_topic_role_types", topic_id: this.props.topic_id }
        const api = new APICall()
        const [role_types_result, topic_role_types_result] = await Promise.all([api.commandWithoutCallback(payload1), api.commandWithoutCallback(payload2)])
        let topic_role_types = {}
        let role_types = []
        if (role_types_result.error_code === 0) {
            role_types = role_types_result.result
        }
        if (topic_role_types_result.error_code === 0) {
            if (topic_role_types_result.result.length !== 0) {
                const list = topic_role_types_result.result
                for (let item of list) {
                    topic_role_types[item.role_type_id.toString()] = 1
                }
            }
        }
        this.setState({ topic_role_types, role_types })
    }

    toggleActive = (role_type_id) => async (event) => {
        event.preventDefault()
        let topic_role_types = JSON.parse(JSON.stringify(this.state.topic_role_types))
        let payload = { command: "topics_api", role_type_id: role_type_id, topic_id: this.props.topic_id }
        if (role_type_id.toString() in topic_role_types === true) {
            delete topic_role_types[role_type_id.toString()]
            payload.action = "remove_role_type_topic_association"
        } else {
            topic_role_types[role_type_id.toString()] = 1
            payload.action = "add_role_type_topic_association"
        }
        this.setState({ topic_role_types })
        const api = new APICall()
        const result = await api.commandWithoutCallback(payload);

    }

    tableData = () => {
        let ret = { data: [], columns: [] }
        ret.columns = [
            { Header: 'ROLE TYPE', accessor: 'role_type', filterable: false },
            { Header: 'ROLE REFERENCE', accessor: 'base', filterable: false, maxWidth: 250 },
            {
                Header: "",
                Cell: row => (
                    <>
                        {
                            row.original.is_active === 1 ?
                                <FontAwesomeIcon className="mc-roles-add-icon" icon={regular('toggle-on')} onClick={this.toggleActive(row.original.id)} /> :
                                <FontAwesomeIcon className="mc-roles-add-icon" style={{ color: "#ababab" }} icon={regular('toggle-off')} onClick={this.toggleActive(row.original.id)} />
                        }
                    </>
                ),
                maxWidth: 50,
                filterable: false
            }
        ]
        if (this.state.role_types.length !== 0) {
            for (let item of this.state.role_types) {
                let is_active = item.id.toString() in this.state.topic_role_types === true ? 1 : 0
                let elem = { id: item.id, role_type: item.name, base: `${item.base}#`, color: item.color, is_active }
                ret.data.push(elem)
            }
        }
        return ret
    }

    render() {
        console.log('inside TopicRoleTypes', this.state);
        return (
            <div style={{ marginTop: "25px" }}>
                <CSLTable processData={this.tableData} />
            </div>
        )
    }

}

export default TopicsRoleTypes
