import React, { Component }from "react"
import CSLTable from "../../Common/CSLTable"
import AlertBox from "../../Common/AlertBox"
import APICall from "../../../Common/APICall"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import styled from "styled-components"
import AssessmentDetails from "./AssessmentDetails"
import AddAssessmentModal from "./AddAssessmentModal"
import EventBus from "../../../Common/EventBus"
import ExcelGenerator from '../../Common/ExcelGenerator';

const MRModalHeader = styled.div`
  background-color: white;
  display: block;
  padding-top: 5px;
  font-size: 14px;
  height: 56px;
  minWidth: 100%;
  box-sizing: border-box;
`;
const MRModalHeaderText = styled.div`
  font-weight: 600;
  font-size: 20px;
  float: left;
  margin-top: 24px;
  margin-left: 35px;
  color: #1a3552;
  font-family: "Montserrat", sans-serif;
`;

const MRModalBody = styled.div`
  position: relative;
  display: block;
  box-sizing: border-box;
  background-color: #ffffff;
  padding: 35px 35px 35px 35px;
  border-radius: 4px;
`;
const ClearBoth = styled.div`
  clear: both;
`;
const TextMessage = styled.div`
  background-color: #f1f6f7;
  padding: 15px 15px 15px 10px;
  text-align: justify;
  border-radius: 3px;
  border-bottom: 1px solid #dfdfdf;
`

class AssessmentsOverview extends React.Component {

    state = { alert_param: null, assessments: [], gcs: [], role_types: [], selected_assessment_id: 0, show_assessment_details: false, view_add_assessment_modal: false, header_context_visible: false }

    componentDidMount() {
        this.setComponentState()
    }

    async componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({ view_add_assessment_modal: this.props.add_assessment })
            if (prevProps.selected_assessment_id !== this.props.selected_assessment_id) {
                this.setState({ selected_assessment_id: this.props.selected_assessment_id, show_assessment_details: true })
            }
        }
    }

    setComponentState = async () => {
        //debugger;
        const payload = { command: "assessments", action: 'list_assessments' }
        const api = new APICall()
        const result = await api.commandWithoutCallback(payload)
        let assessments = result.result.assessments
        if (assessments.length !== 0) {
            assessments = await this.makeAllDeselected(assessments)
        }

        EventBus.raiseEvent("refresh_assessments", assessments);

        this.setState({
            assessments: assessments,
            gcs: result.result.companyLists,
            role_types: result.result.roleTypeLists
        });
    }

    makeAllDeselected = async (list) => {
        if (list.length !== 0) {
            for (let i = 0; i < list.length; i++) {
                list[i].selected = false
                list[i].context_visible = false
            }
        }
        return list
    }

    refreshAssessments = async (resp) => {
        let assessments = await this.makeAllDeselected(resp.assessments)
        EventBus.raiseEvent("refresh_assessments", assessments)
        this.setState({ assessments, gcs: resp.companyLists, role_types: resp.roleTypeLists, view_add_assessment_modal: false })
    }

    toggleActive = (assessment_id) => async (event) => {
        event.preventDefault()
        let status = 0;
        let assessments = JSON.parse(JSON.stringify(this.state.assessments))
        for (let i = 0; i < assessments.length; i++) {
            if (assessments[i].id === assessment_id) {
                assessments[i].is_active = assessments[i].is_active === 1 ? 0 : 1;
                status = assessments[i].is_active;
                break;
            }
        }

        const payload = { command: "assessments", action: 'toggle_assessment_details', assessment_id: assessment_id, is_active: status }
        const api = new APICall()
        const result = await api.commandWithoutCallback(payload)
        this.setState({ assessments })
    }

    toggleContextMenu = (assessment_id) => (event) => {
        event.preventDefault();
        let assessments = JSON.parse(JSON.stringify(this.state.assessments))
        for (let i = 0; i < assessments.length; i++) {
            if (assessments[i].id === assessment_id) {
                assessments[i].context_visible = assessments[i].context_visible === true ? false : true;
                break;
            }
        }
        this.setState({ assessments })
    }

    closeContextMenu = (assessment_id) => (event) => {
        event.preventDefault()
        let assessments = JSON.parse(JSON.stringify(this.state.assessments))
        for (let i = 0; i < assessments.length; i++) {
            if (assessments[i].id === assessment_id) {
                assessments[i].context_visible = false;
                break;
            }
        }
        this.setState({ assessments })
    }

    tableData = () => {
        let ret = { data: [], columns: [] };
        let has_gc = JSON.parse(localStorage.getItem('has_gc'));
        ret.columns = [
            {
                Header: 'TOPIC TITLE', accessor: 'topic_title', filterable: false, Cell: (row) => {
                    return (
                        <div style={{ cursor: 'pointer' }} onClick={this.showAssessmentEditModal(row.original.id)} >
                            {row.original.topic_title}
                        </div>
                    )
                }
            },
            { Header: 'DESCRIPTION', accessor: 'description', filterable: false },
            {
                Header: 'LEGAL ENTITY', accessor: 'legal_entity', filterable: false, maxWidth: 150, show: has_gc,
                Cell: row => (
                    <div>
                        {(() => {
                            if (row.original.legal_entity != 'Multiple') {
                                return (
                                    <span>{row.original.legal_entity}</span>
                                )
                            } else if (row.original.legal_entity == 0) {
                                return (
                                    <span>0</span>
                                )
                            } else {
                                return (
                                    <span title={row.original.legal_entity_multiple.map((item) => { return item.company_name }).join(',')} style={{ color: "#1b3452", cursor: 'pointer' }}>{row.original.legal_entity}</span>
                                )
                            }
                        })()}
                    </div>
                )
            },
            {
                Header: 'TOPICS', accessor: 'topics', filterable: false, maxWidth: 70,
                Cell: row => (
                    <div>
                        <span title={row.original.topics_names} style={{ color: "#1b3452", cursor: 'pointer' }}>{row.original.topics}</span>
                    </div>
                )
            },
            {
                'Header': 'APPLIES TO', accessor: 'applies_to', filterable: false, maxWidth: 100, Cell: row => (
                    <div >
                        {(() => {
                            if (row.original.applies_to != 'Multiple') {
                                return (
                                    <span>{row.original.applies_to}</span>
                                )
                            } else if (row.original.applies_to == 0) {
                                return (
                                    <span>0</span>
                                )
                            } else {
                                return (
                                    <span title={row.original.applies_to_multiple.map((item) => { return item.base }).join(',')} style={{ color: "#1b3452", cursor: 'pointer' }}>{row.original.applies_to}</span>
                                )
                            }
                        })()}
                    </div>
                )
            },
            {
                Header: 'STATUS', accessor: 'status', filterable: false, maxWidth: 70,
                Cell: row => (
                    row.original.is_active === 1 ?
                        <FontAwesomeIcon className="mc-roles-add-icon" icon={regular('toggle-on')} onClick={this.toggleActive(row.original.id)} /> :
                        <FontAwesomeIcon className="mc-roles-add-icon" style={{ color: "#ababab" }} icon={regular('toggle-off')} onClick={this.toggleActive(row.original.id)} />
                )
            },
            {
                'Header': '', filterable: false, maxWidth: 30,
                Cell: row => (
                    <div className="mc-roles-table-action-container" style={{ width: "100%", paddingRight: "10px" }}>
                        <FontAwesomeIcon className="mc-roles-add-icon" title="Edit Assessment" icon={regular('ellipsis-vertical')} onClick={this.toggleContextMenu(row.original.id)} />
                        {
                            row.original.context_visible &&
                            <div className="context-menu" onMouseLeave={this.closeContextMenu(row.original.id)}>
                                <div className="context-menu-item" onClick={this.showAssessmentEditModal(row.original.id)}>Edit</div>
                            </div>
                        }
                    </div>
                )
            },
        ];

        if (this.state.assessments.length !== 0) {
            for (let d of this.state.assessments) {
                let elem = {
                    topic_title: d.name,
                    description: JSON.parse(d.assessment_json)["description"],
                    id: d.id,
                    legal_entity: d.gc_count === 0 ? 0 : (d.gc_count === 1 ? this.state.gcs.find(c => c["assessment_id"] === d.id)["company_name"] : "Multiple"),
                    topics: d.topics_count,
                    applies_to: d.role_count === 0 ? 0 : (d.role_count === 1 ? this.state.role_types.find(rt => rt["assessment_id"] === d.id)["base"] : "Multiple"),
                    status: d.status,
                    is_active: d.is_active,
                    legal_entity_multiple: d.gc_count > 1 ? this.state.gcs.filter((c) => c["assessment_id"] == d.id) : [],
                    applies_to_multiple: d.role_count > 1 ? this.state.role_types.filter((rt) => rt["assessment_id"] == d.id) : [],
                    context_visible: d.context_visible,
                    topics_names: d.topics_names
                }
                ret.data.push(elem);
            }
        }
        return ret;
    }

    showAssessmentEditModal = (assessment_id) => async (event) => {
        event.preventDefault()

        let assessments = JSON.parse(JSON.stringify(this.state.assessments))
        for (let i = 0; i < assessments.length; i++) {
            if (assessments[i].id === assessment_id) {
                assessments[i].selected = true
            } else {
                assessments[i].selected = false
            }
        }
        EventBus.raiseEvent("refresh_assessments", assessments)
        this.setState({ assessments, selected_assessment_id: assessment_id, show_assessment_details: true })
    }

    showAssessmentAddModal = (event) => {
        event.preventDefault()
        this.viewAddModal()
    }

    viewAddModal = () => {
        this.setState({ view_add_assessment_modal: true })
    }

    closeAddModal = () => {
        this.setState({ view_add_assessment_modal: false })
        this.props.unSetAddAssessmentFlag()
    }

    openCloseContexMenu = (e) => {
        e.preventDefault()
        let header_context_visible = this.state.header_context_visible === true ? false : true;
        this.setState({ header_context_visible });
    }

    closeView = async () => {
        // let assessments = JSON.parse(JSON.stringify(this.state.assessments))
        // for (let i = 0; i < assessments.length; i++) {
        //     if (assessments[i].selected === true) {
        //         assessments[i].selected = false
        //         break
        //     }
        // }
        // EventBus.raiseEvent("refresh_assessments", assessments)
        // this.setState({ show_assessment_details: false })

        const payload = { command: "assessments", action: 'list_assessments' }
        const api = new APICall()
        const result = await api.commandWithoutCallback(payload)
        let assessments = result.result.assessments
        if (assessments.length !== 0) {
            assessments = await this.makeAllDeselected(assessments)
        }

        this.setState({
            assessments: assessments,
            gcs: result.result.companyLists,
            role_types: result.result.roleTypeLists,
            show_assessment_details: false
        })
        EventBus.raiseEvent("refresh_assessments", assessments)
    }

    chnageName = (obj) => {
        this.props.changeNick(obj)
    }

    closeHeaderContextMenu = (e) => {
        e.preventDefault();
        let header_context_visible = this.state.header_context_visible === true ? false : true;
        this.setState({ header_context_visible });
    }

    exportAssessments = (e) => {
        e.preventDefault();
        let has_gc = JSON.parse(localStorage.getItem('has_gc'));
        let exportData = [];
        if (this.state.assessments.length !== 0) {
            for (let d of this.state.assessments) {
                let elem = {
                    assessment_name: d.name,
                    description: JSON.parse(d.assessment_json)["description"],
                    legal_entity: d.gc_count == 1 ? this.state.gcs.find((c) => c["assessment_id"] == d.id)["company_name"] : this.state.gcs.filter((c) => c["assessment_id"] == d.id).map((item) => { return item.company_name }).join(','),
                    topic_names: d.topics_names,
                    applies_to: d.role_count == 1 ? this.state.role_types.find((rt) => rt["assessment_id"] == d.id)["base"] : this.state.role_types.filter((rt) => rt["assessment_id"] == d.id).map((item) => { return item.base }).join(','),
                    is_active: d.is_active == 1 ? "Active" : "In Active",
                }
                exportData.push(elem);
            }
        }

        const mapper = [
            { "assessment_name": "Assessment Name" },
            { "description": "Description" },
            { "legal_entity": "Company" },
            { "topic_names": "Topic Names" },
            { "applies_to": "Roles" },
            { "is_active": "Active" }
        ];


        if (has_gc == false) {
            // Remove the specific column from the mapper
            const columnNameToRemove = "Company";
            const updatedMapper = mapper.filter(column => Object.values(column)[0] !== columnNameToRemove);
            // Remove the unwanted data from modifiedArray
            const filteredModifiedArray = exportData.map(({ legal_entity, ...rest }) => rest);

            new ExcelGenerator(updatedMapper, filteredModifiedArray, 'Assessments');
        } else {
            new ExcelGenerator(mapper, exportData, 'Assessments');
        }
    }

    render() {
        const overrideRolesRespoStyle = {
            position: 'absolute',
            backgroundColor: '#f3f0f0',
            padding: '5px 5px',
            display: 'inline-block',
            width: '81px',
            right: '7px',
            boxShadow: '0px 0px 4px rgba(0,0,0,0.4)',
            zIndex: 10000,
            boxSizing: 'border-box',
        };

        return (
            <div style={{ position: "relative" }}>
                {
                    this.state.show_assessment_details === true &&
                    <div className="interactive-overlay" style={{ zIndex: "11" }}>
                        <AssessmentDetails
                            assessment_id={this.state.selected_assessment_id}
                            closeView={this.closeView}
                            changeNick={this.chnageName}
                            key={this.state.selected_assessment_id}
                        />
                    </div>
                }
                {
                    this.state.view_add_assessment_modal === true &&
                    <div className="interactive-overlay" style={{ zIndex: "11" }}>
                        <AddAssessmentModal closeModal={this.closeAddModal} refreshAssessments={this.refreshAssessments} />
                    </div>
                }
                <MRModalHeader>
                    <MRModalHeaderText>Assessments Overview</MRModalHeaderText>
                    <ClearBoth />
                </MRModalHeader>
                <MRModalBody>
                    <TextMessage style={{ marginBottom: 20 }}>
                        An Assessment is made up of one or more “Topics” or areas of assessment. Topics can contain Sections which allows you to break an Assessment down into manageable and identifiable parts. Assessments can be restricted to only apply to certain Role Types and/or Legal Entities. Below are all the Assessments available to you.
                    </TextMessage>
                    <div className="mc-table-header-container">
                        <div style={{ fontWeight: "600", fontSize: "15px" }}>Assessments</div>
                        <div style={{ display: "flex", justifyContent: "flex-end", position: 'relative' }}>
                            <FontAwesomeIcon className="mc-roles-add-icon" title="Add Assessment" icon={regular('square-plus')} onClick={this.showAssessmentAddModal} style={{ marginRight: "10px" }} />
                            <FontAwesomeIcon className="mc-roles-add-icon" icon={regular('ellipsis-vertical')} onClick={(e) => this.openCloseContexMenu(e)} />
                            {
                                (() => {
                                    if (this.state.header_context_visible === true) return (
                                        <div style={overrideRolesRespoStyle} onMouseLeave={this.closeHeaderContextMenu}>
                                            <div className="table-context-menu-item" onClick={(e) => this.exportAssessments(e)}>Export</div>
                                        </div>
                                    )
                                })()
                            }
                        </div>
                    </div>
                    <CSLTable processData={this.tableData} />
                    {this.state.alert_param !== null && <AlertBox alertParam={this.state.alert_param} />}
                </MRModalBody>
            </div>
        )
    }

}

export default AssessmentsOverview
