import React, { Component } from "react";
import AssessmentHeader from "./AssessmentHeader";
import AssessmentBody from "./AssessmentBody";
import APICall from "../../Common/APICall";
import CSLLoader from '../Common/CSLLoader';
import { AssessmentDataProvider } from './AssessmentDataContext';
import "./AssessmentDetails.css";
import Store from '../../Common/Store.js';
import ClassTopicStatus from './ClassTopicStatus';
import moment from 'moment';



class AssessmentDetails extends Component {

  constructor (props) {
    super(props);
    this.over_all_acessor = "";
    this.logged_in_user = 0;


    this.assessment_necessary_object = {
      "assessment_id": "",
      "assignment_id": "",
      "assessment_name": "",
      "assessment_due_date": "",
      "assessment_assigned_date": "",
      "assessment_runs_every": "",
      "certification_comment": "",
      "assessment_over_all_acessor": "",
      "assessment_assigned_to_id": "",
      "assessment_assigned_to_name": "",
      "assessment_assigned_by_id": "",
      "assessment_assigned_by_name": "",

      "assessment_times_assessed": "",
      "assessment_first_assessed_date": "",
      "assessment_last_assessed_date": "",
      "assessment_next_due_date": "",
      "assessment_cur_lane": "",
      "assessment_is_completed": "",
      "assessment_is_overall_acessor": "",


      "re_assessment_window": "",
      "assessment_warning_window": "",
      "assessment_effective_from": "",
      "assessment_effective_to": "",
      "assessment_assessed_by_type": "",
      "assessment_is_open_from_query_str": "",

      "is_all_required_files_uploaded": "false"
    };
    this.ts = {}
  }

  state = {
    ready: false,
    assessment_topics_data: [],
    assessments_header_data: {},
    assessment_complete_click: false,
    prevStatusObj: {},
    assessment_certificate_generated : false,
    deactivate_close : false
  };

  componentDidMount() {
    this.initialiseState();
  }

  componentDidUpdate(prevProps) {
    console.log('codm called Assessment details 1');
    if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
      this.initialiseState();
    }
  }


  initialiseState = async () => {
    this.setState({ ready: false });
    let user_role = Store.getStoreData('role');
    const sldata = JSON.parse(localStorage.getItem("site_layout"));
    let logged_in_user = sldata["contact_id"];
    let assessment_id = this.props.assessment_id;
    let assignment_id = this.props.assignment_id;
    let assigned_to_id = this.props.user_id;

    let assessment_user_name = sldata.result.use_storage.result.contactlist[assigned_to_id]['ContactName'];
    let over_all_acessor = this.props.over_all_acessor;
    let runs_every = this.props.runs_every;

    let is_over_all_acessor = over_all_acessor.includes(logged_in_user)

    const postData = {
      command: "assessments_details",
      action: 'get_assessment_details_data',
      assignment_id: assignment_id,
      assesment_id: assessment_id,
      contact_id: assigned_to_id,
      is_over_all_acessor: is_over_all_acessor,
      user_role: user_role
    };



    const api = new APICall();
    let assessment_response = await api.commandWithoutCallback(postData);

    console.log("ass details ===>",assessment_response);

    let due_date_str = assessment_response.result.assessment_details_json["due_date"];
    let cert_comment = assessment_response.result.assessment_details_json["certification_comment"];
    let assessment_name = assessment_response.result.assessment_details_json["name"];
    let assessment_assigned_date = assessment_response.result.assessment_details_json["assigned_date"]
    let assessment_assigned_by = assessment_response.result.assessment_details_json["assessment_assigned_by"];
    let assessment_assigned_by_name = sldata.result.use_storage.result.contactlist[assessment_assigned_by]['ContactName']
    let assessment_cur_lane = assessment_response.result.assessment_details_json["cur_lane"];
    let is_completed = assessment_response.result.assessment_details_json["is_completed"];
    let effective_from = assessment_response.result.assessment_details_json["effective_from"];
    let effective_to = assessment_response.result.assessment_details_json["effective_to"];
    let assessment_assessed_by_type = assessment_response.result.assessment_details_json["assessment_assessed_by_type"];
    let is_reviewed_send_mail = assessment_response.result.assessment_details_json["is_reviewed_send_mail"];

    this.over_all_acessor = over_all_acessor;
    this.logged_in_user = logged_in_user;


    this.assessment_necessary_object.assessment_id = assessment_id;
    this.assessment_necessary_object.assignment_id = assignment_id;
    this.assessment_necessary_object.assessment_name = assessment_name;
    this.assessment_necessary_object.assessment_due_date = due_date_str;
    this.assessment_necessary_object.assessment_assigned_date = assessment_assigned_date;
    this.assessment_necessary_object.assessment_runs_every = runs_every;
    this.assessment_necessary_object.certification_comment = cert_comment;
    this.assessment_necessary_object.assessment_over_all_acessor = over_all_acessor;
    this.assessment_necessary_object.assessment_assigned_to_name = assessment_user_name;
    this.assessment_necessary_object.assessment_assigned_to_id = assigned_to_id;
    this.assessment_necessary_object.assessment_assigned_by_id = assessment_assigned_by;
    this.assessment_necessary_object.assessment_assigned_by_name = assessment_assigned_by_name;
    this.assessment_necessary_object.assessment_cur_lane = assessment_cur_lane;
    this.assessment_necessary_object.assessment_is_completed = is_completed;
    this.assessment_necessary_object.re_assessment_window = this.props.reAssessmentWindow;
    this.assessment_necessary_object.assessment_warning_window = this.props.assessmentWarningWindow;
    this.assessment_necessary_object.assessment_is_overall_acessor = assessment_assessed_by_type != 6 ? (is_over_all_acessor || user_role == "admin_manager") : is_over_all_acessor;

    this.assessment_necessary_object.assessment_effective_from = effective_from;
    this.assessment_necessary_object.assessment_effective_to = effective_to;
    this.assessment_necessary_object.assessment_assessed_by_type = assessment_assessed_by_type;

    this.assessment_necessary_object.assessment_is_open_from_query_str = this.props.is_open_from_query_str;
    this.assessment_necessary_object.assessment_certificate_generated = assessment_response.result.assessment_details_json["cert_generated"] === 1 ? true : false;
    
    let assessment_certificate_generated = assessment_response.result.assessment_details_json["cert_generated"] === 1 ? true : false;

    this.assessment_necessary_object.assessment_is_reviewed_send_mail = is_reviewed_send_mail;


    let assessmentTopics = assessment_response.result.assessment_details_json.topics;
    console.log('inside assessment details ==>', assessmentTopics);
    for (let i = 0; i < assessmentTopics.length; i++) {
      let topic_status = await ClassTopicStatus({
        assignment_id: assignment_id,
        role_id: assessmentTopics[i]["role_id"],
        topic_id: assessmentTopics[i]["topic_id"],
        a_due_date: due_date_str,
        topic_name: assessmentTopics[i].name
      });
      assessmentTopics[i]['topic_status'] = topic_status
    }
    this.setState({ ready: true, assessment_topics_data: assessmentTopics, assessment_certificate_generated });
  }

  updateTopicStatusState = (topic_id, role_id, status) => {
    //debugger
    let topics = JSON.parse(JSON.stringify(this.state.assessment_topics_data));
    for (let i = 0; i < topics.length; i++) {
      if (topics[i]["topic_id"] == topic_id && topics[i]["role_id"] == role_id) {
        topics[i]["topic_status"] = status;
      }
    }
    this.setState({ assessment_topics_data: topics })
  };

  // update-state from topic right panel component
  updateState = async (topics, topic_id, role_id, role_code) => {
    const postData = {
      command: "assessments_details",
      action: 'assessment_topic_questions',
      role_id: role_id,
      assignment_id: this.props.assignment_id,
      topic_id: topic_id,
      role_code: role_code,
    };



    const api = new APICall();
    let answer_response = await api.commandWithoutCallback(postData);
    let section_questions = answer_response.result.section_questions;

    for (let i = 0; i < topics.length; i++) {
      const active_topic = topics[i]["is_clicked"];
      if (active_topic) {
        let topic_sections = topics[i]["sections"];
        for (let j = 0; j < topic_sections.length; j++) {
          const element = topic_sections[j];
          let section_id = element["section_id"];
          element["questions"] = section_questions[section_id]["questions"];
        }
      }
    }

    this.setState({ assessment_topics_data: topics, assessment_complete_click: false });
  }

  closeAssessmentDetailsModal = () => {
    this.props.closeModal();
  }

  assessmnentCompletion = (topics, assessment_complete_click) => {
    this.setState({ assessment_topics_data: topics, assessment_complete_click: assessment_complete_click });
  }

  certifyClicked = () => {
    this.setState({ deactivate_close : true});
  }

  activateClose = () => {
    this.setState({ deactivate_close : false});
  }

  assessmnentCertificateGenerated = () => {
    this.assessment_necessary_object.assessment_certificate_generated = true;
    this.setState({assessment_certificate_generated : true});
  }

  assessmnentCheckReviewMail = () => {
    this.assessment_necessary_object.assessment_is_reviewed_send_mail = 1;    
  }


  updateCurlaneToFinalCompletion = () => {
    this.assessment_necessary_object.assessment_cur_lane = "COMP_SMCR_FINAL_COMPLETION";
  }

  updateEffectiveFromDate = (date) => {
    let efrom_date = moment(date).unix();
    this.assessment_necessary_object.assessment_effective_from = efrom_date;
  }

  updateEffectiveToDate = (date) => {
    let eto_date = moment(date).unix();
    this.assessment_necessary_object.assessment_effective_to = eto_date;
  }


  // update state from topic left panel component
  updateTopic = async (topic, section_id, question_id, answer_id, answer_json, is_review_needed, pName) => {
    if (this.assessment_necessary_object.assessment_cur_lane != 'COMP_SMCR_REJECTED') {
      let topics = this.state.assessment_topics_data;
      let objIndex = topics.findIndex((obj => obj.topic_id == topic.topic_id && obj.name == topic.name && obj.role_id == topic.role_id));

      let returnedAnswerIdFromDb = await this.updateQuestionToggle(topic.topic_id, topic.role_id, question_id, answer_id, answer_json, is_review_needed, pName);



      let topic_status = await ClassTopicStatus({
        assignment_id: this.props.assignment_id,
        role_id: topic["role_id"],
        topic_id: topic["topic_id"],
        a_due_date: topic.assessment_due_date,
        topic_name: topic.name
      });

      topic['topic_status'] = topic_status

      if (objIndex !== -1) {
        topics[objIndex] = topic;
      }

      topic.sections.find((s) => s["section_id"] == section_id)['questions'].find(q => q["question_id"] == question_id)["answer_id"] = returnedAnswerIdFromDb;
      this.setState({ assessment_topics_data: topics, assessment_complete_click: false });
    }
  }

  // update topic question toggle

  updateQuestionToggle = async (topic_id, role_id, question_id, answer_id, answer_json, is_review_needed, pName) => {
    let assignment_id = this.props.assignment_id;
    const postData = {
      command: "assessments_details",
      action: 'assessment_question_answer',
      assignment_id: assignment_id,
      topic_id: topic_id,
      role_id: role_id,
      question_id: question_id,
      answer_id: answer_id,
      pName,
      answer_json: {
        'answer': answer_json
      },
      is_review_needed: is_review_needed ? 1 : 0,
      //is_review_needed: 0,
    };

    const api = new APICall();
    let answer_response = await api.commandWithoutCallback(postData);


    return answer_response.result.answer_id;
  }

  reviewQuestions = async (topic_id, role_id, review_question_ids, reviewJson, status) => {
    if (this.assessment_necessary_object.assessment_cur_lane != 'COMP_SMCR_REJECTED') {
      const postData = {
        command: "assessments_details",
        action: 'assessment_topic_reviewed',
        assignment_id: this.props.assignment_id,
        topic_id: topic_id,
        role_id: role_id,
        question_ids: review_question_ids,
        answer_json: reviewJson,

      };

      const api = new APICall();
      let answer_response = await api.commandWithoutCallback(postData);
      this.updateTopicStatusState(topic_id, role_id, status);
    }
  }

  updateFiles = (filesArray, role_id, question_id, topic_id, section_id, assignment_id) => {
    console.log('updateFiles 1');
    // Clone the topics array to avoid mutating the state directly
    let topics = [...this.state.assessment_topics_data];

    const topicToUpdate = topics.find(topic => topic.role_id === role_id && topic.topic_id == topic_id);

    if (topicToUpdate) {
      const sectionToUpdate = topicToUpdate.sections.find(section => section.section_id == section_id);
      if (sectionToUpdate) {
        const questionToUpdate = sectionToUpdate.questions.find(question => question.question_id == question_id);
        if (questionToUpdate) {
          questionToUpdate.response_attachment = filesArray;
        }
      }
    }

    // Update the state with the modified topics array
    this.setState({
      assessment_topics_data: topics
    });
  }


  render() {
    if (!this.state.ready) {
      return (<div><CSLLoader /></div>);
    }


    return (
      <AssessmentDataProvider value=
        {{
          data: this.state.assessment_topics_data,
          updateState: this.updateState,
          updateTopic: this.updateTopic,
          assessmnentCompletion: this.assessmnentCompletion,
          assessment_final_click: this.state.assessment_complete_click,
          reviewQuestions: this.reviewQuestions,
          logged_in_user: this.logged_in_user,
          assessment_necessary_object: this.assessment_necessary_object,
          updateTopicStatusState: this.updateTopicStatusState,
          updateCurlaneToFinalCompletion: this.updateCurlaneToFinalCompletion,
          updateEffectiveFromDate: this.updateEffectiveFromDate,
          updateEffectiveTodate: this.updateEffectiveToDate,
          assessmnentCertificateGenerated : this.assessmnentCertificateGenerated,
          certifyClicked : this.certifyClicked,
          activateClose : this.activateClose,
          updateFiles: this.updateFiles,
          assessmnentCheckReviewMail: this.assessmnentCheckReviewMail
        }}>
        <div className="assessment-details-modal-wrapper">
          <div className="assessment-details-modal-container">
            <AssessmentHeader closeAssessmentDetailsModal={this.closeAssessmentDetailsModal} deactivate_close={this.state.deactivate_close}/>
            <AssessmentBody assessment_certificate_generated={this.state.assessment_certificate_generated}/>
          </div>
        </div>
      </AssessmentDataProvider>
    );
  }
}


export default AssessmentDetails;
