import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import './sor.css';

class ReloadIcon extends Component {
    constructor (props) {
        super(props);
        this.state = {
            isClicked: false,
        };
    }

    toggle = () => {
        this.setState((prevState) => ({ isClicked: !prevState.isClicked }));
    };

    render() {
        const { isClicked } = this.state;
        return (
            <div className={`icon-container-reload ${isClicked ? 'clicked' : ''}`} onClick={this.toggle}>
                <FontAwesomeIcon title="Action" icon={solid('rotate-right')} />
            </div>
        );
    }
}

export default ReloadIcon;
