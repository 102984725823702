import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import CSLTable from "./../Common/CSLTable"
import APICall from "../../Common/APICall";
import AddRoleTypeModal from "./Modals/AddRoleTypeModal";
import RoleConfigModal from "./Modals/RoleConfigModal";
import AlertBox from "../Common/AlertBox";
import SmallSpinner from "../Common/SmallSpinner";

class RoleMaintenance extends React.Component {

	state = {
		role_types: [],
		show_role_config_modal: false,
		show_role_type_modal: false,
		alert_param: null,
		loading : false
	}

	async componentDidMount() {
		await this.setComponentState()
	}

	setComponentState = async () => {
		this.setState({ loading : true })
		const api = new APICall()
		const payload = { command: "get_mc_role_types" }
		const result = await api.commandWithoutCallback(payload)
		// console.log("role_types:", result)
		let role_types = []
		if (result.error_code === 0) {
			role_types = result.result.role_types
		}
		this.setState({role_types, loading : false})
	}

	showAddRoleTypeModal = (event) => {
		event.preventDefault();
		this.setState({ show_role_type_modal: true });
	}

	closeModal = () => {
		this.setState({ show_role_config_modal: false, show_role_type_modal: false });
	}

	showRoleTypeEditModal = (role_type_id) => (event) => {
		event.preventDefault();
		this.setState({ edit_role_type_id: parseInt(role_type_id), show_role_config_modal: true });
	}

	deleteRoleType = (role_type_id) => (event) => {
		event.preventDefault();
		let alert_param = {
			title: 'Are you sure?', message: "You have selected to delete this Role Type.", ok_text: 'Ok', confirm: true,
			alertHandler: this.processDeleteRoleType, stack: { role_type_id: role_type_id }
		}
		this.setState({ alert_param: alert_param });
	}

	processDeleteRoleType = async (result, stack) => {
		console.log("result:", result)
		console.log("stack:", stack)
		let alert_param = null
		if (result === true) {
			const payload = {command: "role_api", action: "remove_role_type", role_type_id: stack.role_type_id}
			const api = new APICall()
			const ret = await api.commandWithoutCallback(payload)
			if (ret.error_code === 0) {
				if (ret.result === null) {
					alert_param = {
						title: 'SUCCESS', message: "Role Type removed successfully", ok_text: 'Ok',
						alertHandler: this.afterDeleteRoleType, stack: {role_type_id: stack.role_type_id}
					}
					this.setState({alert_param})
				} else {
					alert_param = {
						title: 'ERROR', message: "This role type contains one or more roles. Please remove all roles to remove this role type", ok_text: 'Ok',
						alertHandler: this.notRevomable, stack: {}
					}
					this.setState({ alert_param: alert_param });
				}
			} else {
				alert_param = {
					title: 'ERROR', message: ret.error_msg, ok_text: 'Ok',
					alertHandler: () => {this.setState({alert_param: null})}, stack: {}
				}
				this.setState({alert_param})
			}
		} else {
			this.setState({ alert_param: null });
		}
	}

	afterDeleteRoleType = (result, stack) => {
		let role_types = []
		for (let item of this.state.role_types) {
			if (item.id !== stack.role_type_id) role_types.push(item)
		}
		this.setState({ alert_param: null, role_types });
	}

	notRevomable = (result, stack) => {
		this.setState({ alert_param: null });
	}

	generateTableData = () => {
		let ret = { data: [], columns: [] };
		ret.columns = [
			{
				Header: 'Role Type Label',
				accessor: 'role_type',
				Cell: row => (
					<div className="mc-roles-table-cell-container">
						{row.original.role_type}
						<div className="mc-roles-table-action-container">
							<FontAwesomeIcon className="mc-roles-add-icon" title="Edit Role Type" icon={solid('pencil')} onClick={this.showRoleTypeEditModal(row.original.id)} />
							<FontAwesomeIcon className="mc-roles-add-icon" title="Remove Role Type" icon={regular('trash-can')} onClick={this.deleteRoleType(row.original.id)} />
						</div>
					</div>
				)
			}
		];
		// console.log("this.state.role_types:", this.state.role_types)
		this.state.role_types.forEach((item) => {
			let elem = { id: item.id, role_type: item.name, priority: item.priority };
			ret.data.push(elem);
		})
		ret.data.sort((a, b) => {
			if (a.priority > b.priority) return 1;
			if (a.priority < b.priority) return -1;
			return 0;
		})
		return ret;
	}

	createRoleType = (role_type_obj) => {
		console.log("createRoleType obj:", role_type_obj)
		let role_types = JSON.parse(JSON.stringify(this.state.role_types))
		role_types.push(role_type_obj)
		this.setState({ role_types, show_role_type_modal: false })
	}

	render() {
		return (
			<div className="mc-general-container">
				<div className="mc-roles-top-container">
					<FontAwesomeIcon className="mc-roles-add-icon" title="Add Role Type" icon={regular('square-plus')} onClick={this.showAddRoleTypeModal} />
				</div>
				{
					this.state.show_role_type_modal === true &&
					<div className="interactive-overlay">
						<AddRoleTypeModal createRoleType={this.createRoleType} closeModal={this.closeModal} />
					</div>
				}
				{
					this.state.show_role_config_modal === true &&
					<div className="interactive-overlay">
						<RoleConfigModal role_type_id={this.state.edit_role_type_id} modifyRoleType={this.modifyRoleType} closeModal={this.closeModal} setComponentState={this.setComponentState} />
					</div>
				}
				<div className="mc-table-container">
					<CSLTable processData={this.generateTableData} />
					{
						this.state.loading && <SmallSpinner />
					}
				</div>
				{this.state.alert_param !== null && <AlertBox alertParam={this.state.alert_param} />}
			</div>
		)
	}
}

export default RoleMaintenance
