import React from "react";
import "./ManageConfirmation.css"
import DemoPdfwithjsPDF from "./ConfirmationReport1";

class Demopdf extends React.Component{

    render(){
        return(
            <div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
                <div className="container">
                        <div className="header-container">
                            <div className="header-text-container">Demo PDF View</div>
                        </div>
                        <div className="table-container">
                            <DemoPdfwithjsPDF />
                        </div>
                </div>
            </div>
        )
    }

}

export default Demopdf;