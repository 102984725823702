import React from 'react'

const AssessmentDataContext = React.createContext();

/*
Provider - The component that provides the value
Consumer - A component that is consuming the value
*/

export const AssessmentDataProvider = AssessmentDataContext.Provider;
export const AssessmentDataConsumer = AssessmentDataContext.Consumer;


export default AssessmentDataContext;
