import React from 'react';
import styled from 'styled-components'
// import { GiFamilyTree } from 'react-icons/gi'
import RespoEventBusNew from './RespoEventBusNew'
import RespoUtils from './RespoUtils';

const RespoTreeRootButtonWrapper = styled.div`
							display: block;
							position: absolute;
							top: 4px;
							right: 30px;
							background-color: transparent;
							cursor: pointer;
							font-size:22px;
						    -webkit-transform: rotate(180deg);
						    -moz-transform: rotate(180deg);
						    -o-transform: rotate(180deg);
						    -ms-transform: rotate(180deg);
						    transform: rotate(180deg);
						    color: blue;
							`;
const RespoTreeUpRootButtonWrapper = styled.div`
							display: block;
							position: absolute;
							top: 4px;
							right: 3px;
							background-color: transparent;
							cursor: pointer;
							font-size:22px;
							color: blue;
							`;

							
let path = RespoUtils.getPath();


class TreeRootButton extends React.Component {
	changeTreeRoot() {
		let respomapVisible = { 'children': [this.props.item] };
		// respomapVisible.push(this.props.item);
		RespoEventBusNew.updateStore('respomap_visible', respomapVisible);
		// RespoEventBusNew.updateStore('root_id', this.props.item.id);
		RespoEventBusNew.updateStore('full_map', false);
		let loadCount = RespoEventBusNew.getStoreData('load_count');
		RespoEventBusNew.updateStore('load_count', loadCount++);
	}

	revertTreeRoot() {
		let respomapVisible = RespoEventBusNew.getStoreData('respomap');
		// console.log('respomapVisible in revert', respomapVisible);
		RespoEventBusNew.updateStore('max_level', 999, null, true);
		RespoEventBusNew.updateStore('respomap_visible', respomapVisible);
		RespoEventBusNew.updateStore('full_map', true);
		let loadCount = RespoEventBusNew.getStoreData('load_count');
		RespoEventBusNew.updateStore('load_count', loadCount++);

		// window.location.reload(true);
	}
	render() {
		// console.log('top in RespoLine', this.top);
		// let rootId = RespoEventBusNew.getStoreData('root_id');
		let fullHash = JSON.stringify(RespoEventBusNew.getStoreData('respomap'));
		let thisHash = JSON.stringify(RespoEventBusNew.getStoreData('respomap_visible'));
		let maxLevel = RespoEventBusNew.getStoreData('max_level');
		maxLevel = maxLevel === null ? 999 : maxLevel;
		if (this.props.item.level === 1) {
			if ((fullHash !== thisHash || maxLevel < 999)) {
				return (<RespoTreeUpRootButtonWrapper title="Revert to full map" onClick={this.revertTreeRoot.bind(this)}>
					<img src={`${path}csltreeactive.png`} alt="Modify tree" style={{ width: "15px", height: "auto" }} />
				</RespoTreeUpRootButtonWrapper>)
			}
			return <div></div>
		}

		return (<RespoTreeRootButtonWrapper title="Make this the root of the tree" onClick={this.changeTreeRoot.bind(this)}>
			<img src={`${path}csltreeinactive.png`} alt="Modify tree" style={{ width: "15px", height: "auto" }} />
		</RespoTreeRootButtonWrapper>)
	}

}

export default TreeRootButton;
