import React, { Component } from 'react'
import AssessmentCertPDF from './../Certifications/AssessmentCertPDF'
import AssessmentCertPDFBGProcess from '../Certifications/AssessmentCertPDFBGProcess';
import CertificateDownloadLink from '../Certifications/CertificateDownloadLink';
const pdf_icon = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzODQgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuNC4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNNjQgMEMyOC43IDAgMCAyOC43IDAgNjRWNDQ4YzAgMzUuMyAyOC43IDY0IDY0IDY0SDMyMGMzNS4zIDAgNjQtMjguNyA2NC02NFYxNjBIMjU2Yy0xNy43IDAtMzItMTQuMy0zMi0zMlYwSDY0ek0yNTYgMFYxMjhIMzg0TDI1NiAwek0yMTYgMjMyVjMzNC4xbDMxLTMxYzkuNC05LjQgMjQuNi05LjQgMzMuOSAwczkuNCAyNC42IDAgMzMuOWwtNzIgNzJjLTkuNCA5LjQtMjQuNiA5LjQtMzMuOSAwbC03Mi03MmMtOS40LTkuNC05LjQtMjQuNiAwLTMzLjlzMjQuNi05LjQgMzMuOSAwbDMxIDMxVjIzMmMwLTEzLjMgMTAuNy0yNCAyNC0yNHMyNCAxMC43IDI0IDI0eiIvPjwvc3ZnPg==';

class AssessmentCertification extends Component {

    state = { 
        assessment_name : "",
        pdf_size : "",
        gen_date: ""
    }

    getSize = (file_info) =>{
        let pdf_size = '';
        if(file_info.size > 1024) pdf_size = `${(file_info.size/1024).toFixed(2)} MB`;
        else pdf_size = `${file_info.size} kB`;
        let gen_date = file_info.date;
        console.log("pdf_size : ", pdf_size);
        this.setState({pdf_size, gen_date});
    }

    render() {
        console.log('Inside Certification ==>', this.props);
        return (
            <div style={{height : "68vh"}}>
                <div style={{position: "relative", border : "1px solid #ccc", margin : "7% auto", width : "60%"}}>
                    <div style={{position: "relative", width : "100%", padding : "15px", fontSize : "1.1rem", fontWeight : "600"}}>
                        Certification - This Assessment
                    </div>
                    <table style={{width : "100%", fontWeight : "500", borderCollapse: "collapse"}}>
                        <tr style={{backgroundColor : "#F6F9FD", color : "#0C6EE4", fontSize : "1rem" }}>
                            <td style={{padding : "10px", borderTop : "1px solid #ccc", borderBottom : "1px solid #ccc"}}></td>
                            <td style={{padding : "10px", borderTop : "1px solid #ccc", borderBottom : "1px solid #ccc"}}>Name</td>
                            <td style={{padding : "10px", borderTop : "1px solid #ccc", borderBottom : "1px solid #ccc"}}>Assessment</td>
                            <td style={{padding : "10px", borderTop : "1px solid #ccc", borderBottom : "1px solid #ccc"}}>Size</td>
                            <td style={{padding : "10px", borderTop : "1px solid #ccc", borderBottom : "1px solid #ccc"}}>Generated Date</td>
                        </tr>
                        <tr style={{fontSize : "0.8rem"}}>
                            <td style={{padding : "10px", border : "none"}}>
                                <img src={pdf_icon} style={{width : "15px", filter: "invert(44%) sepia(78%) saturate(1761%) hue-rotate(198deg) brightness(101%) contrast(101%)"}} />
                            </td>
                            <td style={{padding : "10px", border : "none"}}>
                                {/* <AssessmentCertPDF assessment_name={this.props.assessment_name} assessment_id={this.props.assessment_id} assignment_id={this.props.assignment_id} getSize={this.getSize}/> */}
                                {/* <AssessmentCertPDFBGProcess assessment_name={this.props.assessment_name} assessment_id={this.props.assessment_id} assignment_id={this.props.assignment_id} getSize={this.getSize}/> */}
                                <CertificateDownloadLink assessment_name={this.props.assessment_name} assessment_id={this.props.assessment_id} assignment_id={this.props.assignment_id} getSize={this.getSize}/>
                            </td>
                            <td style={{padding : "10px", border : "none"}}>{this.props.assessment_name}</td>
                            <td style={{padding : "10px", border : "none"}}>{this.state.pdf_size}</td>
                            <td style={{padding : "10px", border : "none"}}>{this.state.gen_date}</td>
                        </tr>
                    </table>
                </div>
            </div>
        )
    }
}

export default AssessmentCertification;
