import React from 'react';
// import styled from 'styled-components';
import './respomap.css';
import RespoMap from './RespoMap.js';
import RespoMapPrintview from './RespoMapPrintview.js';
import RespomapeHeader from './RespomapHeader.js';
import Utils from './../../Common/Utils.js';
import RespoEventBusNew from './RespoEventBusNew'
import { FaTimes } from "react-icons/fa";
import DatePicker from 'react-date-picker';
import BranchIsolationMessage from './BranchIsolationMessage.js';
import Store from '../../Common/Store';
import http from 'mcc-front-aux/dist/httpCommon'

const renderComponent = {
    'screen': RespoMap,
    'viewprint': RespoMapPrintview
};


class RespoDashBoard extends React.Component {
    state = { showScreen: 'screen', component_id: '0', show_filter: false, date: new Date(), load_count: 0 };
    component_id = "";
    show_job_desc = false;
    show_role_desc = false;

    constructor (props) {
        super(props);

        this.updateState = this.updateState.bind(this);
        this.component_id = RespoEventBusNew.registerCallBack('show_screen', this.updateState);
        RespoEventBusNew.registerCallBack('show_filter', this.filterToShowOrHide, this.component_id);
        RespoEventBusNew.registerCallBack('load_count', this.filterToShowOrHide, this.component_id);
        RespoEventBusNew.updateStore('show_all_roles', false, this.component_id);
        RespoEventBusNew.updateStore('orientation', "portrait", this.component_id);
        RespoEventBusNew.updateStore('full_map', true, this.component_id);
        RespoEventBusNew.updateStore('load_count', 0, this.component_id);
        RespoEventBusNew.updateStore('orientation', "portrait");
        // RespoEventBusNew.updateStore('show_icons', true);
        Utils.setEnv('smcr');
    }


    onDateChange = date => {
        console.log('in date change');
        let fullMap = RespoEventBusNew.getStoreData('full_map');
        if (!fullMap) {
            alert("To change effective date you must first render the full map.");
            return;
        }
        let url = process.env.REACT_APP_API_URL;
        let curmonth = parseInt(date.getMonth());
        let currmonth = curmonth + 1;
        var dateStr = date.getFullYear() + '/' + currmonth + '/' + date.getDate();
        let postData = { "command": "getrespomap", "effective_date": dateStr };
        console.log('dateStr', dateStr);
        // let httpVerb = {
        //     method: "POST",
        //     headers: { "Content-Type": "application/x-www-form-urlencoded" },
        //     body: "jsondata=" + JSON.stringify(postData)
        // };
        // console.log('httpVerb', httpVerb);

        http.post(url, { jsondata: postData }).then(result => {
            let respomap_original = JSON.parse(result.data.body);
            let filtered = [];
            console.log('result', result);
            result.result.forEach((item, index) => {
                let objkeys = Object.keys(item.role);
                let check = false;
                objkeys.forEach((obkey) => {
                    // console.log(index);
                    if (obkey.includes("SMF") || obkey.includes("SMP") || obkey.includes("SMO")) {
                        check = true;
                    }
                });
                if (check === true) {
                    filtered.push(item);
                }
            });
            result.result = filtered;
            RespoEventBusNew.updateStore('respomap_original', respomap_original);
            RespoEventBusNew.updateStore('respomap_filtered', result);
            if (RespoEventBusNew.getStoreData('show_all_roles')) {
                RespoEventBusNew.updateStore('respomap_raw', respomap_original);
            } else {
                RespoEventBusNew.updateStore('respomap_raw', result);
            }

            result.show_role_desc = this.show_role_desc;
            result.show_job_desc = this.show_job_desc;
            this.setState({ date });
        }).catch((error) => {
              console.log(error);
        })

       /* fetch(url, httpVerb)
            .then(res => res.json())
            .then((result) => {
                let respomap_original = JSON.parse(JSON.stringify(result));
                let filtered = [];
                console.log('result', result);
                result.result.forEach((item, index) => {
                    let objkeys = Object.keys(item.role);
                    let check = false;
                    objkeys.forEach((obkey) => {
                        // console.log(index);
                        if (obkey.includes("SMF") || obkey.includes("SMP") || obkey.includes("SMO")) {
                            check = true;
                        }
                    });
                    if (check === true) {
                        filtered.push(item);
                    }
                });
                result.result = filtered;
                RespoEventBusNew.updateStore('respomap_original', respomap_original);
                RespoEventBusNew.updateStore('respomap_filtered', result);
                if (RespoEventBusNew.getStoreData('show_all_roles')) {
                    RespoEventBusNew.updateStore('respomap_raw', respomap_original);
                } else {
                    RespoEventBusNew.updateStore('respomap_raw', result);
                }

                result.show_role_desc = this.show_role_desc;
                result.show_job_desc = this.show_job_desc;
                this.setState({ date });
            })
            .catch((error) => {
                console.log(error);
            })*/

    };

    checkRoleSelect = (event) => {
        let fullMap = RespoEventBusNew.getStoreData('full_map');
        if (!fullMap) {
            alert('To change what roles to view, you must first render the full map.');
            return;
        }
        var store_data = RespoEventBusNew.getStoreData('respomap_raw');
        var show_job_desc = store_data.show_job_desc;
        var show_role_desc = store_data.show_role_desc;
        if (event.target.checked) {
            let new_store_data = RespoEventBusNew.getStoreData('respomap_original');
            new_store_data.show_job_desc = show_job_desc;
            new_store_data.show_role_desc = show_role_desc;
            RespoEventBusNew.updateStore('respomap_raw', new_store_data);
            RespoEventBusNew.updateStore('show_all_roles', true);
        } else {
            let new_store_data = RespoEventBusNew.getStoreData('respomap_filtered');
            new_store_data.show_job_desc = show_job_desc;
            new_store_data.show_role_desc = show_role_desc;
            RespoEventBusNew.updateStore('respomap_raw', new_store_data);
            RespoEventBusNew.updateStore('show_all_roles', false);
        }
    }

    updateLoadCount() {
        let loadCount = RespoEventBusNew.getStoreData('load_count');
        this.setState({ load_count: loadCount });
    }

    updateState() {
        let show_screen = RespoEventBusNew.getStoreData('show_screen');
        this.setState({ showScreen: show_screen });
    }

    filterToShowOrHide = () => {
        this.setState({ show_filter: RespoEventBusNew.getStoreData('show_filter') });
    }

    checkJobDesc = (event) => {
        let store_data = RespoEventBusNew.getStoreData('respomap_raw');
        store_data.show_job_desc = parseInt(event.target.value);
        RespoEventBusNew.updateStore('respomap_raw', store_data);
        // this.showRoleDesc = store_data.show_role_desc;
        RespoEventBusNew.updateStore('show_job_desc', event.target.value);
    }

    revertToFullMap = () => {
        let respomapVisible = RespoEventBusNew.getStoreData('respomap');
        RespoEventBusNew.updateStore('max_level', 999, null, true);
        RespoEventBusNew.updateStore('respomap_visible', respomapVisible);
        RespoEventBusNew.updateStore('full_map', true);
        let loadCount = RespoEventBusNew.getStoreData('load_count');
        RespoEventBusNew.updateStore('load_count', loadCount++);
    }

    checkRoleDesc = (event) => {
        //debugger;
        let store_data = RespoEventBusNew.getStoreData('respomap_raw');
        store_data.show_role_desc = parseInt(event.target.value);
        RespoEventBusNew.updateStore('respomap_raw', store_data);
    }

    applyFilter = (event) => {
        event.preventDefault();
    }

    hideFilter = (event) => {
        event.preventDefault();
        RespoEventBusNew.updateStore('show_filter', false);
    }

    componentWillUnmount() {
        RespoEventBusNew.deRegisterCallback('show_screen', this.component_id);
        RespoEventBusNew.deRegisterCallback('show_filter', this.component_id);
    }
    render() {

        if (Store.getStoreData("access_reporting") === true && Store.getStoreData("role") !== 'no_access' && Store.getStoreData("url_permissions")[Store.getStoreData("role")].respomap === 0) {
            return (
                <div style={{ padding: "0px 30px 30px 30px", marginTop: "20px" }}>
                    <div className="mcc-alert error">
                        <div style={{ fontWeight: "600" }}>You are not authorized to access this page.</div>
                    </div>
                </div>
            )
        }
        console.log('respo dashboard gets called', Store.getStoreData('module_name'));
        console.log('role===>', Store.getStoreData("role"));
        let Screen = renderComponent[this.state.showScreen];
        let store_data = RespoEventBusNew.getStoreData('respomap_raw');
        let fullMap = RespoEventBusNew.getStoreData('full_map');
        this.remount++;
        if (this.state.show_filter === true) {
            return (
                <div style={{ position: "relative", overflowX: 'hidden' }}>
                    <RespomapeHeader />
                    <BranchIsolationMessage />
                    <div style={{ height: "calc(100vh - 65px)", overflowX: 'hidden' }}>
                        <Screen respomap={null} level={0} style={{ position: 'relative', marginLeft: '30px' }} />
                    </div>

                    <div className="csl-config-container">
                        <div className="csl-config-header-container">
                            <div className="csl-config-header-text" style={{ color: '#5F5F5F' }}>Report Parameters</div>
                            <div className="csl-config-header-close" onClick={this.hideFilter} style={{ color: '#5F5F5F' }}><FaTimes /></div>
                        </div>
                        <div className="csl-config-options-container">
                            <div>Include Job Description</div>
                            <div style={{ marginTop: "8px" }} className="cslradio"><input id="r0" type="radio" name="jobdesc" onClick={this.checkJobDesc}
                                value="1" defaultChecked={store_data.show_job_desc === 1 ? true : false} />&nbsp;&nbsp;&nbsp;&nbsp;<label htmlFor="r0">Yes</label></div>
                            <div><input type="radio" id="r1" name="jobdesc" value="0" onClick={this.checkJobDesc} defaultChecked={store_data.show_job_desc === 1 ? false : true} />&nbsp;&nbsp;&nbsp;&nbsp;<label htmlFor="r1">No</label></div>
                        </div>
                        <div className="csl-config-options-container">
                            <div style={{ marginBottom: "10px" }}>View Role Indicators</div>
                            <div><input type="radio" id="r3" value="0" name="roledesc" onClick={this.checkRoleDesc} defaultChecked={store_data.show_role_desc === 1 ? false : true} />&nbsp;&nbsp;&nbsp;&nbsp;<label htmlFor="r3">Inline</label></div>
                            <div style={{ marginTop: "8px" }} className="cslradio"><input type="radio" id="r2" name="roledesc"
                                defaultChecked={store_data.show_role_desc === 0 ? false : true} onClick={this.checkRoleDesc} value="1" />&nbsp;&nbsp;&nbsp;&nbsp;<label htmlFor="r2">List view w/ Role Title</label></div>
                        </div>
                        <div className="csl-config-options-container">
                            <div>Run Report As Of</div>
                            <div className="csl-date-cover" title={fullMap ? 'Set effective date for the Responsibilities Map' : 'Unavailable in the Isolated Branch mode'}>

                                <DatePicker
                                    onChange={this.onDateChange}
                                    clearIcon={null}
                                    calendarIcon={null}
                                    locale={"en-GB"}
                                    value={this.state.date}
                                    disabled={!fullMap}
                                />

                            </div>
                        </div>
                        <div className="csl-config-options-container" title={fullMap ? 'Check to include all Roles' : 'Unavailable in the Isolated Branch mode'}>
                            <div>Include Regime Types</div>
                            <div style={{ marginTop: "8px" }} className="cslradio"><input disabled={!fullMap} type="checkbox" id="c2" name="roleselect"
                                defaultChecked={RespoEventBusNew.getStoreData('show_all_roles')}
                                onClick={this.checkRoleSelect} value="1" />&nbsp;&nbsp;&nbsp;&nbsp;<label htmlFor="c2">All Roles</label></div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div style={{ position: "relative", overflowX: 'hidden' }}>
                <RespomapeHeader />
                <BranchIsolationMessage />
                <div style={{ height: "calc(100vh - 65px)", overflowX: "hidden" }}>
                    <Screen respomap={null} level={0} style={{ position: 'relative', marginLeft: '30px' }} />
                </div>
            </div>
        );
    }
}

export default RespoDashBoard;
