import React from 'react'
import styled from 'styled-components'
import ClipLoader from "react-spinners/ClipLoader"

const Wrapper = styled.div`
    position : absolute;
    top : 0;
    left : 0;
    width : 100%;
    height : 100%;
`
const Loader = styled.div`
    position: absolute;
    height : 50px;
    width : 50px;
    left: 50%;
    top: 50%;
    transform : translate(-50%, -50%);
    z-index: 20001;
`

class SmallSpinner extends React.Component {

    render() {
        return (
            <Wrapper>
                <Loader>
                    <ClipLoader size={50} color={"#123abc"} loading={true} />
                </Loader>
            </Wrapper>
        )
    }

}

export default SmallSpinner