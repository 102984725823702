import moment from 'moment';
import APICall from "../../Common/APICall";

async function ClassTopicStatus(props) {
    let questions = [];
    let assignment_id = props.assignment_id;
    let role_id = props.role_id;
    let topic_id = props.topic_id;

    const postData = {
        command: "assessments_details",
        action: "assessment_topic_questions",
        role_id,
        topic_id,
        assignment_id,
    };
    const api = new APICall();

    let questionResponse = await api.commandWithoutCallback(postData);
    let sectionQuestions = questionResponse.result.section_questions;

    Object.entries(sectionQuestions).forEach(([key, val]) => {
        let section_id = key;
        let section_questions = val["questions"];

        questions.push(...section_questions);
    });

    let status = "";
    let allQuestionsCompleted,
        flaggedForAttention,
        inProgress = false;
    let due_date = props.a_due_date;

    // check for in-progress
    inProgress = questions.some((q) => q["answer_id"] != 0);

    // check for all questions are completed
    allQuestionsCompleted = questions.every((q) => q["answer_id"] != 0);

    // Check if any question has is_review_required = 1
    const hasReviewRequiredQuestion = questions.some((q) => q["is_review_required"] === 1);

    // Check for flagged attention
    flaggedForAttention = questions.some((q) => {
        const value = q["answer_json"]["answer"]["value"].toLowerCase();
        const correctAnswer = q["correct_answer"].toLowerCase();
        const flaggedOpt = q["flagged_opt"].toLowerCase();

        if (flaggedOpt === 'incorrect_na') {
            return value == 'na' || value !== correctAnswer;
        } else if (flaggedOpt === 'na') {
            return value == 'na';
        } else if (flaggedOpt === 'incorrect') {
            return value !== correctAnswer;
        } else if (flaggedOpt === 'no_flag') {
            return false;
        }
    });

    // Set reviewed to false if any question has is_review_required = 1
    const reviewed = !hasReviewRequiredQuestion;

    const dueDateStr = moment.unix(due_date).format("DD/MM/YYYY");
    const dueDate = moment.utc(dueDateStr);
    const currentDate = moment.utc();

    if (allQuestionsCompleted && !flaggedForAttention) {
        status = "Complete";
    } else if (allQuestionsCompleted && flaggedForAttention && reviewed) {
        status = "Reviewed";
    } else if (allQuestionsCompleted && flaggedForAttention && !reviewed) {
        status = "Review Required";
    } else if (inProgress) {
        status = "In Progress";
    } else if (currentDate.isAfter(dueDate)) {
        status = "Overdue";
    } else {
        status = "Due " + dueDateStr;
    }

    console.log('status ClassTopicStatus', status);

    return status;
}

export default ClassTopicStatus;
