import Store from './Store.js';
import moment from 'moment'
import http from 'mcc-front-aux/dist/httpCommon'

const cache_commands = { index: 1 }
const cache_renew_time = 2 * 60

class APICall {
  constructor () {
    this.url = Store.getStoreData('api_url');
    this.is_admin = Store.getStoreData('is_admin');
    this.module_name = Store.getStoreData('module_name');
  }

  command(postData, callback, incremental_callback) {
    let now_time = moment().unix()
    let module_name = Store.getStoreData('module_name')
    let command = postData.command
    let env = "select";
    let command_id = `${module_name}-${command}-${env}`;

    let jsonDataString = JSON.stringify(postData).replace(/&/g, '--and--');
    jsonDataString = jsonDataString.replace(/\+/g, '--plus--');
    jsonDataString = jsonDataString.replace(/'+/g, '--quote--');
    jsonDataString = jsonDataString.replace(/\n/g, '--lf--');
    jsonDataString = jsonDataString.replace(/’/g, '--ap--');
    jsonDataString = jsonDataString.replace(/\r/g, '');
    let incremental = (typeof incremental_callback !== 'undefined') ? true : false
    if (command in cache_commands) {
      let cached_result = Store.getStoreData(command_id)
      let cached_time = Store.getStoreData(command_id + '-last_cached')

      if (cached_time !== null && cached_result !== null && now_time - cached_time < cache_renew_time) {

        postData.incremental = 'incremental' in postData ? postData.incremental : incremental
        try {
          let str_cached_result = JSON.stringify(cached_result);
          if (cached_result.error_code === 0) callback(cached_result);
        } catch (err) {

        }
      }
    }

    // Add in the json command if it exists
    let postURL = this.url
    if('command' in postData) {
      postURL += `/${postData.command}`
    }

    http.post(postURL, { jsondata: jsonDataString}).then(result => {
      result = result.data.body;
      result = result.replace(/(--plus--)/g, "+");
      result = result.replace(/(--quote--)/g, "'");
      result = result.replace(/(--and--)/g, "&");
      result = result.replace(/--ap--+/g, "’");
      // result = result.replace(/(--nl--)/g, "\n");
      // result = result.replace(/(--lf--)/g, "\n");
      result = JSON.parse(result);
      if (command in cache_commands) {
        Store.updateStore(command_id, result)
        Store.updateStore(command_id + '-last_cached', now_time)
      }
      if (incremental) {
        incremental_callback(result);
      } else {
        callback(result);
      }

    }).catch(error => {

      return (null)
    })


    // fetch(url, httpVerb)
    //   .then(res => res.json())
    //   .then(result => {
    //     result = JSON.stringify(result);
    //     result = result.replace(/(--plus--)/g, "+");
    //     result = result.replace(/(--quote--)/g, "'");
    //     result = result.replace(/(--and--)/g, "&");
    //     result = result.replace(/--ap--+/g, "’");
    //     // result = result.replace(/(--nl--)/g, "\n");
    //     // result = result.replace(/(--lf--)/g, "\n");
    //     result = JSON.parse(result);
    //     if (command in cache_commands) {
    //       Store.updateStore(command_id, result)
    //       Store.updateStore(command_id + '-last_cached', now_time)
    //     }
    //     if (incremental) {
    //       incremental_callback(result);
    //     } else {
    //       callback(result);
    //     }
    //
    //   }).catch(error => {
    //
    //     return (null)
    //   });
  }

  async commandWithoutCallback(postData) {
    let jsonDataString = JSON.stringify(postData).replace(/&/g, '--and--');
    jsonDataString = jsonDataString.replace(/\+/g, '--plus--');
    jsonDataString = jsonDataString.replace(/'+/g, '--quote--');
    jsonDataString = jsonDataString.replace(/\n/g, '--lf--');
    jsonDataString = jsonDataString.replace(/’/g, '--ap--');
    jsonDataString = jsonDataString.replace(/\r/g, '');
    // if (!this.is_debug) this.url += `msgw/${this.module_name}/api/command`;
    // let url = Store.getStoreData('is_debug') === true ? Store.getStoreData('api_url') : `${Store.getStoreData('api_url')}msgw/${this.module_name}/api/command`
    // let httpVerb = { method: "POST", mode: "cors", headers: { "Content-Type": "application/x-www-form-urlencoded" }, body: "jsondata=" + jsonDataString };
    // const fetch_response = await fetch(url, httpVerb);
    // let result = await fetch_response.json();
    // result = JSON.stringify(result);

    // Add in the json command if it exists
    let postURL = this.url
    if('command' in postData) {
      postURL += `/${postData.command}`
    }

    let result = await http.post(postURL, { jsondata: jsonDataString})
    result = result.data.body;
    result = result.replace(/(--plus--)/g, "+");
    result = result.replace(/(--quote--)/g, "'");
    result = result.replace(/(--and--)/g, "&");
    result = result.replace(/--ap--+/g, "’");
    // result = result.replace(/(--nl--)/g, "\n");
    // result = result.replace(/(--lf--)/g, "\n");
    result = JSON.parse(result);
    return result;
  }
}

export default APICall;
