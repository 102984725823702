import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import CSLTable from "../Common/CSLTable";
import BulkModalEmployee from './BulkModalEmployee.js'
import UploadFile from './../Common/UploadFile';
import APICall from "../../Common/APICall";
import AlertBox from '../Common/AlertBox.js';
import moment from 'moment';
import { saveAs } from 'file-saver';
import './EmployeeFiles.css'
import SmallSpinner from "../Common/SmallSpinner";

class Files extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.uploadApi = new UploadFile();
    }
    state = {
        alert_param: null,
        header_context_visible: false,
        isModalOpen: false,
        binFiles: [],
        show_db_files: [],
        loading : false
    }

    componentDidMount() {
        this.setComponentState()
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) this.setComponentState()
    }

    setComponentState = async () => {
        this.setState({ loading : true })
        const payload = { command: "personnel", action: 'select_employee_files', employee_id: this.props.selected_employee_id }
        const api = new APICall()
        const result = await api.commandWithoutCallback(payload)
        if (result.error_code === 0) {
            let files = result.result
            for (let i = 0; i < files.length; i++) {
                files[i].context_visible = false
            }
            this.setState({ show_db_files: files, loading : false })
        }
    }

    openCloseFilesContexMenu = (event) => {
        event.preventDefault()
        let header_context_visible = this.state.header_context_visible === true ? false : true;
        this.setState({ header_context_visible });
    }


    toggleContextMenu = (uid) => (event) => {
        event.preventDefault()
        let files = JSON.parse(JSON.stringify(this.state.show_db_files))
        for (let i = 0; i < files.length; i++) {
            if (files[i].uid === uid) {
                files[i].context_visible = files[i].context_visible === true ? false : true
            } else {
                files[i].context_visible = false
            }
        }
        this.setState({ show_db_files: files })
    }

    closeContextMenu = (uid) => (event) => {
        event.preventDefault()
        let files = JSON.parse(JSON.stringify(this.state.show_db_files))
        for (let i = 0; i < files.length; i++) {
            if (files[i].uid === uid) {
                files[i].context_visible = false
                break
            }
        }
        this.setState({ show_db_files: files })
    }

    downloadFile = async (e, uid, ref_id, file_name) => {
        let alert_param = null;
        e.preventDefault();
        const payload = { command: "personnel", action: 'download_file', uid: uid, ref_id: ref_id }
        const api = new APICall()
        const { result } = await api.commandWithoutCallback(payload);
        if (result != "") {
            this.downloadBase64File(result, file_name);
        } else {
            alert_param = { title: 'Error', message: "Problem occured when downloading files", ok_text: 'OK', confirm: false, alertHandler: this.confirmHandler }
        }
        this.setState({ alert_param: alert_param });
    }

    downloadBase64File = (base64String, filename) => {
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/octet-stream' });
        saveAs(blob, filename);
    }



    deleteFile = async (e, uid, ref_id, name) => {
        e.preventDefault();
        let alert_param = null;
        const payload = { command: "personnel", action: 'delete_file', uid: uid, ref_id: ref_id, employee_id: this.props.selected_employee_id }
        const api = new APICall()
        let files_response = await api.commandWithoutCallback(payload);
        let db_emp_files = files_response.result;

        for (let i = 0; i < db_emp_files.length; i++) {
            db_emp_files[i].context_visible = false
        }

        if (files_response.error_code == 0) {
            alert_param = { title: 'Success', message: "Files deleted sucessfully", ok_text: 'OK', confirm: false, alertHandler: this.confirmHandler }
        } else {
            alert_param = { title: 'Error', message: "Problem occured when deleting files", ok_text: 'OK', confirm: false, alertHandler: this.confirmHandler }
        }
        this.setState({ alert_param: alert_param, isModalOpen: false, show_db_files: db_emp_files, binFiles: [] });
    }


    processEmployeeFiles = () => {
        let ret = { data: [], columns: [] }
        let emp_files = this.state.show_db_files;
        ret.columns = [
            { Header: 'FILE NAME', accessor: 'name', minWidth: 150, filterable: false, headerStyle: { textAlign: 'left' }, style: { fontWeight: '600' } },
            { Header: 'FILE TYPE', accessor: 'type', minWidth: 150, filterable: false, headerStyle: { textAlign: 'left' }, style: { fontWeight: '600' } },
            { Header: 'UPLOADED DATE', accessor: 'upload_date', minWidth: 150, filterable: false, headerStyle: { textAlign: 'left' }, style: { fontWeight: '600' } },
            { Header: 'UPLOADED BY', accessor: 'upload_by', minWidth: 150, filterable: false, headerStyle: { textAlign: 'left' }, style: { fontWeight: '600' } },
            {
                Header: "",
                Cell: row => (
                    <div className="mc-roles-table-action-container" style={{ width: "100%", paddingRight: "10px" }}>
                        <div>
                            <FontAwesomeIcon className="mc-roles-add-icon" title="Files action" icon={regular('ellipsis-vertical')} onClick={this.toggleContextMenu(row.original.uid)} />
                            {
                                row.original.context_visible &&
                                <div className="context-menu" onMouseLeave={this.closeContextMenu(row.original.ref_id)}>
                                    <div className="context-menu-item" onClick={(e) => { e.preventDefault(); this.downloadFile(e, row.original.uid, row.original.ref_id, row.original.name); }}>Download</div>
                                    <div className="context-menu-item" onClick={(e) => { e.preventDefault(); this.deleteFile(e, row.original.uid, row.original.ref_id, row.original.name); }}>Delete</div>
                                </div>
                            }
                        </div>
                    </div>
                ),
                maxWidth: 70,
                filterable: false
            }
        ]

        for (let file of emp_files) {
            const cts = JSON.parse(localStorage.getItem("contactlist"));
            let contactName = cts.result.contactlist[file.created_by]["ContactName"]
            let fileType = this.getExtension(file.file_name)
            let elem = {
                uid: file.uid,
                ref_id: file.ref_id,
                name: file.file_name,
                type: fileType,
                upload_date: moment.unix(file.created_at).format('DD/MM/YYYY'),
                upload_by: contactName,
                context_visible: file.context_visible,
            }
            ret.data.push(elem);
        }
        return ret
    }

    getExtension = (file_name) => {

        const file_parts = file_name.split(/\.(?=[^\.]+$)/)
        let ext = "PDF";
        switch (file_parts[file_parts.length - 1].toLowerCase()) {
            case "jpeg":
            case "jpg":
                ext = "JPG"; break;
            case "png":
                ext = "PNG"; break;
            case "docx":
                ext = "DOC"; break;
            case "doc":
                ext = "DOC"; break;
            case "msg":
                ext = "MSG"; break;
            case "txt":
                ext = "TXT"; break;
            case "ppt":
                ext = "PPT"; break;
            case "pptx":
                ext = "PPT"; break;
            case "xls":
                ext = "XLS"; break;
            case "xlsx":
                ext = "XLSX"; break;
            default:
                ext = "PDF"; break;
        }
        return ext
    }

    openUploadModal = (e) => {
        e.preventDefault();
        this.setState({ isModalOpen: true })
    }
    handleModalClose = () => {
        this.setState({ isModalOpen: false })
    }
    filesTobeAdded = (files) => {
        console.log('files', files);
        this.setState({ binFiles: files });
    }

    uploadEmpAttachment = (e) => {
        e.preventDefault();
        this.myRef.current.calluploader();
    }

    closeHeaderContextMenu = (e) => {
        e.preventDefault();
        this.setState({ header_context_visible: false });
    }

    handleSave = async (e) => {
        this.callUploadApi();
    }

    callUploadApi = async () => {
        let files = this.state.binFiles
        files = files.map(function (entry) {
            return { ...entry, is_new: 0 };
        });
        this.setState({ binFiles: files })

        await this.uploadApi.command(files, this.returnTempFilesInfo, this.updateStatus);
    }

    returnTempFilesInfo = async (result) => {
        let alert_param = null;
        let emp_id = this.props.selected_employee_id;
        let retFiles = JSON.parse(result);
        let binFiles = this.state.binFiles;
        const sldata = JSON.parse(localStorage.getItem("site_layout"));
        let logged_in_user = sldata["contact_id"];

        const newArray = retFiles.map(function (item) {
            let name = item.inserted_file_name;
            const index = binFiles.findIndex(item => item.name === name);
            const { bin_file, ...newItem } = index !== -1 ? binFiles[index] : {};
            newItem.user_id = emp_id
            return {
                emp_id: emp_id,
                logged_in_user: logged_in_user,
                file_name: item.inserted_file_name,
                ref_id: item.ref_id,
                file_content: JSON.stringify(newItem)
            };
        });

        const payload = { command: "personnel", action: 'insert_employee_files', employee_id: emp_id, files: newArray }
        const api = new APICall();
        let files_response = await api.commandWithoutCallback(payload);
        let db_emp_files = files_response.result;

        for (let i = 0; i < db_emp_files.length; i++) {
            db_emp_files[i].context_visible = false
        }

        if (files_response.error_code == 0) {
            alert_param = { title: 'Success', message: "Files inserted sucessfully", ok_text: 'OK', confirm: false, alertHandler: this.confirmHandler }
        } else {
            alert_param = { title: 'Error', message: "Problem occured when inserting files", ok_text: 'OK', confirm: false, alertHandler: this.confirmHandler }
        }
        this.setState({ alert_param: alert_param, isModalOpen: false, show_db_files: db_emp_files, binFiles: [] });
    };

    confirmHandler = () => {
        this.setState({ alert_param: null });
    }

    updateStatus = (status) => {
        // debugger;
        const { loaded, total } = status;
        console.log('status ==>', status);
        let percentLoaded = Math.round((loaded / total) * 100);
        //console.log('percentLoaded ===>', percentLoaded);
        // if (percentLoaded > 0 && percentLoaded <= 99) {
        //     //show progress bar
        //     this.setState({ ...this.state, isShowProgressBar: true, percentLoaded: percentLoaded });
        // } else {
        //     //hide progress bar
        //     this.setState({ ...this.state, isShowProgressBar: false, percentLoaded: percentLoaded });
        // }
    }

    render() {
        const overrideStyle = {
            position: 'absolute',
            backgroundColor: '#f3f0f0',
            padding: '5px 5px',
            display: 'inline-block',
            width: '150px',
            right: '7px',
            boxShadow: '0px 0px 4px rgba(0,0,0,0.4)',
            zIndex: 10000,
            boxSizing: 'border-box',
        };
        let question_attachments = [];
        return (
            <div className="employee-file-container">
                <div className="mc-table-header-container" style={{ backgroundColor: "#fff" }}>
                    <div style={{ fontWeight: "600", fontSize: "15px", paddingLeft: "15px" }}>Files</div>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <FontAwesomeIcon className="mc-roles-add-icon" icon={regular('ellipsis-vertical')} onClick={this.openCloseFilesContexMenu} />
                        {
                            (() => {
                                if (this.state.header_context_visible === true) return (
                                    <div style={overrideStyle} onMouseLeave={this.closeHeaderContextMenu}>
                                        <div className="table-context-menu-item" onClick={(e) => this.openUploadModal(e)}>UploadFiles</div>
                                    </div>
                                )
                            })()
                        }
                    </div>
                </div>
                <div className="mc-table-container">
                    <CSLTable processData={this.processEmployeeFiles} />
                    {
                        this.state.loading && <SmallSpinner />
                    }
                </div>
                <div className="modal-wrapper">
                    {this.state.isModalOpen && (
                        <div className="modal-popup">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h2>Upload Attachment</h2>
                                    <button onClick={this.handleModalClose}>X</button>
                                </div>
                                <div className="modal-body">
                                    <BulkModalEmployee
                                        ref={this.myRef}
                                        filesTobeAdded={this.filesTobeAdded}
                                        previousFiles={question_attachments}
                                        employee_id={this.state.selected_employee_id}
                                        filesToBeRemoved={this.filesToBeRemoved}
                                    />
                                </div>
                                <div className="modal-footer">
                                    <button className="topic-attcahment-upload-button" onClick={(e) => this.uploadEmpAttachment(e)}>
                                        Add Files
                                    </button>
                                    {this.state.binFiles.length > 0 &&
                                        <button className="save-button" onClick={(e) => this.handleSave(e)}>Save</button>
                                    }
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <AlertBox alertParam={this.state.alert_param} />
            </div>
        )
    }
}

export default Files
