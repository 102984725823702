import React from 'react';
import styled from 'styled-components'
import RespoEventBusNew from './RespoEventBusNew'
// import RespoEventBusNew from '../../Common/RespoEventBusNew.js'


const lineBorderWidth = 5;

const boxTrueWidth = 267;
// const boxHeight = boxTrueHeight - (2 * boxBorderWidth);
// const boxWidth = boxTrueWidth - (2 * boxBorderWidth);
const horzGap = boxTrueWidth * 2 / 3;
const vertGap = 40;
const initialHorzGap = 20;

const RespoLineWrapper = styled.div`
							display: block;
							position: absolute;
							border-color: #1BB2B2;
							background-color: transparent;
							border-bottom-width:${lineBorderWidth}px;
							border-left-width:${lineBorderWidth}px;
							border-left-style: solid;
							border-bottom-style: solid;
							border-bottom-left-radius: 10px;
							`;
const PartRespoLineWrapperZero = styled.div`
							display: block;
							position: absolute;
							border-color: #1BB2B2;
							background-color: transparent;
							border-left-width:${lineBorderWidth}px;
							border-left-style: dashed;
							`;
const PartRespoLineWrapperOne = styled.div`
							display: block;
							position: absolute;
							border-color: #1BB2B2;
							background-color: transparent;
							border-bottom-width:${lineBorderWidth}px;
							border-left-width:${lineBorderWidth}px;
							border-left-style: solid;
							border-bottom-style: solid;
							`;
const PartRespoLineWrapperTwo = styled.div`
							display: block;
							position: absolute;
							border-color: #1BB2B2;
							background-color: transparent;
							border-left-width:${lineBorderWidth}px;
							border-left-style: solid;
							`;
const PartRespoLineWrapperThree = styled.div`
							display: block;
							position: absolute;
							border-color: #1BB2B2;
							background-color: transparent;
							border-left-width:${lineBorderWidth}px;
							border-left-style: dashed;
							`;
// border-bottom-left-radius: 10px;

class RespoLine extends React.Component {
	// constructor(props) {
	//     super(props);
	// }
	render() {
		// ////console.log.log('this.props.boxcount', this.props.numberOfBoxes);
		// return <RespoLineWrapper style={{height:this.props.numberOfBoxes * 150}}/>;
		let fullMap = RespoEventBusNew.getStoreData('full_map');
		let mapXOffset = fullMap ? 0 : 140;
		let mapYOffset = fullMap ? 0 : 200;
		let top = this.props.item.top;
		// let bot = this.props.item.bot;
		let mid = this.props.item.mid;
		let level = this.props.item.level;
		// let thisBoxHeight = this.props.item.boxHeight;
		if (level === 1) {
			//console.log.log('top in RespoLine', top);
			//console.log.log('mid in RespoLine', mid);
		}

		if (level <= 1) {
			if (fullMap) {
				return <div></div>;
			}
			return <div>
				<PartRespoLineWrapperZero style={{
					width: `${(horzGap / 2) - lineBorderWidth}px`,
					height: `${mapYOffset / 4}px`,
					position: 'absolute',
					top: `${mapYOffset + vertGap - (mapYOffset / 4)}px`,
					left: `${(mapXOffset / 4) + initialHorzGap}px`
				}}></PartRespoLineWrapperZero>
				<PartRespoLineWrapperOne style={{
					width: `${(horzGap / 2) - lineBorderWidth}px`,
					height: `${mapYOffset / 4}px`,
					position: 'absolute',
					top: `${mapYOffset + vertGap}px`,
					left: `${(mapXOffset / 4) + initialHorzGap}px`
				}}></PartRespoLineWrapperOne>
				<PartRespoLineWrapperTwo style={{
					width: `${(horzGap / 2) - lineBorderWidth}px`,
					height: `${mapYOffset / 4}px`,
					position: 'absolute',
					top: `${mapYOffset + vertGap + (mapYOffset / 4)}px`,
					left: `${(mapXOffset / 4) + initialHorzGap}px`
				}}></PartRespoLineWrapperTwo>
				<PartRespoLineWrapperThree style={{
					width: `${(horzGap / 2) - lineBorderWidth}px`,
					height: `${mapYOffset / 4}px`,
					position: 'absolute',
					top: `${mapYOffset + vertGap + 2 * (mapYOffset / 4)}px`,
					left: `${(mapXOffset / 4) + initialHorzGap}px`
				}}></PartRespoLineWrapperThree>
			</div>
		}
		return <RespoLineWrapper style={{
			width: `${(horzGap / 2) - lineBorderWidth}px`,
			height: `${mid - top}px`,
			position: 'absolute',
			top: `${mapYOffset + top}px`,
			left: `${mapXOffset + (horzGap * (level - 2)) + (horzGap / 2)}px`
		}}></RespoLineWrapper>
	}

}

export default RespoLine;
