const cached_data_ids = {}

class Store {

    constructor() {
        this.storeData = {};
        this.callBacks = {};
    }
    
    // Call this function to update store data. The data_id identifies the data 
    // and you can use this from any component to access this piece of data
    updateStore = (data_id, state, component_id=null, no_updates=false) => {
        let store_data = JSON.parse(JSON.stringify(state));
        this.storeData[data_id] = store_data
        if(data_id in cached_data_ids) {
            try {
              localStorage.setItem(data_id, JSON.stringify(store_data))
            } catch (err) {
              let loggedin_contact_id = localStorage.getItem('loggedin_contact_id');
              localStorage.clear();
              localStorage.setItem('isemptylocalstore', true)
              localStorage.setItem('loggedin_contact_id', loggedin_contact_id)
            }
        }

        if(no_updates === true) return;
        if(data_id in this.callBacks){
            // for(var i=0; i<this.callBacks[data_id].length; i++) {
            //     this.callBacks[data_id][i]();
            // }
            for (var key in this.callBacks[data_id]) {
                if(component_id === null || key !== component_id)this.callBacks[data_id][key]();
            }
        }
    }
    getStoreAllData = () =>{
        return JSON.parse(JSON.stringify(this.storeData));
    }
    // Usually called from the callback function to update the component state 
    // using the store data and forcing re-render in case data has changed

    getStoreData = (data_id) => {
        if(data_id in cached_data_ids) {
            let ret = localStorage.getItem(data_id)
            if(ret !== null) {
                ret = JSON.parse(ret);
            }
            return ret
        }
        if(data_id in this.storeData) return JSON.parse(JSON.stringify(this.storeData[data_id]));
        else return null;
    }
     getAllStoreData = () => {
        return JSON.parse(JSON.stringify(this.storeData));
        
    }
}

export default new Store();