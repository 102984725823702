import React from "react";
import './MConfig.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import ClipLoader from "react-spinners/ClipLoader"
import General from "./General"
import Permissions from "./Permissions";
import RoleMaintenance from "./RoleMaintenance";
import TopicOverviewModal from "./TopicOverviewModal";
import AssessmentsOverview from "./Assessments/AssessmentsOverview";
import CertSetup from "./CertSetup";
import Reporting from "./Reporting";
import APICall from "../../Common/APICall";
import EventBus from "../../Common/EventBus"
import Emails from "./Emails";
import Store from "../../Common/Store";
import DefaultHeader from "../../Common/DefaultHeader";

class ModuleConfigHOC extends React.Component {

    state = {
        active_tab: "general",
        topics: [],
        fetching_topics: false,
        assessments: [],
        fetching_assessments: false,
        selected_assessment_id: 0,
        gen_id: 0
    }

    componentDidMount() {
        console.log('component did mount called');
        this.checkGroupCompanies();
        EventBus.registerEvent('refresh_assessments', 'ModuleConfigHOC', this.refreshAssessments)
    }



    checkGroupCompanies = async () => {
        let api = new APICall()
        let payload = { command: "qsets", action: "has_group_companies" }
        let result = await api.commandWithoutCallback(payload)
        let has_gc = JSON.parse(result.result.has_gc.has_group_company);
        localStorage.setItem('has_gc', has_gc);
    }

    goToTab = (tab) => (event) => {
        event.preventDefault()
        this.setState({ active_tab: tab, topics: [], assessments: [], fetching_assessments: false, fetching_topics: false, add_assessment: false })
    }

    // arrayToObj = async (list, key_id) => {
    //     let obj = {}
    //     for (let item of list) {
    //         obj[item[key_id].toString()] = item
    //     }
    //     return obj
    // }

    refreshAssessments = (assessments) => {
        this.setState({ assessments, add_assessment: false, fetching_assessments: false })
    }

    unSetAddAssessmentFlag = () => {
        this.setState({ add_assessment: false })
    }

    listItems = (entity) => async (event) => {
        event.preventDefault()
        let payload = null
        let result = null
        let api = new APICall()
        if (entity === "topics") {
            console.log("event:", event.target.tagName)
            if (event.target.tagName !== "SPAN") {
                this.setState({ fetching_topics: true, fetching_assessments: false, active_tab: entity })
                // payload = {command: "topics_api", action: "list_topics"}
                payload = { command: "qsets", action: "list_topics_dashboard" }
                result = await api.commandWithoutCallback(payload)
                let topics = []
                if (result.error_code === 0) {
                    if (result.result.length !== 0) {
                        topics = result.result
                        topics = await this.makeAllDeselected(JSON.parse(JSON.stringify(topics)))
                    }
                }
                this.setState({ active_tab: entity, topics, assessments: [], fetching_topics: false })
            }
        }
        if (entity === "assessments") {
            //debugger
            if (event.target.tagName !== "SPAN") {
                this.setState({ fetching_topics: false, fetching_assessments: true, active_tab: entity, topics: [], gen_id: Date.now() })
                // this.setState({fetching_topics: false, fetching_assessments: true})
                // payload = {command: "topics_api", action: "list_assessments"}
                // result = await api.commandWithoutCallback(payload)
                // let assessments = []
                // if (result.error_code === 0) assessments = result.result
                // assessments = await this.makeAllDeselected(JSON.parse(JSON.stringify(assessments)))
                // this.setState({active_tab: entity, topics: [], assessments, fetching_assessments: false})
            }
        }
    }

    toggleEntityItem = (entity, item_id) => (event) => {
        event.preventDefault()
        let items = JSON.parse(JSON.stringify(this.state[entity]))
        for (let i = 0; i < items.length; i++) {
            if (items[i].id === item_id) {
                items[i].selected = true
            } else {
                items[i].selected = false
            }
        }
        this.setState({ [entity]: items })
        if (entity === "assessments") this.setState({ selected_assessment_id: item_id })
    }

    switchTopic = (topic_id) => {
        if (topic_id === 0) {
            this.addTopicCommon()
        } else {
            let topics = JSON.parse(JSON.stringify(this.state.topics))
            for (let i = 0; i < topics.length; i++) {
                if (topics[i].id === topic_id) {
                    topics[i].selected = true
                } else {
                    topics[i].selected = false
                }
            }
            this.setState({ topics })
        }
    }

    makeAllDeselected = async (list) => {
        if (list.length !== 0) {
            for (let i = 0; i < list.length; i++) {
                list[i].selected = false
                list[i].context_visible = false
            }
        }
        return list
    }

    addTopic = (event) => {
        event.preventDefault()
        console.log("addTopic")
        this.addTopicCommon()
    }

    addAssessment = (event) => {
        event.preventDefault()
        this.setState({ add_assessment: true })
    }

    addTopicCommon = async () => {
        const payload = { command: "topics_api", action: "create_topic" }
        const api = new APICall()
        const result = await api.commandWithoutCallback(payload)
        if (result.error_code === 0) {
            const topic = { id: result.result, name: "Untitled Topic", description: 'Add topic description here...', topic_json: "", is_active: 1, selected: true, can_modify: 1, is_deleted: 0 }
            let topics = JSON.parse(JSON.stringify(this.state.topics))
            for (let i = 0; i < topics.length; i++) {
                if (topics[i].selected === true) {
                    topics[i].selected = false
                    break
                }
            }
            topics.push(topic)
            this.setState({ topics })
        }
    }

    closeView = async () => {
        // let topics = JSON.parse(JSON.stringify(this.state.topics))
        // for (let i = 0; i < topics.length; i++) {
        //     if (topics[i].selected === true) {
        //         topics[i].selected = false
        //         break
        //     }
        // }
        // this.setState({ topics })
        const api = new APICall()
        let payload = { command: "qsets", action: "list_topics_dashboard" }
        let result = await api.commandWithoutCallback(payload)
        let topics = []
        if (result.error_code === 0) {
            if (result.result.length !== 0) {
                topics = result.result
                topics = await this.makeAllDeselected(JSON.parse(JSON.stringify(topics)))
            }
        }
        this.setState({ topics })
    }

    editTopic = async (entity, value, entity_id) => {
        const payload = { command: "topics_api", action: "update_topic", entity, value, topic_id: entity_id }
        const api = new APICall()
        const result = await api.commandWithoutCallback(payload)
        if (result.error_code === 0) {
            let topics = JSON.parse(JSON.stringify(this.state.topics))
            if (entity === "name") {
                for (let i = 0; i < topics.length; i++) {
                    if (topics[i].id === entity_id) {
                        topics[i].name = value
                    }
                }
            }
            else if (entity === "description") {
                for (let i = 0; i < topics.length; i++) {
                    if (topics[i].id === entity_id) {
                        topics[i].description = value
                    }
                }
            }
            else if (entity === "delete") {
                if (topics.length > 0) {
                    const indexToDelete = topics.findIndex(obj => obj.id === entity_id);
                    if (indexToDelete !== -1) {
                        topics.splice(indexToDelete, 1);
                    }
                }
            }
            else {
                for (let i = 0; i < topics.length; i++) {
                    if (topics[i].id === entity_id) {
                        topics[i].is_active = value
                    }
                }
            }
            this.setState({ topics })
        }
    }

    // change assessment name
    changeNick = (modObj) => {
        const newAssessments = this.state.assessments.map(assessment => {
            if (assessment.id === modObj.id) {
                return { ...assessment, name: modObj.name };
            } else {
                return assessment;
            }
        });
        this.setState({ assessments: newAssessments });
    }


    render() {
        if (Store.getStoreData("role") !== "admin_manager") {
            return (
                <div style={{ padding: "0px 30px 30px 30px", marginTop: "20px" }}>
                    <div className="mcc-alert error">
                        <div style={{ fontWeight: "600" }}>You are not authorized to access this page.</div>
                    </div>
                </div>
            )
        }
        return (
            <div>
                <DefaultHeader pageName="Module Configuration" />
                <div className="mc-container">
                    <div className="mc-left-pane-container">
                        <div className="mc-tabs-container">
                            <div className="mc-tab" onClick={this.goToTab('general')}>
                                <div style={{ fontWeight: this.state.active_tab === "general" ? "600" : "400" }}>General</div>
                                <div style={{ color: "#39b3ad", boxSizing: "border-box" }}><FontAwesomeIcon icon={solid('chevron-right')} /></div>
                            </div>
                            <div className="mc-tab" onClick={this.goToTab('emails')}>
                                <div style={{ fontWeight: this.state.active_tab === "emails" ? "600" : "400" }}>Emails</div>
                                <div style={{ color: "#39b3ad", boxSizing: "border-box" }}><FontAwesomeIcon icon={solid('chevron-right')} /></div>
                            </div>
                            {/*<div className="mc-tab" onClick={this.goToTab('permissions')}>
                                <div style={{ fontWeight: this.state.active_tab === "permissions" ? "600" : "400" }}>Permissions</div>
                                <div style={{ color: "#39b3ad", boxSizing: "border-box" }}><FontAwesomeIcon icon={solid('chevron-right')} /></div>
                            </div>*/}
                            <div className="mc-tab" onClick={this.listItems('topics')}>
                                <div style={{ fontWeight: this.state.active_tab === "topics" ? "600" : "400", width: "50%" }}>Topics</div>
                                <div style={{ color: "#39b3ad", boxSizing: "border-box" }}><FontAwesomeIcon icon={solid('chevron-right')} /></div>
                            </div>
                            <div className="mc-tab" onClick={this.listItems('assessments')}>
                                <div style={{ fontWeight: this.state.active_tab === "assessments" ? "600" : "400", width: "50%" }}>Assessments</div>
                                <div style={{ color: "#39b3ad", boxSizing: "border-box" }}><FontAwesomeIcon icon={solid('chevron-right')} /></div>
                            </div>
                            <div className="mc-tab" onClick={this.goToTab('roles')}>
                                <div style={{ fontWeight: this.state.active_tab === "roles" ? "600" : "400" }}>Role Maintenance</div>
                                <div style={{ color: "#39b3ad", boxSizing: "border-box" }}><FontAwesomeIcon icon={solid('chevron-right')} /></div>
                            </div>
                            <div className="mc-tab" onClick={this.goToTab('cert_setup')}>
                                <div style={{ fontWeight: this.state.active_tab === "cert_setup" ? "600" : "400" }}>Certificate set-up</div>
                                <div style={{ color: "#39b3ad", boxSizing: "border-box" }}><FontAwesomeIcon icon={solid('chevron-right')} /></div>
                            </div>
                            <div className="mc-tab" onClick={this.goToTab('reporting')}>
                                <div style={{ fontWeight: this.state.active_tab === "reporting" ? "600" : "400" }}>Reporting</div>
                                <div style={{ color: "#39b3ad", boxSizing: "border-box" }}><FontAwesomeIcon icon={solid('chevron-right')} /></div>
                            </div>
                        </div>
                    </div>
                    <div className="mc-right-pane-container">
                        {this.state.active_tab === 'general' && <General />}
                        {this.state.active_tab === 'emails' && <Emails />}
                        {this.state.active_tab === 'roles' && <RoleMaintenance />}
                        {/*{this.state.active_tab === 'permissions' && <Permissions />}*/}
                        {
                            this.state.active_tab === 'topics' &&
                            <TopicOverviewModal
                                topics={this.state.topics}
                                switchTopic={this.switchTopic}
                                closeView={this.closeView}
                                editTopic={this.editTopic}
                            />
                        }
                        {
                            this.state.active_tab === 'assessments' &&
                            <AssessmentsOverview
                                selected_assessment_id={this.state.selected_assessment_id}
                                add_assessment={this.state.add_assessment}
                                unSetAddAssessmentFlag={this.unSetAddAssessmentFlag}
                                changeNick={this.changeNick}
                                key={this.state.gen_id}
                            />
                        }
                        {this.state.active_tab === 'cert_setup' && <CertSetup />}
                        {this.state.active_tab === 'reporting' && <Reporting />}
                    </div>
                </div>
            </div>
        )
    }
}

export default ModuleConfigHOC
