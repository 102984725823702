import React from 'react';
import Store from '../Common/Store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, thin, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { faHouseBlank, faGear, faSquareList, faGroupArrowsRotate, faMessagesQuestion, faSitemap, faChartUser, faFolderTree } from "@fortawesome/pro-thin-svg-icons";
import "./DefaultHeader.css"

class DefaultHeader extends React.Component {
    redirectTo = (location) => (event) => {
        event.preventDefault();
        console.log(window.location.hostname);
        let url = "";
        switch (location) {
            case "home":
                url = "/"
                break;
            case "accept-roles":
                url = "/accept-roles"
                break;
            case "moduleconfig":
                url = "/moduleconfig"
                break;
            case "rolesresponsibility":
                url = "/rolesresponsibility"
                break;
            case "assessment-dashboard":
                url = "/assessment-dashboard"
                break;
            case "respomap":
                url = "/respomap"
                break;
            case "sor":
                url = "/sor"
                break;
            default:
                url = "/"
        }
        window.location.assign(url);
    }

    render() {
        let use_site_layout = false
        if (localStorage.getItem('site_layout') !== null) {
            let site_layout = JSON.parse(localStorage.getItem('site_layout'));
            use_site_layout = site_layout.result.use_site_layout;
        }
        if (use_site_layout) return (<div></div>)
        const role = Store.getStoreData("role")
        return (
            <div className='head-container'>
                <div className='head-container-left-pane'>
                    {
                        (() => {
                            if (window.location.pathname.includes("/moduleconfig") && role === "admin_manager")
                                return <div className='main-icon-container'><FontAwesomeIcon icon={faGear} /></div>
                            // else if(window.location.pathname.includes("/accept-roles"))
                            //     return <div className='main-icon-container'><FontAwesomeIcon icon={solid('user-check')}/></div>
                            else if (window.location.pathname.includes("/rolesresponsibility"))
                                return <div className='main-icon-container'><FontAwesomeIcon icon={faGroupArrowsRotate} /></div>
                            else if (window.location.pathname.includes("/assessment-dashboard"))
                                return <div className='main-icon-container'><FontAwesomeIcon icon={faMessagesQuestion} /></div>
                            else if (window.location.pathname.includes("/respomap") && role === "admin_manager")
                                return <div className='main-icon-container'><FontAwesomeIcon icon={faSitemap} /></div>
                            else if (window.location.pathname.includes("/sor"))
                                return <div className='main-icon-container'><FontAwesomeIcon icon={faChartUser} /></div>
                            else
                                return <div className='main-icon-container'><FontAwesomeIcon icon={faHouseBlank} /></div>
                        })()
                    }
                    {/* <div className='main-icon-container'><FontAwesomeIcon icon={solid('house')}/></div> */}
                    <span className='head-container-app-name' onClick={this.redirectTo("home")} style={{cursor: 'pointer'}}> My Compliance Centre / SMCR /</span>
                    <span className='head-container-page-name'>{this.props.pageName}</span>
                </div>
                <div className='head-container-right-pane'>
                    {
                        (window.location.pathname.includes("/moduleconfig") === true || window.location.pathname.includes("/accept-roles") === true || window.location.pathname.includes("/rolesresponsibility") === true || window.location.pathname.includes("/assessment-dashboard") === true) &&
                        <div className='icon-container' title='SMCR Home' onClick={this.redirectTo("home")}><FontAwesomeIcon icon={faHouseBlank} /></div>
                    }
                    {
                        window.location.pathname.includes("/moduleconfig") === false && role === "admin_manager" &&
                        <div className='icon-container' title='Module Configuration' onClick={this.redirectTo("moduleconfig")}><FontAwesomeIcon icon={faGear} /></div>
                    }
                    {
                        window.location.pathname.includes("/rolesresponsibility") === false && role !== "team" &&
                        <div className='icon-container' title='Roles & Responsibility' onClick={this.redirectTo("rolesresponsibility")}><FontAwesomeIcon icon={faGroupArrowsRotate} /></div>
                    }
                    {
                        window.location.pathname.includes("/assessment-dashboard") === false &&
                        <div className='icon-container' title='Assessment Dashboard' onClick={this.redirectTo("assessment-dashboard")}><FontAwesomeIcon icon={faMessagesQuestion} /></div>
                    }
                    {
                        window.location.pathname.includes("/respomap") === false && role === "admin_manager" &&
                        <div className='icon-container' title='Responsibilities Map' onClick={this.redirectTo("respomap")}><FontAwesomeIcon icon={faSitemap} /></div>
                    }
                    {
                        window.location.pathname.includes("/sor") === false &&
                        <div className='icon-container' title='Statement of Responsibilities' onClick={this.redirectTo("sor")}><FontAwesomeIcon icon={faChartUser} /></div>
                    }
                </div>
            </div>
        );
    }
}

export default DefaultHeader;
