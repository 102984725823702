import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import APICall from "../../../Common/APICall";
import CSLLoader from "../../Common/CSLLoader";
import styled from "styled-components";

const ArrowUp = styled.div `
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 8px solid #555;
`

const ArrowDn = styled.div `
    width: 0;
	height: 0;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 8px solid #555;
`

class EditRoleModal extends React.Component {

    state = { role_obj: {}, selected_role_code_no: 1, alert_param: null };

	async componentDidMount() {
		console.log("componentDidMount function called in EditRoleModal")
		let role_obj = {}
		if (this.props.role_id === 0) {
			role_obj.title = ""
			role_obj.regulator_definition = ""
			role_obj.firm_definition = ""
			role_obj.comment = ""
			role_obj.is_predefined = 0
			role_obj.is_active = 1
			this.setState({role_obj})
		} else {
			let payload = { command: "get_mc_role", role_id: this.props.role_id }
			let api = new APICall()
			const result = await api.commandWithoutCallback(payload)
			console.log("result:", result)
			let role_code_arr = result.result.role_code.split("#")
			let selected_role_code_no = parseInt(role_code_arr[role_code_arr.length - 1])
			let role_json = JSON.parse(result.result.role_json)
			role_obj.title = role_json.title
			role_obj.regulator_definition = role_json.regulator_definition
			role_obj.firm_definition = role_json.firm_definition
			role_obj.comment = role_json.comment
			role_obj.is_predefined = result.result.is_predefined
			role_obj.is_active = result.result.is_active
			this.setState({role_obj, selected_role_code_no})
		}
	}

    handleChange = (event) => {
        event.preventDefault()
        let role_obj = JSON.parse(JSON.stringify(this.state.role_obj))
        role_obj[event.target.name] = event.target.value
        this.setState({role_obj})
    }

    setRoleNumeric = (direction) => (e) => {
        e.preventDefault();
        let cur_selected_role_code_no = this.state.selected_role_code_no;
        let new_selected_role_code_no = 0;
        if(direction === 'up'){
            new_selected_role_code_no = cur_selected_role_code_no + 1;
            while(this.props.role_numbers.includes(new_selected_role_code_no) === true){
                new_selected_role_code_no++;  //deciding next available code number
            }
        }
        else if(direction === 'down'){
            // debugger;
            let temp = cur_selected_role_code_no - 1;
            while(this.props.role_numbers.includes(temp) === true && temp > 0){
                temp--;
            }
            new_selected_role_code_no = temp !== 0 ? temp : cur_selected_role_code_no;  //deciding previous available code number
        }
        this.setState({ selected_role_code_no : new_selected_role_code_no });
    }

    closeModal = (event) => {
        event.preventDefault()
        this.props.closeModal()
    }

    upsertRole = (event) => {
        event.preventDefault()
        let role_obj = JSON.parse(JSON.stringify(this.state.role_obj))
        role_obj.selected_role_code_no = this.state.selected_role_code_no
        this.props.upsertRole(role_obj)
    }

    render () {

        // if (Object.keys(this.state.role_obj).length === 0) {
		// 	return (<CSLLoader />);
		// }

        return (
            <div className="add-role-modal-container" style={{top: "0%", width: "100%"}}>
                {Object.keys(this.state.role_obj).length === 0 && <CSLLoader />}
                <div className="add-role-modal-header-container">
                    <div className="add-role-modal-header-text-container">
                        <div className="add-role-modal-header-title">{ this.props.role_id === 0 ? "Add Role" : "Update Role" }</div>
                        <div className="add-role-modal-header-subtitle">Use this section to add/edit a role of a role type.</div>
                    </div>
                    <FontAwesomeIcon icon={solid('xmark')} onClick={this.closeModal} size="2x" style={{cursor: "pointer"}} />
                </div>
                <div className="add-role-modal-body-container">
                    <div>
                        <div className="add-role-modal-label">Role Type Label</div>
                        <input type="text" className="add-role-modal-input" value={this.props.role_type.name} readOnly disabled />
                    </div>
                    <div>
                        <div className="add-role-modal-label">Role</div>
                        <div style={{ display : "flex", flexDirection : "row", justifyContent : "flex-start", alignItems : "center", border : "1px solid #ddd", borderRadius : "5px", padding : "5px"}}>
                            <span style={{ fontSize: "16px", fontWeight: "700", padding : "2px", color : "#777"}}>
                                {this.props.role_type.base + "#"}
                            </span>
                            <span style={{ fontSize: "16px", padding : "2px", textAlign : "center", backgroundColor : "#fff"}}>
                                {this.state.selected_role_code_no.toString().padStart(2,'0')}
                            </span>
                            <div style={{display : "flex", flexDirection : "row"}}>
								<div style={{ position: "relative", display : "flex", flexDirection : "column", alignItems : "center", rowGap : "3px", marginLeft : "12px"}}>
									<ArrowUp style={{ cursor : "pointer" }} onClick={this.setRoleNumeric('up')} />
									<ArrowDn style={{ cursor : "pointer" }} onClick={this.setRoleNumeric('down')} />
                                    {/* <FontAwesomeIcon icon={solid('caret-up')} onClick={this.setRoleNumeric('up')} size="2x" style={{cursor: "pointer"}} />
                                    <FontAwesomeIcon icon={solid('caret-down')} onClick={this.setRoleNumeric('down')} size="2x" style={{cursor: "pointer"}} /> */}
								</div>
								<div style={{ fontSize : "12px", fontStyle : "italic", marginLeft : "30px"}}>*Showing only available role codes</div>
							</div>
                        </div>
                    </div>
                    <div>
                        <div className="add-role-modal-label">Title</div>
                        <input type="text" name="title" className="add-role-modal-input" value={this.state.role_obj.title} onChange={this.handleChange} />
                    </div>
                    <div>
                        <div className="add-role-modal-label">Regulator Definition</div>
                        <textarea rows={6} className="add-role-modal-textarea" name="regulator_definition" value={this.state.role_obj.regulator_definition} onChange={this.handleChange} />
                    </div>
                    <div>
                        <div className="add-role-modal-label">Firm Definition</div>
                        <textarea rows={6} className="add-role-modal-textarea" name="firm_definition" value={this.state.role_obj.firm_definition} onChange={this.handleChange} />
                    </div>
                </div>
                <div className="add-role-modal-footer-container">
                    <button className="add-role-modal-cancel-button" onClick={this.closeModal}>Cancel</button>
                    <button className="add-role-modal-save-button" onClick={this.upsertRole}>Save</button>
                </div>
            </div>
        )
    }
}

export default EditRoleModal