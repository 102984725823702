import React from 'react';
import styled from 'styled-components';
import Store from './../../../Common/Store';
import Committee from './Committee.js';

const HeaderDiv = styled.div`
    padding:9px;
    background-color: white;
`;

const HeaderText = styled.div`
    padding:12px 24px 12px 24px;
    margin-bottom: 10px;
    color: #212121;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 22px;
    color: #4D4D68;
`;


class GovmapReport extends React.Component {
	state = {
		ready: false,
		gov_committees: null,
		members: null,
		show_role_desc: false,
		root_user_id: '0',
		max_level: 100000
	}

	constructor(props) {
		super(props);
		this.initBBHt = 80
		this.bHeaderHt = 60
		this.bWidth = 550
		this.hGap = 100
		this.vGap = 30
		this.currentY = 0
		this.rolesPerLine = 6
		this.lineHeight = 15
		this.maxLevel = 0
	}

	setComponentState = () => {
		let gov_committees = Store.getStoreData('gov_committees')
		let members = Store.getStoreData('members')
		this.currentY = 0
		let index = 0;
		if(gov_committees !== null) {
			for(let gm of gov_committees.children) {
				this.measureGovmap(gm, 0, null, index)
				index++;
			}
		}
		
		console.log('committees gov_committees', gov_committees)
		console.log('committees members', members)
		// console.log('after measureRespomap respomap', respomap)
		// Store.updateStore('respomap', respomap)
		this.setState({ready:true, gov_committees: gov_committees})
	}

	componentDidMount = () => {
		this.setComponentState()
	}

	componentDidUpdate = (prevprops) => {
		if(this.props === prevprops)return
		this.setComponentState()
	}

	measureGovmap = (committee, level, prev_r, index) => {
		console.log('committee, level, prev_r', committee, level, prev_r)
		this.maxLevel = level > this.maxLevel ? level : this.maxLevel
		committee.name = committee.json_data.committeename
		committee.purpose = committee.json_data.perposeofcommitee
		committee.members = committee.json_data.committeemembers
		committee.alt_members = committee.json_data.thirdpartyembers
		committee.level = level
		// committee.y = prev_r === null ? 0 : this.currentY
		committee.y = (prev_r === null && index === 0 ) ? 0 : this.currentY;
		console.log('depcommittee.y, this.current_y, this.vGap', committee.y, this.currentY, this.vGap )
		let alt_length = 0
		for(let m of committee.alt_members) {
			if(m.thirdpartyembername !== '')alt_length++
		}
		committee.prev_y = prev_r === null ? 0 : prev_r.y
		committee.prev_height = prev_r === null ? 0 : prev_r.box_height
		committee.box_height = this.props.showRoles ? this.initBBHt + 4 + this.lineHeight * (committee.members.length + alt_length) : this.initBBHt
		// committee.box_height += 20
		this.currentY += committee.box_height
		for(let c of committee.children) {
			this.measureGovmap(c, level+1, committee)
		}
		
	}

	mapFindById = (find_id, respomap) => {
        for(let m of respomap) {
        	if(find_id === m.id)return m
            let found_member = this.mapFindById(find_id, m.children);
            if(found_member !== null) return found_member;
        }
        return null;
    }

	render() {
		console.log('GovmapReport this.state in render', this.state)
		if(!this.state.ready)return (<div></div>)
		if(this.state.gov_committees === null)return (<div></div>)
		console.log('this.state.gov_committees.children', this.state.gov_committees.children)
		return (<div>
					<HeaderDiv>
						<HeaderText>{this.props.showRoles ? 'Committees and Governance Structure (Advanced)' : 'Committees and Governance Structure'}</HeaderText>
						<div style={{position: 'relative', height: this.currentY, marginLeft: 12}}>
						{
							this.state.gov_committees.children.map((item, i) => {
								return <Committee 
									key={i} 
									committee={item}
									horizontalGap={this.hGap}
			                        showRoles={this.props.showRoles}
								/>
							})
						}
						</div>
					</HeaderDiv>
				</div>
			)
	}
}

export default GovmapReport;