import React from 'react';
import Store from './Common/Store.js';
import APICall from './Common/APICall.js';
import Utils from './Common/Utils.js';
import AlertBox from './Components/Common/AlertBox';
import CSLLoader from './Components/Common/CSLLoader';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Index from './Components/Index.js';
import RolesAndResponsibility from './Components/RolesAndResponsibility/RolesAndResponsibility.js'
import AcceptRoles from './Components/RolesAndResponsibility/AcceptRoles.js';
import ModuleConfigHOC from './Components/Moduleconfig/ModuleConfigHOC';
import AssessmentOverview from './Components/Assessment/AssessmentOverview.js';
import RespoDashBoard from './Components/Respomap/RespoDashBoard.js';
import AssessmentHOC from './Components/Assessment/AssessmentHOC.js';
import StatementOfResponsibility from './Components/Reports/Sor/StatementOfResponsibility.js'
import authService from 'mcc-front-aux/dist/authService'
import siteLayout from 'mcc-front-aux/dist/siteLayout'

import "./App.css"
import Reload from './Common/Reload.js';
import Demopdf from './Components/Certifications/demopdf.js';
import SmcrReportIndex from './Components/SmcrReport/SmcrReportIndex.js'
import EventBus from './Common/EventBus.js';

const moment = require('moment');

class AppContent extends React.Component {

	state = { ready: false, alert_param: null };

	constructor (props) {
		super(props);
		Utils.setEnv('smcr');
		Store.updateStore('module_name', 'smcr');
	}

	async componentDidMount() {
		let api = new APICall();
		const cts = JSON.parse(localStorage.getItem("contactlist"));
		const sldata = JSON.parse(localStorage.getItem("site_layout"));
        console.log("sldata:", sldata);

		let company = {};
		company["CompanyName"] = sldata.result.company_name;
		let user_roles = cts.result.user_roles["smcr"];
		let role = sldata.contact_id.toString() in cts.result.user_roles["smcr"] ? cts.result.user_roles.smcr[sldata.contact_id.toString()] : "no_access";
		Store.updateStore('role', role)
        //console.log("role:", role);

		if (role === "no_access") {
			let alert_param = {
				title: 'No Access', message: 'You are not authorised to access this module. If you think this is an error, please contact your administrator.', ok_text: 'Ok', confirm: false,
				alertHandler: this.alertHandler, stack: { id: 0 }
			}
			this.setState({ ready: true, alert_param })
		}


		const postData = {command: "get_mc_general"}
		const result = await api.commandWithoutCallback(postData)
		// console.log("result:", result)
		let config_json = JSON.parse(JSON.stringify(result.result.config_json))
		let access_reporting = config_json.access_reporting
		let url_permissions = config_json.url_permissions
		Store.updateStore("access_reporting", access_reporting)
		Store.updateStore("url_permissions", url_permissions)

		//raiseEvent Start
		let menu = {};
		if(access_reporting && role !== "no_access" && url_permissions[role].respomap === 1){
			menu['34respomap543'] = {"id":"34respomap543","display":"Responsibilities Map","url":"microservice/mstop/smcr/respomap","order":5,"menu":"respomap", source: "smcr"}
		}
		//raiseEvent End
		console.log("menu===>", menu)
		EventBus.raiseEvent('menu_render', menu)


		if (role !== "no_access") {
			let path = window.location.pathname
			if (path.includes("/rolesresponsibility") === true) {
				await this.initializeStore();
				this.setState({ ready: true });
			}
			else if (path.includes("/moduleconfig") || path.includes("/assessment-dashboard")) {
				await this.initializeStore();
				this.setState({ ready: true });
			}else if(path.includes("/respomap")){
				await this.initializeStore();
				// this.getGeneral()
				this.setState({ ready: true });
			} else {

				await this.initializeStore();
				this.myUsers(role)
			}
		}
	}

	alertHandler = (result, stack) => {
		Reload.ReloadPage()
	}

	getGeneral = async () => {
		const api = new APICall()
		const postData = {command: "get_mc_general"}
		const result = await api.commandWithoutCallback(postData)
		// console.log("result:", result)
		let config_json = JSON.parse(JSON.stringify(result.result.config_json))
		let access_reporting = config_json.access_reporting
		let url_permissions = config_json.url_permissions
		Store.updateStore("access_reporting", access_reporting)
		Store.updateStore("url_permissions", url_permissions)
		this.setState({ ready: true })
	}

	myUsers = async (userRole) => {
		// role can be Team , Admin manager , HR Manager , Line manager
		const sldata = JSON.parse(localStorage.getItem("site_layout"));

		let payload = { command: "personnel", action: "list_regime_users" }
		switch (userRole) {
			case "team":
				let logged_in_contact_id = sldata.contact_id;

				localStorage.setItem("users", JSON.stringify([logged_in_contact_id]));
				Store.updateStore("regime_users", [logged_in_contact_id])
				break;
			case "hr":
			case "admin_manager":
				payload.role = userRole
				const api = new APICall()
				const resp = await api.commandWithoutCallback(payload)
				let regime_users = []
				if (resp.error_code === 0) {
					regime_users = resp.result
				}
				Store.updateStore("regime_users", regime_users)
				break;
			default:

		}
		this.setState({ ready: true })
	}

	processIndexRolesResponsibility = (result) => {
		if (result.error_code === 0) {
			let company_id = result.result.company.ClientNumber;
			let company = result.result.company;
			Store.updateStore('company', company);
			Store.updateStore('company_id', company_id);
			Store.updateStore('role_list', result.result['role_list']);
			Store.updateStore('role_type_list', result.result['role_type_list']);
			Store.updateStore('mc_general', result.result.mc_general)
			this.initializeStore();

			this.setState({ ready: true });

		} else {
			let alert_param = {
				title: 'System Error', message: 'Please contact your manager or Compliancy Services customer support on: Tel: 01462 510022 or at regtech@compliancy-services.co.uk.', ok_text: 'Ok', confirm: false,
				alertHandler: this.alertHandler, stack: { id: 0 }
			}
			this.setState({ ready: true, alert_param: alert_param });
			return
		}
	}

	initializeStore = async () => {
		const cts = JSON.parse(localStorage.getItem("contactlist"));
		const sldata = JSON.parse(localStorage.getItem("site_layout"));
		const company_name = sldata.result.company_name;
		let user_roles = cts.result.user_roles["smcr"];
		let role = sldata.contact_id.toString() in cts.result.user_roles["smcr"] ? cts.result.user_roles.smcr[sldata.contact_id.toString()] : "no_access";
		let contact = {};
		contact["ID"] = sldata.contact_id;
		contact["ContactName"] = sldata.result.contact_name;
		let listcontacts = [];
		for (let key in cts.result.contactlist)
			if (cts.result.contactlist[key].IsDeleted != true) listcontacts.push(cts.result.contactlist[key]);
		//let company_id = result.result.company.ClientNumber;
		let contact_name = sldata.result.contact_name;
		let logged_in_contact_id = sldata.contact_id;
		let contacts_all = {};
		let arrMgr = [];
		let arrModulAdmin = [];
		let arrHr = [];
		let arrSystemAdmin = [];
		let arrTeam = [];
		let arrRegimeSetup = [];
		let contactsemail_all = {};
		for (let key in cts.result.user_roles["smcr"]) {
			if (cts.result.contactlist[key] !== undefined && cts.result.contactlist[key].IsDeleted !== true) contactsemail_all[key] = cts.result.contactlist[key].EmailAddress;
			if (cts.result.user_roles["smcr"][key] !== 'no_access') {

				if (cts.result.contactlist[key] !== undefined) {
					contacts_all[key] = cts.result.contactlist[key].ContactName;
					switch (cts.result.user_roles["smcr"][key]) {
						case 'hr':
							arrHr.push({ ID: key, ContactName: cts.result.contactlist[key].ContactName });
							break;
						case 'system_admin':
							arrSystemAdmin.push({ ID: key, ContactName: cts.result.contactlist[key].ContactName });
							break;
						case 'module_admin':
							arrModulAdmin.push({ ID: key, ContactName: cts.result.contactlist[key].ContactName });
							break;
						case 'regime_setup':
							arrRegimeSetup.push({ ID: key, ContactName: cts.result.contactlist[key].ContactName });
							break;
						case 'team':
							arrTeam.push({ ID: key, ContactName: cts.result.contactlist[key].ContactName });
							break;
						case 'manager':
							arrMgr.push({ ID: key, ContactName: cts.result.contactlist[key].ContactName });
							break;
					}
				}
			}
		}


		Store.updateStore('role', role);
		Store.updateStore('company_name', company_name);
		Store.updateStore('contact', contact);
		Store.updateStore('user_roles', user_roles);
		Store.updateStore('listcontacts', listcontacts);
		Store.updateStore('contactName', contact_name);
		Store.updateStore('logged_in_contact_id', logged_in_contact_id);
		//Store.updateStore('userroles', user_roles);
		Store.updateStore('contacts', contacts_all);
		Store.updateStore('contactsemail', contactsemail_all);
		Store.updateStore('reviewee_list', listcontacts);
		Store.updateStore('get_managers', arrMgr);
		Store.updateStore('get_teams', arrTeam);
		Store.updateStore('get_hr', arrHr);
		Store.updateStore('get_system_admin', arrSystemAdmin);
		Store.updateStore('get_regime_setup', arrRegimeSetup);
		Store.updateStore('get_module_admin', arrModulAdmin);
		Store.updateStore('company_id', authService.user.company_id)
	}



	render() {

		if (!this.state.ready) {
			return <div><CSLLoader /></div>
		}

		if (this.state.alert_param !== null) {
			return <AlertBox alertParam={this.state.alert_param} />
		}

		return (
			<Router>
				<Switch>
					<Route path="/accept-roles" exact><AcceptRoles /></Route>
					<Route path="/moduleconfig" exact><ModuleConfigHOC /></Route>
					<Route path="/rolesresponsibility" exact><RolesAndResponsibility /></Route>
					<Route path="/assessment-dashboard" exact><AssessmentOverview /></Route>
					<Route path="/respomap" exact><RespoDashBoard /></Route>
					<Route path="/sor" exact><StatementOfResponsibility /></Route>
					<Route path="/demopdf" exact><Demopdf /></Route>
					<Route
						path="/assessment-details" exact
						render={({ location }) => {
							const searchParams = new URLSearchParams(location.search);
							const assignmentId = searchParams.get('assignment_id');
							return (
								<AssessmentHOC assignmentId={assignmentId} />
							);
						}}
					/>
					<Route path="/" exact><Index /></Route>
					<Route path="/:taskId" exact component={Index} />
				</Switch>
			</Router >
		);

	}

}

class App extends React.Component {
	state = { ready: false }

	setComponentState = async () => {
		let api_url = process.env.REACT_APP_API_URL;
		Store.updateStore('api_url', api_url)

		await authService.refresh()
		await siteLayout()
		this.setState({ready: true})
	}

	componentDidMount = () => {
		this.setComponentState()
	}

	componentDidUpdate = (prevprops) => {
		if (this.props === prevprops) return
		this.setComponentState()
	}

	render() {
		if (!this.state.ready) {
			return <div>Loading...</div>
		}
		// let appContent = <AppContent />
		// return <ErrorBoundary><SiteLayout AppContent={appContent} /></ErrorBoundary>

		return <AppContent />
	}
}


export default App
