import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import SorContext from "./SorContext";
import './sor.css'


class PersonnelFilter extends Component {
    constructor (props) {
        super(props);
        this.state = {
            employeeList: [],
            selectedCount: 0
        };
    }

    static contextType = SorContext;

    componentDidMount() {
        this.setComponentState();
    }


    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setComponentState();
        }
    }

    selectedEmployees = (e, Id) => {
        e.preventDefault();
        const { employeeList } = this.state;
        const allObjectSelected = employeeList.every(item => item.selected === 1);

        const updatedData = employeeList.map(item => {
            if (Id === 'all') {
                if (allObjectSelected) {
                    return { ...item, selected: 0 };
                } else {
                    return { ...item, selected: 1 };
                }
            } else {
                if (item.ID === Id) {
                    return { ...item, selected: item.selected === 0 ? 1 : 0 };
                }
                return item;
            }
        });

        this.setState({
            employeeList: updatedData,
            selectedCount: updatedData.filter(item => item.selected === 1).length
        });
    };



    setComponentState = () => {
        let e = [];
        const { employee_data, selected_report_parameter } = this.context;
        let sfilter = selected_report_parameter.firstSection.personalFilter;
        const extractedData = employee_data.map(item => ({ ID: item.ID, EmployeeName: item.EmployeeName }));
        if (extractedData !== null && extractedData.length > 0) {
            if (sfilter.value == 'all') {
                e = extractedData.map(item => ({ ID: item.ID, EmployeeName: item.EmployeeName, selected: 1 }))
            } else {
                e = extractedData.map(item => ({
                    ID: item.ID,
                    EmployeeName: item.EmployeeName,
                    selected: sfilter.value.includes(item.ID) ? 1 : 0
                }))
            }
        }

        // sort employee by name.
        e = e.sort((a, b) => {
            const nameA = a.EmployeeName.toUpperCase();
            const nameB = b.EmployeeName.toUpperCase();

            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });

        this.setState({
            employeeList: e,
            selectedCount: e.filter(item => item.selected === 1).length
        });
    }

    closePersonnelFilter = () => {
        const { updateFilterParameter } = this.context;
        let employees = this.state.employeeList;
        let obj = {
            type: 'personalFilter',
            secondaryText: "",
            value: ""
        }
        if ((employees.length == this.state.selectedCount)) {
            obj.secondaryText = 'All Personnel';
            obj.value = 'all'
        } else {
            if (this.state.selectedCount != 0) {
                const selectedObjects = employees.filter(item => item.selected == 1);
                let names = "", ids = "";
                selectedObjects.forEach((e) => {
                    names += e.EmployeeName + ','
                    ids += e.ID + ','
                })
                names = names.slice(0, -1);
                ids = ids.slice(0, -1);

                obj.secondaryText = names;
                obj.value = ids
            } else {
                obj.secondaryText = 'All Personnel';
                obj.value = ''
            }
        }

        updateFilterParameter(obj)

        this.props.closeFilter();
    }


    render() {
        return (
            <div className='personnel-container'>
                <div class="search-container">
                    <input type="text" class="search-input" placeholder="Search list" />
                    <span class="search-icon-border">
                        <FontAwesomeIcon title="Action" className='search-icon'
                            icon={solid('magnifying-glass')} />
                    </span>
                </div>
                <span class="select-smr-popover-selected" id="spanSelectCount">Show selected
                    ({this.state.selectedCount} selected)</span>
                <div className='personnel-employee-container'>
                    <div className='personnel-employees'>
                        <ul>
                            <li className='personnel-li' onClick={(e) => this.selectedEmployees(e, 'all')}>
                                {this.state.selectedCount == this.state.employeeList.length && <FontAwesomeIcon ktitle="Action" className='personnel-check-icon'
                                    icon={solid('check')} />} All personnel</li>
                            {this.state.employeeList.map((item, index) => (
                                <React.Fragment>
                                    <li className='personnel-li' key={index} onClick={(e) => this.selectedEmployees(e, item.ID)}>
                                        {item.selected == 1 && <FontAwesomeIcon ktitle="Action" className='personnel-check-icon'
                                            icon={solid('check')}>
                                        </FontAwesomeIcon>}{item.EmployeeName}
                                    </li>
                                </React.Fragment>
                            ))}
                        </ul>
                    </div>
                    <button className='personnel-employee-done' onClick={this.closePersonnelFilter}>Done</button>
                </div>
            </div >
        )
    }
}

export default PersonnelFilter
