import Store from '../../Common/Store.js';
import http from 'mcc-front-aux/dist/httpCommon';

class UploadFile {
    constructor () {
        this.base_api_url = Store.getStoreData('base_api_url');
        this.contact_id = Store.getStoreData('logged_in_contact_id');
        this.company_id = Store.getStoreData('company_id')
    }

    command(postData, callback, uploadCallback = null) {
        let url = this.base_api_url + "/uploaddata"

        let jsonDataString = JSON.stringify(postData);
        const payload = JSON.parse(jsonDataString);
        console.log('jsonDataString ==>', payload);
        const data = new FormData();

        const progressList = [];

        for (var i = 0; i < payload.length; i++) {
            data.append(`model[${i}].name`, payload[i].name);
            data.append(`model[${i}].bin_file`, payload[i].bin_file);
            data.append(`model[${i}].company_id`, this.company_id);
            data.append(`model[${i}].contact_id`, this.contact_id);

            const fileProgress = {
                name: payload[i].name,
                uid: payload[i].uid,
                percentCompleted: 0
            };
            progressList.push(fileProgress);
        }

        var config = {
            method: 'post',
            url: url,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data,
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                const percentCompleted = Math.round((loaded * 100) / total);
                console.log(`Percent completed: ${percentCompleted}%`);

                const fileIndex = progressList.findIndex((item) => item.percentCompleted === 0);
                if (fileIndex !== -1) {
                    progressList[fileIndex].percentCompleted = percentCompleted;
                    progressList[fileIndex].total = total;
                    progressList[fileIndex].loaded = loaded;
                    console.log('progressList', progressList)
                    if (uploadCallback !== null) {
                        uploadCallback(progressList);
                    }
                }
            },
            mcc_debug: false 
        };

        http.request(config)
            .then(function (response)  {
                callback(response.data);
            })
            .catch(function (error) {
                callback(error);
            });
    }

}

export default UploadFile;
