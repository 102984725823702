import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import EditEmployeeModal from "./EditEmployeeModal"
import APICall from "../../Common/APICall";
import Store from "../../Common/Store";
import SmallSpinner from "../Common/SmallSpinner";

class BasicInfo extends React.Component {

    state = {
        context_visible: false,
        show_edit_info_modal: false
    }

    state = { employee: { row_id: 0, jobTitleId: '0', joinedDate: '', appointedDate: '', comments: '', description: '', jobTitleText: '' }, job_titles: [], alert_param: null, loading: false };

    async componentDidMount() {
        // console.log("setComponentState fired from componentDidMount in Basic info")
        await this.setComponentState()
    }
    async componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
            // console.log("this.props:", this.props)
            // console.log("prevProps:", prevProps)
            // console.log("setComponentState fired from componentDidUpdate in Basic info")
            await this.setComponentState()
        }
    }

    setComponentState = async () => {
        this.setState({ loading: true })
        const payload1 = { command: "personnel", action: 'list_jobtitles' }
        const payload2 = { command: "personnel", action: 'fetch_employee_jobtitle_id', employee_id: this.props.employee_id }
        const payload3 = { command: "personnel", action: 'fetch_employee_header_data', employee_id: this.props.employee_id }

        const api = new APICall()
        if (this.props.employee_id !== 0) {
            const [job_titles_response, employee_job_title_resp, employee_details_response] = await Promise.all([
                api.commandWithoutCallback(payload1),
                api.commandWithoutCallback(payload2),
                api.commandWithoutCallback(payload3),
            ]);
            //debugger;
            let job_titles = job_titles_response.result.job_titles;
            let employee_jobtitle_obj = employee_job_title_resp.result.job_title_id;
            let employee_details_obj = employee_details_response.result.employee_details;
    
            // console.log('employee_job_title_resp', employee_details_obj);

            let employee = this.state.employee;


            employee.jobTitleId = employee_jobtitle_obj.hasOwnProperty('jt_id') ? employee_jobtitle_obj['jt_id'] : 0;
            employee.row_id = employee_details_obj.row_id;
            employee.joinedDate = this.convertDDMMYYYY(employee_details_obj.joinedDate);
            employee.appointedDate = this.convertDDMMYYYY(employee_details_obj.appointedDate);
            employee.comments = employee_details_obj.comments;
            employee.description = employee_details_obj.description;
            //employee.jobTitleText = employee.jobTitleId > 0 ? job_titles.find(j => j["id"] == employee.jobTitleId)["name"] : "";
            employee.jobTitleText = "";
            if (employee.jobTitleId > 0) {
                const matchingJob = job_titles.find(j => j.id === employee.jobTitleId);
                if (matchingJob) {
                    employee.jobTitleText = matchingJob.name;
                }
            }
            this.setState({ employee, job_titles, loading: false })
        }
    }

    convertDDMMYYYY = (dateStr) => {
        const isoDate = dateStr;
        let formattedDate = '';
        if (isoDate) {
            const dateObj = new Date(isoDate);
            const day = String(dateObj.getDate()).padStart(2, '0');
            const month = String(dateObj.getMonth() + 1).padStart(2, '0');
            const year = dateObj.getFullYear();
            formattedDate = `${day}-${month}-${year}`;
        }
        return formattedDate;
    }

    toggleContextMenu = (event) => {
        event.preventDefault()
        this.setState({ context_visible: this.state.context_visible === true ? false : true })
    }

    showInfoEditModal = (event) => {
        event.preventDefault()
        this.setState({ show_edit_info_modal: true, context_visible: false })
    }

    closeModal = async () => {
        await this.setComponentState();
        this.setState({ show_edit_info_modal: false, context_visible: false })
    }

    render() {
        // console.log("Props in Basic info:", this.props)
        return (
            <div style={{ padding: "30px", position: "relative" }}>
                <div className="info-container">
                    <div className="info-sub-container">
                        <div className="info-item-container">
                            <div style={{ width: "8%", boxSizing: "border-box", fontWeight: "600" }}>Title:</div>
                            <div style={{ width: "92%", boxSizing: "border-box", fontWeight: "500" }}>{this.state.employee.jobTitleText}</div>
                        </div>
                        <div className="info-item-container">
                            <div style={{ width: "8%", boxSizing: "border-box", fontWeight: "600" }}>Joined:</div>
                            <div style={{ width: "92%", boxSizing: "border-box", display: "flex" }}>
                                <div style={{ fontWeight: "500" }}>{this.state.employee.joinedDate}</div>
                                <div style={{ marginLeft: "8%", fontWeight: "600" }}>Appointed:</div>
                                <div style={{ marginLeft: "4%", fontWeight: "500" }}>{this.state.employee.appointedDate}</div>
                            </div>
                        </div>
                        <div className="info-item-container">
                            <div style={{ width: "8%", boxSizing: "border-box", fontWeight: "600" }}>Description:</div>
                            <div style={{ width: "92%", boxSizing: "border-box", fontWeight: "500" }}>
                                <span style={{
                                    width: '90%', wordBreak: 'break-all', overflowWrap: 'break-word'
                                }}>{this.state.employee.description}</span>
                            </div>
                        </div>
                        <div className="info-item-container">
                            <div style={{ width: "8%", boxSizing: "border-box", fontWeight: "600" }}>Comments:</div>
                            <div style={{ width: "92%", boxSizing: "border-box", fontWeight: "500" }}>
                                <span style={{
                                    width: '90%', wordBreak: 'break-all', overflowWrap: 'break-word'
                                }}>{this.state.employee.comments}</span>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "2%", boxSizing: "border-box", display: "flex", flexDirection: "row-reverse" }}>
                        {
                            Store.getStoreData('role') !== "team" &&
                            <>
                                <FontAwesomeIcon className="mc-roles-add-icon" icon={regular('ellipsis-vertical')} onClick={this.toggleContextMenu} />
                                {
                                    this.state.context_visible &&
                                    <div className="context-menu" style={{ right: "58px" }} onMouseLeave={this.toggleContextMenu}>
                                        <div className="context-menu-item" onClick={this.showInfoEditModal}>Edit</div>
                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>
                {this.state.show_edit_info_modal && <EditEmployeeModal employee_id={this.props.employee_id} closeModal={this.closeModal} />}
                {
                    this.state.loading && <SmallSpinner />
                }
            </div >
        )
    }


}

export default BasicInfo
