import React from 'react';
import styled from 'styled-components';
import APICall from '../../Common/APICall';
import Cookies from 'universal-cookie';
import moment from 'moment';
import Store from '../../Common/Store';
import CSLLoader from '../Common/CSLLoader';
import GovmapReport from './GovernanceMap/GovmapReport.js';
import WelcomeBanner from "./../../Common/WelcomeBanner";
import GovmapTable from './GovernanceMap/GovmapTable.js';
import { FaFilter, FaPrint } from 'react-icons/fa';
import SideBar from './SideBar/SideBar.js';
import PdfGenerator from './PDF/PdfGenerator.js';

const Categories = styled.div`
    float: right;
    font-size: 17px;
    background-color: #ebebeb;
    color: #551A8B;
    width: 25px;
    height: 25px;
    margin-left: 5px;
    margin-top: 4px;
    padding-left: 4px;
    padding-top: 3px;
    border-radius: 2px;
    cursor: pointer;
    margin-right: 10px;
`;
const InactiveOverlay = styled.div`
    display: block;
    box-sizing: border-box;
    height: calc(100vh-200px);
    width: 100%;
    background-color: #ffffff;
    position: absolute;
    z-index: 1000;
    opacity: 0.8;
    top: 0px;
`;

class SmcrReportIndex extends React.Component {
    state = {
		ready: false,
        com_gov: true,
        com_gov_map: true,		
        com_gov_reg:false,
        com_gov_tab: true,
        sidebar: false,
        pdf: false,
        smrtable: false,
        amtable: false,
        amtable_na: false,
	};

    constructor (props) {
		super(props);		
	}

    componentDidMount() {
        console.log("Sourav")

        const cookies = new Cookies();
        let newState = cookies.get('sidebar_cookie')
        console.log("sidebar_cookie", newState);
        if (typeof newState !== "undefined") {
            newState['reportStartDate'] = new Date(newState.reportStartDate)
            newState['pdf'] = false    
        }		
        let reportStartDate = typeof  newState === "undefined" ?  moment().format('DD/MM/YYYY') : newState.reportStartDate
        let postData = { command: "smrc_report", action: 'report_index', 'respomap_effective_date': reportStartDate};	
        console.log("postData===>", postData)	
        let api = new APICall();
		api.command(postData, this.processIndex);
	}

    processIndex = (result) => {
        console.log("SMCR_Report_Index ===>", result)   
        
        let reporting_tables = {};
        reporting_tables.smr_table = result.result['smr_table'];        
        reporting_tables.am_table = result.result['am_table'];        
        reporting_tables.nasmf_table = result.result['nasmf_table'];        
        reporting_tables.accepted_table = result.result['accepted_table'];
        reporting_tables.assigned_table = result.result['assigned_table'];        
        Store.updateStore('reporting_tables', reporting_tables);
        Store.updateStore('respomap_table', result.result.respomap);
        Store.updateStore('respomap_committees', result.result.committees);

        let governance = result.result.governance
        for(let g of governance.committees) {
            g.parent_id = g.json_data.parent_id === '' ? '0' : g.json_data.parent_id
            g.id = g.id
        }
        
        let gov_committees = this.convertListToTree(governance.committees)
        console.log('result governance_table', governance)
        console.log('result committees', gov_committees)
        Store.updateStore('gov_committees', gov_committees) //use
        Store.updateStore('governance_table', governance);
        Store.updateStore('members', governance.employee_hash) //use

        this.setState({ready: true})
    }

    convertListToTree(list) {
		var companyIDs = {};
		list.forEach(function(x) {
			companyIDs[x['id']] = 1;
		});
	    var treeList = [];
	    var lookup = {};
	    list.forEach(function(obj) {
	        if(!(obj['parent_id'] in companyIDs)) {
	        	obj['parent_id'] = 0;
	        }
	        lookup[obj['id']] = obj;
	        obj['children'] = [];
	    });
	    list.forEach(function(obj) {
	        if (obj['parent_id'] !== 0) {
	        	lookup[obj['parent_id']]['children'].push(obj);
	        } else {
	            treeList.push(obj);
	        }
	    });

		var retTree = {"children" : treeList, "id" : 0 };
	    return retTree;
	}

    closeSidebar = (val) => {
        console.log('Index closeSidebar')
        // this.saveCookie()         
        this.setState({sidebar:false})
    }

    toggleSideBar = () =>  {
        // this.saveCookie()
        this.setState({sidebar: true,pdf: false})
    }

    saveCookie = (event) => {
        const cookies = new Cookies();
        cookies.set('sidebar_cookie', this.state, { path: '/' });
    }

    updateGeneral = (val) => {    	
        console.log('updateGeneral',val);
        this.setState(val);
    }

    updateDate = (val) => {        
        console.log('date',val);
		let reportStartDate = moment(val.reportStartDate).format('DD/MM/YYYY');
		console.log('date',reportStartDate);
        //this.setState(val);  
        let postData = { command: "smrc_report", action: 'report_index', 'respomap_effective_date': reportStartDate};	
        console.log("postData===>", postData)		
		let api = new APICall();
		api.command(postData, this.processIndex);
    }

    togglePdf = () =>  {
        let status = this.state.pdf ? false : true;
        let sidebar = status ? false : this.state.sidebar;
        console.log('pdf status', status)
        this.setState({pdf: status, sidebar:sidebar});        
    }

    render() {
        if (Store.getStoreData("access_reporting") === true && Store.getStoreData("role") !== 'no_access' && Store.getStoreData("url_permissions")[Store.getStoreData("role")].respomap === 0) {
            return (
                <div style={{ padding: "0px 30px 30px 30px", marginTop: "20px" }}>
                    <div className="mcc-alert error">
                        <div style={{ fontWeight: "600" }}>You are not authorized to access this page.</div>
                    </div>
                </div>
            )
        }
        if (!this.state.ready) {
			return <div><CSLLoader /></div>
		}
        return(<div style={{boxSizing: "border-box", padding: "8px 5px 8px 5px"}}>
            <WelcomeBanner welcome={`Welcome back, ` + Store.getStoreData('contact').ContactName} />

            <div>
                {
                    (() => {  
                        if(this.state.sidebar) return   <SideBar closeSidebar = {this.closeSidebar} updateGeneral={this.updateGeneral} updateDate = {this.updateDate} />
                    })()
                }
            </div>
            <div style={{width:'calc(100vw - 320px)', marginTop: "2px"}}>
                <Categories style={{marginRight: "15px", backgroundColor: 'white'}} title='Generate PDF'><FaPrint onClick={() => this.togglePdf()}/></Categories>
                {
                    (() => {
                        if(this.state.sidebar) {
                            return <Categories onClick={() => this.closeSidebar()} style={{marginRight: 1, backgroundColor: 'white'}} title='Report Runner'><FaFilter /></Categories>
                        } else {
                            return <Categories onClick={() => this.toggleSideBar()} style={{marginRight: 1, backgroundColor: 'white'}} title='Report Runner'><FaFilter /></Categories>
                        }
                    })()
                }                
            </div>
        
            <div style={{backgroundColor: "#f2f2f3", position: "fixed", left: 310, height: 'calc(100vh - 208px)', width: 'calc(100vw - 330px)', overflowY: 'scroll', marginTop: "35px"}}>
                <div>
                    {
                        (() => {  
                            if(this.state.com_gov_map && this.state.com_gov) return (<GovmapReport showRoles={this.state.com_gov_map ? this.state.com_gov_reg : false}/>)
                        })()
                    }
                    {
                        (() => {
                            if(this.state.com_gov_tab && this.state.com_gov) return (<GovmapTable />)
                        })()
                    }
                </div>
            </div>
            {
                (() => {
                    if (this.state.pdf) {
                        return (
                            <div style={{position:'fixed', top:'140px', width:'calc(100%-50px)', height: 'calc(100vh-180px)'}}>
                                <InactiveOverlay style={{zIndex: "1010"}} />

                                <PdfGenerator togglePdf={this.togglePdf} includes={this.state} />
                            </div>
                        );
                    }
                })()
            }
        </div>)
    }
}

export default SmcrReportIndex;