import React from 'react';
import './../Personnel/EmployeeFiles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro'

class ConfirmModal extends React.Component {
    render() {
        const {
            show,
            onHide,
            onConfirm,
            header,
            body,            
            showConfirm,            
            showCancel,
            showOk,
            confirmText,
            cancelText,
            okText,
            employeeName,
            showIsCheckEmail,
            isCheckedEmailMe,
            isCheckedEmailEmployee,
            onCheckEmilMe,
            onCheckEmilEmployee
        } = this.props;

        return (
            <div className={`custom-modal ${show ? 'show' : ''}`}>
                <div className="modal-overlay-blur" onClick={onHide}></div>
                <div className="confirm-modal">
                    <div className="modal-header2">
                        <div className="modal-title">
                            <span dangerouslySetInnerHTML={{ __html: header }}></span>
                            <span className="modal-close" onClick={onHide}>x</span>
                        </div>
                    </div>
                    <div className="modal-body2">
                        <div dangerouslySetInnerHTML={{ __html: body }}></div>
                        {showIsCheckEmail && (
                            <div style={{marginTop: "20px"}}>
                                {
                                    (() => {
                                        if(isCheckedEmailMe){
                                            return(<div style={{marginBottom: "10px"}}>
                                                        <FontAwesomeIcon style={{color: "#282828", marginRight: "10px"}} className="mc-roles-add-icon" title="Email me a copy" icon={regular('check-square')} onClick={onCheckEmilMe}/>
                                                        Email me a copy
                                                    </div>)
                                        }else{
                                            return(<div style={{marginBottom: "10px"}}>
                                                        <FontAwesomeIcon style={{color: "#282828", marginRight: "10px"}} className="mc-roles-add-icon" title="Email me a copy" icon={regular('square')} onClick={onCheckEmilMe}/>
                                                        Email me a copy
                                                    </div>)
                                        }
                                    })()
                                }
                                {
                                    (() => {
                                        if(isCheckedEmailEmployee){
                                            return(<div>
                                                <FontAwesomeIcon style={{color: "#282828", marginRight: "10px"}} className="mc-roles-add-icon" title="Email me a copy" icon={regular('check-square')} onClick={onCheckEmilEmployee}/>
                                                Email {employeeName} a copy
                                            </div>)
                                        }else{
                                            return(<div>
                                                        <FontAwesomeIcon style={{color: "#282828", marginRight: "10px"}} className="mc-roles-add-icon" title="Email me a copy" icon={regular('square')} onClick={onCheckEmilEmployee}/>
                                                        Email {employeeName} a copy
                                                    </div>)
                                        }
                                    })()
                                }
                                
                                
                            </div>
                        )}
                    </div>
                    <div className="modal-footer2">
                        {showCancel && (
                            <button className="modal-btn modal-cancel" onClick={onHide}>
                                {cancelText || 'Cancel'}
                            </button>
                        )}
                        {showConfirm && (
                            <button className="modal-btn modal-confirm" onClick={onConfirm}>
                                {confirmText || 'Confirm'}
                            </button>
                        )}

                        {showOk && (
                            <button className="modal-btn modal-confirm" onClick={onHide}>
                                {okText || 'Ok'}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default ConfirmModal;
