import React, { Component } from "react";
import styled, { css } from "styled-components";
import { FaTimes, FaCalendarAlt } from 'react-icons/fa';
import APICall from '../../Common/APICall.js';
import MultiColumnDropdown from "../Common/MultiColumnDropdown.js";
import Utils from "../../Common/Utils.js";
import CSLLoader from '../Common/CSLLoader';
import DatePicker from 'react-date-picker';
import moment from 'moment'
import AlertBox from '../Common/AlertBox';
import Store from "../../Common/Store.js";

moment.defaultFormat = "DD/MM/YYYY";


const ModalWraper = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  width: 50%;
  position: absolute;
  top: 38px;
  z-index: 1001;
  margin-left: 15%;
  background-color: #F5F9FD;
`;
const MRModalContainer = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  background-color: #F5F9FD;
  box-shadow: rgb(167, 166, 166) 0px 0px 5px;
`;
const MRModalHeader = styled.div`
  background-color: white;
  display: block;
  padding-top: 5px;
  font-size: 14px;
  height: 100px;
  width: 100%;
  box-sizing: border-box;
  border-width: 1px 1px 1px 10px;
  border-left: 9px solid #04ada8;
  border-bottom: 1px solid #dfdfdf;
`;

const MRModalBody = styled.div`
  position: relative;
  display: block;
  box-sizing: border-box;
  background-color: #f6f9fd;
  padding: 35px 35px 35px 35px;
  border-radius: 4px;
  // border: solid black;
`;
const HelpTextContainer = styled.div`
    margin-top: 8px;
    border: 2px solid #E5ECF0;
    padding: 28px;
    background: #edf3f7;
`;
const First = styled.div`
    font-family:sans-serif;
    font-size: 28px;
    font-weight: bold;
`;
const Second = styled.div`
    font-family: sans-serif;
    color: #6583a1;
    font-size: 15px;
    line-height: 21px;
`;
const MRModalHeaderCloseBtn = styled.div`
	position: absolute;
	top: 8px;
	right: 10px;
	box-sizing: border-box;
	margin-top: 24px;
	margin-right: 20px;
	font-size: 25px;
	cursor: pointer;
	font-weight: 200;
`;

const MRModalButton = styled.button`
	display: inline-block;
	box-sizing: border-box;
	color: #ffffff;
	width: 145px;
	height: 45px;
	border-radius: 3px;
	margin-left: 5px;
	font-size: 15px;
	margin-left: 10px;
	float: right;
	cursor: pointer;
`;

const MRModalNextBtn = styled(MRModalButton)`
	background-color: #0C6EE4;
	border: 1px solid #37ada7;
    cursor: pointer !important;
    width:180px;
     ${(props) =>
        props.isEnable == false
            ? css`
                    background-color: #cccccc;
                    cursor: not-allowed !important;
                    border: none;
                  `
            : css`
                    cursor: pointer !important;
                  `};
`;
const MRModalCancelBtn = styled(MRModalButton)`
	background-color: #ffffff;
	color: #545454;
	border: 2px solid #dadada;
	cursor: pointer !important;

`;

const MRModalFooter = styled.div`
	position: relative;
	padding: 17px;
	background-color: white;
	height: 80px;
	border-style: solid;
	border-width: 1px;
	border-color: #f3f7fb;
	border-top: 1px solid #dfdfdf;
`;

const ModalLabel = styled.div`
    color: #212121;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
`;

const CSLDateCover = styled.div`
    border-radius: 3px;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0 0 4px #c4c4c4;
    `;

const MRModalSelect = styled.select`
	box-sizing: border-box;
	height: 40px;
	width: 100%;
	background-color: #fff;
	border: 1px solid #ffffff;
	border-radius: 3px;
	box-shadow: 0 0 4px #c4c4c4;
	font-family: Montserrat, sans-serif;
	color: #5f6368;
`;


class ReAssessmentModal extends Component {
    initialState = {
        employees_data: [],
        selected_employee_id: 0,
        selected_assessment_id: this.props.assessment_id,
        filterDueDate: new Date(),
        isclear: true,
        alert_param: null,
        ready: false,
        comment: '',
        due_by_str: ''
    }

    state = {
        ...this.initialState
    }

    constructor (props) {
        super(props);
    }

    componentDidMount() {
        this.fetchEmployee(this.state.selected_assessment_id)
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.fetchEmployee(this.state.selected_assessment_id)
        }
    }
    changeClear = () => {
        this.setState({ isclear: false })
    }

    closeView = () => {
        this.props.closeModal();
    }

    selectedEmployeeId = (obj) => {
        let employee_id = 0;
        if (Object.keys(obj).length > 0) {
            const [key, value] = Object.entries(obj)[0];
            employee_id = key;
            this.setState({ selected_employee_id: key })
        } else {
            this.setState({ selected_employee_id: 0 })
        }
    }

    onRequestedDueDate = (date) => {
        let curmonth = parseInt(date.getMonth());
        let currmonth = curmonth + 1;
        var dateStr =
            currmonth >= 10
                ? date.getFullYear() + '/' + currmonth + '/' + date.getDate()
                : date.getFullYear() + '/0' + currmonth + '/' + date.getDate();
        this.setState({ due_by_str: dateStr, filterDueDate: date });
    }

    fetchEmployee = (assessment_id) => {
        if (assessment_id != 0) {
            this.setState({ ready: false })
            const postData = { command: "assessments_details", action: 'get_assessment_employees', assessment_id };
            
            const api = new APICall();
            api.command(postData, this.afterFetchEmployees);
        }

    }

    changeEmployee = (evt) => {
        let id = evt.target.value;
        this.setState({ selected_employee_id: id })
    }

    afterFetchEmployees = (result) => {
        let employees_data = [];
        const cts = JSON.parse(localStorage.getItem("contactlist"));
        let contacts = cts.result.contactlist;
        let employees = result.result.employees;


        for (let i = 0; i < employees.length; i++) {
            let emp_id = employees[i]["contact_id"];
            let name = contacts[emp_id]["ContactName"];

            employees_data.push({ 'contact_id': emp_id, 'name': name })
        }

        this.setState({ employees_data: employees_data, ready: true })
    }

    assignAssessmentSubmit = (e) => {
        e.preventDefault();
        //TODO: write code to re-assign
    }

    confirmHandler = () => {
        this.setState({ alert_param: null })
    }


    handleChange = (event) => {
        this.setState({ comment: event.target.value });
    }


    render() {

        if (!this.state.ready) {
            return (<div><CSLLoader /></div>);
        }
        return (
            <div>
                <ModalWraper>
                    <MRModalContainer>
                        <AlertBox alertParam={this.state.alert_param} />
                        <MRModalBody>
                            <MRModalHeaderCloseBtn onClick={this.closeView}>
                                <FaTimes />
                            </MRModalHeaderCloseBtn>
                            <First>Reassign Assessment</First>
                            <HelpTextContainer>
                                <Second>Select a user and optionally new Due Date to re-assign this Assessment.<br />Please also provide an explanatory comment</Second>
                            </HelpTextContainer>


                            <div style={{ height: '118px', position: 'relative' }}>
                                {(() => {
                                    if (this.state.selected_assessment_id > 0) {
                                        return (
                                            <React.Fragment>
                                                <ModalLabel>
                                                    Select User
                                                </ModalLabel>
                                                <span style={{ display: 'block' }}>Please select an employee to assign the assessment to</span>

                                                <div style={{ maxHeight: '100px' }}>

                                                    <MRModalSelect
                                                        name="field_select_employee"
                                                        value={this.state.selected_employee_id}
                                                        onChange={this.changeEmployee}
                                                    >
                                                        {this.state.employees_data.length > 0 && (
                                                            <option key={0} value={0}>
                                                                Select
                                                            </option>
                                                        )}
                                                        {this.state.employees_data.map((ab) => (
                                                            <option key={ab.contact_id} value={ab.contact_id}>
                                                                {ab.name}
                                                            </option>
                                                        ))}
                                                    </MRModalSelect>

                                                </div>
                                            </React.Fragment>
                                        )
                                    }
                                    return null;
                                })()}
                            </div>
                            <div id="dueDateDiv">
                                <ModalLabel>
                                    Due Date
                                </ModalLabel>

                                <CSLDateCover>
                                    <DatePicker
                                        onChange={this.onRequestedDueDate}
                                        clearIcon={null}
                                        calendarIcon={<FaCalendarAlt style={{ color: '#243E57', marginLeft: '500px' }} />}
                                        locale={'en-GB'}
                                        value={this.state.filterDueDate == null ? new Date() : this.state.filterDueDate}
                                    />
                                </CSLDateCover>
                            </div>

                            <ModalLabel>Comments</ModalLabel>
                            <textarea style={{ width: '99%', resize: 'none', border: '2px solid #EAEEF2' }} name="textarea" cols="30" rows="5"
                                placeholder='Add explanatory text or guidence notes. All recipients will see this.'
                                value={this.state.comment}
                                onChange={this.handleChange}
                            ></textarea>

                        </MRModalBody>
                        <MRModalFooter>
                            <MRModalNextBtn
                                isEnable={this.state.selected_assessment_id > 0 && this.state.selected_employee_id > 0}
                                onClick={this.assignAssessmentSubmit}
                                id="submit"
                            >
                                Assign Assessment
                            </MRModalNextBtn>

                            <MRModalCancelBtn onClick={() => this.closeView()}>Close</MRModalCancelBtn>

                        </MRModalFooter>

                    </MRModalContainer>

                </ModalWraper>
            </div>
        );
    }
}

export default ReAssessmentModal;
