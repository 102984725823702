import React from 'react';
import styled from 'styled-components';
import * as Style from '../Common/StyledComponents';
import AlertBox from '../Common/AlertBox.js';
import { FaTimes, FaPlus, FaMinus, FaLock, FaSitemap, FaPencilAlt, FaEllipsisV, FaUser, FaSearch, FaTrashAlt } from 'react-icons/fa';
import Store from '../../Common/Store.js';
import { FaRegCheckSquare, FaRegSquare } from "react-icons/fa";
import CSLTable from '../Common/CSLTable';
import DateRangeModal from './DateRangeModal';
import APICall from '../../Common/APICall';
import YesNoBox from '../Common/YesNoBox';
import EventBus from '../../Common/EventBus';
import CSLLoader from '../Common/CSLLoader';
import SpinningLoaderSmall from '../Common/SpinningLoaderSmall';

//import { uuid } from 'uuidv4';

//import { json } from 'stream/consumers';
let moment = require('moment');

const ModalBody = styled.div`
    position: relative;
    box-sizing: border-box;
    padding: 10px 60px 10px 60px;
`

const NotesDiv = styled.div`
    padding: 7px 31px 7px 31px;
    color: #919292;
    font-size: 11px;
`;

const CommentDiv = styled.div`
    padding: 7px 31px 7px 0px;
    color: #919292;
    font-size: 11px;
`;

const ModalInnerHeader = styled.div`
	background: #11ABA6;
    height: 30px;
    margin: 7px 0px 0px 0px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color:#FFFFFF;
    padding: 5px;
    box-sizing: border-box;
`;

const ModalInnerBody = styled.div`
	background: #FFFFFF;
    height: auto;
    margin: 0px 0px 7px 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 5px;
    box-sizing: border-box;
`
const ArrowUp = styled.div `
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 8px solid #555;
`

const ArrowDn = styled.div `
    width: 0;
	height: 0;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 8px solid #555;
`
const Circle = styled.div`
    height: 20px;
    width: 20px;
    background-color: #ffffff;
    border-radius: 50%;
    color: #11ABA6;
    font-size: 32px;
    float: right;
    text-align : center;
    line-height : 21px;
    padding : 0px 1px 1px 1px;
`

const SubMenu = styled.div`
    position : absolute;
    top : 0;
    right : 30px;
    width : 150px;
    padding : 5px 0px 5px 5px;
    background-color : #eeeeee;
    box-shadow : 1px 1px 2px #aaaaaa;
    z-index : 50000;
    height : 50px;
`

const SubMenuItem= styled.div`
    position : relative;
    display : block;
    cursor : pointer;
    padding : 5px;
    width : 90%
    color : #000;
    &:hover {
        background-color : #fff;
    }
`

const EllipsisIconDiv = styled.div`
    width : 20px;
    padding : 2px;
    border-radius : 3px;
    text-align : center;
    padding-top : 5px;
    &:hover {
        background-color : #ddd;
    }
`


class CreateRoles extends React.Component {

    state = {
                role_type_list : [],
                role_list : [],
                selected_role_type : {},
                role_type_options : [],
                listContacts : [],
                all_role_type_wise_role_code_numbers : {},
                selected_role_code_no : 0,
                role_info : {},
                show_date_modal : false,
                date_modal_data : {
                    id : "",
                    mode : "",
                    data : {}
                },
                alert_param: null,
                selected_check_box : "",
                show_na_submenu : false,
                show_shared_submenu : false,
                show_divided_submenu : false,
                selected_table_index : -1,
                submenu_for_subsection : null,
                selected_record_id : null,
                yes_no_box_alert_param : null,
                module_config_overlap_window : 20,
                edit_date_range_backup : {},
                module_config_ovr_window : Store.getStoreData('mc_general').respo_overlap_window,
                date_modal_bottom: "",
                show_spinner : false
            }

    constructor(props) {
        super(props);
        this.action = null;
    }

    componentDidMount() {
        // console.log("THIS PROPS > ",this.props);
		this.initialiseState();
	}

    componentDidUpdate(prevProps) {
        if(this.props !== prevProps){
            if(this.action === 'memsave'){
                this.action = null;
                this.setState({ role_info : this.role_info })
            }else if(this.action === 'dbsave'){
                this.action = null;
                this.setState({ role_info : this.role_info })
            }
        }
		    //this.initialiseState();
	}

	// componentDidUpdate(prevProps) {
	// 	if (prevProps !== this.props) {
	// 		this.initialiseState();
	// 	}
	// }

    componentWillUnmount() {
        this.props.sendRoleInfo(this.state.role_info);
    }

    initialiseState = () => {
        //console.log("Create Role 1");
        console.log("THIS PROPS > ",this.props);
        let role_type_options = [];
        let selected_role_type = {};
        let all_role_type_wise_role_code_numbers = {};
        let role_info = JSON.parse(JSON.stringify(this.props.role_info));
        let elem = {};
        let selected_role_code_no = 1;
        if(role_info.role_id !== 0 ) {
            elem = this.props.role_type_list.find(item => item.id === role_info.role_type_id);
            let arr = [];
            arr = (role_info.role_code).split("#")
            selected_role_code_no = parseInt(arr[arr.length - 1]);
            role_type_options.push({id : elem.id, name : elem.name, base : elem.base});
            selected_role_type = role_type_options[0];
        }
        else{
            this.props.role_type_list.forEach((item) =>{
                elem = {id : item.id, name : item.name, base : item.base}
                role_type_options.push(elem);
            })
            selected_role_type = role_type_options[0];
            this.props.role_type_list.forEach((item) => {
                let elem = {};
                elem = [];
                this.props.role_list.forEach((role) =>{
                    if(role.role_type_id === item.id){
                        let arr = role.role_code.split("#");
                        elem.push(parseInt(arr[arr.length - 1]));
                    }
                })
                all_role_type_wise_role_code_numbers[item.id]=elem;  //role type wise already engaged role_code numbers
            })
            selected_role_code_no = 1;
            while(all_role_type_wise_role_code_numbers[selected_role_type.id].includes(selected_role_code_no) === true){
                selected_role_code_no++;   //deciding first available role code no
            }
            role_info.role_type_id = selected_role_type.id;
            role_info.role_code = selected_role_type.base + "#" + selected_role_code_no.toString().padStart(2,'0');
        }

        console.log("all_role_type_wise_numbers", all_role_type_wise_role_code_numbers);
        console.log("selected role code no : ", selected_role_code_no);


        this.setState({ role_info, role_info_from_prop : JSON.parse(JSON.stringify(role_info)), role_list : this.props.role_list, role_type_list : this.props.role_type_list, listContacts : this.props.listContacts, role_type_options, selected_role_type, all_role_type_wise_role_code_numbers, selected_role_code_no })
        //console.log("Create Role 2");
    }

    makeid = (length) => {
		var result = '';
		var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() *
				charactersLength));
		}
		return result;
	}

    handleRoleTypeChange = (e) => {
        e.preventDefault();
        let selected_id = e.target.value;
        let selected_role_type = this.state.role_type_options.find((item) => item.id === parseInt(selected_id));
        let selected_role_code_no = 1;
        while(this.state.all_role_type_wise_role_code_numbers[selected_role_type.id].includes(selected_role_code_no) === true){
            selected_role_code_no++;    //deciding first available role code no for changed role type
        }
        let role_info = JSON.parse(JSON.stringify(this.state.role_info));
        role_info.role_type_id = selected_role_type.id;
        role_info.role_code = selected_role_type.base + "#" + selected_role_code_no.toString().padStart(2,'0');
        this.setState({ role_info, selected_role_type, selected_role_code_no });
        console.log("this.state.role_list",this.state.role_list)
    }

    setRoleNumaric = (up_down) => (e) => {
        e.preventDefault();
        let cur_selected_role_code_no = this.state.selected_role_code_no;
        let new_selected_role_code_no = 0;
        if(up_down === 'up'){
            new_selected_role_code_no = cur_selected_role_code_no + 1;
            while(this.state.all_role_type_wise_role_code_numbers[this.state.selected_role_type.id].includes(new_selected_role_code_no) === true){
                new_selected_role_code_no++;  //deciding next available code number
            }
        }
        else if(up_down === 'down'){
            let temp = cur_selected_role_code_no - 1;
            while(this.state.all_role_type_wise_role_code_numbers[this.state.selected_role_type.id].includes(temp) === true && temp > 0){
                temp--;
            }
            new_selected_role_code_no = temp !== 0 ? temp : cur_selected_role_code_no;  //deciding previous available code number
        }
        let role_info = JSON.parse(JSON.stringify(this.state.role_info));
        let selected_role_type = JSON.parse(JSON.stringify(this.state.selected_role_type));
        role_info.role_code = selected_role_type.base + "#" + new_selected_role_code_no.toString().padStart(2,'0');
        this.setState({ role_info, selected_role_code_no : new_selected_role_code_no });
    }

    handleChange = async (event) => {
		event.preventDefault();
		let role_info = JSON.parse(JSON.stringify(this.state.role_info));
		role_info[event.target.name] = event.target.value;
		this.setState({ role_info });
        let is_dirty = await this.checkRoleInfoChange(role_info);
        EventBus.raiseEvent('deactivate_ass_sec',is_dirty);
        // this.props.activateAssignmentTab(is_dirty,role_info);
	}

    changeCheckStatus = (field) => async (event) =>{
        event.preventDefault();
        let role_info = JSON.parse(JSON.stringify(this.state.role_info));
        const postData = {"command":"rough_work","action":"assignments","role_id":role_info.role_id,"gc_ids":[0]};
        const api = new APICall();
        let result = await api.commandWithoutCallback(postData);
		console.log("Date Assignment Object : ", result);
        // const confirm = this.props.myHookValue;
        // const choice = await confirm({
        //     title: "My title",
        //     description: "My description"
        // })
        //console.log(choice)
        let yes_no_box_alert_param = null;
        let na_date_range_status = await this.statusCount(role_info.na_date_ranges);
        let shared_date_range_status = await this.statusCount(role_info.shared_date_ranges);
        let divided_date_range_status = await this.statusCount(role_info.divided_date_ranges);
        console.log("na_date_range_status", na_date_range_status);
        console.log("shared_date_range_status", shared_date_range_status);
        console.log("divided_date_range_status", divided_date_range_status);
        let acceptable = true;
        let to_status = false;
        if(role_info[field] === false){   //when changing the status into true
            to_status = true;
            switch(field){
                case 'is_mgr_req':
                    role_info[field] = true;
                    break;
                case 'is_na' :
                    // if(this.state.role_info.shared_date_ranges.length === 1 && this.state.role_info.shared_date_ranges[0].action === 'default' || this.state.role_info.divided_date_ranges.length === 1 && this.state.role_info.divided_date_ranges[0].action === 'default'){
                    if(shared_date_range_status.default_count === 1 || divided_date_range_status.default_count === 1 || shared_date_range_status.forever_count >=1 || divided_date_range_status.forever_count >= 1){
                        yes_no_box_alert_param = {
                            "header" : "N/A Date Range Error",
                            "subheader" : "",
                            "msg" : "You have set this role as [ Shared | Divided ] forever. No N/A date range can be applied. Please provide [ Shared | Divided ] date range(s) first.",
                            "closefunc" : this.closeYesNoBox,
                            "buttons" : {
                                "OK" : {
                                    "caption" : "OK",
                                    "bgcolor" : "#24B3BF",
                                    "color" : "#fff",
                                    "callback" : this.closeYesNoBox
                                }
                            }
                        }
                        acceptable = false;
                        //return;
                    //}else if(this.state.role_info.shared_date_ranges.length >= 1 || this.state.role_info.divided_date_ranges.length >= 1){
                    }else if(shared_date_range_status.active_count >=1 || divided_date_range_status.active_count >= 1){
                        yes_no_box_alert_param = {
                            "header" : "N/A Date Range Alert",
                            "subheader" : "",
                            "msg" : "There are one or more [ Shared | Divided ] range present. You must provide proper N/A date range.",
                            "closefunc" : this.closeYesNoBox,
                            "buttons" : {
                                "OK" : {
                                    "caption" : "OK",
                                    "bgcolor" : "#24B3BF",
                                    "color" : "#fff",
                                    "callback" : this.closeYesNoBox
                                }
                            },
                            "modal_params" : ["na","add"],
                            "to_status" : to_status
                        }
                    }
                    else if(Object.keys(result.result).length >= 1){
                        yes_no_box_alert_param = {
                            "header" : "N/A Date Range Alert",
                            "subheader" : "",
                            "msg" : "There are one or more role assignment(s) present. You must provide proper N/A date range.",
                            "closefunc" : this.closeYesNoBox,
                            "buttons" : {
                                "OK" : {
                                    "caption" : "OK",
                                    "bgcolor" : "#24B3BF",
                                    "color" : "#fff",
                                    "callback" : this.closeYesNoBox
                                }
                            },
                            "modal_params" : ["na","add"],
                            "to_status" : to_status
                        }
                    }
                    if(acceptable === true){
                        role_info[field] = true;
                        role_info.na_date_ranges.push({
                            id : 0,
                            role_id : role_info.role_id,
                            status_type : 'na',
                            reason : "default",
                            from : -1,
                            to : parseInt(process.env.REACT_APP_NO_END_DATE),
                            action : "default"
                        })
                    }
                    break;
                case 'is_shared' :
                    //if(this.state.role_info.na_date_ranges.length === 1 && this.state.role_info.na_date_ranges[0].action === 'default'){
                    if(na_date_range_status.default_count === 1 || na_date_range_status.forever_count >= 1){
                        yes_no_box_alert_param = {
                            "header" : "Shared Date Range Error",
                            "subheader" : "",
                            "msg" : "This role is set as N/A for ever.",
                            "closefunc" : this.closeYesNoBox,
                            "buttons" : {
                                "OK" : {
                                    "caption" : "OK",
                                    "bgcolor" : "#24B3BF",
                                    "color" : "#fff",
                                    "callback" : this.closeYesNoBox
                                }
                            }
                        }
                        acceptable = 0;
                        //return;
                    //}else if((this.state.role_info.na_date_ranges.length >= 1 || this.state.role_info.divided_date_ranges.length >= 1) && !(this.state.role_info.na_date_ranges.length === 0 && this.state.role_info.divided_date_ranges.length === 1 && this.state.role_info.divided_date_ranges[0].action === 'default')){
                    }
                    else if((na_date_range_status.active_count >=1 || divided_date_range_status.active_count >=1) && !(na_date_range_status.active_count === 0 && divided_date_range_status.default_count ===1)){
                        yes_no_box_alert_param = {
                            "header" : "Shared Date Range Alert",
                            "subheader" : "",
                            "msg" : "There are one or more [ NA | Divided ] range(s) present. You must provide proper Shared Date Range.",
                            "closefunc" : this.closeYesNoBox,
                            "buttons" : {
                                "OK" : {
                                    "caption" : "OK",
                                    "bgcolor" : "#24B3BF",
                                    "color" : "#fff",
                                    "callback" : this.closeYesNoBox
                                }
                            },
                            "modal_params" : ["shared","add"],
                            "to_status" : to_status
                        }
                    }
                    if(acceptable === true){
                        role_info[field] = true;
                        role_info.shared_date_ranges.push({
                            id : 0,
                            role_id : role_info.role_id,
                            status_type : 'shared',
                            reason : "default",
                            from : -1,
                            to : parseInt(process.env.REACT_APP_NO_END_DATE),
                            action : "default"
                        })
                    }
                    break;
                case 'is_divided' :
                    //if(this.state.role_info.na_date_ranges.length === 1 && this.state.role_info.na_date_ranges[0].action === 'default'){
                    if(na_date_range_status.default_count === 1 || na_date_range_status.forever_count >= 1){
                        yes_no_box_alert_param = {
                            "header" : "Divided Date Range Error",
                            "subheader" : "",
                            "msg" : "This role is set as N/A for ever.",
                            "closefunc" : this.closeYesNoBox,
                            "buttons" : {
                                "OK" : {
                                    "caption" : "OK",
                                    "bgcolor" : "#24B3BF",
                                    "color" : "#fff",
                                    "callback" : this.closeYesNoBox
                                }
                            }
                        }
                        acceptable = 0;
                        break;
                    //}else if((this.state.role_info.na_date_ranges.length >= 1 || this.state.role_info.shared_date_ranges.length >= 1) && !(this.state.role_info.na_date_ranges.length === 0 && this.state.role_info.shared_date_ranges.length === 1 && this.state.role_info.shared_date_ranges[0].action === 'default')){
                    }
                    else if((na_date_range_status.active_count >=1 || divided_date_range_status.active_count >=1) && !(na_date_range_status.active_count === 0 && shared_date_range_status.default_count ===1)){
                        yes_no_box_alert_param = {
                            "header" : "Divided Date Range Alert",
                            "subheader" : "",
                            "msg" : "There are one or more [ NA | Shared ] range(s) present. You must provide proper Divided Date Range.",
                            "closefunc" : this.closeYesNoBox,
                            "buttons" : {
                                "OK" : {
                                    "caption" : "OK",
                                    "bgcolor" : "#24B3BF",
                                    "color" : "#fff",
                                    "callback" : this.closeYesNoBox
                                }
                            },
                            "modal_params" : ["divided","add"],
                            "to_status" : to_status
                        }
                    }
                    if(acceptable === true){
                        role_info[field] = true;
                        role_info.divided_date_ranges.push({
                            id : 0,
                            role_id : role_info.role_id,
                            status_type : 'divided',
                            reason : "default",
                            from : -1,
                            to : parseInt(process.env.REACT_APP_NO_END_DATE),
                            action : "default"
                        })
                    }
                    break;
            }
        }else{    //when changing the status into false
            to_status = false;
            switch(field){
                case 'is_mgr_req':
                    role_info[field] = false;
                    break;
                case 'is_na' :
                    //if(role_info.na_date_ranges.length === 1 && role_info.na_date_ranges[0].action === 'default'){
                    if(na_date_range_status.active_count >=1 && na_date_range_status.default_count === 0){
                        yes_no_box_alert_param = {
                            "header" : "N/A Date Range Error",
                            "subheader" : "",
                            "msg" : "There are one more N/A date ranges present. Remove those date ranges first.",
                            "closefunc" : this.closeYesNoBox,
                            "buttons" : {
                                "OK" : {
                                    "caption" : "OK",
                                    "bgcolor" : "#24B3BF",
                                    "color" : "#fff",
                                    "callback" : this.closeYesNoBox
                                }
                            }
                        }
                        break;
                    }else{
                        if(role_info.na_date_ranges.length >= 1){
                            for(let i = 0; i < role_info.na_date_ranges.length; i++){
                                role_info.na_date_ranges[i]["action"] = 'delete';
                            }
                        }
                        role_info[field] = false;
                    }
                    break;
                case 'is_shared' :
                    //if(this.state.role_info.shared_date_ranges.length === 1 && this.state.role_info.shared_date_ranges[0].action === 'default'){
                    if(shared_date_range_status.default_count === 1){
                        let if_error = false;
                        for(let item in result.result){
                            if(Object.keys(result.result[item]).length > 1) {
                                yes_no_box_alert_param = {
                                    "header" : "Shared Date Range Error",
                                    "subheader" : "",
                                    "msg" : "This role has already been assigned to more than one person. Please <br>i) check the assignments and change the effective dates to remove the assignment overlap; or <br>ii) click the '+' icon to add a date range where this role was able to be [ shared | divided ]",
                                    "closefunc" : this.closeYesNoBox,
                                    "buttons" : {
                                        "OK" : {
                                            "caption" : "OK",
                                            "bgcolor" : "#24B3BF",
                                            "color" : "#fff",
                                            "callback" : this.closeYesNoBox
                                        }
                                    }
                                }
                                if_error = true;
                            }
                            if(if_error) break;
                        }
                        if(!if_error){
                            if(role_info.shared_date_ranges.length >= 1){
                                for(let i = 0; i < role_info.shared_date_ranges.length; i++){
                                    role_info.shared_date_ranges[i]["action"] = 'delete';
                                }
                            }
                            role_info[field] = false;
                        }
                    //}else if(this.state.role_info.shared_date_ranges.length >= 1 && (this.state.role_info.shared_date_ranges.length === 1 && this.state.role_info.shared_date_ranges[0].action === 'default')){
                    }else if(shared_date_range_status.active_count >=1){
                        yes_no_box_alert_param = {
                            "header" : "Shared Date Range Error",
                            "subheader" : "",
                            "msg" : "This role has one or more date ranges where it can be shared/divided. Please remove the relevant date ranges before marking this as never being able to be shared/divided.",
                            "closefunc" : this.closeYesNoBox,
                            "buttons" : {
                                "OK" : {
                                    "caption" : "OK",
                                    "bgcolor" : "#24B3BF",
                                    "color" : "#fff",
                                    "callback" : this.closeYesNoBox
                                }
                            }
                        }
                        break;
                    }else{
                        if(role_info.shared_date_ranges.length >= 1){
                            for(let i = 0; i < role_info.shared_date_ranges.length; i++){
                                role_info.shared_date_ranges[i]["action"] = 'delete';
                            }
                        }
                        role_info[field] = false;
                    }
                    break;
                case 'is_divided' :
                    //if(this.state.role_info.divided_date_ranges.length === 1 && this.state.role_info.divided_date_ranges[0].action === 'default'){
                    if(divided_date_range_status.default_count === 1){
                        let if_error = false;
                        for(let item in result.result){
                            if(Object.keys(result.result[item]).length > 1) {
                                yes_no_box_alert_param = {
                                    "header" : "Divided Date Range Error",
                                    "subheader" : "",
                                    "msg" : "This role has already been assigned to more than one person. Please <br>i) check the assignments and change the effective dates to remove the assignment overlap; or <br>ii) click the '+' icon to add a date range where this role was able to be [ shared | divided ]",
                                    "closefunc" : this.closeYesNoBox,
                                    "buttons" : {
                                        "OK" : {
                                            "caption" : "OK",
                                            "bgcolor" : "#24B3BF",
                                            "color" : "#fff",
                                            "callback" : this.closeYesNoBox
                                        }
                                    }
                                }
                                if_error = true;
                            }
                            if(if_error) break;
                        }
                        if (!if_error){
                            if(role_info.divided_date_ranges.length >= 1){
                                for(let i = 0; i < role_info.divided_date_ranges.length; i++){
                                    role_info.divided_date_ranges[i]["action"] = 'delete';
                                }
                            }
                            role_info[field] = false;
                        }
                    //}else if(this.state.role_info.divided_date_ranges.length >= 1){
                    }else if(divided_date_range_status.active_count >=1){
                        yes_no_box_alert_param = {
                            "header" : "Divided Date Range Error",
                            "subheader" : "",
                            "msg" : "This role has one or more date ranges where it can be shared/divided. Please remove the relevant date ranges before marking this as never being able to be shared/divided.",
                            "closefunc" : this.closeYesNoBox,
                            "buttons" : {
                                "OK" : {
                                    "caption" : "OK",
                                    "bgcolor" : "#24B3BF",
                                    "color" : "#fff",
                                    "callback" : this.closeYesNoBox
                                }
                            }
                        }
                        break;
                    }else{
                        if(role_info.divided_date_ranges.length >= 1){
                            for(let i = 0; i < role_info.divided_date_ranges.length; i++){
                                role_info.divided_date_ranges[i]["action"] = 'delete';
                            }
                        }
                        role_info[field] = false;
                    }
                    break;
            }

        }
		//role_info[field] = role_info[field] === false ? true : false;
        console.log("ROLE INFO ======>", role_info);
		this.setState({ role_info, selected_check_box : field, yes_no_box_alert_param });
        if(acceptable === true && to_status === true && yes_no_box_alert_param === null){
            switch(field){
                case 'is_na':
                    this.openDateRangeModalOnCheck("na","add");
                    break;
                case 'is_shared':
                    this.openDateRangeModalOnCheck('shared','add');
                    break;
                case 'is_divided':
                    this.openDateRangeModalOnCheck('divided','add');
                    break;
            }
        }
        let is_dirty = await this.checkRoleInfoChange(role_info);
        EventBus.raiseEvent('deactivate_ass_sec',is_dirty);
        // this.props.activateAssignmentTab(is_dirty);
    }

    statusCount = async (date_range) =>{
        let default_count = 0;
        let insert_count = 0;
        let delete_count = 0;
        let update_count = 0;
        let nochange_count = 0;
        let active_count = 0;
        let forever_count = 0;
        if(date_range.length > 0) {
            for(let i=0; i<=date_range.length-1;i++){
                if('action' in date_range[i] && date_range[i].action === '') {
                    active_count++;
                }
                if('action' in date_range[i] && date_range[i].action === 'default') {
                    default_count++;
                    active_count++;
                }
                if('action' in date_range[i] && date_range[i].action === 'insert') {
                    insert_count++;
                    active_count++;
                }
                if('action' in date_range[i] && date_range[i].action === 'delete') delete_count++;
                if('action' in date_range[i] && date_range[i].action === 'update'){
                    update_count++;
                    active_count++;
                }
                if('action' in date_range[i] && date_range[i].action === 'nochange') {
                    nochange_count++;
                    active_count++;
                }
                if(date_range[i].from === -1 && date_range[i].to === parseInt(process.env.REACT_APP_NO_END_DATE) && date_range[i].action !== 'delete'){
                    forever_count++;
                }
            }
        }
        let status = { 'default_count' : default_count, 'insert_count' : insert_count, 'delete_count' : delete_count, 'update_count' : update_count, 'nochange_count' : nochange_count, 'active_count' : active_count, 'forever_count' : forever_count }
        return (status);
    }

    openDateRangeModal = (type, mode, id=null) => (e) => {
        console.log("Mode : ", mode, "Type : ", type, "ID ", id);
        e.preventDefault();
        let date_range_data = { id : 0, from : -1, to : parseInt(process.env.REACT_APP_NO_END_DATE), reason : "", action : "insert", temp_id : this.makeid(8) };
        let date_range = {};
        let edit_date_range_backup = {};
        if(mode === 'edit'){
            switch(type){
                case "na":
                    date_range = this.state.role_info.na_date_ranges;
                    break;
                case "shared":
                    date_range = this.state.role_info.shared_date_ranges;
                    break;
                case "divided":
                    date_range = this.state.role_info.divided_date_ranges;
                    break;
            }
            console.log("date_range:", date_range)

            date_range_data = date_range.find(item => item.temp_id === id);
            edit_date_range_backup = JSON.parse(JSON.stringify(date_range_data));
            date_range_data['action'] = date_range_data['action'] !== 'insert' ? 'nochange' : date_range_data['action'];
        }
        let date_modal_data = {};
        date_modal_data["type"] = type;
        date_modal_data["mode"] = mode;
        date_modal_data["data"] = date_range_data;
        this.setState({ date_modal_data, show_date_modal : true, show_na_submenu : false, show_shared_submenu : false, show_divided_submenu : false, edit_date_range_backup });
    }

    openDateRangeModalOnCheck = (type, mode) => {
        console.log("Mode : ", mode, "Type : ", type);
        let date_range_data = { id : 0, from : -1, to : parseInt(process.env.REACT_APP_NO_END_DATE), reason : "", action : "insert", temp_id : this.makeid(8) };
        let edit_date_range_backup = {};
        let date_modal_data = {};
        date_modal_data["type"] = type;
        date_modal_data["mode"] = mode;
        date_modal_data["data"] = date_range_data;
        this.setState({ date_modal_data, show_date_modal : true, show_na_submenu : false, show_shared_submenu : false, show_divided_submenu : false, edit_date_range_backup });
    }

    tableData = (id = null) => {
		let ret = { data: [], columns: [] };
		ret.columns = [
			{ Header: 'From', accessor: 'from', minWidth: 50, headerStyle: { textAlign: 'left' } },
			{ Header: 'To', accessor: 'to', minWidth: 50, headerStyle: { textAlign: 'left' } },
			{ Header: 'Reason', accessor: 'reason', minWidth: 200, headerStyle: { textAlign: 'left' } },
			{
				'Header': '', Cell: row => (
					<div>
						{
							(() => {
								return (
                                    <EllipsisIconDiv onMouseEnter={this.showSubMenu(id, row.original.temp_id, row.viewIndex)}
                                        onClick={this.showSubMenu(id,row.original.temp_id,row.viewIndex)}>
                                        <FaEllipsisV style={{ cursor: 'pointer' }} />
                                    </EllipsisIconDiv>
                                )
							})()
						}
					</div>
				), width: 30, headerStyle: { textAlign: 'left' }
			}];
        let date_range = "";
        if(id != null) {
            switch(id){
                case "na" :
                    date_range = "na_date_ranges";
                    break;
                case "shared" :
                    date_range = "shared_date_ranges";
                    break;
                case "divided" :
                    date_range = "divided_date_ranges";
                    break;
            }
        }
        //console.log("date_range : ",date_range);
        //console.log("Role Info" , this.state.role_info);
        if(this.state.role_info[date_range].length !== 0){
            this.state.role_info[date_range].forEach((item) => {
                if(item.action !== "default" && item.action !== "delete"){
                    console.log("Type No Date",parseInt(process.env.REACT_APP_NO_END_DATE), item.to);
                    let _id = item.id;
                    let _from = item.from === -1 ? '-' : moment(this.toDateObject(item.from)).format("DD-MM-YYYY")
                    let _to = item.to == parseInt(process.env.REACT_APP_NO_END_DATE) ? '-' : moment(this.toDateObject(item.to)).format("DD-MM-YYYY")
                    let _temp_id = item.temp_id;
                    let elem = {id : _id, from: _from, to: _to, reason: item.reason, temp_id : _temp_id };
                    ret.data.push(elem);
                }
            })
        }
		return ret;
	}

    toDateObject = (date) =>{
		let date_str = (date%100).toString() + "-" + Math.floor((date%10000)/100).toString() + "-" + Math.floor(date/10000).toString();
		// console.info("date_str:", date_str);
		let date_obj = moment(date_str, 'DD-MM-YYYY').toDate();
		// console.info("date_obj:", date_obj);
		return date_obj;
	}

    showSubMenu = (type,record_id,table_index) => (e) =>{
        e.preventDefault();
        // console.log("SHOW SUB MENU")
        let show_na_submenu;
        let show_shared_submenu;
        let show_divided_submenu;
        switch(type){
            case 'na' :
                if(this.state.submenu_for_subsection === type && table_index !== this.state.selected_table_index && this.state.show_na_submenu  === true || this.state.submenu_for_subsection !== type){
                    show_na_submenu = true;
                }
                else{
                    show_na_submenu = this.state.show_na_submenu  === false ? true : false;
                }
                show_shared_submenu = false;
                show_divided_submenu = false;
                break;
            case 'shared' :
                if(this.state.submenu_for_subsection === type && table_index !== this.state.selected_table_index && this.state.show_shared_submenu  === true || this.state.submenu_for_subsection !== type){
                    show_shared_submenu = true;
                }
                else{
                    show_shared_submenu = this.state.show_shared_submenu  === false ? true : false;
                }
                show_na_submenu = false;
                show_divided_submenu = false;
                break;
            case 'divided' :
                if(this.state.submenu_for_subsection === type && table_index !== this.state.selected_table_index && this.state.show_divided_submenu  === true || this.state.submenu_for_subsection !== type){
                    show_divided_submenu = true;
                }
                else{
                    show_divided_submenu = this.state.show_divided_submenu  === false ? true : false;
                }
                show_na_submenu = false;
                show_shared_submenu = false;
                break;
        }
        let selected_table_index = table_index;
        let selected_record_id = record_id;
        let submenu_for_subsection = type;
        this.setState({ show_na_submenu, show_shared_submenu, show_divided_submenu, selected_table_index, selected_record_id,submenu_for_subsection });
    }

    closeSubMenuOnMouseLeave = (e) =>{
        e.preventDefault();
        // console.log("CLOSE SUB MENU")
        let show_na_submenu;
        let show_shared_submenu;
        let show_divided_submenu;
        show_divided_submenu = false;
        show_na_submenu = false;
        show_divided_submenu = false;
        this.setState({ show_na_submenu, show_shared_submenu, show_divided_submenu, selected_table_index : -1, selected_record_id : null,submenu_for_subsection : null });
    }

    closeYesNoBox = () => {
        let yes_no_box_alert_param = JSON.parse(JSON.stringify(this.state.yes_no_box_alert_param));
        let modal_params = null;
        let to_status = null;
        if("modal_params" in yes_no_box_alert_param) modal_params = yes_no_box_alert_param.modal_params;
        if("to_status" in yes_no_box_alert_param) to_status = yes_no_box_alert_param.to_status;
        this.setState({ yes_no_box_alert_param : null });
        if(to_status === true) this.openDateRangeModalOnCheck(modal_params[0],modal_params[1]);
    }

    checkBeforeSave = async (e) =>{
        e.preventDefault();
        let acceptable = true;
        let role_info = JSON.parse(JSON.stringify(this.state.role_info));
        let na_date_range_status = await this.statusCount(role_info.na_date_ranges);
        let shared_date_range_status = await this.statusCount(role_info.shared_date_ranges);
        let divided_date_range_status = await this.statusCount(role_info.divided_date_ranges);
        const postData = {"command":"rough_work","action":"assignments","role_id":role_info.role_id,"gc_ids":[0]};
        const api = new APICall();
        let result = await api.commandWithoutCallback(postData);
        //if(role_info.is_na === true && role_info.na_date_ranges.length === 1 && role_info.na_date_ranges[0].action === 'default'){
        if(role_info.is_na === true && na_date_range_status.default_count === 1){
            //if(role_info.shared_date_ranges.length >=1 || role_info.divided_date_ranges.length >=1){
            if(shared_date_range_status.active_count >=1 || divided_date_range_status.active_count >=1){
                let yes_no_box_alert_param = {
                        "header" : "N/A Date Range Error",
                        "subheader" : "",
                        "msg" : "You cannot set a role as N/A for ever when there are [Shared | Divided] date ranges already present",
                        "closefunc" : this.closeYesNoBox,
                        "buttons" : {
                            "OK" : {
                                "caption" : "OK",
                                "bgcolor" : "#24B3BF",
                                "color" : "#fff",
                                "callback" : this.closeYesNoBox
                            }
                        }
                    }
                //alert("You cannot set a role as N/A when there are [Shared | Divided] date ranges already present");
                this.setState({ yes_no_box_alert_param });
                return;
            }else if(Object.keys(result.result).length >= 1){
                let yes_no_box_alert_param = {
                    "header" : "N/A Date Range Alert",
                    "subheader" : "",
                    "msg" : "You cannot set a role as N/A for ever when there are one or more role assignment(s) present",
                    "closefunc" : this.closeYesNoBox,
                    "buttons" : {
                        "OK" : {
                            "caption" : "OK",
                            "bgcolor" : "#24B3BF",
                            "color" : "#fff",
                            "callback" : this.closeYesNoBox
                        }
                    }
                }
                this.setState({ yes_no_box_alert_param });
                return;
            }
        //}else if(role_info.is_shared === true && role_info.shared_date_ranges.length === 1 && role_info.shared_date_ranges[0].action === 'default'){
        }else if(role_info.is_shared === true && shared_date_range_status.default_count === 1){
            //if(role_info.na_date_ranges.length >=1) {
            if(na_date_range_status.active_count >=1) {
                let yes_no_box_alert_param = {
                    "header" : "Shared Date Range Error",
                    "subheader" : "",
                    "msg" : "You cannot set a role to be shared for ever when there are N/A date ranges present",
                    "closefunc" : this.closeYesNoBox,
                    "buttons" : {
                        "OK" : {
                            "caption" : "OK",
                            "bgcolor" : "#24B3BF",
                            "color" : "#fff",
                            "callback" : this.closeYesNoBox
                        }
                    }
                }
                this.setState({ yes_no_box_alert_param });
                //alert("You cannot set a role to be shared for ever when there are N/A date ranges present");
                return;
            }
        //}else if(role_info.is_divided === true && role_info.divided_date_ranges.length === 1 && role_info.divided_date_ranges[0].action === 'default'){
        }else if(role_info.is_divided === true && divided_date_range_status.default_count === 1){
            //if(role_info.na_date_ranges.length >=1) {
            if(na_date_range_status.active_count >= 1) {
                let yes_no_box_alert_param = {
                    "header" : "Divided Date Range Error",
                    "subheader" : "",
                    "msg" : "You cannot set a role to be divided for ever when there are N/A date ranges present",
                    "closefunc" : this.closeYesNoBox,
                    "buttons" : {
                        "OK" : {
                            "caption" : "OK",
                            "bgcolor" : "#24B3BF",
                            "color" : "#fff",
                            "callback" : this.closeYesNoBox
                        }
                    }
                }
                this.setState({ yes_no_box_alert_param });
                //alert("You cannot set a role to be divided for ever when there are N/A date ranges present");
                return;
            }
        }
        if(role_info.role_id !==0 || role_info.status !== 'insert'){
            for(let key in role_info){
                if(typeof role_info[key] !== 'object'){
                    if(role_info[key] !== this.state.role_info_from_prop[key]){
                        role_info.action = 'update';
                    }
                }
                console.log("Key type : ", key, typeof role_info[key]);
            }
        }
        // let date_ranges = role_info.na_date_ranges.concat(role_info.shared_date_ranges);
        // date_ranges = date_ranges.concat(role_info.divided_date_ranges);
        // console.log("Date Ranges : ", date_ranges);
        // for(let i=0; i<=date_ranges.length-1; i++){
        //     if(date_ranges[i].action !== "" && date_ranges[i].action !== "nochange") {
        //         console.log("Changed date range : ", date_ranges[i]);
        //         role_info.action = "update";
        //     }
        // }
        console.log("Role info before save : ", role_info);
        this.setState({ role_info });
        this.saveRole(role_info);
    }

    saveRole = (role_info) => {
        this.action = "dbsave";
        this.role_info = JSON.parse(JSON.stringify(role_info));
        for(let i=0; i<=this.role_info.na_date_ranges.length-1;i++){
            if(this.role_info.na_date_ranges[i].id === 0 && this.role_info.na_date_ranges[i].action === 'insert') this.role_info.na_date_ranges[i].action = "";
        }
        for(let i=0; i<=this.role_info.shared_date_ranges.length-1;i++){
            if(this.role_info.shared_date_ranges[i].id === 0 && this.role_info.shared_date_ranges[i].action === 'insert') this.role_info.shared_date_ranges[i].action = "";
        }
        for(let i=0; i<=this.role_info.divided_date_ranges.length-1;i++){
            if(this.role_info.divided_date_ranges[i].id === 0 && this.role_info.divided_date_ranges[i].action === 'insert') this.role_info.divided_date_ranges[i].action = "";
        }
        this.props.saveRole(role_info);
    }

    handleDateRange = async (date_range_data) => {
        this.setState({ show_spinner : true });
        this.action = "memsave";
        console.log("Date Range Data : " ,date_range_data);
        let date_range_obj = date_range_data.data;
        date_range_obj["status_type"] = date_range_data.type;
        //date_range_obj.from = date_range_obj.from === null ? -1 : parseInt(moment(date_range_obj.from).format("YYYY"))*10000 + parseInt(moment(date_range_obj.from).format("M"))*100 + parseInt(moment(date_range_obj.from).format("D")) ;
        //date_range_obj.to = date_range_obj.to === null ? process.env.REACT_APP_NO_END_DATE : parseInt(moment(date_range_obj.to).format("YYYY"))*10000 + parseInt(moment(date_range_obj.to).format("M"))*100 + parseInt(moment(date_range_obj.to).format("D"));
        let role_info = JSON.parse(JSON.stringify(this.state.role_info));
        let message = ""
        let acceptable = true;
        const postData = {"command":"rough_work","action":"assignments","role_id":role_info.role_id,"gc_ids":[0]};
        const api = new APICall();
        let result = await api.commandWithoutCallback(postData);
		console.log("Date Assignment Object : ", result);
        const postData1 = {"command":"get_role_assignment_for_date_range","from" : date_range_obj.from, "to" : date_range_obj.to, "role_id" : role_info.role_id};
        let assignment_result = await api.commandWithoutCallback(postData1);
		console.log("Assignments in the range : ", assignment_result);
        let na_date_range_status = await this.statusCount(role_info.na_date_ranges);
        let shared_date_range_status = await this.statusCount(role_info.shared_date_ranges);
        let divided_date_range_status = await this.statusCount(role_info.divided_date_ranges);
        let alt_from_date = parseInt(moment().subtract(10,'years').format("YYYYMMDD"));
        let alt_end_date = parseInt(moment().add(10,'years').format("YYYYMMDD"));
        let overlaps = "";
        let checkrange = null
        let res_dates = null
        let continuous_ranges = null
        let err_ovrs = {}

        if(date_range_data.mode === "add"){
            // if(date_range_obj.from !== -1 && date_range_obj.from < role_info.created_at){
            //     acceptable = false;
            //     let yes_no_box_alert_param = {
            //         "header" : "Range From Date Error",
            //         "subheader" : "",
            //         "msg" : `Date range starting date preceeds the role creation date ${moment(role_info.created_at.toString(), "YYYYMMDD").format("DD-MM-YYYY")}.`,
            //         "closefunc" : this.closeYesNoBox,
            //         "buttons" : {
            //             "OK" : {
            //                 "caption" : "OK",
            //                 "bgcolor" : "#24B3BF",
            //                 "color" : "#fff",
            //                 "callback" : this.closeYesNoBox
            //             }
            //         }
            //     }
            //     this.setState({ yes_no_box_alert_param });
            //     return;
            // }
            let overlaps = "";
            switch(date_range_data.type){
                case "na":
                    let ranges1 = role_info.shared_date_ranges.concat(role_info.divided_date_ranges);
                    ranges1 = ranges1.concat(role_info.na_date_ranges)
                    for(let r of ranges1){
                        //console.log("date_range_obj.from : ", date_range_obj.from, " date_range_obj.to : ", date_range_obj.to, " r.from : ", r.from, " r.to : ",r.to);
                        //console.log("R=======>",r);
                        if(r.action !== 'delete' && !(r.status_type === 'na' && r.action === 'default') && (date_range_obj.from >= r.from && date_range_obj.from <= r.to || date_range_obj.to >= r.from && date_range_obj.to <= r.to || date_range_obj.from < r.from && date_range_obj.to > r.to))
                        {
                            acceptable = false;
                            // if(r.status_type === 'na') overlaps =  overlaps + '<br>N/A Date Range from ' + r.from === -1 ? "Regime Start" : moment(this.toDateObject(r.from)).format("DD-MM-YYYY").toString() + ' to ' + r.to === process.env.REACT_APP_NO_END_DATE ? "Present Day" : moment(this.toDateObject(r.to)).format("DD-MM-YYYY").toString();
                            // else if(r.status_type === 'shared') overlaps =  overlaps + '<br>Shared Date Range from ' + r.from === -1 ? "Regime Start" : moment(this.toDateObject(r.from)).format("DD-MM-YYYY").toString() + ' to ' + r.to === process.env.REACT_APP_NO_END_DATE ? "Present Day" : moment(this.toDateObject(r.to)).format("DD-MM-YYYY").toString();
                            // else if(r.status_type === 'divided') overlaps =  overlaps + '<br>Divided Date Range from ' + r.from === -1 ? "Regime Start" : moment(this.toDateObject(r.from)).format("DD-MM-YYYY").toString() + ' to ' + r.to === process.env.REACT_APP_NO_END_DATE ? "Present Day" : moment(this.toDateObject(r.to)).format("DD-MM-YYYY").toString();
                            if(r.status_type === 'na') overlaps =  overlaps + `<br>N/A Date Range from ${r.from === -1 ? "Regime Start" : moment(this.toDateObject(r.from)).format("DD-MM-YYYY").toString()} to ${r.to === parseInt(process.env.REACT_APP_NO_END_DATE) ? "Present Day" : moment(this.toDateObject(r.to)).format("DD-MM-YYYY").toString()}`;
                            else if(r.status_type === 'shared') overlaps =  overlaps + `<br>Shared Date Range from ${r.from === -1 ? "Regime Start" : moment(this.toDateObject(r.from)).format("DD-MM-YYYY").toString()} to ${r.to === parseInt(process.env.REACT_APP_NO_END_DATE) ? "Present Day" : moment(this.toDateObject(r.to)).format("DD-MM-YYYY").toString()}`;
                            else if(r.status_type === 'divided') overlaps =  overlaps + `<br>Divided Date Range from ${r.from === -1 ? "Regime Start" : moment(this.toDateObject(r.from)).format("DD-MM-YYYY").toString()} to ${r.to === parseInt(process.env.REACT_APP_NO_END_DATE) ? "Present Day" : moment(this.toDateObject(r.to)).format("DD-MM-YYYY").toString()}`;
                        }
                    }
                    if(overlaps !== ""){
                        let yes_no_box_alert_param = {
                            "header" : "N/A Date Range Error",
                            "subheader" : "",
                            "msg" : "Date Range overlaps with one or more [NA | Shared | Divided] date range(s).<br>" + overlaps,
                            "closefunc" : this.closeYesNoBox,
                            "buttons" : {
                                "OK" : {
                                    "caption" : "OK",
                                    "bgcolor" : "#24B3BF",
                                    "color" : "#fff",
                                    "callback" : this.closeYesNoBox
                                }
                            }
                        }
                        this.setState({ yes_no_box_alert_param });
                        break;
                    }
                    overlaps = "";
                    if(assignment_result.length !== 0){
                        acceptable = false;
                        overlaps = role_info.role_code + " was assigned to";
                        for(let res of assignment_result){
                            overlaps = overlaps + `<br>${this.state.listContacts.find(item => item.ID === res.contact_id.toString()).ContactName} from ${res.effective_from_date === -1 ? "Regime Start" : moment(this.toDateObject(res.effective_from_date)).format("DD-MM-YYYY")} to ${res.effective_till_date === parseInt(process.env.REACT_APP_NO_END_DATE) ? "Present Day" : moment(this.toDateObject(res.effective_till_date)).format("DD-MM-YYYY")}`;
                        }
                    }
                    if(overlaps !== ""){
                        let yes_no_box_alert_param = {
                            "header" : "Are you sure?",
                            "subheader" : "Your date range overlaps with one or more Role Assignment(s)",
                            "msg" : overlaps + `<br><br>This overlaps with your date range. Please review your dates and change the assignments or 'Not Applicable' date range`,
                            "closefunc" : this.closeYesNoBox,
                            "buttons" : {
                                "OK" : {
                                    "caption" : "OK",
                                    "bgcolor" : "#24B3BF",
                                    "color" : "#fff",
                                    "callback" : this.closeYesNoBox
                                }
                            }
                        }
                        this.setState({ yes_no_box_alert_param });
                        break;
                    }
                    if(acceptable === true) {
                        for(let i=0; i<role_info.na_date_ranges.length; i++){
                            if(role_info.na_date_ranges[i].action === 'default') role_info.na_date_ranges[i].action = 'delete';
                        }
                        role_info.na_date_ranges.push(date_range_obj);
                    }
                    break;
                case "shared":
                    let ranges2 = role_info.shared_date_ranges.concat(role_info.na_date_ranges);
                    for(let r of ranges2){
                        //console.log("date_range_obj.from : ", date_range_obj.from, " date_range_obj.to : ", date_range_obj.to, " r.from : ", r.from, " r.to : ",r.to);

                        if(r.action !== 'delete' && !(r.status_type === 'shared' && r.action === 'default') && (date_range_obj.from >= r.from && date_range_obj.from <= r.to || date_range_obj.to >= r.from && date_range_obj.to <= r.to || date_range_obj.from < r.from && date_range_obj.to > r.to))
                        {
                            console.log("R : ",r);
                            acceptable = false;
                            if(r.status_type === 'na') overlaps =  overlaps + `<br>N/A Date Range from ${r.from === -1 ? "Regime Start" : moment(this.toDateObject(r.from)).format("DD-MM-YYYY").toString()} to ${r.to === parseInt(process.env.REACT_APP_NO_END_DATE) ? "Present Day" : moment(this.toDateObject(r.to)).format("DD-MM-YYYY").toString()}`;
                            else if(r.status_type === 'shared') overlaps =  overlaps + `<br>Shared Date Range from ${r.from === -1 ? "Regime Start" : moment(this.toDateObject(r.from)).format("DD-MM-YYYY").toString()} to ${r.to === parseInt(process.env.REACT_APP_NO_END_DATE) ? "Present Day" : moment(this.toDateObject(r.to)).format("DD-MM-YYYY").toString()}`;
                            //else if(r.status_type === 'divided') overlaps =  overlaps + '<br>Divided Date Range from ' + r.from === -1 ? "Regime Start" : moment(this.toDateObject(r.from)).format("DD-MM_YYYY").toString() + ' to ' + r.to === process.env.REACT_APP_NO_END_DATE ? "Present Day" : moment(this.toDateObject(r.to)).format("DD-MM_YYYY").toString();
                        }
                    }
                    if(overlaps !== ""){
                        let yes_no_box_alert_param = {
                            "header" : "Are you sure?",
                            "subheader" : "Your date range overlaps with one or more NA/Shared date ranges",
                            "msg" : "Date Range overlaps with one or more [ Not Applicable | Shared ] date range(s).<br>" + overlaps,
                            "closefunc" : this.closeYesNoBox,
                            "buttons" : {
                                "OK" : {
                                    "caption" : "OK",
                                    "bgcolor" : "#24B3BF",
                                    "color" : "#fff",
                                    "callback" : this.closeYesNoBox
                                }
                            }
                        }
                        this.setState({ yes_no_box_alert_param });
                        //break;
                    }
                    if(na_date_range_status.active_count === 0 && shared_date_range_status.default_count === 1){
                        acceptable = false;
                        let date_range_to_del = JSON.parse(JSON.stringify(role_info.shared_date_ranges[0]));
                        date_range_to_del.action = 'delete'
                        console.log("date_range_to_del", date_range_to_del);
                        if(date_range_obj.from > -1 && date_range_obj.to === parseInt(process.env.REACT_APP_NO_END_DATE)){
                            console.log("check 11")
                            let a = moment(date_range_obj.from.toString(), "YYYYMMDD");
                            let b = moment(alt_from_date.toString(), "YYYYMMDD")
                            let date_diff = a.diff(b, 'days');
                            if(date_diff <= this.state.module_config_ovr_window) acceptable = true;
                            else{
                                for(let range of role_info.divided_date_ranges){
                                    if(range.action !== "delete" && date_range_obj.from >= range.from && date_range_obj.from <= range.to && range.from <= this.state.edit_date_range_backup.from){
                                        acceptable = true;
                                        break;
                                    }
                                }
                                if(acceptable === false){
                                    const payload = {command:"get_date_range_status", from: alt_from_date, to: date_range_obj.from, role_id:role_info.role_id, role_creation_date: role_info.created_at, date_range_object : date_range_to_del}
                                    const api = new APICall();
                                    let result = await api.commandWithoutCallback(payload);
                                    delete result["status_summary"]
                                    checkrange = {from: alt_from_date, to: this.decreaseOneDay(date_range_obj.from)}
                                    res_dates = await this.getStatusDates(checkrange, result, 'res')
                                    continuous_ranges = await this.getContinuousRanges(res_dates)
                                    err_ovrs = await this.getFaultyOverlaps(continuous_ranges, result)
                                    console.log("err_ovrs:", err_ovrs)
                                    if(Object.keys(err_ovrs).length === 0) acceptable = true;
                                }
                            }

                        }else if(date_range_obj.to < parseInt(process.env.REACT_APP_NO_END_DATE) && date_range_obj.from === -1){
                            console.log("check 12")
                            let b = moment(date_range_obj.to.toString(), "YYYYMMDD");
                            let a = moment(alt_end_date.toString(), "YYYYMMDD")
                            let date_diff = a.diff(b, 'days');
                            if(date_diff <= this.state.module_config_ovr_window) acceptable = true;
                            else{
                                for(let range of role_info.divided_date_ranges){
                                    if(range.action !== "delete" && date_range_obj.to >= range.from && date_range_obj.to <= range.to && range.to >= this.state.edit_date_range_backup.to){
                                        acceptable = true;
                                        break;
                                    }
                                }
                                if(acceptable === false){
                                    const payload = {command:"get_date_range_status", from: date_range_obj.to, to: alt_end_date, role_id:role_info.role_id, role_creation_date: role_info.created_at, date_range_object : date_range_to_del}
                                    const api = new APICall();
                                    let result = await api.commandWithoutCallback(payload);
                                    delete result["status_summary"]
                                    checkrange = {from: this.increaseOneDay(date_range_obj.to), to: alt_end_date}
                                    res_dates = await this.getStatusDates(checkrange, result, 'res')
                                    continuous_ranges = await this.getContinuousRanges(res_dates)
                                    err_ovrs = await this.getFaultyOverlaps(continuous_ranges, result)
                                    console.log("err_ovrs:", err_ovrs)
                                    if(Object.keys(err_ovrs).length === 0) acceptable = true;
                                }
                            }
                        }else if(date_range_obj.to < parseInt(process.env.REACT_APP_NO_END_DATE) && date_range_obj.from > -1){
                            console.log("check 13")
                            let b = moment(date_range_obj.to.toString(), "YYYYMMDD");
                            let a = moment(alt_end_date.toString(), "YYYYMMDD")
                            let date_diff1 = a.diff(b, 'days');
                            let c = moment(date_range_obj.from.toString(), "YYYYMMDD");
                            let d = moment(alt_from_date.toString(), "YYYYMMDD")
                            let date_diff2 = c.diff(d, 'days');
                            if(date_diff1 <= this.state.module_config_ovr_window && date_diff2 <= this.state.module_config_ovr_window) acceptable = true;
                            else{
                                for(let range of role_info.divided_date_ranges){
                                    if(range.action !== "delete" && this.state.edit_date_range_backup.from >= range.from && this.state.edit_date_range_backup.to <= range.to){
                                        acceptable = true;
                                        break;
                                    }
                                }
                                if(acceptable === false){
                                    const payload = {command:"get_date_range_status", from: alt_from_date, to: alt_end_date, role_id:role_info.role_id, role_creation_date: role_info.created_at, date_range_object : date_range_to_del}
                                    const api = new APICall();
                                    let result = await api.commandWithoutCallback(payload);
                                    delete result["status_summary"]
                                    checkrange = {from: alt_from_date, to: this.decreaseOneDay(date_range_obj.from)}
                                    res_dates = await this.getStatusDates(checkrange, result, 'res')
                                    continuous_ranges = await this.getContinuousRanges(res_dates)
                                    console.log("continuous_ranges ====",continuous_ranges);
                                    checkrange = {from: this.increaseOneDay(date_range_obj.to), to: alt_end_date}
                                    res_dates = await this.getStatusDates(checkrange, result, 'res')
                                    let continuous_ranges1 = await this.getContinuousRanges(res_dates)
                                    console.log("continuous_ranges1 ====",continuous_ranges1);
                                    continuous_ranges = continuous_ranges.concat(continuous_ranges1)
                                    err_ovrs = await this.getFaultyOverlaps(continuous_ranges, result)
                                    console.log("err_ovrs:", err_ovrs)
                                    if(Object.keys(err_ovrs).length === 0) acceptable = true;

                                }
                            }
                        }else{
                            acceptable = true;
                        }
                        if(acceptable === false && Object.keys(err_ovrs).length > 0){
                            let overlaps = this.prepareErrorOverlapMsg(err_ovrs);
                            let yes_no_box_alert_param = {
                                "header" : "Are you sure?",
                                "subheader" : "Your date range invalidates one or more assignments",
                                "msg" : overlaps,
                                "closefunc" : this.closeYesNoBox,
                                "buttons" : {
                                    "OK" : {
                                        "caption" : "OK",
                                        "bgcolor" : "#24B3BF",
                                        "color" : "#fff",
                                        "callback" : this.closeYesNoBox
                                    }
                                }
                            }
                            this.setState({ yes_no_box_alert_param });
                            break;
                        }
                    }
                    if(acceptable === true) {
                        for(let i=0; i<role_info.shared_date_ranges.length; i++){
                            if(role_info.shared_date_ranges[i].action === 'default') role_info.shared_date_ranges[i].action = 'delete';
                        }

                        role_info.shared_date_ranges.push(date_range_obj);
                    }else{
                        if(na_date_range_status.active_count >=0 && shared_date_range_status.default_count === 1){
                            role_info.shared_date_ranges = [];
                            role_info.is_shared = false;
                        }
                    }
                    break;
                case "divided":
                    let ranges3 = role_info.divided_date_ranges.concat(role_info.na_date_ranges);
                    for(let r of ranges3){
                        //console.log("date_range_obj.from : ", date_range_obj.from, " date_range_obj.to : ", date_range_obj.to, " r.from : ", r.from, " r.to : ",r.to);

                        if(r.action !== 'delete' && !(r.status_type === 'divided' && r.action === 'default') && (date_range_obj.from >= r.from && date_range_obj.from <= r.to || date_range_obj.to >= r.from && date_range_obj.to <= r.to || date_range_obj.from < r.from && date_range_obj.to > r.to))
                        {
                            console.log("R : ",r);
                            acceptable = false;
                            if(r.status_type === 'na') overlaps =  overlaps + `<br>N/A Date Range from ${r.from === -1 ? "Regime Start" : moment(this.toDateObject(r.from)).format("DD-MM-YYYY").toString()}  to ${r.to === parseInt(process.env.REACT_APP_NO_END_DATE) ? "Present Day" : moment(this.toDateObject(r.to)).format("DD-MM-YYYY").toString()}`;
                            //else if(r.status_type === 'shared') overlaps =  overlaps + '<br>Shared Date Range from ' + r.from === -1 ? "Regime Start" : moment(this.toDateObject(r.from)).format("DD-MM_YYYY").toString() + ' to ' + r.to === process.env.REACT_APP_NO_END_DATE ? "Present Day" : moment(this.toDateObject(r.to)).format("DD-MM_YYYY").toString();
                            else if(r.status_type === 'divided') overlaps =  overlaps + `<br>Divided Date Range from ${r.from === -1 ? "Regime Start" : moment(this.toDateObject(r.from)).format("DD-MM-YYYY").toString()}  to ${r.to === parseInt(process.env.REACT_APP_NO_END_DATE) ? "Present Day" : moment(this.toDateObject(r.to)).format("DD-MM-YYYY").toString()}`;
                        }
                    }
                    if(overlaps !== ""){
                        let yes_no_box_alert_param = {
                            "header" : "Are you sure?",
                            "subheader" : "Your date range overlaps with one or more NA/Divided date ranges",
                            "msg" : "Date Range overlaps with one or more [ Not Applicable | Divided ] date range(s).<br>" + overlaps,
                            "closefunc" : this.closeYesNoBox,
                            "buttons" : {
                                "OK" : {
                                    "caption" : "OK",
                                    "bgcolor" : "#24B3BF",
                                    "color" : "#fff",
                                    "callback" : this.closeYesNoBox
                                }
                            }
                        }
                        this.setState({ yes_no_box_alert_param });
                        break;
                    }

                    if(na_date_range_status.active_count === 0 && divided_date_range_status.default_count === 1){
                        acceptable = false;
                        let date_range_to_del = JSON.parse(JSON.stringify(role_info.divided_date_ranges[0]));
                        date_range_to_del.action = 'delete'
                        console.log("date_range_to_del", date_range_to_del);
                        if(date_range_obj.from > -1 && date_range_obj.to === parseInt(process.env.REACT_APP_NO_END_DATE)){
                            console.log("check 11")
                            let a = moment(date_range_obj.from.toString(), "YYYYMMDD");
                            let b = moment(alt_from_date.toString(), "YYYYMMDD")
                            let date_diff = a.diff(b, 'days');
                            if(date_diff <= this.state.module_config_ovr_window) acceptable = true;
                            else{
                                for(let range of role_info.shared_date_ranges){
                                    if(range.action !== "delete" && date_range_obj.from >= range.from && date_range_obj.from <= range.to && range.from <= this.state.edit_date_range_backup.from){
                                        acceptable = true;
                                        break;
                                    }
                                }
                                if(acceptable === false){
                                    const payload = {command:"get_date_range_status", from: alt_from_date, to: date_range_obj.from, role_id:role_info.role_id, role_creation_date: role_info.created_at, date_range_object : date_range_to_del}
                                    const api = new APICall();
                                    let result = await api.commandWithoutCallback(payload);
                                    delete result["status_summary"]
                                    checkrange = {from: alt_from_date, to: this.decreaseOneDay(date_range_obj.from)}
                                    res_dates = await this.getStatusDates(checkrange, result, 'res')
                                    continuous_ranges = await this.getContinuousRanges(res_dates)
                                    err_ovrs = await this.getFaultyOverlaps(continuous_ranges, result)
                                    console.log("err_ovrs:", err_ovrs)
                                    if(Object.keys(err_ovrs).length === 0) acceptable = true;
                                }
                            }

                        }else if(date_range_obj.to < parseInt(process.env.REACT_APP_NO_END_DATE) && date_range_obj.from === -1){
                            console.log("check 12")
                            let b = moment(date_range_obj.to.toString(), "YYYYMMDD");
                            let a = moment(alt_end_date.toString(), "YYYYMMDD")
                            let date_diff = a.diff(b, 'days');
                            if(date_diff <= this.state.module_config_ovr_window) acceptable = true;
                            else{
                                for(let range of role_info.shared_date_ranges){
                                    if(range.action !== "delete" && date_range_obj.to >= range.from && date_range_obj.to <= range.to && range.to >= this.state.edit_date_range_backup.to){
                                        acceptable = true;
                                        break;
                                    }
                                }
                                if(acceptable === false){
                                    const payload = {command:"get_date_range_status", from: date_range_obj.to, to: alt_end_date, role_id:role_info.role_id, role_creation_date: role_info.created_at, date_range_object : date_range_to_del}
                                    const api = new APICall();
                                    let result = await api.commandWithoutCallback(payload);
                                    delete result["status_summary"]
                                    checkrange = {from: this.increaseOneDay(date_range_obj.to), to: alt_end_date}
                                    res_dates = await this.getStatusDates(checkrange, result, 'res')
                                    continuous_ranges = await this.getContinuousRanges(res_dates)
                                    err_ovrs = await this.getFaultyOverlaps(continuous_ranges, result)
                                    console.log("err_ovrs:", err_ovrs)
                                    if(Object.keys(err_ovrs).length === 0) acceptable = true;
                                }
                            }
                        }else if(date_range_obj.to < parseInt(process.env.REACT_APP_NO_END_DATE) && date_range_obj.from > -1){
                            console.log("check 13")
                            let b = moment(date_range_obj.to.toString(), "YYYYMMDD");
                            let a = moment(alt_end_date.toString(), "YYYYMMDD")
                            let date_diff1 = a.diff(b, 'days');
                            let c = moment(date_range_obj.from.toString(), "YYYYMMDD");
                            let d = moment(alt_from_date.toString(), "YYYYMMDD")
                            let date_diff2 = c.diff(d, 'days');
                            if(date_diff1 <= this.state.module_config_ovr_window && date_diff2 <= this.state.module_config_ovr_window) acceptable = true;
                            else{
                                for(let range of role_info.shared_date_ranges){
                                    if(range.action !== "delete" && this.state.edit_date_range_backup.from >= range.from && this.state.edit_date_range_backup.to <= range.to){
                                        acceptable = true;
                                        break;
                                    }
                                }
                                if(acceptable === false){
                                    const payload = {command:"get_date_range_status", from: alt_from_date, to: alt_end_date, role_id:role_info.role_id, role_creation_date: role_info.created_at, date_range_object : date_range_to_del}
                                    const api = new APICall();
                                    let result = await api.commandWithoutCallback(payload);
                                    delete result["status_summary"]
                                    checkrange = {from: alt_from_date, to: this.decreaseOneDay(date_range_obj.from)}
                                    res_dates = await this.getStatusDates(checkrange, result, 'res')
                                    continuous_ranges = await this.getContinuousRanges(res_dates)
                                    console.log("continuous_ranges ====",continuous_ranges);
                                    checkrange = {from: this.increaseOneDay(date_range_obj.to), to: alt_end_date}
                                    res_dates = await this.getStatusDates(checkrange, result, 'res')
                                    let continuous_ranges1 = await this.getContinuousRanges(res_dates)
                                    console.log("continuous_ranges1 ====",continuous_ranges1);
                                    continuous_ranges = continuous_ranges.concat(continuous_ranges1)
                                    err_ovrs = await this.getFaultyOverlaps(continuous_ranges, result)
                                    console.log("err_ovrs:", err_ovrs)
                                    if(Object.keys(err_ovrs).length === 0) acceptable = true;

                                }
                            }
                        }else{
                            acceptable = true;
                        }
                        if(acceptable === false && Object.keys(err_ovrs).length > 0){
                            let overlaps = this.prepareErrorOverlapMsg(err_ovrs);
                            let yes_no_box_alert_param = {
                                "header" : "Are you sure?",
                                "subheader" : "Your date range invalidates one or more assignments",
                                "msg" : overlaps,
                                "closefunc" : this.closeYesNoBox,
                                "buttons" : {
                                    "OK" : {
                                        "caption" : "OK",
                                        "bgcolor" : "#24B3BF",
                                        "color" : "#fff",
                                        "callback" : this.closeYesNoBox
                                    }
                                }
                            }
                            this.setState({ yes_no_box_alert_param });
                            break;
                        }
                    }

                    if(acceptable === true) {
                        for(let i=0; i<role_info.divided_date_ranges.length; i++){
                            if(role_info.divided_date_ranges[i].action === 'default') role_info.divided_date_ranges[i].action = 'delete';
                        }

                        role_info.divided_date_ranges.push(date_range_obj);
                    }else{
                        if(na_date_range_status.active_count >=0 && divided_date_range_status.default_count === 1){
                            role_info.divided_date_ranges = [];
                            role_info.is_divided = false;
                        }
                    }
                    break;
            }
        }else if(date_range_data.mode === "edit"){

            if(date_range_obj.action === "nochange") return;
            // if(date_range_obj.from !== -1 && date_range_obj.from < role_info.created_at){
            //     acceptable = false;
            //     let yes_no_box_alert_param = {
            //         "header" : "Range From Date Error",
            //         "subheader" : "",
            //         "msg" : `Date range starting date preceeds the role creation date ${moment(role_info.created_at.toString(), "YYYYMMDD").format("DD-MM-YYYY")}.`,
            //         "closefunc" : this.closeYesNoBox,
            //         "buttons" : {
            //             "OK" : {
            //                 "caption" : "OK",
            //                 "bgcolor" : "#24B3BF",
            //                 "color" : "#fff",
            //                 "callback" : this.closeYesNoBox
            //             }
            //         }
            //     }
            //     this.setState({ yes_no_box_alert_param });
            //     return;
            // }
            switch(date_range_data.type){
                case "na":
                    let ranges1 = role_info.shared_date_ranges.concat(role_info.divided_date_ranges);
                    ranges1 = ranges1.concat(role_info.na_date_ranges)
                    ranges1 = ranges1.filter(item => item.temp_id !== this.state.edit_date_range_backup.temp_id);
                    for(let r of ranges1){
                        //console.log("date_range_obj.from : ", date_range_obj.from, " date_range_obj.to : ", date_range_obj.to, " r.from : ", r.from, " r.to : ",r.to);

                        if(r.action !== 'delete' && r.temp_id !== date_range_obj.temp_id && (date_range_obj.from >= r.from && date_range_obj.from <= r.to || date_range_obj.to >= r.from && date_range_obj.to <= r.to || date_range_obj.from < r.from && date_range_obj.to > r.to))
                        {
                            acceptable = false;
                            if(r.status_type === 'na') overlaps =  overlaps + `<br>N/A Date Range from ${r.from === -1 ? "Regime Start" : moment(this.toDateObject(r.from)).format("DD-MM-YYYY").toString()} to ${r.to === parseInt(process.env.REACT_APP_NO_END_DATE) ? "Present Day" : moment(this.toDateObject(r.to)).format("DD-MM-YYYY").toString()}`;
                            else if(r.status_type === 'shared') overlaps =  overlaps + `<br>Shared Date Range from ${r.from === -1 ? "Regime Start" : moment(this.toDateObject(r.from)).format("DD-MM-YYYY").toString()} to ${r.to === parseInt(process.env.REACT_APP_NO_END_DATE) ? "Present Day" : moment(this.toDateObject(r.to)).format("DD-MM-YYYY").toString()}`;
                            else if(r.status_type === 'divided') overlaps =  overlaps + `<br>Divided Date Range from ${r.from === -1 ? "Regime Start" : moment(this.toDateObject(r.from)).format("DD-MM-YYYY").toString()} to ${r.to === parseInt(process.env.REACT_APP_NO_END_DATE) ? "Present Day" : moment(this.toDateObject(r.to)).format("DD-MM-YYYY").toString()}`;
                        }
                    }
                    if(overlaps !== ""){
                        let yes_no_box_alert_param = {
                            "header" : "N/A Date Range Error",
                            "subheader" : "",
                            "msg" : "Date Range overlaps with one or more [NA | Shared | Divided] date range(s).<br>" + overlaps,
                            "closefunc" : this.closeYesNoBox,
                            "buttons" : {
                                "OK" : {
                                    "caption" : "OK",
                                    "bgcolor" : "#24B3BF",
                                    "color" : "#fff",
                                    "callback" : this.closeYesNoBox
                                }
                            }
                        }
                        this.setState({ yes_no_box_alert_param });
                        break;
                    }
                    overlaps = "";
                    if(assignment_result.length !== 0){
                        acceptable = false;
                        overlaps = role_info.role_code + " was assigned to";
                        for(let res of assignment_result){
                            overlaps = overlaps + `<br>${this.state.listContacts.find(item => item.ID === res.contact_id.toString()).ContactName} from ${res.effective_from_date === -1 ? "Regime Start" : moment(this.toDateObject(res.effective_from_date)).format("DD-MM-YYYY").toString()} to ${res.effective_till_date === parseInt(process.env.REACT_APP_NO_END_DATE) ? "Present Day" : moment(this.toDateObject(res.effective_till_date)).format("DD-MM-YYYY").toString()}`;
                        }
                    }
                    if(overlaps !== ""){
                        let yes_no_box_alert_param = {
                            "header" : "Are you sure?",
                            "subheader" : "Your date range overlaps with one or more Role Assignment(s)",
                            "msg" : overlaps + `<br><br>This overlaps with your date range. Please review your dates and change the assignments or 'Not Applicable' date range`,
                            "closefunc" : this.closeYesNoBox,
                            "buttons" : {
                                "OK" : {
                                    "caption" : "OK",
                                    "bgcolor" : "#24B3BF",
                                    "color" : "#fff",
                                    "callback" : this.closeYesNoBox
                                }
                            }
                        }
                        this.setState({ yes_no_box_alert_param });
                        break;
                    }
                    if(acceptable === true) {
                        let index=0;
                        for(index=0; index<role_info.na_date_ranges.length; index++){
                            if(role_info.na_date_ranges[index].temp_id === date_range_obj.temp_id)
                                break;
                        }
                        role_info.na_date_ranges[index] = date_range_obj;
                    }
                    break;
                case "shared":
                    let ranges2 = role_info.shared_date_ranges.concat(role_info.na_date_ranges);
                    ranges2 = ranges2.filter(item => item.temp_id !== this.state.edit_date_range_backup.temp_id);
                    for(let r of ranges2){
                        //console.log("date_range_obj.from : ", date_range_obj.from, " date_range_obj.to : ", date_range_obj.to, " r.from : ", r.from, " r.to : ",r.to);

                        if(r.action !== 'delete' && r.temp_id !== date_range_obj.temp_id && (date_range_obj.from >= r.from && date_range_obj.from <= r.to || date_range_obj.to >= r.from && date_range_obj.to <= r.to || date_range_obj.from < r.from && date_range_obj.to > r.to))
                        {
                            acceptable = false;
                            if(r.status_type === 'na') overlaps =  overlaps + `<br>N/A Date Range from ${r.from === -1 ? "Regime Start" : moment(this.toDateObject(r.from)).format("DD-MM-YYYY").toString()} to ${r.to === parseInt(process.env.REACT_APP_NO_END_DATE) ? "Present Day" : moment(this.toDateObject(r.to)).format("DD-MM-YYYY").toString()}`;
                            else if(r.status_type === 'shared') overlaps =  overlaps + `<br>Shared Date Range from ${r.from === -1 ? "Regime Start" : moment(this.toDateObject(r.from)).format("DD-MM-YYYY").toString()} to ${r.to === parseInt(process.env.REACT_APP_NO_END_DATE) ? "Present Day" : moment(this.toDateObject(r.to)).format("DD-MM-YYYY").toString()}`;
                            //else if(r.status_type === 'divided') overlaps =  overlaps + '<br>Divided Date Range from ' + r.from === -1 ? "Regime Start" : moment(this.toDateObject(r.from)).format("DD-MM-YYYY").toString() + ' to ' + r.to === parseInt(process.env.REACT_APP_NO_END_DATE) ? "Present Day" : moment(this.toDateObject(r.to)).format("DD-MM-YYYY").toString();
                        }
                    }
                    if(overlaps !== ""){
                        let yes_no_box_alert_param = {
                            "header" : "Are you sure?",
                            "subheader" : "Your date range overlaps with one or more NA/Shared date ranges",
                            "msg" : "Date Range overlaps with one or more [ Not Applicable | Shared ] date range(s).<br>" + overlaps,
                            "closefunc" : this.closeYesNoBox,
                            "buttons" : {
                                "OK" : {
                                    "caption" : "OK",
                                    "bgcolor" : "#24B3BF",
                                    "color" : "#fff",
                                    "callback" : this.closeYesNoBox
                                }
                            }
                        }
                        this.setState({ yes_no_box_alert_param });
                        break;
                    }
                    if(date_range_obj.action === "update" || date_range_obj.action === "insert" && date_range_obj.id === 0){
                        console.log("in update")
                        let acceptable = false;
                        if (date_range_obj.from < this.state.edit_date_range_backup.from && date_range_obj.to < this.state.edit_date_range_backup.from) {
                            console.log("left")
                            let existing_range = JSON.parse(JSON.stringify(this.state.edit_date_range_backup))
                            existing_range.action = "delete"
                            const payload = {command:"get_date_range_status", from: existing_range.from === -1 ? alt_from_date : existing_range.from, to: existing_range.to === parseInt(process.env.REACT_APP_NO_END_DATE) ? alt_end_date : existing_range.to, role_id:role_info.role_id, role_creation_date: role_info.created_at, date_range_object : existing_range}
                            const api = new APICall();
                            let result = await api.commandWithoutCallback(payload);
                            checkrange = {from: this.state.edit_date_range_backup.from === -1 ? alt_from_date : this.state.edit_date_range_backup.from, to: this.state.edit_date_range_backup.to === parseInt(process.env.REACT_APP_NO_END_DATE) ? alt_end_date : this.state.edit_date_range_backup.to }
                            res_dates = await this.getStatusDates(checkrange, result, 'res')
                            continuous_ranges = await this.getContinuousRanges(res_dates)
                            err_ovrs = await this.getFaultyOverlaps(continuous_ranges, result)
                            console.log("err_ovrs:", err_ovrs)
                            if(Object.keys(err_ovrs).length > 0){
                                let overlaps = this.prepareErrorOverlapMsg(err_ovrs);
                                let yes_no_box_alert_param = {
                                    "header" : "Are you sure?",
                                    "subheader" : "Your date range invalidates one or more assignments",
                                    "msg" : overlaps,
                                    "closefunc" : this.closeYesNoBox,
                                    "buttons" : {
                                        "OK" : {
                                            "caption" : "OK",
                                            "bgcolor" : "#24B3BF",
                                            "color" : "#fff",
                                            "callback" : this.closeYesNoBox
                                        }
                                    }
                                }
                                this.setState({ yes_no_box_alert_param });
                                break;
                            }
                            if(Object.keys(err_ovrs).length === 0) acceptable = true;
                            break

                        }
                        if (date_range_obj.from > this.state.edit_date_range_backup.to && date_range_obj.to > this.state.edit_date_range_backup.to) {
                            console.log("right")
                            let existing_range = JSON.parse(JSON.stringify(this.state.edit_date_range_backup))
                            existing_range.action = "delete"
                            const payload = {command:"get_date_range_status", from: existing_range.from === -1 ? alt_from_date : existing_range.from, to: existing_range.to === parseInt(process.env.REACT_APP_NO_END_DATE) ? alt_end_date : existing_range.to, role_id:role_info.role_id, role_creation_date: role_info.created_at, date_range_object : existing_range}
                            const api = new APICall();
                            let result = await api.commandWithoutCallback(payload);
                            checkrange = {from: this.state.edit_date_range_backup.from === -1 ? alt_from_date : this.state.edit_date_range_backup.from, to: this.state.edit_date_range_backup.to === parseInt(process.env.REACT_APP_NO_END_DATE) ? alt_end_date : this.state.edit_date_range_backup.to }
                            res_dates = await this.getStatusDates(checkrange, result, 'res')
                            continuous_ranges = await this.getContinuousRanges(res_dates)
                            err_ovrs = await this.getFaultyOverlaps(continuous_ranges, result)
                            console.log("err_ovrs:", err_ovrs)
                            if(Object.keys(err_ovrs).length > 0){
                                let overlaps = this.prepareErrorOverlapMsg(err_ovrs);
                                let yes_no_box_alert_param = {
                                    "header" : "Are you sure?",
                                    "subheader" : "Your date range invalidates one or more assignments",
                                    "msg" : overlaps,
                                    "closefunc" : this.closeYesNoBox,
                                    "buttons" : {
                                        "OK" : {
                                            "caption" : "OK",
                                            "bgcolor" : "#24B3BF",
                                            "color" : "#fff",
                                            "callback" : this.closeYesNoBox
                                        }
                                    }
                                }
                                this.setState({ yes_no_box_alert_param });
                                break;
                            }
                            if(Object.keys(err_ovrs).length === 0) acceptable = true;
                            break
                        }
                        // const payload = {command:"get_date_range_status", from: date_range_obj.from, to: this.state.edit_date_range_backup.to, role_id:role_info.role_id, role_creation_date: role_info.created_at, date_range_object : date_range_obj}
                        // const api = new APICall();
                        // let result = await api.commandWithoutCallback(payload);
                        // delete result["status_summary"]
                        if(date_range_obj.from > this.state.edit_date_range_backup.from && date_range_obj.to >= this.state.edit_date_range_backup.to){
                            console.log("check 1")
                            let a = moment(date_range_obj.from.toString(), "YYYYMMDD");
                            let b = moment(this.state.edit_date_range_backup.from.toString(), "YYYYMMDD")
                            let date_diff = a.diff(b, 'days');
                            if(date_diff <= this.state.module_config_ovr_window) acceptable = true;
                            else{
                                for(let range of role_info.divided_date_ranges){
                                    if(range.action !== "delete" && date_range_obj.from >= range.from && date_range_obj.from <= range.to && range.from <= this.state.edit_date_range_backup.from){
                                        acceptable = true;
                                        break;
                                    }
                                }
                                if(acceptable === false){
                                    const payload = {command:"get_date_range_status", from: this.state.edit_date_range_backup.from === -1 ? alt_from_date : this.state.edit_date_range_backup.from, to: date_range_obj.from, role_id:role_info.role_id, role_creation_date: role_info.created_at, date_range_object : date_range_obj}
                                    const api = new APICall();
                                    let result = await api.commandWithoutCallback(payload);
                                    delete result["status_summary"]
                                    checkrange = {from: this.state.edit_date_range_backup.from === -1 ? alt_from_date : this.state.edit_date_range_backup.from, to: this.decreaseOneDay(date_range_obj.from)}
                                    res_dates = await this.getStatusDates(checkrange, result, 'res')
                                    continuous_ranges = await this.getContinuousRanges(res_dates)
                                    err_ovrs = await this.getFaultyOverlaps(continuous_ranges, result)
                                    console.log("err_ovrs:", err_ovrs)
                                    if(Object.keys(err_ovrs).length === 0) acceptable = true;
                                }
                            }

                        }else if(date_range_obj.to < this.state.edit_date_range_backup.to && date_range_obj.from <= this.state.edit_date_range_backup.from){
                            console.log("check 2")
                            let b = moment(date_range_obj.to.toString(), "YYYYMMDD");
                            let a = moment(this.state.edit_date_range_backup.to.toString(), "YYYYMMDD")
                            let date_diff = a.diff(b, 'days');
                            if(date_diff <= this.state.module_config_ovr_window) acceptable = true;
                            else{
                                for(let range of role_info.divided_date_ranges){
                                    if(range.action !== "delete" && date_range_obj.to >= range.from && date_range_obj.to <= range.to && range.to >= this.state.edit_date_range_backup.to){
                                        acceptable = true;
                                        break;
                                    }
                                }
                                if(acceptable === false){
                                    const payload = {command:"get_date_range_status", from: date_range_obj.to, to: this.state.edit_date_range_backup.to === parseInt(process.env.REACT_APP_NO_END_DATE) ? alt_end_date : this.state.edit_date_range_backup.to, role_id:role_info.role_id, role_creation_date: role_info.created_at, date_range_object : date_range_obj}
                                    const api = new APICall();
                                    let result = await api.commandWithoutCallback(payload);
                                    delete result["status_summary"]
                                    checkrange = {from: this.increaseOneDay(date_range_obj.to), to: this.state.edit_date_range_backup.to === parseInt(process.env.REACT_APP_NO_END_DATE) ? alt_end_date : this.state.edit_date_range_backup.to}
                                    res_dates = await this.getStatusDates(checkrange, result, 'res')
                                    continuous_ranges = await this.getContinuousRanges(res_dates)
                                    err_ovrs = await this.getFaultyOverlaps(continuous_ranges, result)
                                    console.log("err_ovrs:", err_ovrs)
                                    if(Object.keys(err_ovrs).length === 0) acceptable = true;
                                }
                            }
                        }else if(date_range_obj.to < this.state.edit_date_range_backup.to && date_range_obj.from > this.state.edit_date_range_backup.from){
                            console.log("check 3")
                            let b = moment(date_range_obj.to.toString(), "YYYYMMDD");
                            let a = moment(this.state.edit_date_range_backup.to.toString(), "YYYYMMDD")
                            let date_diff1 = a.diff(b, 'days');
                            let c = moment(date_range_obj.from.toString(), "YYYYMMDD");
                            let d = moment(this.state.edit_date_range_backup.from.toString(), "YYYYMMDD")
                            let date_diff2 = c.diff(d, 'days');
                            if(date_diff1 <= this.state.module_config_ovr_window && date_diff2 <= this.state.module_config_ovr_window) acceptable = true;
                            else{
                                for(let range of role_info.divided_date_ranges){
                                    if(range.action !== "delete" && this.state.edit_date_range_backup.from >= range.from && this.state.edit_date_range_backup.to <= range.to){
                                        acceptable = true;
                                        break;
                                    }
                                }
                                if(acceptable === false){
                                    const payload = {command:"get_date_range_status", from: this.state.edit_date_range_backup.from === -1 ? alt_from_date : this.state.edit_date_range_backup.from, to: this.state.edit_date_range_backup.to === parseInt(process.env.REACT_APP_NO_END_DATE) ? alt_end_date : this.state.edit_date_range_backup.to, role_id:role_info.role_id, role_creation_date: role_info.created_at, date_range_object : date_range_obj}
                                    const api = new APICall();
                                    let result = await api.commandWithoutCallback(payload);
                                    delete result["status_summary"]
                                    checkrange = {from: this.state.edit_date_range_backup.from === -1 ? alt_from_date : this.state.edit_date_range_backup.from, to: this.decreaseOneDay(date_range_obj.from)}
                                    res_dates = await this.getStatusDates(checkrange, result, 'res')
                                    continuous_ranges = await this.getContinuousRanges(res_dates)
                                    checkrange = {from: this.increaseOneDay(date_range_obj.to), to: this.state.edit_date_range_backup.to === parseInt(process.env.REACT_APP_NO_END_DATE) ? alt_end_date : this.state.edit_date_range_backup.to}
                                    res_dates = await this.getStatusDates(checkrange, result, 'res')
                                    let continuous_ranges1 = await this.getContinuousRanges(res_dates)
                                    continuous_ranges = continuous_ranges.concat(continuous_ranges1)
                                    err_ovrs = await this.getFaultyOverlaps(continuous_ranges, result)
                                    console.log("err_ovrs:", err_ovrs)
                                    if(Object.keys(err_ovrs).length === 0) acceptable = true;

                                }
                            }
                        }else if(date_range_obj.to >= this.state.edit_date_range_backup.to && date_range_obj.from < this.state.edit_date_range_backup.from || date_range_obj.to > this.state.edit_date_range_backup.to && date_range_obj.from <= this.state.edit_date_range_backup.from){
                            console.log("check 4");
                            acceptable = true;
                        }
                        if(acceptable === false && Object.keys(err_ovrs).length > 0){
                            let overlaps = this.prepareErrorOverlapMsg(err_ovrs);
                            let yes_no_box_alert_param = {
                                "header" : "Are you sure?",
                                "subheader" : "Your date range invalidates one or more assignments",
                                "msg" : overlaps,
                                "closefunc" : this.closeYesNoBox,
                                "buttons" : {
                                    "OK" : {
                                        "caption" : "OK",
                                        "bgcolor" : "#24B3BF",
                                        "color" : "#fff",
                                        "callback" : this.closeYesNoBox
                                    }
                                }
                            }
                            this.setState({ yes_no_box_alert_param });
                            break;
                        }else if(acceptable === true){
                            for(let i=0; i< role_info.shared_date_ranges.length;i++){
                                if(role_info.shared_date_ranges[i].temp_id === date_range_obj.temp_id){
                                    role_info.shared_date_ranges[i] = date_range_obj;
                                    break;
                                }
                            }
                        }
                    }
                    break;
                case "divided":
                    let ranges3 = role_info.divided_date_ranges.concat(role_info.na_date_ranges);
                    ranges3 = ranges3.filter(item => item.temp_id !== this.state.edit_date_range_backup.temp_id);
                    for(let r of ranges3){
                        //console.log("date_range_obj.from : ", date_range_obj.from, " date_range_obj.to : ", date_range_obj.to, " r.from : ", r.from, " r.to : ",r.to);

                        if(r.action !== 'delete' && r.temp_id !== date_range_obj.temp_id && (date_range_obj.from >= r.from && date_range_obj.from <= r.to || date_range_obj.to >= r.from && date_range_obj.to <= r.to || date_range_obj.from < r.from && date_range_obj.to > r.to))
                        {
                            acceptable = false;
                            if(r.status_type === 'na') overlaps =  overlaps + `<br>N/A Date Range from ${r.from === -1 ? "Regime Start" : moment(this.toDateObject(r.from)).format("DD-MM-YYYY").toString()} to ${r.to === parseInt(process.env.REACT_APP_NO_END_DATE) ? "Present Day" : moment(this.toDateObject(r.to)).format("DD-MM-YYYY").toString()}`;
                            //else if(r.status_type === 'shared') overlaps =  overlaps + '<br>Shared Date Range from ' + r.from === -1 ? "Regime Start" : moment(this.toDateObject(r.from)).format("DD-MM-YYYY").toString() + ' to ' + r.to === parseInt(process.env.REACT_APP_NO_END_DATE) ? "Present Day" : moment(this.toDateObject(r.to)).format("DD-MM-YYYY").toString();
                            else if(r.status_type === 'divided') overlaps =  overlaps + `<br>Divided Date Range from ${r.from === -1 ? "Regime Start" : moment(this.toDateObject(r.from)).format("DD-MM_YYYY").toString()} to ${r.to === parseInt(process.env.REACT_APP_NO_END_DATE) ? "Present Day" : moment(this.toDateObject(r.to)).format("DD-MM_YYYY").toString()}`;
                        }
                    }
                    if(overlaps !== ""){
                        let yes_no_box_alert_param = {
                            "header" : "Are you sure?",
                            "subheader" : "Your date range overlaps with one or more NA/Divided date ranges",
                            "msg" : "Date Range overlaps with one or more [ Not Applicable | Divided ] date range(s).<br>" + overlaps,
                            "closefunc" : this.closeYesNoBox,
                            "buttons" : {
                                "OK" : {
                                    "caption" : "OK",
                                    "bgcolor" : "#24B3BF",
                                    "color" : "#fff",
                                    "callback" : this.closeYesNoBox
                                }
                            }
                        }
                        this.setState({ yes_no_box_alert_param });
                        break;
                    }
                    if(date_range_obj.action === "update" || date_range_obj.action === "insert" && date_range_obj.id === 0){
                        console.log("in update")
                        let acceptable = false;
                        if (date_range_obj.from < this.state.edit_date_range_backup.from && date_range_obj.to < this.state.edit_date_range_backup.from) {
                            console.log("left")
                            let existing_range = JSON.parse(JSON.stringify(this.state.edit_date_range_backup))
                            existing_range.action = "delete"
                            const payload = {command:"get_date_range_status", from: existing_range.from, to: existing_range.to === parseInt(process.env.REACT_APP_NO_END_DATE) ? alt_end_date : existing_range.to, role_id:role_info.role_id, role_creation_date: role_info.created_at, date_range_object : existing_range}
                            const api = new APICall();
                            let result = await api.commandWithoutCallback(payload);
                            checkrange = {from: this.state.edit_date_range_backup.from, to: this.state.edit_date_range_backup.to === parseInt(process.env.REACT_APP_NO_END_DATE) ? alt_end_date : this.state.edit_date_range_backup.to}
                            res_dates = await this.getStatusDates(checkrange, result, 'res')
                            continuous_ranges = await this.getContinuousRanges(res_dates)
                            err_ovrs = await this.getFaultyOverlaps(continuous_ranges, result)
                            console.log("err_ovrs:", err_ovrs)
                            if(Object.keys(err_ovrs).length > 0){
                                let overlaps = this.prepareErrorOverlapMsg(err_ovrs);
                                let yes_no_box_alert_param = {
                                    "header" : "Are you sure?",
                                    "subheader" : "Your date range invalidates one or more assignments",
                                    "msg" : overlaps,
                                    "closefunc" : this.closeYesNoBox,
                                    "buttons" : {
                                        "OK" : {
                                            "caption" : "OK",
                                            "bgcolor" : "#24B3BF",
                                            "color" : "#fff",
                                            "callback" : this.closeYesNoBox
                                        }
                                    }
                                }
                                this.setState({ yes_no_box_alert_param });
                                break;
                            }
                            if(Object.keys(err_ovrs).length === 0) acceptable = true;
                            break

                        }
                        if (date_range_obj.from > this.state.edit_date_range_backup.to && date_range_obj.to > this.state.edit_date_range_backup.to) {
                            console.log("right")
                            let existing_range = JSON.parse(JSON.stringify(this.state.edit_date_range_backup))
                            existing_range.action = "delete"
                            const payload = {command:"get_date_range_status", from: existing_range.from === -1 ? alt_from_date : existing_range.from, to: existing_range.to, role_id:role_info.role_id, role_creation_date: role_info.created_at, date_range_object : existing_range}
                            const api = new APICall();
                            let result = await api.commandWithoutCallback(payload);
                            checkrange = {from: this.state.edit_date_range_backup.from === -1 ? alt_from_date : this.state.edit_date_range_backup.from, to: this.state.edit_date_range_backup.to}
                            res_dates = await this.getStatusDates(checkrange, result, 'res')
                            continuous_ranges = await this.getContinuousRanges(res_dates)
                            err_ovrs = await this.getFaultyOverlaps(continuous_ranges, result)
                            console.log("err_ovrs:", err_ovrs)
                            if(Object.keys(err_ovrs).length > 0){
                                let overlaps = this.prepareErrorOverlapMsg(err_ovrs);
                                let yes_no_box_alert_param = {
                                    "header" : "Are you sure?",
                                    "subheader" : "Your date range invalidates one or more assignments",
                                    "msg" : overlaps,
                                    "closefunc" : this.closeYesNoBox,
                                    "buttons" : {
                                        "OK" : {
                                            "caption" : "OK",
                                            "bgcolor" : "#24B3BF",
                                            "color" : "#fff",
                                            "callback" : this.closeYesNoBox
                                        }
                                    }
                                }
                                this.setState({ yes_no_box_alert_param });
                                break;
                            }
                            if(Object.keys(err_ovrs).length === 0) acceptable = true;
                            break
                        }
                        // const payload = {command:"get_date_range_status", from: date_range_obj.from, to: this.state.edit_date_range_backup.to, role_id:role_info.role_id, role_creation_date: role_info.created_at, date_range_object : date_range_obj}
                        // const api = new APICall();
                        // let result = await api.commandWithoutCallback(payload);
                        // delete result["status_summary"]
                        if(date_range_obj.from > this.state.edit_date_range_backup.from && date_range_obj.to >= this.state.edit_date_range_backup.to){
                            console.log("check 1")
                            let a = moment(date_range_obj.from.toString(), "YYYYMMDD");
                            let b = moment(this.state.edit_date_range_backup.from.toString(), "YYYYMMDD")
                            let date_diff = a.diff(b, 'days');
                            if(date_diff <= this.state.module_config_ovr_window) acceptable = true;
                            else{
                                for(let range of role_info.shared_date_ranges){
                                    if(range.action !== "delete" && date_range_obj.from >= range.from && date_range_obj.from <= range.to && range.from <= this.state.edit_date_range_backup.from){
                                        acceptable = true;
                                        break;
                                    }
                                }
                                if(acceptable === false){
                                    const payload = {command:"get_date_range_status", from: this.state.edit_date_range_backup.from === -1 ? alt_from_date : this.state.edit_date_range_backup.from, to: date_range_obj.from, role_id:role_info.role_id, role_creation_date: role_info.created_at, date_range_object : date_range_obj}
                                    const api = new APICall();
                                    let result = await api.commandWithoutCallback(payload);
                                    delete result["status_summary"]
                                    checkrange = {from: this.state.edit_date_range_backup.from === -1 ? alt_from_date : this.state.edit_date_range_backup.from, to: this.decreaseOneDay(date_range_obj.from)}
                                    // checkrange = {from: this.state.edit_date_range_backup.from, to: this.decreaseOneDay(date_range_obj.from)}
                                    res_dates = await this.getStatusDates(checkrange, result, 'res')
                                    continuous_ranges = await this.getContinuousRanges(res_dates)
                                    err_ovrs = await this.getFaultyOverlaps(continuous_ranges, result)
                                    console.log("err_ovrs:", err_ovrs)
                                    if(Object.keys(err_ovrs).length === 0) acceptable = true;
                                }
                            }

                        }else if(date_range_obj.to < this.state.edit_date_range_backup.to && date_range_obj.from <= this.state.edit_date_range_backup.from){
                            console.log("check 2")
                            let b = moment(date_range_obj.to.toString(), "YYYYMMDD");
                            let a = moment(this.state.edit_date_range_backup.to.toString(), "YYYYMMDD")
                            let date_diff = a.diff(b, 'days');
                            if(date_diff <= this.state.module_config_ovr_window) acceptable = true;
                            else{
                                for(let range of role_info.shared_date_ranges){
                                    if(range.action !== "delete" && date_range_obj.to >= range.from && date_range_obj.to <= range.to && range.to >= this.state.edit_date_range_backup.to){
                                        acceptable = true;
                                        break;
                                    }
                                }
                                if(acceptable === false){
                                    const payload = {command:"get_date_range_status", from: date_range_obj.to, to: this.state.edit_date_range_backup.to === parseInt(process.env.REACT_APP_NO_END_DATE) ? alt_end_date : this.state.edit_date_range_backup.to, role_id:role_info.role_id, role_creation_date: role_info.created_at, date_range_object : date_range_obj}
                                    const api = new APICall();
                                    let result = await api.commandWithoutCallback(payload);
                                    delete result["status_summary"]
                                    checkrange = {from: this.increaseOneDay(date_range_obj.to), to: this.state.edit_date_range_backup.to === parseInt(process.env.REACT_APP_NO_END_DATE) ? alt_end_date : this.state.edit_date_range_backup.to}
                                    //checkrange = {from: this.increaseOneDay(date_range_obj.to), to: this.state.edit_date_range_backup.to}
                                    res_dates = await this.getStatusDates(checkrange, result, 'res')
                                    continuous_ranges = await this.getContinuousRanges(res_dates)
                                    err_ovrs = await this.getFaultyOverlaps(continuous_ranges, result)
                                    console.log("err_ovrs:", err_ovrs)
                                    if(Object.keys(err_ovrs).length === 0) acceptable = true;
                                }
                            }
                        }else if(date_range_obj.to < this.state.edit_date_range_backup.to && date_range_obj.from > this.state.edit_date_range_backup.from){
                            console.log("check 3")
                            let b = moment(date_range_obj.to.toString(), "YYYYMMDD");
                            let a = moment(this.state.edit_date_range_backup.to.toString(), "YYYYMMDD")
                            let date_diff1 = a.diff(b, 'days');
                            let c = moment(date_range_obj.from.toString(), "YYYYMMDD");
                            let d = moment(this.state.edit_date_range_backup.from.toString(), "YYYYMMDD")
                            let date_diff2 = c.diff(d, 'days');
                            if(date_diff1 <= this.state.module_config_ovr_window && date_diff2 <= this.state.module_config_ovr_window) acceptable = true;
                            else{
                                for(let range of role_info.shared_date_ranges){
                                    if(range.action !== "delete" && this.state.edit_date_range_backup.from >= range.from && this.state.edit_date_range_backup.to <= range.to){
                                        acceptable = true;
                                        break;
                                    }
                                }
                                if(acceptable === false){
                                    const payload = {command:"get_date_range_status", from: this.state.edit_date_range_backup.from === -1 ? alt_from_date : this.state.edit_date_range_backup.from, to: this.state.edit_date_range_backup.to === parseInt(process.env.REACT_APP_NO_END_DATE) ? alt_end_date : this.state.edit_date_range_backup.to, role_id:role_info.role_id, role_creation_date: role_info.created_at, date_range_object : date_range_obj}
                                    const api = new APICall();
                                    let result = await api.commandWithoutCallback(payload);
                                    delete result["status_summary"]
                                    checkrange = {from: this.state.edit_date_range_backup.from === -1 ? alt_from_date : this.state.edit_date_range_backup.from, to: this.decreaseOneDay(date_range_obj.from)}
                                    res_dates = await this.getStatusDates(checkrange, result, 'res')
                                    continuous_ranges = await this.getContinuousRanges(res_dates)
                                    checkrange = {from: this.increaseOneDay(date_range_obj.to), to: this.state.edit_date_range_backup.to === parseInt(process.env.REACT_APP_NO_END_DATE) ? alt_end_date : this.state.edit_date_range_backup.to}
                                    res_dates = await this.getStatusDates(checkrange, result, 'res')
                                    let continuous_ranges1 = await this.getContinuousRanges(res_dates)
                                    continuous_ranges = continuous_ranges.concat(continuous_ranges1)
                                    err_ovrs = await this.getFaultyOverlaps(continuous_ranges, result)
                                    console.log("err_ovrs:", err_ovrs)
                                    if(Object.keys(err_ovrs).length === 0) acceptable = true;

                                }
                            }
                        }else if(date_range_obj.to >= this.state.edit_date_range_backup.to && date_range_obj.from < this.state.edit_date_range_backup.from || date_range_obj.to > this.state.edit_date_range_backup.to && date_range_obj.from <= this.state.edit_date_range_backup.from){
                            console.log("check 4");
                            acceptable = true;
                        }
                        if(acceptable === false && Object.keys(err_ovrs).length > 0){
                            let overlaps = this.prepareErrorOverlapMsg(err_ovrs);
                            let yes_no_box_alert_param = {
                                "header" : "Are you sure?",
                                "subheader" : "Your date range invalidates one or more assignments",
                                "msg" : overlaps,
                                "closefunc" : this.closeYesNoBox,
                                "buttons" : {
                                    "OK" : {
                                        "caption" : "OK",
                                        "bgcolor" : "#24B3BF",
                                        "color" : "#fff",
                                        "callback" : this.closeYesNoBox
                                    }
                                }
                            }
                            this.setState({ yes_no_box_alert_param });
                            break;
                        }else if(acceptable === true){
                            for(let i=0; i< role_info.divided_date_ranges.length;i++){
                                if(role_info.divided_date_ranges[i].temp_id === date_range_obj.temp_id){
                                    role_info.divided_date_ranges[i] = date_range_obj;
                                    break;
                                }
                            }
                        }
                    }
                    break;
            }

        }
        this.role_info = role_info;
        this.setState({ role_info, show_date_modal : false });
        let is_dirty = await this.checkRoleInfoChange(role_info);
        EventBus.raiseEvent('deactivate_ass_sec',is_dirty);
        // this.props.activateAssignmentTab(is_dirty);
        console.log("After Date Add ", this.state.role_info);
    }

    getStatusDates = async (range, dates, status) => {
        console.log("===============\nrange:", range)
        console.log("===============\ndates:", dates)
        console.log("===============\nstatus:", status)
        let date_arr = []
        if (Object.keys(dates).length !== 0) {
            for (let date=range.from; date<=range.to; date=this.increaseOneDay(date)) {
                //console.log("Dates[date] : ",dates[date.toString()]);
                if (dates[date.toString()].status === status && Object.keys(dates[date.toString()].assignment).length > 1) {
                    date_arr.push(date)
                }
            }
        }
        date_arr.sort()
        return date_arr
    }

    decreaseOneDay = (date) => {
        return parseInt(moment(date.toString(), 'YYYYMMDD').subtract(1, 'days').format('YYYYMMDD'))
    }

    increaseOneDay = (date) => {
        return parseInt(moment(date.toString(), 'YYYYMMDD').add(1, 'days').format('YYYYMMDD'))
    }

    getContinuousRanges = async (sorted_date_arr) => {
        let continuous_ranges = []
        let res_range_obj = {from: null, to: null}
        if (sorted_date_arr.length === 1) {
            res_range_obj = {from: sorted_date_arr[0], to: sorted_date_arr[0]}
            continuous_ranges.push(res_range_obj)
        } else {
            for (let i=0; i<sorted_date_arr.length; i++) {
                let prev_date = sorted_date_arr[i-1]
                let index_date = sorted_date_arr[i]
                if (prev_date !== undefined) {
                    let prev_date_moment = moment(prev_date.toString(), 'YYYYMMDD')
                    let index_date_moment = moment(index_date.toString(), 'YYYYMMDD')
                    let diff_days = moment.duration(index_date_moment.diff(prev_date_moment)).asDays()
                    if (diff_days === 1) {
                        res_range_obj.to = index_date
                        if (i === sorted_date_arr.length - 1) {
                            continuous_ranges.push(JSON.parse(JSON.stringify(res_range_obj)))
                        }
                    } else {
                        res_range_obj.to = prev_date
                        continuous_ranges.push(JSON.parse(JSON.stringify(res_range_obj)))
                        res_range_obj.from = index_date
                        res_range_obj.to = null
                        if (i === sorted_date_arr.length - 1) {
                            continuous_ranges.push({from: index_date, to: index_date})
                        }
                    }
                } else {
                    res_range_obj.from = index_date
                }
            }
        }
        return continuous_ranges
    }

    getFaultyOverlaps = async (continuous_ranges, dates) => {
        let err_ovrs = {}
        for (let range of continuous_ranges) {
            let from_moment = moment(range.from.toString(), 'YYYYMMDD')
            let to_moment = moment(range.to.toString(), 'YYYYMMDD')
            let range_diff = moment.duration(to_moment.diff(from_moment)).asDays()
            if (range_diff > this.state.module_config_ovr_window) {
                for (let date=range.from; date<=range.to; date=this.increaseOneDay(date)) {
                    let ovr_date = moment(date.toString(), 'YYYYMMDD').add(this.state.module_config_ovr_window, 'days').format('YYYYMMDD')
                    if (parseInt(ovr_date) <= range.to) {
                        let user_obj = dates[date.toString()].assignment
                        if (parseInt(ovr_date) <= range.to) {
                            for (let user_id in user_obj) {
                                if (user_obj[user_id].end_date >= ovr_date) {
                                    let range_key = `${range.from.toString()}-${range.to.toString()}`
                                    if (range_key in err_ovrs === false) {
                                        err_ovrs[range_key] = {[user_id]: {[user_obj[user_id].row_id.toString()]: user_obj[user_id]}}
                                    } else {
                                        if (user_id in err_ovrs[range_key] === true) {
                                            if (user_obj[user_id].row_id.toString() in err_ovrs[range_key][user_id] === false) {
                                                err_ovrs[range_key][user_id] = {[user_obj[user_id].row_id.toString()]: user_obj[user_id]}
                                            }
                                        } else {
                                            err_ovrs[range_key][user_id] = {[user_obj[user_id].row_id.toString()]: user_obj[user_id]}
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return err_ovrs
    }

    prepareErrorOverlapMsg = (err_ovrs) => {
        let overlaps = ``
        let inserted_rows = [];
        let list_contacts = Store.getStoreData('listcontacts');
        for(let range in err_ovrs){
            let res_range = err_ovrs[range];
            for(let user in res_range){
                let user_data = res_range[user]
                for(let row in user_data){
                    if(inserted_rows.includes(row) === false){
                        let start_date = user_data[row].start_date === -1 ? "Regime Start" : moment(user_data[row].start_date.toString(), "YYYYMMDD").format("DD-MM-YYYY");
                        let end_date = user_data[row].end_date === parseInt(process.env.REACT_APP_NO_END_DATE) ? "No End Date" : moment(user_data[row].end_date.toString(), "YYYYMMDD").format("DD-MM-YYYY");
                        let user_name = list_contacts.find(item => item.ID === user);
                        overlaps = overlaps + `<br>${user_name.ContactName} assigned from ${start_date} to ${end_date}`
                        inserted_rows.push(row);
                    }
                }
            }
        }
        overlaps = `The intended date range will invalidate the following assignments:<br>` + overlaps;
        return overlaps;
    }

    handleDeleteDateRange = (type,record_id) => async (e) => {
        e.preventDefault();
        //console.log("Date Range Data : " ,date_range_data);
        let role_info = JSON.parse(JSON.stringify(this.state.role_info));
        let date_ranges = role_info.na_date_ranges.concat(role_info.shared_date_ranges);
        date_ranges = date_ranges.concat(role_info.divided_date_ranges);
        let date_range_obj = date_ranges.find(item => item.temp_id === record_id);
        let acceptable = true;
        const postData = {"command":"rough_work","action":"assignments","role_id":role_info.role_id,"gc_ids":[0]};
        const api = new APICall();
        let result = await api.commandWithoutCallback(postData);

		console.log("Date Assignment Object : ", result);
        let index=-1;
        switch(type){
            case "na":
                for(index=0; index<role_info.na_date_ranges.length; index++){
                    if(role_info.na_date_ranges[index].temp_id === date_range_obj.temp_id)
                        break;
                }
                role_info.na_date_ranges[index].action = "delete";
                if(role_info.is_na === true){
                    let na_date_range_status = await this.statusCount(role_info.na_date_ranges);
                    if(na_date_range_status.active_count === 0) role_info.na_date_ranges.push({
                        id : 0,
                        role_id : role_info.role_id,
                        status_type : 'na',
                        reason : "default",
                        from : -1,
                        to : parseInt(process.env.REACT_APP_NO_END_DATE),
                        action : "default"
                    });
                }
                break;
            case "shared":
                let contained_in_div = false;
                for(let range of role_info.divided_date_ranges){
                    if(range.action !== 'delete' && date_range_obj.from >= range.from && date_range_obj.to <= range.to){
                        contained_in_div = true;
                        break;
                    }
                }
                if(contained_in_div === false){
                    for(let key in result.result){
                        if(parseInt(key)>=date_range_obj.from && parseInt(key)<=date_range_obj.to && Object.keys(result.result[key]).length > 1){
                            let yes_no_box_alert_param = {
                                "header" : "Shared Date Range Deletion Error",
                                "subheader" : "",
                                "msg" : "This role has been assigned to more than one person within this date range. Please check the assignments and change the effective dates to remove this Shared Date Range.",
                                "closefunc" : this.closeYesNoBox,
                                "buttons" : {
                                    "OK" : {
                                        "caption" : "OK",
                                        "bgcolor" : "#24B3BF",
                                        "color" : "#fff",
                                        "callback" : this.closeYesNoBox
                                    }
                                }
                            }
                            this.setState({ yes_no_box_alert_param });
                            return;
                        }
                    }
                }

                for(index=0; index<role_info.shared_date_ranges.length; index++){
                    if(role_info.shared_date_ranges[index].temp_id === date_range_obj.temp_id)
                        break;
                }
                role_info.shared_date_ranges[index].action = "delete";
                if(role_info.is_shared === true){
                    let shared_date_range_status = await this.statusCount(role_info.shared_date_ranges);
                    if(shared_date_range_status.active_count === 0) role_info.shared_date_ranges.push({
                        id : 0,
                        role_id : role_info.role_id,
                        status_type : 'shared',
                        reason : "default",
                        from : -1,
                        to : parseInt(process.env.REACT_APP_NO_END_DATE),
                        action : "default"
                    });
                }
                break;
            case "divided" :
                let contained_in_shr = false;
                for(let range of role_info.shared_date_ranges){
                    if(range.action !== 'delete' && date_range_obj.from >= range.from && date_range_obj.to <= range.to){
                        contained_in_shr = true;
                        break;
                    }
                }
                if(contained_in_shr === false){
                    for(let key in result.result){
                        if(parseInt(key)>=date_range_obj.from && parseInt(key)<=date_range_obj.to && Object.keys(result.result[key]).length > 1){
                            let yes_no_box_alert_param = {
                                "header" : "Divided Date Range Deletion Error",
                                "subheader" : "",
                                "msg" : "This role has been assigned to more than one person within this date range. Please check the assignments and change the effective dates to remove this Divided Date Range.",
                                "closefunc" : this.closeYesNoBox,
                                "buttons" : {
                                    "OK" : {
                                        "caption" : "OK",
                                        "bgcolor" : "#24B3BF",
                                        "color" : "#fff",
                                        "callback" : this.closeYesNoBox
                                    }
                                }
                            }
                            this.setState({ yes_no_box_alert_param });
                            return;
                        }
                    }
                }

                for(index=0; index<role_info.divided_date_ranges.length; index++){
                    if(role_info.divided_date_ranges[index].temp_id === date_range_obj.temp_id)
                        break;
                }
                role_info.divided_date_ranges[index].action = "delete";
                if(role_info.is_divided === true){
                    let divided_date_range_status = await this.statusCount(role_info.divided_date_ranges);
                    if(divided_date_range_status.active_count === 0) role_info.divided_date_ranges.push({
                        id : 0,
                        role_id : role_info.role_id,
                        status_type : 'divided',
                        reason : "default",
                        from : -1,
                        to : parseInt(process.env.REACT_APP_NO_END_DATE),
                        action : "default"
                    });
                }
                break;
        }
        let is_dirty = await this.checkRoleInfoChange(role_info);
        EventBus.raiseEvent('deactivate_ass_sec',is_dirty);
        // this.props.activateAssignmentTab(is_dirty);
        this.action = "memsave";
        this.role_info = role_info;
        this.setState({role_info})
    }

    checkRoleInfoChange = async (role_info) =>{
        let is_dirty = false;
        let role_info_from_prop = this.state.role_info_from_prop;
        if(role_info.role_id !==0 || role_info.status !== 'insert'){
            for(let key in role_info){
                if(typeof role_info[key] !== 'object'){
                    if(role_info[key] !== this.state.role_info_from_prop[key]){
                        role_info.status = 'update';
                        is_dirty = true;
                    }
                }
                console.log("Key type : ", key, typeof role_info[key]);
            }
        }
        let ranges = role_info.na_date_ranges.concat(role_info.shared_date_ranges)
        ranges = ranges.concat(role_info.divided_date_ranges)
        for(let r of ranges){
            if(r.id !== 0 && r.action==='delete' || r.action==='update' || r.action==='insert' || r.id === 0 && r.action === 'default'){
                is_dirty = true;
                break;
            }
        }
        console.log ("IS DIRTY : ", is_dirty)
        return is_dirty;
    }

    closeDateRangeModal = async () => {
        let role_info = JSON.parse(JSON.stringify(this.state.role_info));
        let na_date_range_status = await this.statusCount(role_info.na_date_ranges);
        let shared_date_range_status = await this.statusCount(role_info.shared_date_ranges);
        let divided_date_range_status = await this.statusCount(role_info.divided_date_ranges);
        if(na_date_range_status.active_count >= 1){
            if(role_info.is_shared === true && shared_date_range_status.default_count === 1) {
                role_info.is_shared = false;
                role_info.shared_date_ranges = [];
            }
            if(role_info.is_divided === true && divided_date_range_status.default_count === 1) {
                role_info.is_divided = false;
                role_info.divided_date_ranges = [];
            }
        }
        this.setState({ show_date_modal : false, role_info });
    }

    closeAlert = (result, stack) => {
		this.setState({ alert_param: null })
	}

    render(){
        // if (this.state.content === null) {
        //     return (<CSLLoader />);
        // }
        //console.log("Create Role");
        console.log("STATE here", this.state);
        return (
            <div>
                <ModalBody>
                    <div style={{ fontSize: "14px", marginTop: "10px", fontWeight: "700", paddingLeft : "3px" }}>Role Type:</div>
                    <Style.MRModalSelect style={{ height: "31px", marginTop: "10px", width : "100%", boxSizing : "border-box" }} onChange={this.handleRoleTypeChange} name="base" value={this.state.role_info.role_type_id} >
                            {
                                (() => {
                                    let role_type = [];
                                    this.state.role_type_options.forEach((item) => {
                                        role_type.push(<option key={item.id} value={item.id}>{item.name}</option>);
                                    })
                                    return role_type;
                                })()
                            }
                        </Style.MRModalSelect>
                        <div style={{ fontSize: "14px", marginTop: "10px", fontWeight: "700", paddingLeft : "2px" }}>Role:</div>
                        <div style={{ marginTop: "10px", display : "flex", flexDirection : "row", justifyContent : "flex-start", alignItems : "center", border : "1px solid #ddd", borderRadius : "5px", padding : "5px", width : "100%", boxSizing : "border-box"}}>
                            <span style={{ fontSize: "14px", padding : "2px", color : "##282828"}}>
                                {this.state.selected_role_type.base + "#"}
                            </span>
                            <span style={{ fontSize: "16px", padding : "2px", textAlign : "center", backgroundColor : "#fff"}}>
                                {this.state.selected_role_code_no.toString().padStart(2,'0')}
                            </span>
                            {
                                (() => {
                                    if(this.state.role_info.role_id === 0) return(
                                        <div style={{display : "flex", flexDirection : "row"}}>
                                            <div style={{ position: "relative", display : "flex", flexDirection : "column", alignItems : "center", rowGap : "2px", marginLeft : "12px"}}>
                                                <ArrowUp style={{ cursor : "pointer" }} onClick={this.setRoleNumaric('up')} />
                                                <ArrowDn style={{ cursor : "pointer" }} onClick={this.setRoleNumaric('down')} />
                                            </div>
                                            <div style={{ fontSize : "12px", fontStyle : "italic", marginLeft : "30px"}}>*Showing only available role codes</div>
                                        </div>
                                    )

                                })()
                            }
                        </div>
                        <div style={{ fontSize: "14px", marginTop: "10px", fontWeight: "700", paddingLeft : "2px" }}>
						    Title:
                        </div>
                        <Style.ModalInput type="text" name="title" style={{ width: "100%", height: "30px", marginTop : "7px", padding : "5px", boxSizing : "border-box"}} onChange={this.handleChange} value={this.state.role_info.title} />
                        <div style={{ fontSize: "14px", marginTop: "10px", fontWeight: "700", paddingLeft : "2px" }}>
                            Regulator Definition:
                        </div>
                        <Style.ModalTextArea type="text" name="reg_def" style={{ width: "100%", height: "70px", marginTop : "7px", padding : "5px", boxSizing : "border-box"}} onChange={this.handleChange} value={this.state.role_info.reg_def} />
                        <div style={{ fontSize: "14px", marginTop: "10px", fontWeight: "700", paddingLeft : "2px" }}>
                            Firm Definition:
                        </div>
                        <Style.ModalTextArea type="text" name="firm_def" style={{ width: "100%", height: "70px", marginTop : "7px", padding : "5px", boxSizing : "border-box"}} onChange={this.handleChange} value={this.state.role_info.firm_def} />
                        <div style={{ fontSize: "14px", marginTop: "10px", fontWeight: "700", paddingLeft : "2px" }}>
                            Comment:
                        </div>
                        <Style.ModalInput type="text" name="comment" style={{ width: "100%", height: "30px", marginTop : "7px", padding : "5px", boxSizing : "border-box"}} onChange={this.handleChange} value={this.state.role_info.comment} />
                        <div style={{ marginTop: "10px", display : "flex", alignItems : "center" }}>
                            {
                                (() => {
                                    if (this.state.role_info.is_mgr_req) {
                                        return <FaRegCheckSquare name="is_mgr_req" onClick={this.changeCheckStatus("is_mgr_req")} style={{ cursor: "pointer", marginRight: "10px", fontSize : "12px" }} />
                                    } else {
                                        return <FaRegSquare name="is_mgr_req" onClick={this.changeCheckStatus("is_mgr_req")} style={{ cursor: "pointer", marginRight: "10px", fontSize : "12px" }} />
                                    }
                                })()
                            }
                            <span style={{ fontSize: "14px", fontWeight: "700" }}>
                                The employee with this role/function does not require a manager.
                            </span>
                        </div>
                        <Style.HDivider style={{ width : "100%", marginLeft : "0px"}}/>
                        <div style={{ marginTop: "10px", display : "flex", alignItems : "center" }}>
                            {
                                (() => {
                                    if (this.state.role_info.is_na) {
                                        return <FaRegCheckSquare name="is_na" onClick={this.changeCheckStatus("is_na")} style={{ cursor: "pointer", marginRight: "10px", fontSize : "12px"}} />
                                    } else {
                                        return <FaRegSquare name="is_na" onClick={this.changeCheckStatus("is_na")} style={{ cursor: "pointer", marginRight: "10px", fontSize : "12px" }} />
                                    }
                                })()
                            }
                            <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                                This role/function is not applicable to this company
                            </span>
                        </div>
                        {(() => {
                            if (this.state.role_info.hasOwnProperty('is_na')) {
                                if (this.state.role_info['is_na']) {
                                    return (
                                        <React.Fragment>
                                            <CommentDiv>
                                                Please specify the date periods this role was Not Applicable to this company.
                                                You can enter multiple date periods if required.
                                            </CommentDiv>
                                            <ModalInnerHeader>
                                                Date Periods this role was Not Applicable
                                                <Circle style={{ cursor: "pointer" }} onClick={this.openDateRangeModal('na', 'add')}>+</Circle>
                                            </ModalInnerHeader>
                                            <ModalInnerBody style={{position : "relative"}}>
                                                <CSLTable add={false} export={false} processData={this.tableData} tableRows="3" id="na"/>
                                                {
                                                    (()=>{
                                                        if(this.state.show_na_submenu === true) {
                                                            return(
                                                                <SubMenu onMouseLeave={this.closeSubMenuOnMouseLeave} style={{top : this.state.selected_table_index%3 * 44 + 98}}>
                                                                    <SubMenuItem onClick={this.openDateRangeModal("na","edit",this.state.selected_record_id)}>Edit</SubMenuItem>
                                                                    <SubMenuItem onClick={this.handleDeleteDateRange('na',this.state.selected_record_id)}>Remove</SubMenuItem>
                                                                </SubMenu>
                                                            )
                                                        }

                                                    })()
                                                }
                                            </ModalInnerBody>
                                        </React.Fragment>
                                    )
                                } else {
                                    return null;
                                }
                            }
                            return null;
                        })()}
                        <div style={{ marginTop: "10px", display : "flex", alignItems : "center" }}>
                            {
                                (() => {
                                    if (this.state.role_info.is_shared) {
                                        return <FaRegCheckSquare name="is_shared" onClick={this.changeCheckStatus("is_shared")} style={{ cursor: "pointer", marginRight: "10px", fontSize : "12px"}} />
                                    } else {
                                        return <FaRegSquare name="is_shared" onClick={this.changeCheckStatus("is_shared")} style={{ cursor: "pointer", marginRight: "10px", fontSize : "12px" }} />
                                    }
                                })()
                            }
                            <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                                This role/function can be shared
                            </span>
                        </div>
                        {(() => {
                            if (this.state.role_info.hasOwnProperty('is_shared')) {
                                if (this.state.role_info['is_shared']) {
                                    return (
                                        <React.Fragment>
                                            <CommentDiv>
                                                Please specify the date periods this role was available to be shared.
                                                You can enter multiple date periods if required.
                                            </CommentDiv>
                                            <ModalInnerHeader>
                                                Date Periods this role was able to be shared.
                                                <Circle style={{ cursor: "pointer" }} onClick={this.openDateRangeModal('shared', 'add')}>+</Circle>
                                            </ModalInnerHeader>
                                            <ModalInnerBody style={{position : "relative"}}>
                                                <CSLTable add={false} export={false} processData={this.tableData} tableRows="3" id="shared"/>
                                                {
                                                    (()=>{
                                                        if(this.state.show_shared_submenu === true) {
                                                            return(
                                                                <SubMenu onMouseLeave={this.closeSubMenuOnMouseLeave} style={{top : this.state.selected_table_index%3 * 44 + 98}}>
                                                                    <SubMenuItem onClick={this.openDateRangeModal("shared","edit",this.state.selected_record_id)}>Edit</SubMenuItem>
                                                                    <SubMenuItem onClick={this.handleDeleteDateRange('shared',this.state.selected_record_id)}>Remove</SubMenuItem>
                                                                </SubMenu>
                                                            )
                                                        }

                                                    })()
                                                }
                                            </ModalInnerBody>
                                        </React.Fragment>
                                    )
                                } else {
                                    return null;
                                }
                            }
                            return null;
                        })()}
                        <div style={{ marginTop: "10px", display : "flex", alignItems : "center" }}>
                            {
                                (() => {
                                    if (this.state.role_info.is_divided) {
                                        return <FaRegCheckSquare name="is_divided" onClick={this.changeCheckStatus("is_divided")} style={{ cursor: "pointer", marginRight: "10px", fontSize : "12px"}} />
                                    } else {
                                        return <FaRegSquare name="is_divided" onClick={this.changeCheckStatus("is_divided")} style={{ cursor: "pointer", marginRight: "10px", fontSize : "12px" }} />
                                    }
                                })()
                            }
                            <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                                This role/function can be divided
                            </span>
                        </div>
                        {(() => {
                            if (this.state.role_info.hasOwnProperty('is_divided')) {
                                if (this.state.role_info['is_divided']) {
                                    return (
                                        <React.Fragment>
                                            <CommentDiv>
                                                Please specify the date periods this role was available to be divided.
                                                You can enter multiple date periods if required.
                                            </CommentDiv>
                                            <ModalInnerHeader>
                                                Date Periods this role was able to be divided.
                                                <Circle style={{ cursor: "pointer" }} onClick={this.openDateRangeModal('divided', 'add')}>+</Circle>
                                            </ModalInnerHeader>
                                            <ModalInnerBody style={{position : "relative"}}>
                                                <CSLTable add={false} export={false} processData={this.tableData} tableRows="3" id="divided"/>
                                                {
                                                    (()=>{
                                                        if(this.state.show_divided_submenu === true) {
                                                            return(
                                                                <SubMenu onMouseLeave={this.closeSubMenuOnMouseLeave} style={{top : this.state.selected_table_index%3 * 44 + 98}}>
                                                                    <SubMenuItem onClick={this.openDateRangeModal("divided","edit",this.state.selected_record_id)}>Edit</SubMenuItem>
                                                                    <SubMenuItem onClick={this.handleDeleteDateRange('divided',this.state.selected_record_id)}>Remove</SubMenuItem>
                                                                </SubMenu>
                                                            )
                                                        }

                                                    })()
                                                }
                                            </ModalInnerBody>
                                        </React.Fragment>
                                    )
                                } else {
                                    return null;
                                }
                            }
                            return null;
                        })()}
                </ModalBody>
                <div style={{ width: "100%", height: "2px", backgroundColor: "#EBEBEB", marginTop: "20px" }}></div>
				<div style={{ textAlign: "right", marginTop: "20px", marginBottom : "20px" }}>
					<Style.ConfigBtn style={{ backgroundColor: "#FFFFFF", borderColor: "#8D8989", color: "#8D8989" }} onClick={this.props.closeModal}>Cancel</Style.ConfigBtn>
					<Style.ConfigBtn style={{ marginLeft: "15px", marginRight: "15px", backgroundColor: "#18B8A5" }} onClick={this.checkBeforeSave}>Save</Style.ConfigBtn>
				</div>
                {
					(() => {
						if (this.state.show_date_modal) {
							return (

                                    <DateRangeModal date_modal_bottom={this.state.date_modal_bottom} date_range_data={this.state.date_modal_data} saveDateRange={this.handleDateRange} closeModal={this.closeDateRangeModal} />
								)
						}
					})()
				}
                { this.state.yes_no_box_alert_param !== null && <YesNoBox alertParam={this.state.yes_no_box_alert_param}/>}
                <AlertBox alertParam={this.state.alert_param} />
            </div>
        )
    }
}

export default CreateRoles;
