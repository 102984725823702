import React from 'react';
import styled from 'styled-components';
import Store from './../../../Common/Store';
import Utils from './../../../Common/Utils';
import Iframe from 'react-iframe'
import moment from 'moment'
import jsPDF from 'jspdf'
import CSLPDFLoader from './../../Common/CSLPDFLoader'

import { FaTimes,FaAngleDown,FaRegCheckSquare,FaRegSquare,FaAngleUp } from 'react-icons/fa';

const roleScore = {
      "SMF" : 6,
      "SMP" : 5,
      "SMO" : 4,
      "CRR" : 3,
      "COC" : 2,
      "OTH" : 1,
      "Mis" : 0
};
const roleColor=["#e2e2e2", "#d88480", "#a6d9a1", "#efac4e", "#49668a", "#49668a", "#49668a" ];


const marginX = 12;
const marginY = 12;
const pageTop = marginY
const maxX = 210 - (marginX-2);
const maxY = 297 - (2 * marginY);
const levelVertGap = 8;
const levelHorzGap = 15;
const connectorLineOffset = 5;
const boxHeight = 45;
const boxWidth = 70;
const boxHeaderHeight = 6;
const boxBodyHeight = 22;
const boxFooterHeight = 8;
const footerPillWidth = (boxWidth / 4) - 4;
const footerPillGap = 2;
const boxRadius = 2;
const pageHeaderHeight = 10;
const effectivePageHeightP = Math.floor(maxY / (boxHeight + levelVertGap)) * (boxHeight + levelVertGap);
const effectivePageHeightL = Math.floor(maxX / (boxHeight + levelVertGap)) * (boxHeight + levelVertGap);
const connectorLineWidth = 1.25;
const pageHeaderLineWidth = 1;
const dottedLineLength = 8;

const PdfContainer = styled.div`
	position: absolute;
	width: calc(100vw - 325px);
	height:100%;
	z-index: 1011;
    background-color: white;
`;
const FootButton = styled.div`
	padding: 10px 15px;
    float: right;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;

`;

class PdfGenerator extends React.Component
{
	state = {pdfURL: null, ready: false, gstruct: null, table_headers: null, view_count: 0}

	reportPDF = null;
	cell_y = 0
	vGap = 8
	pageWidth = maxY;
	pageHeight = maxX + marginX;
	currentY =  marginX; 
	currentX =pageTop;
	titleHeight = 10
	titleFontHeight = 8
	lineHeight = 9
	tableLineHeight = 4.6
	inLineHeight = 6
	tabWidth = 30
	indentWidth = 10
	line_width = 0.25
	connector_line_width = 1.25

	connector_sep_width = 1.25

	cells = {}
	rows = []
	groupHeader = null
	pageBottom = marginX + maxX
	isFirstPage = true
	default_draw_color = '#3370B2'
	default_text_color = '#1E3E62'

	gov_committees = null
	gov_table = null
	members = null
	committee_ht = 6
	firstTab = 135
	secondTab = 200
	topPadding = 4
	member_height = 3.8

	rb_header_height = 8
	rb_width = 75
	rb_side_padding = 1.2
	rb_footer_height = 8
	rb_pill_width = 20
	rb_allRegimes = false
	rb_jobDesc = false
	rb_gov = false
	rb_roleDesc = false
	rb_header_line_ht = 6
	rb_top_padding = 1
	rb_body_line_height = 3.8
	font_offset = 5
	font_offset_small = 3
	rb_separator_length = 72

	tbl_header_lineheight = 6
	tbl_cell_lineheight = 3.8
	tbl_top_padding = 1
	tbl_side_padding = 1.2

	gt_first_tab = 90
	gt_second_tab = 140
	gt_third_tab = 190
	gt_current_header = null

	gm_lines = {}

	constructor(props) {
    	super(props);
  	}

    getRoleColor(role) {
      let key = role.substring(0,3);
      return roleColor[roleScore[key]];
    }

  	initPage = () => {
  		this.currentX = marginX
  		this.currentY = pageTop
  	}

  	generatePDF = () => {
		console.log('pdf props', this.props)
		// this.loggedin_user = Store.getStoreData('loggedin_user_info')
		// console.log('pdf loggedin_user', this.loggedin_user)
		this.currentX = marginY
		this.currentY = marginX
		this.drawFirstPage()
		this.drawGovMap()
		this.drawGovTable()
		// this.drawRespoMap()
		// this.drawSMRTable()
		// this.drawAssignmentMatrix()

		
		let blob = this.reportPDF.output('blob')
		let binaryData = [];
		binaryData.push(blob);
		let pdfURL = window.URL.createObjectURL(new Blob(binaryData, {type: "application/pdf"}));
		// console.log('PdfGenerator this.scrollDiv', this.scrollDiv)
		
		this.setState({ready: true, pdfURL: pdfURL})
  	}

  	setComponentState = () => {
  		setTimeout(this.generatePDF, 100)
  		this.setState({view_count: this.state.view_count + 1})
  	}

  	componentDidUpdate = (prevprops) => {
  		if(this.props === prevprops) return

  		this.setComponentState()
  	}

	componentDidMount()
	{
		this.setComponentState()
	}

	splitStringToFit = (text, width, font_size, font_style) => {

		let ret = []
		if (typeof text === "undefined") {
			//console.log("text",text);
			//text = 'No data'
			return [];
		}
		text = text.toString();
		
		//text = text.replace("þÿ","")
		this.reportPDF.setFontSize(font_size)
		this.setFontStyle(font_style)
		let words = text.split(' ')
		let left = 0
		let line = ''
		if (text.includes("maintain"))
			console.log("words",words)
		for(let w of words) {
			let l = this.reportPDF.getTextWidth(w + ' ')
			Utils.log('splitStringToFit width, l, w, ret', width, l, w, ret)
			if(left + l > width) {
				ret.push(line)
				left = l
				line = w + ' '
			} else {
				line += w + ' '
				left += l
			}
		}
		ret.push(line)
		Utils.log('splitStringToFit ret', ret)
		return ret	
		

	}

	measureGovmap = (committee, level, prev_r) => {
		Utils.log('committee, level, prev_r', committee, level, prev_r)
		this.maxLevel = level > this.maxLevel ? level : this.maxLevel
		committee.height = 0
		committee.members_height = 0
		committee.name = committee.json_data.committeename.replace(/\n/g, " ")
		committee.name_content = this.splitStringToFit(committee.name, this.firstTab - marginY, 11, 'bold')
		committee.height = committee.name_content.length > committee.height ? committee.name_content.length : committee.height

		committee.company_name_content = this.splitStringToFit(committee.company_name.replace(/\n/g, " "), this.secondTab - this.firstTab, 11, 'bold')
		committee.height = committee.company_name_content.length > committee.height ? committee.company_name_content.length : committee.height
		
		committee.purpose = committee.json_data.perposeofcommitee.replace(/\n/g, " ")
		committee.purpose_content = this.splitStringToFit(committee.purpose, this.pageWidth - marginY - this.secondTab , 11, 'bold')
		committee.height = committee.purpose_content.length > committee.height ? committee.purpose_content.length : committee.height
		Utils.log('pdfGenerator committee.purpose_content, committee.purpose_content.length, committee.height', committee.purpose_content, committee.purpose_content.length, committee.height)
		
		committee.members = committee.json_data.committeemembers
		committee.members_content = []
		committee.member_height = 0
		for(let cm of committee.members) {
			let this_member_content = this.splitStringToFit(cm.employeename, this.secondTab - this.firstTab, 9, 'normal')
			let this_role_content = this.splitStringToFit(cm.committeerolename, this.pageWidth - marginY - this.secondTab, 9, 'normal')
			let this_cell_height = this_member_content.length > this_role_content.length ? this_member_content.length : this_role_content.length
			committee.members_content.push({name: this_member_content, role: this_role_content, height: this_cell_height, is_member: true})
			committee.member_height += this_cell_height
		}
		committee.alt_members = committee.json_data.thirdpartyembers
		for(let am of committee.alt_members) {
			if(am.thirdpartyembername !== '') {
				let this_member_content = this.splitStringToFit(am.thirdpartyembername, this.secondTab - this.firstTab, 9, 'normal')
				let this_role_content = this.splitStringToFit(am.thirdpartyemberreason, this.pageWidth - marginY - this.secondTab, 9, 'normal')
				let this_cell_height = this_member_content.length > this_role_content.length ? this_member_content.length : this_role_content.length
				committee.members_content.push({name: this_member_content, role: this_role_content, height: this_cell_height, is_member: false})
				committee.member_height += this_cell_height
			}
		}
		committee.level = level

		for(let c of committee.children) {
			this.measureGovmap(c, level+1, committee)
		}
		
	}

	initGovMap = () => {
		this.gov_committees = Store.getStoreData('gov_committees')
		Utils.log('pdf this.gov_committees', this.gov_committees)
		this.members = Store.getStoreData('members')
		// this.currentY = 0
		if(this.gov_committees !== null) {
			for(let gm of this.gov_committees.children) {
				this.measureGovmap(gm, 0, null)
			}
		}
	}

	drawGovMap = () => {
		if(!this.props.includes.com_gov_map || !this.props.includes.com_gov)return
		this.addNewPage()
		let show_roles = this.props.includes.com_gov_map ? this.props.includes.com_gov_reg : false
		let header = show_roles ? 'Committees and Governance Structure (Advanced)' : 'Committees and Governance Structure'
		this.drawSectionHeader(header)
		this.initGovMap()
		for(let c of this.gov_committees.children) {
			Utils.log('pdf c', c)
			this.drawCommittee(c)
		}
	}

	drawCommitteeLines = (c) => {
		if(c.level ===0)return
		this.reportPDF.setLineWidth(this.connector_line_width)
		this.reportPDF.setDrawColor('#1BB2B2')
		for(let id in this.gm_lines) {
			let left = marginX + (this.indentWidth / 2) + this.indentWidth * this.gm_lines[id].level
			Utils.log('drawCommitteeLines c, gm_lines', c, this.gm_lines)
			if(id !== c.id.toString()) {
				this.reportPDF.line(left, this.gm_lines[id].top, left, this.gm_lines[id].bottom,  "S");
				this.gm_lines[id].top = this.gm_lines[id].bottom
			} else {
				let top = this.gm_lines[id].bottom - (c.height * this.committee_ht) + (this.committee_ht / 2)
				this.reportPDF.line(left, this.gm_lines[id].top, left, top,  "S");
				// Utils.log('gm_lines in equal c, gm_lines[id]', c, this.gm_lines[id])
				this.reportPDF.line(left, top, left + (this.indentWidth / 2) - this.connector_sep_width, top, 'S')
				
			}
		}
		this.reportPDF.setLineWidth(this.line_width)
	}

	drawMapLines = (c) => {
		// if(c.level !== 1)return
		// Utils.log('drawMapLines this.gm_lines', this.gm_lines)
		this.reportPDF.setLineWidth(this.connector_line_width)
		this.reportPDF.setDrawColor('#1BB2B2')
		for(let id in this.gm_lines) {
			let left = marginX + (this.indentWidth / 2) + this.indentWidth * this.gm_lines[id].level
			
			if(id !== c.id.toString() + '-' + c.parent_id) {
				if(this.gm_lines[id].level >= 0) {
					Utils.log('drawMapLines c, gm_lines', c, JSON.parse(JSON.stringify(this.gm_lines)))
					this.reportPDF.line(left, this.gm_lines[id].top, left, this.gm_lines[id].bottom,  "S");
				}
				Utils.log('drawMapLines outside c, gm_lines', c, JSON.parse(JSON.stringify(this.gm_lines)))
				this.gm_lines[id].top = this.gm_lines[id].bottom
			}
		}
		this.reportPDF.setLineWidth(this.line_width)
	}

	drawMapTerminationLine = (id, b) => {
		Utils.log('drawMapLines id, b, gm_lines', id, b, JSON.parse(JSON.stringify(this.gm_lines)))
		if(!(id in this.gm_lines))return
		let left = marginX + (this.indentWidth / 2) + this.indentWidth * this.gm_lines[id].level
		this.reportPDF.setLineWidth(this.connector_line_width)
		this.reportPDF.setDrawColor('#1BB2B2')
		this.reportPDF.line(left, this.gm_lines[id].top, left, b,  "S");
		this.reportPDF.line(left, b, left + (this.indentWidth / 2) - this.connector_sep_width, b,  "S");
		this.reportPDF.setLineWidth(this.line_width)
	}

	drawCommitteeLinesPageTop = () => {
		for(let id in this.gm_lines) {
			let left = marginX + (this.indentWidth / 2) + this.indentWidth * this.gm_lines[id].level
			this.reportPDF.setLineWidth(this.connector_line_width)
			this.reportPDF.setDrawColor('#FFFFFF')
			this.reportPDF.line(left, marginY, left, marginY + (this.vGap / 2),  "S");
			this.reportPDF.setDrawColor('#1BB2B2')
			// this.reportPDF.line(left, this.gm_lines[id].top, left, this.pageHeight - marginY - (this.vGap / 2),  "S");
			this.reportPDF.setLineDash([2,1],0);
			this.reportPDF.line(left, marginY, left, marginY + (this.vGap / 2),  "S");
			this.reportPDF.setLineDash();
			this.gm_lines[id].top = this.currentY
			this.reportPDF.setLineWidth(this.line_width)
			this.gm_lines[id].bottom = this.currentY
		}
	}

	drawCommitteeLinesPageBottom = () => {
		for(let id in this.gm_lines) {
			if(this.pageHeight - marginY < this.gm_lines[id].top)continue
			let left = marginX + (this.indentWidth / 2) + this.indentWidth * this.gm_lines[id].level
			this.reportPDF.setLineWidth(this.connector_line_width)

			this.reportPDF.setDrawColor('#1BB2B2')
			this.reportPDF.line(left, this.gm_lines[id].top, left, this.pageHeight - marginY - (this.vGap / 2),  "S");
			this.reportPDF.setDrawColor('#FFFFFF')
			this.reportPDF.line(left, this.pageHeight - marginY - (this.vGap / 2), left, this.pageHeight - marginY,  "S");
			this.reportPDF.setDrawColor('#1BB2B2')
			this.reportPDF.setLineDash([2,1],0);
			this.reportPDF.line(left, this.pageHeight - marginY - (this.vGap / 2), left, this.pageHeight - marginY,  "S");
			this.reportPDF.setLineDash();
			this.reportPDF.setLineWidth(this.line_width)
			this.gm_lines[id].top = marginY
			this.gm_lines[id].bottom = marginY
		}
	}

	drawCommitteeBox = (c) => {
		Utils.log('PDFlines c', c)
		if(this.currentY + c.height * this.committee_ht > this.pageHeight - marginX) {
			this.drawCommitteeLinesPageBottom()
			this.addNewPage()
			this.drawCommitteeLinesPageTop()
		}
		let x = this.currentX + this.indentWidth * c.level
		this.reportPDF.setTextColor('#6381A8')
		this.reportPDF.setFontSize(11);
		this.reportPDF.setDrawColor('#EAEAEA');
		this.reportPDF.setFillColor('#F7F7F7');
		Utils.log('pdf drawCommittee this.currentY, this.currentX', this.currentY, this.currentX)
		this.reportPDF.roundedRect(x, this.currentY, this.pageWidth - (this.indentWidth * c.level), c.height * this.committee_ht, 0, 0, "DF");
		let cell_y = 0
		for(let s of c.name_content) {
			this.outText(x+2, this.currentY + this.topPadding + cell_y, s, 'bold')
			cell_y += this.committee_ht
		}
		
		cell_y = 0
		for(let s of c.company_name_content) {
			this.outText(this.firstTab, this.currentY + this.topPadding + cell_y, s, 'bold')
			cell_y += this.committee_ht
		}

		cell_y = 0
		for(let s of c.purpose_content) {
			this.outText(this.secondTab, this.currentY + this.topPadding + cell_y, s, 'bold')
			cell_y += this.committee_ht
		}

		if(c.level === 1)Utils.log('second level before setting top this.currentY gm_lines', this.currentY, JSON.stringify(this.gm_lines))
		for(let id in this.gm_lines) {
			if(id === c.id.toString()) {
				this.gm_lines[id].bottom = this.currentY + c.height * this.committee_ht
				// if(c.is_last)this.gm_lines[id].bottom = this.currentY + this.rb_top_padding
				// else this.gm_lines[id].bottom = this.currentY + c.height * this.committee_ht
				this.gm_lines[id].terminating = true
			} else {
				this.gm_lines[id].bottom = this.currentY + c.height * this.committee_ht
			}
		}
		if(c.level === 1)Utils.log('second level this.currentY gm_lines', this.currentY, JSON.stringify(this.gm_lines))

		this.drawCommitteeLines(c)


		let length = c.children.length
		let count = 0
		for(let ch of c.children) {
			let is_last = count === length - 1 ? true : false
			this.gm_lines[ch.id] =  {
										level: c.level, 
										top: this.currentY + this.connector_sep_width + (c.height * this.committee_ht), 
										bottom: this.currentY + c.height * this.committee_ht, 
										terminating: false, 
										is_last: is_last
									}
			count++
		}
		if(c.level === 0)Utils.log('initial level this.currentY gm_lines', this.currentY, JSON.stringify(this.gm_lines))
		
		
		// this.outText(this.firstTab, this.currentY + this.topPadding, c.company_name, 'bold')
		// this.outText(this.secondTab, this.currentY + this.topPadding, c.purpose, 'bold')
		this.currentY += c.height * this.committee_ht
		// Utils.log('pdf this.currentY, c.height, this.committe_ht', this.currentY, c.height, this.committee_ht)
	}

	drawCommitteeBoxContd = (c) => {
		console.log("Sourac===>", this.gm_lines)
		console.log("Sourac===>", c)
		let x = this.currentX + this.indentWidth * c.level
		// this.currentY += this.committee_ht
		this.reportPDF.setFontSize(9);
		this.reportPDF.setDrawColor('#EAEAEA');
		this.reportPDF.setFillColor('#F7F7F7');
		Utils.log('pdf drawCommittee this.currentY, this.currentX', this.currentY, this.currentX)
		this.reportPDF.roundedRect(x, this.currentY, this.pageWidth - (this.indentWidth * c.level), c.height * this.member_height + (this.rb_top_padding), 0, 0, "DF");
		this.outText(x+2, this.currentY + this.topPadding, c.name + ' (...contd)', 'bold')
		this.outText(this.firstTab, this.currentY + this.topPadding, c.company_name, 'bold')
		this.outText(this.secondTab, this.currentY + this.topPadding, c.purpose, 'bold')
		let top = this.currentY + (this.committee_ht / 2)
		let left = marginX + (this.indentWidth / 2) + this.indentWidth * c.id in this.gm_lines ? this.gm_lines[c.id].level : 0
		Utils.log('in contdBox left, top', left, top)
		this.reportPDF.setLineWidth(this.connector_line_width)
		this.reportPDF.setDrawColor('#1BB2B2')
		this.reportPDF.line(left, this.currentY, left, top, 'S')
		this.reportPDF.line(left, top, left + (this.indentWidth / 2), top, 'S')
		this.currentY += (c.height * this.committee_ht) + (this.rb_top_padding * 2)
	}

	addNewPageInMembers = (c, cell_y) => {
		if(cell_y + this.currentY + this.member_height <= this.pageHeight - marginX)return cell_y
		this.drawCommitteeLinesPageBottom()
		this.addNewPage()
		this.drawCommitteeLinesPageTop()
		this.drawCommitteeBoxContd(c)
		return 0
	}

	drawMembersAndRolesBox = (c) => {
		let cell_y = 0
		this.reportPDF.setFontSize(9);
		if(this.props.includes.com_gov_reg) {
			this.currentY += this.member_height
			for(let m of c.members_content) {
				Utils.log('pdf m', m)
				cell_y = 0
				for(let n of m.name) {
					cell_y = this.addNewPageInMembers(c, cell_y)
					this.outText(this.firstTab, this.currentY + cell_y, n, 'normal')
					cell_y += this.member_height
				}
				cell_y = 0
				for(let n of m.role) {
					cell_y = this.addNewPageInMembers(c, cell_y)
					this.outText(this.secondTab, this.currentY + cell_y, n, 'normal')
					cell_y += this.member_height
				}
				this.currentY += m.height * this.member_height
			}
		}
		// Utils.log('pdf this.currentY', this.currentY)

		this.currentY += this.vGap
		Utils.log('pdf drawCommittee this.currentY, this.currentX', this.currentY, this.currentX)
	}

	drawCommittee = (c) => {
		this.drawCommitteeBox(c)
		this.drawMembersAndRolesBox(c)
		delete this.gm_lines[c.id]
		// Utils.log('pdf after vGap this.currentY', this.currentY)
		for(let ch of c.children) {
			this.drawCommittee(ch)
		}
	}

	drawSectionHeader =(header) => {
		this.reportPDF.setFontSize(16);
		this.outText(this.currentX, this.currentY+10, header, 'bold')
		this.currentY += 10 + this.vGap
	}

	drawGovTable = () => {
		if(!this.props.includes.com_gov_tab || !this.props.includes.com_gov)return
		this.addNewPage()
		let header = 'Boards and Committees'
		this.drawSectionHeader(header)
		this.initGovTable()
	}

	drawMembersCell = (r) => {
		
	}

	drawAttendeesCell = (r) => {
		
	}

	measureMembersCell = (r) => {
		if(r.is_header) {
			r.members_content = ['Members']
			return this.rb_header_line_ht
		} else {
			r.members_content = []
			for(let m of r.row.members) {
				if ('employeename' in  m )r.members_content.push(m.employeename)
			}
			return r.members_content.length * this.rb_body_line_height
		}
		return 6
	}

	measureAttendeesCell = (r) => {
		if(r.is_header) {
			r.attendees_content = ['Attendees']
			return this.rb_header_line_ht
		} else {
			r.attendees_content = []
			for(let m of r.row.attendees) {
				if ('thirdpartyembername' in  m )r.attendees_content.push(m.thirdpartyembername)
			}
			return r.attendees_content.length * this.rb_body_line_height
		}
		return 6
	}

	measurePurposeCell = (r) => {
		if(r.is_header) {
			r.purpose_content = ['Purpose and Remit']
			return this.rb_header_line_ht
		} else {
			r.purpose_content = this.splitStringToFit(r.row.purpose, this.pageWidth - marginY - this.gt_second_tab, 10, 'bold')
			return r.purpose_content.length * this.rb_body_line_height
		}
		return 6
	}

	renderGovTableHeaderRow = (r) => {
		this.gt_current_header = r
		if(this.rb_top_padding + this.rb_header_line_ht + this.font_offset + this.currentY > this.pageHeight) {
			this.addNewPage()
			// renderGovTableHeaderRow(this.gt_current_header)
		}
		this.roundedRect(marginX, this.currentY, this.pageWidth - (2 * marginY), this.rb_top_padding + this.rb_header_line_ht, 0.1, '#ffffff', '203764', true, true, true, true, true)
		this.reportPDF.setTextColor('#ffffff')
		this.reportPDF.setFontSize(10)

		let name = this.splitStringToFit(r.row.name, this.pageWidth - marginY - this.gt_second_tab, 10, 'bold')
		
		this.outText(marginX + this.rb_side_padding, this.currentY + this.font_offset, name[0], 'bold')
		this.outText(this.gt_first_tab + this.rb_side_padding, this.currentY + this.font_offset, r.members_content[0], 'bold')
		this.outText(this.gt_second_tab + this.rb_side_padding, this.currentY + this.font_offset, r.attendees_content[0], 'bold')
		this.outText(this.gt_third_tab + this.rb_side_padding, this.currentY + this.font_offset, r.purpose_content[0], 'bold')
		this.currentY += this.rb_top_padding + this.rb_header_line_ht + this.font_offset
		Utils.log('committees_tab after header draw this.currentY', this.currentY)
	}

	renderGovTableCommitteeRow = (r) => {
		this.reportPDF.setTextColor('#63758a')
		this.reportPDF.setFontSize(9)
		if(this.currentY + this.rb_top_padding + this.rb_body_line_height > this.pageHeight) {
			this.addNewPage()
			this.renderGovTableHeaderRow(this.gt_current_header)
		}
		let first_name = this.splitStringToFit(r.row.name, this.pageWidth - marginY - this.gt_second_tab, 10, 'bold')
		this.outText(marginX + this.rb_side_padding, this.currentY + this.rb_top_padding, first_name[0], 'bold')
		let i_offset = 0
		for(let i = 0; i < r.lines; i++) {
			if(this.currentY + this.rb_top_padding + ((i+1) * this.rb_body_line_height) > this.pageHeight) {
				this.addNewPage()
				this.renderGovTableHeaderRow(this.gt_current_header)
				this.reportPDF.setTextColor('#63758a')
				let second_name = this.splitStringToFit(r.row.name, this.pageWidth - marginY - this.gt_second_tab, 10, 'bold')
				this.outText(marginX + this.rb_side_padding, this.currentY + this.rb_top_padding, second_name[0] + ' (contd...)', 'bold')
				i_offset = i
				Utils.log('committees_tab add new page i, i_offset, this.currentY, r.lines', i, i_offset, this.currentY, r.lines)
			}
			let cell_y = (i-i_offset) * this.rb_body_line_height
			Utils.log('committees_tab outside add new page i, i_offset, this.currentY, r.lines, cell_y, r.members_content', i, i_offset, this.currentY, r.lines, cell_y,r)
			if(i < r.members_content.length)this.outText(this.gt_first_tab + this.rb_side_padding, this.currentY + this.rb_top_padding + cell_y, r.members_content[i], 'normal')
			if(i < r.attendees_content.length)this.outText(this.gt_second_tab + this.rb_side_padding, this.currentY + this.rb_top_padding + cell_y, r.attendees_content[i], 'normal')
			if(i < r.purpose_content.length)this.outText(this.gt_third_tab + this.rb_side_padding, this.currentY + this.rb_top_padding + cell_y, r.purpose_content[i], 'normal')
		}
		this.currentY += i_offset === 0 ? r.row_height + this.font_offset_small : r.row_height - (i_offset * this.rb_body_line_height) + this.font_offset_small
	}

	renderGovTableRow = (r) => {
		if(r.is_header) {
			this.renderGovTableHeaderRow(r)
		} else {
			this.renderGovTableCommitteeRow(r)
		}
	}

	renderGovTable = () => {
		for(let r of this.gov_table) {
			this.renderGovTableRow(r)
		}
	}

	initGovTable = () => {
		this.gov_table = Store.getStoreData('committees_tab')
		
		for(let r of this.gov_table) {
			r.row_height = 0
			r.lines = 0
			let members_height = this.measureMembersCell(r)
			let attendees_height = this.measureAttendeesCell(r)
			let purpose_height = this.measurePurposeCell(r)
			r.row_height = members_height > r.row_height ? members_height : r.row_height
			r.row_height = attendees_height > r.row_height ? attendees_height : r.row_height
			r.row_height = purpose_height > r.row_height ? purpose_height : r.row_height
			r.lines = r.members_content.length > r.lines ? r.members_content.length : r.lines
			r.lines = r.attendees_content.length > r.lines ? r.attendees_content.length : r.lines
			r.lines = r.purpose_content.length > r.lines ? r.purpose_content.length : r.lines
		}
		Utils.log('committees_tab this.gov_table', this.gov_table)
		this.renderGovTable()
		
	}


	// measureRespoMap = (members) => {
	// 	for(let m of members) {
	// 		m.name_content = this.splitStringToFit(m.name, this.rb_width, 9, 'bold')
	// 		m.name_content_small = this.splitStringToFit(m.name, this.rb_width, 8, 'normal')
	// 		let job_desc = m.job_desc.split("\n")
	// 		m.job_desc_content = []
	// 		for(let j of job_desc) {
	// 			let job_desc_content = this.splitStringToFit(j, this.rb_width - (2 * this.rb_side_padding), 9, 'normal')
	// 			m.job_desc_content = [...m.job_desc_content, ...job_desc_content]
	// 		}
	// 		// m.job_desc_content = this.splitStringToFit(m.job_desc, this.rb_width - (2 * this.rb_side_padding), 9, 'normal')
	// 		m.title_content = this.splitStringToFit(m.title, this.rb_width, 13, 'bold')
	// 		m.title_content_small = this.splitStringToFit(m.title + ' (...contd)', this.rb_width, 9, 'bold')
	// 		m.role_content = {}
	// 		let box_width = this.props.includes.respomap_show_gov ? 2 * this.rb_width - this.rb_pill_width - (4 * this.rb_side_padding) : this.rb_width - this.rb_pill_width - (4 * this.rb_side_padding)
	// 		for(let k in m.role) {
	// 			m.role_content[k] = this.splitStringToFit(m.role[k], box_width, 9, 'normal')
	// 		}
	// 		this.measureRespoMap(m.children)
	// 	}
	// }

	// measureRespoMapCommittees = () => {
	// 	for(let mid in this.respomap_committees) {
	// 		// this.respomap_committees[mid].company_content = this.splitStringToFit(this.respomap_committees[mid].company, this.rb_width, 10, 'normal')
	// 		for(let r of this.respomap_committees[mid]) {
	// 			r.company_content = this.splitStringToFit(r.company, this.rb_width, 10, 'bold')
	// 			for(let rr of r.committee_records) {
	// 				rr.committeename_content = this.splitStringToFit(rr.committeename, (this.rb_width / 2) - .5, 9, 'normal')
	// 				rr.committeerolename_content = this.splitStringToFit(rr.committeerolename, (this.rb_width / 2) - .5, 9, 'normal')
	// 			}	
	// 		}
	// 	}
	// }

	// rb_header_height = 8
	// rb_width = 60
	// rb_side_padding = 1.2
	// rb_footer_height = 8
	// rb_pill_width = 10
	// rb_allRegimes = false
	// rb_jobDesc = false
	// rb_gov = false
	// rb_roleDesc = false
	// rb_header_line_ht = 6
	// rb_top_padding = 1
	// rb_body_line_height = 3.8


	roundedRect = (x, y, w, h, r, color, bg_color, lt_round, rt_round, lb_round, rb_round, no_border) => {
		this.reportPDF.setFillColor(bg_color)
		this.reportPDF.setDrawColor(color)
		if(no_border)this.reportPDF.setDrawColor(bg_color)
		this.reportPDF.roundedRect(x, y, w, h, r, r, "DF")
		let l = x
		let t = y
		this.reportPDF.setDrawColor(bg_color)
		if(!lt_round) {l = x; t = y;this.reportPDF.rect(l, t, r, r,'DF')}
		if(!rt_round) {l = x + w - r; t = y; this.reportPDF.rect(l, t, r, r, 'DF')}
		if(!rb_round) {t = y + h - r; l = x + w - r;this.reportPDF.rect(l, t, r, r, 'DF')}
		if(!lb_round) {t = y + h - r; l = x;this.reportPDF.rect(l, t, r, r, 'DF')}
		this.reportPDF.setDrawColor(color)
		if(!lt_round) {l = x; t = y; this.reportPDF.line(l, t, l, t + r);this.reportPDF.line(l, t, l + r, t);}
		if(!rt_round) {l = x + w; t = y; this.reportPDF.line(l, t, l, t + r);this.reportPDF.line(l, t, l - r, t);}
		if(!rb_round) {l = x + w; t = y + h; this.reportPDF.line(l, t, l, t - r);this.reportPDF.line(l, t, l - r, t);}
		if(!lb_round) {l = x; t = y + h; this.reportPDF.line(l, t, l, t - r);this.reportPDF.line(l, t, l + r, t);}
	}

	drawBoxHeader = (m, w) => {
		this.reportPDF.setTextColor(this.default_text_color)
		let height = (m.name_content.length + m.title_content.length) * this.rb_header_line_ht + (2 * this.rb_top_padding)
		if(this.currentY + height > this.pageHeight - marginX) {
			this.drawCommitteeLinesPageBottom()
			this.addNewPage()
			this.drawCommitteeLinesPageTop()
		}
		this.currentX = marginY + (m.level * this.indentWidth)

		this.roundedRect(this.currentX, this.currentY, w, height, 2, '#3370B2', '#ffffff', true, true, false, false)
		this.reportPDF.setDrawColor('#ffffff')
		this.reportPDF.setFillColor('#ffffff')
		this.reportPDF.rect(this.currentX + .25, this.currentY + height - .25,  w - .5, .5, 'DF')
		this.drawMapTerminationLine(m.id.toString() + '-' + m.parent_id, this.currentY + (height / 2) - .25)
		delete this.gm_lines[m.id.toString() + '-' + m.parent_id.toString()]
		let line_height = 0
		for(let c of m.title_content) {
			this.reportPDF.setFontSize(13)
			this.outText(this.currentX + this.rb_side_padding, this.currentY + this.rb_top_padding + this.font_offset + line_height, c, 'bold')
			line_height += this.rb_header_line_ht
		}
		let underline_y = this.currentY + this.rb_top_padding + 2 * line_height
		for(let c of m.name_content) {
			this.reportPDF.setFontSize(10)
			this.outText(this.currentX + this.rb_side_padding, this.currentY + this.rb_top_padding + this.font_offset + line_height, c, 'normal')
			if(this.rb_gov) {
				this.reportPDF.setDrawColor('#D5D5D5')
				let x = this.currentX + (this.rb_side_padding * 2) + this.rb_width
				let y = this.currentY + this.rb_top_padding + line_height
				this.reportPDF.line(x, y, x, y + line_height + this.rb_top_padding)
				this.outText(x + this.rb_side_padding + 0.05, y + this.font_offset, 'Committees', 'bold')
				this.reportPDF.line(x + (this.rb_width - this.rb_separator_length) / 2, underline_y, x + (this.rb_width - this.rb_separator_length) / 2 + this.rb_separator_length, underline_y)
			}
			line_height += this.rb_header_line_ht
		}
		this.reportPDF.setDrawColor('#D5D5D5')

		this.reportPDF.line(this.currentX + (this.rb_width - this.rb_separator_length) / 2, underline_y, this.currentX + (this.rb_width - this.rb_separator_length) / 2 + this.rb_separator_length, underline_y)

		this.reportPDF.setDrawColor(this.default_draw_color)
		this.currentY += height
		Utils.log('drawCommitteeLines drawBoxHeader this.gm_lines, m', this.gm_lines, m)
		this.reviseMapLineBottoms(this.currentY)
		this.drawMapLines(m)
	}

	drawBoxContinuedHeader = (m, w) => {
		this.reportPDF.setTextColor(this.default_text_color)
		let height = (m.name_content_small.length + m.title_content_small.length) * this.rb_body_line_height + (2 * this.rb_top_padding)
		// if(this.currentY + height > this.pageHeight - marginX)this.addNewPage()
		this.currentX = marginY + (m.level * this.indentWidth)

		this.roundedRect(this.currentX, this.currentY, w, height, 2, '#3370B2', '#ffffff', true, true, false, false)
		let line_height = 0
		for(let c of m.title_content_small) {
			this.reportPDF.setFontSize(10)
			this.outText(this.currentX + this.rb_side_padding, this.currentY + this.rb_top_padding + this.font_offset_small + line_height, c, 'bold')
			line_height += this.rb_body_line_height
		}
		for(let c of m.name_content_small) {
			this.reportPDF.setFontSize(9)
			this.outText(this.currentX + this.rb_side_padding , this.currentY + this.rb_top_padding  + this.font_offset_small+ line_height, c, 'normal')
			if(this.rb_gov) {
				this.reportPDF.setDrawColor('#D5D5D5')
				let x = this.currentX + (this.rb_side_padding * 2) + this.rb_width
				let y = this.currentY + this.rb_top_padding + line_height
				this.reportPDF.line(x, y, x, y + line_height)
				this.outText(x + this.rb_side_padding, y + this.font_offset_small, 'Committees', 'bold')
			}
			line_height += this.rb_body_line_height
		}
		// this.reportPDF.setDrawColor('#ffffff')
		// this.reportPDF.setFillColor('#ffffff')
		// this.reportPDF.rect(this.currentX + .25, this.currentY + height - .25,  w - .5, .5, 'DF')
		this.reportPDF.setDrawColor(this.default_draw_color)
		this.currentY += height
		this.reviseMapLineBottoms(this.currentY)
		this.drawMapLines(m)
	}

	drawBoxBodyLine = (m, w, job_line, com_line1, com_line2, is_heading, is_last) => {
		console.log('pdf m, job_line, com_line1, com_line2, is_heading', m, job_line, com_line1, com_line2, is_heading)
		let right_x = this.currentX + w
		if(this.currentY + this.rb_body_line_height > this.pageHeight - marginX) {
			this.reportPDF.line(this.currentX, this.currentY, right_x, this.currentY)
			this.roundedRect(right_x - 25, this.currentY - 2, 20, 3.8, 2, '#3370B2', '#ffffff', true, true, true, true)
			this.outText(right_x - 20, this.currentY + 1, 'contd...', 'italic')
			this.drawCommitteeLinesPageBottom()
			this.addNewPage()
			this.drawCommitteeLinesPageTop()
			console.log('pdf right_x, this.currentX', right_x, this.currentX)
			this.drawBoxContinuedHeader(m, w)
			this.currentY += this.rb_top_padding + this.font_offset
			this.reviseMapLineBottoms(this.currentY)
			this.drawMapLines(m)
		}
		this.reportPDF.setDrawColor('#3370B2')
		// console.log('pdf outside right_x, this.currentX', right_x, this.currentX)
		this.reportPDF.line(this.currentX, this.currentY - this.rb_top_padding - this.font_offset, this.currentX, this.currentY + this.rb_body_line_height)
		let mid_x = this.rb_gov ? this.currentX + this.rb_width + (2 * this.rb_side_padding) : 0
		this.reportPDF.setTextColor(this.default_text_color)
		this.reportPDF.setFontSize(9)
		this.outText(this.currentX + this.rb_side_padding, this.currentY + this.rb_top_padding, job_line, 'normal')
		// right_x = this.rb_gov ? this.currentX + (4 * this.rb_side_padding) + (2 * this.rb_width) : this.currentX + this.rb_width + (2 * this.rb_side_padding)
		if(mid_x > 0 && !is_last){
			this.reportPDF.setDrawColor('#D5D5D5')
			this.reportPDF.line(mid_x, this.currentY - this.rb_top_padding - this.font_offset, mid_x, this.currentY + this.rb_body_line_height)
			this.reportPDF.setDrawColor('#3370B2')
			let style = is_heading ? 'bold' : 'normal'
			this.outText(mid_x + this.rb_side_padding, this.currentY + this.rb_top_padding, com_line1, style)
			this.outText(mid_x + this.rb_side_padding + (this.rb_width / 2), this.currentY + this.rb_top_padding, com_line2, 'normal')
		}
		this.reportPDF.line(right_x, this.currentY - this.rb_top_padding - this.font_offset, right_x, this.currentY + this.rb_body_line_height)
		if(is_last) {
			this.reportPDF.setDrawColor('#3370B2')
			this.reportPDF.line(this.currentX, this.currentY + this.rb_body_line_height, right_x, this.currentY + this.rb_body_line_height)
		}
		this.currentY += this.rb_body_line_height
		this.reviseMapLineBottoms(this.currentY)
		this.drawMapLines(m)
	}

	drawBoxBody = (m, w) => {
		this.committee_lines = []
		console.log('pdf m.id, this.respomap_committees', m.id, this.respomap_committees)
		if(m.id in this.respomap_committees) {
			for(let r of this.respomap_committees[m.id]) {
				for(let c of r.company_content) {
					this.committee_lines.push({line1: c, line2: '', is_heading: true})
				}
				for(let c of r.committee_records) {
					let lines = c.committeename_content.length < c.committeerolename_content.length ? c.committeerolename_content.length : c.committeename_content.length
					console.log('pdf drawBoxBody m.id, c', m.id, c)
					for(let i=0; i < lines; i++) {
						let elem = {line1: '', line2: '', is_heading: false}
						if(i < c.committeename_content.length)elem.line1 = c.committeename_content[i]
						if(i < c.committeerolename_content.length)elem.line2 = c.committeerolename_content[i]
						// console.log('pdf drawBoxBody i, c', m.id, c)
						this.committee_lines.push(elem)
					}
				}
			}
		} else {
			this.committee_lines.push({line1: 'No committee roles are currently assigned', line2: '', is_heading: false})
		}
		console.log('pdf respomap_committees, committee_lines', m.id, this.respomap_committees, this.committee_lines)
		this.box_body_lines = this.committee_lines.length < m.job_desc_content.length ? m.job_desc_content.length : this.committee_lines.length
		if(this.rb_jobDesc && !this.rb_gov)this.box_body_lines = m.job_desc_content.length
		if(!this.rb_jobDesc && this.rb_gov)this.box_body_lines = this.committee_lines.length
		if(!this.rb_jobDesc && !this.rb_gov)this.box_body_lines = 0
		// this.box_body_lines = this.rb_jobDesc
		this.drawBoxBodyLine(m, w, '', '', '', false, false)
		for(let i = 0; i < this.box_body_lines; i++) {
			let job_line = i < m.job_desc_content.length && this.rb_jobDesc ? m.job_desc_content[i] : ''
			let com_line1 = ''
			let com_line2 = ''
			let is_heading = false
			if(i < this.committee_lines.length && this.rb_gov){
				com_line1 = this.committee_lines[i].line1
				com_line2 = this.committee_lines[i].line2
				is_heading = this.committee_lines[i].is_heading
			}

			this.drawBoxBodyLine(m, w, job_line, com_line1, com_line2, is_heading, false)
		}
		this.drawBoxBodyLine(m, w, '', '', '', false, true)
	}

	drawPill = (x, y, content) => {
		let color = this.getRoleColor(content)
		let text_width = this.reportPDF.getTextWidth(content)
		let x_offset = (this.rb_pill_width - text_width) / 2
		console.log('pdf pill content, color', content, color)
		this.roundedRect(x, y, this.rb_pill_width, this.rb_body_line_height + (this.rb_top_padding * 2), 2, '#3370B2', '#ffffff', true, true, true, true)
		this.reportPDF.setTextColor(color)
		this.reportPDF.setFontSize(10)
		this.outText(x + x_offset, y + this.rb_top_padding + this.font_offset_small, content)
		this.reportPDF.setTextColor(this.default_text_color)
	}

	drawFooterLineWithRoleDescs = (m, role, content, width, height, is_last) => {
		height = is_last ? height + 1 : height
		if(this.currentY + height > this.pageHeight - marginX) {
			// this.reportPDF.line(this.currentX, this.currentY, right_x, this.currentY)
			let right_x = this.currentX + width
			this.roundedRect(right_x - 25, this.currentY - 2, 20, 3.8, 2, '#3370B2', '#ffffff', true, true, true, true)
			this.outText(right_x - 20, this.currentY + 1, 'contd...', 'italic')
			this.drawCommitteeLinesPageBottom()
			this.addNewPage()
			this.drawBoxContinuedHeader(m, width)
			this.drawCommitteeLinesPageTop()
			// console.log('pdf right_x, this.currentX', right_x, this.currentX)
			
			// this.currentY += this.rb_top_padding + this.font_offset
		}
		this.roundedRect(this.currentX, this.currentY, width, height, 2, '#3370B2', '#3370B2', false, false, is_last, is_last)
		this.drawPill(this.currentX + this.rb_side_padding, this.currentY + this.rb_top_padding, role)
		let y_offset = this.rb_top_padding + this.font_offset_small
		for(let c of content) {
			this.reportPDF.setTextColor('#ffffff')
			this.outText(this.currentX + 2 * this.rb_side_padding + this.rb_pill_width, this.currentY + y_offset, c, 'normal')
			y_offset += this.rb_body_line_height
		}
		this.reportPDF.setTextColor(this.default_text_color)
		this.currentY += height
	}

	drawFooterWithRoleDescs = (m, w) => {
		let content_length = Object.keys(m.role_content).length
		let i = 0

		for(let r in m.role_content) {
			i++
			let is_last = i < content_length ? false : true
			let line_height = m.role_content[r].length * this.rb_body_line_height + this.rb_top_padding
			line_height = line_height < this.rb_header_line_ht + this.rb_top_padding ? this.rb_header_line_ht + this.rb_top_padding : line_height
			this.drawFooterLineWithRoleDescs(m, r, m.role_content[r], w, line_height, is_last)
		}
		if(i === 0)this.currentY += this.rb_header_line_ht + (2 * this.rb_top_padding)
	}

	drawFooterPills = (m, w) => {
		let line_len = Math.floor((w - (this.rb_side_padding * 2)) / (this.rb_pill_width + this.rb_side_padding))
		let n_pills = Object.keys(m.role_content).length
		let n_lines = Math.floor(n_pills / line_len)
		n_lines = n_lines * line_len === n_pills ? n_lines : n_lines + 1
		let counter = 0
		let x = this.currentX + this.rb_side_padding
		let y = this.currentY + this.rb_top_padding - this.rb_header_line_ht
		for(let r in m.role_content) {
			if(counter % line_len === 0) {
				x = this.currentX + this.rb_side_padding
				y += this.rb_header_line_ht + this.rb_top_padding
				let is_last = Math.floor(counter / line_len) === n_lines - 1 ? true : false
				this.roundedRect(x - this.rb_side_padding, y - this.rb_side_padding, w, this.rb_header_line_ht + (2 * this.rb_top_padding), 2, '#3370B2', '#3370B2', false, false, is_last, is_last)
			} else {
				x += this.rb_pill_width + this.rb_side_padding
			}
			this.drawPill(x, y, r)
			counter++
		}
		this.currentY = y + this.rb_header_line_ht + (2 * this.rb_top_padding)
	}

	drawBoxFooter = (m, w) => {
		if(Object.keys(m.role_content).length === 0) {
			let height = this.rb_header_line_ht + (2 * this.rb_top_padding)
			this.roundedRect(this.currentX, this.currentY, w, height, 2, '#3370B2', '#3370B2', false, false, true, true)
		}
		if(this.rb_roleDesc) {
			this.drawFooterWithRoleDescs(m, w)
		} else {
			this.drawFooterPills(m, w)
		}
		// this.currentY += this.vGap
		this.reviseMapLineBottoms(this.currentY)
		this.drawMapLines(m)
		
		let count = 0
		
		let top = this.currentY + this.connector_sep_width

		for(let mc of m.children) {
			count++
			this.gm_lines[mc.id.toString() + '-' + m.id] = {level: m.level, top: top, parent_id: m.id, bottom: top, terminating: false, is_last: count < m.children.length ? false : true}
		}
		Utils.log('initGmLines m.level this.gm_lines', m.level, JSON.parse(JSON.stringify(this.gm_lines)))
	}

	reviseMapLineTops = () => {
		for(let id in this.gm_lines) {
			this.gm_lines[id].top = this.gm_lines[id].bottom
		}
	}

	reviseMapLineBottoms = (bottom) => {
		for(let id in this.gm_lines) {
			this.gm_lines[id].top = bottom
		}
	}

	drawBox = (m) => {
		console.log('pdf this.rb_jobDesc, this.rb_gov', this.rb_jobDesc, this.rb_gov)
		let width = this.rb_gov ? 2 * (this.rb_width + 2 * this.rb_side_padding) : this.rb_width * this.rb_side_padding
		this.drawBoxHeader(m, width)
		this.drawBoxBody(m, width)
		this.drawBoxFooter(m, width)
		this.currentY += this.vGap
	}

	drawBoxAndMap = (map) => {
		console.log('pdf map', map)
		for(let m of map) {
			this.drawBox(m)
			this.drawBoxAndMap(m.children)
		}
	}


	// drawRespoMap = () => {
	// 	if(!this.props.includes.respomap)return
	// 	this.addNewPage()
	// 	this.gm_lines = {}
	// 	this.currentY = marginX
	// 	this.currentX = marginY
	// 	let header = 'Responsibilities Map'
	// 	this.drawSectionHeader(header)
	// 	this.respomap = Store.getStoreData('respomap')
	// 	this.respomap_committees = Store.getStoreData('respomap_committees');
	// 	Utils.log('drawMapLines respomap', this.respomap)
	// 	this.measureRespoMap(this.respomap)
	// 	this.measureRespoMapCommittees()
	// 	this.rb_allRegimes = this.props.includes.respomap_all_regimes
	// 	this.rb_jobDesc = this.props.includes.respomap_show_jobdesc
	// 	this.rb_gov = this.props.includes.respomap_show_gov
	// 	this.rb_roleDesc = this.props.includes.respomap_show_roledesc
	// 	console.log('pdf respomap this.state', this.props.includes)
	// 	console.log('pdf respomap respomap_committees', this.respomap, this.respomap_committees)
	// 	this.drawBoxAndMap(this.respomap)
	// }

	drawCRRCell = (data) => {
		if(data.is_header) {
			let cell_y = 0
			let s = this.splitStringToFit(data.content, data.width, 11, 'bold')
			for(let c of s) {
				this.outText(this.currentX + this.tbl_side_padding, this.currentY + cell_y + this.tbl_top_padding + this.font_offset_small, c, 'bold')
				cell_y += this.tbl_header_lineheight
			}
		} else {
			let cell_y = 0
			let s = this.splitStringToFit(data.content, data.width, 9, 'normal')
			Utils.log('pdf drawCRRCell s', s)
			for(let c of s) {
				this.outText(this.currentX + this.tbl_side_padding, this.currentY + cell_y + this.tbl_top_padding + this.font_offset_small, c, 'normal')
				cell_y += this.tbl_cell_lineheight
			}
		}
	}

	measureCRRCell = (data) => {
		if(data.is_header) {
			let s = this.splitStringToFit(data.content, data.width, 11, 'bold')
			return (s.length * this.tbl_header_lineheight + (2 * this.tbl_top_padding))
		} else {
			let s = this.splitStringToFit(data.content, data.width, 9, 'normal')

			return (s.length * this.tbl_cell_lineheight + (2 * this.tbl_top_padding))
		}
	}

	drawPRCell = (data) => {
		if(data.is_header) {
			let cell_y = 0;

			let s = this.splitStringToFit(data.content, data.width, 11, 'bold')
			for(let c of s) {
				this.outText(this.currentX + this.tbl_side_padding, this.currentY + cell_y + this.tbl_top_padding + this.font_offset_small, c, 'bold')
				cell_y += this.tbl_header_lineheight
			}
		} else {
			let cell_y = 0;
			let dataSplitLine = data.content.toString();
			let stringArray = dataSplitLine.split('\n');
			//console.log("stringArray",stringArray);
			for (var i = 0; i < stringArray.length - 1; i++) {
				
				let strin = stringArray[i].trim().replace(/[^\x00-\x7F]/g,"");

				let s = this.splitStringToFit(strin, data.width, 9, 'normal')
				console.log("stringArray inside" , stringArray[i].trim());
			Utils.log('pdf drawPRCell s', s)
			
			//this.reportPDF.html(data.content);
			for(let c of s) {
				/*this.reportPDF.html(c, {
				   callback: function() {
				     //this.reportPDF.save();
				   },
				   x: this.currentX,
				   y: this.currentY
				});*/
				

				this.outText(this.currentX + this.tbl_side_padding, this.currentY + cell_y + this.tbl_top_padding + this.font_offset_small , c, 'normal')
				cell_y += this.tbl_cell_lineheight
			}
			}
			
			
		}
	}

	measurePRCell = (data) => {
		if(data.is_header) {
			let s = this.splitStringToFit(data.content, data.width, 11, 'bold')
			return (s.length * this.tbl_header_lineheight + (2 * this.tbl_top_padding))
		} else {
			let s = this.splitStringToFit(data.content, data.width, 9, 'normal')

			return (s.length * this.tbl_cell_lineheight + (2 * this.tbl_top_padding)+7)
		}
	}

	drawSMRTable = () => {
		if(!this.props.includes.smrtable)return
		this.addNewPage()
		this.smr_table = Store.getStoreData('reporting_tables').smr_table;
		this.accepted_table = Store.getStoreData('reporting_tables').accepted_table;
        this.assigned_table = Store.getStoreData('reporting_tables').assigned_table;
		Utils.log('pdf smr_table', this.smr_table)
		this.currentY = marginX
		this.currentX = marginY
		let header = 'Senior Management Responsibilities'
		this.drawSectionHeader(header)
		let options = {
						show_header: true,
						header_bgcolor: '#F5F5FD',
						odd_row_bgcolor: '#F1F2F3',
						even_row_bgcolor: '#FFFFFF',
						header_color: '#3B4B8C',
						odd_row_color: '#482C6E',
						even_row_color: '#482C6E'
					  }
		let fields = [
						{name: 'ID', nickname: '', show: false, width: 0},
						{name: 'Name', nickname: 'Name', show: true, width: .8},
						{name: 'EmploymentStatus', nickname: 'Employment Status', show: true, width: 1},
						{name: 'SMFsHeld', nickname: 'SMFs Held', show: true, width: 1},
						{name: 'PrescribedResponsibity', nickname: 'Prescribed Responsibities', show: true, width: 1.5, drawcell_callback:this.drawPRCell, measurecell_callback:this.measurePRCell},
						{name: 'OverallResponsibity', nickname: 'Overall Responsibities', show: true, width: 1.5, drawcell_callback:this.drawPRCell, measurecell_callback:this.measurePRCell},
						{name: 'CertificationRegime', nickname: 'CRR Titles', show: true, width: 1, drawcell_callback:this.drawCRRCell, measurecell_callback:this.measureCRRCell}
					 ]
		let rows = []
		for(let r of this.smr_table) {
			let contact_id = r.Contact_ID
			let flag = 0
			
			for ( var i = 0; i < this.accepted_table.length; i++) {
				if (this.accepted_table[i].Contact_ID === contact_id){
					flag = 1
					break;
				}
			}
			for ( i = 0; i < this.assigned_table.length; i++) {
				if (this.assigned_table[i].Contact_ID === contact_id){
					flag = 1
					break;
				}
			}
			if (flag === 0) continue;
			let elem = {is_header: false, row: r}
			rows.push(elem)
		}
		this.drawTable({options: options, fields: fields, rows: rows})
	}
	

	drawAMCell = (data) => {
		console.log("data==>",data);
		if(data.is_header) {
			
			let cell_y = 0
			let s = this.splitStringToFit(data.content, data.width, 11, 'bold')
			for(let c of s) {
				this.outText(this.currentX + this.tbl_side_padding, this.currentY + cell_y + this.tbl_top_padding + this.font_offset_small, c, 'bold')
				cell_y += this.tbl_header_lineheight
			}
		} else {
			let cell_y = 0
			//console.log("data",data);
			let s = this.splitStringToFit(data.content, data.width, 9, 'normal')
			//Utils.log('pdf drawAMCell s==>', s);
			//let x = this.currentX ;
			//let y = this.currentY;
				
			for(let c of s) {
				//console.log("c ==", c);
				let x = this.currentX ;
				let y = this.currentY;
				if (c === "sh " | c === "div " | c === "sh(TBA) " | c === "div(TBA) ") {
					this.roundedRect(x + 5 , y + 1.5, 20, this.rb_header_line_ht - ( this.rb_top_padding), 2, '#01B0A2', '#01B0A2', true, true, true, true);
					this.reportPDF.setTextColor("#FFFFFF");
					this.outText(this.currentX + this.tbl_side_padding + 7, this.currentY + cell_y + this.tbl_top_padding + 1 + this.font_offset_small, c, 'normal');
				}

				if (c === "RA " ) {
					//console.log("RoleNotAssigned");
					this.reportPDF.setTextColor("gray");
					this.outText(this.currentX + this.tbl_side_padding , this.currentY + cell_y + this.tbl_top_padding + 1 + this.font_offset_small, "Role Not Assigned", 'italic');
				}
				if (c === "NA " ) {
					//console.log("RoleNotAssigned");
					this.reportPDF.setTextColor("gray");
					this.outText(this.currentX + this.tbl_side_padding , this.currentY + cell_y + this.tbl_top_padding + 1 + this.font_offset_small, "Not Applicable at this firm", 'italic');
				}
				this.reportPDF.setTextColor(this.default_text_color);
				if ( c === "something(TBA) ") {
					this.roundedRect(x + 5 , y + 1.5, 20 , this.rb_header_line_ht - ( this.rb_top_padding), 2, '#01B0A2', '#01B0A2', true, true, true, true);
					this.reportPDF.setTextColor("#FFFFFF");
					this.outText(this.currentX + this.tbl_side_padding + 7, this.currentY + cell_y + this.tbl_top_padding + 1 + this.font_offset_small, "(TBA)", 'normal');
				}
				if (c === "something " ){
					this.roundedRect(x + 5 , y + 1.5, 20 , this.rb_header_line_ht - ( this.rb_top_padding), 2, '#01B0A2', '#01B0A2', true, true, true, true);
				}
				
				
				cell_y += this.tbl_cell_lineheight
			}
		}
	}

	measureAMCell = (data) => {
		if(data.is_header) {
			let s = this.splitStringToFit(data.content, data.width, 11, 'bold')
			return (s.length * this.rb_header_line_ht + (2 * this.rb_top_padding))
		} else {
			console.log("in measureAMCell data",data);
			let s = this.splitStringToFit(data.content, data.width, 9, 'normal')

			return (s.length * this.rb_header_line_ht + (2 * this.rb_top_padding) )
		}
	}

	drawAssignmentMatrix = () => {
		if(!this.props.includes.amtable)return
		this.addNewPage()
		this.am_table = Store.getStoreData('reporting_tables').am_table;
		let nasmf_table = Store.getStoreData('reporting_tables').nasmf_table;
		this.accepted_table = Store.getStoreData('reporting_tables').accepted_table;
        this.assigned_table = Store.getStoreData('reporting_tables').assigned_table;
        let temp_arr = []
        for (var i = 0; i < nasmf_table.length; i++) {
         temp_arr[i] = nasmf_table[i].SeniorManagerFunction
        }
        this.nasmf_table = temp_arr;
        console.log("pdfnasmf",this.nasmf_table)
		Utils.log('pdf am_table', this.am_table)
		this.currentY = marginX
		this.currentX = marginY
		let header = 'Assignment Matrix'
		this.drawSectionHeader(header)
		let options = {
						show_header: true,
						header_bgcolor: '#F5F5FD',
						odd_row_bgcolor: '#F1F2F3',
						even_row_bgcolor: '#FFFFFF',
						header_color: '#3B4B8C',
						odd_row_color: '#482C6E',
						even_row_color: '#482C6E'
					  }



		var distinctEmpName = [];
        var distinctRoleCode = [];
		for (let i = 0; i < this.am_table.length; i++){

      			distinctEmpName.push(this.am_table[i].EmployeeName);
      			distinctRoleCode.push(this.am_table[i].RoleCode);

      	}
      	if (this.props.includes.amtable_na) {
          for (var i = 0; i < this.nasmf_table.length; i++) {
            distinctRoleCode.push(this.nasmf_table[i])
          }
        }
      	distinctEmpName = Array.from(new Set(distinctEmpName));
      	distinctRoleCode = Array.from(new Set(distinctRoleCode));

      	distinctEmpName = distinctEmpName.filter(function(value, index, arr){ return value !== "Null Name";});

      	distinctRoleCode.sort();

      	let tableNum = 1
        let emp_len = distinctEmpName.length
        while(emp_len>10){
          tableNum = tableNum + 1
          emp_len = emp_len - 10
        }

        let b = 0,e = 0
        let sliceEmp = []
        for ( i = 0; i < tableNum; i++) {

            e = e+10
            e = e > distinctEmpName.length ? distinctEmpName.length : e;
            sliceEmp[i] = distinctEmpName.slice(b,e)
                      //console.log("sliceEmp render",this.state.sliceEmp)
            b = b +10
            }

      	//this.setState({rowCountSMR: distinctRoleCode.length});

      	for ( var z = 0; z < tableNum; z++) {
      		if (z>0) {this.addNewPage()}
	      	distinctEmpName = sliceEmp[z]
	      	
	      	let fields = [{name: 'firstColumn', nickname: '', show: true, width: 0.8 }];
	        
			for(let m = 0 ; m <distinctEmpName.length; m++){

				//let str = 'Column'+m;
	        	let field ={name: distinctEmpName[m], nickname: distinctEmpName[m], show: true, width: 1.5, drawcell_callback:this.drawAMCell, measurecell_callback:this.measureAMCell};
	        	
	        	//let elem = {str: 'The Pacifier'};
	        	fields.push(field);
			}


			let rows = [];
			for(let m = 0 ; m <distinctRoleCode.length; m++){
				let flag = 0;
			    for ( i = 0; i < this.nasmf_table.length; i++) {
			        if (this.nasmf_table[i] === distinctRoleCode[m]) {
			          flag = 1;
			          break;
			        }
			    }
			    if (!flag) {
			    	let row = {'firstColumn': distinctRoleCode[m]};
		        	for(let i = 0;i<distinctEmpName.length;i++){
		        		row[distinctEmpName[i]] = ' ';
		        	}
		        	for(let j = 0 ; j< this.am_table.length; j++){
		        		
		        		
		        		
		        		if ( this.am_table[j].RoleCode === distinctRoleCode[m]) {

		        			if (this.am_table[j].EmployeeName === 'Null Name' ) {
		        				
		                
		        				row[distinctEmpName[parseInt(distinctEmpName.length/2)]] = "RA";
		        				console.log("pdf inside null name", row);
		        				//continue;

		        			}
		        			else{

		        				let flagTBA = "";
					              if (this.am_table[j].IsAccepted === false) {
					                flagTBA = "(TBA)"
					              }
		        				if (this.am_table[j].IsShared === true ) {
		        					row[this.am_table[j].EmployeeName] = "sh" + flagTBA;
		        					

		        					

		        				}
		        				else if(this.am_table[j].IsDivided === true){
		        					row[this.am_table[j].EmployeeName] = "div" + flagTBA;
		        					

		        				}
		        				else{
		        					row[this.am_table[j].EmployeeName] = "something" + flagTBA;
		        					

		        					

		        				}
		        				
		        			}
		        		}

		        	}
		        	let elem = {is_header:false,row:row};
					rows.push(elem);

			    } else {
			    	if (this.props.includes.amtable_na) {
			    		let row = {'firstColumn': distinctRoleCode[m]};
			        	for(let i = 0;i<distinctEmpName.length;i++){
			        		row[distinctEmpName[i]] = ' ';
			        	}
		              for (var i = 0; i < this.nasmf_table.length; i++) {
		                if ( this.nasmf_table[i] === distinctRoleCode[m]) {
		                  row[distinctEmpName[parseInt(distinctEmpName.length/2)]] = "NA"
		                }
		              }
		              let elem = {is_header:false,row:row};
					  rows.push(elem);
		            }
			    }

	        	//let elem = {is_header: false, row: distinctRoleCode[m]};
	        	
	        	
					    

	        }

		this.drawTable({options: options, fields: fields, rows: rows})

		console.log("sliceEmp [ ",z,"]",sliceEmp[z]);
		}


	}

	initReport = () => {
		if(!this.isFirstPage){this.addNewPage()}
		else {this.isFirstPage = false}
	}

	drawFirstPage = () => {
		this.reportPDF = new jsPDF({
									 orientation: 'portrait',
									 unit: 'mm',
									 format: 'a4',
									 putOnlyUsedFonts:true
									});
		console.log('pdf fontlist', this.reportPDF.getFontList())

		this.drawTitleSection()
	}

	setFontStyle = (style) => {
		this.reportPDF.setFont('helvetica', style)
	}

	outText = (x, y, text, style) => {
		this.setFontStyle(style)
		this.reportPDF.text(text, x, y)
		let ret = this.reportPDF.getTextWidth(text)
		this.setFontStyle('normal')
		return ret
	}



	// tbl_header_lineheight = 6
	// tbl_cell_lineheight = 3.8

	measureTable = (data) => {
		data._fields = {}
		data._header_height = 0
		let widths = {}
		let tot_width = 0
		for(let f of data.fields) {
			if(f.show)tot_width += f.width
		}
		for(let f of data.fields) {
			widths[f.name] = this.pageWidth * (f.width / tot_width)
		}
		for(let f of data.fields) {
			data._fields[f.name] = f
			data._fields[f.name]._content = []
			data._fields[f.name]._width = widths[f.name]
			let ht = 0
			if('measurecell_callback' in f) {
				ht = f.measurecell_callback({is_header: true, content: f.nickname, width: widths[f.name]})
			} else {
				Utils.log('pdf table data._fields, f.name, f.nickname',  data._fields, f.name, f.nickname)
				data._fields[f.name]._content = this.splitStringToFit(f.nickname, widths[f.name], 11, 'bold')
				ht = data._fields[f.name]._content.length * this.tbl_header_lineheight
			}
			data._header_height = data._header_height < ht ? ht : data._header_height
		}

		for(let r of data.rows) {
			r._row = {cells: {}, height: 0, is_header: r.is_header, height: 0}
			for(let f of data.fields) {
				r._row.cells[f.name] = {}
				// Utils.log('pdf row r.row, f', r.row, f)
				if('measurecell_callback' in f) {
					r._row.cells[f.name]._content = []
					let cell_height = f.measurecell_callback({is_header: false, content: r.row[f.name], width: widths[f.name]})
					r._row.height = r._row.height < cell_height ? cell_height : r._row.height
				} else {

					r._row.cells[f.name]._content = this.splitStringToFit(r.row[f.name], widths[f.name], 9, 'normal')
					let cell_height = r._row.cells[f.name]._content.length * this.tbl_cell_lineheight + (2 * this.tbl_top_padding)
					r._row.height = r._row.height < cell_height ? cell_height : r._row.height
				}
			}
		}
	}

	drawTable = (data) => {
		console.log("data in drawTable",data);
		// this.drawTableHeader()
		this.measureTable(data)
		Utils.log('pdf table data', data)
		this.drawTableHeader(data)
		let counter = 0
		Utils.log('pdf drawTableRow init this.currentY',  this.currentY)
		for(let r of data.rows) {
			counter++
			this.drawTableRow(data, r, counter)
		}
	}

	drawTableRow = (data, r, counter) => {
		this.currentX = marginX
		if(this.currentY + r._row.height > this.pageHeight - marginY) {
			this.addNewPage()
			this.drawTableHeader(data)
		}
		counter++
		let bgcolor = data.options.odd_row_bgcolor
		let color = data.options.odd_row_color
		if(counter % 2 === 0) {
			bgcolor = data.options.even_row_bgcolor
			color = data.options.even_row_color
		}
		this.reportPDF.setFontSize(9)
		Utils.log('pdf drawTableRow roundedRect r, this.currentY', r, this.currentY)
		this.roundedRect(this.currentX, this.currentY, this.pageWidth, r._row.height, 0, color, bgcolor, true, true, true, true, true)
		for(let f_id in data._fields) {
			let f = data._fields[f_id]
			if(f.show) {
				if('drawcell_callback' in f) {
					f.drawcell_callback({is_header: false, content: r.row[f.name], width: f._width})
				} else {
					let cell_y = 0
					for(let c of r._row.cells[f.name]._content) {
						this.outText(this.currentX + this.tbl_side_padding, this.currentY + cell_y + this.tbl_top_padding + this.font_offset_small, c, 'normal')
						cell_y += this.tbl_cell_lineheight
					}
				}
				this.currentX += f._width
			}
		}
		this.currentY += r._row.height
		Utils.log('pdf drawTableRow r, this.currentY', r, this.currentY)
	}

	drawTableHeader = (data) => {
		if(!data.options.show_header)return
		let bgcolor = data.options.header_bgcolor
		let color = data.options.header_color
		// this.reportPDF.setDrawColor(bgcolor)
		// this.reportPDF.setFillColor(bgcolor)
		// this.reportPDF.setTextColor(color)
		this.reportPDF.setFontSize(11)
		Utils.log('pdf table color, bgcolor, data._header_height', color, bgcolor, data._header_height)
		this.roundedRect(this.currentX, this.currentY, this.pageWidth, data._header_height, 0, color, bgcolor, true, true, true, true, true)
		for(let f_id in data._fields) {
			let f = data._fields[f_id]
			if(f.show) {
				if('drawcell_callback' in f) {
					f.drawcell_callback({is_header: true, content: f.nickname, width: f._width})
				} else {
					let cell_y = 0
					for(let c of f._content) {
						this.outText(this.currentX + this.tbl_side_padding, this.currentY + cell_y + this.tbl_top_padding + this.font_offset_small, c, 'bold')
						cell_y += this.tbl_header_lineheight
					}
				}
				this.currentX += f._width
			}
		}
		this.currentY += data._header_height
		this.currentX = marginX;
	}

	addNewPage = () => {
		this.reportPDF.addPage('a4','landscape');
		this.currentY = marginX + (this.vGap / 2)
		this.currentX = marginY
		for(let id in this.gm_lines) {
			this.gm_lines[id].top = this.currentY
		}
	}

	drawTitleSection = () => {
		let contact_name = Store.getStoreData('contact').ContactName
		let today = moment()
		let date = today.format('DD-MM-YYYY')
		let time = today.format('hh:mm a')
		let reporter_string = 'This report was produced by ' + contact_name + ' at ' + time + ' on ' + date
		// console.log('pdfGenerator reporter_string', reporter_string)
		this.reportPDF.setDrawColor('#37ADA7');
		this.reportPDF.setTextColor('#FDFDFD');
		this.reportPDF.setLineWidth(0.25);
		this.reportPDF.setFillColor('#37ADA7');
		this.reportPDF.setFontSize(18);
		this.setFontStyle('bold')
		this.reportPDF.roundedRect(this.currentX, this.currentY, this.pageHeight - marginY, this.titleHeight, 2, 2, "DF");
		// this.reportPDF.setFontStyle('bold')
		this.reportPDF.text('SM&CR Report',this.currentX + 2, this.currentY + 7);
		// this.reportPDF.setFontStyle('normal')
		this.currentY += this.titleHeight + this.lineHeight
		this.reportPDF.setFontSize(12);
		this.reportPDF.setTextColor('#1E3E62');
		this.currentX += this.outText(this.currentX, this.currentY,'This report was produced by ', 'normal')
		this.currentX += this.outText(this.currentX, this.currentY,' ' + contact_name + ' ', 'bold')
		this.currentX += this.outText(this.currentX, this.currentY,'at', 'normal')
		this.currentX += this.outText(this.currentX, this.currentY,' ' + time + ' ', 'bold')
		this.currentX += this.outText(this.currentX, this.currentY,'on', 'normal')
		this.currentX += this.outText(this.currentX, this.currentY,' ' + date + '.', 'bold')
		this.currentX = marginX
		this.currentY += this.lineHeight

	}

	render()
	{
		Utils.log('CSLPDFLoader this.state', this.state)
		if(!this.state.ready)return(<CSLPDFLoader />)

		return (
			<PdfContainer style={{position: 'fixed', top: 154, zIndex: 10000, right: 10, paddingBottom: 30}}>
				<div style={{backgroundColor: 'white', height: 30}}>
					<span style={{fontSize: 16, fontWeight: 700, paddingLeft: 25, paddingTop: 20}}>Print Preview</span><FaTimes style={{position: 'absolute', right: 18, top: 3, cursor: 'pointer'}} onClick={() => this.props.togglePdf()} />
				</div>
				<Iframe url={this.state.pdfURL} width="100%" height="550vh" type="application/pdf" />
			</PdfContainer>
		);
	}
}

export default PdfGenerator;

				// <button onClick={() => this.props.toggleGenerate()}>Close</button>