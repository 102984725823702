import React, { Component } from 'react'
import SorContext from './SorContext';
import moment from 'moment';

// import logo_base_64 from './../../Certifications/Pdf_Font_n_Icons/MccLogo';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "../../../fonts/vfs_fonts";

import {
    HeadingLevel,
    AlignmentType,
    Document,
    Packer,
    Paragraph,
    TextRun,
    ImageRun,
    Footer,
    Header,
    PageNumber,
    Table,
    TableCell,
    TableRow,
    WidthType,
    PageOrientation
} from "docx";


import { saveAs } from "file-saver";

import './sor.css'


const currentURL = window.location.href;
const url = new URL(currentURL);


// Fonts are defined in the vfs_fonts.js file
pdfMake.fonts = {
    'Montserrat': {
        normal: 'Montserrat-Regular.ttf',
        bold: 'Montserrat-Regular.ttf',
    },
};


pdfMake.vfs = pdfFonts.pdfMake.vfs;



class ReportActionPanel extends Component {
    // Initialize the current Y position
    constructor (props) {
        super(props);
        this.currentPosition = 80;
        this.state = {
            selectedOrientation: 'portrait',
            selectedReportType: 'pdf'
        };
    }

    static contextType = SorContext;


    showReport = (e) => {
        e.preventDefault();
        const { saveFilterParameter } = this.context;
        saveFilterParameter();
    }
    generateReport = (e) => {
        e.preventDefault();
        const sldata = JSON.parse(localStorage.getItem("site_layout"));
        let cname = sldata.result.company_name;
        let { selectedOrientation, selectedReportType } = this.state;

        if (selectedReportType == 'pdf') {
            this.generateReportPdf(selectedOrientation, cname)
        } else if (selectedReportType == 'word') {
            this.generateReportWord(selectedOrientation, cname)
        }
    }

    generateReportPdf = async (selectedOrientation) => {
        const docDefinition = {
            content: [
                ...this.generateFirstPage_pdf(),
                { text: "", pageBreak: "after" },
                ...this.generateSecondPage_pdf(),
                { text: "", pageBreak: "after" },
                ...this.generateThirdPage_pdf()
            ],
            footer: this.generateFooter_pdf,
            header: this.generateHeader_pdf,
            pageOrientation: selectedOrientation,
            defaultStyle: {
                font: 'Montserrat' // This sets the default font for the entire document
            }
        };

        //debugger;

        const pdfDoc = pdfMake.createPdf(docDefinition);
        pdfDoc.download("report.pdf");
    }

    generateFirstPage_pdf = () => {
        const dateText = moment().format("MMMM DD, YYYY");
        const sldata = JSON.parse(localStorage.getItem("site_layout"));
        let cname = sldata.result.company_name;


        return [
            {
                margin: [0, 10, 0, 0],
                columns: [
                    {
                        width: "*",
                        text: "Senior Manager and Certification Regime",
                        fontSize: 20,
                        color: "#59595B"
                    }
                ]
            },
            {
                margin: [20, 110, 0, 0],
                columns: [
                    {
                        width: "*",
                        alignment: "right",
                        text: "Statement of Responsibilities",
                        fontSize: 30,
                        color: "#06426E",
                        bold: true
                    }
                ]
            },
            {
                margin: [20, 30, 0, 0],
                columns: [
                    {
                        width: "*",
                        alignment: "right",
                        text: `${cname}`,
                        fontSize: 30,
                        color: "#06426E",
                        bold: true
                    }
                ]
            },
            {
                margin: [0, 20, 0, 0],
                columns: [
                    {
                        width: "*",
                        alignment: "right",
                        text: dateText,
                        fontSize: 10,
                        color: "#a8a7a3"
                    }
                ]
            },
            // // remove as per jenny's comment
            // //https://mycompliancecentre.atlassian.net/browse/T82-878
            // {
            //     margin: this.state.selectedOrientation === 'portrait' ? [0, 300, 0, 0] : [0, 100, 0, 0],
            //     columns: [
            //         {
            //             width: "*",
            //             text: "POWERED BY",
            //             fontSize: 15,
            //             color: "#727476",
            //             bold: true
            //         },
            //         {
            //             width: "auto",
            //             image: logo_base_64,
            //             width: 350,
            //             marginRight: 100
            //         }
            //     ]
            // }
        ];
    };

    generateSecondPage_pdf = () => {
        return [
            {
                text: "CONTENTS",
                fontSize: 30,
                bold: true,
                alignment: "left",
                color: "#0f0c00",
                margin: [0, 40, 0, 0]
            },
            {
                text: "1. STATEMENT OF RESPONSIBILITIES",
                fontSize: 25,
                alignment: "left",
                color: "#0f0c00",
                margin: [0, 50, 0, 0]
            }
        ];
    };

    generateThirdPage_pdf = () => {
        const { employee_data, report_param_apply } = this.context;
        let data = [];
        let employeeFilter = report_param_apply.firstSection.personalFilter.value;
        let roleTypeFilter = report_param_apply.firstSection.roleTypeFilter.value;
        let sortByFilter = report_param_apply.firstSection.sortByFilter.value;
        let statusFilter = report_param_apply.firstSection.statusFilter.value;
        const sldata = JSON.parse(localStorage.getItem("site_layout"));
        let logged_in_user = sldata["contact_id"];
        let logged_in_user_name = sldata.result.use_storage.result.contactlist[logged_in_user].ContactName;
        let cname = sldata.result.company_name;


        if (employeeFilter == '' || employeeFilter == 'all') {
            data = employee_data;
        } else {
            data = employee_data.filter(item => employeeFilter.split(',').includes(item.ID.toString()));
        }

        if (sortByFilter !== 'all') {
            if (sortByFilter.includes('2') && sortByFilter.includes('3')) {
                data = [...data].sort((a, b) => a.EmployeeName.localeCompare(b.EmployeeName));
            } else if (sortByFilter.includes('3')) {
                // First name
                data = [...data].sort((a, b) => a.EmployeeName.split(' ')[0].localeCompare(b.EmployeeName.split(' ')[0]));
            } else if (sortByFilter.includes('2')) {
                //Last name
                data = [...data].sort((a, b) => a.EmployeeName.split(' ').pop().localeCompare(b.EmployeeName.split(' ').pop()));
            } else if (sortByFilter.includes('4')) {
                // role types and roles sort
                data = data.map(item => ({
                    ...item,
                    AssignedRoleTypes: item.AssignedRoleTypes.sort((a, b) => a.RoleType.localeCompare(b.RoleType))
                        .map(roleType => ({
                            ...roleType,
                            AssignedRoles: roleType.AssignedRoles.sort((a, b) => a.ClientRole_Code.localeCompare(b.ClientRole_Code))
                        }))
                }));
            }
        }

        data = data.map((employee, index) => {
            let updatedEmployee = { ...employee }; // Create a copy of the employee object

            if (roleTypeFilter == '' || roleTypeFilter == 'all') {
                // If no filter or 'all' filter is applied, keep the original AssignedRoleTypes
                updatedEmployee.AssignedRoleTypes = employee.AssignedRoleTypes;
            } else {
                // Apply the roleTypeFilter condition to update AssignedRoleTypes
                updatedEmployee.AssignedRoleTypes = employee.AssignedRoleTypes.filter(item =>
                    roleTypeFilter.split(',').includes(item.role_type_id.toString())
                );
            }

            let statusFilter = report_param_apply.firstSection.statusFilter.value;
            //console.log('data ==>', data);
            if (statusFilter != 'all') {
                if (statusFilter != '') {
                    //Accepted
                    if (statusFilter == 4) {
                        updatedEmployee.AssignedRoleTypes = updatedEmployee.AssignedRoleTypes.filter(item => {
                            return item.AssignedRoles.some(role => role.Is_Accepted === 1);
                        });
                    }
                    //Pending
                    else if (statusFilter == 3) {
                        updatedEmployee.AssignedRoleTypes = updatedEmployee.AssignedRoleTypes.filter(item => {
                            return item.AssignedRoles.some(role => role.Is_Accepted === 0);
                        });
                    }
                }
            }

            return updatedEmployee; // Return the updated employee object
        });



        //debugger;
        const content = [
            {
                margin: [0, 30, 0, 0],
                columns: [
                    {
                        width: "*",
                        text: "1. STATEMENT OF RESPONSIBILITIES",
                        fontSize: 20,
                        color: "#024371",
                        bold: true
                    }
                ]
            },
            {
                margin: [0, 20, 0, 0],
                columns: [
                    {
                        width: "*",
                        text: [
                            `The following report lists employees and their functions, roles, and responsibilities as relates to the Senior Manager and Certification Regime implemented at ${cname}. The report was run by ${logged_in_user_name} on `,
                            {
                                text: moment().format('MMMM DD, YYYY'),
                                //fontSize: 10,
                                //color: "#0000",
                                //bold: false,
                            },
                            " for an effective date of ",
                            {
                                text: moment(report_param_apply.firstSection.dateFilter.value, 'YYYYMMDD').format('MMMM DD, YYYY'),
                                //fontSize: 10,
                                //color: "#0000",
                                //bold: false,
                            },
                            ".",
                        ],
                        alignment: "left"
                    }
                ]
            }

        ];


        //data = data.filter((employee) => employee.AssignedRoleTypes.length > 0);

        data = this.sortClientRoleCodes(data)

        data = data.filter(employee => employee.AssignedRoleTypes.length > 0);


        // Add employee data to the content array
        data.forEach((employee) => {
            const employeeColumn = {
                columns: [
                    {
                        width: "*",
                        text: `${employee.EmployeeName}`,
                        fontSize: 15,
                        color: "#024371",
                        bold: true,
                        margin: [0, 20, 10, 0] // Added right margin for spacing
                    },
                    {
                        width: "*",
                        text: `${employee.JobTitle}`,
                        fontSize: 15,
                        color: "#666666",
                        bold: true,
                        margin: [0, 20, 0, 0] // Added left margin for spacing
                    }
                ]
            };

            content.push(employeeColumn);

            // Add employee description
            content.push({
                text: `Description: `,
                fontSize: 11,
                color: "#000",
                bold: true,
                margin: [0, 10, 0, 15] // Added bottom margin for spacing
            });

            content.push({
                text: `${employee.Description != "" ? employee.Description : 'Job description to be provided'}`,
                fontSize: 10,
                color: "#666666",
                margin: [0, 0, 0, 15] // Added bottom margin for spacing
            });

            // Appointed..
            const appointedColumn = {
                columns: [
                    {
                        width: "*",
                        text: `Appointed:`,
                        fontSize: 10,
                        color: "#000",
                        bold: true,
                        margin: [0, 0, 0, 0]
                    },
                    {
                        width: "*",
                        text: `${employee.Appointed}`,
                        fontSize: 10,
                        color: "#666666",
                        margin: [0, 0, 0, 0] // Added left margin for spacing
                    }
                ]
            };
            content.push(appointedColumn);

            // IRN
            const irnColumn = {
                columns: [
                    {
                        width: "*",
                        text: `IRN: ${employee.IRN}`,
                        fontSize: 10,
                        color: "#000",
                        bold: true,
                        margin: [0, 20, 0, 0]
                    },
                    {
                        width: "*",
                        text: `Regulatory Status: ${employee.RegulatoryStatus}`,
                        fontSize: 10,
                        color: "#0000",
                        margin: [0, 20, 0, 0] // Added left margin for spacing
                    },
                    {
                        width: "*",
                        text: `As of: ${employee.RegulatoryStatusDate}`,
                        fontSize: 10,
                        color: "#0000",
                        margin: [50, 20, 0, 0] // Added left margin for spacing
                    }
                ]
            };
            content.push(irnColumn);

            employee.AssignedRoleTypes.forEach((roleType, index) => {
                const roleTable = this.generateRoleTable([roleType]);

                if (index > 0) {
                    roleTable.margin = [0, 20, 0, 0]; // Add a 20-point margin at the bottom
                }
                content.push(roleTable);
            });

            //console.log('completed uptill');
        });
        return content;
    };

    // sort base on role code
    sortClientRoleCodes = (data) => {
        data.forEach((obj) => {
            if (Array.isArray(obj.AssignedRoleTypes)) {
                obj.AssignedRoleTypes.forEach((roleType) => {
                    if (Array.isArray(roleType.AssignedRoles)) {
                        roleType.AssignedRoles.sort((a, b) => {
                            return a.ClientRole_Code.localeCompare(
                                b.ClientRole_Code,
                                undefined,
                                { numeric: true, sensitivity: "base" }
                            );
                        });
                    }
                });
            }
        });
        return data;
    }


    generateHeader_pdf = (currentPage, pageCount, pageSize) => {
        if (currentPage !== 1) {
            return [
                {
                    text: "Statement of Responsibilities",
                    alignment: "left",
                    fontSize: 10,
                    color: "#837C7E",
                    marginLeft: 40,
                    marginTop: 20
                },
                {
                    canvas: [
                        {
                            type: "line",
                            x1: 40,
                            y1: 3,
                            x2: 520,
                            y2: 3,
                            lineWidth: 1,
                            lineColor: "#C2D99C"
                        }
                    ]
                }
            ];
        }
    };


    generateFooter_pdf = (currentPage, pageCount) => {
        if (currentPage != 1) {
            return [
                {
                    canvas: [
                        {
                            type: "line",
                            x1: 40,
                            y1: 0,
                            x2: 555,
                            y2: 0,
                            lineWidth: 1,
                            lineColor: "#a5a6a5",
                            margin: [0, 5, 0, 0] // Adjust the top margin as needed
                        }
                    ]
                },
                {
                    text: `${currentPage}`,
                    alignment: "left",
                    fontSize: 10,
                    marginLeft: 40,
                    marginTop: 10
                },
                {
                    text: "",
                    alignment: "right",
                    fontSize: 10,
                    color: "#a5a6a5",
                    marginRight: 30,
                    marginBottom: 10
                }
            ];
        }
    };

    generateRoleTable = (assignedRoleTypes) => {
        return {
            table: {
                headerRows: 1,
                widths: ["auto", "*", "*"], // Adjust column widths as needed
                body: this.tableBody(assignedRoleTypes)
            },
            // styles: {
            //   header: {
            //     fontSize: 18,
            //     bold: true,
            //     alignment: "center"
            //   },
            //   // firstColumn: {
            //   //   fillColor: "blue" // Color of the first column
            //   // },
            //   normalColumn: {
            //     fillColor: "#F2F2F2" // Color of the second and third columns
            //   }
            // }

            layout: {
                hLineWidth: function (i, node) {
                    return (i === 0 || i === node.table.body.length) ? 1 : 0; // Add border to top and bottom
                },
                vLineWidth: function (i) {
                    return (i === 0 || i === 3) ? 1 : 0; // Add border to left and right
                }
            }
        };
    };

    tableBody = (assignedRoleTypes) => {
        let fontSizeValue = 9;
        const { report_param_apply } = this.context;
        let is_show_role_effective_date = report_param_apply.secondSection.is_show_role_effective_date;
        let is_include_roles_respo_desc = report_param_apply.secondSection.is_include_roles_respo_desc;
        let is_used_regime_color_codes = report_param_apply.secondSection.is_used_regime_color_codes;
        var body = [
            [
                {
                    text: `${assignedRoleTypes[0].RoleFunctionName}`,
                    colSpan: 3,
                    style: "header",
                    fillColor: is_used_regime_color_codes ? assignedRoleTypes[0].RoleTypeGradient_FirstColorCode : '#0000',
                    color: "#fff"
                },
                {},
                {}
            ]
        ];

        assignedRoleTypes.forEach((roleType) => {
            let isFirstRole = true;

            roleType.AssignedRoles.forEach((assignedRole, index) => {

                if (!isFirstRole) {
                    body.push([
                        { text: "", style: "firstColumn" },
                        { text: "", style: "normalColumn" },
                        { text: "", style: "normalColumn" }
                    ]);
                } else {
                    isFirstRole = false;
                    body.push([
                        {
                            text: "",
                            style: "firstColumn",
                            rowSpan: roleType.AssignedRoles.length * 4, // Account for 4 rows for each role
                            fillColor: is_used_regime_color_codes ? roleType.RoleTypeGradient_LastColorCode : '#A8ACB0'
                        },
                        { text: "", style: "normalColumn", fillColor: '#F2F2F2', fontSize: fontSizeValue },
                        { text: "", style: "normalColumn", fillColor: '#F2F2F2', fontSize: fontSizeValue }
                    ]);
                }

                body.push([
                    {
                        text: "",
                        fillColor: is_used_regime_color_codes ? roleType.RoleTypeGradient_LastColorCode : '#A8ACB0',
                        style: "firstColumn"
                    },
                    { text: assignedRole.ClientRole_Code, style: "normalColumn", fillColor: '#F2F2F2', fontSize: fontSizeValue },
                    { text: assignedRole.ClientRole_Title, style: "normalColumn", fillColor: '#F2F2F2', fontSize: fontSizeValue }
                ]);

                if (is_show_role_effective_date && is_include_roles_respo_desc) {
                    // Both true
                    body.push([
                        {},
                        { text: `Effective to: ${assignedRole.RoleAssigned_Date == '31/12/2050' ? 'No end date set' : assignedRole.RoleAssigned_Date}`, style: "normalColumn", fillColor: '#F2F2F2', fontSize: fontSizeValue },
                        { text: assignedRole.ClientRole_Description, style: "normalColumn", fillColor: '#F2F2F2', fontSize: fontSizeValue }
                    ]);
                } else if (!is_show_role_effective_date && !is_include_roles_respo_desc) {
                    // Both false
                    body.push([
                        {},
                        {},
                        {}
                    ]);
                } else if (is_show_role_effective_date && !is_include_roles_respo_desc) {
                    // Effective date true, ClientRole_Description false
                    body.push([
                        {},
                        { text: `Effective to: ${assignedRole.RoleAssigned_Date == '31/12/2050' ? 'No end date set' : assignedRole.RoleAssigned_Date}`, style: "normalColumn", fillColor: '#F2F2F2', fontSize: fontSizeValue },
                        {}
                    ]);
                } else if (!is_show_role_effective_date && is_include_roles_respo_desc) {
                    // Effective date false, ClientRole_Description true
                    body.push([
                        {},
                        {},
                        { text: assignedRole.ClientRole_Description, style: "normalColumn", fillColor: '#F2F2F2', fontSize: fontSizeValue }
                    ]);
                }


                if (assignedRole.Shared_DividedWith != "") {
                    body.push([
                        {},
                        { text: assignedRole.Shared_DividedWithText, style: "normalColumn", fillColor: '#F2F2F2', fontSize: fontSizeValue },
                        { text: this.removeHtmlTags(assignedRole.Shared_DividedWith), style: "normalColumn", fillColor: '#F2F2F2', fontSize: fontSizeValue }
                    ]);
                }
                else if (assignedRole.Shared_DividedWith == "") {
                    body.push([
                        {},
                        {},
                        {}
                    ]);
                }
            });
            console.log('assigned role executed==>')
            // Add empty row between role types
            body.push([
                { text: "", style: "firstColumn" },
                { text: "", style: "normalColumn" },
                { text: "", style: "normalColumn" }
            ]);
        });

        return body;
    };

    removeUnnecessarySpaces = (inputString) => {
        const cleanedString = inputString.replace(/\s+/g, ' ').trim();
        return cleanedString;
    }



    generateReportWord = async (orientationType, name) => {
        const sections = [
            ...this.generateFirstPage(orientationType, name),
            ...this.generateSecondPage(orientationType),
            ...this.generateThirdPage(orientationType)
        ];

        const doc = new Document({
            sections: sections,
            compatibility: {
                version: 15,
                doNotExpandShiftReturn: true,
            },
        });

        Packer.toBlob(doc).then((blob) => {
            saveAs(blob, "statement_of_responsibilities.docx");
        });
    }





    generateFirstPage = (orientationType, name) => {
        return [
            {
                properties: {
                    page: {
                        size: {
                            orientation: orientationType == 'landscape' ? PageOrientation.LANDSCAPE : PageOrientation.POTRAIT,
                        },
                    },
                },
                children: [
                    // Top of the section
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Senior Manager and Certification Regime",
                                color: "59595B",
                                heading: HeadingLevel.HEADING_1,
                                size: 45,
                                alignment: AlignmentType.LEFT,
                                //font: "Montserrat"
                            })
                        ]
                    }),

                    // Middle of the section (Heading)
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Statement of Responsibilities",
                                color: "06426E",
                                size: 50,
                                break: 6,
                                bold: true,
                                //font: "Montserrat"
                            })
                        ],
                        alignment: AlignmentType.RIGHT
                    }),

                    // Middle of the section (Subheading)
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: `${name}`,
                                color: "06426E",
                                size: 50,
                                bold: true,
                                //font: "Montserrat"
                            })
                        ],
                        alignment: AlignmentType.RIGHT
                    }),

                    // Middle of the section (Date)
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: moment().format("MMMM DD, YYYY"),
                                color: "a8a7a3",
                                size: 25,
                                //font: "Montserrat"
                            })
                        ],
                        alignment: AlignmentType.RIGHT
                    }),

                    // // remove as per Jenny's comment
                    // //T82-878

                    // Bottom of the section
                    // new Paragraph({
                    //     children: [
                    //         new TextRun({
                    //             text: "POWERED BY",
                    //             color: "727476",
                    //             bold: true,
                    //             size: 35,
                    //             //font: "Montserrat",
                    //             break: orientationType == 'portrait' ? 15 : 4
                    //         }),
                    //         new ImageRun({
                    //             data: logo_base_64,
                    //             transformation: {
                    //                 width: [350],
                    //                 height: [100],
                    //             },
                    //             floating: {
                    //                 horizontalPosition: {
                    //                     offset: orientationType == 'portrait' ? 4014400 : 5014400,
                    //                 },
                    //                 verticalPosition: {
                    //                     offset: orientationType == 'portrait' ? 9014400 : 5014400,
                    //                 },
                    //             },
                    //         }),
                    //     ],
                    //     alignment: AlignmentType.LEFT,
                    // }),

                ]
            }
        ];
    };


    generateSecondPage = (orientationType) => {
        return [
            {
                properties: {
                    page: {
                        size: {
                            orientation: orientationType == 'landscape' ? PageOrientation.LANDSCAPE : PageOrientation.POTRAIT,
                        },
                    },
                },
                children: [
                    // Heading for the second page
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "CONTENTS",
                                color: "0f0c00",
                                size: 50,
                                bold: true,
                                alignment: AlignmentType.CENTER
                            })
                        ]
                    }),

                    // Child of the second page
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "1. STATEMENT OF RESPONSIBILITIES",
                                color: "0f0c00",
                                size: 30
                            })
                        ],
                        spacing: {
                            before: 700
                        }
                    })
                ]
            }
        ];
    };

    generateThirdPage = (orientationType) => {
        const { employee_data, report_param_apply } = this.context;

        let data = [];
        let employeeFilter = report_param_apply.firstSection.personalFilter.value;
        let roleTypeFilter = report_param_apply.firstSection.roleTypeFilter.value;
        let is_used_regime_color_codes = report_param_apply.secondSection.is_used_regime_color_codes;
        let sortByFilter = report_param_apply.firstSection.sortByFilter.value;

        if (employeeFilter == '' || employeeFilter == 'all') {
            data = employee_data;
        } else {
            data = employee_data.filter(item => employeeFilter.split(',').includes(item.ID.toString()));
        }

        if (sortByFilter !== 'all') {
            if (sortByFilter.includes('2') && sortByFilter.includes('3')) {
                data = [...data].sort((a, b) => a.EmployeeName.localeCompare(b.EmployeeName));
            } else if (sortByFilter.includes('3')) {
                // First name
                data = [...data].sort((a, b) => a.EmployeeName.split(' ')[0].localeCompare(b.EmployeeName.split(' ')[0]));
            } else if (sortByFilter.includes('2')) {
                //Last name
                data = [...data].sort((a, b) => a.EmployeeName.split(' ').pop().localeCompare(b.EmployeeName.split(' ').pop()));
            } else if (sortByFilter.includes('4')) {
                // role types and roles sort
                data = data.map(item => ({
                    ...item,
                    AssignedRoleTypes: item.AssignedRoleTypes.sort((a, b) => a.RoleType.localeCompare(b.RoleType))
                        .map(roleType => ({
                            ...roleType,
                            AssignedRoles: roleType.AssignedRoles.sort((a, b) => a.ClientRole_Code.localeCompare(b.ClientRole_Code))
                        }))
                }));
            }
        }

        // data = data.filter((employee) => employee.AssignedRoleTypes.length > 0);

        data = data.map((employee, index) => {
            let updatedEmployee = { ...employee }; // Create a copy of the employee object

            if (roleTypeFilter == '' || roleTypeFilter == 'all') {
                // If no filter or 'all' filter is applied, keep the original AssignedRoleTypes
                updatedEmployee.AssignedRoleTypes = employee.AssignedRoleTypes;
            } else {
                // Apply the roleTypeFilter condition to update AssignedRoleTypes
                updatedEmployee.AssignedRoleTypes = employee.AssignedRoleTypes.filter(item =>
                    roleTypeFilter.split(',').includes(item.role_type_id.toString())
                );
            }

            let statusFilter = report_param_apply.firstSection.statusFilter.value;
            //console.log('data ==>', data);
            if (statusFilter != 'all') {
                if (statusFilter != '') {
                    //Accepted
                    if (statusFilter == 4) {
                        updatedEmployee.AssignedRoleTypes = updatedEmployee.AssignedRoleTypes.filter(item => {
                            return item.AssignedRoles.some(role => role.Is_Accepted === 1);
                        });
                    }
                    //Pending
                    else if (statusFilter == 3) {
                        updatedEmployee.AssignedRoleTypes = updatedEmployee.AssignedRoleTypes.filter(item => {
                            return item.AssignedRoles.some(role => role.Is_Accepted === 0);
                        });
                    }
                }
            }

            return updatedEmployee;
        });




        data = this.sortClientRoleCodes(data);
        const content = this.generateThirdPageContent(data);

        return [
            {
                properties: {
                    page: {
                        size: {
                            orientation: orientationType == 'landscape' ? PageOrientation.LANDSCAPE : PageOrientation.POTRAIT,
                        },
                    },
                },
                headers: {
                    default: this.generateDocxHeader("STATEMENT OF RESPONSIBILITIES", "1d6e19")
                },
                footers: {
                    default: this.generateFooter()
                },
                children: content
            }
        ];
    };

    // Function to generate the entire third page content
    generateThirdPageContent = (employeeData) => {

        const { report_param_apply } = this.context;
        const employeeContent = this.generateEmployeeContent(employeeData);
        const sldata = JSON.parse(localStorage.getItem("site_layout"));
        let logged_in_user = sldata["contact_id"];
        let logged_in_user_name = sldata.result.use_storage.result.contactlist[logged_in_user].ContactName;
        let cname = sldata.result.company_name;

        return [
            new Paragraph({
                children: [
                    new TextRun({
                        text: "1. STATEMENT OF RESPONSIBILITIES",
                        bold: true,
                        size: 40,
                        color: "06426E"
                    })
                ],
                alignment: AlignmentType.LEFT
            }),
            new Paragraph({
                children: [
                    new TextRun({
                        text: `The following report lists employees and their functions, roles, and responsibilities as relates to the Senior Manager and Certification Regime implemented at ${cname}. The report was run by ${logged_in_user_name} on ${moment().format('MMMM DD, YYYY')} for an effective date of ${moment(report_param_apply.firstSection.dateFilter.value, 'YYYYMMDD').format('MMMM DD, YYYY')}.`,
                        //font: 'Montserrat'
                    })
                ]
            }),
            new Paragraph(
                ""
            ),
            new Paragraph(
                ""
            ),
            new Paragraph(
                ""
            ),
            new Paragraph(
                ""
            ),
            ...employeeContent.flatMap(employee => employee.children)
        ];
    };

    generateEmployeeContent = (employeeData) => {
        employeeData = employeeData.filter(employee => employee.AssignedRoleTypes.length > 0);
        return employeeData.map((employee, index) => {
            return {
                children: [
                    this.generateEmployeeHeader(employee),
                    this.generateDescription(),
                    this.generateDescriptionText(employee.Description),
                    this.generateAppointed(employee.Appointed),
                    this.generateIRNRegStatusAsOf(employee),
                    ...this.generateEmployeeRole(employee)
                ]
            };
        });
    };

    generateEmployeeRole = (employee) => {
        let fontSize = 5;
        const roleTables = [];
        const { report_param_apply } = this.context;
        let is_show_role_effective_date = report_param_apply.secondSection.is_show_role_effective_date;
        let is_include_roles_respo_desc = report_param_apply.secondSection.is_include_roles_respo_desc;
        let is_used_regime_color_codes = report_param_apply.secondSection.is_used_regime_color_codes;


        employee.AssignedRoleTypes.forEach((roleType, index) => {
            //debugger;
            const roleTypeColor = is_used_regime_color_codes ? roleType.RoleTypeGradient_FirstColorCode : '000000';
            const secondColor = is_used_regime_color_codes ? roleType.RoleTypeGradient_LastColorCode : 'A8ACB0';
            const roleFunctionName = roleType.RoleFunctionName;

            const headerRow = new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                text: `${roleFunctionName}`,
                                bold: true,
                                color: 'ffffff',
                                size: 16,
                            }),
                        ],
                        columnSpan: 3,
                        shading: {
                            fill: roleTypeColor,
                        },
                        borders: {
                            right: {
                                color: "#ffffff",
                                size: 1,
                            },
                            top: {
                                color: "#ffffff",
                                size: 1,
                            },
                            bottom: {
                                color: "#ffffff",
                                size: 1,
                            },
                        },
                    }),
                ],
            });

            const rows = roleType.AssignedRoles.map((role) => {
                const roleAssignedDate = role.RoleAssigned_Date;
                const sharedDividedWith = role.Shared_DividedWithText;
                const sharedDividedWithVal = role.Shared_DividedWit;

                console.log('roleAssignedDate ==>', roleAssignedDate);

                return new TableRow({
                    children: [
                        new TableCell({
                            children: [],
                            width: {
                                size: 4,
                                type: WidthType.PERCENTAGE,
                            },
                            shading: {
                                fill: secondColor,
                            },
                            borders: {
                                right: {
                                    color: "#ffffff",
                                    size: 1,
                                },
                                top: {
                                    color: "#ffffff",
                                    size: 1,
                                },
                                bottom: {
                                    color: "#ffffff",
                                    size: 1,
                                },
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    text: role.ClientRole_Code,
                                    bold: true,
                                    size: fontSize,
                                }),

                                is_show_role_effective_date ? new Paragraph({ text: `Effective to: ${roleAssignedDate == '31/12/2050' ? 'No end date set' : roleAssignedDate}`, size: fontSize })
                                    : new Paragraph({ text: "" }),

                                sharedDividedWithVal != "" ? new Paragraph(sharedDividedWith)
                                    : new Paragraph({ text: "" }),
                            ],
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE,
                            },
                            shading: {
                                fill: "f2f2f2",
                            },
                            borders: {
                                right: {
                                    color: "#ffffff",
                                    size: 1,
                                },
                                top: {
                                    color: "#ffffff",
                                    size: 1,
                                },
                                bottom: {
                                    color: "#ffffff",
                                    size: 1,
                                },
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    text: role.ClientRole_Title,
                                    bold: true,
                                    size: 14,
                                }),
                                is_include_roles_respo_desc ? new Paragraph(role.ClientRole_Description) : new Paragraph({ text: "" }),
                                sharedDividedWithVal != "" ? new Paragraph(this.removeHtmlTags(role.Shared_DividedWith)) : new Paragraph({ text: "" }),
                                new Paragraph(`Reason: ${role.Shared_DividedComment}`),
                            ],
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE,
                            },
                            shading: {
                                fill: "f2f2f2",
                            },
                            borders: {
                                top: {
                                    color: "#ffffff",
                                    size: 1,
                                },
                                bottom: {
                                    color: "#ffffff",
                                    size: 1,
                                },
                            },
                        }),
                    ],
                });
            });

            const table = new Table({
                rows: [headerRow, ...rows],
            });

            roleTables.push(table);

            // Add spacing between role types
            if (index < employee.AssignedRoleTypes.length - 1) {
                roleTables.push(new Table({
                    rows: [
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [],
                                    columnSpan: 3,
                                    shading: {
                                        fill: "#ffffff", // Transparent background
                                    },
                                    borders: {
                                        bottom: {
                                            color: "#ffffff",
                                            size: 10, // Adjust the spacing as needed
                                        },
                                    },
                                }),
                            ],
                        }),
                    ],
                }));
            }
        });

        return roleTables;
    };
    removeHtmlTags = (inputString) => {
        return inputString.replace(/<\/?[^>]+(>|$)/g, "");
    };

    generateIRNRegStatusAsOf = (employee) => {
        return new Paragraph({
            spacing: {
                before: 500,
                line: 300
            },
            children: [
                new TextRun({
                    text: "IRN:",
                    size: 20,
                    color: "0a0a0a",
                    bold: true,
                    //font: 'Montserrat'
                }),
                new TextRun({
                    text: `${employee.IRN}`,
                    size: 20,
                    color: "0a0a0a",
                    //font: 'Montserrat'
                }),

                new TextRun({
                    text: "               Regulatory Status:",
                    size: 20,
                    color: "0a0a0a",
                    bold: true,
                    //font: 'Montserrat'
                }),
                new TextRun({
                    text: `${employee.RegulatoryStatus}`,
                    size: 20,
                    color: "0a0a0a",
                    //font: 'Montserrat'
                }),

                new TextRun({
                    text: "               As Of:",
                    size: 20,
                    color: "0a0a0a",
                    bold: true,
                    //font: 'Montserrat'
                }),
                new TextRun({
                    text: `${employee.RegulatoryStatusDate}`,
                    size: 20,
                    color: "0a0a0a",
                    //font: 'Montserrat'
                })
            ]
        });
    };

    generateEmployeeHeader = (employee) => {
        return new Paragraph({
            children: [
                new TextRun({
                    text: `${employee.EmployeeName}`,
                    bold: true,
                    size: 30,
                    color: "06426E",
                    //font: 'Montserrat'
                }),
                new TextRun({
                    text: ` - ${employee.JobTitle}`,
                    bold: true,
                    size: 30,
                    color: "9a9ea6",
                    //font: 'Montserrat'
                })
            ],
            spacing: {
                before: 100
            }
        });
    };

    generateAppointed = (appointed) => {
        return new Paragraph({
            spacing: {
                before: 500,
            },
            children: [
                new TextRun({
                    text: "Appointed:",
                    size: 20,
                    color: "0a0a0a",
                    bold: true,
                    //font: 'Montserrat'
                }),
                new TextRun({
                    text: `${appointed}`,
                    size: 20,
                    color: "0a0a0a",
                    //font: 'Montserrat'
                })
            ]
        });
    };

    generateDescription = () => {
        return new Paragraph({
            spacing: {
                before: 500,
            },
            children: [
                new TextRun({
                    text: "Description:",
                    size: 20,
                    color: "0a0a0a",
                    bold: true,
                    //font: 'Montserrat'
                }),

            ]
        });
    };

    generateDescriptionText = (description) => {
        return new Paragraph({
            spacing: {
                before: 20,
            },
            children: [
                new TextRun({
                    text: `${description != "" ? description : 'Job description to be provided'}`,
                    size: 20,
                    color: "0a0a0a",
                    //font: 'Montserrat'
                })

            ]
        });
    };

    generateEmptyParagraphs = (count) => {
        const emptyParagraphs = [];
        for (let i = 0; i < count; i++) {
            emptyParagraphs.push(new Paragraph(""));
        }
        return emptyParagraphs;
    };

    generateDocxHeader = (text, lineColor) => {
        return new Header({
            children: [
                new Paragraph(text, {
                    alignment: AlignmentType.RIGHT,
                    bold: true,
                    color: "06426E",
                    size: 20,
                    //font: 'Montserrat'
                }),
                new Paragraph(
                    "_______________________________________________________________________________________",
                    {
                        alignment: AlignmentType.RIGHT,
                        color: "#66CC43",
                        //font: 'Montserrat',
                        spacing: {
                            before: 10
                        }
                    }
                )
            ]
        });
    };

    generateFooter = () => {
        return new Footer({
            children: [
                new Paragraph({
                    children: [
                        new TextRun({
                            text: "__________________________________________________________________________________________",
                            fontSize: 10,
                            alignment: AlignmentType.LEFT,
                            color: "a5a6a5",
                            // font: 'Montserrat'
                        })
                    ]
                }),
                new Paragraph({
                    children: [
                        new TextRun({
                            children: [PageNumber.CURRENT],
                            fontSize: 10,
                            alignment: AlignmentType.LEFT,
                            //font: 'Montserrat'
                        })
                    ]
                }),
                new Paragraph({
                    text: "",
                    color: "a5a6a5",
                    fontSize: 10,
                    alignment: AlignmentType.RIGHT,
                    //font: 'Montserrat'
                })
            ]
        });
    };




    handleOrientation = (event) => {
        this.setState({ selectedOrientation: event.target.value });
    };

    handleReportType = (event) => {
        this.setState({ selectedReportType: event.target.value });
    };

    render() {
        return (
            <div className='report-panel-print'>
                <div style={{
                    paddingTop: '14px',
                    paddingLeft: '14px',
                    paddingRight: '14px'
                }} class="form-group from-group-dropdown">
                    <select
                        id="ddlReportOrientation"
                        className="form-control-dropdown"
                        tabIndex="-1"
                        value={this.state.selectedOrientation}
                        onChange={this.handleOrientation}
                    >
                        <option value="portrait">Portrait (Recommended)</option>
                        <option value="landscape">Landscape</option>
                    </select>
                </div>



                <div style={{
                    paddingTop: '5px',
                    paddingLeft: '14px',
                    paddingRight: '14px'
                }} class="form-group from-group-dropdown">
                    <select
                        id="ddlReportPrintType"
                        className="form-control-dropdown"
                        tabIndex="-1"
                        value={this.state.selectedReportType}
                        onChange={this.handleReportType}
                    >
                        <option value="pdf">Pdf</option>
                        <option value="word">Word (.doc)</option>
                    </select>
                </div>

                {/* Button Panel */}
                <div class="button-container">
                    <button class="apply-button" onClick={this.showReport}>Apply</button>
                    <button class="generate-button" onClick={this.generateReport}>Generate</button>
                </div>
            </div>
        )
    }
}

export default ReportActionPanel
