import React from 'react';
import Store from '../../Common/Store.js';
import Utils from '../../Common/Utils.js';
import styled from 'styled-components';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 5000px;
  width: 100%;
  background-color: #ffffff;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 1000;
  opacity: 0.8;
`;

const OkButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #04ACA7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
    float: right;
    font-size: 18px;
`;

const OkDangerButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #EA5A6E;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
    float: right;
    font-size: 18px;
`;

const CancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: white;
    color: #646A7E;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
    float: right;
    font-size: 18px;
`;

const AlertBoxContainer = styled.div`
	position: fixed;
	top: 40vh;
	left: 40vw;
    width: 450px;
    box-shadow: 8px 8px 8px 8px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #F7F7F7;
    color: #2D2D2D;
    border-radius: 10px;
    border-style: solid;
    border-color: #F7F7F7;
    z-index: 2000;
`
const AlertBoxHeader = styled.div`
    width: calc(100% - 15px);
    background-color: #F7F7F7;
    color: #646A7E;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    height: 35px;
    padding-left: 15px;
    padding-top: 15px;
    font-size: 20px;
    font-weight: 600;
`
const AlertBoxBody = styled.div`
    width: calc(100% - 30px);
    background-color: #F7F7F7;
    color: #646A7E;
    font-size: 18px;
    min-height: 100px;
    text-align: center;
    /*padding-top: 20px;*/
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px
`
const AlertBoxFooter = styled.div`
    width: calc(100% - 15px);
    background-color: #EDF1F2;
    color: #2D2D2D;
    height: 50px;
    padding-right: 15px;
    padding-top: 10px;
`

class AlertBox extends React.Component {
    state = { view_count: 0 };

    constructor (props) {
        super(props);
    }

    setComponentState = () => {
        if (this.props.alertParam !== null) {
            let title = 'title' in this.props.alertParam ? this.props.alertParam.title : 'Alert';
            let confirm = 'confirm' in this.props.alertParam ? this.props.alertParam.confirm : false;
            let danger = 'danger' in this.props.alertParam ? this.props.alertParam.danger : false;
            let ok_text = 'ok_text' in this.props.alertParam ? this.props.alertParam.ok_text : 'Ok';
            let cancel_text = 'cancel_text' in this.props.alertParam ? this.props.alertParam.cancel_text : 'Cancel';
            let message = 'message' in this.props.alertParam ? this.props.alertParam.message : '';
            let html_message = 'html_message' in this.props.alertParam ? this.props.alertParam.html_message : '';
            message = html_message === '' ? message : html_message
            let is_html = html_message === '' ? false : true;
            this.targetElement = document.querySelector('#CSLAlertBox');
            let align = 'body_align' in this.props.alertParam ? this.props.alertParam.body_align : 'center'
            // console.log('props', this.props)
            disableBodyScroll(this.targetElement);

            this.setState({
                title: title, confirm: confirm, danger: danger, ok_text: ok_text, cancel_text: cancel_text,
                message: message, html_message: html_message, is_html: is_html, align: align
            })
        }
    }

    componentDidUpdate(prev_props) {
        if (this.props !== prev_props && this.props.alertParam !== null) {
            this.setComponentState()
        }
    }
    componentDidMount() {
        this.setComponentState()
    }

    processOk = () => {
        enableBodyScroll(this.targetElement);
        this.props.alertParam.alertHandler(true, this.props.alertParam.stack)
    }

    processCancel = () => {
        enableBodyScroll(this.targetElement);
        this.props.alertParam.alertHandler(false, this.props.alertParam.stack)
    }

    render() {

        if (this.props.alertParam === null) return <div></div>

        // Utils.log('this.state', this.state)
        // Utils.log('this.props.alertParam', this.props.alertParam)

        return (
            <div>
                <InactiveOverlay />
                <AlertBoxContainer id="CSLAlertBox" style={{top: "position" in this.props.alertParam === true ? this.props.alertParam.position.top : "40vh", left: "position" in this.props.alertParam === true ? this.props.alertParam.position.left : "40vw"}}>
                    <AlertBoxHeader>{this.state.title}
                    </AlertBoxHeader>
                    <AlertBoxBody dangerouslySetInnerHTML={{ __html: this.state.message }} style={{ textAlign: this.state.align }} />
                    <AlertBoxFooter>
                        {
                            (() => {
                                if (this.state.danger) {
                                    return <OkDangerButton onClick={this.processOk}>{this.state.ok_text}</OkDangerButton>
                                } else {
                                    return <OkButton onClick={this.processOk}>{this.state.ok_text}</OkButton>
                                }

                            })()
                        }
                        {
                            (() => {
                                if (this.state.confirm) return <CancelButton onClick={this.processCancel}>{this.state.cancel_text}</CancelButton>
                            })()
                        }
                    </AlertBoxFooter>
                </AlertBoxContainer>
            </div>
        )
    }
}
export default AlertBox;
