import React, { Component } from 'react';
import CSLLoader from '../../Common/CSLLoader';
import APICall from "../../../Common/APICall"
import Store from '../../../Common/Store';
import moment from 'moment';


class AssessmentConfig extends Component {
    state = {
        assessed_by: [],
        ready: false,
        assessed_by_arr: [],
        selected_assessed_by: 0,
        dropdown_label: "",
        dropdown_value: [],
        selected_dropdown_value: 0,
        is_dropdown_visible: false,
        assessment_crud_details: {
            created_by: 0,
            created_at: 0,
            updated_by: 0,
            updated_at: 0
        }
    }
    componentDidMount() {
        this.initialiseState();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.initialiseState();
        }
    }

    initialiseState = () => {
        const postData = { command: "assessments", action: 'get_assessment_assessedby', id: this.props.assessment_id };
        console.log('postDATA ==>>', postData)
        const api = new APICall();
        api.command(postData, this.getListAssessedBy);
    }

    getListAssessedBy = (r) => {
        const sldata = JSON.parse(localStorage.getItem("site_layout"));


        let assessed_by = r.result.selected_assessed_by_type;
        let dropdownValue = assessed_by[0]["assessed_by_id"];
        let lists = r.result.assed_by_types;

        let crudDetails = r.result.assessment_crud_time[0];
        let crud = {
            created_by: 0,
            created_at: 0,
            updated_by: 0,
            updated_at: 0
        }
        crud.created_by = crudDetails.created_by != 0 ? sldata.result.use_storage.result.contactlist[crudDetails.created_by]['ContactName'] : "";
        crud.created_at = moment.unix(crudDetails.created_at).format('DD/MM/YYYY');
        crud.updated_by = crudDetails.update_by != 0 ? sldata.result.use_storage.result.contactlist[crudDetails.update_by]['ContactName'] : "";
        crud.updated_at = crudDetails.update_by != 0 ? moment.unix(crudDetails.last_action_time).format('DD/MM/YYYY') : "";

        const assessmentCrudDetails = {
            created_by: crud.created_by,
            created_at: crud.created_at,
            updated_by: crud.updated_by,
            updated_at: crud.updated_at
        };

        let selected_assessed_by = assessed_by[0]["id"];
        let is_dropdown_visible = assessed_by[0]["assessed_by"] == "Select a specific User";
        if (is_dropdown_visible) {

            this.handleDropDownChangeAssessedBy(selected_assessed_by);
        }
        this.setState({ ready: true, assessed_by: assessed_by, assessed_by_arr: lists, selected_assessed_by, is_dropdown_visible, selected_dropdown_value: dropdownValue, assessment_crud_details: assessmentCrudDetails })
    }



    handleChangeAssessedBy = (evt) => {
        let id = evt.target.value;
        this.handleDropDownChangeAssessedBy(id);
    }


    handleDropDownChangeAssessedBy = (id) => {
        let dropdown_label = "";
        let dropdown_value = [];

        if (id == 6) {
            // let users = Store.getStoreData("listcontacts");
            // let user_roles = Store.getStoreData("user_roles");
            // let u = [];

            // for (const user of users) {
            //     let uid = user.ID;
            //     let user_role = user_roles[uid];
            //     if (user_role == 'no_access') {
            //         continue;
            //     } else if (user_role == 'team') {
            //         continue;
            //     } else {
            //         u.push(user)
            //     }
            // }

            const cts = JSON.parse(localStorage.getItem("contactlist"));
            let contacts = cts.result.contactlist;

            let user_roles = cts.result.user_roles.smcr;

            const u = [];

            for (const empId in contacts) {
                if (contacts.hasOwnProperty(empId)) {
                    const contact = contacts[empId];
                    const contactId = contact["ID"];
                    const contactName = contact["ContactName"];
                    if (user_roles.hasOwnProperty(contactId) && user_roles[contactId] !== "no_access") {
                        u.push({ 'ID': contactId, 'ContactName': contactName });
                    }
                }
            }

            dropdown_value = u;
            dropdown_label = "Select Specific User"
            this.setState({ selected_assessed_by: id, dropdown_label: dropdown_label, is_dropdown_visible: true, dropdown_value: dropdown_value });
        }

        else {
            this.changeAssessedByType(id);
            this.setState({ selected_assessed_by: id, dropdown_label: "", is_dropdown_visible: false, dropdown_value: [] });
        }
    }


    changeAssessedByType = (id) => {
        this.setState({ ready: false });
        const postData = { command: "assessments", action: 'update_assessed_by_type', assessment_id: this.props.assessment_id, assessed_by_type: id };
        const api = new APICall();
        api.command(postData, this.updateAssessedByType);
    }

    updateAssessedByType = (result) => {
        const sldata = JSON.parse(localStorage.getItem("site_layout"));
        let resp = result.result.assessed_by[0];
        let assessed_by_type = resp.assessed_by_type;
        const assessmentCrudDetails = {
            created_by: sldata.result.use_storage.result.contactlist[resp.created_by]['ContactName'],
            created_at: moment.unix(resp.created_at).format('DD/MM/YYYY'),
            updated_by: sldata.result.use_storage.result.contactlist[resp.update_by]['ContactName'],
            updated_at: moment.unix(resp.last_action_time).format('DD/MM/YYYY'),
        };
        this.setState({ assessed_by: assessed_by_type, ready: true, assessment_crud_details: assessmentCrudDetails })
    }


    handleChangeDynamicDropdown = (evt) => {
        this.setState({ selected_dropdown_value: evt.target.value, ready: false });
        const postData = { command: "assessments", action: 'update_assessed_by_user', assessment_id: this.props.assessment_id, assessed_by_type: this.state.selected_assessed_by, user_id: evt.target.value };
        const api = new APICall();
        api.command(postData, this.updateAssessedByType);
    }



    render() {
        if (!this.state.ready) {
            return (<div><CSLLoader /></div>);
        }
        return (
            <div>
                <div className='assessment-config-container'>
                    <div className='assessment-box-container'>
                        <div className='assessment-block-container'>
                            <div className="assessment-config-text-div" style={{ width: '25%', whiteSpace: 'nowrap' }}>
                                <p className='assessment-config-text'>Created by</p>
                                <p className='assessment-config-text'>{this.state.assessment_crud_details.created_by}</p>
                            </div>
                            <div className="assessment-config-text-div">
                                <p className='assessment-config-text'>Created Date</p>
                                <p className='assessment-config-text'>{this.state.assessment_crud_details.created_at}</p>
                            </div>
                            <div className="assessment-config-text-div">
                                <p className='assessment-config-text'>Last Updated By</p>
                                <p className='assessment-config-text'>{this.state.assessment_crud_details.updated_by}</p>
                            </div>
                            <div className="assessment-config-text-div">
                                <p className='assessment-config-text'>Updated Date</p>
                                <p className='assessment-config-text'>{this.state.assessment_crud_details.updated_at}</p>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <div className='assessment-box-container'>
                        <div className="assessment-block-container">
                            <span style={{ "padding": "10px", "fontSize": "13px" }}>
                                Once all the Topics within an Assessment have been completed, the Overall Assessor is responsible for reviewing them and marking the
                                Assessment as complete.</span>
                        </div>
                    </div>

                    <div className='assessment-config-box'>
                        <div className='assessment-config-body-container'>
                            <div className='assessment-config-default-acessor'>Default Assessor </div>
                            <div className='assessment-config-big-text'>
                                Please select the type of person or specific user who will review and complete these assessments
                            </div>
                            <select
                                name="field_assessed_by"
                                value={this.state.selected_assessed_by}
                                onChange={this.handleChangeAssessedBy}
                                className='assessment-config-dropdown'
                            >
                                {this.state.assessed_by_arr.length > 0 && (
                                    <option key={0} value={0}>
                                        Select
                                    </option>
                                )}
                                {this.state.assessed_by_arr.map((ab) => (
                                    <option key={ab.id} value={ab.id}>
                                        {ab.assessed_by}
                                    </option>
                                ))}
                            </select>

                            {this.state.is_dropdown_visible && (
                                <React.Fragment>
                                    <div className='assessment-config-dropdown-label'>{this.state.dropdown_label}</div>
                                    <select
                                        className='assessment-config-dropdown'
                                        name="field_dynamic_by"
                                        value={this.state.selected_dropdown_value}
                                        onChange={this.handleChangeDynamicDropdown}
                                    >
                                        {this.state.dropdown_value.length > 0 && (
                                            <option key={0} value={0}>
                                                Select
                                            </option>
                                        )}
                                        {this.state.dropdown_value.map((gc) => (
                                            <option key={gc.ID} value={gc.ID}>
                                                {gc.ContactName}
                                            </option>
                                        ))}
                                    </select>
                                </React.Fragment>
                            )}
                        </div>

                    </div>
                </div>
            </div >
        )
    }
}

export default AssessmentConfig;
