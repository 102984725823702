import React from "react"
import "./ModalStyle.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import APICall from "../../../Common/APICall"
import CSLLoader from "./../../Common/CSLLoader"
import CSLTable from "../../Common/CSLTable"
import EditRoleModal from "./EditRoleModal"
import AlertBox from "../../Common/AlertBox"

class RoleConfigModal extends React.Component {

    state = { role_type: null, roles: [], help_text: "", color: "", edit_role_id: 0, role_numbers: [], show_edit_role: false, alert_param: null }

    async componentDidMount() {
        const api = new APICall()
        const payload = { command: "get_mc_role_type_and_roles", role_type_id: this.props.role_type_id }
        const result = await api.commandWithoutCallback(payload)
        console.log("result:", result)
        if (result.error_code === 0) {
            let roles = JSON.parse(JSON.stringify(result.result.roles));
            for (let i = 0; i < roles.length; i++) {
                roles[i].context_visible = false;
            }
            this.setState({
                role_type: result.result.role_type,
                roles: roles,
                help_text: JSON.parse(result.result.role_type.role_type_json).help_text,
                color: result.result.role_type.color
            })
        }
    }

    handleChange = (event) => {
        event.preventDefault()
        this.setState({ [event.target.name]: event.target.value })
    }

    handleRoleType = (event) => {
        let role_type = JSON.parse(JSON.stringify(this.state.role_type))
        role_type[event.target.name] = event.target.value
        this.setState({ role_type })
    }

    toggleAcceptance = (event) => {
        event.preventDefault()
        let role_type = JSON.parse(JSON.stringify(this.state.role_type))
        role_type.requires_acceptance = role_type.requires_acceptance === 1 ? 0 : 1
        this.setState({ role_type })
    }

    closeModal = (e) => {
        e.preventDefault();
        this.props.closeModal();
    }

    toggleActive = (role_id) => async (event) => {
        event.preventDefault()
        let active = 0
        let roles = JSON.parse(JSON.stringify(this.state.roles))
        for (let i = 0; i < roles.length; i++) {
            if (roles[i].id === role_id) {
                roles[i].is_active = roles[i].is_active === 1 ? 0 : 1
                active = roles[i].is_active
            }
        }
        this.setState({ roles })
        const payload = { command: "role_api", action: "toggle_active", role_id: role_id, active: active }
        const api = new APICall()
        const ret = await api.commandWithoutCallback(payload)
        console.log(ret);
    }

    toggleContextMenu = (role_id, row_id) => (event) => {
        event.preventDefault();
        // console.log("toggleContextMenu", role_id, row_id);
        let roles = JSON.parse(JSON.stringify(this.state.roles));
        for (let i = 0; i < roles.length; i++) {
            if (roles[i].id === role_id) {
                // roles[i].context_visible = roles[i].context_visible === true ? false : true;
                roles[i].context_visible = true;
                // break;
            } else {
                roles[i].context_visible = false;
            }
        }
        this.setState({ roles })
    }

    closeContextMenu = (role_id) => (event) => {
        event.preventDefault()
        // console.log("closeContextMenu", role_id)
        let roles = JSON.parse(JSON.stringify(this.state.roles));
        for (let i = 0; i < roles.length; i++) {
            if (roles[i].id === role_id) {
                roles[i].context_visible = false;
                break;
            }
        }
        this.setState({ roles })
    }

    removeRole = (role_id) => (event) => {
        event.preventDefault()
        let alert_param = {
            title: 'Are you sure?', message: "You have selected to delete this Role.", ok_text: 'Ok', confirm: true,
            alertHandler: this.processDeleteRole, stack: { role_id: role_id }
        }
        this.setState({ alert_param: alert_param });
    }

    processDeleteRole = async (result, stack) => {
        console.log("result:", result)
        console.log("stack:", stack)
        if (result === true) {
            const payload = { command: "role_api", action: "remove_role", role_id: stack.role_id }
            const api = new APICall()
            const ret = await api.commandWithoutCallback(payload)
            if (ret.error_code === 0) {
                if (ret.result === null) {
                    let roles = []
                    for (let item of this.state.roles) {
                        if (item.id !== stack.role_id) roles.push(item)
                    }
                    this.setState({ alert_param: null, roles });
                } else {
                    let alert_param = {
                        title: 'ERROR', message: "This role cannot be removed as the role is already assigned", ok_text: 'Ok',
                        alertHandler: this.notRevomable, stack: {}
                    }
                    this.setState({ alert_param: alert_param });
                }
            } else {
                this.setState({ alert_param: null });
            }
        } else {
            this.setState({ alert_param: null });
        }
    }

    notRevomable = (result, stack) => {
        this.setState({ alert_param: null });
    }

    generateTableData = () => {
        let ret = { data: [], columns: [] }
        ret.columns = [
            { Header: 'ROLE', accessor: 'code', maxWidth: 100 },
            { Header: 'TITLE', accessor: 'title' },
            {
                Header: "",
                Cell: row => (
                    <div className="mc-roles-table-action-container" style={{ width: "100%", paddingRight: "10px" }}>
                        {
                            row.original.is_active === 1 ?
                                <FontAwesomeIcon className="mc-roles-add-icon" icon={regular('toggle-on')} onClick={this.toggleActive(row.original.id)} /> :
                                <FontAwesomeIcon className="mc-roles-add-icon" style={{ color: "#ababab" }} icon={regular('toggle-off')} onClick={this.toggleActive(row.original.id)} />
                        }
                        <div>
                            <FontAwesomeIcon className="mc-roles-add-icon" icon={regular('ellipsis-vertical')} onClick={this.toggleContextMenu(row.original.id, row.viewIndex)} />
                            {
                                row.original.context_visible &&
                                <div className="context-menu" onMouseLeave={this.closeContextMenu(row.original.id)}>
                                    <div className="context-menu-item" onClick={this.showRoleEditModal(row.original.id)}>Edit</div>
                                    <div className="context-menu-item" onClick={this.removeRole(row.original.id)}>Remove</div>
                                </div>
                            }
                        </div>
                    </div>
                ),
                maxWidth: 70,
                filterable: false
            }
        ]
        this.state.roles.forEach((item) => {
            let role_json = JSON.parse(item.role_json)
            let elem = { id: item.id, code: item.role_code, title: role_json.title, is_active: item.is_active, context_visible: item.context_visible };
            ret.data.push(elem);
        })
        ret.data.sort((a, b) => {
            let a1 = parseInt(a.code.split("#")[1])
            let b1 = parseInt(b.code.split("#")[1])
            if (a1 < b1) return -1
            if (a1 > b1) return 1
            return 0
        })
        return ret;
    }

    showRoleEditModal = (role_id) => (event) => {
        console.log("showRoleEditModal function called")
        event.preventDefault();
        let role_numbers = []
        if (this.state.roles.length !== 0) {
            for (let role of this.state.roles) {
                let role_code_arr = role.role_code.split("#")
                role_numbers.push(parseInt(role_code_arr[role_code_arr.length - 1]))
            }
            role_numbers.sort()
        }
        this.setState({ role_numbers: role_numbers, edit_role_id: role_id, show_edit_role: true });

    }

    updateRoleType = async (event) => {
        event.preventDefault()
        const role_type_data = {
            role_type_id: this.props.role_type_id,
            help_text: this.state.help_text,
            color: this.state.color,
            name: this.state.role_type.name,
            base: this.state.role_type.base,
            requires_acceptance: this.state.role_type.requires_acceptance
        }
        const payload = { command: "role_api", action: "update_role_type", data: role_type_data }
        const api = new APICall()
        const result = await api.commandWithoutCallback(payload)
        let header = result.error_code === 0 ? "SUCCESS" : "ERROR"
        let alert_param = {
            title: header, message: result.error_msg, ok_text: 'Ok',
            alertHandler: this.afterUpdateRoleType, stack: {}
        }
        this.setState({ alert_param })
    }

    afterUpdateRoleType = () => {
        this.setState({ alert_param: null })
        this.props.setComponentState()
    }

    upsertRole = async (role_obj) => {
        // console.log("role_obj:", role_obj)
        let base = this.state.role_type.base + "#" + role_obj.selected_role_code_no.toString().padStart(2, '0')
        role_obj.id = this.state.edit_role_id
        role_obj.role_type_id = this.state.role_type.id
        role_obj.base = base
        console.log("role_obj:", role_obj)
        const payload = { command: "role_api", action: "upsert_role", data: role_obj, role_id: this.state.edit_role_id }
        let roles = JSON.parse(JSON.stringify(this.state.roles))

        const api = new APICall()
        let result = null
        let alert_param = null
        if (this.state.edit_role_id === 0) {
            const exist = roles.find(item => item.role_code === role_obj.base)
            // console.log("exist:", exist)
            if (exist === undefined) {
                result = await api.commandWithoutCallback(payload)
                if (result.error_code === 0) {
                    let role_id = result.result.id
                    roles.push({ id: role_id, role_code: role_obj.base, role_json: JSON.stringify({ title: role_obj.title }), is_active: role_obj.is_active, context_visible: false })
                    alert_param = {
                        title: 'SUCCESS', message: "Role Created Successfully.", ok_text: 'Ok',
                        alertHandler: () => { this.setState({ alert_param: null }) }, stack: {}
                    }
                    this.setState({ roles, show_edit_role: false, alert_param })
                }
            } else {
                alert_param = {
                    title: 'ERROR::Duplication', message: "Cannot create role. A role with the same reference is already present.", ok_text: 'Ok',
                    alertHandler: () => { this.setState({ alert_param: null }) }, stack: {}
                }
                this.setState({ alert_param })
            }
        } else {
            result = await api.commandWithoutCallback(payload)
            for (let i = 0; i < roles.length; i++) {
                if (roles[i].id === this.state.edit_role_id) {
                    roles[i].role_code = base
                    roles[i].role_json = JSON.stringify({ title: role_obj.title })
                    break
                }
            }
            alert_param = {
                title: 'SUCCESS', message: "Role Modifed Successfully.", ok_text: 'Ok',
                alertHandler: () => { this.setState({ alert_param: null }) }, stack: {}
            }
            this.setState({ roles, show_edit_role: false, alert_param })
        }
    }

    closeRoleEditModal = () => {
        this.setState({ show_edit_role: false })
    }

    render() {
        if (this.state.role_type === null) {
            return (<CSLLoader />);
        }

        return (
            <div>
                {this.state.alert_param !== null && <AlertBox alertParam={this.state.alert_param} />}
                <div className="add-role-modal-container" style={{ marginTop: "4%" }}>
                    {
                        this.state.show_edit_role === true &&
                        <div className="interactive-overlay" style={{ zIndex: 2 }}>
                            <EditRoleModal
                                role_id={this.state.edit_role_id}
                                role_type={{ name: this.state.role_type.name, base: this.state.role_type.base }}
                                role_numbers={this.state.role_numbers}
                                closeModal={this.closeRoleEditModal}
                                upsertRole={this.upsertRole}
                            />
                        </div>
                    }
                    <div className="add-role-modal-header-container">
                        <div className="add-role-modal-header-text-container">
                            <div className="add-role-modal-header-title">Update Role Type</div>
                            <div className="add-role-modal-header-subtitle">Use this section to add, edit, and delete Roles of this Role Type.</div>
                        </div>
                        <FontAwesomeIcon icon={solid('xmark')} onClick={this.closeModal} size="2x" style={{ cursor: "pointer" }} />
                    </div>
                    <div className="add-role-modal-body-container-large">
                        <div className="modal-section">
                            <div>
                                <div className="add-role-modal-label">Role Type Label</div>
                                {/* <input type="text" name="name" className="add-role-modal-input" value={this.state.role_type.name} onChange={this.handleRoleType} disabled={this.state.roles.length === 0 ? false : true} /> */}
                                <input type="text" name="name" className="add-role-modal-input" value={this.state.role_type.name} onChange={this.handleRoleType} />
                            </div>
                            <div>
                                <div className="add-role-modal-label">Role Reference</div>
                                <input type="text" name="base" className="add-role-modal-input" value={this.state.role_type.base} onChange={this.handleRoleType} disabled={this.state.roles.length === 0 ? false : true} />
                            </div>
                            <div>
                                <div className="add-role-modal-label">Help Text</div>
                                <textarea rows={6} className="add-role-modal-textarea" name="help_text" value={this.state.help_text} onChange={this.handleChange} />
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", columnGap: "15px", justifyContent: "flex-start", alignItems: "center" }}>
                                {
                                    this.state.role_type.requires_acceptance === 1 ?
                                        <FontAwesomeIcon className="mc-roles-add-icon" style={{ color: "#ababab" }} icon={regular('toggle-off')} onClick={this.toggleAcceptance} /> :
                                        <FontAwesomeIcon className="mc-roles-add-icon" icon={regular('toggle-on')} onClick={this.toggleAcceptance} />
                                }
                                <span style={{ fontSize: "14px", fontWeight: "500" }}>Roles of this type do not need Employee Acceptance</span>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                <div style={{ display: "flex", flexDirection: "row", columnGap: "15px", justifyContent: "flex-start", alignItems: "center", width: "60%" }}>
                                    <div className="add-role-modal-label2" style={{ lineHeight: "30px" }}>Regime Color</div>
                                    <input type="color" name="color" value={this.state.color} onChange={this.handleChange} />
                                </div>
                                <button className="add-role-modal-save-button" onClick={this.updateRoleType}>Save</button>
                            </div>
                        </div>
                        <div className="modal-section">
                            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                                <FontAwesomeIcon className="mc-roles-add-icon" title="Add Role" icon={regular('square-plus')} onClick={this.showRoleEditModal(0)} />
                            </div>
                            <div><CSLTable processData={this.generateTableData} tableRows={10} /></div>
                        </div>
                    </div>
                    <div className="add-role-modal-footer-container">
                        <button className="add-role-modal-cancel-button" onClick={this.closeModal}>Close</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default RoleConfigModal
