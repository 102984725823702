
import React from 'react';
import styled from 'styled-components';
import CSLLoader from '../Common/CSLLoader';
import "./HorizontalTabBar.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, thin, regular } from '@fortawesome/fontawesome-svg-core/import.macro'

const Tab_Inactive = styled.div`
	border-top-left-radius : 6px;
	border-top-right-radius : 6px;
    border : 1px solid #CDCECF;
    border-bottom-color:#E7EAED;
    margin-right: 6px;
    padding: 10px;
    background-color: #E7EAED;
    color:#1B3451;
    display:inline-block;
    font-weight: 700;
	width:20%;
	cursor:pointer;
	margin-bottom: 7px;
`;
const Tab_Active = styled.div`
	border-top-left-radius : 6px;
	border-top-right-radius : 6px;
    border : 1px solid #CDCECF;
    border-bottom-color:#FFFFFF;
    margin-right: 6px;
    padding: 10px;
    background-color: #FFFFFF;
    color:#1B3451;
    display:inline-block;
    font-weight: 700;
	width:20%;
	cursor:pointer;
	margin-bottom: 7px;
`;

class HorizontalTabBar extends React.Component {
	state = {
		active_section_id: null,
		role_types: null,
		isShow: false
	};
	constructor (props) {
		super(props);
		this.testRef = React.createRef();
	}
	componentDidMount = () => {
		this.componentSetState()
	}
	componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			this.componentSetState()
		}
	}

	componentSetState = () => {
		this.setState({ role_types: this.props.roleTypes })
	}

	changeTab(id) {
		this.props.changeTab(id)
	}	

	scroll = (scrollOffset) => {		
		this.testRef.current.scrollLeft += scrollOffset;	
		console.log("this.testRef===>", this.testRef)
		this.setState({isShow: true})		
	};

	render() {
		if (this.state.role_types == null) {
			return <CSLLoader />
		}
		return (
			<div style={{display: "flex"}}>
				<div className='left-arrow' style={{display: this.state.isShow ? "inline-block" : "none"}} onClick={() => this.scroll(-200)}><FontAwesomeIcon style={{display: this.state.isShow ? "inline-block" : "none", width: "15px", cursor: "pointer", color: "#000000", padding: "5px 10px"}} icon={solid('less-than')} /></div>
				<div style={{width: this.state.isShow ? "93%" : "96%", display: "inline-block", marginLeft: this.state.isShow ? "0px" : "7px"}}>
					<div className='tab-container' ref={this.testRef}>
						
						{this.state.role_types.map((menuItem, index) => {
								if (menuItem.selected) {
									return (
										<div className='tab-active' key={index} id={menuItem.id} onClick={() => this.changeTab(menuItem.id)}>{menuItem.role_type}</div>
									)
								} else {
									return (
										<div className='tab-inactive' key={index} id={menuItem.id} onClick={() => this.changeTab(menuItem.id)}>{menuItem.role_type}</div>
									)
								}
						})}				
					</div>
				</div>
				<div className='right-arrow' onClick={() => this.scroll(200)}><FontAwesomeIcon style={{width: "15px", cursor: "pointer", color: "#000000", padding: "5px 10px"}} icon={solid('greater-than')} /></div>
			</div>
		);

	}
}

export default HorizontalTabBar;
