import React from 'react';
import styled from 'styled-components';
const arrow_up = `/9j/4AAQSkZJRgABAQEAYABgAAD/4QAiRXhpZgAATU0AKgAAAAgAAQESAAMAAAABAAEAAAAAAAD/2wBDAAIBAQIBAQICAgICAgICAwUDAwMDAwYEBAMFBwYHBwcGBwcICQsJCAgKCAcHCg0KCgsMDAwMBwkODw0MDgsMDAz/2wBDAQICAgMDAwYDAwYMCAcIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAz/wAARCAAOABEDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD9AP8AgvN/wVGtv+CXP7DWqa1pF9DH8UPG4l0PwTbkxtJFclR52oGN87o7SNw+SrKZntkYYkJHkP8AwbG/8FZZ/wDgoF+yPL8P/HGstqHxa+EcMVpdT3dyJLvxDpBwlrfHOGkkjI+zzN85LLDI7l7jFfmn/wAFwvgn8af+Cm37efibxNNrPhDTfB/hOabw14U0i41C43WNjbzOplkCW23z55N8znLFd6xh3SJDXlf/AATv/Ze+Ov8AwTT/AGuPCnxe8M654Jum8NzMNV0tdVvIY9d06QbbqykP2UjEkedrMrCORY5ApaNaAP6tsUVh/wDCzNH/AOeepf8AftP/AIqigD//2Q==`
const arrow_down = `/9j/4AAQSkZJRgABAQEAYABgAAD/4QAiRXhpZgAATU0AKgAAAAgAAQESAAMAAAABAAEAAAAAAAD/2wBDAAIBAQIBAQICAgICAgICAwUDAwMDAwYEBAMFBwYHBwcGBwcICQsJCAgKCAcHCg0KCgsMDAwMBwkODw0MDgsMDAz/2wBDAQICAgMDAwYDAwYMCAcIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAz/wAARCAAMAA8DASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwDxv/g6K/Y4+I/7D37SUfxe+HXi7x5pfwv+K15NcXdlaeIL0W+g65IzS3Uar5p2RXLF7hFB2qzyooRFjUexf8GoH7E3jr4w6hqX7R/xW8ReMNe8O6Q02leB9K1fV7q4tL26dGiur94nk2SLHE7woHDKXlkbAaNGr9jv2vf2P/Bf7d/7Nvin4YfEC1ubrw34ghxI1rIsVzaSxuDHPC5VgsikZBKkdQQRxXQ/Bf4NeHv2dfhN4Z8B+EbFNL8NeE9Ph0zTbZefKijTAJ9WbksSOSSaAP/Z`

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1000;
  opacity: 0.8;
`;

const UserFormFooter = styled.div`
    position: relative;
    margin-top: -40px;
    margin-left: -36px;
    margin-right: -36px;
    margin-bottom: -50px;
    background-color: white;
    border-style: solid;
    border-width: 1px;
    border-color: #00CDFF;
    height: 80px;
`;

const SubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #046DE4;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
`;

const LinkButton = styled.a`
    display: inline-block;
    box-sizing: border-box;
    background-color: #046DE4;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
`;

const SelectDropDown = styled.select`

    display: inline-block;
    box-sizing: border-box;
    height: 34px;
    width: 97%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    color: #979797;
    font-family:montserrat ;
    font-size:14px;
    font-weight:600;
`;

const HeadContainer = styled.div`
    display: block;
    width: 100%;
    height: 35px;
    background-color: #ffffff;
    padding-left: 10px;
    padding-right: 10px;
`;
const HomeLogo = styled.div`
    float: left;
    margin-top: 7px;
    font-size: 16px;
    color: #929292;
`;
const HomeText = styled.div`
    float: left;
    margin-top: 7px;
    color: #929292;
    margin-left: 6px;
    font-size: 13px;
`;
const NoPermission = styled.div`
  display: block;
  box-sizing: border-box;
  height: 40px;
  font-size:20px;
  font-weight: 700;
  text-align: center;
  background-color: #ffffff;
  position: absolute;
  top: 100px;
  left: 10px;
  right:10px;
  bottom : 10px;
  border: 2px solid #37ada7;
  border-radius:10px;
  color: #37ada7;
  z-index: 1000;
`;


const ModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 100%;
    position: absolute;
    top: 0px;
    z-index: 1001;
    background-color: #F3F7FB;
    box-shadow: 0 0 5px #a7a6a6;
    margin-bottom: 10px;
    border-radius: 5px;
`;

const ModalHeader = styled.div`
    border-width:1px;
    background-color: white;
    border-left-width: 10px;
    border-style: solid;
    border-color: #EDEDED;
    border-left-color: #11ABA6;
    display: block;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 700;
    color: #046DE4;
    width: calc(100% - 10px);
`;

const ModalHeaderText = styled.div`
    float: left;
    font-weight: bold;
    box-sizing: border-box;
    width: calc(100% - 60px);
    font-size: 24px;
    margin-top: 16px;
    padding-left: 30px;
    background-color
    color: #046DE4;
    font-family: 'Montserrat', sans-serif;
`;

const ModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 4px;
`;
const ModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 25px;
    background-color: #f7f7f7;
`;
const ModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 5px;
    font-weight: 600;
    color: #282828;
`;

const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top:5px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const ModalInput = styled.input`
    height: 40px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const ModalClearDiv = styled.div`
    border-radius: 3px 3px 0px 0px;
    width:100%;
    height:10px;

`;

const UserFormContainer = styled.div`
    padding: 35px;
    padding-left:25px;
    padding-right:25px;
    position: relative;
`;

const ModalTab = styled.div`
    position: absolute;
    top: 106px;
    height: 30px;
    padding-top: 10px;
    text-align: center;
    background-color: white;
    font-size: 16px;
    width: 160px;
    color: #046DE4;
    border-style: solid;
    border-width: 1px;
    border-color: #A2A2A2;
    font-weight:400;
    cursor: pointer;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    z-index: 3000;
`;

const ModalTabActive = styled.div`
    position: absolute;
    top: 106px;
    height: 30px;
    padding-top: 10px;
    background-color: white;
    text-align: center;
    border-style: solid;
    font-size: 16px;
    width: 160px;
    color: #046DE4;
    font-weight:700;
    border-width: 1px;
    border-color: #A2A2A2;
    border-top-color: white;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    z-index: 3001;
`;

const ModalWiderHeaderDiv = styled.div`
    border-radius: 4px 4px 0px 0px;
    background-color: #04ACA7;
    width:100%;
    height:40px;
    min-height:40px;
`
const ModalWiderLineDiv = styled.div`
    background-color: #E4E4E4;
    width:100%;
    height:3px;
    min-height:3px;
`;
const ModalWiderDiv = styled.div`
    height: auto;
    width: 100%;
`;
const ModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 16px;
    margin-right: 20px;
    font-size: 36px;
    cursor: pointer;
    font-weight: 500;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #046DE4;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 127px;
    font-size: 13px;
    height: 39px;
    border-radius: 4px;
    float: right;
    margin-top: -30px;
    cursor: pointer;
`;
const CloseBtn = styled.div`
    float: right;
    font-size: 20px;
    color: #9c9c9c;
    cursor: pointer;
    padding-top: 10px;
    padding-right: 5px;
`;
const BodyContainer = styled.div`
    padding-top: 5px;
    width: 100%;
`;
const LeftContainer = styled.div`
    float: left;
    padding-right: 20px;
    width: 200px;
    box-sizing: border-box;
`;
const RightContainer = styled.div`
    float: right;
    padding-left: 20px;
    width: calc(100% - 200px);
    box-sizing: border-box;
    margin-right: 200px;
`;
const RightContainerFullWidth = styled.div`
    float: right;
    padding-left: 20px;
    width: calc(100% - 2px);
    box-sizing: border-box;
    margin-right: 10px;
`;
const TeamsTab = styled.div`
    background-color: #ffffff;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    padding: 10px 20px;
    cursor: pointer;
`;

const DialogBoxHeader = styled.div`
  display: block;
  border-bottom-width:1px;
  border-left-width: 10px;
  border-bottom-style: solid;
  border-left-style: solid;
  border-color: #00CDFF;
  border-radius: 5px 5px 0px 0px;
  padding: 0px;
  margin-top: 0px;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 600;
  height: 120px;
  color: #046DE4;
  background-color: white;
  width: calc(100% - 10px);
`;

const MemberBox = styled.div`
  display: block;
  border-width:1px;
  border-left-width: 10px;
  border-style: solid;
  border-color: #00CDFF;
  border-radius: 3px 10px 10px 3px;
  padding-left: 0px;
  margin-top: 0px;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  color: #1A3552;
  width: 400px;
`;
const MemberBoxHeader = styled.div`
  position: absolute;
  top: 10px;
  left:0px;
  display: block;
  padding-left: 15px;
  padding-top: 5px;
  font-size: 14px;
  font-weight: 700;
  height: 40px;
  color: #046DE4;
  width: 400px;
`;
const MemberButton = styled.div`
  position: absolute;
  display: block;
  font-size: 14px;
  padding-top: 2px;
  padding-left: 1px;
  padding-right: 1px;
  font-weight: 600;
  border-width:1px;
  text-align: center;
  border-style: solid;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
  height: 25px;
  color: #000;
  width: 25px;
`;

const MemberConnector = styled.div`
  display: block;
  position: absolute;
  border-color: #046DE4;
  left: -69px;
  top: 2px;
  background-color: transparent;
  border-bottom-width:4px;
  border-left-width:4px;
  border-left-style: solid;
  border-bottom-style: solid;
  width: 50px;
`;

const MemberSelectionBox = styled.div`
  position: absolute;
  z-index: 2000;
  top: 30px;
  left: 15px;
  display: block;
  background-color: white;
  border-width:2px;
  border-color: #1A3552;
  border-style: solid;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  height: 250px;
  color: blue;
  width:300px;
`;

const MemberSelectionBoxHeader = styled.div`
  width: calc(100% - 19px);
  height: 30px;
  color: white;
  font-size: 16px;
  padding-left: 15px;
  padding-top: 5px;
  margin-left: -1px;
  margin-top:-1px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-color: #1A3552;
  border-style: solid;
  background-color: #1A3552;
`;

const MemberSelectionBoxRow = styled.div`
  width: 100%;
  height: 25px;
  color: #2d2d2d;
  cursor: pointer;
  padding-left: 15px;
  font-size: 14px;
  font-weight: 500;

  &:hover {
    color: white;
    background-color: grey;
  }
`;
const MemberMenuButton = styled.div`
    position: absolute;
    background-color: #FEEBF4;
    font-size: 18px;
    padding-left: 7px;
    padding-top: 7px;
    top: 0px;
    right: -16px;
    color: #F5239A;
    border-radius: 50%;
    border-color: #F5239A;
    border-style: solid;
    border-width: 1px;
    width: 26px;
    height: 26px;
    cursor: pointer;
`;

const MemberMenu = styled.div`
    position: absolute;
    background-color: #FEEBF4;
    font-size: 16px;
    top: 10px;
    left: 415px;
    color: #F5239A;
    border-radius: 5px;
    padding-top: 8px;
    padding-bottom: 4px;
    width: 200px;
    z-index: 4000;
`;

const MemberMenuLine = styled.div`
    width: calc(100% - 20px);
    padding-left: 15px;
    padding-top: 1px;
    padding-bottom: 6px;
    padding-right: 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

`;

const MemberMenuLineSpacer = styled.div`
    width: 100%;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #F5DADA;
    margin-bottom: 5px;
    margin-top: 3px;
`;

const MemberBoxHr = styled.div`
    position: absolute;
    border-top: 1px solid #A0CDFF;
    width: 100%;
`;

const MemberPhotoBox = styled.div`
    position: absolute;
    top: 15px;
    left: 15px;
    border-radius: 50%;
    width: 80px;
    height: 80px;
`;

const MemberProfileBox = styled.div`
    position: absolute;
    top: 17px;
    left: 80px;
    color: #046DE4;
    width: 320px;
    font-size: 22px;
    font-weight: 700;
`;

const OrgMenu = styled.div`
    height: 43px;
    width: calc(100% + 2px);
    background-color: #F9FAFB;
    position: relative;
    color: #9CA4B2;
    border-bottom-style: solid;
    border-bottom-color:  #FEEBF4;
    border-bottom-width: 2px;
    padding-top: 3px;
    padding-left: 0px;
`;
const ZoomMenu = styled.div`
    height: 33px;
    width: calc(100% + 2px);
    background-color: #F9FAFB;
    color: #046EE5;
    border-bottom-style: solid;
    border-bottom-color:  #FEEBF4;
    border-bottom-width: 2px;
    padding-top: 3px;
`;

const OrgMenuItems = styled.div`
    padding-top: 10px;
    width: calc(100% - 180px);
    white-space: nowrap;
    overflow: hidden;
`;


const OrgLegends = styled.div`
    position: sticky;
    top: 15px;
    left: calc(100% - 285px);
    width: 180px;
    height: 40px;
    background-color: 'white';
    border-radius: 10px;
    border-width: 1px;
    border-style: solid;
    color: #046EE5;
    border-color: #046EE5;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 15px;
    z-index: 3000;
`;

const OrgMenuMoreButton = styled.div`
    color: #046EE5;
    position: absolute;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 18px;
    float: left;
    padding-left: 8px;
    padding-right: 50px;
    padding-top: 13px;
    right: 0px;
    top: 0px;
    cursor: pointer;
`;
const OrgMenuSpacer = styled.div`

    min-width: 75px;
    float: left;
`;

const OrgMenuTeamButton = styled.div`
    color: #373838;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 18px;
    padding-left: 8px;
    padding-right: 18px;
    display: inline-block;
    cursor: pointer;
    max-width: 250px;
    &:hover {
      color: #046DE4;
      font-weight: 700;
    }

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const OrgMenuSelectedTeamButton = styled.div`
    color: #046DE4;
    text-transform: uppercase;
    max-width: 250px;
    font-weight: 700;
    font-size: 18px;
    padding-left: 8px;
    padding-right: 18px;
    display: inline-block;
    float: left;
    border-left-width: 10px;
    border-left-style: solid;
    border-left-color: #F50089;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const ZoomMenuButton = styled.div`
    width: 30px;
    background-color: #FFFFFF;
    font-size: 12px;
    color: #046EE5;
    border-radius: 2px;
    height: 20px;
    float: right;
    text-align: center;
    padding-top: 5px;
    margin-right: 3px;
    cursor: pointer;
    margin-left: 3px;
    font-weight: 700;
`;
const MenuButton = styled.div`
    width: 120px;
    background-color: #768697;
    color: #F1F2F3;
    border-radius: 7px;
    height: 25px;
    float: right;
    text-align: center;
    padding-top: 6px;
    margin-right: 5px;
    cursor: pointer;
    margin-left: 8px;
    font-weight: 500;
`;

const AddTeamPopup = styled.div`
    opacity: 1.0;
    z-index: 10000;
    top: 120px;
    left:18px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-style: solid;
    border-width:1px;
    border-color: #1A3552;
    position: absolute;
    background-color: #768697;
    color: #1A3552;
    padding-left: 20px;
    width: 350px;
    height: 300px;
    overflow-y: auto;
    box-sizing: border-box;
    padding-right: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

const AddTeamPopupLine = styled.div`
    width: calc(100% - 25px);
    height: 25px;
    background-color: #768697;
    cursor: pointer;
    color: #F1F2F3;
    padding-left: 5px;
    font-size: 15px;
    font-weight: 400;
    &:hover {
      color: #A3AEBA;
      background-color: #1A3552;
      font-weight: 600;
    }
`;

const SpacerXL = styled.div`
    height: 20px;
`;

const SpacerL = styled.div`
    height: 10px;
`;


const ContactInput = styled.input`
    height: 20px;
    width: 92%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
    font-family:montserrat ;
    font-size:14px;
    font-weight:600;
`;

const SearchInput = styled.input`
    height: 20px;
    width: 92%;
    border: 0px;
    padding: 5px;
    color: #979797;
    font-family:montserrat ;
    font-size:14px;
    font-weight:600;
    &:focus {
      outline-width: 0;
    }
`;

const ContactLabel = styled.label`
    color: #3C3C3C;
    font-weight: 600;
    margin-top: 10px;
    font-size:17px
`;

const UserSelect = styled.select`

    display: inline-block;
    box-sizing: border-box;
    height: 34px;
    width: 94%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    color: #979797;
    font-family:montserrat ;
    font-size:14px;
    font-weight:600;
`;

const ModalContainertop = styled.div`
    display: grid;
    grid-template-columns: 20% calc(80% - 15px);
    grid-gap: 15px;
    padding: 10px;
`;

const ConfigBtn = styled.button`
    box-sizing: border-box;
    background-color: #0C6EE4;
    color: #ffffff;
    border: 1px solid #0C6EE4;
    font-size: 13px;
    border-radius: 4px;
    padding: 5px 20px;
    cursor: pointer;
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 350px;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-family: 'Montserrat', sans-serif;
`;
const ModalTextArea = styled.textarea`
    height: 40px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;

const ArrowUp = styled.div`
    background-image: url(data:image/png;base64,${arrow_up});
    width: 15px;
    height: 11px;
`;
const ArrowDown = styled.div`
    background-image: url(data:image/png;base64,${arrow_down});
    width: 15px;
    height: 11px;
    margin-top:2px;
    margin-left: 1px;
`;
const HDivider = styled.div`
    width: 88%;
    background: rgb(221, 229, 233);
    height: 2px;
    margin-left: 5%;
    margin-top: 7px;
    margin-bottom: 7px;
`;

const Circle = styled.div`
    height: 20px;
    width: 20px;
    background-color: #ffffff;
    border-radius: 50%;
    display: inline-block;
    color: #11ABA6;
    font-size: 27px;
    float: right;
`;
export {
    InactiveOverlay, HeadContainer, HomeLogo, HomeText, NoPermission, MemberBox, MemberButton, MemberSelectionBox,
    MemberSelectionBoxHeader, MemberSelectionBoxRow, MemberConnector, SubmitButton,
    ModalContainer, ModalHeader, ModalHeaderText, ModalBody, ModalBodyInner, ModalNameLabelDiv, ModalNameInputDiv,
    ModalInput, ModalClearDiv, ModalWiderHeaderDiv, ModalTabActive, ModalTab, ModalWiderLineDiv, ModalWiderDiv, ModalHeaderCloseBtn,
    MRButton, CloseBtn, BodyContainer, LeftContainer, RightContainer, TeamsTab, RightContainerFullWidth,
    OrgMenu, MenuButton, AddTeamPopup, AddTeamPopupLine, SelectDropDown, MemberBoxHeader, ZoomMenuButton,
    ZoomMenu, OrgMenuTeamButton, OrgMenuSelectedTeamButton, OrgMenuMoreButton, OrgMenuSpacer,
    MemberPhotoBox, MemberProfileBox, MemberMenu, MemberMenuButton, MemberMenuLine, MemberMenuLineSpacer, UserFormFooter,
    UserFormContainer, SpacerXL, SpacerL, ContactLabel, UserSelect, MemberBoxHr, OrgLegends, DialogBoxHeader,
    ContactInput, OrgMenuItems, LinkButton, SearchInput, ModalContainertop, ConfigBtn, MRModalSelect, ModalTextArea, ArrowUp,
    ArrowDown, HDivider, Circle
}
