import React from "react"
import BulkModalEmployee from './BulkQuestionAttachmentModal.js'
import UploadFile2 from './../Common/UploadFile2';
import APICall from "../../Common/APICall";
import AlertBox from '../Common/AlertBox.js';
import '../Personnel/EmployeeFiles.css'
import AttachmentFiles from "./AttachmentFiles.js";
import AssessmentDataContext from "./AssessmentDataContext";
import CSLLoader from '../Common/CSLLoader';

class QuestionAttachments extends React.Component {
    constructor (props) {
        super(props);
        this.myRef = React.createRef()
        this.uploadApi = new UploadFile2();
    }
    state = {
        alert_param: null,
        header_context_visible: false,
        isModalOpen: false,
        binFiles: [],
        newFiles: [],
        ready: false
    }

    static contextType = AssessmentDataContext;

    componentDidMount() {
        this.setComponentState()
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) this.setComponentState()
    }

    setComponentState = async () => {
        this.setState({ ready: true, binFiles: this.props.attachments });
    }



    getExtension = (file_name) => {
        const file_parts = file_name.split(/\.(?=[^\.]+$)/)
        let ext = "PDF";
        switch (file_parts[file_parts.length - 1].toLowerCase()) {
            case "jpeg":
            case "jpg":
                ext = "JPG"; break;
            case "png":
                ext = "PNG"; break;
            case "docx":
                ext = "DOC"; break;
            case "doc":
                ext = "DOC"; break;
            case "msg":
                ext = "MSG"; break;
            case "txt":
                ext = "TXT"; break;
            case "ppt":
                ext = "PPT"; break;
            case "pptx":
                ext = "PPT"; break;
            case "xls":
                ext = "XLS"; break;
            case "xlsx":
                ext = "XLSX"; break;
            default:
                ext = "PDF"; break;
        }
        return ext
    }

    openUploadModal = (e) => {
        e.preventDefault();
        this.setState({ isModalOpen: true })
    }
    handleModalClose = () => {
        this.setState({ isModalOpen: false })
    }
    filesTobeAdded = (files) => {
        const uniqueFiles = files.filter(file => !this.state.binFiles.some(existingFile => existingFile.uid === file.uid));
        this.setState((prevState) => ({
            newFiles: [...uniqueFiles]
        }));
    }

    uploadEmpAttachment = (e) => {
        e.preventDefault();
        this.myRef.current.calluploader();
    }


    callUploadApi = async () => {
        let files = JSON.parse(JSON.stringify(this.state.binFiles));
        let nFiles = files.filter(f => f["is_new"] == 1);
        const newData = nFiles.map(({ is_new, size, size_hr, ...rest }) => rest);
        await this.uploadApi.command(newData, this.returnTempFilesInfo, this.updateStatus);
    }

    returnTempFilesInfo = async (result) => {
        console.log('return files from axios ==>', result)
        let alert_param = null;
        let retFiles = await JSON.parse(result);
        console.log('ret files ==>', retFiles);
        let binFiles = this.state.binFiles;
        const sldata = JSON.parse(localStorage.getItem("site_layout"));
        let logged_in_user = sldata["contact_id"];

        const newArray = retFiles.map(function (item) {
            let name = item.inserted_file_name;
            const index = binFiles.findIndex(item => item.name === name);
            const { bin_file, ...newItem } = index !== -1 ? binFiles[index] : {};

            return {
                logged_in_user: logged_in_user,
                name: item.inserted_file_name,
                ref_id: item.ref_id,
                file_content: JSON.stringify(newItem)
            };
        });

        // Filter the objects where is_new is equal to 1 so that it upload new files
        const filteredData = newArray.filter(item => {
            const parsedContent = JSON.parse(item.file_content);
            return parsedContent.is_new === 1;
        });


        const payload = {
            command: "assessments_details", action: 'insert_assessment_files',
            files: filteredData,
            role_id: this.props.role_id,
            question_id: this.props.question_id,
            topic_id: this.props.topic_id,
            section_id: this.props.section_id,
            assignment_id: this.props.assignment_id
        }
        console.log('payload ==>', payload)
        const api = new APICall();
        let files_response = await api.commandWithoutCallback(payload);

        const filesArray = files_response.result;

        if (files_response.error_code == 0) {
            alert_param = { title: 'Success', message: "Files inserted sucessfully", ok_text: 'OK', confirm: false, alertHandler: this.successHandler }
        } else {
            alert_param = { title: 'Error', message: "Problem occured when inserting files", ok_text: 'OK', confirm: false, alertHandler: this.confirmHandler }
        }
        this.setState({ alert_param: alert_param, isModalOpen: false, binFiles: filesArray, ready: true });


    };

    successHandler = () => {
        this.setState({ alert_param: null }, () => {
            const { updateFiles } = this.context;
            updateFiles(this.state.binFiles, this.props.role_id, this.props.question_id, this.props.topic_id, this.props.section_id, this.props.assignment_id);
        });
    }

    confirmHandler = () => {
        this.setState({ alert_param: null });
    }

    updateStatus = (status) => {
        const { loaded, total } = status;
        let percentLoaded = Math.round((loaded / total) * 100);

    }

    mergeNewFiles = () => {
        this.setState(
            (prevState) => ({
                binFiles: [...prevState.binFiles, ...prevState.newFiles],
                newFiles: [],
                ready: false
            }),
            () => {
                this.callUploadApi();
            }
        );
    }

    handleDelete = async (ref_id, id) => {
        let alert_param = null;
        const payload = {
            command: "assessments_details",
            action: 'delete_assessment_question_files',
            role_id: this.props.role_id,
            question_id: this.props.question_id,
            topic_id: this.props.topic_id,
            section_id: this.props.section_id,
            assignment_id: this.props.assignment_id,
            ref_id,
            id
        }

        const api = new APICall();
        let files_response = await api.commandWithoutCallback(payload);

        const filesArray = files_response.result;

        if (files_response.error_code == 0) {
            alert_param = { title: 'Success', message: "Files deleted sucessfully", ok_text: 'OK', confirm: false, alertHandler: this.successHandler }
        } else {
            alert_param = { title: 'Error', message: "Problem occured when deleting files", ok_text: 'OK', confirm: false, alertHandler: this.confirmHandler }
        }
        this.setState({ alert_param: alert_param, isModalOpen: false, binFiles: filesArray, });
    }

    render() {
        if (!this.state.ready) {
            return (<div><CSLLoader /></div>);
        }
        let question_attachments = [];
        question_attachments = this.state.binFiles.filter(b => b['is_new'] == 1);

        let show_asterisk = false;
        let req_attach = this.props.req_attach;
        if (req_attach != "") {
            if (req_attach == 'mand_corrt_ans' || req_attach == 'mand_incorrt_ans' || req_attach == 'mand_both') {
                show_asterisk = true;
            }
        }
        return (
            <div className="employee-file-container">
                <div className="attachment-files-section">
                    <div className="attachment-files">
                        <AttachmentFiles files={this.state.binFiles} deleteFile={this.handleDelete} />
                    </div>
                    {show_asterisk && <span className="show-alert-asterisk">*</span>}<button className="attachment-question-button" onClick={(e) => this.openUploadModal(e)}>Add an attachment</button>
                </div>

                <div className="modal-wrapper">
                    {this.state.isModalOpen && (
                        <div className="modal-popup">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h2>Upload Attachment</h2>
                                    <button onClick={this.handleModalClose}>X</button>
                                </div>
                                <div className="modal-body">
                                    <BulkModalEmployee
                                        ref={this.myRef}
                                        filesTobeAdded={this.filesTobeAdded}
                                        previousFiles={question_attachments}
                                        employee_id={this.state.selected_employee_id}
                                        filesToBeRemoved={this.filesToBeRemoved}
                                    />
                                </div>
                                <div className="modal-footer">
                                    <button className="topic-attcahment-upload-button" onClick={(e) => this.uploadEmpAttachment(e)}>
                                        Add Files
                                    </button>
                                    {this.state.newFiles.length > 0 && (
                                        <button className="save-button" onClick={(e) => { this.mergeNewFiles() }}>Save</button>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <AlertBox alertParam={this.state.alert_param} />
            </div >
        )
    }
}

export default QuestionAttachments
