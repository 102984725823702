import React, { Component } from 'react'
import APICall from "../../Common/APICall";
import Store from '../../Common/Store.js';
import AssessmentDetails from "../AssessmentDetails/AssessmentDetails";
import CSLLoader from '../Common/CSLLoader';
import { withRouter } from 'react-router-dom';

class AssessmentHOC extends Component {
    constructor (props) {
        super(props);
    }


    state = {
        assessment_id: '0',
        assignment_id: '0',
        user_id: '0',
        over_all_acessor: '',
        runs_every: '',
        reAssessmentWindow: '',
        assessmentWarningWindow: '',
        ready: false,
    };


    componentDidMount() {
        this.initialiseState();
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
            this.initialiseState();
        }
    }
    initialiseState = async () => {
        let assessment_id = '0';
        let assignment_id = '0';
        let user_id = '0';
        let over_all_acessor = '';
        let runs_every = '';
        let assessment_obj = null;

        let user_role = Store.getStoreData('role');
        const postData = { command: "assessments_details", action: 'get_assessment_dashboard_data', user_role: user_role, assignment_id: this.props.assignmentId };
        const api = new APICall();
        let assessment_response = await api.commandWithoutCallback(postData);
        let assessment_data = assessment_response.result.assessment_details;

        console.log('inside AssessmentHOC ==>', assessment_data);
        let mod_obj = assessment_response.result.module_object.config_json;
        mod_obj = JSON.parse(mod_obj);


        const assest_ReAssessment_window_value = mod_obj.hasOwnProperty("reassest_window")
            ? mod_obj.reassest_window
            : 20;
        const assest_warning_window_value = mod_obj.hasOwnProperty("assest_warn_window")
            ? mod_obj.assest_warn_window
            : 30;
        if (assessment_data != null) {
            if (assessment_data.length > 0) {
                assessment_obj = assessment_data[0];
                assignment_id = this.props.assignmentId;
                assessment_id = assessment_obj["assessment_id"];
                user_id = assessment_obj["assign_to"];
                over_all_acessor = assessment_obj["assessment_assessed_by"];
                runs_every = assessment_obj["runs_every_month"];
            }
        }
        this.setState({
            assessment_id,
            assignment_id,
            user_id,
            over_all_acessor,
            runs_every,
            reAssessmentWindow: assest_ReAssessment_window_value,
            assessmentWarningWindow: assest_warning_window_value,
            ready: true
        })
    }

    render() {
        if (!this.state.ready) {
            return (<div><CSLLoader /></div>);
        }
        return (
            <div>
                {this.state.assessment_id != 0 && this.state.assignment_id != 0 ? (
                    <AssessmentDetails
                        assessment_id={this.state.assessment_id}
                        assignment_id={this.state.assignment_id}
                        user_id={this.state.user_id}
                        over_all_acessor={this.state.over_all_acessor}
                        runs_every={this.state.runs_every}
                        reAssessmentWindow={this.state.reAssessmentWindow}
                        assessmentWarningWindow={this.state.assessmentWarningWindow}
                        is_open_from_query_str={true}
                    />
                ) : (
                    <p>This assessment no longer exists</p>
                )}
            </div>
        );
    }
}
export default withRouter(AssessmentHOC);
