import React, { Component } from 'react';
import SorContext from "./SorContext";


class EmployeeAppointedRoles extends Component {
    static contextType = SorContext;

    renderAssignedRoles() {
        let data = this.props.data;
        const { report_param_apply } = this.context;
        let is_show_role_effective_date = report_param_apply.secondSection.is_show_role_effective_date;
        let is_include_roles_respo_desc = report_param_apply.secondSection.is_include_roles_respo_desc;
        let statusFilter = report_param_apply.firstSection.statusFilter.value;
        //console.log('data ==>', data);
        if (statusFilter != 'all') {
            if (statusFilter != '') {
                //Accepted
                if (statusFilter == 4) {
                    data = data.filter(item => {
                        return item.AssignedRoles.some(role => role.Is_Accepted === 1);
                    });
                }
                //Pending
                else if (statusFilter == 3) {
                    data = data.filter(item => {
                        return item.AssignedRoles.some(role => role.Is_Accepted === 0);
                    });
                }
            }
        }
        return data.map((roleData) => (
            <React.Fragment key={roleData.role_type_id}>
                <p
                    style={{
                        textAlign: 'left',
                        background: roleData.RoleTypeGradient_FirstColorCode,
                        color: '#ffffff',
                        padding: '20px',
                        marginBottom: 0,
                        fontSize: '16px',
                    }}
                >
                    {roleData.RoleFunctionName}
                </p>
                <table style={{ marginBottom: 0, textAlign: 'left', width: '100%' }}>
                    <tbody>
                        {roleData.AssignedRoles.map((role, index) => {
                            let description = this.removeUnnecessarySpaces(role.ClientRole_Description);
                            return <React.Fragment key={index}>
                                <tr>
                                    {index === 0 && (
                                        <td
                                            rowSpan={roleData.AssignedRoles.length}
                                            style={{
                                                background: `rgba(${parseInt(roleData.RoleTypeGradient_FirstColorCode.slice(1, 3), 16)}, ${parseInt(
                                                    roleData.RoleTypeGradient_FirstColorCode.slice(3, 5),
                                                    16
                                                )}, ${parseInt(roleData.RoleTypeGradient_FirstColorCode.slice(5, 7), 16)}, 0.3)`,
                                                width: '4%',
                                                borderRight: '1px solid #fff',
                                                borderTop: '1px solid #fff',
                                                verticalAlign: 'top',
                                            }}
                                        >
                                            &nbsp;
                                        </td>
                                    )}
                                    <td style={{ width: '25%', borderRight: '1px solid #fff', background: '#f9f9f9', borderTop: '1px solid #fff', verticalAlign: 'top' }}>
                                        <p>
                                            <b>{role.ClientRole_Code}</b>
                                        </p>
                                        {is_show_role_effective_date && <p> Effective to: {role.RoleAssigned_Date == "31/12/2050" ? 'No end date set' : role.RoleAssigned_Date}</p>}
                                        {role.Shared_DividedWith && (
                                            <React.Fragment>
                                                <SharedDividedWith
                                                    sharedDividedWithText={role.Shared_DividedWithText}
                                                    descriptionLength={description.length}
                                                    hasMargin={is_include_roles_respo_desc}
                                                />
                                            </React.Fragment>
                                        )}
                                    </td>
                                    <td style={{ background: '#f9f9f9', borderTop: '1px solid #fff', verticalAlign: 'top' }}>
                                        <p style={{ marginBottom: '15px' }}>
                                            <b>{role.ClientRole_Title}</b>
                                        </p>
                                        {is_include_roles_respo_desc && <p>{description}</p>}
                                        {role.Shared_DividedWith && (
                                            <React.Fragment>
                                                {/* <div style={{ marginTop: description.length === 0 ? '50px' : description.length === 10 ? '17px' : '0' }}
                                                    dangerouslySetInnerHTML={{ __html: role.Shared_DividedWith }} /> */}
                                                <div style={{
                                                    marginTop: description.length === 0
                                                        ? '50px'
                                                        : description.length === 10
                                                            ? '17px'
                                                            : is_include_roles_respo_desc
                                                                ? '1px'
                                                                : '0'
                                                }}
                                                    dangerouslySetInnerHTML={{ __html: role.Shared_DividedWith }} />
                                                <p>{role.Shared_DividedComment}</p>
                                            </React.Fragment>
                                        )}
                                    </td>
                                </tr>
                            </React.Fragment>
                        })}
                    </tbody>
                </table>
            </React.Fragment >
        ));
    }

    removeUnnecessarySpaces = (inputString) => {
        const cleanedString = inputString.replace(/\s+/g, ' ').trim();
        return cleanedString;
    }

    render() {
        return <div>{this.renderAssignedRoles()}</div>;
    }
}


const calculateMarginTop = (descriptionLength) => {
    descriptionLength = descriptionLength == '' ? 0 : descriptionLength;
    let a = ((225 * descriptionLength) / 794) + 10
    return a;
};

class SharedDividedWith extends Component {
    render() {
        const { sharedDividedWithText, descriptionLength, hasMargin } = this.props;

        const marginTop = hasMargin ? calculateMarginTop(descriptionLength) : 0;

        return (
            <div style={{ marginTop }}>
                <p>{sharedDividedWithText}</p>
            </div>
        );
    }
}




export default EmployeeAppointedRoles;
