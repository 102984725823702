import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import SorContext from "./SorContext";
import './sor.css'


class RoleTypeFilter extends Component {
    constructor (props) {
        super(props);
        this.state = {
            roleTypeList: [],
            selectedCount: 0
        };
    }

    static contextType = SorContext;

    componentDidMount() {
        this.setComponentState();
    }


    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setComponentState();
        }
    }

    selectedRoleTypes = (e, Id) => {
        e.preventDefault();
        const { roleTypeList } = this.state;
        const allObjectSelected = roleTypeList.every(item => item.selected === 1);

        const updatedData = roleTypeList.map(item => {
            if (Id === 'all') {
                if (allObjectSelected) {
                    return { ...item, selected: 0 };
                } else {
                    return { ...item, selected: 1 };
                }
            } else {
                if (item.id === Id) {
                    return { ...item, selected: item.selected === 0 ? 1 : 0 };
                }
                return item;
            }
        });

        this.setState({
            roleTypeList: updatedData,
            selectedCount: updatedData.filter(item => item.selected === 1).length
        });
    };



    setComponentState = () => {
        let e = [];
        const { role_types, selected_report_parameter } = this.context;
        let sfilter = selected_report_parameter.firstSection.roleTypeFilter;
        const extractedData = role_types.map(item => ({ id: item.id, base: item.base }));
        if (extractedData !== null && extractedData.length > 0) {
            if (sfilter.value == 'all') {
                e = extractedData.map(item => ({ id: item.id, base: item.base, selected: 1 }))
            } else {
                e = extractedData.map(item => ({
                    id: item.id,
                    base: item.base,
                    selected: sfilter.value.includes(item.id) ? 1 : 0
                }))
            }
        }
        this.setState({
            roleTypeList: e,
            selectedCount: e.filter(item => item.selected === 1).length
        });
    }

    closeRoleTypeFilter = () => {
        const { updateFilterParameter } = this.context;
        let roleTypeLists = this.state.roleTypeList;
        let obj = {
            type: 'roleTypeFilter',
            secondaryText: "",
            value: ""
        }
        if ((roleTypeLists.length == this.state.selectedCount)) {
            obj.secondaryText = 'All Role Types';
            obj.value = 'all'
        } else {
            if (this.state.selectedCount != 0) {
                const selectedObjects = roleTypeLists.filter(item => item.selected == 1);
                let role_base = "", ids = "";
                selectedObjects.forEach((e) => {
                    role_base += e.base + ','
                    ids += e.id + ','
                })
                role_base = role_base.slice(0, -1);
                ids = ids.slice(0, -1);

                obj.secondaryText = role_base;
                obj.value = ids
            } else {
                obj.secondaryText = 'All Role types';
                obj.value = ''
            }
        }

        updateFilterParameter(obj)

        this.props.closeFilter();
    }


    render() {
        console.log('this.state.roleTypeList ==>', this.state.roleTypeList);
        return (
            <div className='personnel-container'>
                <div class="search-container" style={{ display: 'none' }}>
                    <input type="text" class="search-input" placeholder="Search list" />
                    <span class="search-icon-border">
                        <FontAwesomeIcon title="Action" className='search-icon'
                            icon={solid('magnifying-glass')} />
                    </span>
                </div>
                <span class="select-smr-popover-selected" id="spanSelectCount">Show selected
                    ({this.state.selectedCount} selected)</span>
                <div className='personnel-employee-container'>
                    <div className='personnel-employees'>
                        <ul>
                            <li className='personnel-li' onClick={(e) => this.selectedRoleTypes(e, 'all')}>
                                {this.state.selectedCount == this.state.roleTypeList.length && <FontAwesomeIcon ktitle="Action" className='personnel-check-icon'
                                    icon={solid('check')} />} All Role Types</li>
                            {this.state.roleTypeList.map((item, index) => (
                                <React.Fragment>
                                    <li className='personnel-li' key={index} title={item.name} onClick={(e) => this.selectedRoleTypes(e, item.id)}>
                                        {item.selected == 1 && <FontAwesomeIcon ktitle="Action" className='personnel-check-icon'
                                            icon={solid('check')}>
                                        </FontAwesomeIcon>}{item.base}
                                    </li>
                                </React.Fragment>
                            ))}
                        </ul>
                    </div>
                    <button className='personnel-employee-done' onClick={this.closeRoleTypeFilter}>Done</button>
                </div>
            </div >
        )
    }
}

export default RoleTypeFilter
