import React from 'react';
import styled from 'styled-components';
import ClipLoader from "react-spinners/ClipLoader";

const Overlay = styled.div`
    background-color : transparent;
    box-sizing : border-box;
    position: relative;
    display : flex;
    flex-direction : row;
    justify-content : flex-start;
    align-items : center;
`
const SpinnerContainer = styled.div`
    background-color : transparent;
    margin-left : 10px;
`

class SpinningLoaderSmall extends React.Component {
    render(){
        return (
            <Overlay>
                <div>{this.props.text !== undefined ? this.props.text : ''}</div>
                <SpinnerContainer><ClipLoader color={"#6666ff"} loading={true} size={25}/></SpinnerContainer>
            </Overlay>
        )   
    }
}

export default SpinningLoaderSmall;