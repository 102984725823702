import React from 'react';
import Store from '../../Common/Store';
import Iframe from 'react-iframe'
import { jsPDF } from 'jspdf'
import APICall from "../../Common/APICall"
import { FieldStyleMapping, PdfFontMapping } from './Pdf_Font_n_Icons/FontMapping';
import './Pdf_Font_n_Icons/Montserrat-Bold-bold'
import './Pdf_Font_n_Icons/Montserrat-BoldItalic-bolditalic'
import './Pdf_Font_n_Icons/Montserrat-Italic-italic'
import './Pdf_Font_n_Icons/Montserrat-Medium-normal'
import './Pdf_Font_n_Icons/Montserrat-MediumItalic-italic'
import './Pdf_Font_n_Icons/Montserrat-Regular-normal'
import './Pdf_Font_n_Icons/Montserrat-SemiBold-bold'
import './Pdf_Font_n_Icons/Montserrat-ExtraBold-bold'
import './Pdf_Font_n_Icons/Montserrat-ExtraBoldItalic-bolditalic'
import './Pdf_Font_n_Icons/Montserrat-SemiBoldItalic-bold'
import './Pdf_Font_n_Icons/NotoSans-Bold-bold'
import './Pdf_Font_n_Icons/NotoSans-BoldItalic-bolditalic'
import './Pdf_Font_n_Icons/NotoSans-Italic-italic'
import './Pdf_Font_n_Icons/NotoSans-Medium-normal'
import './Pdf_Font_n_Icons/NotoSans-MediumItalic-italic'
import './Pdf_Font_n_Icons/NotoSans-Regular-normal'
import './Pdf_Font_n_Icons/NotoSans-SemiBold-bold'
import './Pdf_Font_n_Icons/NotoSans-SemiBoldItalic-bolditalic'
import './Pdf_Font_n_Icons/NotoSans-ExtraBold-bold'
import './Pdf_Font_n_Icons/NotoSans-ExtraBoldItalic-bolditalic'
import './Pdf_Font_n_Icons/AlexBrush-Regular-normal'
import './Pdf_Font_n_Icons/Borel-Regular-normal'
import './Pdf_Font_n_Icons/OpenSans-Regular-normal'
import './Pdf_Font_n_Icons/OpenSans-Bold-bold'
import './Pdf_Font_n_Icons/OpenSans-Italic-italic'
import './Pdf_Font_n_Icons/OpenSans-BoldItalic-bolditalic'
import './Pdf_Font_n_Icons/OpenSans-Medium-normal'
import './Pdf_Font_n_Icons/OpenSans-MediumItalic-italic'
import './Pdf_Font_n_Icons/OpenSans-SemiBold-normal'
import './Pdf_Font_n_Icons/OpenSans-SemiBoldItalic-italic'
import './Pdf_Font_n_Icons/OpenSans-ExtraBold-bold'
import './Pdf_Font_n_Icons/OpenSans-ExtraBoldItalic-bolditalic'
import './Pdf_Font_n_Icons/WorkSans-Regular-normal'
import './Pdf_Font_n_Icons/WorkSans-Bold-bold'
import './Pdf_Font_n_Icons/WorkSans-Italic-italic'
import './Pdf_Font_n_Icons/WorkSans-BoldItalic-bolditalic'
import './Pdf_Font_n_Icons/WorkSans-Medium-normal'
import './Pdf_Font_n_Icons/WorkSans-MediumItalic-italic'
import './Pdf_Font_n_Icons/WorkSans-SemiBold-normal'
import './Pdf_Font_n_Icons/WorkSans-SemiBoldItalic-italic'
import './Pdf_Font_n_Icons/WorkSans-ExtraBold-bold'
import './Pdf_Font_n_Icons/WorkSans-ExtraBoldItalic-bolditalic'
import './Pdf_Font_n_Icons/Roboto-Regular-normal'
import './Pdf_Font_n_Icons/Roboto-Bold-bold'
import './Pdf_Font_n_Icons/Roboto-Italic-italic'
import './Pdf_Font_n_Icons/Roboto-BoldItalic-bolditalic'
import './Pdf_Font_n_Icons/Roboto-Medium-normal'
import './Pdf_Font_n_Icons/Roboto-MediumItalic-italic'


import logo_base_64 from './Pdf_Font_n_Icons/MccLogoNew';
import moment from 'moment';

import demodata from './Demodata'

const marginX = 12;
const marginY = 12;
const maxX = 210 - (2 * marginX);
const maxY = 297 - (2 * marginY);
const centerX = 105;
const footer_height = 15;
const para_gap = 9;
const line_gap = 5;
const gap_constant = 0


class DemoPdfwithjsPDF extends React.Component{

    state = {
        smcrPDF : null, 
        pdfTemp : null, 
        pdfURL: null, 
        pageWidth: maxX, 
        pageHeight: maxY, 
        ready: true, 
        report_data : {},
        field_styles : {},
        uploaded_logo : null,
        image_format : "",
        image_x : 0,
        image_width_for_print : 0,
        image_height_for_print : 0
    };

    constructor(props) {
        super(props);
    }

    async componentDidMount(){
        const api = new APICall();
        let contacts = Store.getStoreData('listcontacts');
        console.log("CONTACST : ",contacts);
        //let assessment_id = this.props.assessment_id;
        //let assignment_id = this.props.assignment_id;
        let assessment_id = 4;
        let assignment_id = 18;
        let postData = { "command": "get_assess_cert_data", "assessment_id" : assessment_id, "assignment_id" : assignment_id };
        const api_result = await api.commandWithoutCallback(postData);
        console.log("API REs : ",api_result);
        let report_data = {}
        report_data.assessee_name= contacts.find(item => item.ID === api_result.result.assessee_id.toString()).ContactName;
        report_data.assessor_name = contacts.find(item => item.ID === api_result.result.assessor_id).ContactName;
        report_data.assessment_name = api_result.result.assessment_name;
        report_data.job_title = api_result.result.job_title;
        report_data.certification_date = moment.unix(api_result.result.certification_date).format("DD/MM/YYYY");
        report_data.records = api_result.result.records;
        console.log("REport Data : ", report_data);
        postData = {command: "get_assess_cert_setup"}
		let result = await api.commandWithoutCallback(postData)
        console.log("api res : ", result);
        let field_styles = null;
        if(Object.keys(result.result.field_styles).length > 1) field_styles = result.result.field_styles;
        else field_styles = FieldStyleMapping;
        let uploaded_logo = field_styles.logo.value === 'default' && this.state.uploaded_logo === null ? logo_base_64 : this.state.uploaded_logo !== null ? uploaded_logo : field_styles.logo.value;
        let image_format = ''
        if(field_styles.logo.value === 'default'){
            image_format = 'png'
        }else{
            const index = uploaded_logo.indexOf(';');
            if (index !== -1) {
                const extractedPart = uploaded_logo.slice(0, index);
                let index_of_slash = extractedPart.indexOf('/');
                if(index_of_slash !== -1){
                    image_format = extractedPart.slice(index_of_slash+1, extractedPart.length)
                }
                console.log(image_format);
            }
        }
        let actual_img_height = 0;
        let actual_img_width = 0;
        let image_x = marginX;
        let image_width_for_print = 20;
        this.loadImage(field_styles.logo.value === 'default' ? logo_base_64 : field_styles.logo.value)
            .then((img) => {
                console.log('height : ', img.height, ' width : ', img.width);
                actual_img_height = img.height;
                actual_img_width = img.width;
                let aspect_ratio = actual_img_width/actual_img_height;
                console.log('aspect ratio : ', aspect_ratio );
                let image_height = aspect_ratio <=1 ? 30 : aspect_ratio > 1 && aspect_ratio <=2 ? 25 : 20;
                image_width_for_print = image_height * aspect_ratio;
                if(field_styles.logo.format === 'middle') image_x = centerX - (image_width_for_print/2);
                else if(field_styles.logo.format === 'right') image_x = (marginX + maxX) - image_width_for_print;
                this.setState({image_x, image_width_for_print, image_height_for_print : image_height});
                this.generatePdf(image_x, image_width_for_print, image_height);
            })
        this.setState({report_data, field_styles, uploaded_logo, image_format}); 
    }

    loadImage = (url) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = reject;
            img.src = url;
        });
    }


    headerSection = async (image_x, image_width_for_print, image_height_for_print) =>{
        
        this.currentPageNumber = this.smcrPDF.getNumberOfPages();
        console.log("this.currentPageNumber",this.currentPageNumber);
        if(this.currentPageNumber === 1){

            this.current_y += 5;            

            var img = new Image()
            img.src = this.state.field_styles.logo.value === 'default' ? logo_base_64 : this.state.field_styles.logo.value;
            console.log('imageX : ', image_x, image_width_for_print, image_height_for_print );
            this.smcrPDF.addImage(img, this.state.image_format, image_x, marginY+5, image_width_for_print, image_height_for_print);
            this.current_y += image_height_for_print * 1.2 + this.state.field_styles.certificate.font_size * 0.4;
        }else{
            this.current_y += 20
        } 

        const field_certificate = this.state.field_styles.certificate; 
        this.smcrPDF.setFontSize(field_certificate.font_size);
        this.smcrPDF.setTextColor(field_certificate.font_color);
        console.log("Cert font : ",PdfFontMapping[field_certificate.font_id].actual_font, PdfFontMapping[field_certificate.font_id].actual_font_postfix);
        this.smcrPDF.setFont(PdfFontMapping[field_certificate.font_id].actual_font,PdfFontMapping[field_certificate.font_id].actual_font_postfix);
        let splitCertText = this.smcrPDF.splitTextToSize(field_certificate.value, maxX-20);
        //let splitCertText = this.smcrPDF.splitTextToSize("Administrator of Kolkata Branch and CEO of Chicago Enclave. Ascendeon Inc. Chicago, USA, Administrator of Kolkata Branch and CEO of Chicago Enclave. Ascendeon Inc. Chicago, USA", maxX-20);
        //this.smcrPDF.text(field_certificate.value, centerX, this.current_y, {align : "center"});
        this.smcrPDF.text(splitCertText, centerX, this.current_y, {align : "center"});
        if(splitCertText.length > 1){
            let extra_gap12 = field_certificate.font_size*0.42;
            this.current_y += (splitCertText.length-1)*extra_gap12;
        }

        const field_assessment_name = JSON.parse(JSON.stringify(this.state.field_styles.assessment_name)); 
        let extra_gap1 = field_assessment_name.font_size*0.3;
        this.current_y += 5 + extra_gap1; 
        
        this.smcrPDF.setFontSize(field_assessment_name.font_size);
        this.smcrPDF.setTextColor(field_assessment_name.font_color);
        this.smcrPDF.setFont(PdfFontMapping[field_assessment_name.font_id].actual_font,PdfFontMapping[field_assessment_name.font_id].actual_font_postfix);
        let splitAssName = this.smcrPDF.splitTextToSize(this.state.report_data.assessment_name, maxX-20);
        //let splitAssName = this.smcrPDF.splitTextToSize("Administrator of Kolkata Branch and CEO of Chicago Enclave. Ascendeon Inc. Chicago, USA, Administrator of Kolkata Branch and CEO of Chicago Enclave. Ascendeon Inc. Chicago, USA", maxX-20);
        //this.smcrPDF.text(this.state.report_data.assessment_name, centerX, this.current_y, {align : "center"});
        this.smcrPDF.text(splitAssName, centerX, this.current_y, {align : "center"});
        if(splitAssName.length > 1){
            let extra_gap11 = field_assessment_name.font_size*0.42;
            this.current_y += (splitAssName.length-1)*extra_gap11;
        }
        
        const field_emp_name = this.state.field_styles.emp_name;
        let extra_gap2 = field_emp_name.font_size*0.3;
        this.current_y += 15 + extra_gap2;

        this.smcrPDF.setFontSize(field_emp_name.font_size);
        this.smcrPDF.setTextColor(field_emp_name.font_color); 
        this.smcrPDF.setFont(PdfFontMapping[field_emp_name.font_id].actual_font,PdfFontMapping[field_emp_name.font_id].actual_font_postfix);
        this.smcrPDF.text(this.state.report_data.assessee_name, centerX, this.current_y, {align : "center"});

        const field_job_title = this.state.field_styles.job_title;
        let extra_gap3 = field_job_title.font_size*0.3;
        this.current_y += 4 + extra_gap3;

        this.smcrPDF.setFontSize(field_job_title.font_size);
        this.smcrPDF.setTextColor(field_job_title.font_color);
        this.smcrPDF.setFont(PdfFontMapping[field_job_title.font_id].actual_font,PdfFontMapping[field_job_title.font_id].actual_font_postfix);
        let splitJobTitle = this.smcrPDF.splitTextToSize(this.state.report_data.job_title, maxX-40);
        //let splitJobTitle = this.smcrPDF.splitTextToSize("Administrator of Kolkata Branch and CEO of Chicago Enclave. Ascendeon Inc. Chicago, USA, Administrator of Kolkata Branch and CEO of Chicago Enclave. Ascendeon Inc. Chicago, USA", maxX-40);
        // this.smcrPDF.text(splitText, centerX, this.current_y, {align : "center"});
        this.smcrPDF.text(splitJobTitle, centerX, this.current_y, {align : "center"});
        if(splitJobTitle.length > 1){
            let extra_gap4 = field_job_title.font_size*0.42;
            this.current_y += (splitJobTitle.length-1)*extra_gap4;
        }

        this.current_y += 2 //do not change

        this.smcrPDF.setDrawColor('#aaa');
        this.smcrPDF.line(marginX + 20, this.current_y, marginX + maxX - 20, this.current_y, 'S');

        let extra_gap5 = this.state.field_styles.dec_text.font_size;
        this.current_y += extra_gap5*0.65 //do not change

        if(this.currentPageNumber <=2){
            const field_dec_text = this.state.field_styles.dec_text;
            this.smcrPDF.setFontSize(field_dec_text.font_size);
            this.smcrPDF.setFont(PdfFontMapping[field_dec_text.font_id].actual_font,PdfFontMapping[field_dec_text.font_id].actual_font_postfix);
            this.smcrPDF.setTextColor(field_dec_text.font_color);
            let splitText = this.smcrPDF.splitTextToSize(field_dec_text.value, maxX-60);
            this.smcrPDF.text(splitText, centerX, this.current_y, {align : "center"});
            if(splitText.length > 1){
                let extra_gap6 = field_dec_text.font_size*0.42;
                this.current_y += (splitText.length-1)*extra_gap6;
            }

            this.current_y += 9; //do not change
        }
    }

    printRows = async () =>{
        this.y_covered = 39;
        let field_role_id = this.state.field_styles.role_id;
        let field_role_title = this.state.field_styles.role_title;
        let records = this.state.report_data.records;
        if(field_role_id.show === true && field_role_title.show === true){
            this.smcrPDF.setFontSize(field_role_id.font_size);
            this.smcrPDF.setFont(PdfFontMapping[field_role_id.font_id].actual_font,PdfFontMapping[field_role_id.font_id].actual_font_postfix);
            let max_role_id_length = 0;
            for(let i=0; i<records.length; i++){
                let item = records[i];
                console.log("role id length : ", item.role_code, this.smcrPDF.getTextWidth(item.role_code));
                if (max_role_id_length <this.smcrPDF.getTextWidth(item.role_code)) max_role_id_length = this.smcrPDF.getTextWidth(item.role_code);
            }
            for(let i=0; i<records.length; i++){
                let item = records[i];
                //let row_height = 12;
                let row_height = (field_role_id.font_size > field_role_title.font_size ? field_role_id.font_size : field_role_title.font_size) * 0.8;
                this.smcrPDF.setFontSize(field_role_title.font_size);
                this.smcrPDF.setFont(PdfFontMapping[field_role_title.font_id].actual_font,PdfFontMapping[field_role_title.font_id].actual_font_postfix);
                let tempSplitRoleTitle = this.smcrPDF.splitTextToSize(item.role_title, (210 - (2*marginX + max_role_id_length + (20 - (((14 - field_role_title.font_size)**2)*0.005 + ((14 - field_role_id.font_size)**2)*0.005)))));
                row_height += (tempSplitRoleTitle.length-1)*(field_role_title.font_size*0.42);
                if(i === records.length - 1 && this.current_y + row_height > (marginY + maxY - 36) || this.current_y + row_height > (marginY + maxY - 16)){
                    //await this.drawVerticalLines();
                    //await this.printPageNo();
                    this.smcrPDF.addPage('a4','portrait');
                    this.current_y = marginY;
                    this.current_x = marginX;
                    await this.headerSection();
                }

                // this.current_y += 12;
                this.current_y += (field_role_id.font_size > field_role_title.font_size ? field_role_id.font_size : field_role_title.font_size) * 0.8;

                this.smcrPDF.setFontSize(field_role_id.font_size);
                this.smcrPDF.setFont(PdfFontMapping[field_role_id.font_id].actual_font,PdfFontMapping[field_role_id.font_id].actual_font_postfix);
                this.smcrPDF.setTextColor(field_role_id.font_color === 'default' ? item.role_color : field_role_id.font_color);
                console.log("role id length : ", item.role_code, this.smcrPDF.getTextWidth(item.role_code));
                this.smcrPDF.text(item.role_code, marginX+10, this.current_y, {align : "left"});
                this.smcrPDF.setFontSize(field_role_title.font_size);
                this.smcrPDF.setTextColor(field_role_title.font_color);
                this.smcrPDF.setFont(PdfFontMapping[field_role_title.font_id].actual_font,PdfFontMapping[field_role_title.font_id].actual_font_postfix);
                //this.smcrPDF.text(item.role_title, marginX + 60, this.current_y, {align : "left"});

                //this.current_y += 12;
                //let splitRoleTitle = this.smcrPDF.splitTextToSize(item.role_title, (100 - field_role_id.font_size*0.6 + (((14 - field_role_title.font_size)**2)*0.005 + ((14 - field_role_id.font_size)**2)*0.005)));
                let splitRoleTitle = this.smcrPDF.splitTextToSize(item.role_title, (210 - (2*marginX + max_role_id_length + (20 - (((14 - field_role_title.font_size)**2)*0.005 + ((14 - field_role_id.font_size)**2)*0.005)))));
                //let splitRoleTitle = this.smcrPDF.splitTextToSize("Administrator of Kolkata Branch and CEO of Chicago Enclave. Ascendeon Inc. Chicago, USA", 100);
                // this.smcrPDF.text(splitText, centerX, this.current_y, {align : "center"});
                //this.smcrPDF.text(splitRoleTitle, marginX + field_role_id.font_size*1.6 + (30 - (((14 - field_role_title.font_size)**2)*0.005 + ((14 - field_role_id.font_size)**2)*0.005)), this.current_y, {align : "left"});
                this.smcrPDF.text(splitRoleTitle, marginX + max_role_id_length + (20 - (((14 - field_role_title.font_size)**2)*0.005 + ((14 - field_role_id.font_size)**2)*0.005)), this.current_y, {align : "left"});
                if(splitRoleTitle.length > 1){
                    this.current_y += (splitRoleTitle.length-1)*(field_role_title.font_size*0.42);
                }
            }
        }else if(field_role_id.show === false && field_role_title.show === true){
            let only_role_titles = [];
            for(let i=0; i<records.length; i++){
                only_role_titles.push(records[i].role_title);
                only_role_titles = only_role_titles.sort((a,b)=>{
                    if (a.toLowerCase() < b.toLowerCase() ) {
                        return -1;
                      } else if (a.toLowerCase() > b.toLowerCase() ) {
                        return 1;
                      }
                      return 0;
                });
            }
            for(let i=0; i<only_role_titles.length; i++){
                let item = only_role_titles[i];
                //let row_height = 12;
                let row_height = parseInt(field_role_title.font_size)*0.8;
                this.smcrPDF.setFontSize(field_role_title.font_size);
                this.smcrPDF.setFont(PdfFontMapping[field_role_title.font_id].actual_font,PdfFontMapping[field_role_title.font_id].actual_font_postfix);
                let tempSplitRoleTitle = this.smcrPDF.splitTextToSize(item, 180 - field_role_title.font_size*0.1);
                row_height += (tempSplitRoleTitle.length-1)*(field_role_title.font_size*0.42);
                if(i === records.length - 1 && this.current_y + row_height > (marginY + maxY - 36) || this.current_y + row_height > (marginY + maxY - 16)){
                    //await this.drawVerticalLines();
                    //await this.printPageNo();
                    this.smcrPDF.addPage('a4','portrait');
                    this.current_y = marginY;
                    this.current_x = marginX;
                    await this.headerSection();
                }

                //this.current_y += 12;
                this.current_y += parseInt(field_role_title.font_size)*0.8;

                // this.smcrPDF.setFontSize(field_role_id.font_size);
                // this.smcrPDF.setFont(PdfFontMapping[field_role_id.font_id].actual_font,PdfFontMapping[field_role_id.font_id].actual_font_postfix);
                // this.smcrPDF.setTextColor(field_role_id.font_color === 'default' ? item.role_color : field_role_id.font_color);
                // this.smcrPDF.text(item.role_code, marginX + 20, this.current_y, {align : "left"});
                this.smcrPDF.setFontSize(field_role_title.font_size);
                this.smcrPDF.setTextColor(field_role_title.font_color);
                this.smcrPDF.setFont(PdfFontMapping[field_role_title.font_id].actual_font,PdfFontMapping[field_role_title.font_id].actual_font_postfix);
                //this.smcrPDF.text(item.role_title, marginX + 60, this.current_y, {align : "left"});

                //this.current_y += 12;
                let splitRoleTitle = this.smcrPDF.splitTextToSize(item, 140);
                //let splitRoleTitle = this.smcrPDF.splitTextToSize("Administrator of Kolkata Branch and CEO of Chicago Enclave. Ascendeon Inc. Chicago, USA", 100);
                // this.smcrPDF.text(splitText, centerX, this.current_y, {align : "center"});
                this.smcrPDF.text(splitRoleTitle, marginX + 20, this.current_y, {align : "left"});
                if(splitRoleTitle.length > 1){
                    this.current_y += (splitRoleTitle.length-1)*(field_role_title.font_size*0.42);
                }
            }
        }else if(field_role_id.show === true && field_role_title.show === false){
            for(let i=0; i<records.length; i+=3){
                let row_height = parseInt(field_role_id.font_size)*0.8;
                // this.smcrPDF.setFontSize(field_role_id.font_size);
                // this.smcrPDF.setFont(PdfFontMapping[field_role_title.font_id].actual_font,PdfFontMapping[field_role_title.font_id].actual_font_postfix);
                // let tempSplitRoleTitle = this.smcrPDF.splitTextToSize(item.role_title, 140);
                // row_height += (tempSplitRoleTitle.length-1)*6.2;
                if(i === records.length - 1 && this.current_y + row_height > (marginY + maxY - 36) || this.current_y + row_height > (marginY + maxY - 16)){
                    //await this.drawVerticalLines();
                    //await this.printPageNo();
                    this.smcrPDF.addPage('a4','portrait');
                    this.current_y = marginY;
                    this.current_x = marginX;
                    await this.headerSection();
                }

                this.current_y += parseInt(field_role_id.font_size)*0.8;

                for(let j=0; j<=2; j++){
                    let item = records[i+j];
                    if(item === undefined) break;
                    console.log("ITEM ", item);
                    this.smcrPDF.setFontSize(field_role_id.font_size);
                    this.smcrPDF.setTextColor(field_role_id.font_color === 'default' ? item.role_color : field_role_id.font_color);
                    this.smcrPDF.setFont(PdfFontMapping[field_role_id.font_id].actual_font,PdfFontMapping[field_role_id.font_id].actual_font_postfix);
                    if(j === 0)
                        this.smcrPDF.text(item.role_code, marginX + 40 - field_role_id.font_size*0.9, this.current_y, {align : "left"});
                    else if(j === 1)
                        this.smcrPDF.text(item.role_code, centerX - 5 - field_role_id.font_size*0.6, this.current_y, {align : "left"});
                    else if(j === 2)
                        this.smcrPDF.text(item.role_code, marginX + maxX - 50 - field_role_id.font_size*0.3, this.current_y, {align : "left"}); 
                }
                // this.smcrPDF.setFontSize(field_role_title.font_size);
                // this.smcrPDF.setTextColor(field_role_title.font_color);
                // this.smcrPDF.setFont(PdfFontMapping[field_role_title.font_id].actual_font,PdfFontMapping[field_role_title.font_id].actual_font_postfix);
                //this.smcrPDF.text(item.role_title, marginX + 60, this.current_y, {align : "left"});

                //this.current_y += 12;
                //let splitRoleTitle = this.smcrPDF.splitTextToSize(item.role_title, 140);
                //let splitRoleTitle = this.smcrPDF.splitTextToSize("Administrator of Kolkata Branch and CEO of Chicago Enclave. Ascendeon Inc. Chicago, USA", 100);
                // this.smcrPDF.text(splitText, centerX, this.current_y, {align : "center"});
                // this.smcrPDF.text(splitRoleTitle, marginX + 20, this.current_y, {align : "left"});
                // if(splitRoleTitle.length > 1){
                //     this.current_y += (splitRoleTitle.length-1)*6.2;
                // }
            }
        }else if(field_role_id.show === false && field_role_title.show === false || records.length === 0){
            const field_no_role = this.state.field_styles.no_role;
            this.smcrPDF.setFontSize(field_no_role.font_size);
            this.smcrPDF.setFont(PdfFontMapping[field_no_role.font_id].actual_font,PdfFontMapping[field_no_role.font_id].actual_font_postfix);
            this.smcrPDF.setTextColor(field_no_role.font_color);
            let splitText = this.smcrPDF.splitTextToSize(field_no_role.value, maxX-60);
            this.smcrPDF.text(splitText, centerX, this.current_y, {align : "center"});
        }
    }

    printSignAndDate = async () =>{
        let field_assessor_name = this.state.field_styles.assessor_name;
        this.smcrPDF.setFontSize(field_assessor_name.font_size)
        this.smcrPDF.setFont(PdfFontMapping[field_assessor_name.font_id].actual_font,PdfFontMapping[field_assessor_name.font_id].actual_font_postfix);
        this.smcrPDF.setTextColor(field_assessor_name.font_color);
        this.smcrPDF.text(this.state.report_data.assessor_name, marginX + 20, maxY - 10, {align : "left"});

        let sign_width = this.smcrPDF.getTextWidth(this.state.report_data.assessor_name);
        console.log(sign_width);

        this.smcrPDF.setDrawColor('#aaa');
        this.smcrPDF.line(marginX + 15, maxY - 6, marginX + 15 + (sign_width * 1.2) + 5, maxY - 6, 'S');

        let field_assessment_date = this.state.field_styles.assessment_date;
        this.smcrPDF.setFontSize(field_assessment_date.font_size)
        this.smcrPDF.setFont(PdfFontMapping[field_assessment_date.font_id].actual_font,PdfFontMapping[field_assessment_date.font_id].actual_font_postfix);
        this.smcrPDF.setTextColor(field_assessment_date.font_color);
        this.smcrPDF.text(moment(this.state.report_data.certification_date, 'DD/MM/YYYY').format(field_assessment_date.format), marginX + maxX - 20, maxY - 10, {align : "right"});

        let date_width = this.smcrPDF.getTextWidth(moment(this.state.report_data.certification_date, 'DD/MM/YYYY').format(field_assessment_date.format));
        this.smcrPDF.setDrawColor('#aaa');
        
        this.smcrPDF.line(marginX + maxX - 15, maxY - 6, marginX + maxX - 15 - (date_width * 1.2) - 5, maxY - 6, 'S');

        let field_cert_by = this.state.field_styles.cert_by;
        this.smcrPDF.setFont(PdfFontMapping[field_cert_by.font_id].actual_font,PdfFontMapping[field_cert_by.font_id].actual_font_postfix);
        this.smcrPDF.setTextColor(field_cert_by.font_color);
        this.smcrPDF.text(field_cert_by.value, marginX + 20 + (sign_width * 1.1)/2, maxY, {align : "center"});

        let field_date = this.state.field_styles.date;
        this.smcrPDF.setFont(PdfFontMapping[field_date.font_id].actual_font,PdfFontMapping[field_date.font_id].actual_font_postfix);
        this.smcrPDF.setTextColor(field_date.font_color);
        this.smcrPDF.text(field_date.value, marginX + maxX - 20 - (date_width * 1.1)/2, maxY, {align : "center"});
    }

    // calculateRowHeight = async (row) =>{
    //     let row_height = 5;
    //     let extra_y_for_identifier = 0;
    //     let extra_y_for_security = 0
    //     let identifier_ar = row.identifier.split(' ');
    //     if(identifier_ar.length > 1){
    //         for(let i=0; i<identifier_ar.length; i++) extra_y_for_identifier+= i*6;
    //     }
    //     let sec_name_arr = row.security.split(' ');
    //     let sec_name_str_arr=[];
    //     if(sec_name_arr.length <= 1) sec_name_str_arr.push(row.security);
    //     else{
    //         let j=0;
    //         for(let i=0; i<sec_name_arr.length; i++){
    //             if(i===0) sec_name_str_arr.push(sec_name_arr[i]);
    //             else{
    //                 let temp_str = `${sec_name_str_arr[j]} ${sec_name_arr[i]}`
    //                 console.log(this.smcrPDF.getStringUnitWidth(temp_str)*3.54, this.sec_info_col_x - 5 - this.sec_name_col_x)
    //                 if(this.smcrPDF.getStringUnitWidth(temp_str)*3.54 < this.sec_info_col_x - 5 - this.sec_name_col_x) sec_name_str_arr[j] = temp_str;
    //                 else{
    //                     sec_name_str_arr.push(sec_name_arr[i]);
    //                     j++;  
    //                     extra_y_for_security += 6;
    //                 }
    //             }
                
    //         }
    //     }
    //     row_height += (extra_y_for_identifier>extra_y_for_security ? extra_y_for_identifier :extra_y_for_security) + 12;
    //     return row_height;
    // }

    // calculatePageNumber = async (data) => {
    //     let single_page_y = 0;
    //     let total_pages = 1;
    //     single_page_y = 39 + marginY;
    //     for(let i=0;i<data.length;i++){
    //         let item=data[i];
    //         let row_height = await this.calculateRowHeight(item);
    //         if((row_height + single_page_y) > (2*marginY + maxY - 10)) {
    //             total_pages++;
    //             single_page_y = 39 + marginY;
    //         }
    //         else single_page_y += row_height;
    //     }
    //     console.log("Total Pages : ", total_pages);
    //     return total_pages;
    // }

    printPageNo = async () =>{
        this.smcrPDF.setFontSize(10)
        this.smcrPDF.setFont('Montserrat-Medium','normal');
        for(let i=1;i<=this.currentPageNumber;i++){
            this.smcrPDF.setPage(i);
            this.smcrPDF.text(`Page ${i} of ${this.currentPageNumber}`, centerX, marginY + maxY - 5, {align : "center"});
        }
    }

    drawVerticalLines = async () =>{
        this.smcrPDF.setDrawColor('#ccc');
        this.smcrPDF.line(marginX, this.table_top, marginX, this.current_y, 'S');
        this.smcrPDF.line(marginX + maxX, this.table_top, marginX + maxX, this.current_y, 'S');
    }

    generatePdf = async (image_x, image_width_for_print, image_height_for_print) => {
        this.current_y = marginY;
        this.current_x = marginX;
        this.currentPageNumber = 1;
        this.smcrPDF = new jsPDF({
                                    orientation: 'p',
                                    unit: 'mm',
                                    format: 'a4',
                                    putOnlyUsedFonts: true
                                });
        //this.smcrPDF.addFont('Montserrat-Regular-normal.ttf', 'Montserrat-Reg', 'regular', 300);
        // this.smcrPDF.addFileToVFS('Montserrat-Regular.ttf', Montserrat);
        // this.smcrPDF.addFont('Montserrat-Regular.ttf', 'Montserrat-Regular', 'regular',300);
        this.smcrPDF.setFont('Montserrat-Bold','bold');
        //this.totalPages = await this.calculatePageNumber(demodata);
        await this.headerSection(image_x, image_width_for_print, image_height_for_print);
        await this.printRows();
        await this.printSignAndDate();
        //await this.drawVerticalLines();
        await this.printPageNo();
        var blob = this.smcrPDF.output('blob')
        var binaryData = [];
        binaryData.push(blob);
        var pdfurl = window.URL.createObjectURL(new Blob(binaryData, {type: "application/pdf"}));
        this.setState({pdfURL:pdfurl, smcrPDF : this.smcrPDF, ready: true});
        //this.smcrPDF.save("PA Dealings Transaction Confirmation Report.pdf");
    }

    render(){
        if(this.smcrPDF === null) {
            return (<div>Loading...</div>);
        } else {
            if (this.state.ready) {
                return (
                    <div>
                        <div style={{paddingRight: "10px"}}>
                        {/* <Style.Categories><span style={{cursor: "pointer", color: "#337ab7"}} onClick={this.closePdf}><FaTimes /></span></Style.Categories> */}
                            <div style={{clear: "both"}}></div>
                        </div>
                        <Iframe url={this.state.pdfURL} width="100%" height="900px" type="application/pdf" />
                    </div>
                );
            } else {
                return (<div>Loading...</div>);
            }
        }
        // return(
        // <div style={{display : "inline-block"}}>
        //     <a href='#' onClick={this.generatePdf}>Download Confirmation Report</a>
        // </div>)
    }

}

export default DemoPdfwithjsPDF