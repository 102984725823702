import React from "react";
import "./ModalStyle.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import APICall from "../../../Common/APICall";
import AlertBox from "../../Common/AlertBox";

class AddRoleTypeModal extends React.Component {

    state = {role_type_obj: {name: "", base: "", help_text: "", color: "", requires_acceptance: true}, alert_param: null}

    handleChange = (event) => {
		event.preventDefault();
		let role_type_obj = JSON.parse(JSON.stringify(this.state.role_type_obj))
		role_type_obj[event.target.name] = event.target.value
		this.setState({role_type_obj})
	}

    toggleAcceptance = (event) => {
        event.preventDefault()
        let role_type_obj = JSON.parse(JSON.stringify(this.state.role_type_obj))
        role_type_obj.requires_acceptance = role_type_obj.requires_acceptance === true ? false : true
        this.setState({role_type_obj})
    }

    createRoleType = async (event) => {
		event.preventDefault()
		if (this.state.role_type_obj.name === "") {
			alert("Role Type must not be empty")
			return false
		}
		if (this.state.role_type_obj.base === "") {
			alert("Role Reference must not be empty")
			return false
		}
		if (this.state.role_type_obj.color === "") {
			alert("Regime color must not be empty")
			return false
		}
		if (this.state.role_type_obj.help_text === "") {
			alert("Help Text must not be empty")
			return false
		}
		const api = new APICall()
		const payload = {command: "create_mc_role_type", role_type_obj: this.state.role_type_obj}
		const result = await api.commandWithoutCallback(payload)
        console.log(result)
        let alert_param = null
		if (result.error_code !== 0) {
			alert_param = {
                title: 'ERROR', message: result.error_msg, ok_text: 'Ok',
                alertHandler: this.afterRoleTypeAdded, stack: {}, position: {top: "22vh", left: "6vw"}
            }
            this.setState({alert_param})
		} else {
            alert_param = {
                title: 'SUCCESS', message: result.error_msg, ok_text: 'Ok',
                alertHandler: this.afterRoleTypeAdded, stack: {id: result.result, name: this.state.role_type_obj.name}, position: {top: "22vh", left: "6vw"}
            }
            this.setState({alert_param})
        }
		// this.props.createRoleType({id: result.result, name: this.state.role_type_obj.name})
	}

    afterRoleTypeAdded = (result, stack) => {
        console.log("result:", result)
        console.log("stack:", stack)
        this.setState({alert_param: null})
        this.props.createRoleType(stack)
    }

    closeModal = (e) => {
        e.preventDefault();
        console.log("this.props.closeModal()", this.props);
        this.props.closeModal();
    }

    render(){
        return(
            <div className="add-role-modal-container">
                <div className="add-role-modal-header-container">
                    <div className="add-role-modal-header-text-container">
                        <div className="add-role-modal-header-title">Add Role Type</div>
                        <div className="add-role-modal-header-subtitle">Use this section to add Role Type.</div>
                    </div>
                    <FontAwesomeIcon icon={solid('xmark')} onClick={this.closeModal} size="2x" style={{cursor: "pointer"}} />
                </div>
                <div className="add-role-modal-body-container">
                    <div>
                        <div className="add-role-modal-label">Role Type Label</div>
                        <input type="text" name="name" className="add-role-modal-input" value={this.state.role_type_obj.name} onChange={this.handleChange} />
                    </div>
                    <div>
                        <div className="add-role-modal-label" style={{marginBottom: "0px"}}>Role Reference</div>
                        <div className="add-role-modal-label-small">Please enter a suitable reference code for your new Role Type. As an example, the role reference code for Senior Manager Functions is 'SMF'</div>
                        <input type="text" name="base" className="add-role-modal-input" value={this.state.role_type_obj.base} onChange={this.handleChange} />
                    </div>
                    <div>
                        <div className="add-role-modal-label">Help Text</div>
                        <textarea rows={6} className="add-role-modal-textarea" name="help_text" value={this.state.role_type_obj.help_text} onChange={this.handleChange} />
                    </div>
                    <div style={{display: "flex", flexDirection: "row", columnGap: "15px", justifyContent: "flex-start", alignItems: "center"}}>
                        {
                            this.state.role_type_obj.requires_acceptance === true ?
                            <FontAwesomeIcon className="mc-roles-add-icon" style={{ color : "#ababab"}} icon={regular('toggle-off')} onClick={this.toggleAcceptance}/> :
                            <FontAwesomeIcon className="mc-roles-add-icon" icon={regular('toggle-on')} onClick={this.toggleAcceptance}/>
                        }
                        <span style={{fontSize: "14px", fontWeight: "500"}}>Roles of this type do not need Employee Acceptance</span>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", columnGap: "15px", justifyContent: "flex-start", alignItems: "center"}}>
                        <div className="add-role-modal-label2" style={{ lineHeight : "30px"}}>Regime Color</div>
                        <input type="color" name="color" value={this.state.role_type_obj.color} onChange={this.handleChange} />
                    </div>
                </div>
                <div className="add-role-modal-footer-container">
                    <button className="add-role-modal-cancel-button" onClick={this.closeModal}>Cancel</button>
                    <button className="add-role-modal-save-button" onClick={this.createRoleType}>Save</button>
                </div>
                {this.state.alert_param !== null && <AlertBox position={{top: "25%", left: "2%"}} alertParam={this.state.alert_param} />}
            </div>
        )
    }
}

export default AddRoleTypeModal;