import React from "react";
import CSLTable from "../Common/CSLTable";
import APICall from "../../Common/APICall";
import Store from "../../Common/Store";

class Permissions extends React.Component {

    state = {
        users_premission: {},
        user_names: {},
        permission_options: [
            {name: 'admin_manager', nickname: 'Admin Manager'},
            {name: 'hr', nickname: 'HR'},
            {name: 'team', nickname: 'Team'},
            {name: 'no_access', nickname: 'No Access'}
        ]
    }

    componentDidMount() {
        const cts = JSON.parse(localStorage.getItem("contactlist"))
		const sldata = JSON.parse(localStorage.getItem("site_layout"))
        const user_names = sldata.result.use_storage.result.contactlist
        const users_premission = sldata.result.use_storage.result.user_roles.smcr
        this.setState({user_names, users_premission})
    }

    changePermission = (user_id) => async (event) => {
        event.preventDefault()
        const role = event.target.value
        console.log("user_id:", user_id)
        console.log("new permission:", role)
        let users_premission = JSON.parse(JSON.stringify(this.state.users_premission))
        users_premission[user_id.toString()] = role
        this.setState({users_premission})
        const api = new APICall()
        const payload = {command: "save_user_role", user_id: parseInt(user_id), role: role}
        const ret = await api.commandWithoutCallback(payload)
        await this.updateStorage(user_id, role)
    }

    updateStorage = async (user_id, role) => {
        let module_name = Store.getStoreData('module_name');
        console.log("module_name:", module_name)
        if(localStorage.getItem('site_layout')!==null){
            let site_layout = JSON.parse(localStorage.getItem('site_layout'));
            site_layout.result.use_storage.result.user_roles[module_name][user_id.toString()] = role
            console.log("st role:", site_layout.result.use_storage.result.user_roles[module_name][user_id.toString()])
            localStorage.setItem("site_layout", JSON.stringify(site_layout))
        }
        if(localStorage.getItem('contactlist')!==null){
            let contactlist = JSON.parse(localStorage.getItem('contactlist'))
            contactlist.result.user_roles[module_name][user_id.toString()] = role
            console.log("ct role:", contactlist.result.user_roles[module_name][user_id.toString()])
            localStorage.setItem("contactlist", JSON.stringify(contactlist))
        }
    }

    tableData = () => {
        let ret = {data: [], columns: []}
        ret.columns = [
            {Header: 'Name', accessor: 'name'},
            {
                Header: 'Permission Level', accessor: 'permission',
                Cell: row => (
                    <select className="mc-dropdown" value={row.original.permission} onChange={this.changePermission(row.original.user_id)}>
                        {this.state.permission_options.map(item => {
                            return <option key={item.name} value={item.name}>{item.nickname}</option>
                        })}
                    </select>
                )
            }
        ]
        if (Object.keys(this.state.user_names).length !== 0) {
            for (let user_id in this.state.user_names) {
                ret.data.push({
                    user_id: user_id,
                    name: this.state.user_names[user_id].ContactName,
                    permission: user_id in this.state.users_premission ? this.state.users_premission[user_id] : 'no_access'
                })
            }
            ret.data.sort((a, b) => {
                if (a.name < b.name) return -1
                if (a.name > b.name) return 1
                return 0
            })
        }
        return ret
    }

    render() {
        return (
            <div className="mc-general-container">
                <div className="mc-table-header-container" style={{marginTop: "40px"}}>
                    <div style={{ fontWeight: "600", fontSize: "15px" }}>Users</div>
                </div>
                <CSLTable processData={this.tableData} />
            </div>
        )
    }

}

export default Permissions