
import Utils from './../../Common/Utils';


class RespoEventBusNew {
    constructor () {
        this.storeData = {};
        this.callBacks = {};
    }

    // Call this function to update store data. The data_id identifies the data
    // and you can use this from any component to access this piece of data
    updateStore = (data_id, state, component_id = null, no_updates = false) => {
        let store_data = JSON.parse(JSON.stringify(state));
        this.storeData[data_id] = store_data
        if (no_updates === true) return;
        if (data_id in this.callBacks) {
            for (var key in this.callBacks[data_id]) {
                if (component_id === null || key !== component_id) this.callBacks[data_id][key]();
            }
        }
    }


    getStoreAllData = () => {
        return JSON.parse(JSON.stringify(this.storeData));
    }
    // Usually called from the callback function to update the component state
    // using the store data and forcing re-render in case data has changed

    getStoreData = (data_id) => {
        if (data_id in this.storeData) return JSON.parse(JSON.stringify(this.storeData[data_id]));
        else return null;
    }
    getAllStoreData = () => {
        return JSON.parse(JSON.stringify(this.storeData));

    }

    registerCallBack = (data_id, callback, component_id = null) => {
        let key = component_id === null ? Utils.genKey(12) : component_id;
        //console.log.log("key",key);
        if (!(data_id in this.callBacks)) {
            this.callBacks[data_id] = {};
        }
        this.callBacks[data_id][key] = callback;
        return key;
    }

    deRegisterCallback = (data_id, component_id) => {
        if (data_id in this.callBacks && component_id in this.callBacks[data_id]) {
            delete this.callBacks[data_id][component_id];
        }
    }

}

export default new RespoEventBusNew();
