import React from 'react';
import styled from 'styled-components'

const trueBoxHeight = 235;
const boxWidth = 267;
const horzGap = boxWidth * 2 / 3;
const vertGap = 40;
const initialHorzGap = 20;
const boxBorderWidth = 1;
const horzAdj = 0;
const vertAdj = 0;
const RespoBoxFooterWrapper = styled.div`
							display: block;
							border-width:${boxBorderWidth}px;
							border-style: solid;
							border-radius: 0px 0px 5px 5px;
							padding-left: 14px;

							color: blue;
							width: 282px;
							bottom:0px;
							left:0px;
							`
const RespoBoxFooterRow = styled.div`
							display: inline-block;
							width: 53px;
							background: white;
							border: 1px solid white;
							border-radius: 4px;
							font-size: 10px;
						    padding: 1px 5px;
						    margin-top: 8px;
						    text-decoration: underline;
  							text-underline-position: under;
  							line-height: 20px;
  							text-align: center;
									`;
const RespoBoxFooterPills = styled.div`
							display: block;
						    background: white;
						    border: 1px solid white;
						    border-radius: 4px;
						    width: 53px;
						    font-size: 10px;
						    padding: 1px 5px;
						    margin-right: 5px;
						    float: left;
						    align: center;
						    margin-top: 8px;
						    text-decoration: underline;
  							text-underline-position: under;
  							line-height: 20px;
  							text-align: center;
									`;
const RespoBoxFooterRowpill = styled.div`
							display: inline;
							padding-left: 14px;
						    font-size: 11px;
						    color: white;
							`;
const RespoLine = styled.div`
					display: block;
				    height: 1px;
				    width: 95%;
				    background: white;
				    margin-top: 6px;
					`;


class RespoBoxFooter extends React.Component {

	componentDidMount() {
		// console.log("this.props -->", this.props);
	}

	render() {
		const showRole = this.props.showRole;
		console.log('this.props.item in RespoBoxFooter ===>', this.props.item)
		const { footerHeight, color, footerItems } = this.props.item;
		let itemlength = Object.keys(footerItems).length;
		let footerHeight_new = Object.keys(footerItems).length + 50;
		if (showRole === 1) {
			let i = 0;
			return (
				<RespoBoxFooterWrapper style={{
					position: 'absolute',
					//height: `${footerHeight}px`,
					//minHeight: `calc(${footerHeight_new}px - ${2 * boxBorderWidth}px)`,
					background: color,
					borderColor: color,
					width: `{boxWidth - 2 * boxBorderWidth}px`

				}}>
					{

						Object.keys(footerItems).map((key) => {
							// debugger;
							i++;
							// console.log("Value of i:", i);
							if (i === itemlength) {
								return (<div><RespoBoxFooterRow style={{
									color: `${footerItems[key]['bgcolor']}`
								}}>{key}</RespoBoxFooterRow>{footerItems[key]["text"]["title"].length > 30 ? <RespoBoxFooterRowpill title={footerItems[key]["text"]["title"]}>{footerItems[key]["text"]["title"].substring(0, 30) + " ..."}</RespoBoxFooterRowpill> : <RespoBoxFooterRowpill>{key}</RespoBoxFooterRowpill>}</div>
								);
							}
							return (<div><RespoBoxFooterRow style={{
								color: `${footerItems[key]['bgcolor']}`
							}}>{key}</RespoBoxFooterRow>{footerItems[key]["text"]["title"].length > 30 ? <RespoBoxFooterRowpill title={footerItems[key]["text"]["title"]}>{footerItems[key]["text"]["title"].substring(0, 30) + " ..."}</RespoBoxFooterRowpill> : <RespoBoxFooterRowpill>{key}</RespoBoxFooterRowpill>}<RespoLine></RespoLine></div>
							);
						})
					}
				</RespoBoxFooterWrapper>
			);
		}
		else {
			return (
				<RespoBoxFooterWrapper style={{
					position: 'absolute',
					//height: `${footerHeight}px`,
					minHeight: `calc(${footerHeight_new}px - ${2 * boxBorderWidth}px)`,
					background: color,
					borderColor: color,
					width: `{boxWidth - 2 * boxBorderWidth}px`
				}}>
					{
						Object.keys(footerItems).map((key, index) => {
							// debugger;
							return (<RespoBoxFooterPills key={index} style={{
								color: `${footerItems[key]['bgcolor']}`
							}}>{key}</RespoBoxFooterPills>);
						})
					}
				</RespoBoxFooterWrapper>
			);
		}
	}

}

export default RespoBoxFooter;
