import React from 'react';
import styled from 'styled-components'
import RespoEventBusNew from './RespoEventBusNew'
import TreeRootButton from './TreeRootButton.js'
import TreeStopButton from './TreeStopButton.js'
import RespoBoxFooter from './RespoBoxFooter.js'

const trueBoxHeight = 235;
const boxWidth = 267;
const horzGap = (boxWidth - 1) * 2 / 3;
const vertGap = 40;
const initialHorzGap = 20;
const boxBorderWidth = 1;
const horzAdj = 0;
const vertAdj = 0;

const RespoBoxWrapper = styled.div`
							display: block;
							border-width:${boxBorderWidth}px;
							border-style: solid;
							border-radius: 7px;
							padding-left: 15px;
							padding-right: 15px;
							height: ${trueBoxHeight}px;
							color: blue;
							width: ${boxWidth}px;
							`
const RespoBoxHeader = styled.div`
							color: blue;
							font-weight: 600;
							font-size: 16px;

							width: 100%;
							height: 20px;
							padding: 4px;
							`
const RespoBoxSpacer = styled.div`
							min-height: 0px;
							`
const RespoBoxBody = styled.div`
							color : #b1b1b1;
							font-weight: 400;
							font-size: 12px;
							width: 100%;
							word-wrap: break-word;
							`
const RespoBoxName = styled.div`
							color: black;
							font-weight: 400;
							font-size: 12px;
							width: 100%;
							height: 16px;
							padding: 2px;
							`

const RespoBoxHline = styled.div`
							display: block;
							margin-top: 10px
						    height: 0px;
						    width: 95%;
						    border: 1px solid #cecece;
						    margin-bottom: 10px;
						    align: ${boxBorderWidth - 400}px;
							`;


class RespoBox extends React.Component {
	render() {
		let fullMap = RespoEventBusNew.getStoreData('full_map');
		let mapXOffset = fullMap ? 0 : 140;
		let mapYOffset = fullMap ? 0 : 200;
		const { name, role, title, level, job_desc, boxHeight, boxTop, color } = this.props.item;
		return (<div style={{ marginBottom: '20px', height: '220px' }}>
			<RespoBoxWrapper style={{
				position: 'absolute',
				top: `${mapYOffset + boxTop}px`,
				left: `${mapXOffset + (horzGap * (this.props.item.level - 1)) + (horzGap / 4) - horzAdj}px`,
				height: `${boxHeight + 10}px`,
				color: color
			}}>
				<RespoBoxHeader style={{ color: color }}>{title}<TreeRootButton item={this.props.item} /><TreeStopButton item={this.props.item} /></RespoBoxHeader>
				<RespoBoxName>{name}</RespoBoxName>
				<RespoBoxHline width="95%" />
				<RespoBoxSpacer />
				{this.props.showJob === 1 ? job_desc.length > 200 ? <RespoBoxBody title={job_desc}>{job_desc.substring(0, 200) + ".."}</RespoBoxBody> : <RespoBoxBody >{job_desc}</RespoBoxBody> : ``}
				<RespoBoxSpacer />
				<RespoBoxFooter item={this.props.item} showRole={this.props.showRole} />
			</RespoBoxWrapper></div>);
	}

}

export default RespoBox;
