import React from "react";
import CSLTable from "../Common/CSLTable";
import APICall from "../../Common/APICall";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import EmailConfigModal from "./Modals/EmailConfigModal";
import SmallSpinner from "../Common/SmallSpinner";

class Emails extends React.Component {

    state = {emails: [], variables: [], current_email: null, show_edit_modal: false, loading : false}

    async componentDidMount() {
        await this.setComponentState()
    }

    async componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            await this.setComponentState()
        }
    }

    setComponentState = async () => {
        this.setState({ loading : true})
        const payload = {command: "email_config", action: "list_emails_and_varibales"}
        const api = new APICall()
        const response = await api.commandWithoutCallback(payload)
        if (response.error_code === 0) {
            this.setState({emails: response.result.emails, variables: response.result.variables, show_edit_modal: false, loading : false})
        }
    }

    toggleActive = (row_id) => async (event) => {
        event.preventDefault()
        let toggleEmail = {id: 0, is_active: 0}
        let emails = JSON.parse(JSON.stringify(this.state.emails))
        for (let i=0; i<emails.length; i++) {
            if (emails[i].id === row_id) {
                emails[i].is_active = emails[i].is_active === 1 ? 0 : 1
                toggleEmail.id = row_id;
                toggleEmail.is_active = emails[i]?.is_active;
                this.toggleEmailInDB(toggleEmail);
            }
        }
        this.setState({emails})
    }

    toggleEmailInDB = async (toggleObj) => {
        let {id, is_active} = toggleObj;
        const payload = {command: "email_config", action: "toggle_email", id: id, is_active: is_active};
        const api = new APICall();
        await api.commandWithoutCallback(payload);
    }

    showEmailEditModal = (row_id) => (event) => {
        console.log("edit row id:", row_id)
        let emails = JSON.parse(JSON.stringify(this.state.emails))
        let current_email = emails.find(item => item.id === row_id)
        this.setState({current_email, show_edit_modal: true})
    }

    closeEditModal = () => {
        this.setState({show_edit_modal: false})
    }

    tableData = () => {
        let ret = { data: [], columns: [] };
        ret.columns = [
            { Header: 'EMAIL', accessor: 'name', filterable: true, minWidth: 330 },
            { Header: 'SUBJECT', accessor: 'subject', filterable: true, minWidth: 330 },
            {
                Header: 'ENABLED', accessor: '', filterable: false, minWidth: 80, textAlign: "center",
                Cell: row => (
                    row.original.is_active === 1 ?
                        <FontAwesomeIcon className="mc-roles-add-icon" icon={regular('toggle-on')} onClick={this.toggleActive(row.original.id)} /> :
                        <FontAwesomeIcon className="mc-roles-add-icon" style={{ color: "#ababab" }} icon={regular('toggle-off')} onClick={this.toggleActive(row.original.id)} />
                )
            },
            {
                'Header': '', filterable: false, minWidth: 50,
                Cell: row => (
                    <div className="mc-roles-table-action-container" style={{ width: "100%", paddingRight: "10px" }}>
                        <FontAwesomeIcon className="mc-roles-add-icon" title="Edit Role Type" icon={solid('pencil')} onClick={this.showEmailEditModal(row.original.id)} />
                    </div>
                )
            },
        ];

        if (this.state.emails.length !== 0) {
            for (let d of this.state.emails) {
                let elem = {
                    name: d.name,
                    subject: JSON.parse(d.lookup_json)["subject"],
                    id: d.id,
                    is_active: d.is_active
                }
                ret.data.push(elem);
            }
        }
        return ret;
    }

    render() {
        return (
            <div className="mc-general-container">
                {
                    this.state.show_edit_modal === true &&
                    <div className="interactive-overlay">
                        <EmailConfigModal 
                            current_email={this.state.current_email}
                            variables={this.state.variables}
                            closeEditModal={this.closeEditModal}
                            setComponentState={this.setComponentState}
                        />
                    </div>
                }
                <div className="mc-table-header-container" style={{marginTop: "40px"}}>
                    <div style={{ fontWeight: "600", fontSize: "15px" }}>Emails</div>
                </div>
                <div className="mc-table-container">
                    <CSLTable processData={this.tableData} />
                    {
                        this.state.loading && <SmallSpinner />
                    }
                </div>
                
            </div>
        )
    }
}

export default Emails