import React from "react"
import DefaultHeader from "../Common/DefaultHeader"
import WelcomeBanner from "../Common/WelcomeBanner"
import Store from "../Common/Store"
import CSLTable from "./Common/CSLTable"
import "./Index.css"
import "./Moduleconfig/MConfig.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import APICall from "../Common/APICall"
import EmployeeDetails from "./Personnel/EmployeeDetails"
// import CSLLoader from './Common/CSLLoader';
// import TransparentLoader from './Common/TransparentLoader'
import SmallSpinner from "./Common/SmallSpinner"
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
const moment = require('moment');

const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";


class Index extends React.Component {

    state = { employees: [], selected_employee_id: 0, show_employee_details: false, users_name_obj: {}, isLoading: true, header_context_visible : false, task_ID: 0 }

    async componentDidMount() {
        // console.log('Index page componnet did mount')
        let task_id = this.props.match ? parseInt(this.props.match.params.taskId) : 0;
        this.setState({task_ID: task_id});
        console.log("task_id:", task_id);

        let users_name_obj = {}
        for (let item of Store.getStoreData("listcontacts")) {
            users_name_obj[item.ID] = item.ContactName
        }
        if (Object.keys(users_name_obj).length !== 0) {
            users_name_obj = Object.fromEntries(Object.entries(users_name_obj).sort((a, b) => a[1].localeCompare(b[1])));
        }
        const role = Store.getStoreData("role")
        let users = Store.getStoreData("regime_users")
        //console.log('users ==>', users);
        if (users.length !== 0) {
            const payload1 = { command: "personnel", action: "users_regimes", users: users, role: role }
            const payload2 = { command: "personnel", action: "users_gcs", users: users, role: role }
            const payload3 = { command: "personnel", action: "users_cert_mgs", users: users, role: role }
            const payload4 = { command: "personnel", action: "users_certs", users: users, role: role }
            const api = new APICall()
            // console.log('payloads', payload1, payload2, payload3, payload4)
            const [users_regimes_result, users_gcs_result, users_cert_mgs_result, users_certs_result] = await Promise.all([
                api.commandWithoutCallback(payload1),
                api.commandWithoutCallback(payload2),
                api.commandWithoutCallback(payload3),
                api.commandWithoutCallback(payload4)
            ])
            // const users_regimes_result = await api.commandWithoutCallback(payload1)
            // const users_cert_mgs_result = await api.commandWithoutCallback(payload3)
            // const users_gcs_result = await api.commandWithoutCallback(payload2)
            // const users_certs_result = await api.commandWithoutCallback(payload4);

            // console.log('data ==>', users_certs_result);

            if (users_regimes_result.error_code === 0 && users_cert_mgs_result.error_code === 0 && users_gcs_result.error_code === 0 && users_certs_result.error_code === 0) {
                let employees = []
                // let index = 0
                for (let user_id of users) {
                    // index++
                    let gcs_arr = []
                    let gcs = ""
                    if (user_id.toString() in users_gcs_result.result) {
                        if (Object.keys(users_gcs_result.result[user_id.toString()]).length !== 0) {
                            for (let key in users_gcs_result.result[user_id.toString()]) {
                                gcs_arr.push(users_gcs_result.result[user_id.toString()][key])
                            }
                            gcs = gcs_arr.length === 1 ? gcs_arr[0] : "Multiple"
                        }
                    }
                    let gc_names = ""
                    if (gcs_arr.length !== 0) {
                        gc_names = gcs_arr.length === 1 ? gcs_arr[0] : gcs_arr.join()
                    }
                    let cert_mgrs_arr = []
                    let cert_mgs = ""
                    if (user_id.toString() in users_cert_mgs_result.result) {
                        if (Object.keys(users_cert_mgs_result.result[user_id.toString()]).length !== 0) {
                            for (let key in users_cert_mgs_result.result[user_id.toString()]) {
                                for (let cert_user_id in users_cert_mgs_result.result[user_id.toString()][key]) {
                                    cert_mgrs_arr.push(users_name_obj[cert_user_id])
                                }
                            }
                        }
                    }
                    if (cert_mgrs_arr.length === 1) cert_mgs = cert_mgrs_arr[0]
                    if (cert_mgrs_arr.length > 1) cert_mgs = "Multiple"
                    let emp = {
                        id: user_id,
                        name: users_name_obj[user_id.toString()],
                        type: users_regimes_result.result[user_id.toString()].display,
                        role_types: users_regimes_result.result[user_id.toString()].role_types,
                        gcs: gcs,
                        gc_names: gc_names,
                        due_date: users_certs_result.result[user_id.toString()].certifications.certCol,
                        completed_count: users_certs_result.result[user_id.toString()].completed,
                        cert_status: users_certs_result.result[user_id.toString()].certifications.certStatus,
                        cert_status_color: users_certs_result.result[user_id.toString()].certifications.certStatusColor,
                        cert_mgs: cert_mgs,
                        cert_mgrs_arr: cert_mgrs_arr,
                        context_visible: false,
                        status: users_regimes_result.result[user_id.toString()].status
                    }
                    employees.push(emp)
                }

                if (employees.length > 0) {
                    employees = employees.sort((a, b) => a.name.localeCompare(b.name));
                }

                this.setState({ isLoading: false, employees, users_name_obj })
                if (task_id !== 0) {
                    if (Store.getStoreData("role") === "team") {
                        if (Store.getStoreData("logged_in_contact_id") === task_id) {
                            this.selectedEmployee(task_id);
                        }
                    } else {
                        this.selectedEmployee(task_id);
                    }
                }
            }
        }
        this.setState({isLoading: false})
    }

    toggleContextMenu = (emp_id) => (event) => {
        event.preventDefault();
        let employees = JSON.parse(JSON.stringify(this.state.employees))
        for (let i = 0; i < employees.length; i++) {
            if (employees[i].id === emp_id) {
                employees[i].context_visible = employees[i].context_visible === true ? false : true;
                break;
            }
        }
        this.setState({ employees })
    }

    closeContextMenu = (emp_id) => (event) => {
        event.preventDefault()
        let employees = JSON.parse(JSON.stringify(this.state.employees))
        for (let i = 0; i < employees.length; i++) {
            if (employees[i].id === emp_id) {
                employees[i].context_visible = false;
                break;
            }
        }
        this.setState({ employees })
    }

    prepareExportData = async () => {
        let ret = {export_data : [], merges : []};
        let export_data = [];
        let employees = JSON.parse(JSON.stringify(this.state.employees));
        //console.log("Employees : ", employees);
        const cts = JSON.parse(localStorage.getItem("contactlist"));
        let contacts = cts.result.contactlist;
        const api = new APICall();
        let employee_ids = [];
        for(let i=0; i<employees.length; i++){
            employee_ids.push(employees[i].id);
        }
        let export_api_result = await api.commandWithoutCallback({command : 'export_data', action : 'get_emp_details_home_page_export', emp_ids : employee_ids});
        console.log("export_api_result : ",export_api_result.result);
        let row = 1;
        let new_record_row = 1;
        let merges = [];
        for(let e of employees){
            let dtl = export_api_result.result.find(item => item.emp_id === e.id);
            let data = {};
            if(dtl.role_detail.length>0){
                let c = 1;
                new_record_row = row;
                let prev_role_type = dtl.role_detail[0].role_type;
                let new_role_rec = row;
                let merge_upto = -1;
                for(let i=0; i<dtl.role_detail.length ; i++) {
                    let r = dtl.role_detail[i];
                    if(r.role_type === prev_role_type){
                        merge_upto++;
                    }else{
                        if(new_role_rec !== new_role_rec + merge_upto) merges.push({ s : { r : new_role_rec, c : 7 }, e : { r : new_role_rec + merge_upto, c : 7}});
                        //console.log("MID from : ", new_role_rec, "to : ", new_role_rec + merge_upto );
                        new_role_rec += merge_upto + 1 ;
                        prev_role_type = r.role_type;
                        merge_upto = 0;
                    }
                    data= {
                            "Name" : c === 1 ? e.name : '',
                            "Job Titles" : c === 1 ? dtl.job_title : '',
                            "Appointed Date" : c === 1 ? dtl.appointed_date === null ? '--/--/----' : moment(dtl.appointed_date).format("DD/MM/YYYY") : '',
                            "Description" : c === 1 ? dtl.description : '',
                            "Email Address" : c === 1 ? dtl.email : '',
                            "Group Companies" : c === 1 ? e.gc_names : '',
                            "Line Manager" : c === 1 ? dtl["line_manager"] != 0 ? contacts[dtl["line_manager"]]["ContactName"] : "" : "",
                            "Role Type" : r.role_type,
                            "Role" : r.role_code,
                            "Effective Date" : moment(r.effective_from_date,"YYYYMMDD").format("DD/MM/YYYY"),
                            "Certification Manager(s)" : r.cert_mgr_id !== null ? employees.find(item => item.id === r.cert_mgr_id) !== undefined ? employees.find(item => item.id === r.cert_mgr_id).name : '' : ''
                        }
                    //console.log("Data  Export ", data);
                    export_data.push(data);
                    c++;
                    row++;
                }
                //merges.push({ s : { r : new_role_rec, c : 6 }, e : { r : merge_upto-1, c : 6}});
                if(new_role_rec !== new_role_rec + merge_upto) merges.push({ s : { r : new_role_rec, c : 7 }, e : { r : new_role_rec + merge_upto, c : 7}});
                //console.log("MID from : ", new_role_rec, "to : ", new_role_rec + merge_upto );
                merges.push(
                    { s : { r : new_record_row, c : 0 }, e : { r : row - 1, c : 0}},
                    { s : { r : new_record_row, c : 1 }, e : { r : row - 1, c : 1}},
                    { s : { r : new_record_row, c : 2 }, e : { r : row - 1, c : 2}},
                    { s : { r : new_record_row, c : 3 }, e : { r : row - 1, c : 3}},
                    { s : { r : new_record_row, c : 4 }, e : { r : row - 1, c : 4}},
                    { s : { r : new_record_row, c : 5 }, e : { r : row - 1, c : 5}},
                    { s : { r : new_record_row, c : 6 }, e : { r : row - 1, c : 6}},
                );
            }else{
                    data= {
                        "Name" : e.name,
                        "Job Titles" : dtl.job_title,
                        "Appointed Date" : dtl.appointed_date === null ? '--/--/----' : moment(dtl.appointed_date).format("DD/MM/YYYY"),
                        "Description" : dtl.description,
                        "Email Address" : dtl.email,
                        "Group Companies" : employees.gc_names,
                        "Line Manager" : '',
                        "Role Type" : '',
                        "Role" :'',
                        "Effective Date" : '',
                        "Certification Manager(s)" : ''
                    }
                    //console.log("Data  Export ", data);
                    export_data.push(data);
                    row++;
                }
        }
        //console.log("merges",merges);
        ret.export_data = export_data;
        ret.merges = merges;
        return ret;
    }

    exportToExcel = async (event) => {
        event.preventDefault();
        //let wb = { SheetNames: [], Sheets: {} };
        let ret_data = await this.prepareExportData();
        let ex_data = ret_data.export_data;
        let ws = XLSX.utils.json_to_sheet(ex_data);
        ws["!merges"] = ret_data.merges;
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Employees");
        var wbout = XLSX.write(wb, {bookType:'xlsx', type:'array'});

        // wb.SheetNames.push("Employees");
        // wb.Sheets["Employees"] = XLSX.utils.json_to_sheet(ex_data);
        const format = "DD-MM-YYYY HH:mm:ss";
        let tm = moment().format(format);
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "SM  & CR - Empolyees" + "-" + tm + fileExtension);
    };


    showEmployeeDetails = (emp_id) => (event) => {
        event.preventDefault()
        let employees = JSON.parse(JSON.stringify(this.state.employees))
        for (let i = 0; i < employees.length; i++) {
            if (employees[i].id === emp_id) {
                employees[i].context_visible = false;
                break;
            }
        }
        this.setState({ selected_employee_id: emp_id, show_employee_details: true, employees })
    }

    selectedEmployee = (emp_id) => {
        this.setState({ selected_employee_id: emp_id, show_employee_details: true})
    }

    processEmployees = () => {
        let ret = { data: [], columns: [] }
        ret.columns = [
            {
                Header: 'NAME', accessor: 'name', minWidth: 150, filterable: true, headerStyle: { textAlign: 'left' },
                Cell: row => (
                    <span style={{ fontWeight: "600", cursor: "pointer" }} onClick={this.showEmployeeDetails(row.original.id)}>{row.original.name}</span>
                )
            },
            { Header: 'TYPE', accessor: 'role_type', minWidth: 60, filterable: true, headerStyle: { textAlign: 'left' }, style: { fontWeight: '600' } },
            {
                Header: 'LEGAL ENTITY', accessor: 'gc', minWidth: 120, filterable: true, headerStyle: { textAlign: 'left' },
                Cell: row => (
                    <span title={row.original.gc === "Multiple" ? row.original.gc_names : ""} style={{ fontWeight: "600", color: row.original.gc === "Multiple" ? "#24B3AD" : "#222222", cursor: row.original.gc === "Multiple" ? "pointer" : "text" }}>{row.original.gc}</span>
                )
            },
            { Header: 'CERTIFICATIONS', accessor: 'due_date', minWidth: 120, filterable: true, headerStyle: { textAlign: 'left' }, style: { fontWeight: '600' } },
            { Header: 'COMPLETE', accessor: 'completed_count', minWidth: 70, filterable: true, headerStyle: { textAlign: 'left' }, style: { fontWeight: '600' } },
            { Header: 'STATUS', accessor: 'status', minWidth: 70, filterable: true, headerStyle: { textAlign: 'left' }, style: { fontWeight: '600' } },
            {
                Header: 'CERT STATUS', accessor: 'cert_status', filterable: true, headerStyle: { textAlign: 'left' },
                Cell: row => (
                    <div style={{ display: "flex", columnGap: "10px" }}>
                        <div style={{ width: "15px", height: "15px", backgroundColor: `#${row.original.cert_status_color}`, borderRadius: "2px" }}></div>
                        <div style={{ fontWeight: "600" }}>{row.original.cert_status}</div>
                    </div>
                )
            },
            {
                Header: 'CERT MANAGER', accessor: 'cert_manager', filterable: true, headerStyle: { textAlign: 'left' },
                Cell: row => (
                    <span style={{ fontWeight: "600", color: row.original.cert_manager === "Multiple" ? "#24B3AD" : "#222222" }}>{row.original.cert_manager}</span>
                )
            },
            {
                Header: "", filterable: false,
                Cell: row => (
                    <div className="mc-roles-table-action-container" style={{ width: "100%", paddingRight: "10px" }}>
                        <FontAwesomeIcon className="mc-roles-add-icon" title="View" icon={regular('ellipsis-vertical')} onClick={this.toggleContextMenu(row.original.id)} />
                        {
                            row.original.context_visible &&
                            <div className="context-menu" onMouseLeave={this.closeContextMenu(row.original.id)}>
                                <div className="context-menu-item" onClick={this.showEmployeeDetails(row.original.id)}>Edit</div>
                            </div>
                        }
                    </div>
                )
            }
        ]
        for (let item of this.state.employees) {
            ret.data.push({
                id: item.id,
                name: item.name,
                role_type: item.type,
                gc: item.gcs,
                gc_names: item.gc_names,
                due_date: item.due_date,
                completed_count: item.completed_count,
                status: item.status,
                cert_status: item.cert_status,
                cert_status_color: item.cert_status_color,
                cert_manager: item.cert_mgs,
                context_visible: item.context_visible
            })
        }
        // ret.data = []
        return ret
    }

    toggleHeaderContextMenu = (e) => {
        e.preventDefault();
        let header_context_visible = this.state.header_context_visible === true ? false : true;
        this.setState({ header_context_visible });
    }

    closeHeaderContextMenu = (e) => {
        e.preventDefault();
        this.setState({ header_context_visible : false });
    }

    closeEmployeeDetails = () => {
        this.setState({ show_employee_details: false })
    }

    render() {
        // console.log("state:", this.state)
        let role = Store.getStoreData('role');
        return (
            <div className="index-container" style={{height: "105vh"}}>
                <DefaultHeader pageName="Employee Overview" />
                <WelcomeBanner welcome={`Welcome back, ` + Store.getStoreData('contact').ContactName} />
                <div style={{ position: "relative" }}>
                    {
                        this.state.show_employee_details === true &&
                        <div className="interactive-overlay" style={{ zIndex: "11" }}>
                            <EmployeeDetails
                                selected_employee_id={this.state.selected_employee_id}
                                users_name_obj={this.state.users_name_obj}
                                closeEmployeeDetails={this.closeEmployeeDetails}
                                task_id={this.state.task_ID}
                            />
                        </div>
                    }
                    <div className="top-div">
                        {Store.getStoreData("company_name") + " Employees"}
                        <div className="index-ellipsis-container">
                            <FontAwesomeIcon className="table-ellipsis" icon={regular('ellipsis-vertical')} onClick={this.toggleHeaderContextMenu}/>
                            {
                                (() => {
                                    if(this.state.header_context_visible === true) return(
                                        <div className="index-table-header-context-menu" onMouseLeave={this.closeHeaderContextMenu}>
                                            {role === 'admin_manager' && <div className="table-context-menu-item" onClick={this.exportToExcel}>Export</div>}
                                        </div>
                                    )
                                })()
                            }
                        </div>
                    </div>
                    <div className="mc-table-container">
                        <CSLTable processData={this.processEmployees} />
                        {
                            this.state.isLoading && <SmallSpinner />
                        }
                    </div>
                    {/* {isLoading && (
                        <div
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }}
                        >
                            <TransparentLoader isLoading={this.state.isLoading} />
                        </div>
                    )} */}
                    {/* {!isLoading && (
                        <div>
                            <CSLTable processData={this.processEmployees} />
                        </div>
                    )} */}
                </div>
            </div>
        )
    }
}

export default Index
