import React, { Component } from 'react'
import './sor.css'
class ReportSectionFilter extends Component {
    render() {
        return (
            <div class='filter-container-first'>
                <h4>Include Report Sections</h4>


                <div class="form-group">
                    <div class="input-group">
                        <div className='checkbox-container'>
                            <input id="chkStatementOfRespo" type="checkbox" checked={true} readOnly />
                        </div>
                        <span
                            id="txtRegime_colourCodes"
                            class="form-control"
                        >
                            Statement of Responsibility
                        </span>
                    </div>
                </div>

                <div class="form-group">
                    <div class="input-group">
                        <div className='checkbox-container'>
                            <input id="chkEmployeeWithNoResponsibilities" type="checkbox" />
                        </div>
                        <span
                            id="txtRegime_colourCodes"
                            class="form-control"
                        >
                            Employee with no Responsibilities
                        </span>
                    </div>
                </div>

                <div class="form-group">
                    <div class="input-group">
                        <div className='checkbox-container'>
                            <input id="chkUnAllocatedResponsibilities" type="checkbox" />
                        </div>
                        <span
                            id="txtRegime_colourCodes"
                            class="form-control"
                        >
                            Unallocated Responsibilities
                        </span>
                    </div>
                </div>

                <div class="form-group">
                    <div class="input-group">
                        <div className='checkbox-container'>
                            <input id="chkResponsibilitiesNaAtThisFirm" type="checkbox" />
                        </div>
                        <span
                            id="txtRegime_colourCodes"
                            class="form-control"
                        >
                            Responsibilities N/A at this firm
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}

export default ReportSectionFilter
