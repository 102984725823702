import React from "react";
import { utils, writeFile } from 'xlsx';
import APICall from "../../../Common/APICall";
import CSLTable from "../../Common/CSLTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'



class TopicsLegalEntities extends React.Component {

    state = { topic_gcs: {}, gcs: [], header_context_visible: false }

    componentDidMount() {
        this.setComponentState()
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) this.setComponentState()
    }

    setComponentState = async () => {
        const payload1 = { command: "topics_api", action: "list_gcs" }
        const payload2 = { command: "topics_api", action: "list_topic_gcs", topic_id: this.props.topic_id }
        const api = new APICall()
        const [gcs_result, topic_gcs_result] = await Promise.all([api.commandWithoutCallback(payload1), api.commandWithoutCallback(payload2)])
        let topic_gcs = {}
        let gcs = []
        if (gcs_result.error_code === 0) {
            gcs = gcs_result.result
        }
        if (topic_gcs_result.error_code === 0) {
            if (topic_gcs_result.result.length !== 0) {
                const list = topic_gcs_result.result
                for (let item of list) {
                    topic_gcs[item.gc_id.toString()] = 1
                }
            }
        }
        this.setState({ topic_gcs, gcs })
    }

    toggleActive = (gc_id) => async (event) => {
        event.preventDefault()
        let topic_gcs = JSON.parse(JSON.stringify(this.state.topic_gcs))
        let payload = { command: "topics_api", gc_id: gc_id, topic_id: this.props.topic_id }
        if (gc_id.toString() in topic_gcs === true) {
            delete topic_gcs[gc_id.toString()]
            payload.action = "remove_gcs_topic_association"
        } else {
            topic_gcs[gc_id.toString()] = 1
            payload.action = "add_gcs_topic_association"
        }
        this.setState({ topic_gcs })
        const api = new APICall()
        const result = await api.commandWithoutCallback(payload)
    }

    tableData = () => {
        let ret = { data: [], columns: [] }
        ret.columns = [
            { Header: 'LEGAL ENTITY', accessor: 'name', filterable: false },
            {
                Header: "",
                Cell: row => (
                    <>
                        {
                            row.original.is_active === 1 ?
                                <FontAwesomeIcon className="mc-roles-add-icon" icon={regular('toggle-on')} onClick={this.toggleActive(row.original.id)} /> :
                                <FontAwesomeIcon className="mc-roles-add-icon" style={{ color: "#ababab" }} icon={regular('toggle-off')} onClick={this.toggleActive(row.original.id)} />
                        }
                    </>
                ),
                maxWidth: 50,
                filterable: false
            }
        ]
        if (this.state.gcs.length !== 0) {
            for (let item of this.state.gcs) {
                let is_active = item.id.toString() in this.state.topic_gcs === true ? 1 : 0
                let elem = { id: item.id, name: item.company_name, is_active }
                ret.data.push(elem)
            }
        }
        return ret
    }

    toggleHeaderContextMenu = (e) => {
        e.preventDefault();
        let header_context_visible = this.state.header_context_visible === true ? false : true;
        this.setState({ header_context_visible });
    }
    closeHeaderContextMenu = (e) => {
        e.preventDefault();
        this.setState({ header_context_visible: false });
    }

    exportTopicLegalEntity = (e) => {
        e.preventDefault();

        let gcs = this.state.gcs;
        let topic_gcs = this.state.topic_gcs;

        const result = gcs.map(({ id, company_name }) => ({
            name: company_name,
            status: topic_gcs[id] ? 'Active' : 'Inactive'
        }));

        console.log('result TopicLegalEntity ==>', this.props.topic.name)


        this.generateExcelFile(this.props.topic.name, result, [
            'Legal Entity',
            'Status',
        ])

    }

    generateExcelFile = (topicName, legalEntities, columnNames = []) => {
        const today = new Date();
        const dateFormatted = `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`;

        const data = [];
        if (columnNames.length > 0) {
            data.push([`Legal Entity Associations for ${topicName}, ${dateFormatted}`]);
            data.push(columnNames);
        }
        data.push(...legalEntities.map((entity) => [entity.name, entity.status]));

        const workbook = utils.book_new();
        const worksheet = utils.aoa_to_sheet(data);

        // Merge the header row
        const merge = { s: { r: 0, c: 0 }, e: { r: 0, c: columnNames.length - 1 } };
        if (!worksheet['!merges']) worksheet['!merges'] = [];
        worksheet['!merges'].push(merge);

        // Set the column widths
        const colWidths = columnNames.map((name) => ({ wch: name.length + 5 }));
        worksheet['!cols'] = colWidths;

        utils.book_append_sheet(
            workbook,
            worksheet,
            'Legal Entity Associations'
        );

        const filename = `${topicName} Legal Entity Associations ${dateFormatted}.xlsx`;
        writeFile(workbook, filename);
    };



    render() {

        const overrideRolesRespoStyle = {
            position: 'absolute',
            backgroundColor: '#f3f0f0',
            padding: '5px 5px',
            display: 'inline-block',
            width: '150px',
            right: '7px',
            boxShadow: '0px 0px 4px rgba(0,0,0,0.4)',
            zIndex: 10000,
            boxSizing: 'border-box',
        };

        return (
            <div style={{ marginTop: "25px" }}>
                <div className="table-header-container" style={{
                    margin: 0,
                    position: 'relative',
                    height: '36px'
                }}>
                    <span style={{ fontSize: '11px', fontWeight: '600' }}>This table lists all the Legal Entities used within your organisation.
                        To enable the use of this Topic for a Legal Entity, simply switch the slider on</span>
                    <div style={{
                        right: '10px',
                        position: 'absolute'
                    }}>
                        <div className="table-header-ellipsis-container">

                            <FontAwesomeIcon className="table-ellipsis" icon={regular('ellipsis-vertical')} onClick={this.toggleHeaderContextMenu} />
                            {
                                (() => {
                                    if (this.state.header_context_visible === true) return (
                                        <div style={overrideRolesRespoStyle} onMouseLeave={this.closeHeaderContextMenu}>
                                            <div className="table-context-menu-item" onClick={(e) => this.exportTopicLegalEntity(e)}>Export</div>
                                        </div>
                                    )
                                })()
                            }
                        </div>
                    </div>
                </div>
                <CSLTable processData={this.tableData} />
            </div>
        )
    }

}

export default TopicsLegalEntities
