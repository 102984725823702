import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import APICall from "../../../Common/APICall"
import CSLTable from "../../Common/CSLTable"
import QuestionModal from "./QuestionModal"

class TopicSections extends React.Component {

    state = { questions: [], question_id: 0, show_question_modal: false }

    async componentDidMount() {
        const payload = { command: "qsets", action: 'list_questions', topic_id: this.props.topic_id, section_id: this.props.section.id }
        const api = new APICall()
        const result = await api.commandWithoutCallback(payload)
        let questions = []
        if (result.error_code === 0) {
            questions = result.result.questions
        }
        if (questions.length !== 0) {
            for (let i = 0; i < questions.length; i++) {
                questions[i].context_visible = false
            }
        }
        this.setState({ questions })
    }

    showAddQuestionModal = (event) => {
        event.preventDefault()
        console.log("showAddQuestionModal")
        this.setState({ show_question_modal: true, question_id: 0 })
    }

    deleteSection = (section_id) => (event) => {
        event.preventDefault()
        this.props.deleteSection(section_id)
    }

    toggleActive = (q_id) => async (event) => {
        event.preventDefault()
        let questions = JSON.parse(JSON.stringify(this.state.questions))
        let status = 1
        for (let i = 0; i < questions.length; i++) {
            // console.log("q_id, aq_id:", q_id, questions[i].id)
            if (questions[i].id === q_id) {
                questions[i].is_active = questions[i].is_active === 1 ? 0 : 1
                status = questions[i].is_active
            }
        }
        const { topic_id, section_id, is_active } = questions.find(obj => obj.id === q_id);

        this.setState({ questions })
        const payload = { command: "topics_api", action: "set_question_status", topic_id, section_id, question_id: q_id, status: is_active }
        const api = new APICall()
        const result = await api.commandWithoutCallback(payload)
    }

    removeQuestion = (q_id) => async (event) => {
        event.preventDefault()

        let questions = JSON.parse(JSON.stringify(this.state.questions))
        for (let i = 0; i < questions.length; i++) {
            // console.log("q_id, aq_id:", q_id, questions[i].id)
            if (questions[i].id === q_id) {
                questions[i].is_deleted = 1
                questions[i].is_active = 0
            }
        }

        const { topic_id, section_id } = questions.find(obj => obj.id === q_id);
        questions = questions.filter(obj => obj.is_deleted === 0);
        this.setState({ questions })
        const payload = { command: "topics_api", action: "remove_topic_question", topic_id, section_id, question_id: q_id }
        const api = new APICall()
        const result = await api.commandWithoutCallback(payload)
    }

    toggleContextMenu = (q_id) => (event) => {
        event.preventDefault()
        let questions = JSON.parse(JSON.stringify(this.state.questions))
        for (let i = 0; i < questions.length; i++) {
            // console.log("q_id, aq_id:", q_id, questions[i].id)
            if (questions[i].id === q_id) {
                questions[i].context_visible = questions[i].context_visible === true ? false : true
            }
        }
        this.setState({ questions })
    }

    closeContextMenu = (q_id) => (event) => {
        event.preventDefault()
        let questions = JSON.parse(JSON.stringify(this.state.questions))
        for (let i = 0; i < questions.length; i++) {
            if (questions[i].id === q_id) {
                questions[i].context_visible = false
                break
            }
        }
        this.setState({ questions })
    }

    showQuestionEditModal = (q_id) => (event) => {
        event.preventDefault()
        console.log("showQuestionEditModal", q_id)
        this.setState({ question_id: q_id, show_question_modal: true })
    }

    closeQuestionModal = () => {
        this.setState({ show_question_modal: false })
    }

    generateTableData = () => {
        let ret = { data: [], columns: [] }
        ret.columns = [
            { Header: '#', accessor: 'sl_no', filterable: false, width: 40 },
            { Header: 'QUESTION', accessor: 'title', filterable: false },
            { Header: 'ANSWER', accessor: 'ans', filterable: false, width: 100 },
            { Header: 'FLAGS', accessor: 'flag', filterable: false, width: 125 },
            { Header: 'ALLOW N/A?', accessor: 'is_na', filterable: false, width: 100 },
            {
                Header: "ACTIVE",
                maxWidth: 70,
                filterable: false,
                Cell: row => (
                    <div className="mc-roles-table-action-container" style={{ width: "100%", paddingRight: "10px" }}>
                        {
                            row.original.is_active === 1 ?
                                <FontAwesomeIcon className="mc-roles-add-icon" icon={regular('toggle-on')} onClick={this.toggleActive(row.original.id)} /> :
                                <FontAwesomeIcon className="mc-roles-add-icon" style={{ color: "#ababab" }} icon={regular('toggle-off')} onClick={this.toggleActive(row.original.id)} />
                        }
                        <div>
                            <FontAwesomeIcon className="mc-roles-add-icon" icon={regular('ellipsis-vertical')} onClick={this.toggleContextMenu(row.original.id)} />
                            {
                                row.original.context_visible &&
                                <div className="context-menu" onMouseLeave={this.closeContextMenu(row.original.id)}>
                                    <div className="context-menu-item" onClick={this.showQuestionEditModal(row.original.id)}>Edit</div>
                                    <div className="context-menu-item" onClick={this.removeQuestion(row.original.id)}>Remove</div>
                                </div>
                            }
                        </div>
                    </div>
                )
            }
        ]
        if (this.state.questions.length !== 0) {
            for (let i = 0; i < this.state.questions.length; i++) {
                let question_json = JSON.parse(this.state.questions[i].question_json)
                let elem = {
                    sl_no: i + 1,
                    id: this.state.questions[i].id,
                    title: this.state.questions[i].name,
                    ans: question_json.correct_answer,
                    flag: question_json.flagged_opt,
                    is_na: question_json.allow_na === 0 ? "No" : "Yes",
                    is_active: this.state.questions[i].is_active,
                    context_visible: this.state.questions[i].context_visible
                }
                ret.data.push(elem)
            }
        }
        return ret
    }

    saveQuestion = async (question) => {
        const api = new APICall()
        const payload = { command: "topics_api", action: "upsert_question", question }
        console.log("payload:", payload)
        const result = await api.commandWithoutCallback(payload)
        if (result.error_code === 0) {
            let questions = JSON.parse(JSON.stringify(this.state.questions))
            if (result.result === null) {
                for (let i = 0; i < questions.length; i++) {
                    if (questions[i].id === question.id) {
                        questions[i] = question
                        break
                    }
                }
            } else {
                question.id = result.result
                questions.push(question)
            }
            this.setState({ questions, show_question_modal: false })
        }
    }

    updateSection = (event) => {
        event.preventDefault()
        console.log(event.currentTarget.innerHTML)
        this.props.updateSection({ name: event.currentTarget.innerHTML, section_id: this.props.section.id })
    }

    handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
        }
    }

    render() {
        return (
            <div style={{ marginBottom: "15px" }}>
                {
                    this.state.show_question_modal === true &&
                    <div className="interactive-overlay" style={{ position: "fixed", left: "10%", zIndex: "2" }}>
                        <QuestionModal topic_id={this.props.topic_id} section_id={this.props.section.id} question_id={this.state.question_id} closeModal={this.closeQuestionModal} saveQuestion={this.saveQuestion} />
                    </div>
                }
                <div className="topic-header-container">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div contentEditable={true} onBlur={this.updateSection} onKeyDown={this.handleKeyDown} style={{ marginRight: "10px", padding: "5px" }}>{this.props.section.name}</div>
                        {/* <FontAwesomeIcon className="mc-general-icon" style={{fontSize: "12px"}} icon={solid('pencil')} /> */}
                    </div>
                    <div className="topic-header-icons-container">
                        <FontAwesomeIcon className="mc-general-icon" title="Remove Section" icon={regular('trash-can')} onClick={this.deleteSection(this.props.section.id)} />
                        <FontAwesomeIcon className="mc-general-icon" title="Add Question" icon={regular('square-plus')} onClick={this.showAddQuestionModal} />
                    </div>
                </div>
                <CSLTable processData={this.generateTableData} tableRows={5} />
            </div>
        )
    }
}

export default TopicSections
