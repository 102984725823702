import React, { Component } from 'react'
import SorContext from './SorContext'
import './sor.css'


class RegimeTypeTracker extends Component {

    static contextType = SorContext;

    changeToggleColor = (e) => {
        let checked = e.target.checked;
        const { updateFilterParameter, selected_report_parameter } = this.context;
        updateFilterParameter({ check: { 'is_used_regime_color_codes': checked } })
    }

    changeToggleRolesRespDesc = (e) => {
        let checked = e.target.checked;
        const { updateFilterParameter } = this.context;
        updateFilterParameter({ check: { 'is_include_roles_respo_desc': checked } })
    }

    changeToggleEffectiveDates = (e) => {
        let checked = e.target.checked;
        const { updateFilterParameter } = this.context;
        updateFilterParameter({ check: { 'is_show_role_effective_date': checked } })
    }


    render() {
        const { selected_report_parameter } = this.context;
        let is_color_checked = selected_report_parameter.secondSection.is_used_regime_color_codes;
        let is_role_desc_checked = selected_report_parameter.secondSection.is_include_roles_respo_desc;
        let is_effective_date_checked = selected_report_parameter.secondSection.is_show_role_effective_date;


        return (
            <div class='filter-container-first'>
                <div class="form-group">
                    <div class="input-group">
                        <div className='checkbox-container'>
                            <input type="checkbox"
                                checked={is_color_checked}
                                onChange={this.changeToggleColor}
                            />
                        </div>
                        <span
                            id="txtRegime_colourCodes"
                            class="form-control"
                        >
                            Use Regime Type colour codes
                        </span>
                    </div>
                </div>
                <div class="form-group">
                    <div class="input-group">
                        <div className='checkbox-container'>
                            <input type="checkbox"
                                checked={is_role_desc_checked}
                                onChange={this.changeToggleRolesRespDesc}
                            />
                        </div>
                        <span
                            id="txtRolesResponsibility_desc"
                            class="form-control"
                        >
                            Include Role/Responsibility Description
                        </span>
                    </div>
                </div>
                <div class="form-group">
                    <div class="input-group">
                        <div className='checkbox-container'>
                            <input type="checkbox"
                                checked={is_effective_date_checked}
                                onChange={this.changeToggleEffectiveDates}
                            />
                        </div>
                        <span
                            id="txtShowRoleEffective_date"
                            class="form-control"
                        >
                            Show Role Effective Date
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}

export default RegimeTypeTracker
