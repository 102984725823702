import React from 'react';
import styled from 'styled-components';
import ClipLoader from "react-spinners/ClipLoader";

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 100vh;
  width: 100vw;
  background-color: #ffffff;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 40000;
  opacity: 0.8;
`;

const Loader = styled.div`
    position: fixed;
    left: 30vw;
    top:20vh;
    width: 40vw;
    z-index: 40001;
    font-size: 28px;
    font-weight: 700;
    color: #123abc;
    text-align: center;
    border-style: solid;
    border-width: 2px;
    border-color: #282828;
    border-radius: 10px;
    background-color: white;
    padding: 15px;
`

const LoaderText = styled.div`
    font-size: 18px;
    font-weight: 700;
    color: #123abc;
`



const CSLPDFLoader = (props) => {
  return (
      <div>
        <InactiveOverlay />
        <Loader>
            <LoaderText>
            Generating PDF. Please be patient!<br /><br /> 
            <span style={{fontSize : 16, fontWeight: 600}}>Depending upon how large the report is and how fast your machine is, this may take upto a couple of minutes to complete.</span><br /><br /><br />
            </LoaderText>
            <ClipLoader
              size={150}
              color={"#123abc"}
              loading={true}
            />
        </Loader>
      </div>
  );
};

export default CSLPDFLoader;
