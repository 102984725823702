import React from 'react';
import styled from 'styled-components'
import RespoEventBusNew from './RespoEventBusNew';
import RespoUtils from './RespoUtils';

const RespoTreeStopButtonWrapper = styled.div`
							display: block;
							position: absolute;
							color: blue;
							top: 2px;
							right: 3px;
							background-color: transparent;
							cursor: pointer;
							font-size:22px;
						    -webkit-transform: rotate(180deg);
						    -moz-transform: rotate(180deg);
						    -o-transform: rotate(180deg);
						    -ms-transform: rotate(180deg);
						    transform: rotate(180deg);

							`;

let path = RespoUtils.getPath();

class TreeStopButton extends React.Component {
	changeMaxLevel() {
		RespoEventBusNew.updateStore('max_level', this.props.item.level);
	}
	render() {
		if (this.props.item.level === 1) return <div></div>
		let titleString = "Stop rendering after " + this.props.item.level + " levels.";
		if (this.props.item.level === 1) titleString = "Stop rendering after the first level.";

		if (this.props.item.level === RespoEventBusNew.getStoreData('max_level')) {
			return (<RespoTreeStopButtonWrapper title={titleString} onClick={this.changeMaxLevel.bind(this)}>
				<img src={`${path}cslstopactive.png`} alt="Stop here" style={{ width: "15px", height: "auto" }} />
			</RespoTreeStopButtonWrapper>)
		}

		return (<RespoTreeStopButtonWrapper title={titleString} onClick={this.changeMaxLevel.bind(this)}>
			<img src={`${path}cslstopinactive.png`} alt="Stop here" style={{ width: "15px", height: "auto" }} />
		</RespoTreeStopButtonWrapper>)
	}

}

export default TreeStopButton;
